import { Action, createReducer, on } from "@ngrx/store";
import { CBFilterResponse } from "src/app/shared/interface/contract-balancing.interface";
import { getPSDFilterDataApiSuccess, invokePSDViewData} from "../action/market-elasticity.action";

export interface State {
    meFilters: any;
    isUpdated: boolean;
    selectedDesk: string;
    selectedDeskParam: string;
    selectedDeskId: any;
    psdFilterResponse: any;
}

export const initialState: State = {
    meFilters:{products:[],fromDate:"",toDate:""},
    isUpdated: false,
    selectedDesk: '',
    selectedDeskParam: '',
    selectedDeskId: '',
    psdFilterResponse: {
        locations: [],
        suppliers: [],
        contractNumbers: [],
        sraDealNumbers: [],
        months: [],
        fromDate: '',
        toDate: ''
    },
};

const marketElasticityReducer = createReducer(
    initialState,
    on(getPSDFilterDataApiSuccess, (state, { allPSDFilterData, dateRange, enableRefreshTableFlagArr }: any) => {
        let allCBFilterDataCopy = JSON.parse(JSON.stringify(allPSDFilterData));
        const cbFilterData = Object.keys(allCBFilterDataCopy);
        if (!cbFilterData.includes("error")) {
            for (let i in allCBFilterDataCopy) {
                console.log("allCBFilterDataCopy[i] " + i);
                if (allCBFilterDataCopy[i].length) {
                    let modifiedObj = allCBFilterDataCopy[i].map((value: any) => ({ name: value, checked: true }));
                    modifiedObj.unshift({ name: 'all', checked: true });
                    allCBFilterDataCopy[i] = modifiedObj;
                }
            }
        } else {
            allCBFilterDataCopy = {};
        }
        
        return {
            ...state,
            cbFilterResponse: allCBFilterDataCopy
        }
    }),
    on(invokePSDViewData, (state, { meFilters, isUpdated }) => ({
        ...state,
        meFilters,
        isUpdated
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return marketElasticityReducer(state, action);
}

export const getPSDFilterResponse = (state: State) => ({ psdFilterResponse: state.psdFilterResponse});

export const getPSDViewResponse = (state: State) => ({ meFilters: state.meFilters, isUpdated: state.isUpdated });

export const getSelectedDesk = (state: State) => ({ selectedDesk: state.selectedDesk, selectedDeskParam: state.selectedDeskParam , selectedDeskId: state.selectedDeskId });
