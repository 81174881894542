
<span class="icon-remove" (click)="closeModal()">X</span>
<button class="btn send-btn" type="submit" (click)="createPayLoad();"  *ngIf="nominationData?.length > 0" [ngClass]="{'vendor-notification-email_alignment-sendMailPipeline':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk)}" [class.disabled]="suppliers.length > 1">send email</button>


<div class="selected-nomination-items" [ngClass]="{'selected-nomination-items-pipeline':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}">
    <div class="each-nom-item" *ngFor="let item of nominationIds; let i=index;">
        <input type="radio" [class.first]="i===0" [checked]="item.checked === true" [value]="item.no" id="{{item.id}}"  name="nominations"  (click)="getItemPreview(item.id)" />
        <label for="{{item.id}}">{{item.no}}</label>
    </div>

</div>
<div>

<div class="email-form-data">
    <p class="vn-preview-heading" *ngIf="!(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)">preview of selected nomination</p>
    <span class="danger" *ngIf="suppliers.length > 1 &&!(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)">Please select same supppliers to send email</span>
    <span class="danger" [hidden]="!hasEmail">Please enter email</span>
    <form>
        <div class="form-row">
            <div class="form-group col-md-4" [ngClass]="{'vendor-notification-email_alignment-pipeline_form_group':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}">
                <div class="vendor-notification-email_alignment" [ngClass]="{'vendor-notification-email_alignment-pipeline_group':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}">
                    <label for="product" [ngClass]="{'vendor-notification-email_alignment-pipeline':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}">To : </label>
                    <input type="email" [ngClass]="{'vendor-notification-email_alignment-pipeline_input':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}" (focus)="toolTipAction(true)" (focusout)="toolTipAction(false)"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="toEmailInput" class="form-control" #toEmail
                        placeholder="Enter to" />
                    <div class="tooltip" *ngIf="showToolTip">
                        <span class="tooltiptext" [ngClass]="{'vendor-notification-email_alignment-pipeline_tooltiptext':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}">use <span class="highlight-text">,</span> to separate multiple emails</span>
                    </div>
                </div>
                    <div class="vendor-notification-SEDate">
                        <label for="startDate" class="vendor-notification-SEDate_lable">Date range nomination to be sent</label>
                        <input type="date" (focus)="toolTipAction(true)" [ngModelOptions]="{standalone: true}" [(ngModel)]="startRangeForInput"
                            class="form-control vendor-notification-date_range" id="startDate" [min]="startRange" [max]="endRange"/>
                        <input type="date" (focus)="toolTipAction(true)" (focusout)="toolTipAction(false)"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="endRangeForInput" class="form-control vendor-notification-date_range"
                            [min]="startRange" [max]="endRange"/>
                    </div>
                <button class="btn" (click)="applyDateRange()">apply</button>
        
            </div>
        </div>
    </form>  
    <div class="subject-container"  *ngIf="(nominationData?.length > 0) || (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk) " >
        <p class="vendor-notification_subject">Subject :</p><input class="email-subject" [ngClass]="{'email-subject-pipeline':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}" [(ngModel)]="subjectLine"/>
        <span title="{{ (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk) ?nominationData.nominationNo :nominationData[0]?.destinationLocation }}">{{ (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk) ?nominationData?.nominationNo :(nominationData[0]?.destinationLocation || nominationData[0]?.originLocation)}}</span>
    </div>
    <span class="no-data-text" *ngIf="noDataText">please complete all data fields prior to sending notification</span>
</div>

<div *ngIf="nominationData?.length > 0 && !(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)">

    <p class="font-16">Team,</p>
    <div class="email-preview" [ngClass]="{'resize_height_month':dateDifference ==='month'}" *ngFor="let nomination of nominationData">

        <p class="font-16">Below is the demand for <b>{{
                startRangeForInput | date:'MM/dd/yyyy'}} - {{ endRangeForInput | date:'MM/dd/yyyy'}}</b> for the
            following terminal. </p>
        <p class="font-16">As of today, here is the projected ethanol demand for <b>terminal</b> for <b>{{
                nomination.destinationLocation}}:</b></p>

        <table aria-describedby="vendornotification">
            <th></th>
            <tr class="text-center">
                <td colspan="3">Chevron Account</td>
            </tr>
            <tr class="h-25" *ngIf="nomination.nominationNo && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">nomination no</td>
                <td colspan="2" class="pl-5">{{nomination.nominationNo}}</td>
            </tr>
            <tr class="h-25" *ngIf="nomination.nominationNo">
                <td class="pl-5">date range</td>
                <td colspan="2" class="pl-5">{{startRangeForInput | date:'MM/dd/yyy'}} <b>through</b> {{endRangeForInput
                    | date:'MM/dd/yyy'}} </td>
            </tr>
            <tr class="h-25" *ngIf="nomination.scheduledDate && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">scheduled date</td>
                <td colspan="2" class="pl-5">
                    {{nomination.scheduledDate.substring(4,6)}}/{{nomination.scheduledDate.substring(6,nomination.scheduledDate.length)}}/{{nomination.scheduledDate.substring(0,4)}}
                </td>
            </tr>
            <tr *ngIf="nomination.month && dateDifference === 'month'">
                <td class="pl-5"> month</td>
                <td colspan="2" class="pl-5">{{nomination.month}}</td>
            </tr>
            <tr *ngIf="nomination.supplier && nomination.contractNumber && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">contract number </td>
                <td colspan="2" class="pl-5">{{nomination.contractNumber}}</td>
            </tr>
            <tr *ngIf="nomination.sapNumber && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">SAP number </td>
                <td colspan="2" class="pl-5">{{nomination.sapNumber}}</td>
            </tr>
            <tr *ngIf="nomination.supplier">
                <td class="pl-5">supplier </td>
                <td colspan="2" class="pl-5">{{nomination.supplier}}</td>
            </tr>
            <tr *ngIf="nomination.carrier && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">carrier </td>
                <td colspan="2" class="pl-5">{{nomination.carrier}}</td>
            </tr>
            <tr *ngIf="nomination.originLocation && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">origin location </td>
                <td colspan="2" class="pl-5">{{nomination.originLocation}}</td>
            </tr>

            <tr *ngIf="nomination.destinationLocation">
                <td class="pl-5">destination location </td>
                <td colspan="2" class="pl-5">{{nomination.destinationLocation}}</td>
            </tr>
            <tr *ngIf="nomination.modeOfTransportDesc && dateDifference === 'week' && dateDifference !== 'month' " class="b-none">
                <td class="pl-5">Total {{nomination.modeOfTransportDesc === 'Pipeline' ? 'Tank Transfer' :
                    nomination.modeOfTransportDesc === 'Barge' ? 'Barges Volume' : nomination.modeOfTransportDesc ===
                    'Rail' ? 'Rail Car' : nomination.modeOfTransportDesc }}s</td>
                <td colspan="2">
                <input type="text" [class.notAllowed] ="nomination.modeOfTransportDesc === 'Pipeline' " class="table-input"  (keyup)="validateVolume()"  [(ngModel)]="nomination.totalTrucks_rails_deliveries" >
                
            </td>

            </tr>
            <tr *ngIf="monthlyTruckData">
                <td class="pl-5">total bbls</td>
                <td colspan="2" class="pl-5"> <input type="text"  class="table-input"  (keyup)="validateVolume()"   [(ngModel)]="nomination.totalBbls" >
                </td>
            </tr>
            <tr *ngIf="nomination.totalGallons && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">total gallons</td>
                <td colspan="2" class="pl-5">{{nomination.totalGallons}} </td>
            </tr>
            <tr *ngIf="nomination.supplier && nomination.contractBbls && dateDifference ==='month'">
                <td class="pl-5">contract Volume</td>
                <td colspan="2" class="pl-5">{{nomination.contractBbls}}</td>
            </tr>
            <tr *ngIf="nomination.supplier && nomination.contractTolerance && dateDifference !=='week' && dateDifference !=='month'">
                <td class="pl-5">contract tolerance</td>
                <td colspan="2" class="pl-5">{{nomination.contractTolerance}} <span
                        *ngIf="nomination.contractTolerance"> %</span></td>
            </tr>
            <tr class="text-center"
                *ngIf="nomination && nomination.modeOfTransportDesc && (nomination.modeOfTransportDesc.toLowerCase() === 'pipeline' || nomination.modeOfTransportDesc.toLowerCase() === 'rail' || nomination.modeOfTransportDesc.toLowerCase() === 'truck') && dateDifference ==='week' && dateDifference !=='month'">
                <td colspan="2" *ngIf="nomination.modeOfTransportDesc === 'Pipeline'">Transfers By Day (BBL)</td>
                <td colspan="3" *ngIf="nomination.modeOfTransportDesc === 'Rail'">Movement By week</td>
                <td colspan="2" *ngIf="nomination.modeOfTransportDesc === 'Truck'"> Movement By Day</td>
            </tr>
            <ng-container *ngIf="nomination.modeOfTransportDesc === 'Rail' && dateDifference !=='month'">
                <tr *ngIf="nomination.weeklyData?.length">
                    <td class="pl-5">Date Range</td>
                    <td class="pr-5"># of railcars</td>
                    <td class="pr-5">BBLS</td>
                </tr>
                <tr *ngFor="let data of nomination.weeklyData">
                    <td class="pl-5">
                        {{data.dateRange.substring(3,5)}}/{{data.dateRange.substring(0,2)}}/{{data.dateRange.substring(6,10)}}
                        -
                        {{data.dateRange.substring(14,16)}}/{{data.dateRange.substring(11,13)}}/{{data.dateRange.substring(17)}}
                    </td>
                    <td class="pr-5"> <input type="text" class="table-input" (keyup)="validateVolume()"  [(ngModel)]="data.bbl685Rails">
                    </td>
                    <td class="pr-5">  <input type="text" class="table-input" [(ngModel)]="data.scheduledQtyBbl"></td>
                </tr>
                <tr>
                </tr>
            </ng-container>
            <ng-container
                *ngIf="nomination && nomination.modeOfTransportDesc && (nomination.modeOfTransportDesc.toLowerCase() === 'truck' || nomination.modeOfTransportDesc.toLowerCase() === 'pipeline') && dateDifference !== 'month'">
                <tr *ngIf="nomination.dailyBreakdownQty?.length">
                    <td class="pl-5">Date Range</td>
                    <td class="pr-5">{{nomination.modeOfTransportDesc === 'Truck' ? '# of Trucks':
                        nomination.modeOfTransportDesc === 'Rail' ? '# of Rails' : '# of bbls'}} ({{nomination.modeOfTransportDesc === 'Truck' ? truckBbblRepharse:''}})</td>
                </tr>
                <tr *ngFor="let data of nomination.dailyBreakdownQty">
                    <td class="pl-5">
                        {{data.scheduledDate.substring(4,6)}}/{{data.scheduledDate.substring(6,nomination.scheduledDate.length)}}/{{data.scheduledDate.substring(0,4)}}
                    </td>

                    <td class="pr-5">
                        <input type="text" class="table-input" #truckWeek [value]="nomination.modeOfTransportDesc === 'Truck' ? data[updateTruckBbl[0][0]]:
                        data.scheduledQty" (keyup)="validateVolume()"  [(ngModel)]="nomination.modeOfTransportDesc === 'Truck' ? data[updateTruckBbl[0][0]]:
                        data.scheduledQty"></td>
                </tr>
                <tr>
                </tr>
            </ng-container>
        </table>
        <span class="error-text"  *ngIf="showErrorMessage">Note: {{errorText}}</span>

    </div>

    <p class="vendor_notification_comments_label">Comments :</p>
    <textarea class="vendor_notification_comments" name="" id="" cols="77" rows="5" [(ngModel)]="comments"></textarea>

    <p class="font-16">Plan accordingly and let me know if there are any other questions or concerns</p>
    <p class="font-16">Thanks,</p>
    <p class="m-0 font-16"> {{fromUserName}} </p>
    <p class="m-0 font-16"> {{userDesg}} </p>
    <p class="m-0 font-16"> {{userEmail}} </p>
    <p class="m-0 font-16"> {{userContact}} </p>


</div>

<div *ngIf="nominationData?.pipelineVendorNotificationList?.length > 0 && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)">
    <div>
        <p>Nomination number - {{nominationData?.nominationNo}}</p>
        <p>MOT - {{nominationData?.modeOfTransport}}</p>
        <p> Vessel/barge/pipeline - {{nominationData?.vehicleName}}</p>
    </div>
    <table aria-describedby="vendornotification" class="pipeline_table">
        <tr class="text-center">
            <th></th>
            <td>Type <br/>
                <select name="filterType" id="filterType" [(ngModel)]="selectedfilterType" (change)="selectedType()" >
                    <option value="select">Select</option>
                    <option *ngFor="let nomination of uniqueLoadType" [ngValue]="nomination">{{nomination}}</option>
                </select>
            </td>
            <td>Location <br />
                <select name="filterType" id="filterType" [(ngModel)]="selectedfilterLocation" (change)="selectedLocation()">
                    <option value="select">Select</option>
                    <option *ngFor="let nomination of uniqueLocation" [ngValue]="nomination">{{nomination}}</option>
                </select>
            </td>
            <td>counterParty <br />
                <select name="filterType" id="filterType" [(ngModel)]="selectedfiltercounterPart" (change)="selectedcounterPart()">
                    <option value="select">Select</option>
                    <option *ngFor="let nomination of uniquecounterPart" [ngValue]="nomination">{{nomination}}</option>
                </select>
            </td>
            <td>Product</td>
            <td>Volume(mb)</td>
            <td>ETA</td>
            <td>Comments</td>
        </tr>

        <tr class="text-center" [ngClass]="{'vendor-notification-table_alignment-pipeline':(desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk|| desk === PipelineUswcPNWDesk || desk === CleanProductsEccaDesk || desk === CleanProductsWccaDesk)}" *ngFor="let nomination of updatedNominationPipelineData.pipelineVendorNotificationList; let i = index">
            <th></th>
            <td class="vendor-notification-table_alignment-pipeline_row">{{nomination?.type}}</td>
            <td class="vendor-notification-table_alignment-pipeline_row">{{nomination?.location}}</td>
            <td class="vendor-notification-table_alignment-pipeline_row">{{nomination?.counterPart}}</td>
            <td class="vendor-notification-table_alignment-pipeline_row">{{nomination?.product}}</td>
            <td class="vendor-notification-table_alignment-pipeline_row">{{nomination?.volume}}</td>
            <td class="vendor-notification-table_alignment-pipeline_row">
                {{nomination.eta.substring(4,6)}}/{{nomination.eta.substring(6,nomination.eta.length)}}/{{nomination.eta.substring(0,4)}}
            </td>
            <td>
                <textarea class="vendor_notification_comments vendor_notification_comments_pipeline" [(ngModel)] ="comment[i]" name="comment" id="comment" cols="25" rows="5"></textarea>
            </td>
        </tr>
        
    </table>
    <div class="pipeline_btn">
        <button class="pipeline_btn_cancel mr-3"(click)="closeModal()">cancel</button>
        <button class="pipeline_btn_submit" (click)="createPayLoad()">send mails</button>
    </div>
</div>

<p *ngIf="!(nominationData?.length || nominationData?.pipelineVendorNotificationList?.length)" class="no-data">no data is available for the selected date range </p>