import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'app-state-renderer',
    templateUrl: './chip-renderer.component.html',
    styleUrls: ['./chip-renderer.component.scss'],
})
export class ChipRendererComponent implements ICellRendererAngularComp {
    public value: any;
    agInit(params: ICellRendererParams<any, any, any>): void {
        this.value = params?.data?.DetailType?.length > 0 ? 'multiple' : params.data.status;

    }
    refresh(params: ICellRendererParams<any, any, any>): boolean {
        return false;
    }
}