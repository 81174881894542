<popout-window #infoPanelPopout>
    <div class="overallPanel overflow">
        <div class="panel-header info-header d-flex flex-row align-items-center justify-content-around pt-3 md-2">
            <p class="dockpanelHeader flex-fill p-0 m-0" *ngIf="showUndockButton">information panel</p>
            <p class="panelHeader flex-fill p-0 m-0" *ngIf="!showUndockButton">information panel</p>
            <label *ngIf="showUndockButton" (click)="infoPanelService.dockUnDockToggle()" class="dockText hoverable"><img (click)="infoPanelService.dockUnDockToggle()" src="../../../../assets/icons/icon_dock.svg" 
                class = "dockIcon hoverable" alt="dock button"> Dock Panel</label>
            <img (click)="infoPanelService.dockUnDockToggle()" *ngIf="!showUndockButton" src="../../../../assets/icons/icon_undock.svg" 
                class = "undockIcon hoverable" alt="undock button">
            <button (click)="infoPanelService.showInfoPanel(false)"
                    *ngIf="!showUndockButton"
                    class="notification-btn notification-panel notification flex-fill p-0 m-0 pr-3">
                <span class="icon-remove open"></span>
            </button>
        </div>

        <div class="content">
            <info-panel-content></info-panel-content>
        </div>
    </div>
</popout-window>