import { selectBcpSwitchView, selectCBHeaderCheckboxState, selectDesk, selectIsAdminSettingsSaveEnabled, selectPipelineSwitchView, selectBCPCompactView, selectCBDynamicDealsCount } from './../shared/store/selector/app.selector';
import { selectEthanolTRFiltersResponse, selectSwitchView, getUserDetails } from 'src/app/shared/store/selector/app.selector';
import { selectisTankParamSaveEnabled, selectisNotifParamSaveEnabled, } from 'src/app/shared/store/selector/app.selector';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
} from '@cvx/nextpage';
import { NavigationEnd, Router } from '@angular/router';
import { Utilities } from '../shared/utilities/utilities';
import { ToastObj, UrlDeconstructor } from '../shared/interface/interface';
import { ToastService } from '../services/toast.service';
import { Location } from '@angular/common';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';
import { NominationGridComponent } from '../nominations/nomination-grid/nomination-grid.component';
import { NominationService } from '../services/nomination.service';
import { FilterPanelComponent } from '../core/components/filter-panel/filter-panel.component';
import { select, Store } from '@ngrx/store';
import { saveTRView } from '../replenishment-planning/store/action/terminal-replenishment.action';
import { setBCPView, setClearFiltersBoolean, setEnableTableView, setReceivedResponseBoolean, setUDMScreen, triggerTRSave, updatedOfftakersFalse, setSwitchView, saveEthanolNomFiltersUpdated, 
  updateCBHeaderCheckboxesState, setPipelineSwitchView, triggerAdminSettingsSave, setAdminView, initialisePipelineNomFilters, setPipelineCompactView, showHideDeletedRules, triggerNotifParamSave, triggerTankParamSave, setBcpSwitchView, setBCPCompactView, saveCargoFiltersCCA } from '../shared/store/action/app.action';
import { selectisTRSaveEnabled, enableTableView, selectIsReceivedResponseBoolean, selectUDMScreen, selectDefaultTRFilters, selectBcpView } from '../shared/store/selector/app.selector';
import { LoaderService } from '../services/loader.service';
import { HomeService } from '../services/home.service';
import { AppState } from '../shared/store/reducer/app.reducer';
import { BCPView } from '../shared/interface/bcp-dashboard.interface';
import { saveBCPView } from '../bcp-dashboard/store/action/bcp-dashboard.action';
import { updateRefreshTableFlag } from '../contract-balancing/store/action/contract-balancing.action';
import { NominationEthanolViewComponent } from '../nominations/nomination-grid/nomination-ethanol-view/nomination-ethanol-view.component';
import { NominationPipelineViewComponent } from '../nominations/nomination-grid/nomination-pipeline-view/nomination-pipeline-view.component';
import { TRScreen, TRViewConst, UDMDesk } from '../shared/constants/terminal-replenishment.constant';
import { AlertsNotificationsComponent } from '../settings-alerts/alerts-notifications/alerts-notifications.component';
import { AlertsService } from '../services/alerts.service';
import { ReplenishmentPlanningService } from '../services/replenishment-planning.service';
import { AdminView } from '../shared/interface/mdm-admin.interface';
import { AlertSaveGuardService } from '../services/alert-save-guard.service';
import { DismissAlertService } from '../services/dismiss-alert.service';

import { MyMonitoringService } from '../services/logging.service';
import { CargoPlanningService } from '../services/cargo-planning.service';
import { MdmService } from '../services/mdm.service';
import { PopupWindowService } from '../services/popup-window.service';
import { InformationPanelService } from '../services/information-panel.service';
import { MktElsUtilities } from '../market-elasticity/utilities/mkt-els-utilities';
import { FilterDropdown } from '../shared/interface/FilterDropdown.interface';
import { CbDynamicDealsService } from '../services/cb-dynamic-deals.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Input() isLoggedIn!: boolean;
  title = 'pmmi-udm';
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  isFilterPanelClosed: boolean = true;
  isCreateNominationPanelClosed: boolean = true;
  isLoading: boolean = true;
  collpaseMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  expandedMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  adminCollapseMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  adminExpandMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  deletedRulesMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cpNominations$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  cpCargoPlanning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialDeskParam: any = localStorage.getItem("deskParam");
  initialDesk: any = localStorage.getItem("desk");
  isCargoDesks:any;
  searchNomIsOpen:boolean= false;

  selectedView = 'gant';
  selectedBCPView = 'summary';
  testArr: any = ['terminal', 'supplier', 'carrier'];
  gotUserDetails: any = false;
  toastObj: ToastObj = {
    show: false,
    type: '',
    msg: '',
  };
  apiCallCount: any = 0;
  path$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.location.path()
  );
  @ViewChild(NominationGridComponent) nominationsGrid!: NominationGridComponent;
  @ViewChild(NominationEthanolViewComponent) nominationEthanolViewComponent!: NominationEthanolViewComponent;
  @ViewChild(NominationPipelineViewComponent) nominationPipelineViewComponent!: NominationPipelineViewComponent;
  selectedDesk: any = '';
  homeMenuChange: any = true;
  @ViewChild(FilterPanelComponent) filterPanel!: FilterPanelComponent;
  @ViewChild(AlertsNotificationsComponent) alertsNotificationsComponent!: AlertsNotificationsComponent;
  isSaveEnabled: boolean = true;
  isAdminSettingsSaveEnabled: boolean = true;
  isTankParamSaveEnabled: boolean = true;
  isNotifParamSaveEnabled: boolean = true;


  // ngrx selectors
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectisTRSaveEnabled$ = this.store.pipe(select(selectisTRSaveEnabled));
  selectIsAdminSettingsSaveEnabled$ = this.store.pipe(select(selectIsAdminSettingsSaveEnabled));
  selectBCPView = this.store.pipe(select(selectBcpView));
  selectTableView = this.store.pipe(select(enableTableView))
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectisTankParamSaveEnabled$ = this.store.pipe(select(selectisTankParamSaveEnabled));
  selectisNotifParamSaveEnabled$ = this.store.pipe(select(selectisNotifParamSaveEnabled));

  selectedDeskOption: string = '';
  selectedReceivedResponseBoolean$ = this.appStore.pipe(select(selectIsReceivedResponseBoolean));
  selectedUDMScreen$ = this.store.pipe(select(selectUDMScreen));
  selectedCBHeaderCheckboxState$ = this.store.pipe(select(selectCBHeaderCheckboxState));
  selectSwitchView$ = this.store.pipe(select(selectSwitchView));
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))

  selectedDefaultFilters$ = this.appStore.pipe(select(selectDefaultTRFilters));
  private previousUrl: string | null;
  private currentUrl: string | null;
  private selectedUDMScreen: string = '';
  private isTRScreenLoaded = 1;
  cbHeaderCheckboxes: any[] = [];
  cbHeaderCheckedCount: number = 0;
  nominationSidePanelExpanded: boolean = false;
  currentSwitch: any;
  isPivotVisible: boolean = false;
  getProduct: any;
  isDeletedRules: boolean = false;
  deskListResponse: any = [];
  isPipelineView: boolean = false;
  isRefineryView: boolean = false;
  isCCAPanamaView: boolean = false;
  isCCAView: boolean = false;
  currentPipelineSwitch: any;
  currentBcpSwitch:any;
  selectedPipelineSwitchView$ = this.store.pipe(select(selectPipelineSwitchView));
  selectedBcpSwitchView$ = this.store.pipe(select(selectBcpSwitchView));
  selectedBCPCompactView$ = this.store.pipe(select(selectBCPCompactView));
  showViewsDropdown: boolean = false;
  showNomCargoDropdown: boolean=false;
  viewsList: any = []
  @ViewChild("viewsDropdown") viewsDropdown!: ElementRef;
  @ViewChild("dropDownIcon") dropDownIcon!: ElementRef;
  selectedCustomView: any = "default view";
  showDeleteConfirmation: boolean = false;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  userDetails: any;
  allowGetApiCall: any;
  checked = false
  selectedParams: any;
  createCargo: boolean = false;
  inCpTab:boolean = false;
  noContractBalancing!: boolean;
  isDropdownOpen = false;
  alertNotificationPanelOpen: boolean = false;
  dismissAlertModal = { open: false, modal: 'dismissModal' };
  showSaveConfirmation: boolean = false;
  deleteLocationModal = { open: false, modal: 'deleteLocation' };

  isCreateUpdateView:boolean = false;
  PipelineUswcPNWDesk:any = "clean products + uswc + pnw desk";
  PipelineUsecFloridaDesk:any = "clean products + usec + florida desk";
  PipelineUsecRichmondDesk:any = "clean products + uswc + richmond desk";
  PipelineUsecpascagoulaDesk:any = "clean products + usec + pascagoula desk";
  CleanProductsEccaDesk:any = "clean products + latam + ecca desk";
  CleanProductsWccaDesk:any = "clean products + latam + wcca desk";
  compactView: boolean = false;
  compactViewBCP: boolean = false;
  deleteViewDetails: any;
  isCombinedView: boolean = false;
  deskSelectiononLoad: any;
  linkNomData:any =[];
  filterLocationSelected: any;
  isSaveDisabledForPSD = true;

  viewInfoPanel: boolean = false;
  @ViewChild("popoutWindow") popoutWindow!: any;
  showAT: boolean = false;
  allowUpdate:boolean = false;
  cbDynamicDealsCount$!:Observable<any>;
  isCBGlobalSearchBarOpened: boolean = false;
  checkIfDeskChanged:any;
  constructor(
    private store: Store,
    private appStore: Store<AppState>,
    private NP: NextPageService,
    private router: Router,
    private utilities: Utilities,
    private toastSr: ToastService,
    public location: Location,
    public detectChanges: ChangeDetectorRef,
    public nominationService: NominationService,
    private loaderService: LoaderService,
    public alertsService: AlertsService,
    private homeService: HomeService,
    private replenishmentService: ReplenishmentPlanningService,
    private alertSaveService :AlertSaveGuardService,
    private dismissAlertService: DismissAlertService,
    private myMonitoringService: MyMonitoringService,
    private cpService: CargoPlanningService,
    private data: NominationService,
    private mdmService : MdmService,
    private popupService: PopupWindowService,
    private infoPanelService: InformationPanelService,
    private mktElsUtilities : MktElsUtilities,
    private cbService: CbDynamicDealsService,
    private toasterService: ToastService
  ) {
    this.NP.options.sitename = 'unified distribution management';
    this.setHomeMenu(location);
    this.compactView = localStorage.getItem("compactViewToggleStatus") === "true"
    this.currentUrl = this.router.url;
    this.previousUrl = null;


    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let desk = this.selectedDeskOption.toLowerCase();

        this.previousUrl = this.currentUrl;
        const prevDesk:any =this.previousUrl;
        localStorage.setItem("prevUrl",prevDesk);
        this.currentUrl = event.urlAfterRedirects;
        if (this.nominationService?.routingRestrictionFromDetailsPage) {
          this.currentUrl.toLowerCase().includes("bcpdashboard") && this.appStore.dispatch(setUDMScreen({ screen: TRScreen.BCPDashboard, receivedTotalFiltersResponse: true }));
          this.currentUrl?.toLowerCase().includes("nominations") && this.appStore.dispatch(setUDMScreen({ screen: TRScreen.Nominations, receivedTotalFiltersResponse: true }));
          this.currentUrl?.toLowerCase().includes("cargoplanning") && this.appStore.dispatch(setUDMScreen({ screen: TRScreen.CargoPlanning, receivedTotalFiltersResponse: true }));
          this.currentUrl?.toLowerCase().includes("marketelasticity") && this.appStore.dispatch(setUDMScreen({ screen: TRScreen.MarketElasticity, receivedTotalFiltersResponse: true }));
        }
          if(event.url !== '/contract-balancing'){
          this.noContractBalancing = true;
        }
          else if(this.path$.value === '/contract-balancing' && (desk.startsWith("ethanol") || desk.startsWith('clean'))) {
          this.noContractBalancing = true;
        } else {
          this.noContractBalancing = false;
        }

          if (event.url === '/admin/locations')
            this.appStore.dispatch(setAdminView({ adminView: AdminView.Locations }));
          else if (event.url === '/admin/desks')
            this.appStore.dispatch(setAdminView({ adminView: AdminView.Desks }));
          else if (event.url === '/admin/materialMapping')
            this.appStore.dispatch(setAdminView({ adminView: AdminView.MaterialMapping }));
          else if (event.url === '/admin/transport')
            this.appStore.dispatch(setAdminView({ adminView: AdminView.Transport }));

          if((this.previousUrl?.includes("/bcpDashboard") && (this.currentUrl.includes("/replenishmentPlanning") || this.currentUrl.includes("/nominations"))) ||
          ((this.previousUrl?.includes("/replenishmentPlanning") || this.previousUrl?.includes("/nominations")) && this.currentUrl.includes("/bcpDashboard")))
          {            
            this.nominationService.setSelectedView = "default view";      
            this.nominationService.customViewSidePanel$.next(false);
            this.nominationService.resetToDefaultView$.next(true);
            localStorage.removeItem("selectedView");
            localStorage.removeItem("nomColData");
            localStorage.removeItem("trColExpandData");
            localStorage.removeItem("trColOrder");
            this.isCreateUpdateView = false;
            this.selectedCustomView = "default view";           
            this.apiCallCount=1;
            this.getAllTheViews()
          }
          this.detectChanges.detectChanges();

      });

    this.dismissAlertService.showModalObservable$.subscribe(showModal => this.dismissAlertModal.open = showModal);
  }
  //host listner for when the page url navigation changes


  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.removeItem("selectedView");
    localStorage.removeItem("selectedDefaultView");
    if(this.isAdminSettingsSaveEnabled) {
      this.toggleDeleteLocationModal();
      event.preventDefault();
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    console.log('URL navigation change detected!');
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    //get the text of the clicked element
    const clickedElement = target.innerText;
    if((clickedElement === 'desks' || clickedElement === 'locations' || clickedElement === 'material mapping') && this.isAdminSettingsSaveEnabled){
      this.toggleDeleteLocationModal();
    }
    if (!target.classList.contains("dropdown-trigger")) {
      this.showViewsDropdown = false;
    }
  }

  checkForContractBalancing(){
    let desk = this.selectedDeskOption.toLowerCase();
    if(this.path$.value !== '/contract-balancing'){
      this.noContractBalancing = true;
    }
    else if(this.path$.value === '/contract-balancing' && (desk?.includes("ethanol") || desk.startsWith('clean'))) {
      this.noContractBalancing = true;
    } else {
      this.noContractBalancing = false;
    }
  }

  ngOnInit(): void {
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedDeskOption = response.selectedDesk;
          this.selectedParams = response.selectedDeskParam;
          this.isCCAView = this.utilities.checkIfCCAView(response.selectedDesk);
          this.isCargoDesks = this.selectedParams === 'floridadesk' || this.selectedParams === 'pnwdesk' || this.selectedParams === 'pascagouladesk' || this.selectedParams === 'richmonddesk';
          if(!this.isCargoDesks){
            this.inCpTab = false;
          }
          if(this.path$.value === '/cargoPlanning'){
            this.inCpTab = true;
          }

          this.toggleCarogPlanning();
          if (this.selectedDeskOption) {
            this.apiCallCount += 1;
            this.getAllTheViews();
            this.checkForContractBalancing();
          }
          this.isPipelineView = this.utilities.checkIfPipelineView(this.selectedDeskOption);
          this.isRefineryView = this.utilities.checkIfRefineryView(this.selectedDeskOption);
          this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.selectedDeskOption);
          this.isCCAView = this.utilities.checkIfCCAView(this.selectedDeskOption);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectedCBHeaderCheckboxState$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.cbHeaderCheckboxes = response.cbHeaderCheckboxes;
          this.cbHeaderCheckedCount = response.cbHeaderCheckedCount;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.clearStateMgmtDataFromLocalStorage();


    this.nominationService.resetToDefaultView$.subscribe(res => {
      if (res) {
        this.selectedCustomView = "default view";
      }
    });
    this.nominationService.linkNomData$.subscribe((res: any) => {
      this.linkNomData = res;
      if(res?.length)
        this.nominationService.linkCargoData = [...res];
    })
    this.nominationService.getSearchNominationAFAPI$.subscribe((res)=>{
      this.searchNomIsOpen = res;
    });

    this.nominationService.updateViewNameForStateManagement$.subscribe(res => {
      if (res) {
        this.selectedCustomView = res;
      }
    })

    this.toastSr.checkToastNotification$.subscribe((obj) => {
      this.toastObj = obj;
    });
    this.loaderService.checkLoader$.subscribe(flag => {
      setTimeout(() => {
        this.isLoading = flag;
      })
    });
    this.location.onUrlChange((url: string) => {
      this.path$.next(url);
    });
    this.nominationService.getSearchNominationAFAPI$.subscribe(res => {
      this.searchNomIsOpen = res;
    })
    this.nominationService.triggerDelete$.subscribe(res => {
      if (res?.deleteView) {
        this.showDeleteConfirmation = true;
        this.deleteViewDetails = res?.payload
      }
      else {
        this.showDeleteConfirmation = false;
      }
    })

    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userDetails = response.userDetails;
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

    this.selectisTRSaveEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.isSaveEnabled = !response.isTRSaveEnabled;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectisTankParamSaveEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.isTankParamSaveEnabled = !response.isTankParamSaveEnabled;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      })

    this.selectisNotifParamSaveEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.isNotifParamSaveEnabled = !response.isNotifParamSaveEnabled;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      })


    this.selectIsAdminSettingsSaveEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.isAdminSettingsSaveEnabled = response.isAdminSettingsSaveEnabled;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { }
      })



    this.selectTableView
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedView = response.isTableView ? 'table' : 'gant';
        }
      })

    this.nominationService.sidePanel$.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.nominationSidePanelExpanded = response;
        }
      })

    this.selectBCPView
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedBCPView = response.BCPView;
        }
      });

    this.selectedUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedUDMScreen = response.screen;                   
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isSwitchUpdated) {
            this.currentSwitch = response.switchView;
            this.store.dispatch(setSwitchView({ switchView: response.switchView, isSwitchUpdated: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectEthanolTRFiltersResponse$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.ethanolFilterValues.length > 0) {
            response.ethanolFilterValues.forEach((getValue: any) => {
              if (getValue?.headerName?.toLowerCase() === "mode of transport") {
                let isCheckedList = getValue.list.filter((value: any) => value.checked)
                if (isCheckedList.length === 1 && isCheckedList[0].name === "truck") {
                  this.isPivotVisible = true
                }
                else {
                  this.isPivotVisible = false
                }
              }
            })
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectedDefaultFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.filterLocationSelected = localStorage.getItem('locationSelected');
          this.getProduct = response.updatedFilterSelection.updatedProduct
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedPipelineSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isPipelineSwitchUpdated) {
            this.currentPipelineSwitch = response.pipelineSwitchView;
            this.store.dispatch(setPipelineSwitchView({ pipelineSwitchView: response.pipelineSwitchView, isPipelineSwitchUpdated: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      this.nominationService.triggerUpdateViewname.subscribe(res => {
        if(res === 'update'){
          if(!localStorage.getItem("selectedView")){
          this.selectedCustomView = "default view";
        }
          else{
            this.selectedCustomView =  localStorage.getItem("selectedView");
        }

      }
    })
    this.nominationService.triggerGetAllFromCreateView$.subscribe(res => {
      if(this.path$.value == "/bcpDashboard" || this.selectedDeskOption !=="panama"){
        if (res.isTrigger) {
          this.allowGetApiCall = true;
          this.gotUserDetails = false;
          if (res.selectedView) {
            this.selectedCustomView = res.selectedView;
            this.nominationService.setSelectedView = res.selectedView;
            localStorage.setItem("selectedView", res.selectedView);
            this.isCreateUpdateView = true;
          }
          else {
            this.selectedCustomView = "default view";
            this.isCreateUpdateView = false;
          }
          this.getAllTheViews();
        }
      }
    })

    this.nominationService.allCustomView$.subscribe(res => {
      if (res.length) {
        this.viewsList = res;
        let index = this.viewsList.findIndex((view: { applied: boolean; }) => view.applied === true);
        if (index >= 0) {
          this.selectedCustomView = this.viewsList[index].name;
        }
      }
      else {
        this.viewsList = [];
        this.selectedCustomView = "default view";
      }
    })

    this.selectedBcpSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isBcpSwitchUpdated) {
            this.currentBcpSwitch = response.bcpSwitchView;
            this.store.dispatch(setBcpSwitchView({ bcpSwitchView: response.bcpSwitchView, isBcpSwitchUpdated: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedBCPCompactView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.any) {
            this.compactViewBCP = response.bcpCompactView;
            this.store.dispatch(setBCPCompactView({ bcpCompactView: response.bcpCompactView, isBCPCompact: this.compactViewBCP }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.alertsService.sidePanel$.subscribe(response => {

      if(response !== this.alertNotificationPanelOpen){
        this.alertNotificationPanelOpen = response;
      }
    });

    this.alertsService.getIsCreateAlertsPopupOpen().subscribe(data => {
      this.toggleCreateAlertButton(data);
    });
    this.popupService.popupShow$.subscribe(show => {

      if(this.popoutWindow != null){
        if(show){
          this.popoutWindow.windowWidth = 800;
          this.popoutWindow.windowHeight = 500;
          this.popoutWindow.popOut();
        }
        else if(show && this.popoutWindow.windowHeight < 800) {
        this.popoutWindow = 800; 

        }else if(show && this.popoutWindow.windowWidth < 600) {
          this.popoutWindow = 600;
        }else{
          this.popoutWindow.popIn();
        }
      }
    })

    this.popupService.showNomAT$.subscribe(show => {
      this.showAT = show;      
    })



    this.infoPanelService.infoPanelOpen.subscribe(show => {
      this.viewInfoPanel = show;
    })

    this.cbDynamicDealsCount$ = this.appStore.pipe(select(selectCBDynamicDealsCount));

    this.mktElsUtilities.notifyEditedDataPSD$.subscribe((res:any) => {
      this.isSaveDisabledForPSD = res.isSaveDisabled;
    })
  }

  confirmSaveLocation(event : Event): void {
    this.showSaveConfirmation = true;
    this.toggleDeleteLocationModal();
  }

  setHomeMenu(location: Location) {
    if (location.path() !== '/admin/locations' && location.path() !== '/admin/materialMapping'
      && location.path() !== '/admin/desks' && location.path() !== '/admin/transport' && location.path() !== '/admin/parameters'
      && location.path() !== '/admin/parameters' && location.path() !== '/settings/alerts' && (this.selectedDesk?.deskValue?.includes('clean products + latam'))) {
      this.NP.options.navigationRoutes = [
        {
          title: 'replenishment planning',
          path: 'replenishmentPlanning',
        },
        {
          title: 'market elasticity',
          path: 'marketElasticity',
        },
        {
          title: 'nominations',
          path: 'nominations',
        },
        {
          title: 'cargo planning',
          path: 'cargoPlanning',
        },
        {
          title: 'bcp dashboard',
          path: 'bcpDashboard',
        },
       {
          title: 'contracts' ,
          path: '/contracts',
          children: [
            {
              title: 'dashboard',
              path: 'dashboard',
            },
            {
              title: 'dynamic deals',
              path: 'dynamicdeals',
            },
            
          ]
        },
        {
          title: 'reports',
          path: 'reports',
        },
      ];
      this.homeMenuChange = true

    }
    else if (location.path() !== '/admin/locations' && location.path() !== '/admin/materialMapping'
      && location.path() !== '/admin/desks' && location.path() !== '/admin/transport' && location.path() !== '/admin/parameters'
      && location.path() !== '/admin/parameters' && location.path() !== '/settings/alerts' && (!this.selectedDesk?.deskValue?.includes('clean products + latam'))) {
      this.NP.options.navigationRoutes = [
        {
          title: 'replenishment planning',
          path: 'replenishmentPlanning',
        },
        { 
          title: 'market elasticity',
          path: 'marketElasticity',
        },
        {
          title: 'nominations',
          path: 'nominations',
        },
        {
          title: 'bcp dashboard',
          path: 'bcpDashboard',
        },
        {
          title: 'contracts' ,
          path: '/contracts',
          children: [
               {
                   title: 'dashboard',
                   path: 'dashboard',
               },
            {
              title: 'dynamic deals',
              path: 'dynamicdeals',
            }
         ]
        },     
        {
          title: 'reports',
          path: 'reports',
        },
      ];
      this.homeMenuChange = false

}  else if (location.path() === '/settings/alerts') {
      this.NP.options.navigationRoutes = [
        {
          title: 'alerts & notifications',
          path: 'settings/alerts',
        },
      ];

    } else {
      this.NP.options.navigationRoutes = [

        {
          title: 'desks',
          path: 'admin/desks',
        },
        {
          title: 'locations',
          path: 'admin/locations',
        },
        {
          title: 'material mapping',
          path: 'admin/materialMapping',
        },
        {
          title: 'transport',
          path: 'admin/transport',
        },
        {
          title: 'inventory parameters',
          path: 'admin/parameters',
        },
      ];
    }
  }


  clearStateMgmtDataFromLocalStorage() {
    localStorage.removeItem("nomColData");
    localStorage.removeItem("trColExpandData");
    localStorage.removeItem("trColOrder");
  }

  toggleDropdownMenu(open: boolean) {
    this.isDropdownOpen = open;
  }
  toggleWhiteSpaceAction(event:any, updateSelectedDesk:any){
    if (updateSelectedDesk.deskValue === UDMDesk.PipelineUsecFloridaDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUsecColonialPlantationDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUsecPascagoulaDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUsecRichmondDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUsecTexasDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUswcElPasoDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUswcBayAreaDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUswcLADesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUswcPNWDesk || updateSelectedDesk.deskValue === UDMDesk.PipelineUswcSaltLakeCityDesk) {
      localStorage.setItem("compactViewToggleStatus", event.target.checked)
    }
    this.compactView = event.target.checked
    this.updateCompactView(this.compactView)
  }
  updateCompactView(compactViewStatus: boolean) {
    let value: any;

    if(compactViewStatus) {
      value = 'compact';
    } else {
      value= "normal";
    }
    const sizes = ['compact'];
    const el = document.querySelector<HTMLElement>('.ag-theme-alpine');
    sizes.forEach((size) => el?.classList?.toggle(size, size === value));
    this.store.dispatch(setPipelineCompactView({ pipelineCompactView: compactViewStatus, isPipelineCompact: true }));

  }
  toggleWhiteSpaceActionForBCP(event: any) {
    let value: any;
    this.compactViewBCP = event.target.checked
    if (event.target.checked) {
      value = 'compact';
    } else {
      value = "normal";
    }
    const sizes = ['compact'];
    const el = document.querySelector<HTMLElement>('.ag-theme-alpine')!;
    sizes?.forEach((size) => el.classList.toggle(size, size === value));
    this.store.dispatch(setBCPCompactView({ bcpCompactView: event.target.checked, isBCPCompact: this.compactViewBCP }));
  }

  toggleDeleteLocationModal(): void {
    const modal = { open: !this.deleteLocationModal.open, modal: 'deleteLocation' }
    this.deleteLocationModal = { ...modal};
  }

  closeDeleteLocationModal(){
    const modal = { open: false, modal: 'deleteLocation' }
    this.deleteLocationModal = { ...modal};
  }

  getAllTheViews() {
    if ((this.apiCallCount === 1 || this.allowGetApiCall) && (this.selectedDeskOption !== "" && (this.path$.value == "/bcpDashboard" || this.selectedDeskOption !== 'panama')) && (this.path$.value !== '/contract-balancing') ) {
      this.allowGetApiCall = false;
      const screenType = (this.currentUrl && this.currentUrl == "/bcpDashboard") ? TRScreen.BCPDashboard : '';
      this.nominationService.getAllViews({ emailId: this.userDetails.email, desk: this.utilities.updateAPIParams() }, screenType).subscribe(res => {
       
        if (res.value.length && res.statusCode === 200) {
          this.allowGetApiCall = false;
          this.viewsList = [];
          res.value.map((view: any) => {
            this.viewsList.push({ name: view.viewName, applied: false, isDefaultView:view.isDefaultView ?? false });
          });
        }
        else {
          this.viewsList = [];
        }
        if(!this.isCreateUpdateView && !localStorage.getItem("selectedView") && (this.selectedDeskOption === 'ethanol + uswc' || this.selectedDeskOption === "ethanol + usec" || this.selectedDeskOption?.includes('clean products') || (this.selectedUDMScreen.toLowerCase() == TRScreen.BCPDashboard && this.selectedDeskOption?.includes(UDMDesk.Panama))))
          this.applyDefaultView(); 
      })
    }
  }

  getAlertRulesData() {
    this.store.dispatch(showHideDeletedRules({ triggeredDeletedRules: this.isDeletedRules }));
    this.expandedMode$.next(false);
    this.collpaseMode$.next(true);
  }

  resetToDefaultView() {
    this.nominationService.setSelectedView = "default view";
    this.nominationService.triggerClearFilter$.next(true);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.resetToDefaultView$.next(true);
    localStorage.removeItem("selectedView");
    localStorage.removeItem("nomColData");
    localStorage.removeItem("trColExpandData");
    localStorage.removeItem("trColOrder");
    this.selectedCustomView = "default view";
  }

  applyDefaultView() {
    let defaultView = this.viewsList.find((view: { name: string; isDefaultView: boolean }) => view.isDefaultView === true);
    if (defaultView) {
      this.selectedCustomView = defaultView.name;
      window.localStorage.setItem("selectedDefaultView", defaultView.name);
      this.setSelectedView(defaultView);
    }
    else {
      this.nominationService.setSelectedView = "default view";
      this.selectedCustomView = "default view";
      if (!localStorage.getItem("selectedView")) {
        this.nominationService.applyDefaultCustomView$.next(true);
      }
    }
  }
  setSelectedView(view: any) {
    this.nominationService.setSelectedView = view.name;
    localStorage.setItem("selectedView", view.name);
    window.localStorage.setItem("selectedView", view.name);
    if (this.path$.value === '/replenishmentPlanning') {
      this.replenishmentService.applyCustomView$.next(view.name);
    }
    else if (this.path$.value === '/nominations') {
      this.nominationService.applyCustomView$.next(view.name);
    }
    else if (this.path$.value === '/bcpDashboard'){
      this.nominationService.applyCustomView$.next(view.name);
    }
    this.selectedCustomView = view.name;
    this.nominationService.setSelectedView = view.name;
    window.localStorage.setItem("selectedView", view.name);
    this.showViewsDropdown = false;
    this.nominationService.getallCustomViewFromHome$.next(this.viewsList);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
  }

  toggleViewDropdown() {
    this.showViewsDropdown = !this.showViewsDropdown;
  }
  toggleNomCargoDropdown()
  {
    this.showNomCargoDropdown = !this.showNomCargoDropdown;
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  toggleFilterPanel() {
    this.isFilterPanelClosed = !this.isFilterPanelClosed;
    this.nominationService.sidePanel$.next(false);
  }

  toggleCreateNominationPanel() {

    if(this.nominationService.sidePanel$.value !== true){
      this.nominationService.sidePanel$.next(true);
      this.nominationService.restrictLoader$.next(true);
    }
  }

  toggleExpandCollpaseAction(action: string) {
    if (action === 'expand') {
      this.expandedMode$.next(true);
      this.collpaseMode$.next(false);
    }
    else {
      this.expandedMode$.next(false);
      this.collpaseMode$.next(true);
    }
    this.utilities.sendActionToChild$.next(action);

  }
  toggleAdminCollapseAction(action: string) {
    if (action === 'expand') {
      this.adminExpandMode$.next(true);
      this.adminCollapseMode$.next(false);
    }
    else {
      this.adminExpandMode$.next(false);
      this.adminCollapseMode$.next(true);
    }
  }

  confirmDeleteView(action: string) {
    if (action === 'yes') {
      this.nominationService.triggerDeleteflag = true;
      this.nominationService.triggerDeleteApi$.next({ deleteView: true, payload: this.deleteViewDetails });
      this.showDeleteConfirmation = false;
    }
    else {
      this.nominationService.triggerDeleteflag = false;
      this.showDeleteConfirmation = false;
    }
  }

  confirmSaveView(action: string) {
    if (action === 'yes') {
      this.showSaveConfirmation = false;
      this.saveAdminSettings();
      this.closeDeleteLocationModal();
    }
    else {
      this.showSaveConfirmation = false;
      this.closeDeleteLocationModal();
    }
  }

  getFilterPanelAction(action: boolean) {
    this.isFilterPanelClosed = action;
  }

  save(): void {
    this.store.dispatch(triggerTRSave({ triggeredTRDataSave: true, isTRSaveEnabled: true, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
    this.store.dispatch(
      setClearFiltersBoolean({ isClearFilter: false }));
  }

  saveTankParams(): void {
    this.store.dispatch(triggerTankParamSave({ triggeredTankParamDataSave: true, isTankParamSaveEnabled: true }));
    this.expandedMode$.next(false);
    this.collpaseMode$.next(true);
  }

  saveNotifParams(): void {
    this.store.dispatch(triggerNotifParamSave({ triggeredNotifParamDataSave: true, isNotifParamSaveEnabled: true }));
    this.alertSaveService.clearSettings();
    this.expandedMode$.next(false);
    this.collpaseMode$.next(true);
  }

  saveAdminSettings(): void {
    this.store.dispatch(triggerAdminSettingsSave({ triggeredAdminSettingsSave: true, isAdminSettingsSaveEnabled: true }));
  }

  saveUpdatedNominations() {    
    if (this.selectedDeskOption === "panama") {
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'save',
        type: '',
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} nomination`, {category:"Nomination"})
      this.utilities.addUnsubscribableList(modalPopupAction);
    } else if (this.selectedDeskOption === "ethanol + uswc" || this.selectedDeskOption === "ethanol + usec") {
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'saveEthanolNominations',
        type: '',
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
        this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} nomination`, {category:"Nomination"})
    }
    else if (this.selectedDeskOption.includes('clean products + latam')) {
        
        if (this.router.url === '/cargoPlanning') {
          const selectedTabFunction = {
            tab: this.router.url,
            function: 'saveCargoPlanningCca',
            type: '',
          };
          const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
          this.utilities.addUnsubscribableList(modalPopupAction);
          this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} cargo plan`, {category:"Cargo Planning"});
        } else if (this.router.url === '/nominations') {
          const selectedTabFunction = {
            tab: this.router.url,
            function: "saveCCAData",
            type: "",
          };
          const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
          this.utilities.addUnsubscribableList(publishOrDelete);
          this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} nomination`, {category:"Nomination"});
        }
      } else if (this.selectedDeskOption?.includes('clean products')) {
        if (this.nominationService?.isCargoScreen?.value) {
          const selectedTabFunction = {
            tab: this.router.url,
            function: 'saveCargoPlanning',
            type: '',
          };
          const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
          this.utilities.addUnsubscribableList(modalPopupAction);
        } else {
          const selectedTabFunction = {
            tab: this.router.url,
            function: 'savePipelineNominations',
            type: '',
          };
          const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
          this.utilities.addUnsubscribableList(modalPopupAction);
          this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} nomination`, {category:"Nomination"})
      }
        }
     else if (this.selectedDeskOption?.includes('refinery')) {
        const selectedTabFunction = {
          tab: this.router.url,
          function: 'saveRefineryNominations',
          type: '',
        };
        const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(modalPopupAction);
          this.myMonitoringService.logEvent(`Save ${this.selectedDeskOption} nomination`, {category:"Nomination"})
    }
  }

  linkToExistingNom(){
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'linkToExistingNom',
      type: '',
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleCreateTerminalNominationsModal(mot?: any): void {
    this.nominationService.isModalPopupOpen$.next(false);
    let selectedTabFunction: any;
    if (this.selectedDeskOption === 'panama' || (this.isCCAPanamaView && mot === 'from scratch - in-tank')) {
      selectedTabFunction = {
        tab: this.router.url,
        function: 'createTerminalNomination',
        type: '',
      };
    }
    else if (this.selectedDeskOption.includes('clean products + latam')) {
      if(this.inCpTab) {
        selectedTabFunction = {
          tab: this.router.url,
          function: 'createCcaCargo',
          type: '',
        };
      } else {
        selectedTabFunction = {
          tab: this.router.url,
          function: 'createCcaNomination',
          type: '',
        };
      }
    }
    else if (this.selectedDeskOption.includes('clean products')) {
      this.nominationService?.createNominationForPipelineActuation?.next(true);
      selectedTabFunction = {
        tab: this.router.url,
        function: 'createPipelineNomination',
        type: '',
      };
      this.nominationService.triggerMasterCargoAPI.next(true);
    } else if (this.selectedDeskOption === 'ethanol + uswc' || this.selectedDeskOption === "ethanol + usec") {
      selectedTabFunction = {
        tab: this.router.url,
        function: 'ethanolCreateNominationModal',
        type: '',
      };
    }
    else if (this.selectedDeskOption.includes('refinery')) {
      this.nominationService?.createNominationForPipelineActuation?.next(true);
      selectedTabFunction = {
        tab: this.router.url,
        function: 'createRefineryNomination',
        type: '',
      };
    }

    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleCreateAlertButton(data: any) {
    let button = document.getElementById('create_alert_btn') as HTMLButtonElement;
    button.disabled = data;
  }

  toggleCreateAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'createAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
    this.alertsService.changeEditStatus(this.allowUpdate);
  }

  toggleUserVacationModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'userVacation',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleDenyUpdateAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'denyUpdateAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleDeleteAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'deleteAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleDenyDeleteAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'denyDeleteAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleRestoreDeletedModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'restoreDeleteAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleSubscriberAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'subscriberAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  toggleSaveAlertModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'saveAlert',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  duplicateCargoWithReference(): void {
    let selectedTabFunction: any;

    selectedTabFunction =  {
      tab: this.router.url,
      function: 'duplicateCargoWithReference',
      type: '',
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }
  toggleCreateNominationWithReference(): void {
    this.nominationService.isModalPopupOpen$.next(false);
    console.log(this.selectedDeskOption);
    let isEthanol = this.selectedDeskOption === 'ethanol + uswc' || this.selectedDeskOption === 'ethanol + usec';
    let isEnabled;
    if (this.selectedDeskOption === 'panama') {
      isEnabled = this.nominationService.enableHeaderButton$.value.deleteButton;
    }
    else if (isEthanol) {
      isEnabled = this.nominationService.motCheck$.value;
    }
    else if (this.selectedDeskOption?.includes('clean products') || this.selectedDeskOption?.includes('refinery')) {

      this.nominationService?.createNominationForPipelineActuation?.next(true);
      isEnabled = this.nominationService.nomSelected$.value;
    }
    if (isEnabled) {
      let selectedTabFunction: any;
      if (this.selectedDeskOption === 'panama') {
        selectedTabFunction = {
          tab: this.router.url,
          function: 'createNominationWithReference',
          type: '',
        }
      } else if (isEthanol) {
        selectedTabFunction = {
          tab: this.router.url,
          function: 'ethanolCreateNominationWithReference',
          type: '',
          values: undefined,
        }
      }
      else if (this.selectedDeskOption.includes('clean products + latam')) {
        this.isCargoDesks=this.router.url === '/cargoPlanning';
        this.cpService.isEditCargo = false;
        // if(this.isCargoDesks){
        selectedTabFunction = {
          tab: this.router.url,
          function: 'CcaCreateNominationWithReference',
          type: '',
        };
        // }
      }
      else if (this.selectedDeskOption?.includes('clean products') || this.selectedDeskOption?.includes('refinery')) {
        this.nominationService.getSearchNominationAFAPI$.subscribe((res)=>{
          if(res){

            selectedTabFunction = {
              tab: this.router.url,
              type: 'group',
              function: 'pipelineCreateNominationWithReferenceSearch',
              desk: this.selectedDeskOption,
              values: undefined,
              editNomination: false
            };

          }
          else {
            selectedTabFunction = this.elseCleanProduct();
          }
        })



      }
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }
  }

  elseCleanProduct(){
    if(this.isCargoDesks){
      return  {
        tab: this.router.url,
        function: 'pipelineCreateNominationWithReference',
        type: '',
      };
    } else{
      return  {
        tab: this.router.url,
        type: 'group',
        function: 'pipelineCreateNominationWithReferenceNonCP',
        desk: this.selectedDeskOption,
        values: undefined,
        editNomination: false
      };
    }
  }

  checkEventTypeForDeleteAndPublish(event: any) {
    if (event === 'delete' && this.router.url !== '/cargoPlanning') {
      this.data.popupAction(event);
      this.mdmService.invokeToggleMethod(true);
      this.mdmService.actionConfirmed$.subscribe((actionConfirmed) => {
        if (actionConfirmed) {
          if(event !== ''){
            this.publishOrDeleteData(event);
            this.mdmService.actionConfirm(false);
            this.mdmService.invokeToggleMethod(false);
            event = '';
          }
          
        }
      });
    }
    else {
      this.publishOrDeleteData(event);
    }
  }

  publishOrDeleteData(event: any) {
    let action = event === 'delete' ? 'Delete' : 'Publish';
    if (this.selectedDeskOption === "panama") {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `${event}RowData`,
        type: event,
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} nomination`, {category:"Nomination"})
    } else if (this.selectedDeskOption === "ethanol + uswc" || this.selectedDeskOption === "ethanol + usec") {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `${event}EthanolRowData`,
        type: event,
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} nomination`, {category:"Nomination"})
    } else if (this.selectedDeskOption.includes('clean products + latam')) {

      if (this.router.url === '/cargoPlanning') {
        const selectedTabFunction = {
          tab: this.router.url,
          function: `${event}CargoPlanningCca`,
          type: '',
        };
        const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(modalPopupAction);
        this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} cargo plan`, {category:"Cargo Planning"})
      } else {
        const selectedTabFunction = {
          tab: this.router.url,
          function: `${event}CCARowData`,
          type: event,
        };
        const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(publishOrDelete);
        this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} nomination`, {category:"Nomination"})
      }
    } else if (this.selectedDeskOption?.includes('clean products')) {
      if (this.nominationService?.isCargoScreen?.value) {
        const selectedTabFunction = {
          tab: this.router.url,
          function: `${event}CargoRowData`,
          type: event,
        };
        const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(publishOrDelete);
      } else {
        const selectedTabFunction = {
          tab: this.router.url,
          function: `${event}PipelineRowData`,
          type: event,
        };
        const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(publishOrDelete);
        this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} nomination`, {category:"Nomination"})
      }
    } else if (this.selectedDeskOption?.includes('refinery')) {
        const selectedTabFunction = {
          tab: this.router.url,
          function: `${event}RefineryRowData`,
          type: event,
        };
        const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(publishOrDelete);
        this.myMonitoringService.logEvent(`${action} ${this.selectedDeskOption} nomination`, {category:"Nomination"})
    }
  }

  toggleAddNewDeskModal(): void {
    let selectedTabFunction: any;
    selectedTabFunction = {
      tab: this.router.url,
      function: 'addNewDesk',
      type: '',
    };
    const modalPopupAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  downloadBcpReport(): void {
    const selectedTabFunction = {
      tab: this.router.url,
      function: `downloadBcpReport`,
      type: '',
    };
    const downloadReport = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(downloadReport);
  }

  triggerVendorPreview() {
    let selectedNom = this.nominationService.selectedNom$.value;
    let truckNoms = selectedNom.every((eachRow: any) => {
      return eachRow?.modeOfTransportDesc?.toLowerCase() === 'truck' && (eachRow?.sys_status?.toLowerCase() === "unpublished" || eachRow?.sys_status?.toLowerCase() === "published");
    })
    if ((this.nominationService.isSelectedPublished$.value || truckNoms) || (this.selectedDeskOption?.includes('pipeline')) || (this.selectedDeskOption === this.PipelineUsecFloridaDesk || this.selectedDeskOption === this.PipelineUsecRichmondDesk ||this.selectedDeskOption === this.PipelineUsecpascagoulaDesk || this.selectedDeskOption === this.PipelineUswcPNWDesk || this.selectedDeskOption === this.CleanProductsEccaDesk || this.selectedDeskOption  === this.CleanProductsWccaDesk ) ) {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `previewVendor`,
        type: ''
      };
      const preview = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(preview);
    }
    else {
      this.updateIsSelectedPublished();
    }
  }

  updateIsSelectedPublished(){
    if (!this.nominationService.isSelectedPublished$.value) {
      this.toastSr.setToastNotification({ show: true, type: 'error', msg: "Please select the published nomination" });
    }
  }

  triggerPushToApplication() {
    const selectedTabFunction = {
      tab: this.router.url,
      function: `openPushToApplication`,
      type: ''
    };
    const preview = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(preview);
  }

  triggerDeleteNomination() {
    const selectedTabFunction = {
      tab: this.router.url,
      function: `openCargoDelete`,
      type: ''
    };
    const preview = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(preview);
  }

  toggleView(viewType: string) {
    this.selectedView = viewType;
    if (viewType === 'gant') {
      this.store.dispatch(setEnableTableView({ isTableView: false }))
      this.store.dispatch(saveTRView({ selectedTRView: TRViewConst.Gant }));
      this.store.dispatch(
        setClearFiltersBoolean({ isClearFilter: false }));
    }
    if (viewType === 'table') {
      this.store.dispatch(setEnableTableView({ isTableView: true }))
      this.store.dispatch(saveTRView({ selectedTRView: TRViewConst.Table }));
      this.store.dispatch(
        setClearFiltersBoolean({ isClearFilter: false }));
    }
    if (viewType === 'summary') {
      this.store.dispatch(setBCPView({ BCPView: 'summary' }))
      this.store.dispatch(saveBCPView({ selectedBCPView: BCPView.Summary }));
      this.store.dispatch(
        setClearFiltersBoolean({ isClearFilter: false }));
    }
    if (viewType === 'terminal') {
      this.store.dispatch(setBCPView({ BCPView: 'terminal' }))
      this.store.dispatch(saveBCPView({ selectedBCPView: BCPView.Terminal }));
      this.store.dispatch(
        setClearFiltersBoolean({ isClearFilter: false }));
    }
    if (viewType === 'map') {
      this.store.dispatch(setBCPView({ BCPView: 'map' }))
      this.store.dispatch(saveBCPView({ selectedBCPView: BCPView.Map }));
      this.store.dispatch(
        setClearFiltersBoolean({ isClearFilter: false }));
    }
  }

  togglebuttonSwitch(buttonSwitch: any) {
    let combinedButtonSwitch: string = '';
    if(buttonSwitch.deskParameter) {
      if(buttonSwitch.deskParameter == 'ethanolusec' || buttonSwitch.deskParameter == 'ethanoluswc'){
        if(this.currentSwitch){
          buttonSwitch = this.currentSwitch.split('_')[0];
          this.nominationService.setCustomDropDownValue$.next(buttonSwitch);   
        }  
      }
    }
    switch (buttonSwitch) {
      case 'terminal':
        combinedButtonSwitch = 'terminal_supplier_nomination_carrier'
        break;
      case 'supplier':
        combinedButtonSwitch = 'supplier_terminal_nomination_carrier'
        break;
      case 'carrier':
        combinedButtonSwitch = 'carrier_terminal_nomination_supplier'
        break;
      default:
        break;
    }
    if (combinedButtonSwitch && this.currentSwitch !== combinedButtonSwitch) {
      this.store.dispatch(setSwitchView({ switchView: combinedButtonSwitch, isSwitchUpdated: true }));
    }

  }

  getSelectedDesk(desk: any): void {
    this.checkIfDeskChanged = {...desk};
    let cleanProddesk = desk?.deskValue?.includes('clean product')
    let cleanProdSeelctdesk = this.selectedDesk?.deskValue?.includes('clean product')
    let ethanldesk = desk?.deskValue?.includes('ethanol')
      let ethanlSeelctdesk =this.selectedDesk?.deskValue?.includes('ethanol')
    let panadesk = desk?.deskValue?.includes('panama')
    let panamaSeelctdesk = this.selectedDesk?.deskValue?.includes('panama')

      if(ethanlSeelctdesk && (panadesk||cleanProddesk) || (panamaSeelctdesk && (ethanldesk||cleanProddesk) )||( cleanProdSeelctdesk && (ethanldesk||panadesk)) ) {
      this.compactView = false;
      this.store.dispatch(setPipelineCompactView({ pipelineCompactView: this.compactView, isPipelineCompact: false }));
    }
    else {
      this.compactView = localStorage.getItem("compactViewToggleStatus") === "true";
      this.store.dispatch(setPipelineCompactView({ pipelineCompactView: this.compactView, isPipelineCompact: this.compactView }));
    }
    this.selectedDesk = desk;
    this.isCombinedView = Number(this.selectedDesk.id) === 25 && this.selectedDesk.deskParameter === 'alldesk';
    this.setHomeMenu(this.location);
    this.nominationService.getSearchNominationAFAPI$.next(false)
  }
  

  getAppliedFilters(filters: any) {
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'applyFilters',
      type: '',
      values: filters,
    };
    const applyFiltersAction =
      this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(applyFiltersAction);
  }
  updateIsRpScreen(){
    /**While navigating from nominations to  replenishmentPlanning*/

    this.compactView = localStorage.getItem("compactViewToggleStatus") === "true";
    this.store.dispatch(setPipelineCompactView({ pipelineCompactView: this.compactView, isPipelineCompact: true }));
     let event = {target: {checked : this.compactView}}
    this.toggleWhiteSpaceAction(event, this.selectedDesk)
    this.appStore.dispatch(setUDMScreen({ screen: TRScreen.ReplenishmentPlanning, receivedTotalFiltersResponse: false }));
     if ((!this.selectedDeskOption?.includes('clean products')&&!this.selectedDeskOption?.includes('clean products + latam'))) {
      this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: true, receivedUpdatedFilterSelection: false, isTRGrid: true }));
    } else if (this.isCCAPanamaView) {
      this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true, isTRGrid: false }));
      this.filterPanel?.getLocationFilters();
    } else {
      this.filterPanel?.getLatestPipelineFilters();
    }
  }
  updateIsBCPScreen() {
    /**While navigating to  BCP*/
    let event = { target: { checked: this.compactViewBCP } }
    this.toggleWhiteSpaceActionForBCP(event);
    this.appStore.dispatch(setUDMScreen({ screen: TRScreen.BCPDashboard, receivedTotalFiltersResponse: true }));
  }
  updateMktElsScreen() {
    this.filterPanel?.getPipelineFilters();
  }
  onClickNavChange(event: any) {
    if (this.selectedDesk?.deskValue?.includes("clean products + latam")) {
      this.nominationService.nomSelected$.next(false);
      if (event.target.innerHTML === "cargo planning") {
        this.store.dispatch(saveCargoFiltersCCA({ cargoCCAFilters: this.cpService?.cargoCCAFilters?.filterValues, cargoCCAStartDate: this.cpService?.cargoCCAFilters?.fromDate, cargoCCAEndDate: this.cpService?.cargoCCAFilters?.toDate, isCargoCCAUpdated: true }));
      }
       if(this.isCombinedView && (event.target.innerText === "nominations"|| event.target.innerText === "replenishment planning") ){
        this.toastSr.setToastNotification({show: true, type: 'error', msg: "Nomination and Replenishment Planning screens cannot be accessed in all desks"})
      }
    }
    this.nominationService.getSearchNominationAFAPI$.next(false);
    if (event?.target?.innerHTML?.toLowerCase() === 'nominations' && this.selectedDeskOption?.includes('clean products')) {
      localStorage.setItem("slectedTab", "nomination");
      this.nominationService.isCargoScreen.next(false);
      this.nominationService.isNominationScreen = "nomination";
      this.createCargo = false;
      this.cpNominations$.next(true);
      this.cpCargoPlanning$.next(false);
      this.inCpTab = false;
      this.nominationService?.createNominationForPipelineActuation?.next(false);
      this.nominationService.sidePanel$.next(false);
    }
    if (event?.target) {
      this.nominationService.customViewSidePanel$.next(false);
      this.nominationService.createCustomView$.next(false);
      if (event?.target?.innerHTML?.toLowerCase() === 'replenishment planning') {
        this.updateIsRpScreen();
                  this.nominationService.sidePanel$.next(false); 
      } else if (event?.target?.innerHTML?.toLowerCase() === 'nominations') {
        // /**While navigating from replenishmentPlanning to nominations*/
        this.navFromNomToRP(event); 
        this.nominationService.sidePanel$.next(false);
        this.linkNomData = []

      }
      else if (event?.target?.innerHTML?.toLowerCase() === 'cargo planning') {
        // /**While navigating from replenishmentPlanning to cargo planning*/
        this.navFromCP(event);
        this.nominationService.sidePanel$.next(false);
      }else if(event.target.innerHTML.toLowerCase() === 'contract balancing'){
        this.appStore.dispatch(setUDMScreen({ screen: TRScreen.CB, receivedTotalFiltersResponse: false }));
      } else if (event.target.innerHTML.toLowerCase() === 'bcp dashboard') {
        this.updateIsBCPScreen();  
      } else if (event.target.innerHTML.toLowerCase() === "market elasticity") {
        this.updateMktElsScreen();
      }
    }
  }
  navFromCP(event: any){
    this.appStore.dispatch(setUDMScreen({ screen: TRScreen.CargoPlanning, receivedTotalFiltersResponse: false }));
    this.filterPanel.getLatestPipelineFilters();
  }
  navFromNomToRP(event: any){
    this.nominationService.restrictNomApiGetCall$.next(true);
    this.appStore.dispatch(setUDMScreen({ screen: TRScreen.Nominations, receivedTotalFiltersResponse: false }));
    if ((!this.selectedDeskOption?.includes('clean products')&& this.selectedDeskOption?.includes('clean products + latam')) || this.isCCAPanamaView) {
      this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: true, receivedUpdatedFilterSelection: false, isTRGrid: false }));
    }
    if (this.selectedDeskOption === 'panama' || this.isCCAPanamaView) {
      this.store.dispatch(updatedOfftakersFalse({ updatedOfftakers: false }));
    } else if (this.selectedDeskOption === 'ethanol + uswc' || this.selectedDeskOption === 'ethanol + usec') {
      this.appStore.dispatch(saveEthanolNomFiltersUpdated({ isNomFilterUpdated: true }));
    } else if (this.selectedDeskOption?.includes('clean products')|| this.selectedDeskOption?.includes('clean products + latam')|| this.selectedDeskOption?.includes('refinery')) {
      this.filterPanel.getLatestPipelineFilters();
    } else if (event.target.innerHTML.toLowerCase() === 'bcp dashboard') {
      this.appStore.dispatch(setUDMScreen({ screen: TRScreen.BCPDashboard, receivedTotalFiltersResponse: true }));
      this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: true, receivedUpdatedFilterSelection: false, isTRGrid: false }));
    }
  }

  allGroupActionClick(action: boolean) {
    this.homeService.setAllGroupAction(action)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterData(event: any) {
    const updatedCBHeaderCheckboxes = JSON.parse(JSON.stringify(this.cbHeaderCheckboxes));
    updatedCBHeaderCheckboxes.forEach((value: any) => {
      if (value.name === event.target.value) {
        value.checked = event.target.checked;
        value.checked ? this.cbHeaderCheckedCount++ : this.cbHeaderCheckedCount--;
      }
    });
    this.store.dispatch(updateCBHeaderCheckboxesState({ cbHeaderCheckboxes: updatedCBHeaderCheckboxes, cbHeaderCheckedCount: this.cbHeaderCheckedCount }));
    const updatedCBHeaderCheckbox = event?.target?.value?.toLowerCase();
    switch (updatedCBHeaderCheckbox) {
      case 'contract details table':
        this.store.dispatch(updateRefreshTableFlag({ refreshTableFlag: { refreshContractDetailsTable: event.target.checked } }));
        break;
      case 'mtd tracking':
        this.store.dispatch(updateRefreshTableFlag({ refreshTableFlag: { refreshMTDTrackingTable: event.target.checked } }));
        break;
      case 'monthly performance review':
        this.store.dispatch(updateRefreshTableFlag({ refreshTableFlag: { refreshMPRTable: event.target.checked } }));
        break;
      case 'deal performance review':
        this.store.dispatch(updateRefreshTableFlag({ refreshTableFlag: { refreshDPRTable: event.target.checked } }));
        break;
      default:
        break;
    }
  }

  getList(terminaltemp: any, supplierTemp: any, carrierTemp: any) {
    let list = [
      { name: "terminal", dataContent: terminaltemp, selected: true },
      { name: "supplier", dataContent: supplierTemp },
      { name: "carrier", dataContent: carrierTemp }
    ]
    return list
  }

  getOriginProductList(originProductTemp: any) {
    let list = [
      { name: "origin", dataContent: originProductTemp, selected: true }
    ]
    return list
  }

  getDeskList(response: any) {
    this.deskListResponse = response;
  }

  getPipelinePivotViews(pipelineProductTemp: any, pipelineTerminalTemp: any) {
    let list = [
      { name: "product", dataContent: pipelineProductTemp, selected: (!this.currentPipelineSwitch || this.currentPipelineSwitch === 'product_terminal_nomination') },
      { name: "terminal", dataContent: pipelineTerminalTemp, selected: ( this.currentPipelineSwitch === 'terminal_product_nomination') },
    ]
    return list
  }

  getBcpPivotViews(bcpProductTemp: any, bcpTerminalTemp: any) {
    let list = [
      { name: "product", dataContent: bcpProductTemp, selected: (!this.currentBcpSwitch || this.currentBcpSwitch === 'product_terminal') },
      { name: "terminal", dataContent: bcpTerminalTemp, selected: ( this.currentBcpSwitch === 'terminal_product') },
    ]
    return list
  }

  togglePipelineButtonSwitch(buttonSwitch: any) {
    let combinedButtonSwitch: string = '';
    switch (buttonSwitch) {
      case 'product':
        combinedButtonSwitch = 'product_terminal_nomination'
        break;
      case 'terminal':
        combinedButtonSwitch = 'terminal_product_nomination'
        break;
      default:
        break;
    }
    if (combinedButtonSwitch && this.currentPipelineSwitch !== combinedButtonSwitch) {
      this.store.dispatch(setPipelineSwitchView({ pipelineSwitchView: combinedButtonSwitch, isPipelineSwitchUpdated: true }));
    }
  }

  toggleBcpButtonSwitch(buttonSwitch: any) {
    let combinedButtonSwitch: string = '';
    switch (buttonSwitch) {
      case 'product':
        combinedButtonSwitch = 'product_terminal'
        break;
      case 'terminal':
        combinedButtonSwitch = 'terminal_product'
        break;
      default:
        break;
    }
    if (combinedButtonSwitch && this.currentBcpSwitch !== combinedButtonSwitch) {
      this.store.dispatch(setBcpSwitchView({ bcpSwitchView: combinedButtonSwitch, isBcpSwitchUpdated: true }));
    }
  }

  openCustomViewAccordion(event: any) {
    this.nominationService.customViewSidePanel$.next(true);
    this.showViewsDropdown = false
    if (event.target.innerText === "create new") {
      this.nominationService.createCustomView$.next(true);
      this.nominationService.manageCustomView$.next(false);
    } else if (event.target.innerText === "manage custom views") {
      this.nominationService.manageCustomView$.next(true);
      this.nominationService.createCustomView$.next(false);
    }
  }
  openAccordionNomCargo(event: any) {
    this.toggleNomCargoDropdown();
    if (event.target.innerText === "from scratch" || event.target.innerText === "from scratch - in-tank" || event.target.innerText === "from scratch - other mots") {
      this.nominationService.ccaEditNomination = false
      this.toggleCreateTerminalNominationsModal(event.target.innerText);
      // open new popup for cargo create from scratch;
    } else if (event.target.innerText === "from reference") {
      this.toggleCreateNominationWithReference();
      // open new popup for cargo create from reff
    } else if(event.target.innerText === "duplicate"){
      this.duplicateCargoWithReference();
    }

  }

  toggleDismissModal() {
    const modal = { open: true, modal: 'dismissWorkspaceAlertModal' }
    this.dismissAlertModal = { ...modal };
  }

  closeDismissModal() {
    const modal = { open: false, modal: 'dismissWorkspaceAlertModal' }
    this.dismissAlertModal = { ...modal };
  }

  // Cargo Planning
  toggleCarogPlanning(event?:string){
    if (event === 'cpNomination') {
      localStorage.setItem("slectedTab","nomination");
      this.nominationService.isCargoScreen.next(false);
      this.nominationService.isNominationScreen = "nomination";
      this.createCargo = false;
      this.cpNominations$.next(true);
      this.cpCargoPlanning$.next(false);
      this.inCpTab = false;
      this.store.dispatch(initialisePipelineNomFilters({ isPipelineNomFilterInitialised : true }));
    }
    else if( event === 'cpCargoPlanning') {
      this.nominationService.isCargoScreen.next(true);
      this.nominationService.isNominationScreen = "cargo";
      this.createCargo = true;
      this.cpNominations$.next(false);
      this.cpCargoPlanning$.next(true);
      this.inCpTab = true;
      localStorage.setItem("slectedTab","cargo");
      this.store.dispatch(initialisePipelineNomFilters({ isPipelineNomFilterInitialised : true }));
      this.nominationService.getSearchNominationAFAPI$.next(false);
    }
  }

  redirectToInventoryParameters(){
    this.router.navigate(['/admin/parameters']);
  }

  saveEdit() {
    this.mktElsUtilities.notifyEditedDataPSD$.next({isSaveDisabled: false, isSubmitted: true})
  }

  getSelectedValues(event:any) {
    //consume event.firstDate and event.lastDate
    console.log('event.firstDate'+event.firstDate+ 'event.lastDate'+event.lastDate);
  }

  getIsCBGlobalSearchBarOpened(flagValue:boolean) {
    this.isCBGlobalSearchBarOpened = flagValue;
  }

}
