import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { CellValueChangedEvent, ColDef, FirstDataRenderedEvent,GridApi, GridReadyEvent, IAggFunc, IAggFuncParams, IRowNode, IsRowMaster, SelectionChangedEvent } from 'ag-grid-community';
import { CustomHeaderComponent } from '../../custom-header.component';
import { AutoCompleteComponent } from '../../autocomplete/autocomplete.component';
import { Store, select } from '@ngrx/store';
import { getUserDetails, selectAppliedTRFilters, selectDefaultTRFilters, selectDesk, selectPipelineNomFiltersResponse } from 'src/app/shared/store/selector/app.selector';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { Subject, Subscription, firstValueFrom, map, takeUntil, timeInterval } from 'rxjs';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { savePipelineNomFiltersUpdated, setReceivedResponseBoolean, updatedOfftakersFalse, setSelectedDesk, savePipelineNomFiltersResponse, saveAppliedTRFilters } from 'src/app/shared/store/action/app.action';
import { DatePipe } from '@angular/common';
import { MoreMenuNonCPComponent } from 'src/app/shared/modules/more-menu-nonCP/more-menu-nonCP.component';
import { CellInfoHoverComponent } from 'src/app/shared/modules/cell-info-hover/cell-info-hover.component';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { CreateNominationCcaComponent } from 'src/app/core/components/create-nomination-cca/create-nomination-cca.component';
import * as Nom from '../../../shared/constants/nominations.constant';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { ToastService } from 'src/app/services/toast.service';
import { invokeNominationData } from '../../store/action/nominations.action';
import { selectNominationList } from '../../store/selector/nomination.selector';
import { Router } from '@angular/router';
import { CreateTreminalNominationComponent } from 'src/app/core/components/create-treminal-nomination/create-treminal-nomination.component';
import { CargoPlanningCcaService } from 'src/app/services/cargo-planning-cca.service';
import { VendorNotificationPreviewComponent } from 'src/app/core/components/vendor-notification-preview/vendor-notification-preview.component';
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { MdmService } from 'src/app/services/mdm.service';

@Component({
  selector: 'app-nomination-cca-view',
  templateUrl: './nomination-cca-view.component.html',
  styleUrls: ['./nomination-cca-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NominationCcaViewComponent implements OnInit, OnDestroy {

  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: CustomHeaderComponent,
      autoComplete: AutoCompleteComponent
    };
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  userFullEmail: any;
  selectedDeskParameter: any;
  selectedDeskOption!: any;
  region: any;
  getCCAFilterResponse: any;
  getlinktoNomCCAResponse: any;
  getNomFilterResponse: any;
  public materialCode: any[] = [];
  public locationCodes: any[] = [];
  terminalFilterData!: any[];
  productFilterData!: any[];
  transportSystemFilterData!: any[];
  modeOfTransport!: any[];
  public rowData!: any[];
  rpFilterData : any = localStorage.getItem('sPNFR');
  filterListDataFromRP : any = JSON.parse(this.rpFilterData);
  CcaNominationModal = { open: false, modal: 'createCcaNomination' };
  pipelineNominationModal = { open: false, modal: 'createCcaNomination' };
  isCargo: any = false;
  isCcaNomEdit: boolean = false;
  modalTitle = "creating new cca nomination";
  differentHeaderId: any;
  isCCAPanamaView: boolean = false;
  isCCAView: boolean = false;
  terminalNominationModal = { open: false, modal: 'createTerminalNomination' };
  isIntankMot: any = false;
  trSave!: boolean;
  @ViewChild(CreateNominationCcaComponent) createNominationCcaComponent!: CreateNominationCcaComponent;
  @ViewChild(CreateTreminalNominationComponent) createTerminalNominationComponent!: CreateTreminalNominationComponent;
  selectedDefaultFilters$ = this.appStore.pipe(select(selectDefaultTRFilters));
  selectedAppliedFilters$ = this.appStore.pipe(select(selectAppliedTRFilters));
  nominationList$ = this.store.pipe(select(selectNominationList));
  updatedFilterSelection: any;
  nomOffTakerFilterResponse: any[] = [];
  vendorNotificationPreview = { open: false, modal: 'createPreviewForNomination' };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public sapContractDetails: any[] = [];
  public contractLineNumberList: any[] = [];
  private isPanamaAppliedApiCallInProgress = false;
  private isPanamaDefaultApiCallInProgress = false;
  private isCCAApiCallInProgress = false;
  private isApiCalled  = false;
  allowForGetCustomView: boolean = false;
  @ViewChild(VendorNotificationPreviewComponent) vendorPreviewNotificationComponent!: VendorNotificationPreviewComponent;
  autoGroupColumnDef = {
    headerName: 'nomination no',
    filter: 'agGroupColumnFilter',
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
      innerRenderer: CellInfoHoverComponent,
    }
  };
  public columnDefs: ColDef[] = [
    {
      field: '',
      headerName: 'actions',
      cellRendererFramework: MoreMenuNonCPComponent,
      cellRendererParams: (params: any) => ({
        girdParams: params,
        rowDataRecord: params?.node?.allLeafChildren?.[0]?.data,
        isIntankMot: params?.node?.allLeafChildren?.[0]?.data?.mot === 'TankTransfer' || params?.node?.allLeafChildren?.[0]?.data?.nominationType?.toUpperCase() === "IT"
      }),
    },
    {
      field: 'udmItemStatus',
      headerName: 'status',
      aggFunc: 'checkMultiple',
      cellClass: (params: any) => {
        if (params?.value?.toLowerCase() === "unpublished" || params?.value?.toLowerCase() === "multiple") {
          return "unpublished";
        } else if (params?.value?.toLowerCase() === "published") {
          return "published";
        } else {
          return "";
        }
      }
    },
    {
      field: 'nominationNo',
      headerName: 'nomination no.',
      wrapHeaderText: true,
      autoHeaderHeight: true,
      rowGroup: true,
      hide: true
    },
    {
      field: "partnerNominationNumber",
      headerName: "partner nom no",
      editable: (params: any) => params?.data,
      valueGetter: (params: any) => {
        let sameValue = false;
        if (params?.data?.headerToItemNav?.length > 1) {
          const headerToItemNav = params?.data?.headerToItemNav;
          for (let i = 0; i < headerToItemNav.length - 1; i++) {
            if (headerToItemNav[i]?.partnerNominationNumber === headerToItemNav[i + 1]?.partnerNominationNumber) {
              sameValue = true;
            } else {
              sameValue = false;
              break;
            }
          }
        } else {
          sameValue = true;
        }
        if (sameValue) {
          return params?.data?.partnerNominationNumber;
        } else {
          return "multiple";
        }
      },
    },
    {
      field: "contractPartner",
      headerName: "counterparty",
      editable: true,
      aggFunc: 'checkMultiple',
    },
    {
      field: 'vehicleName',
      headerName: 'vessel/barge/pipeline name',
      aggFunc: 'checkMultiple',
      editable: true,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          region: this.region,
          desk: this.selectedDeskOption,
          deskParameter: this.selectedDeskParameter,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.name}_(${data?.id})` : '';
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      },
    },
    {
      field: 'carrier',
      headerName: 'carrier',
      aggFunc: 'checkMultiple'
    },
    {
      field: 'nominationKey',
      headerName: 'nom key',
      aggFunc: 'checkMultiple'
    },
    {
      field: 'scheduledMaterialName',
      headerName: 'scheduled material',
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.name;
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: 'demandMaterialName',
      headerName: 'demand material',
      editable: (params: any) => params?.data,
      aggFunc: 'checkMultiple',
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.name;
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: 'transportSystem',
      headerName: 'transport system',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'scheduledQty',
      headerName: 'volume',
      aggFunc: 'originVolSum',
      editable: (params: any) => params?.data,
    },
    {
      field: 'actualPostedQuantity',
      headerName: 'actual volume'
    },
    {
      field: 'unitOfMeasurementName',
      headerName: 'unit of measure',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'udmLaycanStartDate',
      headerName: 'laycan start date',
      editable: true,
      cellEditor: DatePickerComponent
    },
    {
      field: 'scheduledDate',
      headerName: 'TSW schedule date',
      aggFunc: 'checkEarlyDate',
      editable: true,
      cellEditor: DatePickerComponent
    },
    {
      field: 'scheduledTime',
      headerName: 'arrival time',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'locationName',
      headerName: 'location',
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data,
      valueGetter: (params: any) => {
        let sameValue = false;
        if (params?.data?.headerToItemNav?.length > 1) {
          const headerToItemNav = params?.data?.headerToItemNav;
          for (let i = 0; i < headerToItemNav.length - 1; i++) {
            if (headerToItemNav[i]?.locationName === headerToItemNav[i + 1]?.locationName) {
              sameValue = true;
            } else {
              sameValue = false;
              break;
            }
          }
        } else {
          sameValue = true;
        }
        if (sameValue) {
          return params?.data?.locationName;
        } else {
          return "multiple";
        }
      },
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          rowData: this.locationCodes,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.name}_(${data?.value})` : '';
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: 'tswNominationType',
      headerName: 'nomination type',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'scheduleType',
      headerName: 'TSW nomination type',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'nominationReferenceDocument',
      headerName: 'sap contract no',
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data,
      // editable: true,
      cellClass: params => {
        return params?.data?.detailType?.length > 0 && params?.data?.sapContract ? 'detail-type-multiple subtitle-4 extra_width' : '';
      },
      cellRendererSelector: (params: any) => {
        const rowExpansion = {
          component: "agGroupCellRenderer",
        }
        if (params?.data?.detailType?.length > 0 && params?.data?.sapContract) {
          return rowExpansion;
        }
        return undefined;
      },
      cellEditorSelector: (params: any) => {
        if (params?.data?.mot === 'TankTransfer') {
          return {
            component: AutoCompleteComponent,
            params: {
              propertyRendered: 'sapContractNumber',
              showedColumn: 'sapContractNumber',
              rowData: this.sapContractDetails,
              columnDefs: [
                {
                  filter: 'agTextColumnFilter',
                  field: 'sapContractNumber',
                  valueFormatter: (event: any) => {
                    let data = event.data;
                    return data.sapContractNumber;
                  },
                },
                {
                  field: 'sapContractNumber',
                  filter: 'agTextColumnFilter',
                  hide: true,
                },
              ],
              popup: true,
            }
          }

        } else return { component: 'agTextCellEditor' }
      },
    },
    {
      field: 'nominationReferenceDocumentItem',
      headerName: 'contract line item no',
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data?.nominationReferenceDocument && params?.data,
      // editable: true,
      cellEditorSelector: (params: any) => {
        if (params?.data?.mot === 'TankTransfer') {
          return {
            component: AutoCompleteComponent,
            params: {
              propertyRendered: 'contractLineItem',
              showedColumn: 'contractLineItem',
              rowData: this.setContractLineNoBasedOnSapContractNo(params.data),
              columnDefs: [
                {
                  filter: 'agTextColumnFilter',
                  field: 'contractLineItem',
                  valueFormatter: (event: any) => {
                    let data = event.data;
                    return data.Name;
                  },
                },
                {
                  field: 'contractLineItem',
                  filter: 'agTextColumnFilter',
                  hide: true,
                },
              ],
              popup: true,
            }
          }
        } else return {
          component: AutoCompleteComponent,
          params: {
            propertyRendered: 'contractLineNumber',
            showedColumn: 'contractName',
            region: this.region,
            desk: this.selectedDeskOption,
            deskParameter: this.selectedDeskParameter,
            rowData: {},
            columnDefs: [
              {
                filter: 'agTextColumnFilter',
                field: 'contractName',
                valueFormatter: (event: any) => {
                  let data = event.data;
                  return Object.keys(data).length > 0 ? `${data?.contractLineNumber} | ${data?.sraDealDetailReferenceNumber} | ${data?.location} | ${data?.modeOfTransportDescription} | ${data?.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}` : '';
                },
              },
              {
                field: 'contractName',
                filter: 'agTextColumnFilter',
                hide: true,
              },
            ],
            popup: true,
          }
        }
      }
    },
    {
      field: 'referenceDocumentIndicator',
      headerName: 'ref doc id',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'nominationItemStatus',
      headerName: 'TSW status',
      aggFunc: 'checkMultiple',
    },
    {
      field: 'udmComments',
      headerName: 'comments',
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data
    },
    {
      field: 'createdBy',
      headerName: 'createdBy',
      hide: true
    },
    {
      field: 'confirmationIndicator',
      headerName: 'Confirm',
      editable: true,
      cellRenderer: (params: any) => {
        if (!params?.node?.group) {
          const input = document.createElement('input');
          input.type = 'checkbox';
          input.checked = params.value;
          input.addEventListener('change', (event: any) => {
            params.node.setDataValue(params.colDef.field, event.target.checked ? 'X' : "");
          });
          return input;
        }
        return null;
      },
      valueGetter: (params: any) => {
        return params?.data?.nominationItemStatus === "4" || params?.data?.confirmationIndicator === "X";
      },
      cellClassRules: {
        'disable-option disable-option-with-opacity': (params: any) => !params?.node?.group && (params?.data?.nominationItemStatus === "5" || params?.data?.nominationItemStatus === "4")
      },
    }
  ];
  public groupDefaultExpanded = 1;
  public aggFuncs: {
    [key: string]: IAggFunc;
  } = {
      checkMultiple: (params: IAggFuncParams) => {
        return params.values.every((val: string) => val === params.values[0]) ? params.values[0] : 'multiple';
      },
      originVolSum: (params: IAggFuncParams) => {
        let totalOrigin = 0;
        let totalDestination = 0;
        params.rowNode.allLeafChildren.forEach((row: any) => {
          if (row.data.scheduleType === 'O' || row.data.scheduleType === 'IT')
            totalOrigin += +row.data.scheduledQty;
          else
            totalDestination += +row.data.scheduledQty;
        });
        return totalOrigin || totalDestination;
      },
      checkEarlyDate: (params: IAggFuncParams) => {
        let arr = params.rowNode.allLeafChildren.length ? params.rowNode.allLeafChildren : [];
        if (!arr.length) return;
        let param: any = arr?.reduce((a: any, b: any) => {
          if (a?.data?.scheduledDate)
            return new Date(a.data.scheduledDate) < new Date(b.data.scheduledDate) ? a : b
        }, arr[0]);
        let date = param?.data?.scheduledDate;
        return date;
      }
    };
  public gridApi!: GridApi;
  public gridColumnApi: any;
  public isRowMaster: IsRowMaster = (dataItem: any) => {
    if (dataItem?.headerToItemNav?.length > 1) {
      return true;
    } else if (dataItem?.headerToItemNav?.length === 1 && dataItem?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  detailHeaderToItemNavHeader: any[] = [];
  headerPayload: any[] = [];
  public selectedRows: any[] = [];
  masterGridSelectedRows: any[] = [];
  detailGridSeectedRows: any[] = [];
  warningPopup: boolean = false;
  copyRowData: any;
  mainGridIndex: any;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'subtitle-6 font-gotham-narrow-book',
    headerClass: 'font-gotham-bold'
  };
  totalPanamaCCAFiltersResponse: any;
  linkNomId: string = '';
  linkCargoId: string = '';
  linkNomData: any = [];
  deleteInitialPayload: any;
  goToNominationId: any;
  goToNominationNumber: any;
  updatePayload: any[]=[];
  detailPayload: any[] = [];
  detailHeaderToItemNav: any[] = [];
  getPanamaCCAFilterResponse: any;
  goToNomKey: any;
  nomProductFilterResponse: any[] = [];
  navToNomKey: any;
  nomSchedule: any;
  nomProduct: any;
  nomTerminal: any;
  tswLocCode: any;
  nomTransportSystem: any;
  apiCallCount: any = 0;

  constructor(private appStore: Store<AppState>, private utilities: Utilities, private store: Store, private datePipe: DatePipe, private nominationService: NominationService, private cpService: CargoPlanningService, private cargoPlanningCcaService: CargoPlanningCcaService, private toasterService: ToastService, private router: Router , private loaderService: LoaderService, private myMonitoringService: MyMonitoringService, private mdmService : MdmService) { }

  ngOnInit(): void {
    this.rowSelection = 'multiple';
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
            this.userFullEmail = response.userDetails.email;
            localStorage.setItem("userEmail", this.userFullEmail);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
      this.myMonitoringService.logPageView('Nominations');
      this.nominationService.isCCANomModalOpen$.subscribe((res) => {
      if (res && !this.isApiCalled) {
        this.closeCcaModal()
        this.isApiCalled = true;
      }
    });
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(this.selectedDeskOption !== response.selectedDesk){
            this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Nominations');
          }
          this.selectedDeskOption = response.selectedDesk;
          this.selectedDeskParameter = response.selectedDeskParam;
          this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
          this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.selectedDeskOption);
          this.nominationService.nomSelected$.next(false);
          this.isCCAView = this.utilities.checkIfCCAView(this.selectedDeskOption)
        },
        error: (err: any) => { },
      });
    this.selectedDefaultFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.totalPanamaCCAFiltersResponse = response.totalTRFiltersResponse;
          this.nominationService.ccaDefaultFilters = JSON.parse(JSON.stringify(this.totalPanamaCCAFiltersResponse));
          if (this.nominationService.clearFilter.value) {
            this.updatedFilterSelection = [];
          }
          if (!response.receivedTotalFiltersResponse) {
            this.isPanamaDefaultApiCallInProgress = false;
          }
          if (response.receivedTotalFiltersResponse && !response.isTRGrid && this.nominationService.routingRestrictionFromDetailsPage && !this.isPanamaDefaultApiCallInProgress) {
            this.updatedFilterSelection = [];
            this.isPanamaDefaultApiCallInProgress = true;
            if(localStorage.getItem('prevUrl') === '/cargoPlanning'){
              this.getPanamaCCANominations(this.getCCAFilterResponse);
            }else{
             this.getPanamaCCANominations(this.getPanamaCCAFilterResponse);
            }
          }
          else if (response.updatedOfftakers && !response.isTRGrid) {
            this.totalPanamaCCAFiltersResponse = response.totalTRFiltersResponse;
            let offTakerFilterArray: any[] = [];
            this.nomOffTakerFilterResponse = [];
            this.totalPanamaCCAFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
            this.nominationService.panamaCCAFilters = undefined;
            offTakerFilterArray = this.totalPanamaCCAFiltersResponse.filter((data: any) => data.filterName === "offtakers")
            offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
              if (el.checked) {
                this.nomOffTakerFilterResponse.push(el.name)
              }
            });
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectedAppliedFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (this.nominationService.clearFilter.value) {
            this.nomOffTakerFilterResponse = [];
          }
          if (!response.receivedUpdatedFilterSelection) {
            this.isPanamaAppliedApiCallInProgress = false;
          }
          if (response.receivedUpdatedFilterSelection && !response.isTRGrid && !this.isPanamaAppliedApiCallInProgress) {
            this.isPanamaAppliedApiCallInProgress = true;
            this.updatedFilterSelection = response.updatedFilterSelection;
            this.nominationService.ccaFilters = JSON.parse(JSON.stringify(this.updatedFilterSelection));
            this.nominationService.panamaCCAFilters = JSON.parse(JSON.stringify(this.updatedFilterSelection));
            this.totalPanamaCCAFiltersResponse = response.totalTRFiltersResponse;
            let offTakerFilterArray: any[] = [];
            this.nomOffTakerFilterResponse = [];
            this.totalPanamaCCAFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
            offTakerFilterArray = this.totalPanamaCCAFiltersResponse.filter((data: any) => data.filterName === "offtakers")
            offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
              if (el.checked) {
                this.nomOffTakerFilterResponse.push(el.name)
              }
            });
            this.getPanamaCCANominations(this.getPanamaCCAFilterResponse);
            this.gridApi?.redrawRows();
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.gridApi?.setFilterModel({nominationNo:null});
        if (response?.isPipelineNomFilterUpdated) {
          this.isCCAApiCallInProgress = false;
        }
        if (response?.isPipelineNomFilterUpdated && this.selectedDeskParameter !== "panamadesk" && !this.isCCAApiCallInProgress) {
          this.isCCAApiCallInProgress= true;
          this.getCCAFilterResponse = response;
          this.getNomFilterResponse = JSON.parse(JSON.stringify(response));
          const productFilterArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "product");
          this.materialCode = productFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
          })
          const locationFilterArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
          this.locationCodes = locationFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
          })
          if(localStorage.getItem('prevUrl') === '/cargoPlanning'){
            this.apiCallCount += 1;
            this.nominationService.linkNomData$.subscribe((value: any) => {
              this.getlinktoNomCCAResponse = value[0];
            });
            this.getlinktoNomCCANominations(this.getlinktoNomCCAResponse, this.getNomFilterResponse);
          }else{
              this.getCCANominations(this.getCCAFilterResponse);
          }

        }
      }
    });
    this.utilities.sendActionToChild$.subscribe(action => {
      if (action === 'expand') {
        this.gridApi.forEachNode((index: { setExpanded: (arg0: boolean) => void; }) => {
          index.setExpanded(true);
        })
      }
      else {
        this.gridApi.forEachNode((index: { setExpanded: (arg0: boolean) => void; }) => {
          index.setExpanded(false);
        })
      }
    });
    this.utilities.getDataFromParent().pipe(
      takeUntil(this.destroy$)     // import takeUntil from rxjs/operators.
    ).subscribe((response: SelectedTabFunction) => {
      if (response.function === 'createCcaNomination') {
        this.isCcaNomEdit = false;
        this.isIntankMot = false;
        this.toggleCreatePipelineNominationsModal();
      } else if (response.function === 'createTerminalNomination') {
        this.isIntankMot = true;
        this.toggleCreateTerminalNominationsModal();
      } else if ((response.function === 'editPopupNomNo' || response.function === 'pipelineCreateNominationWithReferenceNonCP' || response.function === 'CcaCreateNominationWithReference') && this.selectedDeskOption?.includes("+ latam +")) {
        this.createNominationWithReference(response);
      } else if (response.function === "linkToExistingNom") {
        this.linkToExistingNom(response.type);
      } else if (response.function === "previewVendor") {
        this.toggleNominationPreviewModal();
      } else if (response.function === "onPublishEthanol" || response.function === "onDeleteEthanol") {
          setTimeout(() => {
            this.getPanamaCCANominations(response.values);
          }, 1500);
      }
    })
    if (this.selectedDeskOption === 'panama' || this.isCCAView) {
      this.getSapContractDetails()
    }
    this.nominationService.getTerminalNominationAFAPI$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res === true) {
        this.getPanamaCCANominations();
      }
    })
    this.nominationService.linkNomData$.subscribe((value: any) => {
      let nomTerminal = value[0]?.terminal;
      this.nomProduct = value[0]?.products[0].tswMaterialDesc;
      let nomPort = value[0]?.port;
      this.nomSchedule = value[0]?.loadDischarge;
      sessionStorage.setItem("nomSchedule",this.nomSchedule);
      const nomArry: any[]=[];
      value[0]?.products.forEach((el: any) => {
        nomArry.push(el?.tswMaterialDesc)
      });
    })
    this.nominationService.goToNominationData$.subscribe((value: any) => {
      if(this.selectedDeskOption.includes("clean products + latam + panama")){
        this.goToNomKey = value?.data;
      }
      if (Object.keys(value).length && value?.isTrue) {
        const fieldType = value?.data?.colDef?.field;
        let fieldDataName = fieldType?.split('_')[3] ? fieldType?.split('_').slice(0, 3).join('_') : fieldType;
        const getId = fieldDataName ? value?.data?.data[`${fieldDataName}_id_Child`] : value?.data?.id;
        this.goToNominationId = getId;
        this.goToNominationNumber = fieldDataName ? value?.data?.data[`${fieldDataName}`] : value?.data?.nominationNo;
      }
    })
  }

  getCustomViewByName(res: any) {
    let payload = { emailId: this.userFullEmail, viewName: res, desk: this.utilities.updateAPIParams() };
    this.nominationService.applyView(payload).subscribe(res => {
      if (res.statusCode === 200) {
        this.formPayloadForFilters(res.value)
      }
    })
  }

  formPayloadForFilters(res: any) {
    let filterList: any[] = [];
    if (this.selectedDeskOption?.includes("clean products + latam")) {
      this.CargoCCAFiltersApply(res, filterList);
    }
    this.nominationService.updatedFIlterValuesFromView$.next(filterList);
  }

  CargoCCAFiltersApply(res: any, filterList: any) {
    let sDate, eDate;
    let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = res.customViews[0].pipelineFilters;
    transportSystem.forEach((transportSystem: any) => {
      transportSystem.checked = true;
    });
    filterList.push({ headerName: "transport system", list: [...transportSystem] });
  }

  getCounterPartyToGrid(params: any) {
    if (this.selectedDeskOption?.includes("latam + panama")) {
      return params?.data?.counterPart;
    } else {
      return params?.data?.contractParner;
    }
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    let gTNDeskValue: any = localStorage.getItem('gTNDeskValue');
    var DeskValueTemp : any = JSON.parse(gTNDeskValue);
    this.store.dispatch(setSelectedDesk({ selectedDesk: DeskValueTemp.deskValue , selectedDeskParam: DeskValueTemp.deskParameter , selectedDeskId: DeskValueTemp.id }));
    let rowFound = false;
    if (DeskValueTemp.deskValue === "clean products + latam + panama desk"){
      this.rpFilterData = localStorage.getItem('gCCAF');
      this.filterListDataFromRP = JSON.parse(this.rpFilterData);
      const values = {
        offtakers: this.filterListDataFromRP.customers,
        updatedProduct: this.filterListDataFromRP.product,
        updatedfromDate: this.filterListDataFromRP.fromDate,
        updatedToDate: this.filterListDataFromRP.toDate,
      };
      this.store.dispatch(saveAppliedTRFilters({ updatedFilterSelection: JSON.parse(JSON.stringify(values)), receivedUpdatedFilterSelection: true, isClearFilter: false, isTRSaveEnabled: this.trSave, isTRGrid: false }));
    }
    else {
      let sDate, eDate;
      let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = this.filterListDataFromRP.pipelineFilterValues ;
      sDate = this.filterListDataFromRP.pipelineFromDate;
      eDate = this.filterListDataFromRP.pipelineToDate;
      this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
      this.nominationService.nomDefaultSetCustomView = { pipelineNomFilterValues: JSON.parse(JSON.stringify(this.filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true };
      this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));
    }
    this.loaderService.setLoading(true);
    let tempValue = localStorage.getItem('nomId');
    let tempValueNo = localStorage.getItem('nomNo');
    setTimeout(() => {
    params?.api?.forEachNode((node: IRowNode) => {
      console.log(node.key)
        if (DeskValueTemp.deskValue === "clean products + latam + panama desk" && node.key === tempValue  && !rowFound && tempValue) {
          params.api.getRowNode(`${node.id}`)?.setSelected(true);
          params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
          localStorage.setItem('nomId', '');
          rowFound = true;
        }
        if (DeskValueTemp.deskValue === "clean products + latam + panama desk" && node.key === tempValueNo  && !rowFound && tempValueNo) {
          params.api.getRowNode(`${node.id}`)?.setSelected(true);
          params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
          localStorage.setItem('nomId', '');
          rowFound = true;
        }
        if (((node?.key === this.goToNominationNumber)||(node?.key === tempValue)) && !rowFound) {
          this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
          params?.api?.getRowNode(`${node.id}`)?.setSelected(true);
          params?.api?.ensureIndexVisible(Number(node.rowIndex), 'top');
          rowFound = true;
        }
        this.loaderService.setLoading(false);
      });
      localStorage.removeItem('nomId');
      localStorage.removeItem('nomNo');
      localStorage.removeItem('gTNDeskValue');
      localStorage.removeItem('sPNFR');
    }, 2600);
  }


  getSapContractDetails() {
    this.nominationService.getNominationsRefData(["PanamaSapContractList"]).subscribe(data => {
      this.sapContractDetails = data.result[0].SapContractResponse;
    });
  }
  setContractLineNoBasedOnSapContractNo(data: any) {
    this.contractLineNumberList = [];
    let lineItem = this.sapContractDetails.find((dt: any) => {
      if (dt.sapContractNumber === data.sapContract || dt.sapContractNumber === data.nominationReferenceDocument) {
        return dt.lineItemList;
      }
    });
    lineItem?.lineItemList?.forEach((value: any) => {
      this.contractLineNumberList.push({ contractLineItem: this.utilities.removeLeadingZeros(value) })
    });
    return this.contractLineNumberList;
  }

  /**This method has the code specific to update nomination only. use the same method to develop the create Nomination from reference */
  async createNominationWithReferenceForNonGroupValue(data: any) {
    if (this.masterGridSelectedRows[0]?.nominationType === "IT") {
      this.isIntankMot = true;
      this.isCCAPanamaView = true;
      setTimeout(() => {
        this.createNominationWithReferenceForInTank(this.masterGridSelectedRows);
      });
      const ccaModal = { open: false, modal: 'createCcaNomination' }
      this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
      const inTankModal = { open: true, modal: 'createTerminalNomination' }
      this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
    } else {
      this.isIntankMot = false;
      this.isCCAPanamaView = true;
      setTimeout(() => {
        this.createNominationWithReferenceForOtherMOTs(this.masterGridSelectedRows);
      })
      const inTankModal = { open: false, modal: 'createTerminalNomination' }
      this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
      const ccaModal = { open: true, modal: 'createCcaNomination' }
      this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
      // }
    }
  }

  async createNominationWithReference(data: any) {
    if (data?.type === 'group') {
      data.values = !data.values ? this.masterGridSelectedRows[0]?.nominationNo : data.values;
      const pipelineNomination = await this.utilities.getPipelineNomPayload(data, this.userEmail, this.selectedDeskOption, true);
      pipelineNomination.nominationNo = !data.values ? this.masterGridSelectedRows[0]?.nominationNo : data.values;

      pipelineNomination["screenName"] = 'side-panel-tr';
      pipelineNomination["editNomination"] = data.editNomination;
      this.modalTitle = data.editNomination ? "edit nomination" : "create new cca nomination"
      this.nominationService.ccaEditNomination = data.editNomination;
      if ((this.selectedDeskOption.includes("panama") && pipelineNomination?.headerToItemNav[0][0]?.scheduleType !== "IT") || !this.selectedDeskOption.includes("panama")) {
        this.isIntankMot = false;
        this.isCCAPanamaView = this.selectedDeskOption.includes("panama");
        setTimeout(() => {this.createNominationCcaComponent.createNominationFromReferenceForPanamaCCA(pipelineNomination);});
        const inTankModal = { open: false, modal: 'createTerminalNomination' }
        this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
        const ccaModal = { open: true, modal: 'createCcaNomination' }
        this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
      } else {
        this.isIntankMot = true;
        this.isCCAPanamaView = true;
        const ccaModal = { open: false, modal: 'createCcaNomination' }
        this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
        const inTankModal = { open: true, modal: 'createTerminalNomination' }
        this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
        this.createNominationWithReferenceForInTank(pipelineNomination);
      }
    } else {
      console.log(this.selectedRows[0]);
      const params = { data: this.selectedRows[0] };
      if (params.data) {
        console.log(params.data);
        const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params, this.userEmail, this.selectedDeskOption, true);
        if ((this.selectedDeskOption.includes("panama") && pipelineNomination?.headerToItemNav[0][0]?.scheduleType !== "IT") || !this.selectedDeskOption.includes("panama")) {
          this.isIntankMot = false;
          this.isCCAPanamaView = this.selectedDeskOption.includes("panama");
          setTimeout(() => { this.createNominationCcaComponent.createNominationFromReferenceForPanamaCCA(pipelineNomination); })
          const inTankModal = { open: false, modal: 'createTerminalNomination' }
          this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
          const ccaModal = { open: true, modal: 'createCcaNomination' }
          this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
        } else {
          this.isIntankMot = true;
          this.isCCAPanamaView = true;
          const ccaModal = { open: false, modal: 'createCcaNomination' }
          this.CcaNominationModal = JSON.parse(JSON.stringify(ccaModal));
          const inTankModal = { open: true, modal: 'createTerminalNomination' }
          this.terminalNominationModal = JSON.parse(JSON.stringify(inTankModal));
          setTimeout(() => { this.createNominationWithReferenceForInTank(pipelineNomination) })
        }
      }
    }
  }

  createNominationWithReferenceForInTank(data: any) {
    setTimeout(() => { this.createTerminalNominationComponent?.createNominationWithReferenceForPanamaCCA(data); });
  }

  async createNominationWithReferenceForOtherMOTs(data: any) {
    const params = { data: this.selectedRows[0] };
    if (params.data) {
      const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params, this.userEmail, this.selectedDeskOption, true);
      this.createNominationCcaComponent.createNominationFromReferenceForPanamaCCA(pipelineNomination);
    }
  }

  deskFormationForGrid() {
    switch (this.selectedDeskOption) {
      case "clean products + latam + panama desk":
        return "panamacca";
      case "clean products + latam + ecca desk":
        return "ecca";
      case "clean products + latam + wcca desk":
        return "wcca";
      default:
        return;
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getPanamaCCANominations(values?: any) {
    this.getPanamaCCAFilterResponse = values;
    this.nominationService.routingRestrictionFromDetailsPage = true;
    let productFilterResponse = this.totalPanamaCCAFiltersResponse?.find((el: any) => el.filterName?.toLowerCase() === "product");
    let offTakerFilterResponse = this.totalPanamaCCAFiltersResponse?.filter((data: any) => data.filterName?.toLowerCase() === "offtakers");
    const locationFilter = this.totalPanamaCCAFiltersResponse?.find(
      (filter: any) => filter.filterName?.toLowerCase() === 'location'
    )?.filterValues.find((value: any) => value.checked);
    this.nomSchedule = sessionStorage.getItem("nomSchedule");
    let postData!: any;
    let payload!: any;
    let postDataDefault!: any;
    let payloadDefault!: any;
    /**selectedDesl$ and if else desk conditions to be removed and maintain only elaborate postdata of panama code here after routing changes implmented */
    let selectedProduct = "";
    this.appStore.select(selectAppliedTRFilters).subscribe(state => selectedProduct = state.updatedFilterSelection.updatedProduct);

    if (this.isCCAPanamaView) {
      const getCurrentDate: any = new Date();
      const getCurrentDay = getCurrentDate.getDay();
      postData = {
        desk: "newPanama",
        location: locationFilter?.name,
        product: [selectedProduct],
        scheduleType: this.nomSchedule,
        counterpart: this.nomOffTakerFilterResponse.length > 0 ? this.nomOffTakerFilterResponse : this.counterPartCheck(offTakerFilterResponse),
        fromDate:
          this.updatedFilterSelection?.updatedfromDate
            ? this.updatedFilterSelection?.updatedfromDate
            : this.getTMinusSevenDays(getCurrentDate, getCurrentDay),
        toDate:
          this.updatedFilterSelection?.updatedToDate
            ? this.updatedFilterSelection?.updatedToDate
            : this.getTPlusSixtyDays(getCurrentDate),
      };
      postDataDefault = {
        desk: "newPanama",
        location: locationFilter?.name,
        product: [selectedProduct],
        counterpart: this.nomOffTakerFilterResponse.length > 0 ? this.nomOffTakerFilterResponse : this.counterPartCheck(offTakerFilterResponse),
        fromDate:
          this.updatedFilterSelection?.updatedfromDate
            ? this.updatedFilterSelection?.updatedfromDate
            : this.getTMinusSevenDays(getCurrentDate, getCurrentDay),
        toDate:
          this.updatedFilterSelection?.updatedToDate
            ? this.updatedFilterSelection?.updatedToDate
            : this.getTPlusSixtyDays(getCurrentDate),
      };
      payload = values?.setFlag ? values?.filters : postData;
      payloadDefault = values?.setFlag ? values?.filters : postDataDefault;
    }
      if (postData?.location && postData?.product && (this.nomSchedule == "Load" || this.nomSchedule == "Discharge")) {
        this.getTerminalNominations(payload);
      }else if(postDataDefault?.location && postDataDefault?.product){
        this.getTerminalNominations(payloadDefault);
      }
  }

  counterPartCheck(offTakerFilterResponse : any) {
    let checkedCounterPart: any = [];
    let counterparty = offTakerFilterResponse[0]?.filterValues?.filter((item: any) =>
         item.checked
    )
    counterparty.map((item: any) => checkedCounterPart.push(item.name));
    return checkedCounterPart;
  }

  getTMinusSevenDays(getCurrentDate: any, getCurrentDay: any) {
    if (getCurrentDay === 0) {
      return this.datePipe.transform(
        getCurrentDate.setDate(new Date().getDate()) - 7,
        'yyyy-MM-dd'
      );
    } else {
      const getDayDifference =
        getCurrentDate.getDate() -
        7 -
        getCurrentDay +
        (getCurrentDay === 0 ? -6 : 0);
      return this.datePipe.transform(
        new Date(getCurrentDate.setDate(getDayDifference)),
        'yyyy-MM-dd'
      );
    }
  };

  getTPlusSixtyDays(getCurrentDate: any) {
    const date = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 1, 0)
    if (getCurrentDate + 60 === date) {
      return this.datePipe.transform(getCurrentDate + 60, 'yyyy-MM-dd');
    } else {
      const getMonthLastDay = new Date(
        getCurrentDate.getFullYear(),
        getCurrentDate.getMonth() + 3,
        0
      );
      return this.datePipe.transform(getMonthLastDay, 'yyyy-MM-dd');
    }
  };
  getTerminalNominations(data: any) {
    this.store.dispatch(invokeNominationData({ payload: data }));
    this.nominationList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.rowData = JSON.parse(JSON.stringify(data));
      this.rowData?.forEach((el: any) => {
        el.scheduledDate = this.dateCellRenderer(el?.destinationDate);
        el.udmLaycanStartDate = this.dateCellRenderer(el.udmDeliveryLaycanStartDate);
        el.udmLaycanEndDate = this.dateCellRenderer(el.udmDeliveryLaycanEndDate);
        el.udmComments = el?.comment;
        el.vehicleName = el?.vesselName;
        el.tswNominationType = el?.nominationType;
        el.udmItemStatus = el?.status
      });
      this.copyRowData = JSON.parse(JSON.stringify(this.rowData));
      this.gridApi?.setRowData(this.rowData);
    })
    this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false, isTRGrid: false }));
    this.store.dispatch(updatedOfftakersFalse({ updatedOfftakers: false }));
  }

  async getlinktoNomCCANominations(data: any, getNomFilterResponse: any) {
    if(this.apiCallCount === 1) {
      this.getCCAFilterResponse = data;
      let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = getNomFilterResponse.pipelineNomFilterValues;
      const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
      fromDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth(), 1);
      toDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 5, 0);

      let filterList: any[] = [];
    
      const transportsystemArray = getNomFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "transport system");
      const motArray = getNomFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "mode of transport");
      modeOfTransport = getNomFilterResponse?.pipelineNomFilterValues.find((el: any) => el?.headerName?.toLowerCase() === "mode of transport");
      transportSystem = getNomFilterResponse?.pipelineNomFilterValues.find((el: any) => el?.headerName?.toLowerCase() === "transport system");
      transportSystem.list.forEach((transport: any) => {
        transport.checked = true;
      });

      let terminalFilterArray: any[]=[];
      let nomArry: any[]=[];
      let terminalId: any[]=[];
      let productId: any[]=[];

      const unLoCode = this.mdmService?.pavisResponse?.value?.regionDeskMapping?.[0]
      ?.deskMappings?.map((desk: any) => desk.deskPortMapping)?.flat(1)
      ?.find((port: any) => port.portName === data.port)?.unLoCode;
      console.log('unLoCode: ', unLoCode);
      await firstValueFrom(this.mdmService.getTswLocationByPort(unLoCode)).then((response: any) => {
        let terminalNameId = response?.value[0]?.locationCode; 
        if(terminalNameId == undefined){
          const terminalArray = getNomFilterResponse?.pipelineNomFilterValues?.find((el: any) => el?.headerName?.toLowerCase() === "terminal");
          terminalArray.list.forEach((el: any) => {
            el.checked = true;
            if(el.id){
              terminalId.push(el?.id);
              this.nomTerminal = terminalId;
            }
            terminalFilterArray.push({name: el?.name, id: el?.id, checked: el?.checked });
          });
        }
        else {
          this.nomTerminal = [response?.value[0]?.locationCode];
          terminalFilterArray.push({name: terminalNameId,id: terminalNameId, checked: true});  
        }       
        data?.products.forEach((el: any) => {
          productId.push(el?.tswMaterialCode);
          this.nomProduct = productId;
          nomArry.push({id: el?.tswMaterialCode, name: el?.tswMaterialDesc, checked: true})
        });
      });
      filterList.push(
        { ...modeOfTransport },
        { ...transportSystem },
        { headerName: "terminal", list: [...terminalFilterArray] }, 
        { headerName: "product", list: [...nomArry] }
      );
      this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: fromDate, pipelineToDate: toDate, isPipelineNomFilterUpdated: true }));
      this.terminalFilterData = [];
      this.productFilterData = [];
      this.modeOfTransport = [];
      this.transportSystemFilterData = [];
      this.nomSchedule = sessionStorage.getItem("nomSchedule");
      this.setTerminalFilterData(terminalFilterArray);
      this.setProductFilterData(this.nomProduct);
      this.setTransportSystemFilterData(transportsystemArray);
      this.setMotFilterData(motArray);
      const postData = {
        desk: this.deskFormationForGrid(),
        fromDate: getNomFilterResponse?.pipelineFromDate,
        modeOfTransport: this.modeOfTransport,
        toDate: getNomFilterResponse?.pipelineToDate,
        terminal: this.nomTerminal,
        scheduleType: this.nomSchedule,
        product: this.nomProduct,
        transportsystem: this.transportSystemFilterData,
        region: this.region,
      }
      const postDataDefault ={
        desk: this.deskFormationForGrid(),
        fromDate: getNomFilterResponse?.pipelineFromDate,
        modeOfTransport: this.modeOfTransport,
        toDate: getNomFilterResponse?.pipelineToDate,
        terminal: this.nomTerminal,
        product: this.nomProduct,
        transportsystem: this.transportSystemFilterData,
        region: this.region,
      }
      if (postData && this.getCCAFilterResponse && (this.nomSchedule=="Load" || this.nomSchedule == "Discharge")){
        this.getCCANominationsAF(postData);
      }else if(postDataDefault && this.getCCAFilterResponse){
        this.getCCANominationsAF(postDataDefault);
      }
    }
  }

  getCCANominations(response: any) {
    this.getCCAFilterResponse = response;
    const terminalFilterArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
    const productFilterArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "product");
    const transportsystemArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "transport system");
    const motArray = this.getCCAFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "mode of transport");
    this.nomSchedule = sessionStorage.getItem("nomSchedule");
    this.terminalFilterData = [];
    this.productFilterData = [];
    this.modeOfTransport = [];
    this.transportSystemFilterData = [];
    this.setTerminalFilterData(terminalFilterArray);
    this.setProductFilterData(productFilterArray);
    this.setTransportSystemFilterData(transportsystemArray);
    this.setMotFilterData(motArray);
    const postData = {
      desk: this.deskFormationForGrid(),
      fromDate: this.getCCAFilterResponse?.pipelineFromDate,
      modeOfTransport: this.modeOfTransport,
      toDate: this.getCCAFilterResponse?.pipelineToDate,
      terminal: this.terminalFilterData,
      scheduleType: this.nomSchedule,
      product: this.productFilterData,
      transportsystem: this.transportSystemFilterData,
      region: this.region,
    }
    const postDataDefault ={
      desk: this.deskFormationForGrid(),
      fromDate: this.getCCAFilterResponse?.pipelineFromDate,
      modeOfTransport: this.modeOfTransport,
      toDate: this.getCCAFilterResponse?.pipelineToDate,
      terminal: this.terminalFilterData,
      product: this.productFilterData,
      transportsystem: this.transportSystemFilterData,
      region: this.region,
    }
    if (postData && this.getCCAFilterResponse && (this.nomSchedule=="Load" || this.nomSchedule == "Discharge")){
      this.getCCANominationsAF(postData);
    }else if(postDataDefault && this.getCCAFilterResponse){
      this.getCCANominationsAF(postDataDefault);
    }
  }

  setTerminalFilterData(terminalFilterArray: any) {
    if (terminalFilterArray?.length > 0) {
      terminalFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.terminalFilterData.push(dt?.id);
        }
      });
    }
  }
  setProductFilterData(productFilterArray: any) {
    if (productFilterArray?.length > 0) {
      productFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.productFilterData.push(dt?.id);
        }
      });
    }
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    let selectedRows = this.gridApi.getSelectedRows();
    const selectedids = this.selectedRows.map(row => row.nominationNo);
    const allidssame = selectedids.every(id => id === selectedids[0]);
    if (this.selectedRows.length > 0 && allidssame) {
      this.nominationService.nomSelected$.next(true);
    } else {
      this.nominationService.nomSelected$.next(false);
    }
    this.nominationService.isCpGridSelected = selectedRows.length ? selectedRows : "";
    this.deleteInitialPayload = JSON.parse(JSON.stringify(event?.api?.getSelectedRows()));
    const unPublishedNomArray: string[] = [];
    this.selectedRows?.forEach((data: any) => {
      if ((data?.headerToItemNav?.length > 0 && data.headerToItemNav?.every((status: any) => status?.udmItemStatus?.toLowerCase() === 'published') || (data?.headerToItemNav?.length === 0 && data?.nominationStatus?.toLowerCase() === 'published'))) {
        unPublishedNomArray.push("published")
      }
      else {
        unPublishedNomArray.push("unpublished")
      }
    })
    this.selectedRows.forEach((selectedRow?) => {
      if (selectedRow.nominationStatus) {
        selectedRow?.nominationStatus === 'published' ?
          this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: true }) :
          this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: false });
      }
    })
    this.nominationService.enableNominationsSaveButton$.next(this.selectedRows.length > 0);
  }
  toggleNominationPreviewModal(): void {

    const selectedRows = this.getSelectedRows();
    const uniqueRows = [...new Map(selectedRows.map((item: any) => [item['id'], item])).values()]
    this.vendorPreviewNotificationComponent.selectedRows(uniqueRows)
    const modal = { open: !this.vendorNotificationPreview.open, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }


  closePreviewModal() {
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }

  setTransportSystemFilterData(transportSystemFilterArray: any) {
    if (transportSystemFilterArray?.length > 0) {
      transportSystemFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.transportSystemFilterData.push(dt?.id);
        }
      });
    }
  }

  setMotFilterData(motArray: any) {
    if (motArray?.length > 0) {
      motArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.modeOfTransport.push(dt?.name);
        }
      });
    }
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  getCCANominationsAF(postData: any) {
    this.appStore.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: false }));
    this.utilities.getPipelineNominationMasterData(postData);
    this.nominationService?.getFilterNominations(postData).subscribe(data => {
      this.rowData = data?.result;
      this.rowData?.forEach((el: any) => {
        el.scheduledDate = this.dateCellRenderer(el?.scheduledDate);
        el.udmLaycanStartDate = this.dateCellRenderer(el.udmLaycanStartDate);
        el.udmLaycanEndDate = this.dateCellRenderer(el.udmLaycanEndDate);
      });
      this.copyRowData = JSON.parse(JSON.stringify(this.rowData));
    });
  }

  statusDeclaration(event: any, column: any) {
    if (event?.toLowerCase() === Nom.PUBLISHED && column === "udmComments") {
      return Nom.PUBLISHED;
    } else if ((event?.toLowerCase() === Nom.PUBLISHED || event?.toLowerCase() === "failed") && column !== "udmComments") {
      return Nom.UNPUBLISHED;
    } else {
      return event;
    }
  }

  accessForNecessaryDesk() {
    if (this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) {
      return true;
    } else {
      return false;
    }
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    this.existanceOfPayloadCheckHeader(event);
    this.mainGridIndex = this.copyRowData?.map((el: any) => el?.id).indexOf(event?.data?.id);
    if (this.headerPayload?.find((val: any) => val.id !== event?.data?.id)) {
      this.detailHeaderToItemNavHeader = [];
    }
    const headerToItemPayload: any = {
      itemId: event?.data?.itemId,
      udmItemStatus: "inprocess",
      textLine: `{Author:${event?.data?.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
      scheduledDate: this.datePipe.transform(event?.data?.scheduledDate, "yyyMMdd"),
    }
    const rowIndex = this.mainGridIndex;
    let keys: any;
    if (rowIndex >= 0) {
      keys = Object.keys(this.copyRowData[rowIndex]);
    }
    for (const key of keys) {
      this.addUpdatedRowsToPayload(this.copyRowData[rowIndex], key, event, headerToItemPayload)
    }
    if (!(this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
      this.detailHeaderToItemNavHeader.push(headerToItemPayload);
    } else {
      const detailPayloadIndex = this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
      this.detailHeaderToItemNavHeader[detailPayloadIndex] = headerToItemPayload;
    }
    const updatedComments = this.detailHeaderToItemNavHeader?.filter((el: any) => !!el?.udmComments);
    const payload = {
      id: event?.data?.id,
      sys_status: "inprocess",
      deskFilter: "ccadesk",
      modeOfTransportDesc: event?.data?.modeOfTransportDesc,
      headerToItemNav: this.detailHeaderToItemNavHeader,
      udmPublishEvent: this.accessForNecessaryDesk(),
      udmNominationGrouping: this.copyRowData[rowIndex]?.udmNominationGrouping ? this.uniqueUID() : '',
      isUdmCommentsUpdated: updatedComments?.length > 0 ? true : undefined,
    }
    this.finalUpdatePayloadFormation(payload, event);
    this.nominationService.enableNominationsSaveButton$.next(true);
  }

  addUpdatedRowsToPayload(rowDetail: any, key: any, event: any, headerToItemPayload: any) {
    if ((rowDetail[key] !== event?.data[key] && key !== "itemId" && key !== "id" && key !== "udmItemStatus" && key !== "nominationStatus") || (rowDetail[key] !== event?.data[key] && key !== "itemId" && key !== "udmItemStatus" && key !== "id" && key !== "nominationStatus")) {
      if (key === "scheduledMaterialName") {
        headerToItemPayload["scheduledMaterialDesc"] = event?.data[key];
        this.updatePayloadForCCADesks(event, key, headerToItemPayload);
      } else if (key === "demandMaterialName") {
        headerToItemPayload["demandMaterialDesc"] = event?.data[key];
        this.updatePayloadForCCADesks(event, key, headerToItemPayload);
      } else if (key === "scheduledDate") {
        headerToItemPayload["scheduledDate"] = `${event?.data[key].substring(6, 10)}${event?.data[key].substring(0, 2)}${event?.data[key].substring(3, 5)}`;
      } else if (key === "locationName") {
        headerToItemPayload["locationName"] = event?.data[key];
        this.updatePayloadForCCADesks(event, key, headerToItemPayload);
      } else if (key === "scheduledQty" && this.isCCAPanamaView && event?.data?.modeOfTransportDesc === 'TankTransfer') {
        headerToItemPayload["scheduledQty"] = event?.data[key] / 1000
      } else this.splitAddUpdatedRowsToPayload(key, event, headerToItemPayload);
    }
  }

  splitAddUpdatedRowsToPayload(key: any, event: any, headerToItemPayload: any) {
    if (key === "nominationReferenceDocument" && event?.data[key] === "") {
      headerToItemPayload["nominationReferenceDocument"] = " ";
      headerToItemPayload["nominationReferenceDocumentItem"] = " ";
      headerToItemPayload["referenceDocumentIndicator"] =event?.data["referenceDocumentIndicator"];
    } else if (key === "nominationReferenceDocument"){
      headerToItemPayload[key] = event?.data[key];
      headerToItemPayload["referenceDocumentIndicator"] =event?.data["referenceDocumentIndicator"];
    } else if (event?.data[key] === "" && key !== "confirmationIndicator") {
      headerToItemPayload[key] = " ";
    } else if (key === "nominationReferenceDocumentItem") {
      headerToItemPayload[key] = this.utilities.removeLeadingZeros(headerToItemPayload[key] = event?.data[key]);
      headerToItemPayload["referenceDocumentIndicator"] = event?.data["referenceDocumentIndicator"];
    } else if (key === "udmComments") {
      headerToItemPayload[key] = event?.data[key];
      headerToItemPayload["isUdmCommentsUpdated"] = true
    }
    else {
      headerToItemPayload[key] = event?.data[key];
    }
  }

  updatePayloadForCCADesks(event: any, key: any, headerToItemPayload: any) {
    if (key === "scheduledMaterialName") {
      const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      headerToItemPayload["scheduledMaterial"] = this.materialCode.find((el: any) => el.name === event?.data[key])?.value;

    } else if (key === "demandMaterialName") {
      const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      headerToItemPayload["demandMaterial"] = this.materialCode.find((el: any) => el.name === event?.data[key])?.value;

    } else if (key === "locationName") {
      const locations = this.locationCodes?.find((el: any) => el?.name === event?.data[key]);
      headerToItemPayload["locationId"] = locations?.value;
    }

  }

  uniqueUID() {
    return (new Date()).getTime().toString(36);
  }

  finalUpdatePayloadFormation(payload: any, event: any) {
    if (!(this.headerPayload?.map(el => el.id).includes(event?.data?.id))) {
      this.headerPayload.push(payload);
    } else {
      const payloadIndex = this.headerPayload.map(el => el.id).indexOf(event?.data?.id);
      this.headerPayload[payloadIndex] = payload;
    }
  }

  existanceOfPayloadCheckHeader(event: any) {
    if (this.differentHeaderId !== event?.data?.id) {
      this.detailHeaderToItemNavHeader = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(event?.data?.id));
    if (this.headerPayload?.map((el: any) => el?.id)?.includes(event?.data?.id)) {
      const ind = this.headerPayload?.map((el: any) => el?.id)?.indexOf(event?.data?.id);
      this.detailHeaderToItemNavHeader = this.headerPayload[ind]?.headerToItemNav;
    }
  }

  getSelectedRows() {
    let selectedRowNodes = this.gridApi.getSelectedNodes();
    if (selectedRowNodes.length === 0) this.masterGridSelectedRows = [];
    let selectedRowsFromChildrenNode: any = [];
    selectedRowNodes.forEach((rowNode: any) => {
      rowNode.allLeafChildren?.forEach((row: any) => {
        selectedRowsFromChildrenNode.push(row.data);
      })
    })
    return selectedRowsFromChildrenNode;
  }

  onRowSelectRow(event: any) {
    let selectedRowsFromChildrenNode = this.getSelectedRows();
    if (selectedRowsFromChildrenNode) {
      this.selectedRows = JSON.parse(JSON.stringify(selectedRowsFromChildrenNode));
      // Adding udmNominationGrouping flag for selected rows
      this.selectedRows?.forEach((obj: any) => {
        obj["udmNominationGrouping"] = false;
        obj["isNonCPDesk"] = true;
      })
      this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
      this.nominationService.nomSelected$.next(false);
      this.nominationService.selectedNom$.next(this.selectedRows);
      // As event.api.getSelectedRows() wont work for grouped rows, manually pushing and removing
      if (event?.event?.target?.checked || event?.source?.toLowerCase() === 'rowclicked' || event?.source?.toLowerCase() === 'api') {
        this.masterGridSelectedRows = this.selectedRows;
      } else {
        this.selectedRows?.forEach((data: any, index: any) => {
          this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c: any) => c.itemId !== data.itemId);
        })
      }
      // Retrieve unique nomination number from this.masterGridSelectedRows
      this.retrieveUniqueNomNo();
    }
  }

  retrieveUniqueNomNo() {
    const uniqueNominationNo = [...new Set(this.masterGridSelectedRows.map((a: any) => a.nominationNo))];
    if (uniqueNominationNo?.length === 1) {
      this.nominationService.nomSelected$.next(true);
    } else {
      this.nominationService.nomSelected$.next(false);
    }
    const unPublishedNomArray: string[] = [];
    this.selectedRows?.forEach((data: any) => {
      if ((data.udmItemStatus?.toLowerCase() === 'published') || (data?.nominationStatus?.toLowerCase() === 'published')) {
        unPublishedNomArray.push("published")
      }
      else {
        unPublishedNomArray.push("unpublished")
      }
    })
    if (this.selectedRows?.length > 0) {
      if (this.nominationService?.linkCargoData.length) {
        this.rowSelection = 'single';
      } else {
        this.rowSelection = 'multiple';
      }
      this.nominationService.enableLinkToNomButton$.next({ linkNomBotton: true });
      this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: unPublishedNomArray?.every((status: any) => status === 'published') });
    } else {
      this.nominationService.enableLinkToNomButton$.next({ linkNomBotton: false });
      this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    }
  }

  setWarningMsgorApi(nomPayload: any, event: any) {
    let i = 0;
    while (i < this.selectedRows.length && event.trim() !== 'delete') {
      /* Do not call update api for missing values, send error popup */
      if ((nomPayload?.length > 0) && (this.selectedRows[i].demandMaterial === null) || (this.selectedRows[i].scheduledMaterial === null)) {
        this.warningPopup = true;
      }
      i = i + 1
    }
    /**Call update nominations api */
    if (nomPayload?.length > 0 && !this.warningPopup) {
      this.publishDeleteApiCall(event, nomPayload);

    }
  }
  showWarningPopup() {
    this.warningPopup = true;
  }

  publishOrDeleteNominationData(event: any) {
    this.selectedRows = [...this.masterGridSelectedRows, ...this.detailGridSeectedRows];
    let nomPayload: any[] = [];
    // Checking if masterGridSelectedRows from Non Cp Desk
    if (this.masterGridSelectedRows.some((obj: any) => obj.isNonCPDesk)) {
      // Finding unique id's from masterGridSelectedRows
      let uniqueIDs = [...new Set(this.masterGridSelectedRows.map((a: any) => a.id))];
      // Transforming masterGridSelectedRows with headerToItemNav
      uniqueIDs.forEach((uniqueId: any) => {
        let temp = this.masterGridSelectedRows;
        if (this.masterGridSelectedRows.some((obj: any) => obj.headerToItemNav)) {
          temp = this.masterGridSelectedRows.filter((a: any) => !a.headerToItemNav)
        }
        let tempMasterGridSelectedRows = JSON.parse(JSON.stringify(temp));
        const tempHeaderToItemNav = JSON.parse(JSON.stringify(tempMasterGridSelectedRows.filter((b: any) => b.id === uniqueId)));
        const uniqueNominationIdIndex = tempMasterGridSelectedRows.findIndex((obj: any) => obj.id === uniqueId);
        tempMasterGridSelectedRows[uniqueNominationIdIndex]["headerToItemNav"] = JSON.parse(JSON.stringify(tempHeaderToItemNav));
        // Removing other nomination objects without headerToItemNav from this.masterGridSelectedRows
        // Filtering obj with headerToItemNav
        tempMasterGridSelectedRows = tempMasterGridSelectedRows.filter((c: any) => c.headerToItemNav);
        // Removing existing objs from with matching uniqueId 
        this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c: any) => c.id !== uniqueId);
        // Adding back filtered obj with headerToItemNav to this.masterGridSelectedRows
        this.masterGridSelectedRows.push(...tempMasterGridSelectedRows);
      })


    }

    this.masterGridPublishDeleteRecord(event, nomPayload);
    // only one innergrid for now
    this.setNomPayload(nomPayload, event);
    /*  */
    this.setWarningMsgorApi(nomPayload, event)
  }

  linkToExistingNom(event: any) {
    if (this.selectedRows.length) {
      this.linkNomId = this.selectedRows[0].id;
    }
    let linkCargoData = this.nominationService?.linkCargoData[0];
    let returnDeskId = this.nominationService?.linkCargoData[0].returnDeskId;
    this.linkCargoId = this.nominationService?.linkCargoData[0]?.uid.split('_')[0];
    if (this.linkNomId && this.linkCargoId) {
      const payload = {
        nominationId: this.linkNomId,
        id: this.linkCargoId,
        sys_modifiedBy: this.userFullEmail,
        cargoItineraryList: [{
          loadDischarge: linkCargoData?.loadDischarge,
          products: this.nominationService?.linkCargoData[0]?.products
        }]
      }
      this.myMonitoringService.logEvent('Linking nomination to cargo planning is clicked', {category: "Nominations"});
      this.cargoPlanningCcaService.updateLinkNominationWithCargoCca(payload).subscribe({
        next: (response) => {
          if (response.statusCode === 200 && response.success) {
            this.gridApi.deselectAll();
            let message = response.result[0]?.linkStatus;
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: message,
            });
            setTimeout(() => {
              if (!response.result[0]?.isProductVolumeVaild) {
                this.toasterService.setToastNotification({
                  show: true,
                  type: 'error',
                  msg: response.result[0]?.validationMessage,
                });
              }
            }, 2000);
            this.nominationService.navigateToNom('', '/cargoPlanning', returnDeskId)
          }
        },
        error: (err: any) => {
          this.gridApi.deselectAll();
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err.message,
          });
          this.nominationService.navigateToNom('', '/cargoPlanning', returnDeskId)
        }
      });
    }
  }

  setNomPayload(nomPayload: any, event: any) {
    let headerItem: any[] = [];
    /* Can be removed not required */
    this.detailGridSeectedRows?.forEach(val => {
      val.detailType?.forEach((lineItem: any) => {
        headerItem.push({
          itemId: lineItem.itemId,   // need to change  id to itemId
          udmItemStatus: event.trim() === 'delete' ? Nom.INPROCESS : Nom.PUBLISHED,
          deletionIndicator: event.trim() === 'delete' ? 'X' : undefined,
          textLine: `{Author:${lineItem.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        })
      })
      headerItem = [...new Set(headerItem)];
      nomPayload.push(
        {
          id: val.id,// check what should be for single nom with detailtype=[]
          deskFilter: "ccadesk",
          sys_status: event.trim() === 'delete' ? (val.nominationStatus.toLowerCase() === 'failed' ? 'unpublished' : val.nominationStatus) : Nom.PUBLISHED,
          headerToItemNav: headerItem,
          udmPublishEvent: event.trim() === 'delete' ? this.accessForNecessaryDesk() : undefined,
        }
      );
    });
  }

  setHeaderToItemNavMultiple(val: any, hToItemNavValue: any, isStatusDelete: any, nomPayload: any, event: any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus = deletedUdmItemStatus;
      if (deletedItemNomKey) {
        updatedStatus = "InProcess";
      }
      let headerVal: any = []
      headerVal?.push({
        itemId: el.itemId,
        udmItemStatus: event.trim() === 'delete' ? (updatedStatus.toLowerCase() === 'failed' ? 'unpublished' : updatedStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`

      });
      const deletedNomStatus = val?.nominationStatus;
      let deletedNomKey = val?.nominationKey;
      deletedItemNomKey = deletedNomKey?.length;
      let updatedNomStatus = deletedNomStatus;
      if (deletedItemNomKey) {
        updatedNomStatus = "InProcess";
      }
      nomPayload.push(
        {
          id: el.id,
          deskFilter: "ccadesk",
          sys_status: event.trim() === 'delete' ? (updatedStatus.toLowerCase() === 'failed' ? 'unpublished' : updatedStatus) : "InProcess",
          headerToItemNav: headerVal
        }
      )
      hToItemNavValue = [...hToItemNavValue, ...headerVal]
    });
  }

  onCallGridApi(){
      this.getPanamaCCANominations(this.getPanamaCCAFilterResponse);
  }

  isNotMultiple(event: any, nomPayload: any) {
    this.masterGridSelectedRows?.forEach(val => {
      const hToItemNavValue: any = [];
      const isStatusDelete = event.trim() === 'delete';
      if (val) {
        if (val?.udmNominationGrouping) { // check if the data is for multi transport

          if (val.headerToItemNav?.length > 0) {
            this.setHeaderToItemNavMultiple(val, hToItemNavValue, isStatusDelete, nomPayload, event);
          }
        }

        else {
          this.isNotMultipleElseCondition(event, val, nomPayload, hToItemNavValue, isStatusDelete);
        }

      }
    })
  }

  setHeaderToItemNav(val: any, hToItemNavValue: any, isStatusDelete: any, event: any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedNomStatus = deletedUdmItemStatus;
      if (deletedItemNomKey) {
        updatedNomStatus = "InProcess";
      }
      hToItemNavValue?.push({
        itemId: el.itemId,
        udmItemStatus: isStatusDelete ? (updatedNomStatus.toLowerCase() === 'failed' ? 'unpublished' : updatedNomStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
      });
    });
  }

  isNotMultipleElseCondition(event: any, val: any, nomPayload: any, hToItemNavValue: any, isStatusDelete: any) {
    if (val.headerToItemNav?.length > 0) {
      this.setHeaderToItemNav(val, hToItemNavValue, isStatusDelete, event);
    }
    let updateSys_status = val?.nominationStatus?.toLowerCase() === "published" ? Nom.INPROCESS : val?.nominationStatus
    nomPayload.push(
      {
        id: val.id,
        deskFilter: "ccadesk",
        sys_status: event.trim() === 'delete' ? (updateSys_status?.toLowerCase() === 'failed' ? 'unpublished' : updateSys_status) : Nom.INPROCESS,
        udmPublishEvent: event.trim() === 'delete' ? this.accessForNecessaryDesk() : undefined,
        headerToItemNav: hToItemNavValue
      }
    )
  }
  masterGridPublishDeleteRecord(event: any, nomPayload: any) {
    const isStatusDelete = event.trim() === 'delete';
    if (!this.masterGridSelectedRows[0].udmNominationGrouping) {
      this.isNotMultiple(event, nomPayload)
    }
    else {
      this.groupTransport(nomPayload, event, isStatusDelete)
    }
  }
  groupTransport(nomPayload: any, event: any, isStatusDelete: any) {
    let obj: any = {};
    this.masterGridSelectedRows[0]?.headerToItemNav.forEach((el: any, i: any) => {
      if (Object.keys(obj).length === 0 || !Object.keys(obj).includes(el.id)) {
        obj[el.id] = [el]
      }
      else {
        obj[el.id].push(el)
      }
    })
    Object.keys(obj).forEach((key: any) => {
      let hToItemNav: any = []

      obj[key].forEach((elem: any) => {
        const deletedUdmItemStatus = elem?.udmItemStatus;
        let deletedItemNomKey = elem?.nominationKey;
        deletedItemNomKey = deletedItemNomKey?.length;
        let updatedStatus = deletedUdmItemStatus;
        if (deletedItemNomKey) {
          updatedStatus = "InProcess";
        }
        hToItemNav.push({
          itemId: elem.itemId,
          udmItemStatus: event.trim() === 'delete' ? (updatedStatus.toLowerCase() === 'failed' ? 'unpublished' : updatedStatus) : "InProcess",
        })
      })

      const deletedNomStatus = this.masterGridSelectedRows[0]?.nominationStatus;
      let deletedItemNomKey = this.masterGridSelectedRows[0]?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus = deletedNomStatus;
      if (deletedItemNomKey) {
        updatedStatus = "InProcess";
      }

      nomPayload.push({

        id: key,
        deskFilter: "ccadesk",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        sys_status: event.trim() === 'delete' ? (updatedStatus.toLowerCase() === 'failed' ? 'unpublished' : updatedStatus) : "InProcess",
        headerToItemNav: hToItemNav,
      })
    })

  }

  publishDeleteApiCall(event: any, nomPayload: any) {
  
    if (this.selectedDeskOption.includes('clean products')) {
      this.myMonitoringService.logEvent(`Nomination ${event.trim()} clicked`, {category: "Nominations"});
      this.nominationService.updateNomination(nomPayload).subscribe({
        next: () => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.selectedRows = [];
          nomPayload = [];
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: event.trim() === "delete" ? "Nomination has been deleted" : "Nomination is being sent to TSW",
          });
        },
        error: (err: any) => { },
        complete: () => { },
      });
      this.warningPopup = false;
      setTimeout(() => {
        if (this.selectedDeskOption?.includes("clean products + latam + panama")) {
          this.getPanamaCCANominations(this.getPanamaCCAFilterResponse);
        } else {
          this.getCCANominations(this.getCCAFilterResponse);
        }
        this.gridApi.deselectAll();
      }, 5000)
    }
  }

  saveCCAData() {
    if (this.headerPayload?.length > 0) {
      this.myMonitoringService.logEvent('Nomination save clicked', {category: "Nominations"});
      this.nominationService.updateNomination(this.headerPayload).subscribe({
        next: () => {
          this.headerPayload = [];
          this.differentHeaderId = '';
          this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
          setTimeout(() => {
            if (this.selectedDeskOption?.includes("clean products + latam + panama")) {
              this.getPanamaCCANominations()
            } else {
              this.getCCANominations(this.getCCAFilterResponse);
            }
            this.gridApi.deselectAll();
          }, 5000)
          this.gridApi.deselectAll();
          this.toasterService.setToastNotification({
            show: true,
            type: "success",
            msg: "Nomination has been updated",
          });
        },
        error: (err) => {
          this.headerPayload = [];
          this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
        }
      })
    } else{
      this.publishOrDeleteNominationData('publish');
    }
  }

  closeCcaModal() {
    const modal = { open: false, modal: 'createCcaNomination' }
    this.CcaNominationModal = JSON.parse(JSON.stringify(modal));
    this.gridApi?.deselectAll();
    if(this.selectedDeskParameter){
      if (this.selectedDeskParameter === "panamadesk" && !this.isApiCalled) {
        this.getPanamaCCANominations(this.getPanamaCCAFilterResponse);
        this.isApiCalled = true;
      } else if(!this.isApiCalled){
        this.getCCANominations(this.getCCAFilterResponse);
        this.isApiCalled = true;
      }
    }
    this.modalTitle = "creating new cca nomination";
  }
  
  toggleCreatePipelineNominationsModal(): void {
    this.isCcaNomEdit = true;
    const modal = { open: !this.CcaNominationModal.open, modal: 'createCcaNomination' }
    this.CcaNominationModal = { ...modal };
    this.createNominationCcaComponent.rdiDescriptionMapFn();

  }
  toggleCreateTerminalNominationsModal(): void {
    const modal = { open: !this.terminalNominationModal.open, modal: 'createTerminalNomination' }
    this.terminalNominationModal = Object.assign({}, modal);
  }
  closeTerminalNominationModal(val: any) {
    this.gridApi.deselectAll();
    const modal = { open: false, modal: 'createTerminalNomination' }
    this.terminalNominationModal = Object.assign({}, modal);
  }

  ngOnDestroy() {
    this.nominationService.enableLinkToNomButton$.next({ linkNomBotton: false });
    this.nominationService.linkNomData$.next([]);
    this.nominationService.linkCargoData = [];
    this.isPanamaDefaultApiCallInProgress = false;
    this.isCCAApiCallInProgress = false;
    this.isPanamaAppliedApiCallInProgress = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.utilities.clearDataToChild();
    this.utilities.unsubscribeDataToChild();
  }
}
