import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Utilities } from '../../utilities/utilities';


@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatePickerComponent implements ICellEditorAngularComp {
  private params: any;
  public value!: any;
  constructor(private datePipe: DatePipe, private utilities: Utilities) {

  }
  @ViewChild('input') test:any;
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;
  agInit(params: ICellEditorParams): void {
    this.params = params;

    this.setInitialState(this.params);
  }

  setInitialState(params: ICellEditorParams) {
    if (params.value) {
      this.value = params.value
    } else {
      this.value = this.datePipe.transform(this.utilities.convertDatesToCurrentTZ(new Date().toISOString()), 'MM/dd/YYYY');
    }
  }
  parseDate(dateString: any) {
    if (this.params.resetToMin) {
      if (dateString) {
        return dateString.split('T')[0].replaceAll('-', '/');
      } else {
        return "0001-01-01";
      }
    } else {
      if (dateString) {
        return dateString.split('T')[0].replaceAll('-', '/');
      }
      this.input.element.nativeElement.blur();
      return this.params.value?.split('T')[0].replaceAll('-', '/');
    }
  }
  getValue(): any {
    this.value = this.datePipe.transform(new Date(this.test.nativeElement.value.replaceAll('-','/')), 'MM/dd/YYYY');
    return this.value;
  }

}
