import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, GridApi, ICellRendererParams } from 'ag-grid-community';
import { select, Store } from '@ngrx/store';
import { selectDesk } from '../../store/selector/app.selector';
import { Subject, takeUntil } from 'rxjs';
import { UDMDesk } from '../../constants/terminal-replenishment.constant';

@Component({
  selector: 'app-cell-info-hover',
  templateUrl: './cell-info-hover.component.html',
  styleUrls: ['./cell-info-hover.component.scss']
})
export class CellInfoHoverComponent implements ICellRendererAngularComp {

  public params: any
  public gridApi2!: GridApi;
  gridColumnApi2!: ColumnApi;
  public value!: any;
  isDisplay = false;
  errorMsg = false;
  isErrorPresent = false; // make it by default false
  desk$ = this.store.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentSelectedDesk:any
  constructor( private store: Store){}


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.currentSelectedDesk = response.selectedDesk;        
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
    const isCargo = ( this.currentSelectedDesk?.toLowerCase() === UDMDesk.PipelineUsecFloridaDesk  ||  this.currentSelectedDesk?.toLowerCase() === UDMDesk.PipelineUswcPNWDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecRichmondDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecPascagoulaDesk);
    this.params = params
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    this.value =  isCargo && params?.value.includes('-')? "" : params?.value;
    let nomError = params.data?this.isErrorPresentInNom(params.data): this.isErrorPresentInNewNom(params.node);
    this.isErrorPresent = nomError.showError
    this.errorMsg = nomError.msg
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  toggleDisplay(e: any) {
    e.stopPropagation();
    this.isDisplay = !this.isDisplay;
  }

  isErrorPresentInNom(nom: any){
    if(nom.errors &&  (nom.errors !== null) && nom.errors.length > 0){
     let msg = nom.errors.join(', ')
      return {showError :true, msg : msg};
    } else {
      let msg = '';
      nom?.headerToItemNav?.forEach((element:any) => {
        if(element.errors){
          msg = msg ? `${msg} , ${element.errors.join(', ') }` : element.errors.join(', ')
        }
      });
      return {showError :!!(msg), msg : msg};
    }

  }
  isErrorPresentInNewNom(node:any){
    let msg = '';
    node.allLeafChildren.forEach((element:any) => {
      if(element.data.errors){
        msg = msg ? `${msg} , ${element.data.errors.join(', ') }` : element.data.errors.join(', ')
      }
    });
    return {showError :!!(msg), msg : msg};
  }

}
