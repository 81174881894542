import { Action, createReducer, on } from "@ngrx/store";
import { TRViewConst } from "src/app/shared/constants/terminal-replenishment.constant";
import { CustomerDetail, GetTRPayload } from "../../../shared/interface/terminal-replenishment.interface";
import { getTRDataAPISuccess, saveTRView } from "../action/terminal-replenishment.action";

export interface State {
    productName: string;
    customerDetails: CustomerDetail[];
    selectedTRView: string;
    filterPayload: GetTRPayload;
}

export const initialState: State = {
    productName: "",
    customerDetails: [],
    selectedTRView: TRViewConst.Table,
    filterPayload: {
        product: "",
        customers: [],
        fromDate: "",
        toDate: "",
    },
};

const terminalReplenishmentReducer = createReducer(
    initialState,
    on(getTRDataAPISuccess, (state, { allTRData }) => ({
        ...state,
        ...allTRData,
    })),
    on(saveTRView, (state, { selectedTRView }) => ({
        ...state,
        selectedTRView,
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return terminalReplenishmentReducer(state, action);
}

export const getSelectedTRView = (state: State) => ({ selectedTRView: state.selectedTRView });


export const getCustomerDetails = (state: State) => ({ customerDetails: state.customerDetails });


