import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { dealsAllData, dealsSearchData} from 'src/app/dynamicdeals/store/selector/cb-dynamic-deals.selector';
import { applyDDPrimaryFilter, selectMaterialTab } from 'src/app/dynamicdeals/store/action/cb-dynamic-deals.action';
import { combineLatest, map, of } from 'rxjs';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent implements OnInit {
  showTransactionDropdown: boolean = false;
  showMatrialDropdown: boolean = false;
  showCycleDropdown: boolean = false;
  @Input() changedDeskObj:any;  

  dealsData$ = this.store.select(dealsAllData);
  searchData$ = this.store.select(dealsSearchData);

  ngOnChanges(changes: SimpleChanges){
    if(changes['changedDeskObj']?.previousValue?.deskParameter !== changes['changedDeskObj']?.currentValue?.deskParameter){
      this.showTransactionDropdown = false;
      this.showMatrialDropdown = false;
      this.showCycleDropdown = false;
    }
  }

  closeTransactionDropdown(targetElement:any)
  {
    this.showTransactionDropdown = false;
  }

  closeMaterialDropdown(targetElement:any)
  {
    this.showMatrialDropdown = false;
  }

  closeCycleDropdown(targetElement:any)
  {
    this.showCycleDropdown = false;
  }




  constructor(private store: Store) { 
  }

  ngOnInit(){

    
  }


  isTransactionSelected(filter:string)
  {
   let selected = false;
   this.dealsData$.subscribe(filters => {
     selected = filters.selectedFilters.primary.transaction.includes(filter);
   });
   return selected;
  }

  isTransactionDisabled(filter:string)
  {
   let disabled = false;
   this.dealsData$.subscribe(filters => {
    disabled = filters.selectedFilters.primary.transaction.includes('all') && filter != 'all';
   });
   return disabled;
  }

  applyTransactionFilters(filter: any)
  {
    let selectedFilters: any;
    this.dealsData$.subscribe(filters => {
      selectedFilters = {...filters.selectedFilters.primary,transaction:[...filters.selectedFilters.primary.transaction]}
    });
    if(filter === 'all')
    {
      if(selectedFilters.transaction.includes('all')) {
        selectedFilters.transaction = selectedFilters.transaction.filter((f: string)=>f!='all')
      }
      else{
        selectedFilters.transaction = ['all'];

      }
    }
    else
    {
      selectedFilters.transaction = selectedFilters.transaction.filter((f: string)=> f!= 'all');
      if(selectedFilters.transaction.includes(filter)) {
        selectedFilters.transaction = selectedFilters.transaction.filter((f: string)=>f!=filter)
      }
      else{
        selectedFilters.transaction.push(filter);
      }
    }
    this.store.dispatch(applyDDPrimaryFilter({filters:selectedFilters}));
  }

  search(event:Event,tab:string)
  {
    const query = (event.target as HTMLInputElement).value.toLocaleLowerCase();

    if(tab === 'tsw')
    {
       this.dealsData$ = combineLatest([this.searchData$,of(query)]).pipe(
        map(([items,query])=>{
          if(!query)
          {
            return items;
          }
          return {
            ...items,
            filters:{
              ...items.filters,
              primary:{
                ...items.filters.primary,
                material:{
                  ...items.filters.primary.material,
                  tsw: items.filters.primary.material.tsw.filter(item => 
                    item.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                  )
                }
              }
            }

          }
        })
       )
    }
    else if(tab === 'sra')
    {
      this.dealsData$ = combineLatest([this.searchData$,of(query)]).pipe(
        map(([items,query])=>{
          if(!query)
          {
            return items;
          }
          return {
            ...items,
            filters:{
              ...items.filters,
              primary:{
                ...items.filters.primary,
                material:{
                  ...items.filters.primary.material,
                  sra: items.filters.primary.material.sra.filter(item => 
                    item.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                  )
                }
              }
            }

          }
        })
       )
    }


    
   
  }

  selectMaterialTab(tab:string)
  {
    this.store.dispatch(selectMaterialTab({tab}));
  }

   isMaterialSelected(filter:string)
   {
    let selected = false;
    this.dealsData$.subscribe(filters => {
      selected = filters.selectedFilters.primary.material.values.includes(filter);
    });
    return selected;
   }

   isMaterialDisabled(filter:string)
  {
   let disabled = false;
   this.dealsData$.subscribe(filters => {
    disabled = filters.selectedFilters.primary.material.values.includes('all') && filter != 'all';
   });
   return disabled;
  }


  applyMaterialFilters(filter: any)
  {
    let selectedFilters: any;
    this.dealsData$.subscribe(filters => {
      selectedFilters = {...filters.selectedFilters.primary,
        material:{...filters.selectedFilters.primary.material,
          values:[...filters.selectedFilters.primary.material.values]}}
    });

    if(filter === 'all')
    {
      
      if(selectedFilters.material.values.includes('all')) {
        selectedFilters.material.values = selectedFilters.material.values.filter((f: string)=>f!='all')
      }
      else{
        selectedFilters.material.values = ['all'];
      }
    }
    else{
      selectedFilters.material.values = selectedFilters.material.values.filter((f: string) => f != 'all');
      if(selectedFilters.material.values.includes(filter)) {
        selectedFilters.material.values = selectedFilters.material.values.filter((f:string) => f != filter);
      }
      else{
        selectedFilters.material.values.push(filter);
      }
    }
    this.store.dispatch(applyDDPrimaryFilter({filters:selectedFilters}));

  }



  isCycleSelected(filter:string)
  {
   let selected = false;
   this.dealsData$.subscribe(filters => {
     selected = filters.selectedFilters.primary.cycle.includes(filter);
   });
   return selected;
  }

  isCycleDisabled(filter:string)
  {
   let disabled = false;
   this.dealsData$.subscribe(filters => {
    disabled = filters.selectedFilters.primary.cycle.includes('all') && filter != 'all';
   });
   return disabled;
  }


  applyCycleFilters(filter: any)
  {
    let selectedFilters: any;

    this.dealsData$.subscribe(filters => {
      selectedFilters = {...filters.selectedFilters.primary,cycle:[...filters.selectedFilters.primary.cycle]}
    });
    if(filter === 'all')
    {
      if(selectedFilters.cycle.includes('all')) {
        selectedFilters.cycle = selectedFilters.cycle.filter((f: string)=>f!='all')
      }
      else{
        selectedFilters.cycle = ['all'];
      }
    }
    else
    {
      selectedFilters.cycle = selectedFilters.cycle.filter((f: string)=> f!= 'all');
      if(selectedFilters.cycle.includes(filter)) {
        selectedFilters.cycle = selectedFilters.cycle.filter((f: string)=>f!=filter)
      }
      else{
        selectedFilters.cycle.push(filter);
      }
    }
    this.store.dispatch(applyDDPrimaryFilter({filters:selectedFilters}));

  }

  




  toggleViewDropdown(filter:string) {
    switch (filter) {
      case 'transaction':
        this.showTransactionDropdown = !this.showTransactionDropdown;
        this.showMatrialDropdown = false;
        this.showCycleDropdown = false;
        break;
      case 'material':
        this.showMatrialDropdown = !this.showMatrialDropdown;
        this.showTransactionDropdown = false;
        this.showCycleDropdown = false;
        break;
      case 'cycle':
        this.showCycleDropdown = !this.showCycleDropdown;
        this.showTransactionDropdown = false;
        this.showMatrialDropdown = false;
        break;
    
      default:
        this.showTransactionDropdown = false;
        this.showMatrialDropdown = false;
        this.showCycleDropdown = false;
        break;
    }
  }

}
