import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';



@Component({
  selector: 'add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent implements OnInit, OnChanges {


  allTagOptions: string[] = ['General', 'Decision Capture', 'Date', 'Volume']
  @Input() selectedTags: string[] = [];
  selectableTagOptions: string[] = [];
  @Output() selectedTag: EventEmitter<string> = new EventEmitter<string>();
  @Output() lengthOfAllOptions: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('selectElement') selectElement!: ElementRef; 
    
  listVisible: boolean = false;

  constructor(private changeDet: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.lengthOfAllOptions.emit(this.allTagOptions.length);
    let allSet = new Set(this.allTagOptions);
    let selectedTagSet = new Set(this.selectedTags);
    this.selectableTagOptions = Array.from(this.setDiff(allSet, selectedTagSet))
  }

  ngOnChanges(){
    let allSet = new Set(this.allTagOptions);
    let selectedTagSet = new Set(this.selectedTags);

    this.selectableTagOptions = Array.from(this.setDiff(allSet, selectedTagSet))
  }

  setDiff(firstSet: Set<string>, secondSet: Set<string>){

    let differenceSet = new Set(firstSet);

    for(let element of secondSet){
      differenceSet.delete(element);
    }

    return differenceSet;
  }

  addTag(event: Event){
    const selectElement = event.target as HTMLSelectElement;   
    if(selectElement.value != ''){
      this.selectedTag.emit(selectElement.value);
    }
    this.selectElement.nativeElement.focus();
  }

  showList(){
    this.listVisible = true;
    setTimeout(() => {
      if (this.selectElement) {
        this.selectElement.nativeElement.focus();
      }
    }, 0); 
  }

  showAddButton(){
    if (this.listVisible) {
      this.listVisible = false;
    }
  }
  

}
