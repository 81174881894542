<div class="create-nominations-panel-wrapper">
    <div class="panel-header d-flex justify-content-between">
        <div class="panel-title">nominations</div>
        <button class="nominations-btn nominations-panel nominations" (click)="closeNominationsPanel()">
            <span class="icon-remove open"></span>
        </button>
    </div>
    <div class="panel-content">
        <div class="accordion-item">
            <div class="accordion-header" [class.highlight-header]="draftNominations.length > 0"
                (click)="expandDraftAccordion()">
                draft({{draftNominations.length}})<span class="expand-icon"
                    *ngIf="!isDraftAccordionExpanded">+</span><span class="expand-icon"
                    *ngIf="isDraftAccordionExpanded">-</span>
            </div>
            <div class="accordion-content" *ngIf="isDraftAccordionExpanded">
                <div class="draft-nominations" *ngFor="let draftNom of draftNominations; let i =index">
                    <div class="row counter-part" [ngClass]="{'highlight_counterpart': getMatchedlineItem?.id=== draftNom?.id}">
                        <div class="col-md-10 offtaker">
                            <span class="content-header cpname">{{draftNom.counterPart}}</span>
                            <span class="content-body">Created on {{draftNom.detailType[0].createdOn}}</span>
                        </div>
                        <div class="col-md-2">
                            <div class="list">
                                <button class="ellipses-icon"></button>
                                <div class="list-content">
                                    <a class="list-item create-nomination"
                                        (click)="onCreateDraftNomination(draftNom)">create
                                        nomination</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="draftNom.detailType.length === 0 || (draftNom.detailType.length > 0 && (draftNom.counterPart !== 'TERPEL' && draftNom.counterPart !== 'DELTA'))">
                        <span class="content-header">nomination volume</span>
                        <input type="number" class="form-control content-body" name="scheduledQty"
                            [(ngModel)]="draftNom.scheduledQty">
                    </div>
                    <div *ngIf="draftNom.detailType.length > 0 && draftNom.counterPart === 'TERPEL'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                                    (input)="onInput($event, i, draftNom.inventoryDetailType[0]?.id, 'inventory')">{{
                                    draftNom.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">reserve volume</span>
                                <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                                    (input)="onInput($event, i, draftNom.reserveDetailType[0]?.id, 'reserve')">{{
                                    draftNom.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">heels volume</span>
                                <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                                    (input)="onInput($event, i, draftNom.heelsDetailType[0]?.id, 'heel')">{{
                                    draftNom.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{ draftNom.product }}</span>
                            </div>
                        </div>
                        <div>
                            <span class="content-header">destination date</span>
                            <span class="content-body" name="destinationDate">{{draftNom?.destinationDate}}</span>
                        </div>
                    </div>
                    <div *ngIf="draftNom.detailType.length > 0 && draftNom.counterPart === 'DELTA'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                                    (input)="onInput($event, i, draftNom.inventoryDetailType[0]?.id, 'inventory')">{{
                                    draftNom.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">safety volume</span>
                                <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                                    (input)="onInput($event, i, draftNom.safetyDetailType[0]?.id, 'safety')">{{
                                    draftNom.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{draftNom.product}}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">destination date</span>
                                <span class="content-body" name="destinationDate">{{draftNom?.destinationDate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="draftNom.detailType.length === 0 || (draftNom.detailType.length > 0 && (draftNom.counterPart !== 'TERPEL' && draftNom.counterPart !== 'DELTA'))">
                        <div class="col-md-6">
                            <span class="content-header">product</span>
                            <span class="content-body">{{draftNom.product}}</span>
                        </div>
                        <div class="col-md-6">
                            <span class="content-header">destination date</span>
                            <span class="content-body" name="destinationDate">{{draftNom?.destinationDate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" [class.highlight-header]="publishedNominations.length > 0"
                (click)="expandPublishedAccordion()">
                published({{publishedNominations.length}})<span class="expand-icon"
                    *ngIf="!isPublishedAccordionExpanded">+</span><span class="expand-icon"
                    *ngIf="isPublishedAccordionExpanded">-</span>
            </div>
            <div class="accordion-content" *ngIf="isPublishedAccordionExpanded">
                <div class="published-nominations" *ngFor="let publishedNom of publishedNominations; let i =index">
                    <div class="row" [ngClass]="{'highlight_counterpart-published': getMatchedlineItem?.id=== publishedNom?.id}">
                        
                        
                        <div class="col-md-12 accordion-item-internal">
                            <div class="accordion-header counter-part" (click)="expandPublishedAccordionInternal(publishedNom?.id)">
                                <div class="row headerPadding">
                            
                                    <div class="col-md-1 alligncentercontent">
                                        <span class="expand-icon"
                                   *ngIf="isPublishedAccordionExpandedInternal==publishedNom?.id; else PubiconName">-</span>
             
                                   <ng-template #PubiconName>
                                     <span class="expand-icon">+</span>
                                 </ng-template>
                                   </div>

                                    <div class="col-md-9 offtaker">
                                        <span class="content-header cpname">{{publishedNom?.counterPart}} </span>
                                        <span class="content-body">Created on {{publishedNom?.detailType[0]?.createdOn}}</span>
                                     
                                      </div>
                                         

                                          <div class="col-md-1 alligncentercontent">
                                            <div class="list">
                                                <button class="ellipses-icon"></button>
                                                <div class="list-content">
                                                    <a class="list-item create-nomination" (click)="onCreateDraftNomination(publishedNom)">create nom from reference</a>
                                                    <a class="list-item go-to-nomination" (click)="goToNominations(publishedNom)">go to
                                                        nominations</a>
                                                    <a class="list-item go-to-nomination" (click)="delete(publishedNom)">delete</a>
                                                </div>
                                            </div>
                                        </div>

                            
                            </div>
                        </div> 
                        </div>           

                      
                    </div>

                    <div class="accordion-content" *ngIf="isPublishedAccordionExpandedInternal==publishedNom?.id">
                    <div class="row">
                        <div class="col-md-6">
                            <span class="content-header">nomination #</span>
                            <span class="content-body">{{publishedNom?.nominationNo}}</span>
                        </div>   
                        <div class="col-md-6">
                            <span class="content-header">vessel name</span>
                            <span class="content-body"></span>
                        </div>                    
                    </div>   
                    <div class="row">
                        <div class="col-md-6">
                            <span class="content-header">origin date</span>
                            <span class="content-body" *ngIf="!publishedNom?.originDate">- -</span>
                            <span class="content-body" *ngIf="publishedNom?.originDate">{{ publishedNom?.originDate?.substring(4, 6) }}/{{
                                publishedNom?.originDate?.substring(6, 8) }}</span>
                        </div>
                        <div class="col-md-6">
                            <span class="content-header">destination date</span>
                            <span class="content-body" name="destinationDate">{{publishedNom?.destinationDate }}</span>
                        </div>
                    </div>
                    <div *ngIf="publishedNom?.detailType.length === 0 || (publishedNom?.detailType.length > 0 && (publishedNom?.counterPart !== 'TERPEL' && publishedNom?.counterPart !== 'DELTA'))">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span class="content-body">{{publishedNom?.scheduledQty}}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{publishedNom?.product}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="publishedNom?.detailType.length > 0 && publishedNom?.counterPart === 'TERPEL'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span class="content-body">{{
                                    publishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">reserve volume</span>
                                <span class="content-body">{{
                                    publishedNom?.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                            <span class="content-header">heels volume</span>
                            <span class="content-body">{{
                                publishedNom?.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{publishedNom?.product}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="publishedNom?.detailType.length > 0 && publishedNom?.counterPart === 'DELTA'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span class="content-body">{{
                                    publishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">safety volume</span>
                                <span class="content-body">{{
                                    publishedNom?.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{publishedNom?.product}}</span>
                            </div>
                        </div>
                    </div> 
                </div>              
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" [class.highlight-header]="unpublishedNominations.length > 0"
                (click)="expandUnpublishedAccordion()">
                unpublished({{unpublishedNominations.length}})<span class="expand-icon"
                    *ngIf="!isUnpublishedAccordionExpanded">+</span><span class="expand-icon"
                    *ngIf="isUnpublishedAccordionExpanded">-</span>
            </div>
            <div class="accordion-content" *ngIf="isUnpublishedAccordionExpanded">
                <div class="unpublished-nominations"
                    *ngFor="let unpublishedNom of unpublishedNominations; let i =index">
                    <div class="row" [ngClass]="{'highlight_counterpart-unpublished': getMatchedlineItem?.id=== unpublishedNom?.id}">
                        
                        <div class=" col-md-12 accordion-item-internal">
                            <div class="accordion-header counter-part" (click)="expandUnPublishedAccordionInternal(unpublishedNom?.id)">
                        <div class="row headerPadding">

                            <div class="col-md-1 alligncentercontent">
                                <span class="expand-icon"
              *ngIf="isUnPublishedAccordionExpandedInternal==unpublishedNom?.id; else UnpubiconName">-</span>

              <ng-template #UnpubiconName>
                <span class="expand-icon">+</span>
            </ng-template>
                              </div>
                            <div class="col-md-9 offtaker">
                                <span class="content-header cpname">{{unpublishedNom?.counterPart}}</span>
                                <span class="content-body">Created on {{unpublishedNom?.detailType[0]?.createdOn}}</span>
                             
                                                  </div>
                                                
                                                  <div class="col-md-1 alligncentercontent">
                                                    <div class="list">
                                                        <button class="ellipses-icon"></button>
                                                        <div class="list-content">
                                                            <a class="list-item create-nomination"
                                                                (click)="onCreateDraftNomination(unpublishedNom)">create nom from reference</a>
                                                            <a class="list-item go-to-nomination" (click)="goToNominations(unpublishedNom)">go to
                                                                nominations</a>
                                                            <a class="list-item go-to-nomination" (click)="delete(unpublishedNom)">delete</a>
                                                        </div>
                                                    </div>
                                                </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-content" *ngIf="isUnPublishedAccordionExpandedInternal==unpublishedNom?.id">
                    <div class="row">
                        <div class="col-md-6">
                            <span class="content-header">nomination #</span>
                            <span class="content-body">{{unpublishedNom?.nominationNo}}</span>
                        </div> 
                        <div class="col-md-6">
                            <span class="content-header">vessel name</span>
                            <span class="content-body"></span>
                        </div>                     
                    </div>  
                    <div class="row">
                        <div class="col-md-6">
                            <span class="content-header">origin date</span>
                            <span class="content-body" *ngIf="!unpublishedNom?.originDate">- -</span>
                            <span *ngIf="unpublishedNom?.originDate" class="content-body">{{ unpublishedNom?.originDate?.substring(4, 6) }}/{{
                                unpublishedNom?.originDate?.substring(6, 8) }}</span>
                        </div>
                        <div class="col-md-6">
                            <span class="content-header">destination date</span>
                            <span class="content-body" name="destinationDate">{{unpublishedNom?.destinationDate }}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="unpublishedNom?.detailType.length === 0 || (unpublishedNom?.detailType.length > 0 && (unpublishedNom?.counterPart !== 'TERPEL' && unpublishedNom?.counterPart !== 'DELTA'))">
                        <div class="col-md-6">
                            <span class="content-header">utilization volume</span>
                            <span class="content-body">{{unpublishedNom?.scheduledQty}}</span>
                        </div>
                        <div class="col-md-6">
                            <span class="content-header">product</span>
                            <span class="content-body">{{unpublishedNom?.product}}</span>
                        </div>
                    </div>
                    <div *ngIf="unpublishedNom?.detailType.length > 0 && unpublishedNom?.counterPart === 'TERPEL'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span class="content-body">{{
                                    unpublishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">reserve volume</span>
                                <span class="content-body">{{
                                    unpublishedNom?.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">heels volume</span>
                                <span class="content-body">{{
                                unpublishedNom?.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                             </div> 
                             <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{unpublishedNom?.product}}</span>
                            </div>  
                        </div>
                    </div>
                    <div *ngIf="unpublishedNom?.detailType.length > 0 && unpublishedNom?.counterPart === 'DELTA'">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">utilization volume</span>
                                <span class="content-body">{{
                                    unpublishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                            <div class="col-md-6">
                                <span class="content-header">safety volume</span>
                                <span class="content-body">{{
                                    unpublishedNom?.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="content-header">product</span>
                                <span class="content-body">{{unpublishedNom?.product}}</span>
                            </div>
                        </div>
                    </div>   
                </div>             
                </div>
            </div>
        </div>

       
    </div>
</div>