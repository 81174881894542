import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import 'ag-grid-enterprise';
import { CalAngularModule } from '@cvx/cal-angular';
import { AgGridModule } from 'ag-grid-angular';
import { NextPage } from '@cvx/nextpage';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AuthTokenServiceInterceptor } from './auth-token-service.interceptor';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/modules/shared.module';
import { FullWidthCellRenderer } from './shared/modules/FullWidthCellRenderer/FullWidthCellRenderer';

import { CommonModule, DecimalPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducer } from './shared/store/reducer/app.reducer';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppEffect } from './shared/store/effects/app.effect';
import { FormControlValidationMsgDirective } from '../app/shared/directives/form-validation-messages.directive';
import { ValidationMsgService } from '../app/services/validation-messages.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomViewSidePanelComponent } from './core/components/custom-view-side-panel/custom-view-side-panel.component';
import { CreateCustomViewComponent } from './core/components/custom-view-side-panel/create-custom-view/create-custom-view.component';
import { EditCustomViewComponent } from './core/components/custom-view-side-panel/edit-custom-view/edit-custom-view.component';
import { ManageCustomViewComponent } from './core/components/custom-view-side-panel/manage-custom-view/manage-custom-view.component';
import { MatTooltipModule, MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { RealTimeAlertService } from './services/real-time-alert.service';
import { MyMonitoringService } from './services/logging.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActivityTableComponent } from './core/components/activity-tracker/old AT/activity-table/activity-table.component';
import { GrayHeaderComponent } from './shared/hide-headers/gray-header/gray-header.component';
import { LightHeaderComponent } from './shared/hide-headers/light-header/light-header.component';
import { PopupWindowService } from './services/popup-window.service';
import { PopoutWindowModule } from 'angular-popout-window';
import { CustomDateRangePickerComponent } from './shared/custom-date-range-picker/custom-date-range-picker.component';
import { FilterDropdownComponent } from './shared/filter-dropdown/filter-dropdown.component';
import { CloseDropdownDirective } from './shared/filter-dropdown/directives/close-dropdown.directive';

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}

@NgModule({
  declarations: [AppComponent, HomeComponent, FullWidthCellRenderer, 
    FormControlValidationMsgDirective, CustomViewSidePanelComponent,
     CreateCustomViewComponent, EditCustomViewComponent, ManageCustomViewComponent,
     FilterDropdownComponent],
  imports: [
    CalAngularModule.forRoot({
      autoSignIn: true,
      popupForLogin: false,
      cacheLocation: 'localStorage',
      instance: 'https://login.microsoftonline.com/',
      tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
      clientId: environment.clientID,
      // redirectUri will need to match a redirectUri setup in your app registration
      //"redirectUri": "http://localhost:4200",
      redirectUri: environment.calRedirectURI,
      oidcScopes: ['openid', 'profile', 'User.Read', 'offline_access'],
      graphScopes: ['User.Read', 'User.Read.All'],
    }),
    BrowserModule,
    AppRoutingModule,
    NextPage,
    AgGridModule,
    SharedModule,
    CommonModule,
    StoreModule.forRoot({ appState: appReducer }),
    EffectsModule.forRoot([AppEffect]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    //StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    NoopAnimationsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    PopoutWindowModule,

    BrowserAnimationsModule,
    CustomDateRangePickerComponent,
    CloseDropdownDirective
  ],
  providers: [
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenServiceInterceptor,
      multi: true,
    },
    MyMonitoringService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    ValidationMsgService,
    MyMonitoringService,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions},
    RealTimeAlertService,
    PopupWindowService

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

