import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, map, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EthanolTruckService {
  public modalPopoOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public customViewPayload$: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  public motToggleSwitch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getTruckVolume$: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  constructor(private apiService: ApiService) { } 
  getEthanolTruckInformation(payload:any, isPivotActive: any): Observable<any> {
    let pivotName = isPivotActive.charAt(0).toUpperCase() + isPivotActive.slice(1)
    let url = ''
    if(isPivotActive === 'supplier' || isPivotActive === 'carrier'){
      url = `${environment.replenishmentPlanningAPI}/GetEthonalPivotDataAsync?type=` + pivotName;
    }else {
      url = `${environment.replenishmentPlanningAPI}/GetEthonalDataAsync?`
    }
    return this.apiService
      .post(
        url,
        'replenishmentPlanning',
        payload
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getTruckTerminalFilterList(ownership?:any, getType?: any, includeXLocations?: any): Observable<any> {
    return this.apiService.get(
      `${environment.replenishmentPlanningAPI}/GetEthanolFilterData?type=${getType}&ownerShip=${ownership}&includeXLocations=${includeXLocations}`,
      'replenishmentPlanning'
    ).pipe(
      map((data) => {
        return data;
      })
    );
  }
  
  getTruckSupplierCarrierFilterList(payload:any): Observable<any> {

    return this.apiService.post(
      `${environment.replenishmentPlanningAPI}/GetSupplierCarrierList`,
      'replenishmentPlanning',
      payload
    ).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveEthanolTRData(updatedEthanolTRBody: any[]): Observable<any> {
    return this.apiService
      .post(
        `${environment.replenishmentPlanningAPI}/SaveReplenishmentPanamaData`,
        'replenishmentPlanning',
        updatedEthanolTRBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveEthanolNominationData(updatedEthanolNominationBody: any[]): Observable<any> {
    return this.apiService
      .patch(
        `${environment.nominationAPI}/UpdateNominations`,
        'nomination',
        updatedEthanolNominationBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveEthanolNominationDataAsDraft(updatedEthanolNominationBody: any[]): Observable<any> {
    return this.apiService
      .post(
        `${environment.nominationAPI}/CreateNomination?`,
        'nomination',
        updatedEthanolNominationBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

   getEthanolModalPopupData(desk: any){
    return this.apiService.get(`${environment.nominationAPI}/GetEthanolModelPopUpRefData?desk=${desk}`, 'nomination').pipe(map((response) => {return response}));
  }
}

