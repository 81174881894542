import {
  Component,
  Input,
  forwardRef,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
@Component({
  selector: "app-select-dropdown",
  templateUrl: "select-dropdown.component.html",
  styleUrls: ["select-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements ControlValueAccessor {
  list = [];
  temp_list = [];
  keyword = "";
  selectedOptions: any = []
  mouseEvent: MouseEvent | undefined
  @Output() afterChange = new EventEmitter();
  @Output() triggerClick = new EventEmitter();
  @ViewChild("input", { static: false }) input!: ElementRef;
  @ViewChildren("checkboxes") checkboxes!: QueryList<ElementRef>;
  @ViewChild("dropdownContainer") dropdownContainer!:ElementRef;
  @Input('action') action:any;
  @Input("items") set items(value: any) {
    this.list = value;
    this.temp_list = value;
  }
  @Input("disabled") disabledStatus: any;
  @Input("defaultValue") defaultValue: any;
  @Input("multiple") multiple: any;
  onChange: any = () => { };
  onTouch: any = () => { };
  value: any = "select";
  focusedIndex = 0;
  shown = false;
  constructor(private ele: ElementRef) {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.defaultValue) {
      this.select(this.defaultValue, false)
    }
    else {
      this.value = 'select'
    }
    if (this.defaultValue && !this.disabledStatus) {
      this.disabledStatus = false;
    }
  }
  writeValue(value: any) {
    return;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  search(e: string) {
    const val = e?.length > 0 ? e?.toLowerCase() : "";
    const temp = this.temp_list?.filter((x: any) => {
      if (x?.length > 0 && x?.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.list = temp;
  }
  select(item: string, openDropdown:boolean=true) {
    this.value = item;
    if(openDropdown){
      this.shown = false;
    }
    this.keyword = '';
    this.search('');

    if (item === 'select') {
      this.value = "select"
      if(openDropdown){
        this.shown = false;
      }
      item = "";
    }
    if (!this.multiple) {
      this.onChange(item);
      this.afterChange?.emit(item);
    }
  }

  onKeyDown(event:KeyboardEvent){
   const key = event.key;
   if(key.toLowerCase() === 'arrowdown'){
    this.focusNext();
   }
   else if(key.toLowerCase() === 'arrowup'){
    this.focusPrev();
   }
   else if(key.toLowerCase() === 'enter'){
    this.seelectItem(this.focusedIndex);
   }
  }

  seelectItem(index:number){
    const element =  this.ele.nativeElement.querySelectorAll(".list-holder span")[index] as HTMLElement;
    if (element) {
      let text:any = element.textContent;
      this.select(text);
     }
  }

  focusPrev(){
    if(this.focusedIndex > 0){
      this.focusedIndex--;
      this.focusItem(this.focusedIndex);
    }
  }

  focusNext(){
      if(this.focusedIndex < this.list.length){
        this.focusedIndex++;
        this.focusItem(this.focusedIndex);
      }
  }

  focusItem(index: number) {
    const element = this.ele.nativeElement.querySelectorAll(".list-holder span")[index] as HTMLElement;
    if (element) {
      element.focus();
    
    const dropdownContainer =  this.ele.nativeElement.querySelector(".search-dropdown__dropdown__scroller") as HTMLElement;
     dropdownContainer.scrollTop = index * 40 - 20;
  }
  
  }

  show() {
    this.shown = !this.shown;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);

    if(this.action === 'contract'){
      this.triggerEvent();
    }

  }
  resetInput() {
    this.keyword = '';
    this.value = "select";
  }
  closeDropdown() {
    this.shown = false;
  }
  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedOptions = [];
    this.onChange(this.selectedOptions.toString());
    this.afterChange.emit("");
  }
 
  focus(){
    this.dropdownContainer.nativeElement.focus();
   }

  changeSelection(event: any) {
    let value = event.target.value;
    if (!this.selectedOptions.includes(value)) {
      this.selectedOptions.push(value);
    }
    else {
      const index = this.selectedOptions.indexOf(value);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
    }

    if (this.selectedOptions.length > 0) {
      this.value = this.selectedOptions.join("#")
      this.onChange(this.value);
      this.afterChange.emit();
    }
    else {
      this.onChange(this.selectedOptions.toString());
      this.afterChange.emit("");
    }
  }
  triggerEvent(){ 
    this.triggerClick.emit()
  }
  @HostListener("document:click", ["$event"]) onClick(e: { target: any; }) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
}
}
