
export interface CommentI {
    commentType: commentType,
    commentTypeId: string,  //UID of the nom/CCA CArgo Plan of interest
    commentText: string,
    commentTag: {
        volume: boolean,
        decisionCapture: boolean,
        date: boolean,
        general: boolean,
    },
    email: string,
    createdBy: string, 
    createdDate: string,
    updatedDate: string, 
    isActive: boolean,
    id?: string,
}

export enum commentType{
    NOM= 'nomination',
    CCA= 'cca',
    Car= 'cargoPlan',
    UND= 'undeclared',
}

export interface tagType{
    volume: boolean,
    decisionCapture: boolean,
    date: boolean,
}