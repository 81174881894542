<div class="d-flex flex-column">

    <div class="header d-flex flex-row justify-content-between align-items-center overflowScroll">
        <p style="margin-bottom: 0px; padding: 0 15px;" class="header-text">{{data.name}}</p>
        <p style="margin-bottom: 0px;">{{dateFormat}}</p>
    </div>

    <div class="d-flex flex-row justify-content-left overflowScroll">    
        <p *ngFor="let header of headers; let i = index" (click)="updateSelection(i)"
            class="m-0 mt-3 mr-2 ml-1 header-size"
            [ngClass]="this.selectedTabIndex == i ? 'tab-button-selected': 'tab-button'">
            <img [src]="header.displayIcon"/> {{header.displayValue}}
        </p>
    </div>
    <hr class="seletion-line m-0 mb-2">

    <activity-tracker *ngIf="selectedSelector == 'activityTracker'"
        [screenInformation]="data">
    </activity-tracker>  
    <comment-panel *ngIf="selectedSelector == 'comments'"
        [data]="data">
    </comment-panel> 
    <div *ngIf="selectedSelector != 'activityTracker' && selectedSelector != 'comments'">Functionality coming soon</div>      
</div>