import { createAction, props } from "@ngrx/store";
import { CreateDeskTsMapping, DeskTransportFilters, TransportData, TransportFunctionalGroup, TransportSystemRequest } from "../../../shared/interface/mdm-admin.interface";

export const loadDeskTs = createAction(
  '[Desks Transport Data API] Load Desks Transport Data',
);

export const loadDeskTsSuccess = createAction(
  '[Desks Transport Data API] Load Desks Transport Data API Success',
  props<{ deskTs: TransportFunctionalGroup[] }>(),
);

export const getTransport = createAction(
  '[Transport Data API] Load Transport Data',
  props<{ payload: TransportSystemRequest }>(),
);

export const getTransportSuccess = createAction(
  '[Transport Data API] Load  Transport Data API Success',
  props<{ transport: TransportData[] }>(),
);

export const loadDeskTsFailure = createAction(
  '[Desks Transport Data API] Load Desks Transport Data API Failure',
  props<{ error: any }>(),
);

export const resetTransportScreen = createAction(
  '[Reset Transport Location Screen] Reset Transport Location Screen'
);

export const createDeskTsMapping = createAction(
  '[Create Desks Transport Mapping Data API] Create desk Transport mapping',
  props<{ createDeskTsId : CreateDeskTsMapping }>(),
);
export const createDeskTsMappingSuccess = createAction(
  '[Create Desks Transport Mapping Data API] Create desk Transport mapping success',
  props<{ newDeskTsId : number }>(),
);

export const recievedNewDeskTsId = createAction(
  '[Create Desks Transport Mapping Data API] Get new desk Transport ID'
);

export const setDefaultTransportsFilter = createAction(
  '[Desks Filter] Set Default Desks Filter',
  props<{ transportFilter: DeskTransportFilters, deskTs: TransportFunctionalGroup[]}>()
);

export const updateTransportsFilter = createAction(
  '[Desks Filter] Update Desks Filter',
  props<{ transportFilter: DeskTransportFilters, deskTs: TransportFunctionalGroup[]}>()
);

export const transportsFilterUpdated = createAction(
  '[Desks Filter] Set updateDesksFilter',
  props<{ updateTransportFilter: boolean }>()
);

export const updateTransportsTable = createAction(
  '[Desks Filter] Set updateDesksTable',
  props<{ deskTs: TransportFunctionalGroup[], updateTransportsTable: boolean, initializeTransportFilter: boolean }>()
);

export const deleteDeskTsMapping = createAction(
  '[Delete Desks Ts Data API] Delete desk Ts mapping',
  props<{ id : number }>(),
);
export const deleteDeskTsMappingSuccess = createAction(
  '[Delete Desks Ts Data API] Delete desk Ts mapping success'
);

export const deskTsDeleted = createAction(
  '[Delete Desks Ts Data API] Delete desk Ts mapping from grid'
);