import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CargoPlanningCcaService {
  public isCargoCcaScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getCargoFilterResponse:any;
  public isCCACargoModalOpen$ : BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  constructor(private apiService: ApiService) { }

  getFilterCargoPlanningCca(postData: any) {
    return this.apiService
      .post(
        `${environment.ccaCargoApi}/GetCCACargoPlans`, 'cargoPlanning', postData
      )
      .pipe();
  }

  updateCargoPlanningCca(postData: any) {
    return this.apiService
      .patch(
        `${environment.ccaCargoApi}/UpdateCcaCargoPlan`, 'cargoPlanning', postData
      )
      .pipe(shareReplay());
  }

  publishCargoPlanningCca(postData: any) {
    return this.apiService
      .post(
        `${environment.ccaCargoApi}/PublishCcaCargoPlan`, 'cargoPlanning', postData
      )
      .pipe(shareReplay());
  }

  updateLinkNominationWithCargoCca(postData: any) {
    return this.apiService
      .patch(
        `${environment.ccaCargoApi}/LinkCargoWithNomination `, 'cargoPlanning', postData
      )  
  }
}
