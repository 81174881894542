import { Component, OnInit, Input } from '@angular/core';
import { animate, state, trigger, style, transition } from '@angular/animations';

@Component({
  selector: 'expand-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
  animations: [
    trigger('trans-expand', [
      state('smallCard', style({
        height: '80px',
        width: '200px',
      })),
      state('largeCard', style({
        height: '200px',
        width: 'flex: 0 0 100%',
      })),
      transition('smallCard <=> largeCard', [
        animate('0.5s')
      ])
    ])
  ]
})
export class FlipCardComponent implements OnInit {

  cardExpanded: boolean = false;
  @Input() hasContent: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  toggleExpand(){
    this.cardExpanded = !this.cardExpanded;
  }

}
