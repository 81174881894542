import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { SelectDropdownComponent } from 'src/app/shared/modules/select-dropdown/select-dropdown.component';
import { NominationService } from '../../../services/nomination.service';
import * as Nom from '../../../shared/constants/nominations.constant';
import { select, Store } from '@ngrx/store';
import { triggerTRSave } from 'src/app/shared/store/action/app.action';
import { createNominationData, selectDesk, selectUDMScreen } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { Subject, takeUntil, firstValueFrom } from 'rxjs';
import { TRScreen , ccaSpecific } from 'src/app/shared/constants/terminal-replenishment.constant';
import { Router } from '@angular/router';
import { MyMonitoringService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-create-terminal-nomination',
  templateUrl: './create-treminal-nomination.component.html',
  styleUrls: ['./create-treminal-nomination.component.scss']
})
export class CreateTreminalNominationComponent implements OnInit {
  public masterData!: any;
  public createTerminalNominationForm!: FormGroup;
  referenceData!: any;
  inValidDestinationLocation: boolean = false;
  invalidDestinationLocationsg: string = '';
  @Output() closeModalPopup = new EventEmitter();
  @Output() refreshGrid = new EventEmitter();
  @ViewChild("SelectDropdownComponent") selectDropdown!: SelectDropdownComponent;
  nominationData!: any;
  public nominationTypeDropdown!: any[];
  public referenceDocumentDropdown = ["G", "K"];
  public productDropdown: any[] = [];
  public counterPartDropdown = ["CHEVRON", "REFPAN", "TERPEL", "DELTA"];
  public transportationDropdown = ["TankTransfer", Nom.VESSEL, Nom.PIPELINE];
  public destinationLocationDropdown: any[] = [];
  public transportSystemDropdown: any[] = [];
  public referenceNominations: any;
  public createNominationReference: boolean = false;
  public userDetails!: any;
  deskId: any;
  desk: any;
  createNomination$ = this.store.pipe(select(createNominationData));
  trNomination!: any;
  productGroup: FormGroup<any> = this.fb.group({
    productName: ['', [Validators.required]],
    udmDetailType: ['', [Validators.required]],
    scheduledQty: ['', [Validators.required]]
  });
  formattedDate!: any;
  getDestinationLocationDropdown: any;
  getRefData: any;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();
  isCCAPanamaView: any;
  currentScreen: any;
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  createNomFromDraftId: any;

  constructor(private store: Store, private fb: FormBuilder, public nominationService: NominationService, private changeDetection: ChangeDetectorRef, private datePipe: DatePipe, private toasterService: ToastService, private utilities:Utilities, private router: Router, private myMonitoringService: MyMonitoringService) {
    this.createTerminalNominationForm = this.fb.group({
      nominationNo: ['', [Validators.required, Validators.maxLength(16)]],
      modeOfTransport: ['', [Validators.required]],
      nominationType: ['', [Validators.required]],
      counterPart: ['', [Validators.required]],
      product: ['', [Validators.required]],
      detailTypeRequest: this.fb.array([]),
      destinationLocation: ['', [Validators.required]],
      transportSystem: ['', Validators.required],
      destinationDate: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.userDetails = this.utilities.getUserName(this.nominationService.userDetails$.value._email);
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk;
          this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.desk);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
        },
      });
      this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.getNominationRefData();
    if(this.isCCAPanamaView) {
      this.transportationDropdown = ["TankTransfer", Nom.PIPELINE];
    }
  }

  getNominationRefData() {
    if (this.destinationLocationDropdown?.length === 0) {
      this.nominationService.getNominationsRefData(["locationAssignedTransSys", "locations"]).subscribe(data => {
        this.masterData = data.result;
        data.result.map((data: any) => {
          if (data.BusinessLocationId) {
            this.getDestinationLocationDropdown = JSON.parse(JSON.stringify(data.BusinessLocationId))
            this.destinationLocationDropdown.push(data.BusinessLocationId);
          }
        });
      });
    }
  }

  closePopup() {
    this.createTerminalNominationForm.reset({
      nominationNo: '',
      modeOfTransport: '',
      nominationType: '',
      counterPart: '',
      product: '',
      detailTypeRequest: [],
      destinationLocation: '',
      transportSystem: '',
      destinationDate: '',
    });
    while (this.detailTypeRequest.length !== 0) {
      this.detailTypeRequest.removeAt(0)
    }
    
    this.createNominationReference = false;
    this.invalidDestinationLocationsg = "";
    this.getRefData = null;
    this.referenceData = null;
    this.selectDropdown?.resetInput();
    this.trNomination = null;
    this.referenceNominations = null;
    this.changeDetection.detectChanges();
    this.closeModalPopup.emit(false);
  }

  onValueChangeOfTransportSystem(event: any) {
    this.inValidDestinationLocation = false;
    this.invalidDestinationLocationsg = "";
    this.transportSystemDropdown = [];
    this.masterData?.forEach((data: any) => {
      if (event === data.LocationId) {
        this.transportSystemDropdown.push(data.TransportSystem);
      }
    });
  }

  get detailTypeRequest() {
    this.changeDetection.detectChanges();
    return this.createTerminalNominationForm.get('detailTypeRequest') as FormArray;
  }

  defineNominationTypeForPanamaCCA(refDoc: any) {
    if (refDoc?.length > 1) {
      return "ITK + ITG";
    } else {
      return `IT ${refDoc[0]}`
    }
  }

  async createNominationWithReferenceForPanamaCCA(data: any) {
    this.getRefData = JSON.parse(JSON.stringify(data.headerToItemNav[0]));
    const headerToItemNav = data.headerToItemNav[0]
    this.createNomFromDraftId = data.id;
    if (this.nominationService.ccaEditNomination) {
      if (data.nominationNo && typeof data.nominationNo === "object") {
        this.createTerminalNominationForm.get("nominationNo")?.setValue(data.nominationNo.nominationNo);
      } else {
        this.createTerminalNominationForm.get("nominationNo")?.setValue(data.nominationNo);
      }
    }
    this.transportationDropdown = [Nom.TANK_TRANSFER, Nom.PIPELINE];
    this.createTerminalNominationForm?.get("modeOfTransport")?.setValue(headerToItemNav[0]?.modeOfTransportDesc);
    let refDoc: any = [];
    let referenceDoc: any = [];
    headerToItemNav?.map((el: any) => referenceDoc.push(el.referenceDocumentIndicator));
    refDoc = [...new Set(referenceDoc)];
    if (headerToItemNav[0]?.modeOfTransportDesc === Nom.TANK_TRANSFER) {
      this.nominationTypeDropdown = ["IT G", "IT K", "ITK + ITG"];
    }
    else if(headerToItemNav[0]?.modeOfTransportDesc === Nom.PIPELINE) {
      this.nominationTypeDropdown = Nom.PIPELINE_NOMINATIONS;
    }
    refDoc = this.defineNominationTypeForPanamaCCA(refDoc);
    this.createTerminalNominationForm.get('nominationType')?.setValue(refDoc);
    this.createTerminalNominationForm.get('counterPart')?.setValue(headerToItemNav[0].udmCounterPart);
    this.productList();
    this.createTerminalNominationForm.get('product')?.setValue(headerToItemNav[0].scheduledMaterialName);
    this.defineFormArray(this.createTerminalNominationForm.get('product')?.value, null, headerToItemNav[0]?.scheduledQty, headerToItemNav[0], headerToItemNav);
    const locationData = await firstValueFrom(this.nominationService.getNominationsRefData(["locationAssignedTransSys", "locations"]));
    this.masterData = locationData.result;
    this.destinationLocationDropdown = [];
    locationData.result.map((el: any) => {
      if (el.BusinessLocationId) {
        this.getDestinationLocationDropdown = JSON.parse(JSON.stringify(el.BusinessLocationId))
        this.destinationLocationDropdown.push(el.BusinessLocationId);
      }
    });
    
    if (this.createTerminalNominationForm.get('counterPart')?.value === Nom.CHEVRON_COUNTER_PART && this.createTerminalNominationForm.get('nominationType')?.value === Nom.ITK_G) {
      this.createTerminalNominationForm?.get("destinationLocation")?.setValue(headerToItemNav[1].locationName);
      this.referenceData = headerToItemNav[1].locationName;
      this.onValueChangeOfTransportSystem(headerToItemNav[1].locationId);
      this.createTerminalNominationForm?.get("transportSystem")?.setValue(headerToItemNav[1].transportSystem);
      const myDate = this.datePipe.transform(headerToItemNav[1]?.scheduledDate, "yyy-MM-dd")
      this.createTerminalNominationForm.get('destinationDate')?.setValue(myDate);
      this.filterLMINASTRM()
    }
    else {
      this.createTerminalNominationForm?.get("destinationLocation")?.setValue(headerToItemNav[0].locationName);
      this.referenceData = headerToItemNav[0].locationName;
      this.onValueChangeOfTransportSystem(headerToItemNav[0].locationId);
      this.createTerminalNominationForm?.get("transportSystem")?.setValue(headerToItemNav[0].transportSystem);
      const myDate = this.datePipe.transform(headerToItemNav[0]?.scheduledDate, "yyy-MM-dd")
      this.createTerminalNominationForm.get('destinationDate')?.setValue(myDate);
    }
  }

  createNominationWithReference(data: any) {
    this.getRefData = JSON.parse(JSON.stringify(data))
    let refDoc: any;
    data.forEach((el: any, index: any) => {
      if (index === 0) {
        refDoc = el.referenceDocumentIndicator;
      } else {
        refDoc = refDoc.concat(`, ${el.referenceDocumentIndicator}`);
      }
    });
    let tempArray = refDoc?.split(', ');
    tempArray = [...new Set(tempArray)];
    refDoc = tempArray.join(',');
    this.changeDetection.detectChanges();
    console.log("refValue1", data);
    const dataFirstRow = data[0]
    data[0].referenceDocumentIndicator = refDoc === 'G,K' ? 'G + K' : data[0].referenceDocumentIndicator;
    this.referenceData = data[0]?.destinationLocation ?? {};
    this.invalidDestinationLocationsg = '';
    this.referenceNominations = dataFirstRow;
    if(this.isCCAPanamaView) {
      this.transportationDropdown = ["TankTransfer", Nom.PIPELINE];
    }else {
      this.transportationDropdown = ["TankTransfer", Nom.VESSEL, Nom.PIPELINE];
    }
    this.createTerminalNominationForm.get('modeOfTransport')?.setValue(dataFirstRow?.mot);
    if (dataFirstRow.mot === Nom.TANK_TRANSFER) {
      this.nominationTypeDropdown = ["IT G", "IT K", "ITK + ITG"];
    }
    else {
      this.onChangeMOT(dataFirstRow.mot)
    }
    const refDocIndicator = dataFirstRow?.referenceDocumentIndicator === 'G + K' ? "ITK + ITG" : `${dataFirstRow?.nominationType} ${dataFirstRow?.referenceDocumentIndicator}`;
    this.createTerminalNominationForm.get('nominationType')?.setValue(refDocIndicator);
    this.createTerminalNominationForm.get('counterPart')?.setValue(dataFirstRow?.counterPart);
    this.productList();
    this.createTerminalNominationForm.get('product')?.setValue(dataFirstRow?.product);
    this.defineFormArray(this.createTerminalNominationForm.get('product')?.value, null, dataFirstRow?.scheduledQty, dataFirstRow, data);
    this.getNominationRefData();
    if (this.createTerminalNominationForm.get('counterPart')?.value === Nom.CHEVRON_COUNTER_PART && this.createTerminalNominationForm.get('nominationType')?.value === Nom.ITK_G) {
      this.createTerminalNominationForm.get('destinationLocation')?.setValue(data[1]?.destinationLocation)
      this.referenceData = data[1]?.destinationLocation
      this.filterLMINASTRM()
    }
    else {
      this.createTerminalNominationForm.get('destinationLocation')?.setValue(dataFirstRow?.destinationLocation);
      this.referenceData = dataFirstRow?.destinationLocation
    }
    this.onValueChangeOfTransportSystem(dataFirstRow?.destinationLocation);
    this.createTerminalNominationForm.get('transportSystem')?.setValue(dataFirstRow?.transportSystem);
    const myDate = this.datePipe.transform(dataFirstRow?.destinationDate, "yyy-MM-dd")
    this.createTerminalNominationForm.get('destinationDate')?.setValue(myDate);
  }

  presetNominationData() {
    this.changeDetection.detectChanges();
    if (this.trNomination?.status?.toLowerCase() === "draft") {
      this.createNominationReference = true;
    }
    if(this.isCCAPanamaView) {
      this.transportationDropdown = ["TankTransfer", Nom.PIPELINE];
    } else {
      this.transportationDropdown = ["TankTransfer", Nom.VESSEL, Nom.PIPELINE];
    }
    this.createTerminalNominationForm.get('modeOfTransport')?.setValue(Nom.TANK_TRANSFER);
    this.nominationTypeDropdown = ["IT G", "IT K", "ITK + ITG"];
    this.createTerminalNominationForm.get('nominationType')?.setValue(Nom.ITK_G);
    this.createTerminalNominationForm.get('counterPart')?.setValue(this.trNomination?.counterPart);
    this.productList();
    this.createTerminalNominationForm.get('product')?.setValue(this.trNomination?.product);
    this.defineFormArray(this.createTerminalNominationForm.get('product')?.value, this.trNomination);
    this.getNominationRefData();
    if (this.trNomination?.status?.toLowerCase() !== "draft") {
      this.referenceData = this.trNomination?.destinationLocation;
      this.createTerminalNominationForm.get('destinationLocation')?.setValue(this.trNomination?.destinationLocation);
      this.onValueChangeOfTransportSystem(this.trNomination?.destinationLocation);
      this.createTerminalNominationForm.get('transportSystem')?.setValue(this.trNomination?.transportSystem);
    }
    if (this.trNomination?.destinationDate && !this.trNomination?.destinationDate?.includes('/')) {
      const inputDateStr = this.trNomination?.destinationDate;
      const year = inputDateStr.substr(0, 4);
      const month = inputDateStr.substr(4, 2);
      const day = inputDateStr.substr(6, 2);
      const date = new Date(year, parseInt(month) - 1, day);
      this.formattedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    } else this.formattedDate = this.datePipe.transform(this.trNomination?.destinationDate, "yyyy-MM-dd");
    this.createTerminalNominationForm.get('destinationDate')?.setValue(this.formattedDate);
  }

  resetProductList(eventType: any) {    
    if (!this.trNomination) {
      while (this.detailTypeRequest.length !== 0) {
        this.detailTypeRequest.removeAt(0);
      }
      this.createTerminalNominationForm.controls['product'].setValue('');
    } else {
      this.defineFormArray(this.createTerminalNominationForm.get('product')?.value, this.trNomination?.scheduledQty, this.trNomination?.detailType);
    }
    if (eventType === "modeOfTransport") {
      this.createTerminalNominationForm.controls["nominationType"].setValue('');
      this.createTerminalNominationForm.controls["counterPart"].setValue('');
    }
    if (this.createTerminalNominationForm.get('modeOfTransport')?.value === Nom.VESSEL) {
      this.counterPartDropdown = ["CPFL", "CHEVRON", "REFPAN", "TERPEL", "DELTA"]
    }
    else {
      this.counterPartDropdown = ["CHEVRON", "REFPAN", "TERPEL", "DELTA"]

    }
    this.productList()
    this.filterLMINASTRM()
  }

  filterLMINASTRM() {
    if (this.createTerminalNominationForm.get('modeOfTransport')?.value === Nom.TANK_TRANSFER && this.createTerminalNominationForm.get('nominationType')?.value === Nom.ITK_G && this.createTerminalNominationForm.get('counterPart')?.value === Nom.CHEVRON_COUNTER_PART) {
      this.destinationLocationDropdown = this.destinationLocationDropdown.filter((val: any) => val !== 'LMINASTRM')
    }
    else {
      const getCurrentIndex = this.destinationLocationDropdown.findIndex((val: any) => val === "LMINASTRM")
      if (getCurrentIndex === -1) {
        this.destinationLocationDropdown.push('LMINASTRM')
      }
    }
  }

  tTProductList(nominationTypeValue: any, counterPartValue: any) {
    if ((nominationTypeValue === Nom.ITK || nominationTypeValue === Nom.ITG || nominationTypeValue === Nom.ITK_G) && counterPartValue === Nom.REFPAN_COUNTER_PART) {
      this.productDropdown = [Nom.JETA, Nom.ULSD, Nom._91RON, Nom._95RON, Nom.ASPHALTAC30, Nom.ASPHALTMC250, Nom.ASPHALTRC250];
    } else {
      this.productDropdown = [Nom.JETA, Nom.ULSD, Nom._91RON, Nom._95RON];
    }
  }

  vesselOKDXProductList(counterPartValue: any) {
    if (counterPartValue === Nom.REFPAN_COUNTER_PART || counterPartValue === Nom.CPFL_COUNTER_PART) {
      this.productDropdown = [Nom.BUTANE, Nom.PROPANE, Nom.AVGAS];
    } else {
      this.productDropdown = [];
    }
  }

  vesselOGDKProductList(nominationTypeValue: any) {
    if (nominationTypeValue === Nom.DK) {
      this.productDropdown = [Nom.MGO, Nom.HSFO, Nom.ASPHALTAC30];
    } else {
      this.productDropdown = [Nom.MGO, Nom.HSFO];
    }
  }

  productList() {
    const nominationTypeValue = this.createTerminalNominationForm.get("nominationType")?.value;
    const counterPartValue = this.createTerminalNominationForm.get("counterPart")?.value;
    const modeOfTransportValue = this.createTerminalNominationForm.get("modeOfTransport")?.value;
    if (modeOfTransportValue === Nom.TANK_TRANSFER) {
      this.tTProductList(nominationTypeValue, counterPartValue);
    } else if (modeOfTransportValue === Nom.VESSEL && (nominationTypeValue === Nom.OK || nominationTypeValue === Nom.DX)) {
      this.vesselOKDXProductList(counterPartValue);
    } else if ((nominationTypeValue === Nom.OG || nominationTypeValue === Nom.DK) && (counterPartValue === Nom.REFPAN_COUNTER_PART || counterPartValue === Nom.CPFL_COUNTER_PART) && modeOfTransportValue === Nom.VESSEL) {
      this.vesselOGDKProductList(nominationTypeValue);
    } else if ((nominationTypeValue === Nom.ITG || nominationTypeValue === Nom.ITK) && counterPartValue === Nom.REFPAN_COUNTER_PART && modeOfTransportValue === Nom.PIPELINE) {
      this.productDropdown = [Nom.MGO, Nom.HSFO];
    } else {
      this.productDropdown = [];
    }
  }

  addProductList(event: any) {
    this.defineFormArray(event.target.value);
  }

  defineFormArray(val: any, nomination?: any, vol?: any, refValue?: any, refData?: any) {
    const counterPartValue = this.createTerminalNominationForm.get('counterPart')?.value;
    const modeOfTransportValue = this.createTerminalNominationForm.get('modeOfTransport')?.value;
    const nomtype = this.createTerminalNominationForm.get('nominationType')?.value;
    const scheduleType = nomtype === 'ITK + ITG' ? 'IT' : nomtype.split(" ")[0];
    const referenceDocumentIndicator = nomtype === 'ITK + ITG' ? 'G' : nomtype.split(" ")[1];
    while (this.detailTypeRequest.length !== 0) {
      this.detailTypeRequest.removeAt(0);
    }
    if (modeOfTransportValue === Nom.TANK_TRANSFER && counterPartValue === Nom.TERPEL_COUNTER_PART) {
      this.inventoryHeelReserveNomination(val, vol, scheduleType, referenceDocumentIndicator, refValue);
    } else if (modeOfTransportValue === Nom.TANK_TRANSFER && counterPartValue === Nom.DELTA_COUNTER_PART) {
      this.inventorySafetyNomination(val, vol, scheduleType, referenceDocumentIndicator, refValue);
    } else {
      let InventoryIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.INVENTORY_DETAIL_TYPE['utilization']);
      this.detailTypeRequest.push(
        this.fb.group({
          itemId: InventoryIndex !== -1 ? this.getRefData[InventoryIndex].itemId : null,
          udmDetailType: Nom.INVENTORY_DETAIL_TYPE['utilization'],
          scheduledQty: new FormControl(InventoryIndex !== -1? this.getRefData[InventoryIndex].scheduledQty : vol),
          scheduleType: scheduleType,
          referenceDocumentIndicator: referenceDocumentIndicator,
          // NominationReferenceDocument:refValue?.sapContract,
          // NominationReferenceDocumentItem:refValue?.contractLineItem
        }),
      );
    }
  }

  inventoryHeelNomination(val: any, vol: any, refValue?: any) {
    this.detailTypeRequest.push(this.fb.group({
      itemId: this.trNomination?.inventoryDetailType && this.trNomination?.inventoryDetailType[0]?.itemId ? this.trNomination?.inventoryDetailType[0]?.itemId : null,
      udmDetailType: Nom.INVENTORY_DETAIL_TYPE['utilization'],
      scheduledQty: new FormControl(this.trNomination?.inventoryDetailType && this.trNomination?.inventoryDetailType[0]?.scheduledQty ? this.trNomination?.inventoryDetailType[0]?.scheduledQty : vol),
      // NominationReferenceDocument:refValue?.sapContract,
      // NominationReferenceDocumentItem:refValue?.contractLineItem
    }));
    this.detailTypeRequest.push(
      this.fb.group({
        itemId: this.trNomination?.heelsDetailType && this.trNomination?.heelsDetailType[0]?.itemId ? this.trNomination?.heelsDetailType[0]?.itemId : null,
        udmDetailType: Nom.HEEL_DETAIL_TYPE,
        scheduledQty: new FormControl(this.trNomination?.heelsDetailType && this.trNomination?.heelsDetailType[0]?.scheduledQty ? this.trNomination?.heelsDetailType[0]?.scheduledQty : vol),
        // NominationReferenceDocument:refValue?.sapContract,
        // NominationReferenceDocumentItem:refValue?.contractLineItem
      })
    );
  }

  inventoryHeelReserveNomination(val: any, vol: any, scheduleType: any, referenceDocumentIndicator: any, refValue?: any) {
    let InventoryIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.INVENTORY_DETAIL_TYPE['utilization']);
    let HeelIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.HEEL_DETAIL_TYPE);
    let ReserveIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.RESERVE_DETAIL_TYPE);
    this.detailTypeRequest.push(this.fb.group({
      itemId: InventoryIndex !== -1 ? this.getRefData[InventoryIndex].itemId : null,
      udmDetailType: Nom.INVENTORY_DETAIL_TYPE['utilization'],
      scheduledQty: new FormControl(InventoryIndex !== -1? this.getRefData[InventoryIndex].scheduledQty : vol),
      scheduleType: scheduleType,
      referenceDocumentIndicator: referenceDocumentIndicator,
      // NominationReferenceDocument:refValue?.sapContract,
      // NominationReferenceDocumentItem:refValue?.contractLineItem
    }));
    this.detailTypeRequest.push(
      this.fb.group({
        itemId: HeelIndex !== -1 ? this.getRefData[HeelIndex].itemId : null,
        udmDetailType: Nom.HEEL_DETAIL_TYPE,
        scheduledQty: new FormControl(HeelIndex !== -1? this.getRefData[HeelIndex].scheduledQty : vol),
        scheduleType: scheduleType,
        referenceDocumentIndicator: referenceDocumentIndicator,
        // NominationReferenceDocument:refValue?.sapContract,
        // NominationReferenceDocumentItem:refValue?.contractLineItem
      })
    );
    this.detailTypeRequest.push(
      this.fb.group({
        itemId: ReserveIndex !== -1 ? this.getRefData[ReserveIndex].itemId : null,
        udmDetailType: Nom.RESERVE_DETAIL_TYPE,
        scheduledQty: new FormControl(ReserveIndex !== -1? this.getRefData[ReserveIndex].scheduledQty : vol),
        scheduleType: scheduleType,
        referenceDocumentIndicator: referenceDocumentIndicator,
        // NominationReferenceDocument:refValue?.sapContract,
        // NominationReferenceDocumentItem:refValue?.contractLineItem
      }),
    );
  }

  inventorySafetyNomination(val: any, vol: any, scheduleType: any, referenceDocumentIndicator: any, refValue?: any) {
    let InventoryIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.INVENTORY_DETAIL_TYPE['utilization']);
    let SafetyIndex = this.getRefData.findIndex((x:any) => x.referenceDocumentIndicator === referenceDocumentIndicator && x.udmDetailType === Nom.SAFETYSTOCK_DETAIL_TYPE);
    this.detailTypeRequest.push(this.fb.group({
      itemId: InventoryIndex !== -1 ? this.getRefData[InventoryIndex].itemId : null,
      udmDetailType: Nom.INVENTORY_DETAIL_TYPE['utilization'],
      scheduledQty: new FormControl(InventoryIndex !== -1? this.getRefData[InventoryIndex].scheduledQty : vol),
      scheduleType: scheduleType,
      referenceDocumentIndicator: referenceDocumentIndicator,
      // NominationReferenceDocument:refValue?.sapContract,
      // NominationReferenceDocumentItem:refValue?.contractLineItem
    }));
    this.detailTypeRequest.push(
      this.fb.group({
        itemId: SafetyIndex !== -1 ? this.getRefData[InventoryIndex].itemId : null,       
        udmDetailType: Nom.SAFETYSTOCK_DETAIL_TYPE,
        scheduledQty: new FormControl(SafetyIndex !== -1 ? this.getRefData[SafetyIndex].scheduledQty : vol),
        scheduleType: scheduleType,
        referenceDocumentIndicator: referenceDocumentIndicator,
        // NominationReferenceDocument:refValue?.sapContract,
        // NominationReferenceDocumentItem:refValue?.contractLineItem
      })
    );
  }

  trackByFn(index: number, item: FormControl) {
    return index;
  }

  removeProduct(i: any) {
    this.detailTypeRequest.removeAt(i);
    this.detailTypeRequest.updateValueAndValidity();
  }

  updateNomination(requestBodyArray: any) {
    requestBodyArray?.forEach((obj: any) => {
      obj.headerToItemNav?.forEach((el: any, i: any) => {
        el["textLine"] = `{Author:${this.trNomination?.createdBy},MdfBy:${this.userDetails},SS:UDM}`;
      });
    });
    const screenName = this.router.url === "/replenishmentPlanning" ? "Replenishment Planning" : "Nominations";
    this.myMonitoringService.logEvent('Nomination updation clicked', {category: screenName});
    this.nominationService.updateNomination(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been created`,
          });
        }
        this.closeModalPopup.emit(response);
        this.createTerminalNominationForm.reset({
          nominationNo: '',
          modeOfTransport: '',
          nominationType: '',
          counterPart: '',
          product: '',
          detailTypeRequest: [],
          destinationLocation: '',
          transportSystem: '',
          destinationDate: '',
        });
        this.destinationLocationDropdown = [];
        this.getRefData = null;
        this.referenceData = null;
        while (this.detailTypeRequest.length !== 0) {
          this.detailTypeRequest.removeAt(0)
        }
        this.nominationTypeDropdown = [];
        this.productDropdown = [];
        this.trNomination = null;
        this.referenceNominations = null;
        setTimeout(()=>{
        this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true }));
        },2000)
        this.referenceNominations = null;
        this.createNominationReference = false;
      },
      error: (err) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err?.result;
        this.trNomination = null;
        this.referenceNominations = null;
      },
      complete: () => {
        this.selectDropdown.resetInput();
      },
    });
  }

  createNomination(requestBodyArray: any) {
    requestBodyArray?.forEach((obj: any) => {
      obj.headerToItemNav?.forEach((el: any, i: any) => {
        el.itemId = null;
        el["textLine"] = `{Author:${this.userDetails},MdfBy:${null},SS:UDM}`;
      });
    });
    this.nominationService.createTerminalNomination(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been created`,
          });
        }
        this.closeModalPopup.emit(response);
        this.createTerminalNominationForm.reset({
          nominationNo: '',
          modeOfTransport: '',
          nominationType: '',
          counterPart: '',
          product: '',
          detailTypeRequest: [],
          destinationLocation: '',
          transportSystem: '',
          destinationDate: '',
        });
        this.getRefData = null;
        this.referenceData = null;
        while (this.detailTypeRequest.length !== 0) {
          this.detailTypeRequest.removeAt(0)
        }
        this.nominationTypeDropdown = [];
        this.productDropdown = [];
        this.trNomination = null;
        setTimeout(()=>{
        this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true }));
        },2000)
        this.referenceNominations = null;
      },
      error: (err) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err?.result;
      },
      complete: () => {
        this.selectDropdown.resetInput();
      },
    });
  }

  onFormSubmit() {
    let requestBody!: any
    this.nominationData = JSON.parse(JSON.stringify(this.createTerminalNominationForm.value));
    const nomType = this.createTerminalNominationForm.get('nominationType')?.value;
    const nType = nomType.split(" ");
    this.nominationData["nominationType"] = nType[0] === "ITK" ? "IT" : nType[0];
    this.nominationData["referenceDocument"] = nType[1];
    let id;
    if(this.createNomFromDraftId) {
      id = this.createNomFromDraftId;
    } else if (this.trNomination?.id) {
      id = this.trNomination?.id;
    } else if (this.referenceNominations?.id) {
      id = this.referenceNominations?.id;
    } else {
      id = null
    }
    requestBody = {
      "udmDesk": Object.keys(ccaSpecific).find(key => ccaSpecific[key] === this.desk),
      "udmCounterPart": this.nominationData.counterPart,
      "id": id,
      "udmProduct": this.nominationData.product,
      "udmDeliveryLaycanStartDate": null,
      "udmDeliveryLaycanEndDate": null,
      "nominationNo": this.nominationData?.nominationNo?.toUpperCase(),
      "transportSystem": this.nominationData.transportSystem,
      "sys_status": Nom.INPROCESS,
      "udmModeOfTransportDesc": this.nominationData.modeOfTransport,
      "headerToItemNav": this.nominationData.detailTypeRequest
    };
    requestBody.headerToItemNav.forEach((el: any) => {
      const destDate = this.createTerminalNominationForm.get('destinationDate')?.value
      el.scheduledQty = el?.scheduledQty ? Number(el?.scheduledQty / 1000) : null;
      el.scheduledDate = destDate.replaceAll('-', '');
      el.locationId = this.createTerminalNominationForm.get('destinationLocation')?.value;
      el.udmItemStatus = Nom.INPROCESS;
      if(requestBody.id == null){
        el.itemId = null;
      }
      else if(nomType === "ITK + ITG") {
        let getCurrentIndex = this.getRefData.findIndex((val: any) => 
       (val?.referenceDocumentIndicator ?? null) === (el?.referenceDocumentIndicator ?? null) &&
        (val?.udmDetailType ?? null) === (el?.udmDetailType ?? null)
      )
      el.itemId = getCurrentIndex >= 0 ? this.getRefData[getCurrentIndex].itemId : null;
    } else{
      let getCurrentIndex = this.getRefData.findIndex((val: any) => 
         (val?.udmDetailType ?? null) === (el?.udmDetailType ?? null)
       )
       el.itemId = getCurrentIndex >= 0 ? this.getRefData[getCurrentIndex].itemId : null;
    }
      el.udmDetailType = el.udmDetailType;
      el.confirmationIndicator = "X";
      el.deletionIndicator = null;
      if (nomType === "ITK + ITG") {
        const cloneObject = Object.assign({}, el);
        if (cloneObject?.referenceDocumentIndicator?.toLowerCase() === 'g' && el?.locationId?.toLowerCase() === "lminaschev" && this.createTerminalNominationForm.get('counterPart')?.value?.toLowerCase() === 'chevron') {
          el.locationId = 'LMINASTRM'
        }
        cloneObject.referenceDocumentIndicator = 'K';
        if(requestBody.id == null){
          cloneObject.itemId = null;
        }
        else {
        const getCurrentIndex = this.getRefData.findIndex((val: any) => 
          (val?.referenceDocumentIndicator ?? null) === (cloneObject?.referenceDocumentIndicator ?? null) &&
          (val?.udmDetailType ?? null) === (cloneObject?.udmDetailType ?? null)
        );
        cloneObject.itemId = getCurrentIndex !== -1 ? this.getRefData[getCurrentIndex].itemId : null;
      }
        requestBody.headerToItemNav.push(cloneObject)
      }
    });
    this.postSapContract(requestBody)
    const requestBodyArray = [requestBody];
    if (this.createNominationReference || this.nominationService?.ccaEditNomination) {
      requestBody?.headerToItemNav?.forEach((el: any, i: any) => {
        el.udmItemStatus = Nom.INPROCESS;
      });
      this.updateNomination(requestBodyArray);
    } else {
      this.createNomination(requestBodyArray);
    }
  }

  postSapContract(requestBody: any) {
    if (this.getRefData) {
      requestBody.headerToItemNav.forEach((val: any) => {
        this.getRefData.filter((value: any) => {
          const getDetailType = value?.udmDetailType ? value.udmDetailType : value.detailType;
          if (getDetailType === val.udmDetailType && (value.nominationType === val.scheduleType || value.scheduleType === val.scheduleType) && value.referenceDocumentIndicator === val.referenceDocumentIndicator) {
            val.NominationReferenceDocument = value.sapContract ?? value.nominationReferenceDocument;
            val.NominationReferenceDocumentItem = value.contractLineItem ?? value.nominationReferenceDocumentItem;
          }
        })
      })
    }
  }
  onChangeMOT(event: any) {
    const getMOT = event?.target?.value ? event.target.value : event
    this.nominationTypeDropdown = [];
    if (getMOT === Nom.MARINE_TRANSFER) {
      this.nominationTypeDropdown = Nom.MARINE_NOMINATIONS;
    } else if (getMOT === Nom.TANK_TRANSFER) {
      this.referenceDocumentDropdown.forEach(val => {
        this.nominationTypeDropdown.push(`IT ${val}`);
      });
      this.nominationTypeDropdown.push('ITK + ITG');
    } else if (getMOT === Nom.VESSEL) {
      this.nominationTypeDropdown = Nom.VESSEL_NOMINATIONS;
    } else if (getMOT === Nom.PIPELINE) {
      this.nominationTypeDropdown = Nom.PIPELINE_NOMINATIONS;
    }
    this.resetProductList("modeOfTransport");
  }

  onKeyPress(event: KeyboardEvent) {
    const keyCode = event.key;
    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyCode))) {
      event.preventDefault();
    }
  }

  updateVolume(event: any, index: any) {
    this.detailTypeRequest.controls.forEach((el: any, j) => {
      if (index === j) {
        el.controls['scheduledQty'].setValue(event.target.value);
      }
    });
    this.detailTypeRequest.updateValueAndValidity();
  }

}
