<div class="custom-view-side-panel">
    <div class="custom-view-panel-header col-12">
        <img src="../../../../assets/icons/24_Sorting.svg" alt="manage-constraints icon" class="side-panel-icon">
        <span class="font-gotham-bold custom-view-panel-header-text col-8">your custom views</span>
        <a class="custom-view-panel-close col-4" (click)="onCloseCustomViewSidePanel()"> <span
                class="icon-remove"></span> </a>
    </div>

    <div class="custom-view-panel-content"
        [ngClass]="nominationService.manageCustomView$.value || nominationService.createCustomView$.value ? 'expanded' : ''">
        <div class="custom-view-panel-accordian-item">
            <div class="font-gotham-bold create-custom-view-accordian-header" (click)="customViewAccordion($event)">
                create new custom view
                <button class="custom-view-expand-icon trigger-new-custom"
                    *ngIf="!nominationService.createCustomView$.value"></button><span
                    class="custom-view-collapsed-icon trigger-new-custom"
                    *ngIf="nominationService.createCustomView$.value"></span>
            </div>
            <div class="create-custom-view-accordian-content" *ngIf="nominationService.createCustomView$.value">
                <app-create-custom-view [desk]="desk" [deskParam]="deskParam"
                    [isExpanded]="nominationService.createCustomView$.value"></app-create-custom-view>
            </div>
        </div>
        <div class="custom-view-panel-accordian-item">
            <div class="font-gotham-bold manage-custom-view-accordian-header" (click)="customViewAccordion($event)">
                manage your custom views
                <button class="custom-view-expand-icon"
                    *ngIf="!nominationService.manageCustomView$.value"></button><span class="custom-view-collapsed-icon"
                    *ngIf="nominationService.manageCustomView$.value"></span>
            </div>
            <div class="create-custom-view-accordian-content" *ngIf="nominationService.manageCustomView$.value">
                <app-manage-custom-view [desk]="desk" [deskParam]="deskParam" (customViewDetails)="customViewDetail($event)"
                    [isExpanded]="nominationService.manageCustomView$.value"></app-manage-custom-view>
            </div>
        </div>
    </div>

    <np-modal [show]="nominationService.editCustomView$.value" class="edit-custom-modal"
        [headerTitle]="'editing selected custom view'">
        <app-edit-custom-view [updatedDesk]="updatedDesk" [deskParam]="deskParam" [selectedCustomView]="selectedCustomView" [customViewDts]="customViewDetails"></app-edit-custom-view>
    </np-modal>
</div>