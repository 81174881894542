import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/settings-alerts.reducer';
import * as Rules from '../reducer/settings-alerts.reducer';

export const selectAlertRulesData = createFeatureSelector<State>('alertrules');

export const getAlertRulesData = createSelector(
  selectAlertRulesData,
  Rules.getAlertRulesData
);

export const getAlertFilters = createSelector(
  selectAlertRulesData,
  Rules.getAlertFilters
);

