import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/bcp-dashboard.reducer';
import * as BCP from '../reducer/bcp-dashboard.reducer';

export const selectBCPData = createFeatureSelector<State>('bcpdashboard');
export const selectBCPRefData = createFeatureSelector<State>('bcpdashboard');

export const selectBCPView = createSelector(
  selectBCPData,
  BCP.getSelectedBCPView
);

export const getBCPSummaryData = createSelector(
  selectBCPData,
  BCP.getBCPSummaryData
);

export const getBCPRefData = createSelector(
  selectBCPRefData,
  BCP.getBCPRefDataResponse
);

export const getBCPFilters = createSelector(
  selectBCPRefData,
  BCP.getBCPFilters
);
