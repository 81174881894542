interface activityTrackerCollection {
    [Key: string]: string[]
}

export const activityTrackerFields: activityTrackerCollection = {
    "CCAActivityTracker": [
        "windowTo",
        "windowFrom",
        "tswScheduledDate",
        "portEta",
        "portEtd",
        "etb",
        "port",
        "region",
        "vesselName",
        "voyageName",
        "planName",
        "plannedVolume",
        "ccaStatus",
        "mcatStatus"
    ],
    "NominationActivityTracker": [
        "scheduledDate",
        "scheduledTime",
        "locationName",
        "transportSystem",
        "tdShipmentType",
        "tdVehNo",
        "carrier",
        "tdVehIdentifier",
        "carrierName",
        "transportSystemName",
        "modeOfTransport",
        "udmModeOfTransportDesc",
        "transportPlanningPoint",
        "vehicleCode",
        "vehicleName",
        "scheduledQty",
        "actualPostedQuantity",
        "scheduledUom",
        "demandMaterial",
        "scheduledMaterial",
        "deletionIndicator",
        "changeInd"
    ]

}

interface groupByActivity {
    [Key: string]: string[]
}

export const ccagroupByActivity: groupByActivity = {

    "cargoGrade": [               
        "plannedVolume"      
    ],
    "supplierReceiver": [
        "windowTo",
        "windowFrom",
        "tswScheduledDate",
        "portEta",
        "portEtd",
        "etb",
    ]

}

export const ccaheaderorder = ['volumeChanges', 'dateChanges', 'transportChanges', 'statusChanges'];

export const greyHeaderDisplay: any = {
    'volumeChanges': 'Volume Changes',
    'dateChanges': 'Date Changes by Supplier/Receiver',
    'transportChanges': 'Transportation Changes',
    'statusChanges': 'Status Changes'  
}

export const displayFrontCardName: any = {
    'plannedVolume': 'plannedVolume',
    'windowTo': 'Laycan End',
    'windowFrom': 'Laycan Start',
    'portEta': 'Port ETA',
    'port': 'Port',
    'region': 'Region',
    'vesselName': 'Vessel',
    'voyageName': 'Voyage',
    'ccaStatus' : 'CCA Status',
    'mcatStatus' : 'MCAT Status'

}

interface greyHeader {
    [Key: string]: string[]
}
export const ccagreyHeader: greyHeader = {  
    "volumeChanges": [
        "plannedVolume"
    ],
    "dateChanges": [
        "windowTo",
        "windowFrom",
        "tswScheduledDate",
        "portEta",
        "portEtd",
        "etb",
    ],
    "transportChanges": [
        "port",
        "region",
        "terminal",
        "vesselName",
        "voyageName",
        "planName"
    ],
    "statusChanges": [
        "ccaStatus",
        "mcatStatus"
    ]

}