import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { frontCardData } from 'src/app/shared/interface/activity-tracker';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'at-card-front',
  templateUrl: './card-front.component.html',
  styleUrls: ['./card-front.component.scss']
})
export class CardFrontComponent implements OnInit, OnChanges {

  @Input() cardInfo!: frontCardData;
  dateString: string = "---";

  constructor(private dateService: DateService) { }

  ngOnInit(): void {}

  ngOnChanges(){
    this.dateString = this.dateService.getStandardDateFromISOString(this.cardInfo.changedDate);
  }

}

