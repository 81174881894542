import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomeComponent } from 'src/app/home/home.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertsNotificationsComponent } from 'src/app/settings-alerts/alerts-notifications/alerts-notifications.component';
import { SubscriptionUsers } from 'src/app/shared/interface/settings.interface';

@Component({
  selector: 'app-subscriber-alerts',
  templateUrl: './subscriber-alerts.component.html',
  styleUrls: ['./subscriber-alerts.component.scss']
})
export class SubscriberAlertsComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();

  selectedUserList: string[] = []
  public subscriptions!: SubscriptionUsers[];
  subscriptionList!: SubscriptionUsers[]
  selectedMaterial = '';
  selectedTerminal = '';
  selectedRuleType = '';

  selectedReference = '';
  selectedNoOfDays = '';
  selectedRules = '';
  selectedParameters = '';
  selectedPercent = '';
  selectedRuleName = '';
  selectedFreq = '';
  selectedFreqFull = '';
  selectedSummary = '';
  selectedId = '';
  selectedCreatedBy = '';
  selectedCreatedDate = '';
  selectedUpdatedBy = '';
  selectedUpdatedDate = '';

  public terminalDropdown: string[] = [];
  public materialDropdown: string[] = [];
  public rulesDropdown: string[] = [];
  public ruleNamesList: string[] = [];

  constructor(private store: Store, private alertsService: AlertsService,private alertsComponent: AlertsNotificationsComponent, private homeComponent: HomeComponent) { }

  ngOnInit(): void {
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
    this.alertsService.currentRuleName.subscribe(() => this.getRuleDetails(this.selectedTerminal, this.selectedMaterial, this.selectedRuleName))
  }

  getRuleDetails(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    this.selectedUserList = [];
    this.selectedTerminal = selectedTerminal
    this.selectedMaterial = selectedMaterial
    this.selectedRuleName = selectedRuleName
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminals(response, selectedTerminal, selectedMaterial, selectedRuleName)
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getTerminals(response: any, selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterials(i, selectedMaterial, selectedRuleName)
        }
      }

    })
  }

  getMaterials(i: any, selectedMaterial: string, selectedRuleName: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        this.getRules(j, selectedRuleName)
      }
    }
  }

  getRules(j: any, selectedRuleName: string) {
    if (j.ruleName === selectedRuleName) {

      this.selectedRuleType = j.ruleType;
      this.selectedCreatedBy = j.createdBy;
      this.selectedCreatedDate = j.createdDate;
      this.selectedUpdatedBy = j.updatedBy;
      this.selectedUpdatedDate = j.updatedDate;
      this.subscriptionList = [{ userEmail: ' ', isEmail: false, isTeams: false, isWorkspace: false, isActive: false }];
      for (let i of j.subscription.users) {
        this.subscriptionList.push(i);
        if (i.isActive === true && !this.selectedUserList.includes(i.userEmail)) {
          this.selectedUserList.push(i.userEmail)
        }
      }
    }
  }

  closePopup() {
    this.closeModalPopup.emit(false);
  }

}
