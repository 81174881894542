import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AdminView, DeskFilters, DeskFunctionalGroup, RegionDeskLocationMapping, TextFilter, DeskLocationMapping, LocationNoDetails,LocationNameCode } from '../../../../shared/interface/mdm-admin.interface';
import { selectDeskLocationsData } from '../../../../../app/admin-desks/store/selector/admin-desks.selector';
import { setDefaultDesksFilter, updateDesksFilter } from '../../../../../app/admin-desks/store/action/admin-desks.action';

@Component({
  selector: 'app-desks-filter',
  templateUrl: './desks-filter.component.html',
  styleUrls: ['./desks-filter.component.scss']
})
export class DesksFilterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  deskLocationsData$ = this.store.pipe(select(selectDeskLocationsData));
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  desksData: DeskFunctionalGroup[] = [];
  locationNameCode: LocationNameCode[]=[];
  showAdminView: string = AdminView.Locations;
  latitudeFilterValue: number = NaN;
  longitudeFilterValue: number = NaN;
  filters: DeskFilters = {
    functionalGroup: [],
    region: [],
    locationName: [],
    locationCode: [],
  };
  relatedFilters: string[] = ['locationName', 'locationCode'];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.deskLocationsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.desksData.length && response.initializeDesksFilter) {
          this.desksData = JSON.parse(JSON.stringify(response.desksData));
          this.formatDefaultDesksFilters();
          this.store.dispatch(setDefaultDesksFilter({
            desksFilter: JSON.parse(JSON.stringify(this.filters)),
            desksData: JSON.parse(JSON.stringify(this.desksData))
          }));
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
  }

  formatDefaultDesksFilters() {
    const allOption: TextFilter = { name: 'all', checked: true, show: true };
    this.filters = {
      functionalGroup: [{ ...allOption}],
      region: [{ ...allOption}],
      locationName: [{ ...allOption}],
      locationCode: [{ ...allOption}],
    };
    const uniqueLocationSIS = new Set<string>();
    const uniqueRegions = new Set<string>();
    const uniqueLocations = new Set<string>();
    this.desksData.forEach(functionalGroup => {
      // Functional Group Check
      if (functionalGroup.functionalGroup !== null && functionalGroup.functionalGroup !== '' && functionalGroup.functionalGroup !== 'null') {
        this.filters.functionalGroup.push({ name: functionalGroup.functionalGroup, checked: allOption.checked, show: allOption.show });
        functionalGroup.show = allOption.show;
      }

      functionalGroup.regionDeskMapping.forEach(region => {
        // Region Check
        if (region.region !== null && region.region !== '' && region.region !== 'null' ) {
          this.filters.region.push({ name: region.region, checked: allOption.checked, show: allOption.show });
          region.show = allOption.show;
        }
        else if(!uniqueRegions.has(region.region)) {
          uniqueRegions.add(region.region);
          this.filters.region.push({ name: "n/a", checked: allOption.checked, show: allOption.show });
          region.show = allOption.show;
        }
        this.setDeskLocationFilters(region, allOption.show, allOption.checked, uniqueLocations, uniqueLocationSIS);
      });
    });
     //set all on first index of array
     const lnIndex =  this.filters.locationName.findIndex(x => x.name === 'all');
     if (lnIndex) {
     const [ ln ] =  this.filters.locationName.splice(lnIndex, 1); // delete ln
     this.filters.locationName.unshift(ln);
     }//add at first index

     const lcIndex =  this.filters.locationCode.findIndex(x => x.name === 'all');
     if (lcIndex) {
     const [ lc ] =  this.filters.locationCode.splice(lcIndex, 1); // delete lc
     this.filters.locationCode.unshift(lc);
     }//add at first index
  }

  setDeskLocationFilters(region: RegionDeskLocationMapping, showFilter: boolean, checkFilter: boolean, uniqueLocations: Set<string>, uniqueLocationSIS: Set<string>): void {
    region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
      deskLocationMapping.show = showFilter;
      deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
        if (location.locationCode !== null && location.locationCode !== '' &&location.locationCode !== 'null' ) {
          if (!uniqueLocations.has(location.locationName)) {
            uniqueLocations.add(location.locationName);
            this.filters.locationName.sort((a, b) => (a.name < b.name ? -1 : 1)).push({ name: location.locationName, checked: checkFilter, show: showFilter });
            }
          if (!uniqueLocationSIS.has(location.locationCode)) {
            uniqueLocationSIS.add(location.locationCode);
            this.filters.locationCode.sort((a, b) => (a.name < b.name ? -1 : 1)).push({ name: location.locationCode, checked: checkFilter, show: showFilter });
            location.show = showFilter;
          }
          this.locationNameCode.push({locationName:location.locationName, locationcode:location.locationCode});
        }
      });
    });
  }

  showDeskLocationMappings(region: RegionDeskLocationMapping): void {
    region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
      deskLocationMapping.show = true
      deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
        location.show = true
      });
    });
  }

  filterTable(filterNames: { filterName: string, propertyName: string }[]): void {
    this.desksData.forEach((functionalGroup: DeskFunctionalGroup) => {
      functionalGroup.show = true
      functionalGroup.regionDeskMapping.forEach((region: RegionDeskLocationMapping) => {
        region.show = true
        this.showDeskLocationMappings(region);
      });
    });

    filterNames.forEach((filter: { filterName: string, propertyName: string }) => {
      this.filters[filter.filterName as keyof typeof this.filters].filter((filterOption: TextFilter) => filterOption.name !== 'all').forEach((filterOption: TextFilter) => {
        if (!filterOption.checked) {
          if(filter.filterName === "functionalGroup") {
            const functionalGroup: DeskFunctionalGroup = this.desksData.find((functionalGroup: DeskFunctionalGroup) => functionalGroup.functionalGroup === filterOption.name)!;
            functionalGroup.show = filterOption.checked;
          }
          else if(filter.filterName === "region" || filter.filterName === "locationCode" || filter.filterName === "locationName") {
            this.desksData.forEach(functionalGroup => {
              this.hideRegionsAndDeskLocations(functionalGroup, filter, filterOption);
            });
          }
        }
      });
    });
    this.hideNestedTables();
  }

  hideRegionsAndDeskLocations(functionalGroup: DeskFunctionalGroup, filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    functionalGroup.regionDeskMapping.forEach((region: RegionDeskLocationMapping) => {
      if (((filterOption.name !== 'n/a' && region.region === filterOption.name)
        || ((region.region === null || region.region === '' || region.region === 'null') && filterOption.name === 'n/a'))
        && filter.filterName === "region"
      ) {
        region.show = filterOption.checked;
      } else if (filter.filterName === "locationCode" || filter.filterName === "locationName") {
        region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
          deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
            if((location.locationName === filterOption.name && filter.filterName === "locationName")
            || (location.locationCode === filterOption.name && filter.filterName === "locationCode")
            ) {
              location.show = filterOption.checked;
            }
          })
        });
      }
    });
  }

  hideNestedTables(): void {
    this.desksData.forEach((functionalGroup: DeskFunctionalGroup) => {
      const showFunctionalGroups: boolean = functionalGroup.regionDeskMapping.filter((region: RegionDeskLocationMapping) => region.show).length > 0;
      if (!showFunctionalGroups) {
        functionalGroup.show = showFunctionalGroups;
      }
      functionalGroup.regionDeskMapping.forEach(region => {
        const showRegions: boolean = region.deskLocationMappings.filter((desk: DeskLocationMapping) => desk.show).length > 0;
        if (!showRegions) {
          region.show = showRegions;
        }
      });
    });
  }

  filterDesks(): void {
    const filterNames: { filterName: string, propertyName: string }[] = [
      { filterName: 'functionalGroup', propertyName: 'functionalGroup' },
      { filterName: 'region', propertyName: 'region' },
      { filterName: 'locationName', propertyName: 'locationName' },
      { filterName: 'locationCode', propertyName: 'locationCode' }
    ];
    this.filterTable(filterNames);
    this.store.dispatch(updateDesksFilter({
      desksFilter: JSON.parse(JSON.stringify(this.filters)),
      desksData: JSON.parse(JSON.stringify(this.desksData))
    }));
  }

  applyCheckboxFilters(filterName: string, filterItem: any, index: number): void {
    this.filters[filterName as keyof typeof this.filters][index].checked = filterItem.target.checked;
    const relatedFilterGroup: string[] = this.relatedFilters.filter((relatedFilter: string) => relatedFilter !== filterName);
    const isRelatedFilter: boolean = relatedFilterGroup.length < this.relatedFilters.length;
    const foundFilter: TextFilter[] = this.filters[filterName as keyof typeof this.filters];
    if (index !== 0) {
      // Check or uncheck clicked filter if filter option selected is not 'all'
      // Check or uncheck the all option for clicked filter
      const filterItems: TextFilter[] = this.filters[filterName as keyof typeof this.filters].slice(1, this.filters[filterName as keyof typeof this.filters].length);
      const checkAllOption: boolean = filterItems.length === filterItems.filter((filterOption: TextFilter) => filterOption.checked).length;
      foundFilter[0].checked = checkAllOption;
      if (isRelatedFilter) {
        // Check or uncheck for related filter
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          // Check or uncheck the all option for related filter
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          
          if(filterName==="locationName")
            {
            var currentitemselected=filterItem.currentTarget.value;
            var releateditem=this.locationNameCode.filter(x=>x.locationName===currentitemselected)
            index=foundRelatedFilter.findIndex(x => x.name ===releateditem[0].locationcode);
            }
            else if(filterName==="locationCode"){
              var currentitemselected=filterItem.currentTarget.value;
            var releateditem=this.locationNameCode.filter(x=>x.locationcode===currentitemselected)
            index=foundRelatedFilter.findIndex(x => x.name ===releateditem[0].locationName);
            }

          foundRelatedFilter[index].checked = filterItem.target.checked;
          foundRelatedFilter[0].checked = checkAllOption;
        });
      }
    } else {
      // Check or uncheck all filter options for clicked filter
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        // Check or uncheck all filter options for filters that are directly related
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText = [];
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          if (filter.name.toLowerCase().includes(text.value.toLowerCase()) && filter.name !== 'all') {
            filter.show = true;
          } else {
            filter.show = false;
          }
        });
      } else {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          filter.show = true;
        });
      }
    });
  }

  clearFilters(): void {
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultDesksFilters();
    this.store.dispatch(setDefaultDesksFilter({
      desksFilter: JSON.parse(JSON.stringify(this.filters)),
      desksData: JSON.parse(JSON.stringify(this.desksData))
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
