<section class="filter-form" [formGroup]='fiterFormGroup'>
  <form class="branded">
    <div class="form-row">
      <div class="form-group col-sm mb-2">
        <label for="nominationNo">Nomination No</label>
        <input type="text" class="form-control" formControlName="nominationNo" name="nominationNo"
          >
      </div>
      <div class="form-group col-sm mb-2">
        <label for="nominationKey">Nomination Key</label>
        <input type="text" class="form-control" formControlName="nominationKey" name="nominationKey"
          >
      </div>
      <div class="form-group col-sm mb-2">
          <label for="transportSystem" class="label">Trasport System</label>
          <select formControlName='transportSystem' class="form-control"  id="transportSystem">
            <option>CP</option>
          </select>
      </div>
      <div class="form-group col-sm mb-2">
        <label for="cycleId">Cycle Id</label>
        <input type="text" class="form-control" formControlName="cycleId" name="cycleId"
          >
      </div>
      <div class="form-group col-sm mb-2">
        <label for="status">Status</label>
        <input type="text" class="form-control" formControlName='status' name="status" id="status" readonly>
      </div>
    </div>
  </form>
</section>
