<div class="restore-alerts">
    <div class="label-3">would you like to restore this rule?</div>
    <div>&nbsp;</div>
    <div class="label-2">you are about to restore {{ selectedRuleName }}:</div>
    <div class="label-1">{{selectedSummary}}</div>
    <div class="label-2">on the frequency</div>
    <div class="label-1">{{selectedFreqFull}}</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-3" style="margin-left: 300px;">
            <button class="cancel-btn" (click)="closePopup()">cancel</button>
        </div>
        <div class="form-group col-md-3" style="margin-right: 0px;">
            <button class="delete-rule-btn" (click)="restoreRule()">restore rule <img src="../../assets/icons/icon-alerts-delete-white.svg" alt="delete-icon"></button>
        </div>
    </div>
  </div>
  