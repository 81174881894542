// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appInsights: {
    instrumentationKey: '06f4e608-e348-4303-ac0c-18ed57a4802c'
  },
  production: false,
  //nominationTokenURL: 'https://pmmims-dev.azure.chevron.com/nominations/user_impersonation',
  nominationTokenURL: 'https://pmmims-test.azure.chevron.com/nominationsbffsvc/user_impersonation',
  nominationAPI: 'https://cscm-pmmims-dev-nomination-bff-af-cvx.azurewebsites.net/api',
  replenishmentPlanningTokenURL: 'https://pmmims-test.azure.chevron.com/trbffsvc/user_impersonation',
  replenishmentPlanningAPI: 'https://cscm-pmmims-dev-terminalreplenishment-bff-af-cvx.azurewebsites.net/api',
  bcpDashboardTokenURL: 'https://pmmims-test.azure.chevron.com/trbffsvc/user_impersonation',
  bcpDashboardAPI: 'https://cscm-pmmims-dev-terminalreplenishment-bff-af-cvx.azurewebsites.net/api',
  contractBalancingAPI: 'https://cscm-pmmims-dev-contract-balancing-af-cvx.azurewebsites.net/api',
  //contractBalancingAPI: 'http://localhost:7089/api',
  contractBalancingTokenURL: 'https://pmmims-test.azure.chevron.com/contractbalancing/user_impersonation',
  clientID: "9724fed8-0f67-4cf0-92f0-969a03f1856e",
  calRedirectURI: 'https://udm-dev.azure.chevron.com',
  //calRedirectURI: 'http://localhost:4200/',
  evGroupId:"05c39d60-4640-41da-bbc6-052aa89df377",
  evTrHistorical :'3c3fd055-c0ba-4c29-b5d4-ec6a6b1c463a',
  evEthanol :'554d1025-dfe2-4a2b-ad29-d0d14ecec7d2',
  evPanama :'635887fe-88e8-492c-a6cd-6e26d5ded3a9',
  evCustomarInsights :'482b80db-888e-4b04-bc5e-b0e300e93360',
  evPipelineUtilization :'5d7d81c8-1f74-41ba-bdf1-4963d73a725d',
  alertAPI : 'https://cscm-pmmims-dev-rule-engine-af-cvx.azurewebsites.net/api', 
  alertTokenURL: 'https://pmmims-test.azure.chevron.com/ruleengine/user_impersonation', 
  rtAlertAPI: 'https://cscm-pmmims-dev-alert-af-cvx.azurewebsites.net/api',
  rtAlertTokenURL: 'https://pmmims-test.azure.chevron.com/alert/user_impersonation',
  mdmAPI : 'https://cscm-pmmims-test-mdm-query-cvx.azurewebsites.net/api',
  mdmTokenURL: 'https://pmmims-test.azure.chevron.com/masterData/user_impersonation',
  evDiesel: '8ab0e939-1d21-42ef-bd59-02a0fc6e7103',
  stateManagementApiTokenURL: 'https://pmmims-test.azure.chevron.com/statemanagementsvc/user_impersonation',
  stateManagementApi: 'https://cscm-pmmims-dev-statemanagement-af-cvx.azurewebsites.net/api',
  jetEconomics :'b1e6ebcb-52c7-4460-b925-adbb417d6c9e',
  mogasEconomics :'c250a8d3-2ed2-49e3-8d91-8a3b66cedcb4',
  mogasJetDieselEconomics :'78ee5598-e1ae-4d23-8419-a3396031bd40',
  cargoApi : 'https://cscm-pmmims-dev-cargoplanning-bff-af-cvx.azurewebsites.net/api',
  cargoTokenURL : "https://pmmims-test.azure.chevron.com/cargoplanningbff/user_impersonation",
  ccaCargoTokenURL:"https://pmmims-test.azure.chevron.com/ccacargoplanningbff/user_impersonation",
  ccaCargoApi:"https://cscm-udm-dev-cca-cp-bff-cvx.azurewebsites.net/api",
  inventoryHealthReport :'cf4dd2b3-b281-47e3-a7dc-7c5518222439',
  activityAPI: 'https://cscm-pmmims-dev-activity-tracker-af-cvx.azurewebsites.net/api',
  activityTrackerApiTokenURL: 'https://pmmims-test.azure.chevron.com/activitytracker/user_impersonation',
  cCAReports :'a74ba6c4-3bb6-4735-a6aa-a3667345d8f3',
  meAPI: 'https://cscm-udm-dev-refinvbff-cvx.azurewebsites.net/api',
  mktElsTokenURL: "https://pmmims-dev.azure.chevron.com/refineryInventoryv2/user_impersonation",
  commentAPI: 'https://cscm-pmmims-dev-comments-af-cvx.azurewebsites.net/api/',
  commentApiTokenURL: 'https://pmmims-test.azure.chevron.com/comments/user_impersonation',
};