<div class="sub-header">
  <div class="ethanol-nomination-list">
    <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
    [masterDetail]="true" [embedFullWidthRows]="true" [rowData]="rulesGridData" [detailCellRendererParams]="detailCellRendererParams"
    [groupDefaultExpanded]="groupDefaultExpanded" [detailRowAutoHeight]="true">
    >
    </ag-grid-angular>
  </div>
  <np-modal [show]="createAlertModal.open" [headerTitle]="'alert'" [noModalButtons]="true">
    <app-create-alerts (closeModalPopup)="closeCreateAlertModal()">
    </app-create-alerts>
  </np-modal>
  <np-modal [show]="denyUpdateAlertModal.open" [headerTitle]="'alert'" [noModalButtons]="true">
    <app-deny-edit-alert (closeModalPopup)="closeDenyUpdateAlertModal()">
    </app-deny-edit-alert>
  </np-modal>
  <np-modal [show]="deleteAlertModal.open" [headerTitle]="'delete rule?'" [noModalButtons]="true">
    <app-delete-alerts (closeModalPopup)="closeDeleteAlertModal()">
    </app-delete-alerts>
  </np-modal>
  <np-modal [show]="denyDeleteAlertModal.open" [headerTitle]="'we are sorry'" [noModalButtons]="true">
    <app-deny-delete-alert (closeModalPopup)="closeDenyDeleteAlertModal()">
    </app-deny-delete-alert>
  </np-modal>
  <np-modal [show]="restoreDeletedAlertModal.open" [headerTitle]="'restore rule?'" [noModalButtons]="true">
    <app-restore-alerts (closeModalPopup)="closeRestoreDeletedAlertModal()">
    </app-restore-alerts>
  </np-modal>
  <np-modal [show]="subscriberAlertModal.open" [noModalButtons]="true">
    <app-subscriber-alerts (closeModalPopup)="closeSubscriberAlertModal()">
    </app-subscriber-alerts>
  </np-modal>
  <np-modal [show]="saveAlertModal.open" [headerTitle]="'unsaved changes'" [noModalButtons]="true">
    <app-save-alerts (closeModalPopup)="closeSaveAlertModal()">
    </app-save-alerts>
  </np-modal>
  <np-modal [show]="userVacationModal.open" [noModalButtons]="true">
    <user-vacation (closeModalPopup)="closeUserVacationModal()">
    </user-vacation>
  </np-modal>
</div>
