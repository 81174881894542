import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-desk-block-panel',
  templateUrl: './filter-desk-block-panel.component.html',
  styleUrls: ['./filter-desk-block-panel.component.scss']
})
export class FilterDeskBlockPanelComponent implements OnInit {

  @Input() cardValues!: {deskName: string, numberOfItems: number};

  deskName: string = '';
  numberOfItems: number = 0;

  @Output() choseFilterOption = new EventEmitter<string>();


  ngOnInit(): void {

    this.deskName = this.cardValues.deskName;
    this.numberOfItems = this.cardValues.numberOfItems;
  }

  changeFilterSettings(){
    this.choseFilterOption.emit(this.deskName);
  }

}
