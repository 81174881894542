import { Component, Input, OnInit } from '@angular/core';
import { infoPanelData, panelType } from 'src/app/services/information-panel.service';

@Component({
  selector: 'add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

  addButtonSelectable: boolean = true;
  @Input() screenInformation: infoPanelData = { name: "", date: "", id: "", type: panelType.CP, data: ""};

  constructor() { }

  ngOnInit(): void {
  }

  toggleAddButton(){
    if(this.addButtonSelectable){
      this.addButtonSelectable = false;
    }
  }

  resetAdd(resetValue: any){
    this.addButtonSelectable = true;
  }

}
