import { createAction, props } from "@ngrx/store";
import { BCPView, BCPSummaryData, BCPRefData, BCPFilters } from "src/app/shared/interface/bcp-dashboard.interface";

export const saveBCPView = createAction(
  '[BCP View API] save BCP View',
  props<{ selectedBCPView: BCPView }>()
);

export const loadBcpData = createAction(
  '[BCP Data API] Load BCP Data',
  props<{ payload: any}>(),
);

export const loadBcpDataSuccess = createAction(
  '[BCP Data API] Load BCP Data API Success',
  props<{ bcpDashboardData: BCPSummaryData[] }>(),
);

export const loadBcpDataFailure = createAction(
  '[BCP Data API] Load BCP Data API Failure',
  props<{ error: any }>(),
);

export const loadBCPRefData = createAction(
  '[BCP Ref Data API] Load BCP Reference Data',
  props<{ desk: string}>()
);

export const loadBCPRefDataAPISuccess = createAction(
  '[BCP Ref Data API] Load BCP Reference Data API Success',
  props<{ BCPRefDataResponse: BCPRefData[] }>()
);

export const loadBCPRefDataAPIFailure = createAction(
  '[BCP Ref Data API] Load BCP Reference Data API Failure',
  props<{ error: any }>()
);

export const setDefaultBCPFilters = createAction(
  '[BCP Filters] Set Default BCP Filters',
  props<{ BCPFilters: BCPFilters, updateBCPSummaryTable : boolean }>()
);

export const updateBCPFilters = createAction(
  '[BCP Filters] Update BCP Filters',
  props<{ BCPFilters: BCPFilters }>()
);

export const updateBCPSummaryTable = createAction(
  '[BCP Filters] Set updateBCPSummaryTable',
  props<{ updateBCPSummaryTable: boolean }>()
);
