import { Action, createReducer, on } from "@ngrx/store";
import { AlertLocationData, SettingsAlertFilters } from "src/app/shared/interface/settings.interface";
import { loadRulesDataSuccess, updateAlertFilters, UpdatAlertFilterData } from "../action/settings-alerts.action";

export interface State {
    AlertRulesData: AlertLocationData[],
    SettingsAlertFilters: SettingsAlertFilters,
    UpdatAlertFilterData: boolean
}

export const initialState: State = {
    AlertRulesData: [],
    SettingsAlertFilters: { locationsFilter: [], materialsFilter: [], rulesFilter: [] },
    UpdatAlertFilterData: false,
};

const alertRulesReducer = createReducer(
    initialState,
    on(loadRulesDataSuccess, (state, { alertRulesData }) => ({
        ...state,
        ...alertRulesData,
    })),
    on(updateAlertFilters, (state, { SettingsAlertFilters }) => ({
        ...state,
        SettingsAlertFilters: SettingsAlertFilters,
        UpdatAlertFilterData: true
    })),
    on(UpdatAlertFilterData, (state) => ({
        ...state,
        UpdatAlertFilterData: false
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return alertRulesReducer(state, action);
}

export const getAlertRulesData = (state: State) => ({ AlertRulesData: state.AlertRulesData });
export const getAlertFilters = (state: State) => ({ SettingsAlertFilters: state.SettingsAlertFilters, UpdatAlertFilterData: state.UpdatAlertFilterData });


