import { createAction, props } from '@ngrx/store';
import { TRViewConst } from 'src/app/shared/constants/terminal-replenishment.constant';
import { GetTRPayload } from "../../../shared/interface/terminal-replenishment.interface";
import { State } from '../reducer/terminal-replenishment.reducer';

export const invokeGetTRDataAPI = createAction(
    '[TerminalReplenishment API] Invoke getTRData API',
    props<{ payload: GetTRPayload }>()
);

export const getTRDataAPISuccess = createAction(
    '[TerminalReplenishment API] get API Success',
    props<{ allTRData: State }>()
);

export const saveTRView = createAction(
    '[TerminalReplenishment API] save TR View',
    props<{ selectedTRView: TRViewConst }>()
);

export const combinedButtonSwitch = createAction(
    '[TerminalReplenishment API] combinedButtonSwitch',
    props<{ combinedButtonSwitch: string }>()
);

export const getSelectedTRView = createAction(
    '[TerminalReplenishment API] get TR View',
    props<any>()
);
