import { Component, Input } from '@angular/core';

@Component({
  selector: 'hide-list',
  templateUrl: './header-hide-list.component.html',
  styleUrls: ['./header-hide-list.component.scss']
})
export class HeaderHideListComponent{

  @Input() header!: string;
  @Input() hideList: boolean = true;

  toggleListVisibility(): void{
    this.hideList = !this.hideList;
  }
  constructor(){}
}
