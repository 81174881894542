import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AlertLocationMaterialRulesData, SettingsAlertFilters, SettingsAlertLocationsFilter, SettingsAlertMaterialsFilter, SettingsAlertUniqueMaterialsFilter, SettingsAlertRulesFilter } from '../../../shared/interface/settings.interface';
import { selectDesk } from '../../../shared/store/selector/app.selector';
import { updateAlertFilters } from '../../../settings-alerts/store/action/settings-alerts.action';
import { UDMDesk } from '../../../shared/constants/terminal-replenishment.constant';
import { MdmService } from 'src/app/services/mdm.service';
import { MDMDeskValues, MDMDeskLocations, MDMLocationMaterials } from 'src/app/shared/interface/mdm.interface';
import { AlertsService } from "src/app/services/alerts.service";

@Component({
  selector: 'app-settings-alert-rules-filter',
  templateUrl: './settings-alert-rules-filter.component.html',
  styleUrls: ['./settings-alert-rules-filter.component.scss']
})
export class SettingsAlertRulesFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  locationsearchValue: string = "";
  materialsearchValue: string = "";
  rulesearchValue: string = "";

  updateSettingsAlertLocationSearchData: SettingsAlertLocationsFilter[] = [];
  baseSettingsAlertLocationSearchData: SettingsAlertLocationsFilter[] = [];
  selectallornoneSettingsAlertLocationSearchData: SettingsAlertLocationsFilter[] = [];

  updateSettingsAlertMaterialSearchData: SettingsAlertMaterialsFilter[] = [];
  updateSettingsAlertUniqueMaterialSearchData: SettingsAlertUniqueMaterialsFilter[] = [];
  baseSettingsAlertUniqueMaterialSearchData: SettingsAlertUniqueMaterialsFilter[] = [];
  selectallornoneSettingsAlertMaterialSearchData: SettingsAlertUniqueMaterialsFilter[] = [];

  updateSettingsAlertRuleSearchData: SettingsAlertRulesFilter[] = [];
  baseSettingsAlertRulesSearchData: SettingsAlertRulesFilter[] = [];

  materialsIdList: string[] = [];

  alertLocationMaterialRules: AlertLocationMaterialRulesData[] = [];

  settingsalertfilters: SettingsAlertFilters = { locationsFilter: [], materialsFilter: [], rulesFilter: [] };
  updatedSettingsAlertLocationsFilter: SettingsAlertLocationsFilter[] = [];
  updatedSettingsAlertMaterialsFilter: SettingsAlertMaterialsFilter[] = [];
  updatedSettingsAlertRulesFilter: SettingsAlertRulesFilter[] = [];

  deskValues: MDMDeskValues[] = [];
  deskList: number[] = [];
  locValues: MDMDeskLocations[] = [];
  public matValues: MDMLocationMaterials[] = [];
  public matList: string[] = [];

  locationsforSelectedDesk: string[] = [];
  private redirectCardLocationStorageKey = 'alertredirectcard-location';
  private redirectCardMaterialStorageKey = 'alertredirectcard-material';
  private redirectCardRuleStorageKey = 'alertredirectcard-rule';
  redirectCardLocation: string = '';
  redirectCardMaterial: string = '';
  redirectCardRule: string = '';
  locationAccordionClosed: boolean = false;
  materialAccordionClosed: boolean = false;
  ruleAccordionClosed: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  desk$ = this.store.pipe(select(selectDesk));
  selectedDesk: any;
  previousDesk: any;
  selectedDeskId: number = 0;

  constructor(private store: Store, private mdmService: MdmService, private settingsService: AlertsService) { }

  ngOnInit(): void {
    this.getRedirectLocationMaterial();
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.selectedDesk != "") {
          this.selectedDesk = response.selectedDesk?.toLowerCase();
          this.selectedDeskId = response.selectedDeskId;
          localStorage.setItem('selectedDesk', this.selectedDesk);
        } else {
          this.selectedDesk = UDMDesk.Panama;
          this.selectedDeskId = 1;
        }

        if (this.previousDesk !== this.selectedDesk) {
          this.getDeskData();
          this.previousDesk = this.selectedDesk;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.removeRedirectLocationMaterial();
  }

  getDeskData() {
    this.deskList = [];
    if (this.selectedDeskId > 0)
      this.deskList.push(Number(this.selectedDeskId))
    else {
      this.mdmService.getAllDesks().subscribe({
        next: (response: any) => {
          this.deskValues = response.value
          for (let k of this.deskValues) {
            if (k.name.toLowerCase() === this.selectedDesk) {
              this.deskList.push(Number(k.id))
            }
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
        },
      })
    }
    this.getAllLocationsforSelectedDesk();
  }

  getAllLocationsforSelectedDesk() {
    const locPayload = {
      "deskIds": this.deskList
    };

    this.locationsforSelectedDesk = [];
    this.baseSettingsAlertLocationSearchData = [];
    this.updateSettingsAlertLocationSearchData = [];

    this.mdmService.getDeskLocation(locPayload).subscribe({
      next: (response: any) => {
        this.locValues = response.value
        for (let i of this.locValues) {
          if(!i.locationCode.includes("-X")){
            this.locationsforSelectedDesk.push(i.locationCode);
            this.baseSettingsAlertLocationSearchData.push({ locationid: i.locationCode, location: i.locationCode, checked: true })
            this.updateSettingsAlertLocationSearchData.push({ locationid: i.locationCode, location: i.locationCode, checked: true })
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getMaterialData();
      },
    })
  }

  getMaterialData() {
    const matPayload = {
      "deskIds": this.deskList,
      "activeOnly": false,
      "locationIds": this.locationsforSelectedDesk
    };

    this.materialsIdList = [];
    this.updateSettingsAlertUniqueMaterialSearchData = [];
    this.baseSettingsAlertUniqueMaterialSearchData = [];
    this.updateSettingsAlertMaterialSearchData = [];

    this.mdmService.getMaterialLocationMappings(matPayload).subscribe({
      next: (response: any) => {
        this.matValues = response.value.value
        for (let i of this.matValues) {
          for (let j of i.locationMaterials) {
            this.matList.push(j.materialNumber)
            if (!this.materialsIdList.includes(j.materialNumber)) {
              this.updateSettingsAlertUniqueMaterialSearchData.push({ materialid: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
              this.baseSettingsAlertUniqueMaterialSearchData.push({ materialid: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
              this.materialsIdList.push(j.materialNumber)
            }
            this.updateSettingsAlertMaterialSearchData.push({ location: i.locationCode, materialid: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getAlertsData();
      },
    })
  }

  getAlertsData() {
    this.updateSettingsAlertRuleSearchData = [];
    this.baseSettingsAlertRulesSearchData = [];

    this.settingsService
      .getAlertRules()
      .pipe()
      .subscribe({
        next: (data: any) => {
          data.result?.forEach((locelement: any) => {
            locelement.locationMaterials?.forEach((matelement: any) => {
              matelement.locationMaterialRules?.forEach((rulelement: any) => {
                this.updateSettingsAlertRuleSearchData.push({ location: rulelement.location, material: rulelement.materialNumber, ruleid: rulelement.ruleId, rulename: rulelement.ruleName, checked: true })
                this.baseSettingsAlertRulesSearchData.push({ location: rulelement.location, material: rulelement.materialNumber, ruleid: rulelement.ruleId, rulename: rulelement.ruleName, checked: true })
              });
            });
          });
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          this.settingsalertfilters.rulesFilter = this.updateSettingsAlertRuleSearchData;
          this.settingsalertfilters.rulesFilter.unshift({ location: 'all', material: 'all', ruleid: 'all', rulename: 'all', checked: true });
          if (this.redirectCardRule !== '') {            
            this.settingsalertfilters.rulesFilter.forEach((rule: SettingsAlertRulesFilter) => {
              if (rule.ruleid === this.redirectCardRule) {                
                rule.checked = true;
              }
              else
                rule.checked = false;
            });
          }
          this.settingsalertfilters.materialsFilter = this.updateSettingsAlertUniqueMaterialSearchData;
          this.settingsalertfilters.materialsFilter.unshift({ materialid: 'all', material: 'all', udmmaterial: 'all', checked: true });
          if (this.redirectCardMaterial !== '') {
            this.settingsalertfilters.materialsFilter.forEach((material: SettingsAlertUniqueMaterialsFilter) => {
              if (material.materialid.toLowerCase() === this.redirectCardMaterial.toLowerCase())
                material.checked = true;
              else
                material.checked = false;
            });
          }
          this.settingsalertfilters.locationsFilter = this.updateSettingsAlertLocationSearchData;
          this.settingsalertfilters.locationsFilter.unshift({ locationid: 'all', location: 'all', checked: true });
          if (this.redirectCardMaterial !== '') {
            this.settingsalertfilters.locationsFilter.forEach((location: SettingsAlertLocationsFilter) => {
              if (location.locationid.toLowerCase() === this.redirectCardLocation.toLowerCase())
                location.checked = true;
              else
                location.checked = false;
            });
          }
          this.store.dispatch(updateAlertFilters({ SettingsAlertFilters: JSON.parse(JSON.stringify(this.settingsalertfilters)) }));
        },
      });
  }

  getRedirectLocationMaterial() {
    this.redirectCardLocation = localStorage.getItem(this.redirectCardLocationStorageKey) || "";
    this.redirectCardMaterial = localStorage.getItem(this.redirectCardMaterialStorageKey) || "";
    this.redirectCardRule = localStorage.getItem(this.redirectCardRuleStorageKey) || "";
  }

  removeRedirectLocationMaterial(): void {
    localStorage.removeItem(this.redirectCardLocationStorageKey);
    localStorage.removeItem(this.redirectCardMaterialStorageKey);
    localStorage.removeItem(this.redirectCardRuleStorageKey);
  }

  updateAlertLocationFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsalertfilters.locationsFilter.filter((ele: any) => ele.location.toLowerCase().includes(text.value.toLowerCase()) && ele.location !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsAlertLocationSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsAlertLocationSearchData = this.settingsalertfilters.locationsFilter;
      }
      else {
        this.updateSettingsAlertLocationSearchData = this.settingsalertfilters.locationsFilter;
      }
    });
  }

  updateAlertMaterialsFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsalertfilters.materialsFilter.filter((ele: any) => ele.material.toLowerCase().includes(text.value.toLowerCase()) && ele.material !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsAlertUniqueMaterialSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsAlertUniqueMaterialSearchData = this.settingsalertfilters.materialsFilter;
      }
      else {
        this.updateSettingsAlertUniqueMaterialSearchData = this.settingsalertfilters.materialsFilter;
      }
    });
  }

  updateAlertRulesFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsalertfilters.rulesFilter.filter((ele: any) => ele.rulename.toLowerCase().includes(text.value.toLowerCase()) && ele.rulename !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsAlertRuleSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsAlertRuleSearchData = this.settingsalertfilters.rulesFilter;
      }
      else {
        this.updateSettingsAlertRuleSearchData = this.settingsalertfilters.rulesFilter;
      }
    });
  }

  filterByAlertLocation(event: any): void {
    if (event.target.value === 'all') {
      this.selectallornoneSettingsAlertLocationSearchData = [];
      this.settingsalertfilters.locationsFilter.forEach((location: SettingsAlertLocationsFilter) => {
        location.checked = event.target.checked;
        this.selectallornoneSettingsAlertLocationSearchData.push(location);
      });
      this.settingsalertfilters.locationsFilter = this.selectallornoneSettingsAlertLocationSearchData;
    }

    this.updateSelectedLocationFilter(event);

    this.store.dispatch(updateAlertFilters({ SettingsAlertFilters: JSON.parse(JSON.stringify(this.settingsalertfilters)) }));
  }

  updateSelectedLocationFilter(event: any): void {
    let selectedLocationFilter = this.settingsalertfilters.locationsFilter.find((locationFilter: SettingsAlertLocationsFilter) => locationFilter.location === event.target.value);
    this.settingsalertfilters.locationsFilter.forEach((location: SettingsAlertLocationsFilter) => {
      if (location.locationid === selectedLocationFilter?.locationid)
        location.checked = event.target.checked;
    });

    let locationsExceptall = this.settingsalertfilters.locationsFilter.filter((location: SettingsAlertLocationsFilter) => location.locationid != 'all')
    let allLocationsChecked: boolean = locationsExceptall.filter((location: SettingsAlertLocationsFilter) => location.checked === true).length === (this.settingsalertfilters.locationsFilter.length - 1);
    this.settingsalertfilters.locationsFilter.forEach((location: SettingsAlertLocationsFilter) => {
      if (location.locationid === 'all')
        location.checked = allLocationsChecked;
    });

    this.materialsIdList = [];
    let allSelectedlocations = this.settingsalertfilters.locationsFilter.filter((location: SettingsAlertLocationsFilter) => location.checked === true)
    allSelectedlocations.forEach((selectdloc: SettingsAlertLocationsFilter) => {
      this.updateSettingsAlertMaterialSearchData.forEach((mat: SettingsAlertMaterialsFilter) => {
        if (mat.location === selectdloc.locationid && !(this.materialsIdList.includes(mat.material)))
          this.materialsIdList.push(mat.material)
      });
    });

    this.updateSettingsAlertUniqueMaterialSearchData = this.baseSettingsAlertUniqueMaterialSearchData.filter((mat: SettingsAlertUniqueMaterialsFilter) => this.materialsIdList.includes(mat.material));

    this.settingsalertfilters.materialsFilter = this.updateSettingsAlertUniqueMaterialSearchData;
    this.settingsalertfilters.materialsFilter.unshift({ materialid: 'all', material: 'all', udmmaterial: 'all', checked: true });
    this.LoadRulesForSelectedLocationMaterials();
  }

  LoadRulesForSelectedLocationMaterials(): void {
    this.updateSettingsAlertRuleSearchData = [];
    this.settingsalertfilters.locationsFilter.forEach((location: SettingsAlertLocationsFilter) => {
      if (location.location != 'all' && location.checked) {
        this.settingsalertfilters.materialsFilter.forEach((material: SettingsAlertUniqueMaterialsFilter) => {
          if (material.materialid != 'all' && material.checked) {
            this.baseSettingsAlertRulesSearchData.forEach((rule: SettingsAlertRulesFilter) => {
              if (rule.location === location.location && rule.material === material.materialid)
                this.updateSettingsAlertRuleSearchData.push(rule);
            });
          }
        });
      }
    });

    this.settingsalertfilters.rulesFilter = this.updateSettingsAlertRuleSearchData;
    this.settingsalertfilters.rulesFilter.unshift({ location: 'all', material: 'all', ruleid: 'all', rulename: 'all', checked: true });
  }

  filterByAlertMaterial(event: any): void {
    if (event.target.value === 'all') {
      this.selectallornoneSettingsAlertMaterialSearchData = [];
      this.settingsalertfilters.materialsFilter.forEach((material: SettingsAlertUniqueMaterialsFilter) => {
        material.checked = event.target.checked;
        this.selectallornoneSettingsAlertMaterialSearchData.push(material);
      });
      this.settingsalertfilters.materialsFilter = this.selectallornoneSettingsAlertMaterialSearchData;
    }

    this.updateSelectedMaterialFilter(event);
    this.store.dispatch(updateAlertFilters({ SettingsAlertFilters: JSON.parse(JSON.stringify(this.settingsalertfilters)) }));
  }

  updateSelectedMaterialFilter(event: any): void {
    let selectedMaterialFilter = this.settingsalertfilters.materialsFilter.find((materialFilter: SettingsAlertUniqueMaterialsFilter) => materialFilter.material === event.target.value);
    this.settingsalertfilters.materialsFilter.forEach((material: SettingsAlertUniqueMaterialsFilter) => {
      if (material.materialid === selectedMaterialFilter?.materialid)
        material.checked = event.target.checked;
    });

    let materialsExceptall = this.settingsalertfilters.materialsFilter.filter((material: SettingsAlertUniqueMaterialsFilter) => material.materialid != 'all')
    let allMaterialsChecked: boolean = materialsExceptall.filter((material: SettingsAlertUniqueMaterialsFilter) => material.checked === true).length === (this.settingsalertfilters.materialsFilter.length - 1);
    this.settingsalertfilters.materialsFilter.forEach((material: SettingsAlertUniqueMaterialsFilter) => {
      if (material.materialid === 'all')
        material.checked = allMaterialsChecked;
    });

    this.LoadRulesForSelectedLocationMaterials();
  }

  filterByAlertRule(event: any): void {
    if (event.target.value === 'all') {
      this.settingsalertfilters.rulesFilter.forEach((rule: SettingsAlertRulesFilter) => {
        rule.checked = event.target.checked;
      });
    } else {
      this.updateSelectedRuleFilter(event);
    }

    this.store.dispatch(updateAlertFilters({ SettingsAlertFilters: JSON.parse(JSON.stringify(this.settingsalertfilters)) }));
  }

  updateSelectedRuleFilter(event: any): void {
    let selectedRuleFilter = this.settingsalertfilters.rulesFilter.find((ruleFilter: SettingsAlertRulesFilter) => ruleFilter.ruleid === event.target.value);
    this.settingsalertfilters.rulesFilter.forEach((rule: SettingsAlertRulesFilter) => {
      if (rule.ruleid === selectedRuleFilter?.ruleid)
        rule.checked = event.target.checked;
    });

    let rulesExceptall = this.settingsalertfilters.rulesFilter.filter((rule: SettingsAlertRulesFilter) => rule.ruleid != 'all')
    let allRulesChecked: boolean = rulesExceptall.filter((rule: SettingsAlertRulesFilter) => rule.checked === true).length === (this.settingsalertfilters.rulesFilter.length - 1);
    this.settingsalertfilters.rulesFilter.forEach((rule: SettingsAlertRulesFilter) => {
      if (rule.ruleid === 'all')
        rule.checked = allRulesChecked;
    });
  }

  clearFilters(): void {
    this.locationsearchValue = '';
    this.materialsearchValue = '';
    this.rulesearchValue = '';

    this.getDeskData();

    this.store.dispatch(updateAlertFilters({ SettingsAlertFilters: JSON.parse(JSON.stringify(this.settingsalertfilters)) }));
  }

  changeLocationAccordion(){
    this.locationAccordionClosed = !this.locationAccordionClosed;
  }

  changeMaterialAccordion(){
    this.materialAccordionClosed = !this.materialAccordionClosed;
  }

  changeRuleAccordion(){
    this.ruleAccordionClosed = !this.ruleAccordionClosed;
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}