export interface FieldsRef {
  fieldsToCheck: string[];
  fieldToDisable: string;
}

export interface SelectedTabFunction {
  tab: string;
  function: string;
  type: string;
  values?: any;
}

export interface ToastObj{
  show: boolean,
  type: string,
  msg: string
}

export interface ConfirmModalObj{
  show: boolean,
  msg: string
}

export interface UrlDeconstructor{
  baseUrl: string,
  parameters: string,
}