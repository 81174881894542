import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil, filter, firstValueFrom, forkJoin, map, BehaviorSubject } from 'rxjs';
import { UDMDesk } from '../constants/terminal-replenishment.constant';
import { SelectedTabFunction } from '../interface/interface';
import { searchDeskRecord, selectDesk, selectEthanolTRFiltersResponse, selectPipelineNomFiltersResponse, selectPipelineTRFiltersResponse, selectRefineryNomFiltersResponse, selectUDMScreen, selectedDeskFromSearch, selectedMdmSearch } from '../store/selector/app.selector';
import { Store, select } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { NominationService } from 'src/app/services/nomination.service';
import { MdmService } from 'src/app/services/mdm.service';
import { UnitConversionService } from 'src/app/services/unit-conversion.service';
import * as Nom from 'src/app/shared/constants/nominations.constant';
import{ prodTSWCode, LocSraCode} from  '../constants/cca-dummy.constatnt';
import { LoaderService } from 'src/app/services/loader.service';
import { CellClassRules } from 'ag-grid-community';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';

@Injectable({ providedIn: 'root' })
export class Utilities {
  deskSearchMdm: any;
  selectedDeskId: any;
  deskListResponse: any = [];
  filtersResponse: any = [];
  constructor(private store: Store,
    private datePipe: DatePipe,private nominationService:NominationService,private mdmService : MdmService, private unitConversionService: UnitConversionService, private loaderService: LoaderService, private ethanolService: EthanolTruckService) { }
  desk$ = this.store.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();
  sendDataToChildComp$ = new Subject<SelectedTabFunction>();
  sendActionToChild$ = new Subject<string>;
  unsubscribeList: any = [];
  params:any;
  updatedEthanolFilterRes: any;
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
  selectedDeskFromSearch$ = this.store.pipe(select(selectedDeskFromSearch))
  terminalOwnershipValue:any;
  getInvDetailsArr:any = [];
  globalPipelineAggregatorResponse:any;
  globalethanolAggregatorResponse:any;
  globalPipelineAggregatorResponseForDropdown: any;
  currentSelectedDesk: any;
  selectedPipelineTRFiltersResponse$ = this.store.pipe(select(selectPipelineTRFiltersResponse));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  selectedRefineryNomFiltersResponse$ = this.store.pipe(select(selectRefineryNomFiltersResponse));
  searchDeskRecord$ = this.store.pipe(select(searchDeskRecord));
  selectedMdmSearch$ = this.store.pipe(select(selectedMdmSearch))
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  callCount: number = 1;
  currentScreen:any;
  isSearchOpen: boolean = false;
  globalMasterDataForBCP : any = {};
  searchSelectedDesk: any
  isNonCPDesk: boolean = false;
  ethanolSupplierCarrier:any;
  ethanolBargeResponse : any;
  isSearchTab: any;
  savedWidths: any; 
  public colWidth = new BehaviorSubject<string>("");
  colWidthObserver: any = this.colWidth.asObservable();
  
  sendDataToChild(selectedTabFunction: SelectedTabFunction) {
    this.sendDataToChildComp$.next(selectedTabFunction);
  }

  sendDataTonominationEthanol(action: string) {
    this.sendActionToChild$.next(action);
  }
  getDataFromParent(): Observable<SelectedTabFunction> {
    return this.sendDataToChildComp$.asObservable();
  }

  clearDataToChild() {
    this.sendDataToChildComp$.next({
      tab: '',
      function: '',
      type: '',
    });
  }

  addUnsubscribableList(temp: any) {
    this.unsubscribeList.push(temp);
  }
  unsubscribeDataToChild() {
    if (this.unsubscribeList?.length) {
      this.unsubscribeList.forEach((item: any) => {
        item?.unsubscribe();
      });
      this.unsubscribeList = [];
    }
  }

  // format number to show comma and roundoff
  formatNumber(number: number) { //NOSONAR lightweight logging
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works  
    const nonNeumericChars = /\B(?=(\d{3})+(?!\d))/g; //NOSONAR lightweight logging
    return isNaN(Math.round(number)) ? "0" :
      this.decimalToTwo(number)
        .toString()
        .replace(nonNeumericChars, ",");
  }
  formatNumberPipeline(number: number,isEmptyRequired=true) { //NOSONAR lightweight logging
    const nonNumericChars = /\B(?=(\d{3})+(?!\d))/g; //NOSONAR lightweight logging
    const validNumber = isNaN(number) ? 0 : Number(number);
    if(isEmptyRequired){
      // Check if the number is zero
      if (validNumber === 0) {
        return '';
      }
    }
    
    const formattedNumber = validNumber.toFixed(2);
    return formattedNumber.replace(nonNumericChars, ",");
  }


  parseNumber(number: any) {
    // For removing commas while pasting it into cells or while typing
    if (number?.toString().includes(",")) {
      number = number.replace(/,/g, "");
    }
    return number;
  }

  //logic to convert number to decimals
  decimalToTwo(params: any) {
    if (typeof params === 'object' && !params.data) return;
    let value = params;
    if (params !== null && typeof params === 'object') {
      value = params.value;
    }
    if (value && value !== '' && !Number.isInteger(+value)) {
      return Number.parseFloat(value).toFixed(3);
    } else if (value && value !== '' && Number.isInteger(+value)) {
      return value;
    }
    return 0;
  }

  validateValue(params: any, numericFields?: string[], stringFields?: string[], decimalFields?: string[]): boolean {
    let data = params.data;
    let field = params.colDef.field;
    let isValid: boolean = false;
    if (numericFields?.length) {
      numericFields.find(value => value === field) && (data[field] = !isNaN(params.newValue) && (params.newValue % 1 === 0 && params.newValue >= 0) ? params.newValue : params.oldValue);
    }
    if (decimalFields?.length) {
      if (decimalFields.find(value => value === field)) {
        !params.oldValue && (params.oldValue = 0);
        isNaN(params.newValue) && (data[field] = +params.oldValue);
        data[field] = this.acceptOrRejectNegativeValues(params, field, data);
      }
    }
    isValid = (+data[field] !== +params.oldValue); //? false : true
    return isValid;
  }

  acceptOrRejectNegativeValues(params: any, field: any, data: any) {
    const posNegColumns = ["whatif"]; //, "nominationvolume"
    const stringCols = ["nominationid", "deliveryid", "receiptid", "comments"];
    let fieldDataName = field.split('_')[3] ? field.split('_')[3] : (field.split('_')[2])?field.split('_')[2]:field.split('_')[1];
    /*  */
    let nomField = "";
    if (field.split("_")[3]) {
      nomField = field.split("_").splice(0, 3).join("_");
    }
    if ((params?.data[`${nomField}_scheduleType_Child`] === 'O' && params?.data["date"] === "" && fieldDataName?.toLowerCase() === "nominationvolume") || (params?.data[`${nomField}_scheduleType_Child`] === 'O' && params?.data["date"] !== "")) {
      data[field] = (!isNaN(params.newValue) && (+params.newValue <= 0)) ? (+params.newValue) : +params.oldValue;
    } else {
      data[field] = (!isNaN(params.newValue) && (+params.newValue >= 0)) ? (+params.newValue) : +params.oldValue;
    }
    const fields = { stringCols, params, fieldDataName, data, field, posNegColumns, nomField };
    data[field] = this.checkStringPosNegValues(fields);
    return data[field];
  }

  checkStringPosNegValues(fields: any) {
    const { stringCols, params, fieldDataName, data, field, posNegColumns, nomField } = fields;
    if (stringCols.includes(fieldDataName?.toLowerCase())) {
      data[field] = params.newValue;
    }
    // Added or condition for accepting negative values to nomination volume column while adding freshly in pipeline tr grid
    if (posNegColumns.includes(fieldDataName?.toLowerCase()) || (params?.data[`${nomField}_scheduleType_Child`] === '' && params?.data["date"] !== "" && fieldDataName?.toLowerCase() === "nominationvolume")) {
      data[field] = isNaN(params.newValue) ? (+params.oldValue) : +params.newValue;
    }
    return data[field];
  }

  // Supports both single and double dates
  convertDatesToCurrentTZ(fromDate: string, toDate?: string): any[] | any {
    // fromDate and toDate parameters should be received like yyyy-MM-dd
    let fromDatewithTZ = new Date(fromDate);
    fromDatewithTZ.setMinutes(fromDatewithTZ.getMinutes()); // + toDatewithTZ.getTimezoneOffset()

    if (toDate) {
      let toDatewithTZ = new Date(toDate);
      toDatewithTZ.setMinutes(toDatewithTZ.getMinutes()); // + toDatewithTZ.getTimezoneOffset()
      return [fromDatewithTZ, toDatewithTZ];
    }
    return fromDatewithTZ;

  }
  setExpandAllCollapseAll(gridColumnApi: any, isExpand: boolean, columnDefs: any = []) {
    let getAllHeaderNames: any = []
    columnDefs.forEach((value: any) => {
      getAllHeaderNames.push(value.groupId)
    })

    getAllHeaderNames.forEach((groupId: any) => {
      if (gridColumnApi) {
        gridColumnApi.setColumnGroupOpened(groupId, isExpand)
      }
    })
  }

  getCloumnAddedtoChildren(e: any, dataObj: any) {
    let cList: any = []
    for (let key in e) {
      if ((typeof (e[key])) === 'object' && !['headerName', 'headerType', 'children', 'nomination'].includes(key)) {
        let nfieldName = e[key].headerId ? this.getCamelCaseString(e[key].headerId) : 'misc'
        cList.push({
          headerName: (e[key].headerName ? e[key].headerName : 'misc')?.toLowerCase(),
          type: e[key].headerType,
          field: this.getCamelCaseString(e.headerId) + "_" + nfieldName,
          children: null,
          headerId: e[key].headerId ? e[key].headerId : this.getCamelCaseString(e.headerId) + "_" + nfieldName
        })
        dataObj[this.getCamelCaseString(e.headerId) + "_" + nfieldName] = e[key].value
      }
    }
    return { cList: cList, dataObj: dataObj }
  }

  getCamelCaseString(str: any) {
    return str?.replace(" ", "-").split(' ').reduce((s: any, c: any) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
  }
  checkPastFiveDaysEditable(params: any, checkChildRows = false) {
    if (!checkChildRows) {
      const getStockProjDate = params.data.date ? params.data.date : params.data.scheduledDate;
      return (new Date(getStockProjDate).getTime() >= (new Date().setDate((this.getPSTDateObj()).getDate() - 6))) && (new Date(getStockProjDate).getTime() <= new Date(this.getPSTDateObj().toLocaleDateString()).getTime())
    } else {
      const getStockProjDate = params.data.date ? params.data.date : params.data.scheduledDate;
      return (new Date(getStockProjDate).getTime() >= (new Date().setDate((this.getPSTDateObj()).getDate() - 6)) && params?.data.date !== "") && (new Date(getStockProjDate).getTime() <= new Date(this.getPSTDateObj().toLocaleDateString()).getTime() && params?.data.date !== "")
    }
  }

  // Here column should match the property name in data
  getAggregatedCBTableHeadersValues(column: string, data: any): number {
    const sum = data.reduce(
      (acc: any, curr: any) => acc + curr[column],
      0,
    );
    return sum;
  }

  convertToFixed2(value: any): number {
    return parseFloat(Number.parseFloat(value).toFixed(2));
  }

  checkIfInteger(value: any): boolean {
    return Number.isInteger(value);
  }
  formatRegionDesk(selectedDesk: string) {
    return selectedDesk?.split('+')[1]?.trim()
  }
  formatSelectedDesk(selectedDesk: string) {
    let updatedDesk = selectedDesk;
    switch (selectedDesk) {
      case UDMDesk.Panama:
        updatedDesk = UDMDesk.Panama;
        break;
      case UDMDesk.EthanolUswc:
        updatedDesk = UDMDesk.NewEthanolUswcVal;
        break;
      case UDMDesk.EthanolUsec:
        updatedDesk = UDMDesk.NewEthanolUsecVal;
        break;
      case UDMDesk.PipelineUsecTexasDesk:
        updatedDesk = UDMDesk.PipelineUsecTexasDeskVal;
        break;
      case UDMDesk.PipelineUsecFloridaDesk:
        updatedDesk = UDMDesk.PipelineUsecFloridaDeskVal;
        break;
      case UDMDesk.PipelineUsecColonialPlantationDesk:
        updatedDesk = UDMDesk.PipelineUsecColonialPlantationDeskVal;
        break;
      case UDMDesk.PipelineUsecPascagoulaDesk:
        updatedDesk = UDMDesk.PipelineUsecPascagoulaDeskVal;
        break;
      case UDMDesk.PipelineUswcLADesk:
        updatedDesk = UDMDesk.PipelineUswcLADeskVal;
        break;
      case UDMDesk.PipelineUswcBayAreaDesk:
        updatedDesk = UDMDesk.PipelineUswcBayAreaDeskVal;
        break;
      case UDMDesk.PipelineUswcPNWDesk:
        updatedDesk = UDMDesk.PipelineUswcPNWDeskVal;
        break;
      case UDMDesk.PipelineUswcElPasoDesk:
        updatedDesk = UDMDesk.PipelineUswcLADeskVal;
        break;
      case UDMDesk.PipelineUswcSaltLakeCityDesk:
        updatedDesk = UDMDesk.PipelineUswcBayAreaDeskVal;
        break;
      
      default:
        break;
    }
    return updatedDesk;
  }
  getUserName(getMail: string) {
    if (getMail.includes('@')) {
      let userName = getMail.split('@')
      return userName[0]
    }
    else {
      return getMail
    }
  }

  invDetailsUpdate(e:any, fieldRef:any,fieldName:any,getInvDetails:any){
    const updateInvDetail = {
      physicalInventoryTimeStamp: e.physicalInventoryTimeStamp??'',
      lastUpdatedUDMInventory: e.lastUpdatedUDMInventory??'',
      isUdmManagedInv: e.isUdmManagedInv??'',
      field: fieldRef ? fieldRef + "_" + fieldName : fieldName,
      headerId: e.headerId ?? 'misc'
    }
    return getInvDetails.push(updateInvDetail)   
  }

  getLowLevelConf(row: any, ethonalSwitch: any, fieldRef?: any, obj?: any, uniqueList?: any) {
    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
       let terminalOweneshipList = response?.ethanolFilterValues?.filter((ele: any) => ele.headerName === 'terminal ownership')[0]
        this.terminalOwnershipValue = terminalOweneshipList?.list.filter((ele: any) => ele.checked)[0]
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    })

    let rowConfig: any = [];
    let dataObj: any = obj || {
      date: row.scheduledDate
    }
    let uniqueHeaders: any = uniqueList || [];
    let getInvDetails:any = [];

    (row?.headerType?.toLowerCase() === 'offtaker' ? row.children[0] : row.children)?.forEach((e: any) => { 
      let cList: any = [];
      let childData = this.getCloumnAddedtoChildren(e, dataObj);
      cList = childData.cList;
      dataObj = childData.dataObj
      dataObj[e.headerId + '_id'] = e?.trData?.id;
      dataObj[e.headerId + '_batchValuationType'] = e?.trData?.batchValuationType;
      dataObj[e.headerId + '_headerMaterial'] = e?.trData?.headerMaterial;
      dataObj[e.headerId + '_udmInvEditedDatetime'] = e?.trData?.udmInvEditedDatetime;
      uniqueHeaders = this.getUniqueHeaders(uniqueHeaders, e);

      let name = e.headerName;
      let fieldName = e.headerId ? this.getCamelCaseString(e.headerId) : 'misc';
      let childConfig: any = {
        headerName: (name || 'misc')?.toLowerCase(),
        type: e.headerType?.toLowerCase(),
        field: fieldRef ? fieldRef + "_" + fieldName : fieldName,
        headerId: e.headerId ?? 'misc',
        minInventory: e?.trData?.minInventory,
        maxInventory: e?.trData?.maxInventory
      }
      this.updateInvData(e,childConfig,fieldRef,fieldName, getInvDetails);
      dataObj = this.getUpdatedDataObj(dataObj, e, childConfig, ethonalSwitch);
      let lowlevelColDef = this.getLowLevelConf(e, ethonalSwitch, childConfig.field, dataObj, uniqueHeaders);
      childConfig['children'] = lowlevelColDef.col.length > 0 ? lowlevelColDef.col : null;
      dataObj = lowlevelColDef.data;
      childConfig = this.addChildrentoConfig(childConfig, cList);
      rowConfig.push(childConfig);
    })
    let updateGetInvDetails = this.terminalOwnershipValue?.name.toLowerCase() === 'single entity' ? getInvDetails : this.getInvDetailsArr
    return { col: row ? rowConfig : null, data: dataObj, uniqueIds: uniqueHeaders, getInvDetails: updateGetInvDetails }
  }
  updateInvData(e:any,childConfig:any,fieldRef:any,fieldName:any, getInvDetails:any) {
    let getDesk = this.updateAPIParams()
     if ((e.headerType?.toLowerCase() === 'terminal' && e.targetInventory) || (e.headerType?.toLowerCase() === 'terminal'&& getDesk === 'ethanolusec')) {
        if(getDesk !== 'ethanolusec'){
          childConfig['headerName'] = `${e.headerName}(${e.targetInventory})`;
        }
        if (this.terminalOwnershipValue?.name.toLowerCase() === 'single entity') {
          this.invDetailsUpdate(e, fieldRef,fieldName,getInvDetails)  
        }
      }
      if (e.headerType?.toLowerCase() === 'offtaker' && this.terminalOwnershipValue?.name.toLowerCase() === 'multiple entities') {
        this.invDetailsUpdate(e, fieldRef,fieldName,this.getInvDetailsArr)  
      }
  }

  addChildrentoConfig(childConfig: any, cList: any) {
    if (childConfig['children'] !== null && cList.length > 0) {
      childConfig['children'] = [...childConfig['children'], ...(cList)]
    }
    return childConfig;
  }

  setTransloadQtyForRespectiveDate(dataObj: any, e: any){
    let response = e?.trData.filter((data: any) => {
      return data.scheduledDate === dataObj.date;
    })
    if(response?.length > 0){
      const totatQty = response?.reduce((sum: any, actual: any) => sum + actual.scheduledQty, 0);
      return totatQty;
    } else {
      return response?.scheduledQty;
    }  

  }
  getUpdatedDataObj(dataObj: any, e: any, childConfig: any, ethonalSwitch: any) {
    let val;
    if (childConfig?.headerId?.toLowerCase().includes('transload') && e?.trData?.length) {
      val = this.setTransloadQtyForRespectiveDate(dataObj, e);
      dataObj[childConfig?.headerId + '_transloadTRData'] = e?.trData;
      dataObj[childConfig.field + '_actualNomination'] = +val;
    } else if (ethonalSwitch.split('_')[0]?.toLowerCase() === 'terminal') {
      val = e?.trData?.nomination ?? e?.trData?.value; 
    } else {
      val = e?.nomination ?? e?.value;
    }
    if (e?.headerId?.toLowerCase() === 'comments') {
      val = e?.trData?.comments;
      dataObj[childConfig.field] = val;
    }else{
      dataObj[childConfig.field] = +(val || 0);
    }
   
    
    dataObj[childConfig.field + '_actualNomination'] = e?.trData?.actualNomination
    dataObj[childConfig.field + '_calculatedNomination'] = e?.trData?.calculatedNomination
    dataObj[childConfig.field + '_nominationItemStatus'] = e?.trData?.nominationItemStatus
    dataObj[childConfig.field + '_createdBy'] = e?.trData?.createdBy
    dataObj[childConfig.field + '_id'] = e?.trData?.id
    dataObj[childConfig.field + '_itemId'] = e?.trData?.itemId
    dataObj[childConfig.field + '_itemStatus'] = e?.trData?.itemStatus
    dataObj[childConfig.field + '_status'] = e?.trData?.status
    dataObj[childConfig.field + '_batchValuationType'] = e?.trData?.batchValuationType
    dataObj[childConfig.field + '_headerMaterial'] = e?.trData?.headerMaterial;
    dataObj[childConfig.field + '_headerMaterialDesc'] = e?.trData?.headerMaterialDesc;
    dataObj[childConfig.field + '_locationName'] = childConfig.headerName;
    dataObj[childConfig.field + '_headerHubSpoke'] = e?.hubSpoke
    dataObj[childConfig.field + '_nomType'] = e?.trData?.nomType
    dataObj[childConfig.field + '_nomKey'] = e?.trData?.nomKey
    dataObj[childConfig.field + '_isEditable'] = e?.trData?.isEditable
    dataObj[childConfig.field + '_scheduledMaterial'] = e?.trData?.scheduledMaterial
    dataObj[childConfig.field + '_demandMaterial'] = e?.trData?.demandMaterial
    dataObj[childConfig.field + '_scheduleType'] = e?.trData?.scheduleType
    dataObj[childConfig.field + '_tswNominationType'] = e?.trData?.tswNominationType
    dataObj[childConfig.field + '_referenceDocumentIndicator'] = e?.trData?.referenceDocumentIndicator
    dataObj[childConfig.field + '_supplier'] = e?.trData?.supplier
    dataObj[childConfig.field + '_modeOfTransport'] = e?.trData?.modeOfTransport

    dataObj[childConfig.field + '_physicalInventoryTimeStamp'] = childConfig?.physicalInventoryTimeStamp;
    dataObj[childConfig.field + '_lastUpdatedUDMInventory'] = childConfig?.lastUpdatedUDMInventory;
    dataObj[childConfig.field + '_isUdmManagedInv'] = childConfig?.isUdmManagedInv;
    dataObj[childConfig.field + '_udmInvEditedDatetime'] = e?.trData?.udmInvEditedDatetime;
    dataObj[childConfig.field + '_udmTimeFrame'] = e?.trData?.udmTimeFrameOption;
    dataObj[childConfig.field + '_minInventory'] = e?.trData?.minInventory;
    dataObj[childConfig.field + '_maxInventory'] = e?.trData?.maxInventory;
    dataObj[childConfig.field + '_minESL'] = e?.trData?.minESL;
    dataObj[childConfig.field + '_maxESL'] = e?.trData?.maxESL;
    dataObj[childConfig.field + '_isUDMTransloadSplit'] = e?.trData?.isUDMTransloadSplit;
    dataObj[childConfig.field + '_transloadSplitQty'] = e?.trData?.transloadSplitQty;
    dataObj[childConfig.field + '_nominationNo'] = e?.trData?.nominationNo;
    dataObj[childConfig.field + '_avgDailyLifting'] = e?.trData?.avgDailyLifting;
    dataObj[childConfig.field + '_nominationNo'] = e?.trData?.nominationNo;
    dataObj[childConfig.field + '_comments'] = e?.trData?.comments;
    if(childConfig.field.split('_')[1] === 'totalInventory'){
      dataObj[childConfig.field + '_minESL'] = e?.trData?.minESL;
      dataObj[childConfig.field + '_maxESL'] = e?.trData?.maxESL;
      dataObj[childConfig.field + '_physicalInventory']= e?.trData?.physicalInventory;
      dataObj[childConfig.field + '_physicalInventoryUnitOfMeasure']= e?.trData?.physicalInventoryUnitOfMeasure;
      dataObj[childConfig.field + '_udmInvEditedDatetime'] = e?.trData?.udmInvEditedDatetime;
    }
    return dataObj
  }

  getUniqueHeaders(uniqueHeaders: any, e: any) {
    if (uniqueHeaders[e?.headerType?.toLowerCase()]) {
      uniqueHeaders[e?.headerType?.toLowerCase()].push(e.headerId)
    } else {
      uniqueHeaders[e?.headerType?.toLowerCase()] = [e.headerId]
    }
    return uniqueHeaders
  }

  checkIfPipelineView(selectedDeskOption: any) {
    return selectedDeskOption.includes("clean products");
  }
  checkIfRefineryView(selectedDeskOption: any) {
    return selectedDeskOption.includes("refinery");
  }
  checkIfCCAView(selectedDeskOption: any) {
    return selectedDeskOption.includes("clean products + latam");
  }

  checkIfCCAPanamaView(selectedDeskOption: any) {
    return selectedDeskOption.includes("clean products + latam + panama");
  }

  // format empty value to ''
  formatNull(number: number) { //NOSONAR lightweight logging  
    return ''
  }

  formatNaN(number: number) {
    const nonNeumericChars = /\\B(?=(\\d{3})+(?!\\d))/g;
    return isNaN(Math.round(number)) ? "" :
      Math.round(number)
        .toString()
        .replace(nonNeumericChars, ",");
  }

  formatDaysOfSupplyValue(number: number) {
    if(Number.isFinite(number)){
      const nonNumericChars = /\\B(?=(\\d{3})+(?!\\d))/g;
      let validNumber:any = isNaN(number) ? 0 : Number(number);
      if (validNumber < 0 || (Number.isFinite(validNumber) && validNumber % 1 !== 0)) {
        validNumber = Math.max(validNumber, 0);
      }
      // Check if the number is zero
      if (validNumber === 0) {
        return 0;
      }
      validNumber = validNumber.toFixed(1);
      return validNumber.toString().replace(nonNumericChars, ",");
    }else{
      return 'N/A';
    }
  }

  camel2title(camelCase: string) {
    return camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toLowerCase())
      .trim();
  }

  updateAPIParams() {
    let selectedDesk: any, selectedDeskParams: any;
    const convertedSelectedDesk: any = {
      [UDMDesk.EthanolUswc]: 'ethanoluswc',
      [UDMDesk.EthanolUsec]: 'ethanolusec',
    }
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        selectedDesk = response.selectedDesk;
        this.currentSelectedDesk = response.selectedDesk;
        selectedDeskParams = response.selectedDeskParam;
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        selectedDesk = response.selectedDesk;
        selectedDeskParams = response.selectedDeskParam;
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
    if(selectedDesk?.startsWith("panama"))
        return selectedDesk;
    if (selectedDesk?.startsWith("clean products + usec")) {
      return `pipelineusec${selectedDeskParams}`
    } else if (selectedDesk?.startsWith("clean products + uswc")) {
      return `pipelineuswc${selectedDeskParams}`
    } else if (selectedDesk?.startsWith('refinery + usec')) {
      return `${selectedDeskParams}`
    } else if (selectedDesk?.startsWith("clean products + latam")) {
      return `pipelinelatam${selectedDeskParams}`
    } else {
      return convertedSelectedDesk[selectedDesk];
    }
  }

  // To save updatedCellNode and agGridState temporarily in sessionStorage
  saveGridStateWithCellNode(event: any, gridColumnApi: any) {
    const cellObj = { rowIndex: event?.rowIndex, field: event?.column?.colDef?.field, colId: event?.column?.colDef?.colId??event?.column?.colId }
    sessionStorage.setItem("updatedCellNode", JSON.stringify(cellObj));
    const groupState = JSON.stringify(gridColumnApi?.getColumnGroupState());
    sessionStorage.setItem('agGridState', groupState);
  }

  // To highlight last updated cell
  highlightLastUpdatedCell(col: any) {
    if (sessionStorage.getItem("updatedCellNode")) {
      const lastUpdatedCellNode = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem("updatedCellNode"))));
      const cellClassRules = {
        'highlight-updated-value': (params: any) => {
          const currentRowIndex = params?.node?.rowIndex;
          const currentField = params?.colDef?.field;
          return (currentRowIndex === lastUpdatedCellNode?.rowIndex) && (currentField === lastUpdatedCellNode?.field);
        },
      };
      col['cellClassRules'] = cellClassRules;
    }
  }

  // Find last updated cell with position
  retrieveLastUpdatedCellWithPosition(gridApi: any, gridColumnApi: any) {
    if (sessionStorage.getItem("updatedCellNode")) {
      const lastUpdatedCellNode = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem("updatedCellNode"))));
      gridApi?.ensureIndexVisible(lastUpdatedCellNode?.rowIndex + 5, null); // adding 10 to increase the viewable position
      const storedState = (JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('agGridState')))));
      if (storedState) {
        setTimeout(() => {
        gridColumnApi?.setColumnGroupState(storedState);
        },1000);
      }
    }
  }

  getPSTDateObj() {
    const d = new Date()

    // convert to msec since Jan 1 1970
    const localTime = d.getTime()

    // obtain local UTC offset and convert to msec
    const localOffset = d.getTimezoneOffset() * 60 * 1000

    // obtain UTC time in msec
    const utcTime = localTime + localOffset

    // obtain and add destination's UTC time offset
    const estOffset = this.getEstOffset()
    const usa = utcTime + (60 * 60 * 1000 * estOffset)

    // convert msec value to date string
    const nd = new Date(usa)
    return nd
  }

  // Get time zone offset for NY, USA
  getEstOffset() {
    const stdTimezoneOffset = () => {
      let jan = new Date(0, 1)
      let jul = new Date(6, 1)
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    }

    let today = new Date()
    const isDstObserved = (today: Date) => {
      return today.getTimezoneOffset() < stdTimezoneOffset()
    }

    if (isDstObserved(today)) {
      return -7
    } else {
      return -8
    }
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  formatDateForPayload(date: any) {
    if (date && date.includes('/')) {
      const splitDate = date.split('/')
      return splitDate[2]+'-'+splitDate[0]+'-'+splitDate[1];
    } else if (date && date.includes('-')) {
      return date.substr(0, 10);
    }
    return '';
  }

  fetchGroupingProperties(nomCol:any,fieldDataName:any,fieldType:any){
    const materialIdField = this.params?.colDef?.field.split('_').slice(0, 2).join("_");
      const materialId = this.params?.data[materialIdField + '_demandOverride_headerMaterial'] ?? this.params?.data[`${materialIdField}_${nomCol}_scheduledMaterial_Child`]; //[materialIdField + '_nominationId_scheduledMaterial_Child']
      const materialDesc = this.params?.data[materialIdField + '_demandOverride_headerMaterialDesc'] ?? this.params?.data[`${materialIdField}_${nomCol}_materialDesc_Child`]; //[materialIdField + '_nominationId_materialDesc_Child']
      const nominationQty = +this.params.data[`${fieldDataName}_value_Child`];
      const refDocInd = this.params?.data[`${materialIdField}_${nomCol}_referenceDocumentIndicator_Child`]; //[materialIdField + '_nominationId_referenceDocumentIndicator_Child']
      const scheduleType = this.params?.data[`${materialIdField}_${nomCol}_scheduleType_Child`]; //[materialIdField + '_nominationId_scheduleType_Child']
      const pipelineDate = this.params?.data.date === "" ? this.params?.data.scheduledDate : this.params?.data.date;
      const locationId = fieldType.split('_')[1];
      let nomField = fieldType.split('_').slice(0, 2).join("_");
      let rowID = this.params?.data[`${nomField}_${nomCol}_id`]?? this.params?.data[`${nomField}_${nomCol}_id_Child`]; //[nomField + '_nominationId_id_Child']
      let rowItemId = this.params?.data[`${nomField}_${nomCol}_itemId_Child`]; 

      return [materialId,materialDesc,nominationQty,refDocInd,scheduleType,pipelineDate,locationId,nomField,rowID,rowItemId];
  }


async getPipelineNomSearchPayload2(data:any,userEmail:any,desk:any,isMoreMenuEditNom=false){
  this.searchSelectedDesk = data.deskSet;
  if(Object.keys(data).length){
    this.params = data;
  
  let pipelineNomination:any;
  let pipelineNomFilterResponse: any;
  let updatedDesk: any;
  updatedDesk = this.formatRegionDesk(data?.desk?.toLowerCase());
  this.isNonCPDesk = true
  if(this.searchSelectedDesk ===  '5'||this.searchSelectedDesk ===  '17' ||this.searchSelectedDesk ===  '16' ||this.searchSelectedDesk === '9'){
    this.isNonCPDesk =false
  }
    this.searchDeskRecord$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
       if(response.pipelineNomSearchValues){
        pipelineNomFilterResponse = [response.pipelineNomSearchValues.value];

       }     
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    })
    //  this.selectedDeskFromSearch$.pipe(takeUntil(this.destroy$)).subscribe({
    //   next: (res: any) => {
    //     if(res.deskSetUpdated && res.deskSearchValues){
    //       this.searchSelectedDesk = res.deskSearchValues
    //        if(this.searchSelectedDesk ===  '5'||this.searchSelectedDesk ===  '17' ||this.searchSelectedDesk ===  '16' ||this.searchSelectedDesk === '9'){
    //         this.isNonCPDesk =false
    //         desk = deskLink[this.searchSelectedDesk]
    //        }
    //        else {
    //         this.isNonCPDesk = true 
    //         desk = deskLink[this.searchSelectedDesk]
    //        }
        
    //     }
    //   }
    //  })
 
  pipelineNomination = this.params.data;
  if(!pipelineNomination){
    pipelineNomination={};
  }
  const rowID = this.params.data?.id;
  const udmNomGroupingId =  this.params?.data?.udmNominationGrouping;
  let udmGrouplingPayload: any;
  let getNomDetails: any;

  let currentPayload = pipelineNomFilterResponse;
  udmGrouplingPayload = this.getNominationByUdmGroupingIdPayload(rowID, udmNomGroupingId, updatedDesk, currentPayload)
  getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingIdPostCallSearch(udmGrouplingPayload, this.searchSelectedDesk));
  let res = this.pipelineSearchDetails(getNomDetails, pipelineNomination, userEmail, data);
  return res;
  }
}
 pipelineSearchDetails(getNomDetails: any, pipelineNomination: any, userEmail: any, data: any){
  let updatedLineItems:any = [];
  let combinedHeaderText = ""; 
  getNomDetails.result.forEach((res:any)=>{
   let formattedRes = res.map((ele: any) => {
      ele.scheduledDate = this.dateCellRenderer(ele.scheduledDate);
      ele.scheduledQty = this.checkIfCCAView(data?.desk) ? ele?.scheduledQty : ele?.scheduledQty / 1000;
      ele.nominationReferenceDocumentItem = this.removeLeadingZeros(ele.nominationReferenceDocumentItem);
      return ele;
    })
    updatedLineItems.push(formattedRes);
  })
  let transportSystem = null;
  transportSystem=updatedLineItems.length?updatedLineItems[0][0]?.transportSystem:'';
  if (getNomDetails.result.length > 1){
  combinedHeaderText = [...new Set(getNomDetails.result.map((item:any)=> item[0].headerTextLine))].join(", ");
  pipelineNomination['headerTextLine'] = combinedHeaderText;
  }
  else{
  pipelineNomination['headerTextLine'] = updatedLineItems.length ? updatedLineItems[0][0]?.headerTextLine : '';
  }
  pipelineNomination['btCustodyChain'] = updatedLineItems.length ? updatedLineItems[0][0]?.btCustodyChain : '';
  pipelineNomination['createdOn'] = new Date().toISOString();
  pipelineNomination['createdBy'] = userEmail;
  pipelineNomination['transportSystem'] = transportSystem;
  pipelineNomination['udmNominationGrouping'] = this.params?.data?.udmNominationGrouping;
  pipelineNomination['sys_status'] = updatedLineItems.length ? updatedLineItems[0][0]?.sys_status : '';
  pipelineNomination['headerToItemNav'] = [...updatedLineItems];
  pipelineNomination['udmDesks'] = data.deskSet
  pipelineNomination['nominationNo'] = getNomDetails.result[0][0].nominationNumber
  pipelineNomination['editNomination'] = data?.editNomination;

  return pipelineNomination;
 }
  async getPipelineNomPayload(data:any,userEmail:any,desk:any,isMoreMenuEditNom=false) { //NOSONAR lightweight logging
    this.nominationService.getSearchNominationAFAPI$.subscribe((getSearchTab:any)=>{
      this.isSearchTab = getSearchTab;
    });
    if(Object.keys(data).length){
    this.params = data;
    let  pipelineNomination:any;
    let updatedDesk;
    let pipelineTRFilterResponse: any;
    let pipelineNomFilterResponse: any;
    let currentScreen: any;
    
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen;
          currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (desk.includes('clean products') || desk.includes('refinery')) {
          pipelineTRFilterResponse = response.pipelineFilterValues;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next:  (response: any) => {
        if (desk.includes('clean products')|| desk.includes('refinery')) {
       
            pipelineNomFilterResponse = response.pipelineNomFilterValues;
          
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedRefineryNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next:  (response: any) => {
        if (desk.includes('refinery')) {
            pipelineNomFilterResponse = response.pipelineNomFilterValues;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
 
  
    if(desk?.includes("ethanol")){
      updatedDesk = (desk === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.EthanolUswcVal;
    } else if (desk?.includes("+ latam +")) {
      updatedDesk = desk?.split('+')[2]?.trim()?.split(' ')[0];
    } else {
      updatedDesk = this.formatRegionDesk(desk?.toLowerCase());
    }

    const isCargo = ( desk?.toLowerCase() === UDMDesk.PipelineUsecFloridaDesk  ||  desk?.toLowerCase() === UDMDesk.PipelineUswcPNWDesk || desk === UDMDesk.PipelineUsecRichmondDesk || desk === UDMDesk.PipelineUsecPascagoulaDesk);
    // For RP screen
    if(!isMoreMenuEditNom){
      let pipelineNomination : any;
      const fieldType = this.params?.colDef?.field;
        const nomCol = fieldType?.split('_')[2];
        let fieldDataName = fieldType?.split('_')[3] ? fieldType.split('_').slice(0, 3).join('_') : fieldType;
        const [materialId,materialDesc,nominationQty,refDocInd,scheduleType,pipelineDate,locationId,nomField,rowID,rowItemId] = this.fetchGroupingProperties(nomCol,fieldDataName,fieldType);
  
        const udmNomGroupingId =  this.params?.data[`${nomField}_${nomCol}_udmNominationGrouping_Child`]; 
        let udmGrouplingPayload: any;
        let getNomDetails: any;
        if (desk?.includes('clean products') && isCargo) {
          let currentPayload = currentScreen === "replenishmentplanning" ? pipelineTRFilterResponse : pipelineNomFilterResponse;
          udmGrouplingPayload = this.getNominationByUdmGroupingIdPayload(rowID, udmNomGroupingId, updatedDesk, currentPayload)
          getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingIdPostCall(udmGrouplingPayload));
        } 
        if (desk?.includes('clean products') && !isCargo)
        {
          const nominationNo =  this.params?.data[`${nomField}_${nomCol}_nominationNo_Child`]; 
          let currentPayload = currentScreen === "replenishmentplanning" ? pipelineTRFilterResponse : pipelineNomFilterResponse;
          udmGrouplingPayload = this.getNominationByUdmGroupingIdPayload(rowID, udmNomGroupingId, updatedDesk, currentPayload);
          getNomDetails = await firstValueFrom(this.nominationService.getNominationByNominationNo(rowID, nominationNo, updatedDesk,udmGrouplingPayload));
        }
        if (desk?.includes('refinery + usec') && !isCargo) {
          const nominationNo =  this.params?.value;
          let currentPayload = pipelineNomFilterResponse;
          udmGrouplingPayload = this.getNominationByUdmGroupingIdPayload(rowID, udmNomGroupingId, updatedDesk, currentPayload)
          getNomDetails = await firstValueFrom(this.nominationService.getNominationByNominationNo(rowID, nominationNo, updatedDesk,udmGrouplingPayload));
        }
  
        let updatedLineItems:any = [];
        let combinedHeaderText = ""; 
        let mot = 'Pipeline';
        let motDesc = 'Pipeline';
        let vehId = null;
        let vehName=null;
        let tug:any = null;
        let udmDesk: any = null;
        getNomDetails.result.forEach((res:any)=>{
         let formattedRes = res.map((ele: any) => {
            ele.scheduledDate = this.dateCellRenderer(ele.scheduledDate);
            ele.scheduledQty = this.checkIfCCAView(desk) ? ele?.scheduledQty : ele?.scheduledQty / 1000;
            ele.nominationReferenceDocumentItem = this.removeLeadingZeros(ele.nominationReferenceDocumentItem);
            mot = ele.modeOfTransportDesc;
            motDesc = ele.modeOfTransportDesc;
            vehId = ele.tdVehNo;
            vehName = ele.vehicleName;
            tug = ele.udmTug;
            return ele;
          })
          updatedLineItems.push(formattedRes);
        })
        let transportSystem = null;
        transportSystem=updatedLineItems.length?updatedLineItems[0][0]?.transportSystem:'';
        let headerTextLine=null;
        if(getNomDetails.result.length > 1) {
            combinedHeaderText = [...new Set(getNomDetails.result.map((item:any)=> item[0].headerTextLine))].join(", ");
            headerTextLine = combinedHeaderText;
         }
          else{
            headerTextLine=updatedLineItems.length ? updatedLineItems[0][0]?.headerTextLine : '';
          }
          let btCustodyChain =  updatedLineItems.length ?(updatedLineItems[0][0]?.btCustodyChain?? updatedLineItems[0]?.btCustodyChain): '';
        pipelineNomination = {
          "id":this.params.data[`${fieldDataName}_id`] ?? this.params.data[`${fieldDataName}_id_Child`],
          "itemId": this.params.data[`${fieldDataName}_itemId_Child`],
          "tempTRKey": null,
          "nominationNo": this.params.data[`${fieldDataName}_nominationNo_Child`],
          "nominationKey": this.params.data[`${fieldDataName}_nomKey_Child`],
          "nominationKeyItem": null,
          "partnerNominationNumber": null,
          "scheduledMaterialName": materialDesc,
          "scheduledMaterial": materialId,
          "demandMaterial": materialId,
          "demandMaterialName": materialDesc,
          "scheduledQty": nominationQty,
          "modeOfTransportDesc": motDesc,
          "modeOfTransport": mot,
          "carrier": null,
          "supplier": null,
          "carrierName": null,
          "supplierName": null,
          "scheduleType": scheduleType,
          "udmDesk" : data.deskSet,
          "nominationType": null,
          "tswNominationType": "",
          "nominationReferenceDocument": null,
          "referenceDocumentIndicator": refDocInd,
          "locationId": locationId,
          "scheduledDate": pipelineDate,
          "scenario": null,
          "actualPostedQuantity": null,
          "quantityToleranceOver": "0.0",
          "transportSystem": transportSystem,
          "udmComments": null,
          "createdOn": new Date().toISOString(),
          "sys_status": rowID && rowItemId ? this.params.data[`${nomField}_${nomCol}_status_Child`] : "draft",  //[nomField + '_nominationId_status_Child']
          "nominationItemSubstatus": null,
          "vehicleName": vehName,
          "vehicleId": vehId,
          "tdVehNo": vehId,
          ...(!!tug && { "udmTug": tug }),
          "udmDeliveryLaycanStartDate": null,
          "udmDeliveryLaycanEndDate": null,
          "nominationReferenceDocumentItem": null,
          "nominationItemStatus": null,
          "valuationTypeDestinationLoc": null,
          "scheduledUom": this.params.data[`${fieldDataName}_uom_Child`],
          "unitOfMeasurementName": this.params.data[`${fieldDataName}_uom_Child`],
          "headerTextLine":headerTextLine,
          "btCustodyChain": btCustodyChain,
          "headerToItemNav": [
            ...updatedLineItems
          ],
          "createdBy": userEmail,
          "modifiedBy": null,
          "udmNominationGrouping":  this.params.data[`${fieldDataName}_udmNominationGrouping_Child`],
        };

        if (desk?.includes('refinery + usec') && !isCargo) {
          pipelineNomination.nominationKey = getNomDetails?.result[0][0]?.nominationKey;
          pipelineNomination.nominationNo = getNomDetails?.result[0][0]?.nominationNumber;
        }
        
        return pipelineNomination;
    } else{ // For nominations screen
      let  pipelineNomination:any;
      pipelineNomination = this.params.data;
      if(!pipelineNomination){
        pipelineNomination={};
      }
      const rowID = this.getNominationId(data, desk);
      const udmNomGroupingId =  this.params?.data?.udmNominationGrouping;
      let udmGrouplingPayload: any;
      let getNomDetails: any;
      
      if (desk?.includes("clean products") && data.type !== 'group' && !desk?.includes("+ latam + panama")) {
        let currentPayload = currentScreen === "replenishmentplanning" ? pipelineTRFilterResponse : pipelineNomFilterResponse;
        udmGrouplingPayload = this.getNominationByUdmGroupingIdPayload(rowID, udmNomGroupingId, updatedDesk, currentPayload)
        getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingIdPostCall(udmGrouplingPayload));
      } else if(data.type === 'group' && !desk?.includes("+ latam + panama") && !isCargo){
        let currentPayload = currentScreen === "replenishmentplanning" ? pipelineTRFilterResponse : pipelineNomFilterResponse;
        udmGrouplingPayload = this.getNominationByUdmNomNoPayload(data.values?data.values:data.data.nominationNo, updatedDesk, currentPayload);
        getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingNo(udmGrouplingPayload))
        if (getNomDetails?.result?.length > 0) {
          this.loaderService.setLoading(false);
        }
      } else if (desk?.includes("+ latam + panama")) {
        getNomDetails = await firstValueFrom(this.nominationService.getNominationByNominationNumber(rowID, updatedDesk));
        const refineDataStructure = JSON.parse(JSON.stringify(getNomDetails));
        getNomDetails.result = [];
        getNomDetails?.result?.push(refineDataStructure.result);
      }
      else if(data.type === 'group'&& isCargo){
        let currentPayload = currentScreen === "replenishmentplanning" ? pipelineTRFilterResponse : pipelineNomFilterResponse;
        data.nomId = data.nomId ? data.nomId : data.values?data.values:data?.data?.id;
        udmGrouplingPayload = this.getNominationByUdmNomNoPayload(data.values?data.values:data?.data?.nominationNo, updatedDesk, currentPayload, data.nomId, isCargo);
        getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingNo(udmGrouplingPayload))
        getNomDetails = await firstValueFrom(this.nominationService.getNominationByUdmGroupingIdPostCall(udmGrouplingPayload));
      }
      
     
      let updatedLineItems:any = [];
      let combinedHeaderText = ""; 
      getNomDetails.result.forEach((res:any)=>{
       let formattedRes = res.map((ele: any) => {
          ele.scheduledDate = this.dateCellRenderer(ele.scheduledDate);
          ele.scheduledQty =  this.checkIfCCAView(desk) ? ele?.scheduledQty : ele?.scheduledQty / 1000; // to avoid / 1000 for cca panama desk as in modalpopup qty will be in bbl
          ele.nominationReferenceDocumentItem = this.removeLeadingZeros(ele.nominationReferenceDocumentItem);
          return ele;
        })
        updatedLineItems.push(formattedRes);
      })
      let transportSystem = null;
      transportSystem=updatedLineItems.length?updatedLineItems[0][0]?.transportSystem:'';
      if (getNomDetails.result.length > 1){
      combinedHeaderText = [...new Set(getNomDetails.result.map((item:any)=> item[0].headerTextLine))].join(", ");
      pipelineNomination['headerTextLine'] = combinedHeaderText;
      }
      else{
      pipelineNomination['headerTextLine'] = updatedLineItems.length ? updatedLineItems[0][0]?.headerTextLine : '';
      }
      pipelineNomination['btCustodyChain'] = updatedLineItems.length ? (updatedLineItems[0][0]?.btCustodyChain?? updatedLineItems[0]?.btCustodyChain) : '';
      pipelineNomination['createdOn'] = new Date().toISOString();
      pipelineNomination['createdBy'] = userEmail;
      pipelineNomination['transportSystem'] = transportSystem;
      pipelineNomination['udmNominationGrouping'] = this.params?.data?.udmNominationGrouping;
      pipelineNomination['sys_status'] = updatedLineItems.length ? updatedLineItems[0][0]?.sys_status : '';
      pipelineNomination['headerToItemNav'] = [...updatedLineItems];
      pipelineNomination['udmDesks'] = data.deskSet;
      pipelineNomination['id'] = rowID;
      pipelineNomination['nominationNo'] = data.nominationNo ? data.nominationNo : ( updatedLineItems.length ? updatedLineItems[0][0]?.nominationNumber : '');
      pipelineNomination['editNomination'] = data.editNomination;
      return pipelineNomination;
     
    }
   
    
  }
  }

  getNominationId(data: any, desk: any) {
    if (desk?.includes("+ latam + panama") && data?.type === 'group') {
      return data?.values?.id || data?.id || data?.data?.id
    } else if (data?.id) {
      return data?.id
    } else {
      return data?.data?.id
    }
  }

  async getPipelinePayloadForNomNo(data:any){
    let trFilter:any;
    let nomFilter:any;
    let currentScreen: any;
    let desk = data.desk;

    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (desk.includes('clean products') || desk.includes('refinery')) {
          trFilter = response.pipelineFilterValues;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (desk.includes('clean products')  || desk.includes('refinery')) {
          nomFilter = response.pipelineNomFilterValues;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedRefineryNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (desk.includes('refinery')) {
          nomFilter = response.pipelineNomFilterValues;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    let currentPayload = currentScreen === "replenishmentplanning" ? trFilter : nomFilter;
    let appliedFilter = this.createPipelinePayload(JSON.parse(JSON.stringify(currentPayload)));
    this.getPipelineNominationMasterData(appliedFilter, true)
    return {
      masterData: appliedFilter.masterData,
      nominationNo: data.values,
      desk: desk.includes('refinery') ? 'refinery':'pipeline'
    }
    
  }

  checkFutureDateFromLastInvUpdatedDate(params:any) {
    if(params?.data?.isUdmManagedInv){
      let lastUpdatedDate = params.data.date;
      
      // Create a Date object from the given string
      lastUpdatedDate = new Date(lastUpdatedDate);
      
      // Get the next day by adding 1 to the current date
      const nextDate = new Date(lastUpdatedDate);
      nextDate.setDate(lastUpdatedDate.getDate() + 1);

      // Format the next date as a string
      //const nextDateString = nextDate.toLocaleDateString();

      return nextDate.getTime() > lastUpdatedDate.getTime();
    }else{
      return false;
    }
  }

  checkPast14DaysEditable(params: any, checkChildRows = false) {
    if (!checkChildRows) {
      const getStockProjDate = params.data.date ? params.data.date : params.data.scheduledDate;
      return (new Date(getStockProjDate).getTime() >= (new Date().setDate((this.getPSTDateObj()).getDate() - this.getEditableDaysForInv()))) && (new Date(getStockProjDate).getTime() <= new Date(this.getPSTDateObj().toLocaleDateString()).getTime())
    } else {
      const getStockProjDate = params.data.date ? params.data.date : params.data.scheduledDate;
      return (new Date(getStockProjDate).getTime() >= (new Date().setDate((this.getPSTDateObj()).getDate() - this.getEditableDaysForInv())) && params?.data.date !== "") && (new Date(getStockProjDate).getTime() <= new Date(this.getPSTDateObj().toLocaleDateString()).getTime() && params?.data.date !== "")
    }
  }

  getEditableDaysForInv(){
    return 15;
  }

  searchBackwards(array:any,key:any,startIndex:any) {
    for(let i = startIndex; i >= 0; i--) {
        if(array[i][key]) {
            return array[i];
        }
    }
    return null;
  }
  getPipelineCPDropdownListForSearch(scenario: any, desk?: any, tranportSys?: any, terminals?: any, products?: any){
    
    let data = this.getRequiredPipelineFilterJsonStructureForSearch(this.deskSearchMdm || this.globalPipelineAggregatorResponse, false, false);
    let dd = JSON.parse(JSON.stringify(data))
    if(!dd.modeoftransport){
      dd.modeoftransport = [];
    }
    let modeoftransportCode = dd.modeoftransport?.filter((ele:any)=>scenario?.toLowerCase() === ele.modeOfTransportDesc.toLowerCase())[0]?.modeOfTransportCode
    let isTank = 'intank' === scenario?.toLowerCase();

    dd.transportsystem = dd.transportsystem?.filter((el:any)=>(el?.modeOfTransportCode?.toLowerCase() === modeoftransportCode?.toLowerCase() || (isTank && (el?.transportSystemCode?.toLowerCase() === 'ite' || el?.transportSystemCode?.toLowerCase() === 'itw'))))
    const lists: any = this.getPipelineMasterDataDropdownList(dd, tranportSys, terminals, products)
    return lists;
  }
  getPipelineCPDropdownList(scenario: any, tranportSys?: any, terminals?: any, products?: any) {
    const currentDeskId: any = localStorage.getItem("deskId");
     if( currentDeskId !== '25'){
      if (this.globalPipelineAggregatorResponseForDropdown?.deskId !== +currentDeskId) {
        let mdmRes: any = this.getData(currentDeskId, false);
        let res = mdmRes?.value?.value[0]
        this.globalPipelineAggregatorResponseForDropdown = res
      }
      let {filterObj, copyOfFilterObj} = this.getRequiredPipelineFilterJsonStructure(this.globalPipelineAggregatorResponseForDropdown, false, false);
      let dd = JSON.parse(JSON.stringify(filterObj))
      if (!dd.modeoftransport) {
        dd.modeoftransport = [];
      }
      let modeoftransportCode = dd.modeoftransport?.filter((ele: any) => scenario?.toLowerCase() === ele.modeOfTransportDesc.toLowerCase())[0]?.modeOfTransportCode
      let isTank = 'intank' === scenario?.toLowerCase();
      dd.transportsystem = dd.transportsystem?.filter((el: any) => (el?.modeOfTransportCode?.toLowerCase() === modeoftransportCode?.toLowerCase() || (isTank && (el?.transportSystemCode?.toLowerCase() === 'ite' || el?.transportSystemCode?.toLowerCase() === 'itw'))))
      const lists: any = this.getPipelineMasterDataDropdownList(dd, tranportSys, terminals, products)
      return lists;
     }
  }

  async getData(storedDeskId:any, isTrackInv:any){
    let payload:any = [];
    if (isTrackInv) {
      payload.push({
        "field": "trackInv",
        "operator": "eq",
        "value": isTrackInv
      })
    }
    let response =  await firstValueFrom(this.mdmService.getAggregateData(+storedDeskId, payload));
    return response;
  }


  getFilterDataList(isFromAtocomplete:any, tranportSys?:any, terminals?:any){
    const currentDeskId: any = localStorage.getItem("deskId");
    if (this.globalPipelineAggregatorResponse?.deskId !== +currentDeskId) {
      this.getScreenName()
      const storedDeskId: any = localStorage.getItem("deskId");
      let isTrackInv = this.currentScreen !== 'nominations';
      let mdmRes: any = this.getData(storedDeskId, isTrackInv);
      let res = mdmRes.value.value[0]
      this.globalPipelineAggregatorResponse = res
    }
    let {filterObj, copyOfFilterObj} = this.getRequiredPipelineFilterJsonStructure(this.globalPipelineAggregatorResponse, !isFromAtocomplete, false);
    const lists: any = this.getPipelineMasterDataDropdownList(filterObj, tranportSys, terminals)
    return lists;
  }

  getPipelineMasterDataDropdownList(data: any, tranportSys?: any, terminals?: any, products?: any) {
    let terminalsList: any = [];
    let productsList: any = [];
    data.transportsystem = [...new Map(data.transportsystem?.map((item: any) => [item['transportSystemCode'], item])).values()];

    if (tranportSys || terminals || products) {
      if (tranportSys?.length > 0 && terminals?.length > 0) {
        terminalsList = data['terminal'].filter((element: any) => { return this.findCommonElements(element.transportSystem, tranportSys) }).map((el: any) => {
          return { terminalName: el.terminalName, terminalCode: el.terminalCode, terminalSRACode: el.locationSRACode }
        })
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, terminals) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory, productSRACode: el.materialSRACode }
        })

      }
      else if (tranportSys?.length > 0) {
        terminalsList = data['terminal'].filter((element: any) => { return this.findCommonElements(element.transportSystem, tranportSys) }).map((el: any) => {
          return { terminalName: el.terminalName, terminalCode: el.terminalCode ,  terminalSRACode: el.locationSRACode}
        })
        let selectedTerminalsList = terminalsList.map((item: any) => item.terminalCode);
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, selectedTerminalsList) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory, productSRACode: el.materialSRACode }
        })
      }else{
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, terminals) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory, productSRACode: el.materialSRACode }
        })
      }
      let dropdownlist = { transportsystem: data.transportsystem, terminal: terminalsList, product: productsList }
      return { value: dropdownlist };
    } else {
      return { value: data };
    }
  }

  findCommonElements(arr1: any, arr2: any) {
    return arr1.some((item: any) => arr2.includes(item))
  }

  getScreenName(){
    if(this.callCount === 1){
      this.callCount = 0;
      this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    }
   }
   async getPipelineDropdownListforSearch( desk: any, tranportSys?: any, terminals?: any, products?: any){
    const storedDeskId: any = parseInt(desk);
    let isTrackInv = this.currentScreen !== 'nominations';
    let payload: any = [];
    if (isTrackInv) {
      payload.push({
        "field": "trackInv",
        "operator": "eq",
        "value": isTrackInv
      })
    }
    if( storedDeskId !== '25'){

    let mdmRes: any = await firstValueFrom(this.mdmService.getAggregateData(storedDeskId, payload));
    let res = mdmRes.value.value[0]
    this.globalPipelineAggregatorResponse = res;
    let data = this.getRequiredPipelineFilterJsonStructureForSearch(res, false, false);
    const lists = this.getPipelineMasterDataDropdownList(data, tranportSys, terminals, products);
    return lists;    

    }
    else{
      return null;
    }
   }
  addSraCode(res: any){
    res.forEach((ele: any) => {
      ele.deskLocations.forEach((item: any)=> {
        item.locationSRACode = LocSraCode[item.locationCode];
        item.materialLocations.forEach((li:any) => {
          li.materialSRACode = prodTSWCode[li.materialNumber]
        })
      })
    })
    return res;
  }
  async getPipelineDropdownList(tranportSys?: any, terminals?: any, products?: any) {
    // For updating this.globalPipelineAggregatorResponse correctly after switching desks, added deskId condition
    const currentDeskId: any = localStorage.getItem("deskId");
    this.selectedDeskId = currentDeskId && (currentDeskId && currentDeskId !== null && currentDeskId?.toLowerCase() !== 'null') ? currentDeskId : this.deskListResponse[0]?.filterValues[0]?.id;
    localStorage.setItem("deskId", this.selectedDeskId);
    if (this.globalPipelineAggregatorResponseForDropdown?.deskId !== +currentDeskId) {
      this.getScreenName()
      const storedDeskId: any = localStorage.getItem("deskId");
      if( storedDeskId !== '25'){
        let mdmRes: any = await firstValueFrom(this.mdmService.getAggregateData(+storedDeskId));
        let res = mdmRes.value.value[0]
        this.globalPipelineAggregatorResponseForDropdown = res
      }
    }
    let {filterObj, copyOfFilterObj} = this.getRequiredPipelineFilterJsonStructure(this.globalPipelineAggregatorResponseForDropdown, false, false);
    const lists = this.getPipelineMasterDataDropdownList(filterObj, tranportSys, terminals, products)
    return lists;
  }

  getRequiredPipelineFilterJsonStructureForSearch(response: any, IsSPMGroupRequired: any, IsRPFilterPanel: any, isaCPDesk?:boolean){
    let filterObj: any = { transportsystem: [], terminal: [], product: [] }
    response?.deskTsSummary?.forEach((ts: any) => {


        filterObj.transportsystem.push({
          transportSystemCode: ts.transportSystem,
          transportSystemName: ts.transportSystemName,
          modeOfTransportDesc: ts.modeOfTransportDesc,
          modeOfTransportCode: ts.modeOfTransport
        });
      })
      let isCargoType = this.currentSelectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.currentSelectedDesk === UDMDesk.PipelineUswcPNWDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecRichmondDesk || ['5','17','16','9'].includes(JSON.stringify(response?.deskId)) ? true : undefined

      this.getAggMOT(isCargoType, response, filterObj, IsRPFilterPanel)
       response?.deskLocations.forEach((loc: any) => {
         if (((IsRPFilterPanel && !loc.exchangeTerminal) || (!IsRPFilterPanel)) && loc.locationCode) {
           let tsList = loc.transportSystemInfo.map((termTS: any) => termTS.transportSystem)
           filterObj.terminal.push({
             terminalCode: loc.locationCode,
             terminalName: loc.locationName,
             transportSystem: tsList
           });
           this.getAggProductList(loc,IsSPMGroupRequired,filterObj,response,IsRPFilterPanel)
         }
       });
       this.deskSearchMdm = response;
       return filterObj;
  }

  isRefineryScreen() {
    return this.currentSelectedDesk?.includes("refinery + usec");
  }

  getFilterObjForRefinery(filterObj: any, copyOfFilterObj: any) {
    const prepareJSONforME = (_filterObj: any) => {
      let allTerminals = _filterObj['terminal'].filter((tl:any) => tl.terminalCode.toLowerCase());
      let terminal = _filterObj['terminal'].filter((tl:any) => !tl.terminalCode.toLowerCase().includes("-x") && tl.terminalCode === "PASADENA");
      // let terminal = _filterObj['terminal'].filter((tl:any) => !tl.terminalCode.toLowerCase().includes("-x"));
      if(this.currentScreen === "nominations" && this.currentSelectedDesk.includes('refinery')){
         terminal = _filterObj['terminal'].filter((tl:any) => tl.terminalCode.toLowerCase());
      }
      const transportsystem = _filterObj['transportsystem'].filter((ts: any) => terminal.some((tl: any) => tl.transportSystem.includes(ts.transportSystemCode)));
      const product = _filterObj['product'].filter((prod: any) => terminal.some((tl:any) => prod.terminalCode.includes(tl.terminalCode)));
      return {..._filterObj, terminal, transportsystem, product, allTerminals}  
    }
    filterObj = prepareJSONforME(filterObj);
    copyOfFilterObj = prepareJSONforME(copyOfFilterObj);
    return {filterObj, copyOfFilterObj}
  }

  getRequiredPipelineFilterJsonStructure(response: any, IsSPMGroupRequired: any, IsRPFilterPanel: any, isaCPDesk?: boolean) {
    let filterObj: any = { transportsystem: [], terminal: [], product: [] }
    let copyOfFilterObj: any = { transportsystem: [], terminal: [], product: [] }
    if (!(IsRPFilterPanel && +(response.deskId) === 16)) {
      response?.deskTsSummary?.forEach((ts: any) => {
        if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['ite', 'pp', 'poe'].includes(ts.transportSystem.toLowerCase()))) {
          filterObj.transportsystem.push({
            transportSystemCode: ts.transportSystem,
            transportSystemName: ts.transportSystemName,
            modeOfTransportDesc: ts.modeOfTransportDesc,
            modeOfTransportCode: ts.modeOfTransport
          });
          copyOfFilterObj.transportsystem.push({
            transportSystemCode: ts.transportSystem,
            transportSystemName: ts.transportSystemName,
            modeOfTransportDesc: ts.modeOfTransportDesc,
            modeOfTransportCode: ts.modeOfTransport
          });
        }
      })
      let isCargoType = this.currentSelectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.currentSelectedDesk === UDMDesk.PipelineUswcPNWDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecRichmondDesk || isaCPDesk ? true : undefined
      this.getAggMOT(isCargoType, response, filterObj, IsRPFilterPanel, copyOfFilterObj)
      response?.deskLocations.forEach((loc: any) => {
        if (((IsRPFilterPanel && !loc.exchangeTerminal) || (!IsRPFilterPanel)) && loc.locationCode) {
          let tsList = loc.transportSystemInfo.map((termTS: any) => termTS.transportSystem)
          if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['colltran', 'pascagou'].includes(loc.locationCode.toLowerCase()))) {
            filterObj.terminal.push({
              terminalCode: loc.locationCode,
              terminalName: loc.locationName,
              transportSystem: tsList
            });
            this.getAggProductList(loc, IsSPMGroupRequired, filterObj, response, IsRPFilterPanel)
          }
        }
      });
      response?.deskLocations.forEach((loc: any) => {
        if (loc.locationCode) {
          let tsList = loc.transportSystemInfo.map((termTS: any) => termTS.transportSystem)
          if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['colltran', 'pascagou'].includes(loc.locationCode.toLowerCase()))) {
            copyOfFilterObj.terminal.push({
              terminalCode: loc.locationCode,
              terminalName: loc.locationName,
              transportSystem: tsList
            });
            this.getAggProductList(loc, IsSPMGroupRequired, copyOfFilterObj, response, IsRPFilterPanel)
          }
        }
      });
    }
    return this.isRefineryScreen() ? this.getFilterObjForRefinery(filterObj, copyOfFilterObj) :  {filterObj, copyOfFilterObj};

  }
  getRequiredPipelineFilterJsonStructure2(response: any, IsSPMGroupRequired: any, IsRPFilterPanel: any, isaCPDesk?: boolean) {
    let filterObj: any = { transportsystem: [], terminal: [], product: [] }
    if (!(IsRPFilterPanel && +(response.deskId) === 16)) {
      response?.deskTsSummary?.forEach((ts: any) => {
        if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['ite', 'pp', 'poe'].includes(ts.transportSystem.toLowerCase()))) {
          filterObj.transportsystem.push({
            transportSystemCode: ts.transportSystem,
            transportSystemName: ts.transportSystemName,
            modeOfTransportDesc: ts.modeOfTransportDesc,
            modeOfTransportCode: ts.modeOfTransport
          });
        }
      })
      let isCargoType = this.currentSelectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.currentSelectedDesk === UDMDesk.PipelineUswcPNWDesk || this.currentSelectedDesk === UDMDesk.PipelineUsecRichmondDesk || isaCPDesk ? true : undefined
      this.getAggMOT(isCargoType, response, filterObj, IsRPFilterPanel)
      response?.deskLocations.forEach((loc: any) => {
        if (((IsRPFilterPanel && !loc.exchangeTerminal) || (!IsRPFilterPanel)) && loc.locationCode) {
          let tsList = loc.transportSystemInfo.map((termTS: any) => termTS.transportSystem)
          if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['colltran', 'pascagou'].includes(loc.locationCode.toLowerCase()))) {
            filterObj.terminal.push({
              terminalCode: loc.locationCode,
              terminalName: loc.locationName,
              transportSystem: tsList
            });
            this.getAggProductList(loc, IsSPMGroupRequired, filterObj, response, IsRPFilterPanel)
          }
        }
      });
    }
    return filterObj;
  }

  getAggProductList(loc: any, IsSPMGroupRequired: any, filterObj: any, response: any, IsRPFilterPanel: any) {
    loc.materialLocations.forEach((products: any) => {
      if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['216103990', '203410990'].includes(products.materialNumber))) {
        if (products.materialNumber) {
          let isSPMProduct = (products.aggregateUnderGroup && (!!products.materialSubgroup && products.materialSubgroup !== null));
          let prodNo = IsSPMGroupRequired && (isSPMProduct) ? `${products.materialGroup}-${products.materialSubgroup}` : products.materialNumber
          let isProductPresent = filterObj.product.findIndex((ele: any) => ele.productCode === prodNo)
          if (isProductPresent >= 0) {
            filterObj.product[isProductPresent].terminalCode.push(loc.locationCode)
          } else {
            filterObj.product.push({
              productName: IsSPMGroupRequired && (isSPMProduct) ? products.materialSubgroupDescription : products.materialDescription,
              productCategory: products.levelValue2Description?.length > 0 ? products.levelValue2Description : products.levelValue1Description,
              productCode: prodNo,
              terminalCode: [loc.locationCode],
              trackInv: `${products.trackInv}_${prodNo}`
            })
          }
        }
      }
    })
    filterObj.product = [...new Map(filterObj.product.map((item: any) => [item['productCode'], item])).values()];
  }

  getAggMOT(isCargoType: any, response: any, filterObj: any, IsRPFilterPanel: any, copyOfFilterObj?: any) {
    if (isCargoType || this.currentSelectedDesk === "clean products + latam + panama desk" || this.currentSelectedDesk === "clean products + latam + ecca desk" || this.currentSelectedDesk === "clean products + latam + wcca desk" || this.isRefineryScreen()) {
      let modList: any = []
      response?.deskTsSummary.forEach((ts: any) => {
        if (!(IsRPFilterPanel && +(response.deskId) === 17 && !['pipeline'].includes(ts.modeOfTransportDesc.toLowerCase()))) {
          if ((ts.modeOfTransportDesc && ts.modeOfTransportDesc !== null) && (ts.modeOfTransport && ts.modeOfTransport !== null)) {
            modList.push({
              modeOfTransportDesc: ts.modeOfTransportDesc,
              modeOfTransportCode: ts.modeOfTransport
            });
            if (ts.modeOfTransportDesc === "Vessel" && ts.vehicleIdentifiers.includes('B')) {
              modList.push({
                modeOfTransportDesc: 'Barge',
                modeOfTransportCode: 'S'
              });
            }
          }
        }
      })
      filterObj.modeoftransport = [...new Map(modList.map((item: any) => [item['modeOfTransportDesc'], item])).values()];
      if (copyOfFilterObj) {
        copyOfFilterObj.modeoftransport = [...new Map(modList.map((item: any) => [item['modeOfTransportDesc'], item])).values()];
      }
    }
  }

  
 masterDataForPayloadNom(pipelineAgRes: any, currentAppliedFilter: any,forGrouingAPI: any, masterData: any ){
  pipelineAgRes?.deskLocations?.forEach((loc: any) => {
      let deskLocationDetails: any = {
        locationCode: loc.locationCode,
        locationName: loc.locationName,
        active: forGrouingAPI ? undefined : loc.active
      };
      let materialLocations: any = [];
      loc.materialLocations.forEach((product: any) => {
          materialLocations.push({
            materialNumber: product.materialNumber,
            materialDescription: product.materialDescription,
            materialGroup: forGrouingAPI ? undefined : product.materialGroup,
            materialSubgroup: forGrouingAPI ? undefined : product?.materialSubgroup?.toString()
          })
      })
      deskLocationDetails.materialLocations = materialLocations;
      masterData.push(deskLocationDetails)
    
  });
 }
  getPipelineNominationMasterData(currentAppliedFilter: any, forGrouingAPI?: any) {
    let pipelineAgRes: any;
    let masterData: any = [];
    if (this.globalPipelineAggregatorResponse) {
      pipelineAgRes = JSON.parse(JSON.stringify(this.globalPipelineAggregatorResponseForDropdown ||this.globalPipelineAggregatorResponse))
     this.masterDataForPayloadNom(pipelineAgRes, currentAppliedFilter,forGrouingAPI, masterData)
    }
    currentAppliedFilter.masterData = [{ deskLocations: masterData }]
  }
  
  getCheckedIds(element: any) {
    const list = element.list.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.id);
    return list;
  }

  createPipelinePayload(reqFilter: any) {
    reqFilter.forEach((element: any) => {
      switch (true) {
        
        case (element?.headerName === 'product'):
          reqFilter['product'] = this.getCheckedIds(element)
          break;
        
          case (element?.headerName === 'terminal'):
          reqFilter['terminal'] = this.getCheckedIds(element)
          break;
        
          case (element?.headerName === 'transport system'):
          reqFilter['transportSystem'] = this.getCheckedIds(element)
          break;
        default:
          break;
      }
    });
    return reqFilter;
  }

  getNominationByUdmGroupingIdPayload(rowID: any, udmNomGroupingId: any, updatedDesk: any, currentPayload: any) {
    let appliedFilter = this.createPipelinePayload(JSON.parse(JSON.stringify(currentPayload)));
    this.getPipelineNominationMasterData(appliedFilter, true)
    let payload = {
      nomId: rowID,
      udmgroupingid: (!udmNomGroupingId || udmNomGroupingId === null || udmNomGroupingId?.toLowerCase() === 'null') ? undefined : udmNomGroupingId,
      desk: updatedDesk,
      masterData: this.isSearchOpen ?'':appliedFilter.masterData
    }
    return payload;
  }

  getNominationByUdmNomNoPayload(nomNo: any, updatedDesk: any, currentPayload: any, nomId?:any, isCargo?:any) {
    let appliedFilter =  this.createPipelinePayload(JSON.parse(JSON.stringify(currentPayload)));
    this.getPipelineNominationMasterData(appliedFilter, true)
    let payload = {
      desk: updatedDesk,
      masterData: this.isSearchOpen?'':appliedFilter.masterData,
      ...(!isCargo && {nominationNo: nomNo}),
      ...((isCargo || this.isSearchTab) && {nomId: nomId}),
    }
    return payload;
  }

  //Section Start :: Ethanol MDM functions start from here
  
  getEthanolFilterData(terminalOwnership?:any,desk?:any): Observable<any> {
    const storedDeskId: any = localStorage.getItem("deskId");
    let payload: any = [];
      payload.push({
        "field": "materialNumber",
        "operator": "in",
        "valuesList": ["296660990", "296669990"]
      })
    let res: any;
    let sCpayLoad = {
      deskId: +storedDeskId
    }
    return forkJoin({
      ethanolFilterData: this.mdmService.getAggregateData(+storedDeskId, payload),
      ethanolSupplierCarrier: this.ethanolService.getTruckTerminalFilterList(terminalOwnership, desk, true)
    }).pipe(
      map((results: any) => {
        let ethanolFilterData = results.ethanolFilterData.value.value[0];
        let { carrier, customer, supplier } = results.ethanolSupplierCarrier.value;
        res = { ...ethanolFilterData, carrier, customer, supplier };
        this.globalethanolAggregatorResponse = res;
        return res;
      })
    );
  }

  getSupplierCarrier() {
    this.globalethanolAggregatorResponse.supplier = this.getPaddingadded(this.globalethanolAggregatorResponse?.supplier, 'supplier');
    this.globalethanolAggregatorResponse.carrier = this.getPaddingadded(this.globalethanolAggregatorResponse?.carrier, 'carrierCode');
    let data: any = { supplier: this.globalethanolAggregatorResponse?.supplier || [], carrier: this.globalethanolAggregatorResponse?.carrier || [], customer: this.globalethanolAggregatorResponse?.customer || [] };
    return data;
  }

  getPaddingadded(data:any, type:any){
    let suppCarrData : any = JSON.parse(JSON.stringify(data));
    suppCarrData?.forEach((element:any) => {
      element[type] = this.padSupplierCarrier(element[type]);
    });
    return suppCarrData;
  }

  getEthanolMasterDataDropdownList(data: any, tranportSys?: any, terminals?: any, products?: any) {
    let terminalsList: any = [];
    let productsList: any = [];
    data.transportsystem = [...new Map(data.transportsystem?.map((item: any) => [item['transportSystemCode'], item])).values()];

    if (tranportSys || terminals || products) {
      if (tranportSys?.length > 0 && terminals?.length > 0) {
        terminalsList = data['terminal'].filter((element: any) => { return this.findCommonElements(element.transportSystem, tranportSys) }).map((el: any) => {
          return { terminalName: el.terminalName, terminalCode: el.terminalCode };
        })
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, terminals) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory };
        })

      }
      else if (tranportSys?.length > 0) {
        terminalsList = data['terminal'].filter((element: any) => { return this.findCommonElements(element.transportSystem, tranportSys) }).map((el: any) => {
          return { terminalName: el.terminalName, terminalCode: el.terminalCode };
        })
        let selectedTerminalsList = terminalsList.map((item: any) => item.terminalCode);
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, selectedTerminalsList) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory };
        })
      } else {
        productsList = data['product'].filter((element: any) => { return this.findCommonElements(element.terminalCode, terminals) }).map((el: any) => {
          return { productName: el.productName, productCode: el.productCode, productCategory: el.productCategory };
        })
      }
      let dropdownlist = { transportsystem: data.transportsystem, terminal: terminalsList, product: productsList };
      return { value: dropdownlist };
    } else {
      return { value: data };
    }
  }

  getRequiredEthanolFilterJsonStructure(response: any, IsSPMGroupRequired: any, IsRPFilterPanel: any) {
    let filterObj: any = { transportsystem: [], terminal: [], product: [], modeOfTransportDesc: [], motHeaderItem: [], nominationType: [] };
    let transportSystem: { name: string, value: string }[] = [];
      response?.deskTsSummary?.forEach((ts: any) => {
        filterObj.modeOfTransportDesc = Nom.modeOfTransportDesc;
        filterObj.nominationType = Nom.nominationTypes;
        filterObj.transportsystem.push({
          transportSystemCode: ts.transportSystem,
          transportSystemName: ts.transportSystemName,
          modeOfTransportDesc: ts.modeOfTransportDesc,
          modeOfTransportCode: ts.modeOfTransport,
        });
      })
      filterObj.transportsystem.forEach((ts: any) => {
        transportSystem.push({
          name: ts.transportSystemCode.toLowerCase(),
          value: ts.transportSystemCode
        })
        filterObj.motHeaderItem = Nom.motHeaderItem;
        filterObj.TimeFrameOption = Nom.TimeFrameOption;
        filterObj.motHeaderItem = filterObj.motHeaderItem.map((mh: any) => {
          if(mh.modeOfTransportDesc === 'In-Tank'){
            mh.transportSystem = this.currentSelectedDesk.includes('usec') ? Nom.ethanolIteTransportSys : Nom.ethanolItwTransportSys;
          }
          if(mh.modeOfTransportDesc === 'Pipeline'){
            mh.transportSystem = this.currentSelectedDesk.includes('usec') ? Nom.ethanolUSECPipelineTransportSys : Nom.ethanolUSWCPipelineTransportSys
          }
          if (mh.modeOfTransportDesc === 'Barge') {
            mh.transportSystem = this.currentSelectedDesk.includes('usec') ? Nom.ethanolUSECBargeTransportSys : Nom.ethanolUSWCBargeTransportSys;
            if (this.ethanolBargeResponse) {
              return { ...mh, bargeCarrierName: this.ethanolBargeResponse, nominationType: Nom.nominationType };
            } else {
              this.mdmService.getVehicles('B').subscribe({
                next: (response: any) => {
                  if (response?.success && response?.statusCode === 200) {
                    this.ethanolBargeResponse = response.value.value.map((ele: any) => {
                      return {
                        value: ele.vehicle,
                        name: ele.vehicleName
                      }
                    })
                    return { ...mh, bargeCarrierName: this.ethanolBargeResponse, nominationType: Nom.nominationType };
                  }
                }
              });
              return { ...mh, nominationType: Nom.nominationType };
            }
          }
          if (mh.modeOfTransportDesc === 'In-Tank') {
            return { ...mh, nominationType: Nom.inTankNominationType };
          } else {
            return { ...mh, nominationType: Nom.nominationType };
          }
        });
      })
      response?.deskLocations.forEach((loc: any) => {
          let tsList = loc.transportSystemInfo.map((termTS: any) => termTS.transportSystem)
            filterObj.terminal.push({
              id: loc.locationCode,
              name: loc.locationName,
              transportSystem: tsList,
              volume: loc.ethanolTruckVol,
              terminalOwnership: loc.udmTerminalOwnership
            });
            this.getAggProductList(loc, IsSPMGroupRequired, filterObj, response, IsRPFilterPanel);
      });
    let scData = this.getSupplierCarrier();
    filterObj = { ...filterObj, ...scData };
    return filterObj;
  }

  getEthanolTRNomPyaloadMasterData(currentAppliedFilter: any, onlyCred?: any, getMaterials?: any) {
    let masterData: any = {};
    if (currentAppliedFilter && !currentAppliedFilter?.masterData) {
      if (this.globalethanolAggregatorResponse) {
        let ethanolAgRes = JSON.parse(JSON.stringify(this.globalethanolAggregatorResponse))
        this.masterDataForEthanolTRPayloadNom(ethanolAgRes, currentAppliedFilter?.RequestFilter ?? currentAppliedFilter, masterData, onlyCred, getMaterials)
      }
      currentAppliedFilter.masterData = [masterData];
    }
    return currentAppliedFilter;
  }

  getEthanolNomPyaloadMasterData(currentAppliedFilter: any) {
    this.getEthanolTRNomPyaloadMasterData(currentAppliedFilter, true);
  }

  masterDataForEthanolTRPayloadNom(ethanolAgRes: any, currentAppliedFilter: any, masterData: any, onlyCred?: any, getMaterials?:any) {
    this.getLocationListForPayload(ethanolAgRes, currentAppliedFilter, masterData, onlyCred, getMaterials);
    this.getSupplierListForPayload(currentAppliedFilter, masterData);
  }

  getMasterDataForNominationDetailsPayload(currentAppliedFilter:any){
    this.getEthanolTRNomPyaloadMasterData(currentAppliedFilter, true, true);
  }

  getVendorNotificationMaterDataPayload(payload: any){
    this.getEthanolTRNomPyaloadMasterData(payload, true);
  }

  getCreateUpdateMasterDataPayload(updatePayload:any){
    let masterData: any = {};
    if (this.globalethanolAggregatorResponse) {
      let ethanolAgRes = JSON.parse(JSON.stringify(this.globalethanolAggregatorResponse));
      this.CreateUpdateMasterData(ethanolAgRes, masterData);
    }
    updatePayload.map((el:any) =>{
      el.masterData = [masterData];
    })
  }

  CreateUpdateMasterData(ethanolAgRes: any, masterData: any) {
    let deskLocations: any = [];
    ethanolAgRes?.deskLocations?.forEach((loc: any) => {
      let deskLocationDetails: any = {
        locationCode: loc.locationCode,
        locationName: loc.locationName,
      };
      let materialLocations: any = [];
      loc.materialLocations.forEach((product: any) => {
        materialLocations.push({
          materialNumber: product.materialNumber,
          materialDescription: product.materialDescription,
          valuationType: product?.valuationTypes[0]?.valuationType
        })
      })
      deskLocationDetails.materialLocations = materialLocations;
      deskLocationDetails.transportSystemInfo = loc.transportSystemInfo.map((el:any) =>{ return {transportSystem:el.transportSystem}});
      deskLocations.push(deskLocationDetails);
    });
    masterData.deskLocations = deskLocations;
  }

  getLocationListForPayload(ethanolAgRes: any, currentAppliedFilter: any, masterData: any, onlyCred: any = false, getMaterials?: any) {
    let deskLocations: any = [];
    ethanolAgRes?.deskLocations?.forEach((loc: any) => {
        let deskLocationDetails: any = {
          locationCode: loc.locationCode,
          locationName: loc.locationName,
          numHubs: onlyCred ? undefined : loc.numHubs,
          numSpokes: onlyCred ? undefined : loc.numSpokes,
          spokeLocationCodes: onlyCred ? undefined : loc.spokeLocationCodes,
          ethanolTruckVol: loc.ethanolTruckVol
        };
        if (getMaterials) {
          let materialLocations: any = []
          loc.materialLocations.forEach((product: any) => {
            materialLocations.push({
              materialNumber: product.materialNumber,
              materialDescription: product.materialDescription,
            })
          })
          deskLocationDetails.materialLocations = materialLocations;
        }
        deskLocations.push(deskLocationDetails);
    });
    masterData.deskLocations = deskLocations;
  }

  getSupplierListForPayload(currentAppliedFilter: any, masterData: any) {
    let data = this.getSupplierCarrier();
    let supplier: any = [];
    let carrier: any = [];
    let customer: any = [];
    data?.supplier?.forEach((supp: any) => {
        let supplierDetails: any = {
          supplierCode: supp.supplier,
          supplierName: supp.supplierName,
        };
        supplier.push(supplierDetails);
    });

    data?.carrier?.forEach((carr: any) => {
        let carrierDetails: any = {
          carrierCode: carr.carrierCode,
          carrierName: carr.carrierName,
        };
        carrier.push(carrierDetails);
    });
    data?.customer?.forEach((cust: any) => {
        let customerDetails: any = {
          customerCode: cust.customer,
          customerName: cust.customerName,
        };
        customer.push(customerDetails);
    });
    masterData.supplier = supplier.length > 0 ? supplier : [];
    masterData.carrier = carrier.length > 0 ? carrier : [];
    masterData.customer = customer.length > 0 ? customer : [];
  }

  //Section End :: Ethanol MDM functions ends here
  
  removeLeadingZeros(input: any) {
    if (input) {
      if (input == 0) {
        return 0;
      }
      else {
        return input.replace(/^0+/, '');
      }
    }else return input;
  }

  cbDesk(desk:any=''){
    if(desk.selectedDesk.toLowerCase().startsWith('ethanol')){
      return desk.selectedDesk.split(' + ').join('');
    } else if (desk.selectedDesk.toLowerCase().includes('+ latam +')) {
      return desk.selectedDesk;
    } else if (desk.selectedDesk.toLowerCase().startsWith('clean')){
      return `pl${desk.selectedDeskParam}`;
    }
    return '';
  }
  
  checkUniqueIDs(array:any){
    let ids = array.map((obj:any) => obj.id); 
    return new Set(ids).size > 1;
  }
  compareInventoryWithPhyInv(params: any, uom:string = "MB",forInvCalc = false) {
    let wordCount = params?.colDef?.field?.split('_').length;
    let fieldDataName = wordCount === 3 ? params?.colDef?.field?.split('_').slice(0, 2).join('_') : params?.colDef?.field?.split('_')[0];
    let toleranceValue = this.unitConversionService.convertUnits(0.042, "MB", uom) ?? 0;
    let physicalInventory = params?.data[fieldDataName + '_totalInventory_physicalInventory'] && this.unitConversionService.convertUnits(+params?.data[fieldDataName + '_totalInventory_physicalInventory'], params?.data[fieldDataName + '_totalInventory_physicalInventoryUnitOfMeasure'], uom);
    const totalInventory = params?.data[fieldDataName + '_totalInventory'];
    const userEdited = params?.data[fieldDataName + '_totalInventory_udmInvEditedDatetime'] || false;
    physicalInventory = this.roundToThree(physicalInventory);
    if (!forInvCalc){
      return userEdited || (+Math.abs(totalInventory - physicalInventory).toFixed(3) < toleranceValue && params?.data?.date && new Date(params.data.date).getTime() <= new Date(this.getPSTDateObj().toLocaleDateString()).getTime())
    } else{
      return (parseFloat(totalInventory).toFixed(3) === parseFloat(physicalInventory).toFixed(3));
    }
   
  }

  checkForPastDays(params:any, days:number){
    const daysAgo = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
    const today = new Date().getTime();
    return daysAgo.getTime() <= new Date(params.data.date).getTime() &&  new Date(params.data.date).getTime()<= today ;
  }
  warnMinMaxLimit(params: any, colData: any,minValue: any,maxValue: any) {
    const fieldName = params?.colDef?.field; 
    let fieldDataName = params?.colDef?.field?.split('_').slice(0, 2).join('_');
    let currentRowInv = params?.colDef?.headerName.toLowerCase() === 'available inventory' ? +params?.data[fieldDataName + '_' + 'totalInventory'] : +params?.data[fieldName];
    
    return (currentRowInv<=minValue && params?.data?.date && new Date(params.data.date).getTime() > new Date(this.getPSTDateObj().toLocaleDateString()).getTime())|| (currentRowInv>=maxValue && params?.data?.date && new Date(params.data.date).getTime() > new Date(this.getPSTDateObj().toLocaleDateString()).getTime());
  }

  warnEslMinMaxLimit(params: any, colData: any,minEsl: any,maxEsl: any) {
    const fieldName = params?.colDef?.field; 
    let fieldDataName = params?.colDef?.field?.split('_').slice(0, 2).join('_');
    let currentRowInv = params?.colDef?.headerName.toLowerCase() === 'available inventory' ? +params?.data[fieldDataName + '_' + 'totalInventory'] : +params?.data[fieldName];

    return (currentRowInv<=minEsl && params?.data?.date && new Date(params.data.date).getTime() > new Date(this.getPSTDateObj().toLocaleDateString()).getTime())|| (currentRowInv>=maxEsl && params?.data?.date && new Date(params.data.date).getTime() > new Date(this.getPSTDateObj().toLocaleDateString()).getTime());
  }
  
  addExistingDashXLocToSelectedViewTerminals(existingTerminalsWithDashXloc:any,selectedViewTerminals:any){
    // using hash table approach to add dash-x locations
    // Pushing existing terminals with dash-x to map obj
    let map:any = {};
    existingTerminalsWithDashXloc.forEach((obj:any)=>{
      map[obj.terminalCode] = obj
    })
    // Iterating through map obj to find keys which contains dash-x and forming corresponding obj then pushing it to selectedViewTerminals array and returning it
    Object.keys(map).forEach(key=>{
      if(key.toLowerCase().includes("-x")){
       const dashXTerminalObj= {
          name: map[key].terminalName,
          id: map[key].terminalCode,
          checked: false
        };
        // Adding a check to prevent adding duplicate -x locations
        if(!selectedViewTerminals.map((val:any)=>val.id).includes(dashXTerminalObj.id)){
          selectedViewTerminals.push(dashXTerminalObj)
        }
      }
    })
    return selectedViewTerminals;
    
  }

  overHaulPipelineViewDataPayload(currentAppliedFilter:any){
    let masterDataProducts:any = [];
    currentAppliedFilter.masterData[0]?.deskLocations?.forEach((desk:any)=>{
      desk?.materialLocations?.forEach((material:any)=>{
      const isSPMProduct = (material.aggregateUnderGroup && (!!material.materialSubgroup && material.materialSubgroup !== null));
      const updatedMaterialNo = isSPMProduct?`${material.materialGroup}-${material.materialSubgroup}`:material.materialNumber;
        masterDataProducts.push(updatedMaterialNo);
      })
    })
    masterDataProducts = [...new Set(masterDataProducts)];
    return currentAppliedFilter.product?.filter((val:any)=>masterDataProducts.includes(val));
  }

  getPortRegionList(values:any, matchTerminal:string =''){
    let list = [];
    for(var key in values){
      if(values.hasOwnProperty(key)){
        if(!matchTerminal || matchTerminal === key){
          list.push(values[key]);
        }
      }
    }
    list=  list.length?[...new Set(list)]:[];
    return list.map((data: any) => { return { name: data } });
  }

  getTerminalList(values:any){
    let list = [];
    for(var key in values){
      if(values.hasOwnProperty(key)){
        list.push(key);
      }
    }
    list=  [...new Set(list)];
    return list.map((data: any) => { return { name: data } });
  }

  getSupplierList(values:any){
    let list = [];
    for(var key in values){
      if(values.hasOwnProperty(key)){
        list.push(key);
      }
    }
    list=  [...new Set(list)];
    return list.map((data: any) => { return { name: data } });
  }
  getKeyByValue(object: any, value: string | number) {
    return Object.keys(object).find(key => object[key] === value);
  }
  roundToThree(num:any) {
    return +(Math.round(Number(num + "e+3"))  + "e-3");
  }

  getUpdatedCellNode() {
    if (sessionStorage.getItem("updatedCellNode")) {
      const lastUpdatedCellNode:any = JSON.parse(sessionStorage.getItem("updatedCellNode")??'{}');
      return lastUpdatedCellNode;
    } else {
      return null;
    }
  }
  
  getConfirmationIndicatorValue(item:any,isEditPopup:any){
    let confirmationIndicator = "";
    if((item?.nominationItemStatus === "4" || item?.nominationItemStatus === "1" || !item?.nominationItemStatus || item?.nominationItemStatus==="") && item?.confirmationIndicator){
      confirmationIndicator = 'X';
    }
    if(item?.nominationItemStatus === "4" && !item?.confirmationIndicator && isEditPopup){
      confirmationIndicator = 'R';
    }
    if((item?.nominationItemStatus === "1" || !item?.nominationItemStatus || item?.nominationItemStatus==="") && !item?.confirmationIndicator){
      confirmationIndicator = "";
    }
    return confirmationIndicator;
  }

  getLastUpdatedCellStyle():CellClassRules {
    if (sessionStorage.getItem("updatedCellNode")) {
      const lastUpdatedCellNode = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem("updatedCellNode"))));
      const cellClassRules = {
        'highlight-updated-value': (params: any) => {
          const currentRowIndex = params?.node?.rowIndex;
          const currentField = params?.colDef?.field;
          return (currentRowIndex === lastUpdatedCellNode?.rowIndex) && (currentField === lastUpdatedCellNode?.field);
        },
      };
      return cellClassRules;
    } else{
        const cellClassRules = {
        'highlight-updated-value':'false'
      };
      return cellClassRules;
    }

  }

  padSupplierCarrier(str:any){
    return this.pad(str, 10);
  }

  pad(str: any, max: any): any {
    if (str) {
      return str.length < max ? this.pad("0" + str, max) : str;
    } else {
      return str; 
    }
  }

  getOffTakers(terminalList:string[],listDeskId:any) {
    let pdesk = listDeskId.filter((ele:any) => ele.id).map((ele:any)=>ele.id);
    let selectdeskdetails: any[]=[];
            pdesk.forEach((element:any) => {
               selectdeskdetails.push(this.globalMasterDataForBCP.find((item:any)=> item.deskId == element).deskLocations)
      
            });  
    let offtakers:any[] = [];
    if(selectdeskdetails && selectdeskdetails.length > 0)
    {
       terminalList.forEach((location:any) => {
          if(location == "LASMINAS")
          {
            selectdeskdetails.forEach((ele:any)=> {
             ele.map((deskLocation:any) => {
                  if(deskLocation?.locationCode == "LMINASCHEV" || deskLocation?.locationCode == "LMINASTRM")
                    { 
                        deskLocation?.materialLocations?.map((material:any) => {
                              material.valuationTypes?.map((offtaker:any) => {
                                if(!offtakers.find((x => x == offtaker.valuationType)))
                                  offtakers.push(offtaker.valuationType);
                              });
                        });
                    }                
              });
            });
          }
          else
          {
            selectdeskdetails.forEach((ele:any)=> {
              ele?.filter((deskLocation:any) => deskLocation?.locationCode == location)
              .map((locations:any)=>{
                locations.materialLocations?.map((material:any) => {
                      material.valuationTypes?.map((offtaker:any) => {
                        if(!offtakers.find((x => x == offtaker.valuationType)))
                          offtakers.push(offtaker.valuationType);
                      });
                });
              });
            }); 
           
          }
      });
      if(!offtakers.find(x => x == null))
        offtakers.push(null);
    }
    return offtakers;
  }

  formatData(data: any, desk: string, nominationId: string): any {
    try {
      const getNomDetails = this.refineDataStructure(data);
      const updatedLineItems = this.formatLineItems(getNomDetails.result, desk);
      const nomination = this.createNominationObject(data, updatedLineItems, nominationId);
  
      return nomination;
    } catch (error) {
      console.error('Error formatting data:', error);
      throw new Error('Error formatting data');
    }
  }
  
  private refineDataStructure(data: any): any {
    const refineDataStructure = JSON.parse(JSON.stringify(data));
    return { result: [refineDataStructure.result] };
  }
  
  private formatLineItems(result: any[], desk: string): any[] {
    return result.map((res: any) => {
      return res.map((ele: any) => {
        return {
          absoluteToleranceQtyOver: ele.absoluteToleranceQtyOver || null,
          absoluteToleranceQtyUnder: ele.absoluteToleranceQtyUnder || null,
          absoluteToleranceUOM: ele.absoluteToleranceUOM || null,
          batchDestinationLocation: ele.batchDestinationLocation || null,
          batchOriginLocation: ele.batchOriginLocation || null,
          btCustodyChain: ele.btCustodyChain || null,
          cargoStatus: ele.cargoStatus || null,
          carrier: ele.carrier || null,
          confirmationIndicator: ele.confirmationIndicator || null,
          consigneePartner: ele.consigneePartner || null,
          contractPartner: ele.contractPartner || null,
          customer: ele.customer || null,
          cycle: ele.cycle || null,
          cycleId: ele.cycleId || null,
          deletionIndicator: ele.deletionIndicator || null,
          demandMaterial: ele.demandMaterial || null,
          demandMaterialDesc: ele.demandMaterialDesc || null,
          demandMaterialName: ele.demandMaterialName || null,
          headerStatus: ele.headerStatus || null,
          headerTextLine: ele.headerTextLine || null,
          id: ele.id || null,
          inspector: ele.inspector || null,
          itemId: ele.itemId || null,
          locationId: ele.locationId || null,
          locationName: ele.locationName || null,
          locationPartnerPlant: ele.locationPartnerPlant || null,
          locationPartnerStorageLocation: ele.locationPartnerStorageLocation || null,
          modeOfTransport: ele.modeOfTransport || null,
          modeOfTransportDesc: ele.modeOfTransportDesc || null,
          nominationItemStatus: ele.nominationItemStatus || null,
          nominationItemSubstatus: ele.nominationItemSubstatus || null,
          nominationKey: ele.nominationKey || null,
          nominationKeyItem: ele.nominationKeyItem || null,
          nominationNumber: ele.nominationNumber || null,
          nominationReferenceDocument: ele.nominationReferenceDocument || null,
          nominationReferenceDocumentItem: this.removeLeadingZeros(ele.nominationReferenceDocumentItem || 0),
          nominationType: ele.nominationType || null,
          nominationUom: ele.nominationUom || null,
          partnerNominationNumber: ele.partnerNominationNumber || null,
          quantityToleranceOver: ele.quantityToleranceOver || null,
          quantityToleranceUnder: ele.quantityToleranceUnder || null,
          railCarStatus: ele.railCarStatus || null,
          referenceDocumentIndicator: ele.referenceDocumentIndicator || null,
          scheduleType: ele.scheduleType || null,
          scheduledDate: this.dateCellRenderer(ele.scheduledDate || null),
          scheduledDateTo: ele.scheduledDateTo || null,
          scheduledMaterial: ele.scheduledMaterial || null,
          scheduledMaterialDesc: ele.scheduledMaterialDesc || null,
          scheduledMaterialName: ele.scheduledMaterialName || null,
          scheduledQty: this.checkIfCCAView(desk) ? ele.scheduledQty : ele.scheduledQty / 1000,
          scheduledTime: ele.scheduledTime || null,
          scheduledUOM: ele.scheduledUOM || null,
          shipper: ele.shipper || null,
          sys_status: ele.sys_status || null,
          tankagePartner: ele.tankagePartner || null,
          tdShipmentType: ele.tdShipmentType || null,
          tdVehIdentifier: ele.tdVehIdentifier || null,
          tdVehNo: ele.tdVehNo || null,
          textline: ele.textline || null,
          transportSystem: ele.transportSystem || null,
          udmCounterPart: ele.udmCounterPart || null,
          udmDesks: ele.udmDesks || null,
          udmDetailType: ele.udmDetailType || null,
          udmMot: ele.udmMot || null,
          udmNominationGrouping: ele.udmNominationGrouping || null,
          udmTimeFrameOption: ele.udmTimeFrameOption || null,
          udmTug: ele.udmTug || null,
          unitOfMeasurementName: ele.unitOfMeasurementName || null,
          unlimitedOverDeliveryAllowed: ele.unlimitedOverDeliveryAllowed || null,
          valuationTypeDestinationLoc: ele.valuationTypeDestinationLoc || null,
          valuationTypeOriginLoc: ele.valuationTypeOriginLoc || null,
          vehicleCode: ele.vehicleCode || null,
          vehicleName: ele.vehicleName || null
        };
      });
    });
  }
  
  private createNominationObject(data: any, updatedLineItems: any[], nominationId: string): any {
    const nomination: any = {};
    const transportSystem = updatedLineItems.length ? updatedLineItems[0][0]?.transportSystem : '';
    const combinedHeaderText = this.getCombinedHeaderText(updatedLineItems);
    let author = updatedLineItems.length 
    ? (updatedLineItems[0][0]?.textline?.match(/Author:([^,}]+)/) || [])[1] 
      ?? (updatedLineItems[0]?.textline?.match(/Author:([^,}]+)/) || [])[1] 
    : '';
  
    nomination['headerTextLine'] = combinedHeaderText || (updatedLineItems.length ? updatedLineItems[0][0]?.headerTextLine : '');
    nomination['btCustodyChain'] = updatedLineItems.length ? (updatedLineItems[0][0]?.udmNominationGrouping ?? updatedLineItems[0]?.udmNominationGrouping) : '';
    nomination['createdOn'] = new Date().toISOString();
    nomination['createdBy'] = author;
    nomination['transportSystem'] = transportSystem;
    nomination['udmNominationGrouping'] =  updatedLineItems.length ? (updatedLineItems[0][0]?.btCustodyChain ?? updatedLineItems[0]?.btCustodyChain) : '';
    nomination['sys_status'] = updatedLineItems.length ? updatedLineItems[0][0]?.sys_status : '';
    nomination['headerToItemNav'] = updatedLineItems;
    nomination['udmDesks'] = data.deskSet;
    nomination['id'] = nominationId;
    nomination['nominationNo'] = data.nominationNo || (updatedLineItems.length ? updatedLineItems[0][0]?.nominationNumber : '');
    nomination['editNomination'] = data.editNomination;
    return nomination;
  }
  
  private getCombinedHeaderText(updatedLineItems: any[]): string {
    if (updatedLineItems.length > 1) {
      return [...new Set(updatedLineItems.map((item: any) => item[0].headerTextLine))].join(", ");
    }
    return '';
  }
}


