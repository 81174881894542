<div class="cca-nomination-list">
    <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="columnDefs" [rowData]="rowData"
        [rowSelection]="rowSelection" [defaultColDef]="defaultColDef" [components]="components" [masterDetail]="true"
        [isRowMaster]="isRowMaster" [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" [embedFullWidthRows]="true"
        (gridReady)="onGridReady($event)" [autoGroupColumnDef]="autoGroupColumnDef" [aggFuncs]="aggFuncs"
        (selectionChanged)="onSelectionChanged($event)" (cellValueChanged)="onCellValueChanged($event)"
        [enableRangeSelection]="true" [suppressAggFuncInHeader]="true" (rowSelected)="onRowSelectRow($event)"
        [stopEditingWhenCellsLoseFocus]="true">
    </ag-grid-angular>
    <ng-container *ngIf="(isCCAPanamaView && !isIntankMot) || !isCCAPanamaView">
        <np-modal class="cca-nomination-modal" [show]="CcaNominationModal.open" [headerTitle]="modalTitle"
            [subHeaderText]="'*required fields'" [noModalButtons]="true">
            <app-create-nomination-cca (closeModalPopup)="closeCcaModal()" (callGridApi)="onCallGridApi()"
                [isCcaNomEdit]="isCcaNomEdit"></app-create-nomination-cca>
        </np-modal>
    </ng-container>
    <ng-container *ngIf="isCCAPanamaView && isIntankMot">
        <np-modal [show]="terminalNominationModal.open" [headerTitle]="modalTitle"
            [subHeaderText]="'*required fields'" [noModalButtons]="true">
            <app-create-terminal-nomination *ngIf="isCCAPanamaView"
                (closeModalPopup)="closeTerminalNominationModal($event)">
            </app-create-terminal-nomination>
        </np-modal>
    </ng-container>
    <np-modal class="vendor-notification-modal" [show]="vendorNotificationPreview.open"
        [headerTitle]="'selected nominations'" [noModalButtons]="true">
        <app-vendor-notification-preview (closeModalPopup)="closePreviewModal()">
        </app-vendor-notification-preview>
    </np-modal>
</div>