import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { Utilities } from '../../utilities/utilities';
import { getUserDetails, selectDesk } from '../../store/selector/app.selector';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/reducer/app.reducer';
import { Subject, takeUntil } from 'rxjs';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { InformationPanelService, infoPanelData, panelType } from 'src/app/services/information-panel.service';
import { tabEnum } from 'src/app/core/components/information-panel/info-content/info-content.component';

export interface MyCellRendererParams {
  rowDataRecord: any;
  parentRecord: any;
  totalRecords: any;
  isDetailGrid: any;
  isIntankMot: any;
}
@Component({
  selector: 'more-menu-cargo-parent',
  templateUrl: './more-menu-cargo-parent.component.html',
  styleUrls: ['./more-menu-cargo-parent.component.scss']
})
export class MoreMenuCargoParentComponent {
  public isOpen = false;
  public rowIndex: any;
  pipelineParentKey: string='';
  pipelineParentObj:any;
  public rowDataRecord: any;
  public desk: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  isCCAView: any;
  showUi = true;
  details: any;

  constructor(
    private utilities: Utilities,
    private router: Router,
    private appStore: Store<AppState>,
    private cpService: CargoPlanningService,
    private infoPanelService: InformationPanelService,
    ){ }

  agInit(params: ICellRendererParams<any, any, any> & MyCellRendererParams): void {
    if(params.data){
      this.rowIndex = params.node?.rowIndex;
      this.pipelineParentKey = params.data.uid;
      this.pipelineParentObj = params.data;
      this.showUi = params.data.ccaStatus !== 'completed';
    }
    this.rowDataRecord = params;
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      }
    );

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          this.isCCAView = this.utilities.checkIfCCAView(response.selectedDesk);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }

  moreMenu() {
    this.isOpen = !this.isOpen;
  }
  
  editNomination(){
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'CcaCreateNominationWithReference',
      editNomination: true,
      type: 'editCargo',
    };
    this.cpService.editCargoData = this.rowDataRecord.data;
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  openActivityTracker(){
    this.infoPanelService.undockInfoPanel(true);
    this.infoPanelService.updateSelectedSelector(tabEnum.AT);
    this.openInformationPanel()
  }
  
  openComments(){
    this.infoPanelService.showInfoPanel(true);
    this.infoPanelService.updateSelectedSelector(tabEnum.COM);
    this.openInformationPanel()
  }
  
  openInformationPanel() {
    let valueToPass: infoPanelData = {
      name: this.pipelineParentObj.voyageName,
      date: this.pipelineParentObj.loadLaycanStartDate,
      id: this.pipelineParentObj.id,
      type: panelType.CP,
      data: this.pipelineParentObj,
    }
    this.infoPanelService.setPanelData(valueToPass);
  } 
  
  openSidePanel(){
    this.infoPanelService.showInfoPanel(true);
  }
}