<div class="more-menu" (mouseleave)="isOpen = false">
    <ng-container *ngIf="!nominationTab">
      <ng-container *ngIf="details;else showOnlyValue">
        <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
      </ng-container>
      <ng-template #showOnlyValue><span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span></ng-template>
    </ng-container>
    <div class="more-menu-dropdown">
      <button *ngIf="moremenuIconShow && ((details?.id!== '' && details?.status!== '')) && !isFirstGroupin" class="ellipses-icon"
        (click)="moremwnu()"></button>
        <div *ngIf="isOpen" class="dropdown-content" [ngClass]="{'place-top': rowIndex > 8, 'displayHover' : isOpen }" (mouseleave)="moremwnu()">
          <a *ngIf="!isDetailGrid" class="dropdown-item edit-nomination" (click)="editNomination(pipelineParentKey, pipelineCurrentDesk)">edit
        nomination</a>
        <a *ngIf="!isDetailGrid" class="dropdown-item create-nom-with-ref"
          (click)="createNomWithRef(pipelineParentKey, pipelineCurrentDesk)">create nom. with
          reference</a>
        <a *ngIf="!isDetailGrid" class="dropdown-item contract-balancinf"
          (click)="contractBalancing()">contract
          balancing</a>
        <a class="dropdown-item publish" (click)="publish(pipelineParentKey, pipelineCurrentDesk)">publish</a>
        <a class="dropdown-item delete"
          (click)="delete(pipelineParentKey, pipelineCurrentDesk)">delete</a>
        <!-- <a *ngIf="!isDetailGrid" class="dropdown-item see-details" (click)="seeDetails()">see details</a> -->
      </div>
    </div>
  
  
  </div>