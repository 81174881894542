import { Injectable } from '@angular/core';
import { MdmService } from './mdm.service';
import { AlertsService } from './alerts.service';
import { AlertRuleResponse } from '../shared/interface/settings.interface';
import { CalAngularService } from '@cvx/cal-angular';
import { BehaviorSubject } from 'rxjs';
import { RealTimeAlertService } from 'src/app/services/real-time-alert.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsNotificationService {

  userAlerts: AlertRuleResponse[] = [];
  userAlertsSubscribeEmitter = new BehaviorSubject<AlertRuleResponse[]>(this.userAlerts);

  showBell = new BehaviorSubject<boolean>(false);


  previousCount: number = 0;
  currentCount: number = 0;

  constructor(private mdmService: MdmService, private alertService: AlertsService, private authService: CalAngularService, private realTimeAlertService : RealTimeAlertService) { 
    this.getAlertData();
    this.getRealTimeAlerts();
  }

  getAlertData(){

    this.alertService.getWorkspaceAlert(this.generateAlertPayload()).subscribe({
      next: (response: any) => {

        this.userAlerts = this.filterAlertsForUser(response.result);

      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.userAlertsSubscribeEmitter.next(this.userAlerts);
      },
    });
  }


  conductChangeDetection(){

    if(this.previousCount < this.currentCount){
      this.showBell.next(true);
    }

  }

  generateAlertPayload(): any{

    let returnDate: Date = new Date();
    returnDate.setDate(returnDate.getDate() - 7);
    let returnString = (returnDate.getMonth() + 1) + "/" + returnDate.getDate() + "/" + returnDate.getFullYear(); 

    let returnObject = {
      user: this.authService.cvxClaimsPrincipal.email,
      date: returnString,
    }

    return returnObject;
  }

  filterAlertsForUser(alertList: AlertRuleResponse[]): AlertRuleResponse[]{

    let returnList: AlertRuleResponse[] = [];
    alertList.forEach((currentAlert: AlertRuleResponse) => {

      if(this.alertDoesNotHaveUndefinedOrNull(currentAlert) && currentAlert.user.toLowerCase() === this.authService.cvxClaimsPrincipal.email.toLowerCase()){
        returnList.push(currentAlert);
      }
    });
    return returnList;
  }

  alertDoesNotHaveUndefinedOrNull(alert: AlertRuleResponse): boolean{

    return (alert.user != null && alert.isActive != null
      && alert.createdDate != null
      && alert.material != null
      && alert.location != null
      && alert.message != null);
  }
  

  removeAlerts(alertList: AlertRuleResponse[]){

    let alertsToRemove = new Set(alertList.map(_ => _.id));
    this.userAlerts = this.userAlerts.filter(_ => !alertsToRemove.has(_.id));
    this.userAlertsSubscribeEmitter.next(this.userAlerts);
  }

  addAlerts(alertList: AlertRuleResponse[]){

    alertList.forEach(_ => this.userAlerts.push(_));
    this.userAlertsSubscribeEmitter.next(this.userAlerts);
  }


  getRealTimeAlerts(){
    this.realTimeAlertService.connect();
    this.realTimeAlertService.messages.subscribe((rtAlerts : any[])  => {
      try {
          let user = this.authService.cvxClaimsPrincipal.email;
          let rtAlertData = rtAlerts.filter(x => x.user.toLowerCase() === user.toLowerCase());
          rtAlertData.forEach((rtAlert) => { 
            let idx = this.userAlerts.findIndex(x => x.ruleId == rtAlert.ruleId);
            if(idx != -1){
              this.userAlerts.splice(idx, 1, rtAlert);
            }else{
              this.userAlerts.push(rtAlert);
            } 
        });
        console.log("Recieved real time message");
        this.userAlertsSubscribeEmitter.next(this.userAlerts);  
      } catch (error) {
          console.log(error);
      }
                
    });
  }


}
