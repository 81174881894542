export const TERPEL_COUNTER_PART = "TERPEL";
export const DELTA_COUNTER_PART = "DELTA";
export const REFPAN_COUNTER_PART = "REFPAN";
export const CPFL_COUNTER_PART = "CPFL";
export const CHEVRON_COUNTER_PART = "CHEVRON";
export const PSAT_COUNTER_PART = "PSAT";
export const MARINE_TRANSFER = "Marine";
export const TANK_TRANSFER = "TankTransfer";
export const VESSEL = "Vessel";
export const PIPELINE = "Pipeline";
export const INVENTORY_DETAIL_TYPE = { utilization: "inventory" };
export const HEEL_DETAIL_TYPE = "heel";
export const RESERVE_DETAIL_TYPE = "reserve";
export const SAFETYSTOCK_DETAIL_TYPE = "safety";

export const ITK = "IT K";
export const ITG = "IT G";
export const OK = "O K";
export const OG = "O G";
export const DK = "D K";
export const DG = "D G";
export const OX = "O X";
export const DX = "D X";
export const ITK_G = "ITK + ITG"

export const MARINE_NOMINATIONS = ["O", "D"];
export const VESSEL_NOMINATIONS = [OK, OG, DK, DG, OX, DX];
export const PIPELINE_NOMINATIONS = [ITK, ITG, OK, OG, DK, DG, OX, DX];

export const HSFO = "HSFO";
export const MGO = "MGO";
export const PROPANE = "Propane";
export const BUTANE = "Butane";
export const AVGAS = "Avgas";
export const ASPHALTAC30 = "Asphalt AC30";
export const ASPHALTRC250 = "Asphalt RC250";
export const ASPHALTMC250 = "Asphalt MC250";
export const ULSD = "ULSD";
export const JETA = "Jet A"
export const _91RON = "91 Ron Export";
export const _95RON = "95 Ron Export";

export const CANCEL = "cancel";
export const CONFIRM = "confirm";
export const DRAFT = "draft";
export const PUBLISHED = "published";
export const UNPUBLISHED = "unpublished";
export const INPROCESS = "InProcess";

export const TRUCK = { name: "Truck", value: "J1" };
export const RAIL = { name: "Rail", value: "R_" };
export const BARGE = { name: "Barge", value: "S" };
export const TANKTRANSFER = { name: "in-tank", value: "PL" };
export const PIPELINEETHANOL = { name: "Pipeline", value: "PL" };

export const TRANSPORT_SYSYTEM_FOR_TRUCK = "truck-us";
export const TRANSPORT_SYSYTEM_FOR_RAIL = "rail-us";
export const TRANSPORT_SYSYTEM_FOR_TANK_TRANSFER = "itw";

export const NOMINATION_TYPE_DESTINATION_PURCHASE = {
    'nomination_type': 'destination purchase',
    'tsw_nomination_type': 'DK'
}

export const NOMINATION_TYPE_ORIGIN_TRANSFER_DESTINATION_TRANSFER = {
    'nomination_type': 'origin transfer + destination transfer',
    'tsw_nomination_type': 'OX + DX'
}

export const NOMINATION_TYPE_ORIGIN_PURCHASE_DESTINATION_TRANSFER = {
    'nomination_type': 'origin purchase + destination transfer',
    'tsw_nomination_type': 'OK + DX'
}

export const SUPPLIER_DIRECT_DELIVERY = 'supplier direct delivered';
export const CARRIER_MANAGED_INVENTORY = 'carrier managed inventory';
export const FOB = 'fob';
export const SALE = 'sale';

export const BBL_UOM = 'BBL';
export const GAL_UOM = 'GAL';

export const cargoMots = [
    {key:'barge',value:"barge",identifier:"B"},
    {key:'vessel',value:"vessel",identifier:"S"},
]


export const cargoNomMots = [
    {key:'barge',value:"barge",},
    {key:'vessel',value:"vessel"},
    {key:'pipeline',value:"pipeline"},
    {key:'inTank',value:"in-tank"},
]



export const MONTHS = [{ key: '01', value: 'january' }, { key: '02', value: 'february' }, { key: '03', value: 'march' }, { key: '04', value: 'april' }, { key: '05', value: 'may' }, { key: '06', value: 'june' }, { key: '07', value: 'july' }, { key: '08', value: 'august' }, { key: '09', value: 'september' }, { key: '10', value: 'october' }, { key: '11', value: 'november' }, { key: '12', value: 'december' }];

export const modeOfTransportDesc = [
    "Truck",
    "Rail",
    "Barge",
    "In-Tank",
    "Pipeline"
];

export const nominationTypes = [
    {
        name: 'In Tank Transfer Purchase',
        scheduleType: 'IT',
        referenceDocumentIndicator: 'K'
    },
    {
        name: 'In Tank Transfer Sale',
        scheduleType: 'IT',
        referenceDocumentIndicator: 'G'
    },
    {
        name: 'Origin Transfer',
        scheduleType: 'O',
        referenceDocumentIndicator: 'X'
    },
    {
        name: 'Destination Transfer',
        scheduleType: 'D',
        referenceDocumentIndicator: 'X'
    },
    {
        name: 'Destination Purchase',
        scheduleType: 'D',
        referenceDocumentIndicator: 'K'
    },
    {
        name: 'Origin Purchase',
        scheduleType: 'O',
        referenceDocumentIndicator: 'K'
    },
    {
        name: 'Origin Sale',
        scheduleType: 'O',
        referenceDocumentIndicator: 'G'
    },
    {
        name: 'Destination Sale',
        scheduleType: 'D',
        referenceDocumentIndicator: 'G'
    },
];

export const nominationType = [
    {
      name: 'Origin Transfer',
      scheduleType: 'O',
      referenceDocumentIndicator: 'X'
    },
    {
      name: 'Destination Transfer',
      scheduleType: 'D',
      referenceDocumentIndicator: 'X'
    },
    {
      name: 'Destination Purchase',
      scheduleType: 'D',
      referenceDocumentIndicator: 'K'
    },
    {
      name: 'Origin Purchase',
      scheduleType: 'O',
      referenceDocumentIndicator: 'K'
    },
    {
      name: 'Origin Sale',
      scheduleType: 'O',
      referenceDocumentIndicator: 'G'
    },
    {
      name: 'Destination Sale',
      scheduleType: 'D',
      referenceDocumentIndicator: 'G'
    }
  ];

  export const inTankNominationType = [
    {
        name: "In Tank Transfer Purchase",
        scheduleType: "IT",
        referenceDocumentIndicator: "K"
    },
    {
        name: "In Tank Transfer Sale",
        scheduleType: "IT",
        referenceDocumentIndicator: "G"
    }
  ]

  export const motHeaderItem = [
    {
        modeOfTransportDesc: 'Truck',
        modeOfTransport: 'J1',
        transportSystem: [
            { name: 'truck-us', value: 'TRUCK-US' }
        ],
        tdVehIdentifier: null,
        bargeCarrierName: [],
        nominationType:[]
    },
    {
        modeOfTransportDesc: 'Rail',
        modeOfTransport: 'R_',
        transportSystem: [
            { name: 'rail-us', value: 'RAIL-US' }
        ],
        tdVehIdentifier: null,
        bargeCarrierName: [],
        nominationType:[]
    },
    {
        modeOfTransportDesc: 'Barge',
        modeOfTransport: 'S',
        transportSystem: [],
        tdVehIdentifier: 'B',
        bargeCarrierName: [],
        nominationType:[]
    },
    {
        modeOfTransportDesc: 'In-Tank',
        modeOfTransport: 'PL',
        transportSystem: [],
        tdVehIdentifier: null,
        bargeCarrierName: [],
        nominationType:[]
    },
    {
        modeOfTransportDesc: 'Pipeline',
        modeOfTransport: 'PL',
        transportSystem: [],
        tdVehIdentifier: null,
        bargeCarrierName: [],
        nominationType:[]
    }
];

export const ethanolUSWCBargeTransportSys = [
    { name: 'Northwest Marine - Richmond', value: 'NWHS' },
    { name: 'California North Marine', value: 'CA-N' },
    { name: 'California South Marine', value: 'CA-S' },
    { name: 'Southwest Marine - El Segundo', value: 'SWHS' },
    { name: 'Oregon Marine', value: 'OR' }
]

export const ethanolUSECBargeTransportSys = [
    { name: 'Mississippi Marine', value: 'MS' },
    { name: 'Florida Marine', value: 'FL' },
    { name: 'Louisiana Marine', value: 'LA' },
    { name: 'Texas Marine', value: 'TX' },
    { name: 'New Jersey marine', value: 'NJ' },
    { name: 'Alabama Marine', value: 'AL' }
]

export const ethanolUSECPipelineTransportSys = [
    { name: 'cf', value: 'CF' },
    { name: 'cp', value: 'CP' },
    { name: 'evpl1', value: 'EVPL1' },
    { name: 'expl', value: 'EXPL' },
    { name: 'fl', value: 'FL' },
    { name: 'la', value: 'LA' },
    { name: 'mgtx', value: 'MGTX' },
    { name: 'ms', value: 'MS' },
    { name: 'nj', value: 'NJ' },
    { name: 'poe', value: 'POE' },
    { name: 'pp', value: 'PP' },
    { name: 'tap', value: 'TAP' },
    { name: 'tepl', value: 'TEPL' },
    { name: 'terml', value: 'TERML' },
    { name: 'wmspl', value: 'WMSPL' },
    { name: 'tx', value: 'TX' }
];

export const ethanolUSWCPipelineTransportSys = [{ name: 'sln', value: 'SLN' }];

export const ethanolItwTransportSys = [{ name: 'itw', value: 'ITW' }];

export const ethanolIteTransportSys = [{ name: 'ite', value: 'ITE' }];

  export const TimeFrameOption = [
    "Truck-Daily",
    "Truck-Weekly",
    "Truck-Monthly"
]
