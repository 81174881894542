import { ToastService } from 'src/app/services/toast.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { FormControl, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Utilities } from '../utilities/utilities';
import { NominationService } from 'src/app/services/nomination.service';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'date-range-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule
  ],
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  dateRangegroup: any
  @Input() dateRangeData!: { fromDate: any, toDate: any }
  @Output() updateDateRangeData = new EventEmitter()
  @Input() restrictStartDate: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private utilities: Utilities,
    private toasterService: ToastService,
    private nsServe: NominationService
  ) {
  }

  ngOnInit(): void {
    const [fromDatewithTZ, toDatewithTZ] = this.utilities.convertDatesToCurrentTZ(this.dateRangeData.fromDate, this.dateRangeData.toDate);
    this.dateRangegroup = new FormGroup({
      start: new FormControl(fromDatewithTZ, [Validators.required]),
      end: new FormControl(toDatewithTZ, [Validators.required]),
    })
    this.nsServe.setFilterDateRange$.subscribe(res => {
      if(Object.keys(res).length){
        const [fromDatewithTZ, toDatewithTZ] = this.utilities.convertDatesToCurrentTZ(res.fromDate, res.toDate);
        this.dateRangegroup.setValue({ start: fromDatewithTZ, end: toDatewithTZ });
      }
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateRangeData']?.currentValue && this.dateRangegroup) {
      const [fromDatewithTZ, toDatewithTZ] = this.utilities.convertDatesToCurrentTZ(changes['dateRangeData'].currentValue.fromDate, changes['dateRangeData'].currentValue.toDate);

      this.dateRangegroup.setValue({ start: fromDatewithTZ, end: toDatewithTZ });
    }
  }


  dateRangePickerFunction() {
    if (this.dateRangegroup?.status?.toLowerCase() !== 'invalid')
      this.updateDateRangeData.emit(this.dateRangegroup)
  }

  currentDateFilter = (d: Date | null): boolean => {
    if (new Date(d || new Date()).getTime() > new Date(new Date()).getTime()) {
      return false;
    }
    return true;
  };

  noFilter = (d: Date | null): boolean => {
    return true;
  }

  onStartdateRangePickerFunction(event: any) {
    if (this.restrictStartDate && new Date(event.value || new Date()).getTime() > new Date(new Date()).getTime()) {
      this.dateRangegroup.controls.start.patchValue(new Date(new Date()));
      this.toasterService.setToastNotification({ show: true, type: "info", msg: 'Start date should not be greater than current date.' });
    }
  }

}
