import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/admin-desks.reducer';
import * as Admin from '../reducer/admin-desks.reducer';

export const selectLocationsData = createFeatureSelector<State>('adminDesks');
export const selectDesksData = createFeatureSelector<State>('adminDesks');
export const selectDeskLocationsData = createFeatureSelector<State>('adminDesks');

export const getLocationsData = createSelector(
  selectLocationsData,
  Admin.getLocationsData
);

export const getDesksData = createSelector(
  selectDesksData,
  Admin.getDesksData
);

export const getDesksFilter = createSelector(
  selectDesksData,
  Admin.getDesksFilter
);

export const getDeskLocationsData = createSelector(
  selectDeskLocationsData,
  Admin.getDeskLocationsData
);