import { ChangeDetectorRef, Component, OnInit, Inject, HostListener } from '@angular/core';
import { PopupWindowService } from '../../../../services/popup-window.service';
import { DateService } from '../../../../services/date.service';
import { activityChange, activityChangeLine, activityResponse, nominationBehavior } from 'src/app/shared/interface/activity-tracker';
import { ActivityTrackerService } from 'src/app/services/activity-tracker.service';

@Component({
  selector: 'app-activity-tracker',
  templateUrl: './activity-tracker.component.html',
  styleUrls: ['./activity-tracker.component.scss']
})
export class ActivityTrackerComponent implements OnInit {

  //Table top buttons to change selection of the dropdown 
  tooltipHeader: any[] = ["Volume Changes", "Date Changes", "MOT/Location Changes", "Material Changes"];
  
  //The options that are available to the user for the dropdown
  volumeDropdown: activitySelector[] = [{displayName: "Nomination Volume", apiValue: "scheduledqty"}, 
                                        {displayName: "Actual Volume", apiValue: "actualpostedquantity"},]

  dateDropdown: activitySelector[] = [{displayName: "Scheduled Date", apiValue: "scheduleddate"}, 
                                      {displayName: "Arrival time", apiValue: "scheduledtime"},]

  //TODO: FILL IN THE API VALUES FOR THIS DROPDWN
  motDropdown: activitySelector[] = [{displayName: "Destination", apiValue: "locationname"},
                                     {displayName: "MOT", apiValue: "modeoftransport"},
                                     {displayName: "Batch Volume", apiValue: "scheduledqty"},
                                     {displayName: "Batch Date", apiValue: "scheduleddate"},   
                                     {displayName: "Transport", apiValue: "transportsystem"},
                                     {displayName: "Transport Name", apiValue: "transportsystemname"},]

  materialDropdown: activitySelector[] = [{displayName: "Scheduled Material", apiValue: "scheduledmaterial"}, 
                                          {displayName: "Demand Material", apiValue: "demandmaterial"},
                                          {displayName: "UOM", apiValue: "scheduleduom"},]

  
  dropDownArray: activitySelector[][] = [this.volumeDropdown, this.dateDropdown, this.motDropdown, this.materialDropdown];
  
  //initial selection setup for selecting between activities to view
  selectedDropdown: activitySelector[] = this.volumeDropdown;
  selectedOption!: activitySelector;
  selectedApi!: string;
  selectedHeaderIndex: number = 0;
  successApi: boolean = false;

  //Data used for grid 
  nomActivityData!: activityResponse;
  rowData: any[] = [];

  isMultipleView = false;  
  selectedNomination!: nominationBehavior;

  showSpinner: boolean = false;

  //Initial nomination data 
  nominationDataSet: any; 
  public innerWidth: any;


  constructor(private dateService: DateService, private activityTrackerService: ActivityTrackerService, private popupService: PopupWindowService) {

    this.selectedDropdown = this.dropDownArray[0]; //we need to show a default open dropdown, so select the first 
    this.selectedOption = this.selectedDropdown[0]; //we need to set the option to be the 
    this.selectedApi = this.selectedOption.apiValue;
  }

  ngOnInit(): void {

    this.popupService.showNomAT$.subscribe(displayAT => {
      if(displayAT){
        this.selectedHeaderIndex = 0;
        this.rowData = [];
        this.formatData();

        this.nominationDataSet = this.popupService.dataToShow$.value;
        this.handleInjectionData(this.nominationDataSet);
        this.selectedDropdown = this.dropDownArray[0]; //we need to show a default open dropdown, so select the first 
        this.selectedOption = this.selectedDropdown[0]; //we need to set the option to be the 
        this.selectedApi = this.selectedOption.apiValue;
        this.getActivityTracking();
      }
    })
  }

  getActivityTracking(){
    this.showSpinner = true;
    this.activityTrackerService.getBulkActivityTracking(this.selectedNomination.nominationId)
    .subscribe({
      next: (response: any) => {
        this.nomActivityData = response;
      },
      error: (err: any) => {
        this.showSpinner = false;
        this.successApi = false;
        console.log(err);
      },
      complete: () => {
        this.showSpinner = false;
        this.successApi = true;
        this.updateRowData();
       }
    })
  }
  
  updateDropdown(headerName: string, headerIndex: number){

    if(headerIndex == this.selectedHeaderIndex){
      return;
    }

    this.selectedHeaderIndex = headerIndex;
    this.selectedDropdown = this.dropDownArray[headerIndex];
    this.selectedOption = this.selectedDropdown[0];
    this.selectedApi = this.selectedOption.apiValue;
    this.updateRowData();
  }

  updateRowData(): void{

    this.selectedApi = this.selectedOption.apiValue;
    let changedLines = this.nomActivityData.activities.find((_:activityChange) =>{ 
        return _.changeType.toLowerCase() == this.selectedOption.apiValue.toLowerCase()
      })!.changes.filter((change: activityChangeLine) =>{
        return change.id == this.selectedNomination.itemID; 
      })

    if(changedLines == undefined){
      this.rowData = [];
      return;
    }
    
    this.rowData = JSON.parse(JSON.stringify(changedLines));
    this.formatData();
  }

  formatData(): void{

    this.rowData.forEach(row => {

      if(row.changedAt.slice(-1) != 'Z') row.changedAt = row.changedAt + 'Z'; 
      row.changedAt = this.dateService.getStandardDateFromISOString(row.changedAt) + " - " 
                          + this.dateService.getLocalTimeFromISO(row.changedAt);

      //Certain values need specific formatting 
      if(this.selectedOption.apiValue.toLowerCase().includes("date")){
        row.newValue = this.dateService.getStandardDateFromISOString(row.newValue);
        row.oldValue = this.dateService.getStandardDateFromISOString(row.oldValue);
      }else if(this.selectedOption.apiValue.includes("time")){
        row.newValue = this.dateService.getTimeFromSixDigitFormat(row.newValue);
        row.oldValue = this.dateService.getTimeFromSixDigitFormat(row.oldValue);
      }
    });
  }

  updateViewActivityNomination(){
    this.getActivityTracking(); 
  }

  handleInjectionData(nominationData: nominationBehavior[]){

    //Set if we are in a multiple case or not, as changes UI we show user
    if(nominationData.length != 1){
      this.isMultipleView = true;
    }
    //initialize the selceted nomination to the first value in the list
    this.selectedNomination = nominationData[0];
    }
}

export interface activitySelector{
  displayName: string,
  apiValue: string,
}
