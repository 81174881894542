<div>
    <div id="Search" class="search-panel-content tabcontent row">
        <div>
            <div class="search-panel-title">Search</div>
        </div>
        <div class="col-md-12">
            <app-select-dropdown class="ml-2" [items]="searchList" [defaultValue]="searchList[0]" (afterChange)="onValueChange($event)">
            </app-select-dropdown>
        </div>
        <div class="col-md-12">
            <input class="ml-2 search form-control" type="text" placeholder="Search.." name="search" [(ngModel)]="searchTextValue" (keydown.enter)="applySearchFilterforSearch($event)">
        </div>
    </div>
    <div class="search-panel-footer d-flex justify-content-between p-3">
        <span class="clear-filter" (click)="searchClearFilter($event)">clear</span>
        <span class="clear-filter" (click)="applySearchFilterforSearch($event)">Search</span>
    </div>
</div>
