<div class="form-row pb-1 pr-2">
    <span class="label-3">if</span> &nbsp;
    <span class="label-4">difference</span> &nbsp;
    <span class="label-3">between</span> &nbsp;
    <span class="label-4">estimated inventory</span> &nbsp;
    <span class="label-3">and</span> &nbsp;
    <span class="label-4">actual inventory </span>
</div>
<div class="form-row pb-1 pr-2">
  <span class="label-3">exceeds</span>
  <div class="form-group col-md-4">
      <select class="form-control" [(ngModel)]="selectedPercent" (change)="shareReconciliationDetails()">
          <option value="" disabled selected>select percent</option>
          <option *ngFor="let percent of percentDropdown">
              {{percent}}</option>
      </select>
  </div>
  <span class="label-3">percent</span>
</div>