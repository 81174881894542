import { Action, createReducer, on } from "@ngrx/store";
import { CreateDeskTsMapping, DeskTransportFilters, TransportData, TransportFunctionalGroup } from "../../../shared/interface/mdm-admin.interface";
import { createDeskTsMappingSuccess, deleteDeskTsMappingSuccess, deskTsDeleted, getTransportSuccess, loadDeskTsSuccess, resetTransportScreen, recievedNewDeskTsId, setDefaultTransportsFilter, transportsFilterUpdated, updateTransportsFilter, updateTransportsTable } from "../action/admin-transport.action";

export interface State {
  deskTs: TransportFunctionalGroup[];
  initializeDeskTs: boolean;
  transport: TransportData[];
  initializeTransport: boolean;
  createDeskTsMapping: CreateDeskTsMapping;
  newDeskTsId: number;
  setNewDeskTsId: boolean;
  deleteDeskTsMapping: boolean;
  transportFilter: DeskTransportFilters;
  updateTransportFilter: boolean;
  initializeTransportFilter: boolean;
  updateTransportsTable: boolean;
}

export const initialState: State = {
  deskTs: [],
  initializeDeskTs: true,
  transport: [],
  initializeTransport: false,
  createDeskTsMapping: {
    deskId: NaN,
    transportSystem: ''
  },
  initializeTransportFilter: false,
  transportFilter: {
    transportFunctionalGroup: [],
    region: [],
    transportSystem: [],
    modeOfTransport: [],
    transportSystemName: []
  },
  updateTransportFilter: false,
  updateTransportsTable: false,
  newDeskTsId: NaN,
  setNewDeskTsId: false,
  deleteDeskTsMapping: false
};

const transportReducer = createReducer(
  initialState,
  on(loadDeskTsSuccess, (state, { deskTs }) => ({
    ...state,
    deskTs: deskTs,
    initializeDeskTs: false,
    initializeTransport: true
  })),
  on(getTransportSuccess, (state, { transport }) => ({
    ...state,
    transport: transport,
    initializeTransport: false,
    initializeTransportFilter: true
  })),
  on(createDeskTsMappingSuccess, (state, { newDeskTsId }) => ({
    ...state,
    newDeskTsId: newDeskTsId,
    setNewDeskTsId: true
  })),
  on(deleteDeskTsMappingSuccess, (state) => ({
    ...state,
    deleteDeskTsMapping: true
  })),
  on(deskTsDeleted, (state) => ({
    ...state,
    deleteDeskTsMapping: false
  })),
  on(recievedNewDeskTsId, (state) => ({
    ...state,
    setNewDeskTsId: false
  })),
  on(setDefaultTransportsFilter, (state, { transportFilter, deskTs }) => ({
    ...state,
    transportFilter: transportFilter,
    deskTs: deskTs,
    updateTransportFilter: true,
    initializeTransportFilter: false
  })),
  on(updateTransportsFilter, (state, { transportFilter, deskTs }) => ({
    ...state,
    transportFilter: transportFilter,
    deskTs: deskTs,
    updateTransportFilter: true
  })),
  on(transportsFilterUpdated, (state, { updateTransportFilter }) => ({
    ...state,
    updateTransportFilter: updateTransportFilter
  })),
  on(updateTransportsTable, (state, { deskTs, updateTransportsTable, initializeTransportFilter }) => ({
    ...state,
    updateTransportsTable: updateTransportsTable,
    deskTs: deskTs,
    initializeTransportFilter: initializeTransportFilter,
  })),
  on(resetTransportScreen, (state) => ({
    ...state,
    initializeDeskTs: true,
    initializeTransport: true,
    initializeTransportFilter: false,
    updateTransportFilter: false,
    updateTransportsTable: false,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return transportReducer(state, action);
}

export const getDeskTs = (state: State) => ({
  deskTs: state.deskTs,
  initializeDeskTs: state.initializeDeskTs,
  transport: state.transport,
  initializeTransport: state.initializeTransport,
  transportFilter: state.transportFilter,
  initializeTransportFilter: state.initializeTransportFilter,
  updateTransportFilter: state.updateTransportFilter,
  updateTransportsTable: state.updateTransportsTable,
  newDeskTsId: state.newDeskTsId,
  setNewDeskTsId: state.setNewDeskTsId,
  createDeskTsMapping: state.createDeskTsMapping,
  deleteDeskTsMapping: state.deleteDeskTsMapping
});
