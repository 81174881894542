import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, ICellRendererParams } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { setReceivedResponseBoolean, triggerTRSave, updatedOfftakersFalse } from '../../store/action/app.action';
import { AppState } from '../../store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectUDMScreen, selectedPipelineCompactView } from '../../store/selector/app.selector';
import { Utilities } from '../../utilities/utilities';
import { LoaderService } from 'src/app/services/loader.service';
import { UDMDesk, deskName } from '../../constants/terminal-replenishment.constant';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { PopupWindowService } from 'src/app/services/popup-window.service';
import { nominationBehavior } from '../../interface/activity-tracker';
import { MdmService } from 'src/app/services/mdm.service';
import { infoPanelData, InformationPanelService, panelType } from 'src/app/services/information-panel.service';
import { tabEnum } from 'src/app/core/components/information-panel/info-content/info-content.component';

export interface MyCellRendererParams {
  rowDataRecord: any;
  parentRecord: any;
  totalRecords: any;
  isDetailGrid: any;
  desk?: string;
}
@Component({
  selector: 'more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.scss']
})
export class MoreMenuComponent implements ICellRendererAngularComp {
  gridColumnApi2!: ColumnApi;
  getVesselData: any;
  ethanolMoreMenuEditable: any = false;
  constructor(
    private appStore: Store<AppState>,
    private utilities: Utilities,
    private router: Router,
    private toasterService: ToastService,
    private nominationService: NominationService,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private store: Store,
    private toaterService: ToastService,
    private myMonitoringService: MyMonitoringService,
    private popupWindowService: PopupWindowService,
    private data: NominationService,
    private mdmService : MdmService,
    private informationPanelService: InformationPanelService,
  ) { }
  public value: any;
  public params: any;
  public parentRecord: any;
  public totalRecords: any = 35;
  public isDetailGrid: any;
  public rowIndex: any;
  public details: any;
  public customer: any;
  public counterPart: any;
  public isEditable = true;
  public isOpen = false;
  public moremenuIconShow = true;
  public nominationTab: any;
  public lineItem = false;
  public desk: any;
  public rowDataRecord: any;
  PipelineUswcPNWDesk:string = UDMDesk.PipelineUswcPNWDesk; 
  PipelineUsecFloridaDesk:string = UDMDesk.PipelineUsecFloridaDesk;
  PipelineUsecRichmondDesk:string = UDMDesk.PipelineUsecRichmondDesk;
  PipelineUsecpascagoulaDesk:string = UDMDesk.PipelineUsecPascagoulaDesk;
  public disablePublish: any;
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName: any = '';
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectedPipelineCompactView$ = this.store.pipe(select(selectedPipelineCompactView))
  compactView: boolean = false;
  screen$ = this.store.pipe(select(selectUDMScreen));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  public selectedDeskParameter: any;
  isCCAPanamaView: any;


  agInit(params: ICellRendererParams<any, any, any> & MyCellRendererParams): void {
    this.gridColumnApi2 = params.columnApi;
    // this.disablePublish = params?.data?.modeOfTransportDesc?.toLowerCase() === 'truck';
    this.isDetailGrid = params.isDetailGrid;
    this.lineItem = params.data.lineItem;
    this.nominationTab = this.router.url === '/nominations';
    this.customer = params?.colDef?.field?.split('.')[0];
    this.counterPart = this.customer.split('_')[1];
    this.rowDataRecord = params?.node?.key;
    this.selectedPipelineCompactView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.pipelineCompactView) {
            this.compactView = true
          }
          else {
            this.compactView = false
          }
        },
        error: (err: any) => {
        },
        complete: () => { },
      });
    this.getUserandDeskDetails();
    if (this.desk?.toLowerCase() === 'ethanol + uswc' || this.desk?.toLowerCase() === 'ethanol + usec' || this.desk?.includes("clean products")) {
      this.parentRecord = params.parentRecord;
      this.rowIndex = params.rowIndex;
      this.details = params.data;
      this.getVesselData = params
    }
    
    let transformedDate;
    let rowDate = params?.data?.date;
    if(params.data['modeOfTransport']?.toLowerCase() === 'pipeline'){
      rowDate = params?.data?.scheduledDate;
    }
    if (rowDate && params.data['modeOfTransport']?.toLowerCase() !== 'pipeline') {
      transformedDate = this.datePipe.transform(
        new Date(rowDate),
        'MM/dd/yyyy'
      );
    
    const currentRowDate = transformedDate?.split('/');
    let currentRowFormatedDate;
    if (currentRowDate) {
      currentRowFormatedDate = new Date(
        `${currentRowDate[0]}/${currentRowDate[1]}/${currentRowDate[2]}`
      );
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      currentRowFormatedDate.setHours(0, 0, 0, 0);
      if (currentRowFormatedDate < today || (this.customer.includes('nomination') && (this.counterPart === 'DELTA' || this.counterPart === 'TERPEL'))
        || this.counterPart === 'CPFL' || this.counterPart === 'TOTAL' || (this.customer.includes('heels') && (this.counterPart?.toLowerCase() === 'psat'))) {
        this.moremenuIconShow = false;
      }
      else if (currentRowFormatedDate > today) {
        this.moremenuIconShow = true;
      }
    }
  }
  this.initalizeValues(params)
  }

  getRowIndex(id : string) {
    return this.params?.node?.rowIndex;
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk;
          this.selectedDeskParameter = response.selectedDeskParam;
          this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.desk);
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.desk.split('+')[this.desk.split('+').length -1];
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }
  initalizeValues(params: any) {
    this.ethanolMoreMenuEditable = ['1','4',null].includes(params.data.nominationItemStatus) && !params?.data.lineItem;
    let qty;
    this.isEditable = params.data[`isEditable_${this.counterPart}`] || (params.data['sys_status']?.toLowerCase() === 'unpublished'|| params.data['sys_status']?.toLowerCase() === 'failed' || params.data['sys_status']?.toLowerCase() === 'published' || params.data['sys_status']?.toLowerCase() === 'multiple');
    const deltaorTerple_Utilization = this.customer.includes('utilization');
    if (deltaorTerple_Utilization) {
      qty = params.data[`utilization_${this.counterPart}`]
    }
    else if (this.customer.includes('nomination')) {
      qty = params.data[`nomination_${this.counterPart}`]
    }
    else if (this.customer.includes('heels')) {
      qty = params.data[`heels_${this.counterPart}`];
    }
    else if (this.customer.includes('reserve')) {
      qty = params.data[`reserve_${this.counterPart}`];
    }
    else if (this.customer.includes('safety')) {
      qty = params.data[`safety_${this.counterPart}`]
    }
    else {
      qty = ""
    }
    this.value = this.utilities.formatNumber(qty);
    this.assignDetails(params);
    this.params = params;
  }

  assignDetails(params: any) {
    if ((this.customer.includes('nomination') || this.customer.includes('utilization'))) {
      this.details = params.data[`nomination_${this.counterPart}_nominationDetails`];
    }
    else if (this.customer.includes('heels')) {
      this.details = params.data[`nomination_${this.counterPart}_heelsDetails`]
    }
    else if (this.customer.includes('reserve')) {
      this.details = params.data[`nomination_${this.counterPart}_reserveDetails`]
    }
    else if (this.customer.includes('safety')) {
      this.details = params.data[`nomination_${this.counterPart}_safetyDetails`]
    }
  }




  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  moremwnu() {
    this.isOpen = !this.isOpen;
  }

 async pipelineDeletePayload(scenario: any, createdBy: any) {
    const nominationDetails: any = await this.utilities.getPipelineNomPayload(this.params,this.userEmail,this.desk,true);
    let requestBodyArr:any=[];
    // Checking if we delete from parent row in nomination module
    if(!this.parentRecord){
      const deletedNomStatus = nominationDetails?.sys_status;
      let deletedItemNomKey = nominationDetails?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;

      requestBodyArr=[];
      nominationDetails.headerToItemNav.forEach((elem: any) => {
        let tempRequestBody:any = {
          id: elem[0]?.id,
          deskFilter: "pipelinedesk",
          sys_status:  deletedItemNomKey?"InProcess":deletedNomStatus,
          deletionIndicator:"X"
        }
        tempRequestBody.headerToItemNav = [];
        elem.forEach((ele: any) => {
          const deletedUdmItemStatus = ele?.sys_status;
          let deletedItemNomKey = ele?.nominationKey;
          deletedItemNomKey = deletedItemNomKey?.length;
          const obj = {
            itemId: ele.itemId,
            udmItemStatus: deletedItemNomKey?"InProcess":deletedUdmItemStatus,
            deletionIndicator: "X",
            textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
          }
          if((this.params.data.id === ele.id && requestBodyArr.length === 0)||(requestBodyArr.every((e:any)=>e.id!==ele.id) && requestBodyArr.length)){
            tempRequestBody.headerToItemNav.push(obj);
          }
        })
        requestBodyArr.push(tempRequestBody)
       
      })
    }else{ // If we are deleting a line item individually in nomination module
      requestBodyArr=[];
      const lineItemDetail = this.parentRecord.headerToItemNav.find((ele:any)=>
      ele.id === this.params.data.id && ele.itemId === this.params.data.itemId
      )
      const deletedUdmItemStatus = lineItemDetail?.udmItemStatus;
      let deletedItemNomKey = lineItemDetail?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;

      let tempRequestBody:any = {
        id: this.details?.id,
        deskFilter: "pipelinedesk",
        sys_status: deletedItemNomKey?"InProcess":this.parentRecord?.sys_status,
      }
      tempRequestBody.headerToItemNav= [{
        itemId: lineItemDetail.itemId,
        deletionIndicator: 'X',
        udmItemStatus: deletedItemNomKey?"InProcess":deletedUdmItemStatus,
        textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
      }]
      requestBodyArr.push(tempRequestBody);
    }
    return requestBodyArr;
  }

  ethanolDeletePayload(scenario: any, createdBy: any) {
    const textLine = `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`;
    let requestBody: any = {
      id: this.parentRecord ? this.parentRecord?.id : this.details?.id,
      deskFilter: this.selectedDeskParameter,
      modeOfTransportDesc: this.details?.modeOfTransportDesc,
      sys_status: this.parentRecord ? this.parentRecord?.sys_status : this.details?.sys_status,
      headerToItemNav: []
    }
      this.details.headerToItemNav?.map((val:any, i:number)=>{
        requestBody.headerToItemNav.push({
          itemId: val.itemId,
          deletionIndicator: 'X',
          udmItemStatus: val.udmItemStatus,
          textLine: textLine,
        })
        return val;
    })
    if (!this.details?.headerToItemNav?.length) {
      requestBody.headerToItemNav = [{
        itemId: this.details.itemId,
        deletionIndicator: 'X',
        udmItemStatus: this.details?.udmItemStatus,
        textLine: textLine,
      }];

    }

    return requestBody
  }

  getScenario() {
    return this.details.headerToItemNav?.length > 0 ? this.details.headerToItemNav[0].scenario : this.details?.scenario;
  }

  async updateRequestBody(requestBody:any,scenario:any,createdBy:any){
    if (this.desk?.toLowerCase() === 'ethanol + uswc' || this.desk?.toLowerCase() === 'ethanol + usec') {
      requestBody = this.ethanolDeletePayload(scenario, createdBy)
    } else if (this.desk?.includes('clean products') && !this.isCCAPanamaView) {
      requestBody = await this.pipelineDeletePayload(scenario, createdBy);
    }
    else {
      requestBody = {
        id: this.details?.id,
        sys_status: this.details?.status?.toLowerCase() === 'published' ? 'Unpublished' : this.details?.status,
        headerToItemNav: [{
          itemId: this.details.itemId,
          deletionIndicator: 'X',
          udmItemStatus: this.details?.status?.toLowerCase() === 'published' ? 'Unpublished' : this.details?.status,
          textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        }]
      }
    }
    return requestBody;
  }
 
  async delete(){
    let action = 'delete';
    this.data.popupAction("delete");
    if(this.router.url === '/nominations'){
      this.mdmService.invokeToggleMethod(true); 
      this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
        if(actionConfirmed){
          if(action !== ''){
            this.deleteAfterConfirm();
            this.mdmService.actionConfirm(false);
            this.mdmService.invokeToggleMethod(false); 
            action = '';
          }
         
        }
      });
    }
    else{
      this.mdmService.invokeReplenishmentToggleMethod(true); 
      this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
        if(actionConfirmed){
          if(action !== ''){
            this.deleteAfterConfirm();
            this.mdmService.actionConfirm(false);
            this.mdmService.invokeToggleMethod(false); 
            action = '';
          }
        }
      });
    }
    
  }
 
 async deleteAfterConfirm() {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.colDef?.colId??this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    const payload: any[] = [];
    let requestBody;
    let scenario = this.getScenario();
    let createdBy = this.details.headerToItemNav?.length > 0 ? this.details.headerToItemNav[0].createdBy : this.details?.createdBy;
    requestBody = await this.updateRequestBody(requestBody,scenario,createdBy);
    if (Array.isArray(requestBody) && requestBody?.length) {
      payload.push(...requestBody)
    }else{
      payload.push(requestBody)
    }
    this.nominationService.restrictUpdateFromTRApiCall$.next(true);
    this.nominationService.restrictUpdateNomApiCall$.next(true);
    this.screen$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.screen === "replenishmentplanning" && this.nominationService.restrictUpdateFromTRApiCall$.value) {
        this.nominationService.updateNominationFromTRScreen(payload).subscribe({
          next: (response) => {
            this.loaderService.setLoading(true);
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: "Nomination has been deleted",
            });
            this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true }));
            this.myMonitoringService.logEvent(`Delete nomination`, {category:"Replenishment Planning"})
          },
          error: (err) => {
            this.toasterService.setToastNotification({ show: true, type: 'error', msg: `Request coudn't get completed, try again in sometime.` });
          },
          complete: () => {
            const selectedTabFunction = {
              tab: this.router.url,
              function: 'onDeleteEthanol',
              type: '',
              values: '',
            };
            const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
            this.utilities.addUnsubscribableList(modalPopupAction);
          },
        });
      } else if (data.screen === "nominations" && this.nominationService.restrictUpdateNomApiCall$.value) {
        if(this.desk?.toLowerCase().includes("ethanol") ){
          this.utilities.getCreateUpdateMasterDataPayload(payload);
        }
        this.nominationService.updateNomination(payload).subscribe({
          next: (response) => {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: "Nomination has been deleted",
            });
            this.myMonitoringService.logEvent(`Delete nomination`, {category:"Nominations"})
          },
          error: (err) => {
            this.toasterService.setToastNotification({ show: true, type: 'error', msg: `Request coudn't get completed, try again in sometime.` });
          },
          complete: () => {
            const selectedTabFunction = {
              tab: this.router.url,
              function: 'onDeleteEthanol',
              type: '',
              values: '',
            };
            const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
            this.utilities.addUnsubscribableList(modalPopupAction);
          },
        });
      }
    })

  }

  goToNominations() {
    if (this.desk?.toLowerCase() === "ethanol + uswc" || this.desk?.toLowerCase() === 'ethanol + usec') {
      this.nominationService.restrictNomApiGetCall$.next(true);
    }
    // This set of code is for the transfer of desk
    let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.SelectedDeskParam , id: this.SelectedDeskId };
    if(!this.SelectedDesk && !this.SelectedDeskParam){
      deskOj = this.SelectedDeskName;
    }
    localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
    // this set of code is for the transfer of nomination 
    const fieldType = this.params?.colDef?.field;
    let stringarray = fieldType.split('_');
    if(stringarray[stringarray.length - 1] === "DELTA" || stringarray[stringarray.length - 1] === "TERPEL" ){
      let fieldDataName: string = `nomination_${stringarray[stringarray.length - 1]}_nominationDetails`;
      if(this.params.data[fieldDataName].id){
        localStorage.setItem('nomId', this.params.data[fieldDataName].id);
      }
      localStorage.setItem('nomNo', this.params.data[fieldDataName].nominationNo);
    }
    else if (deskOj.deskValue === "clean products + latam + panama desk"){
      let fieldDataName = `${fieldType}_nominationDetails`;
      if(this.params.data[fieldDataName].id){
        localStorage.setItem('nomId', this.params.data[fieldDataName].id);
      }
      localStorage.setItem('nomNo', this.params.data[fieldDataName].nominationNo);
    }
    else {
      let fieldDataName = `${fieldType}_nominationDetails`;
      localStorage.setItem('nomId', this.params.data[fieldDataName].id);
    }
    //this set of code is for the transfer of filter
    localStorage.setItem('gCCAF',JSON.stringify(this.data.ccaFilters));
    // this set of code onpens the nomination in a new tab 
    window.open("/nominations");
    if (this.desk?.toLowerCase() === "panama" || this.isCCAPanamaView) {
      this.nominationService.getTerminalNominationAFAPI$.next(true)
    }
  }

  seeInNominationPannel() {
    this.nominationService.sidePanel$.next(true);
    this.nominationService.sidePanelData$.next(this.params)
  }

  onCreateDraftNomination(ref?: any) {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.colDef?.colId??this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    let formatedValue = this.value.replaceAll(',', '');

    let nominationDetails: any = {
      'counterPart': this.counterPart,
      'product': this.params.data.productName,
      'destinationDate': this.params.data.date,
      'transportSystem': this.params.data[`transportSystem_${this.counterPart}`],
      'uDMNominationGrouping': this.params.data.uDMNominationGrouping,
      'status': ref === 'reference' ? undefined : this.details.status,
      'id': ref === 'reference' ? undefined : this.details.id,
    }

    if (this.counterPart === 'DELTA') {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      this.params.data[`nomination_${this.counterPart}_nominationDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty;
      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_safetyDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_safetyDetails`].safetyQty;
      nominationDetails['safetyDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_safetyDetails`]
      ];
    } else if (this.counterPart === 'TERPEL') {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      this.params.data[`nomination_${this.counterPart}_nominationDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty;
      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_reserveDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_reserveDetails`].reserveQty;
      nominationDetails['reserveDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_reserveDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_heelsDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_heelsDetails`].heelsQty;
      nominationDetails['heelsDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_heelsDetails`]
      ];
    } else {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      nominationDetails['scheduledQty'] = formatedValue;

      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'createDraftNomination',
      type: '',
      values: nominationDetails,
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);

  }

  async editNomination(data?:any) {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.colDef?.colId??this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    if(["296669990",'296660990'].includes(data.demandMaterial) && !data.lineItem && ['1','4',null].includes(data.nominationItemStatus) &&  ['truck','rail','barge','pipeline'].includes(data?.modeOfTransportDesc?.toLowerCase())){
      let data = JSON.parse(JSON.stringify(this.params.data));
      data.editNomination=true;
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'editNominationForEthanol',
        type:data,
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }
    else if(data?.status && this.isEditable && data?.modeOfTransport?.toLowerCase() !== 'pipeline'){
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'editNomination',
        type: this.params,
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    } else if (this.isEditable && data?.modeOfTransport?.toLowerCase() !== 'pipeline' && this.desk?.toLowerCase() === "ethanol + uswc" || this.desk?.toLowerCase() === 'ethanol + usec') {
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'ethanolCreateNominationWithReference',
        type: 'editNomination',
        values: this.details
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    } else{
      const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params,this.userEmail,this.desk,true);
      pipelineNomination["screenName"] = 'side-panel-tr';
      pipelineNomination["editNomination"] = true;
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'onCreatePipelineNominationFromTR',
        type: '',
        values: pipelineNomination
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
      this.nominationService.sidePanel$.next(false);
    }
  }

  async createNomWithRef() {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.colDef?.colId??this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);
    
    this.nominationService.isModalPopupOpen$.next(false);
    let selectedTabFunction: any
    if (this.desk === "panama" || this.isCCAPanamaView) {
      this.onCreateDraftNomination('reference');
    } else if (this.desk?.toLowerCase() === "ethanol + uswc" || this.desk?.toLowerCase() === 'ethanol + usec') {
      selectedTabFunction = {
        tab: this.router.url,
        function: 'ethanolCreateNominationWithReference',
        type: '',
        values: this.details
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }
    else if (this.desk?.includes('clean products')) {
      this.nominationService?.createNominationForPipelineActuation?.next(true);
      if(this.nominationService?.isNominationScreen !== "cargo"){
        const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params,this.userEmail,this.desk,true);
        selectedTabFunction = {
          tab: this.router.url,
          function: 'pipelineCreateNominationWithReference',
          type: '',
          values: pipelineNomination
        };
        const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(modalPopupAction);
      }
     
    }
    this.myMonitoringService.logEvent(`Create ${this.desk} nomination`, {category:"Nomination"})

  }
  contractBalancing() {
    console.log('contractBalancing clicked');
  }
  vesselScheduleUpdate() {
    let selectedTabFunction: any
    selectedTabFunction = {
      tab: this.router.url,
      function: 'pipelineVesselScheduleUpdate',
      type: '',
      values: this.getVesselData
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  updateNomApiCall(deskCondition: any, payload: any) {
    if ((deskCondition || this.desk?.includes('clean products')) && this.details?.demandMaterial && this.details?.scheduledMaterial) {
      /**Call update nominations api */
      if(this.desk?.includes('ethanol')){
        this.utilities.getCreateUpdateMasterDataPayload(payload);
      }
      this.nominationService.updateNomination(payload).subscribe({
        next: () => {
          this.toaterService.setToastNotification({
            show: true,
            type: 'success',
            msg: "Nomination is being sent to TSW"
          });
        },
        error: (err: any) => { },
        complete: () => {
          const selectedTabFunction = {
            tab: this.router.url,
            function: 'onPublishEthanol',
            type: '',
            values: '',
          };
          const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
          this.utilities.addUnsubscribableList(modalPopupAction);
        },
      });
    }
    else {
      const selectedTabFunction = {
        tab: this.router.url,
        function: this.desk?.includes('clean products') ? `publishPipelineDataFromMoreMenu` : `publishEthanolDataFromMoreMenu`,
        type: '',
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);

    }
  }


publishEthanol(deskCondition: any){
    if (deskCondition && !this.details?.demandMaterial && !this.details?.scheduledMaterial) {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `publishEthanolDataFromMoreMenu`,
        type: '',
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`Published ${this.desk} nomination`, {category:"Nomination"})
    }
  }

  publishPipeline(deskCondition: any){
    if ((this.desk?.includes('clean products')) && !this.details?.demandMaterial && !this.details?.scheduledMaterial) {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `publishPipelineDataFromMoreMenu`,
        type: '',
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`Published ${this.desk} nomination`, {category:"Nomination"})
    }
  }

  async publish(){
    const deskCondition = (this.desk?.toLowerCase() === "ethanol + uswc" || this.desk?.toLowerCase() === 'ethanol + usec' || this.desk?.includes('clean products'))
    
    this.publishEthanol(deskCondition);
    
    this.publishPipeline(deskCondition);


    const payload: any = [];
    const scenario = this.details.headerToItemNav?.length > 0 ? this.details.headerToItemNav[0].scenario : this.details?.scenario;
    const createdBy = this.details.headerToItemNav?.length > 0 ? this.details.headerToItemNav[0].createdBy : this.details?.createdBy;
    let textLine = `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
    

    let hToItemNav: any[] = [];
    if (!this.details?.udmNominationGrouping) {
      this.isSingleTransport(hToItemNav, textLine, deskCondition, payload,scenario)
    }
    else {
     this.groupTransportSystem(deskCondition,payload, textLine)
    }
  }
isSingleTransport(hToItemNav: any, textLine: any, deskCondition: any, payload: any, scenario:any){
  this.details?.headerToItemNav?.length > 0 ? this.details?.headerToItemNav?.forEach((el: any, i:any) => { hToItemNav.push({
    itemId: el.itemId,
    udmItemStatus: "InProcess",
    textLine: textLine
  })

}) :  hToItemNav.push(
    {
      itemId: this.details.itemId,
      udmItemStatus: "InProcess",
      textLine: textLine
    }
  );
  let id = this.parentRecord ? this.parentRecord?.id : this.details?.id
  let requestBody = {
    id: this.desk?.includes('clean products') || this.desk?.includes('pipeline') ? this.details?.id : id,
    isEthanolNominationType: this.desk?.includes('pipeline') || this.desk?.includes('clean products') ? undefined : true,
    deskFilter: this.desk?.includes('pipeline') ? "pipelinedesk" : this.deskFilter(),
    sys_status: 'InProcess',
    headerToItemNav: hToItemNav,
  }
  payload.push(requestBody);
  this.updateNomApiCall(deskCondition, payload);
}

  deskFilter() {
    if (this.desk?.includes('clean products')) {
      return "pipelinedesk";
    } else if (this.desk?.includes('ethanol')) {
      return this.selectedDeskParameter;
    } else {
      return undefined;
    }
  }
groupTransportSystem(deskCondition:any, payload:any, textLine: any){
  let obj: any = {};
  this.details?.headerToItemNav?.forEach((el: any, i: any) => {
    if (Object.keys(obj).length === 0 || !Object.keys(obj).includes(el.id)) {
      obj[el.id] = [el]
    }
    else {
      obj[el.id].push(el)
    }
  })
  Object.keys(obj).forEach((key: any) => {
    let hToItemNav: any = []

    obj[key].forEach((elem: any) => {
      hToItemNav.push({
        itemId: elem.itemId,
        udmItemStatus: "InProcess",
        textLine: textLine
      })
    })
    payload.push({
      id: key,
      deskFilter: this.deskFilter(),
      sys_status: 'InProcess',
      headerToItemNav: hToItemNav,
    })
  })


  this.updateNomApiCall(deskCondition, payload);
}

  async seeDetails() {
    const udmNomGroupingId =  this.params?.data?.udmNominationGrouping;
    localStorage.setItem('nomId', this.details.id);
    localStorage.setItem('udmNomGroupingId', udmNomGroupingId);
    this.router.navigate(['nominations/nominationDetails'])
  }

  disableVesselOption(details:any){
    if (((details?.modeOfTransport?.toLowerCase() === 'pipeline' || details?.modeOfTransport?.toLowerCase() === 'intank') && (details?.tdVehIdentifier?.toLowerCase() !== 's' || details?.tdVehIdentifier?.toLowerCase() !== 'b')) || details?.nominationItemStatus ===5) {
      return true
    }
    return  false
  }

  openActivityTracker(){

    let parentObject = this.params.node; 
    let nominationNo = this.details.nominationNo;
    let nominationKey = this.details.nominationKey;
    
    let nominationData :nominationBehavior[] = [];

    //two cases, one is the line item selection
    if(this.isDetailGrid){
      let nomInfo: nominationBehavior = {
        nominationId: this.details.id,
        itemID: this.details.itemId,
        nominationNo: this.details.nominationNo,
        nominationKey: this.details.nominationKey,
        nominationKeyItem: this.details.nominationKeyItem,
      }
      nominationData.push(nomInfo);
    
    }else{ //in the case of all line items, must handle all 
      parentObject.data.headerToItemNav.forEach((currentNom: any) => {          
            let nomInfo: nominationBehavior = {
              nominationId: currentNom.id,
              itemID: currentNom.itemId,
              nominationNo: currentNom.nominationNo,
              nominationKey: currentNom.nominationKey,
              nominationKeyItem: currentNom.nominationKeyItem,
            }
            nominationData.push(nomInfo);
      })
    }
    this.popupWindowService.setData(nominationData);
    this.popupWindowService.togglePopup(true);
    this.popupWindowService.toggleActivityTrackerNomComponent(true);  }


    openNominationComments(){

      this.informationPanelService.updateSelectedSelector(tabEnum.COM);
      let nomHeader = this.params.node.data.headerToItemNav[0];
      let valueToPass: infoPanelData = {
        name: nomHeader.nominationNo,
        date: nomHeader.scheduledDate,
        id: nomHeader.id,
        type: panelType.NOM,
        data: nomHeader,
      }
      this.informationPanelService.setPanelData(valueToPass);
      this.informationPanelService.showInfoPanel(true);
    }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.utilities.clearDataToChild();
    this.utilities.unsubscribeDataToChild();
  }
}
