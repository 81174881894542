import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject, map, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class MarketElasticityService {

  public applyCustomView$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public applyColOrder$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  pipelineData$: Subject<any> = new Subject();
  updatedDesk$: BehaviorSubject<any> = new BehaviorSubject<any>("");

  constructor(private apiService: ApiService, private httpClient: HttpClient) { }

  getColumnData(): Observable<any> {
    let url = '../assets/json/market-elasticty-header.json';
    return this.httpClient.get(url);
  }

  getRowData(): Observable<any> {
    let url = '../assets/json/market-elasticty-rowdata.json';
    return this.httpClient.get(url);
  }


  getHeaderRowData(deskId: number): Observable<any> {
    let url = environment.meAPI + '/GetMarketElasticityData?deskId=' + deskId;
    return this.httpClient.get(url);
  }

  getGridData(payload: any): Observable<any> {
    return this.apiService
      .post(`${environment.meAPI}/GetMarketElasticityData`, 'marketElasticity', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveRowData(payload: any) {
    return this.apiService
      .post(`${environment.meAPI}/PostMarketElasticityData`, 'marketElasticity', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  createNomination(payload: any) {
    return this.apiService
      .post(`${environment.nominationAPI}/CreateNomination`, 'nomination', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateNomination(payload: any) {
    return this.apiService
      .patch(`${environment.nominationAPI}/UpdateNominations`, 'nomination', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  
  getNominationDetailfromSource(nomKey: any): Observable<any> { //622202
    return this.apiService
      .get(
        `${environment.nominationAPI}/SearchNominations?nominationKey=`+ nomKey + '&deskFilter=ME', 'nomination',
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  
  getHBCHeaderRowData(): Observable<any> {
    let url = '../assets/json/market-elasticty-hbc.json';
    return this.httpClient.get(url);
  }

  public showNominationFooter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showNominationFooter$ = this.showNominationFooter.asObservable()

  setShowNominationFooter(action: string) {
    this.showNominationFooter.next(action)
  }

  getPSDFilterData({ fromDate, toDate }: any, selectedDesk: any): Observable<any> {
    const encodeURITime = encodeURIComponent(` 00:00:00`);
    /*
    return this.apiService
      .get(`${environment.contractBalancingAPI}/GetContractBalancingFilter?fromDate=${fromDate}${encodeURITime}&toDate=${toDate} 23:59:59&desk=${selectedDesk}`, 'contractBalancing')
      .pipe(
        map((data) => {
          return data;
        })
      );
      */
    let url = '../assets/json/pasadena-filter-data.json';
    return this.httpClient.get(url);
  }

  public nominationParamPSD: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nominationParamPSD$ = this.nominationParamPSD.asObservable();
  setNominationParamPSD(action:any){
    this.nominationParamPSD.next(action);
  }

  public getOpenNominationPSD: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  getOpenNominationPSD$ = this.getOpenNominationPSD.asObservable();
  setOpenNominationPSD(action:any){
    this.getOpenNominationPSD.next(action);
  }

}
