import { AlertRuleResponse } from "./settings.interface";



export interface AlertCardDivider{
    divider: string,
    alertList: AlertRuleResponse[],
}

export interface DeskFilterAlert{
    deskName: string,
    numberOfItems: number,
}



export enum NotificationState{

    SignalR = "SIGINALR", 
    UserOpen = "USEROPEN",
    TR = "TR",
  }
