import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'name-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './name-header.component.html',
  styleUrls: ['./name-header.component.scss']
})
export class NameHeaderComponent implements OnInit, OnChanges {

  @Input() dateToShow: string = '';
  @Input() userName: string = '';
  @Input() creatingNewItem: boolean = false;
  initials: string = '';

  constructor(private dateService: DateService, ) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.setDate();
    this.validateName();
  }

  setDate() {
    var date = this.dateToShow != '' ? this.dateToShow : this.dateService.getCurrentTimeInISO();
    this.dateToShow = this.dateService.getStandardDateFromISOString(date);
  }

  validateName()
  {
    if(this.userName == "" && !this.creatingNewItem){
      this.userName = "Unknown User";
    }else if(this.creatingNewItem){
      this.userName = localStorage.getItem("userName")!;
    }
    this.createInitials(this.userName);
  }

  createInitials(name: any) {
    const nameArray = name.split(' ').filter(Boolean);
    const firstLetter = nameArray[0] ? nameArray[0][0] : '';
    const secondLetter = nameArray[1] ? nameArray[1][0] : '';
    this.initials = secondLetter + firstLetter;
  }

}
