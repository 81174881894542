<div class="create-nominations-panel-wrapper">
  <div class="panel-header d-flex justify-content-between">
    <div class="panel-title">nominations</div>
    <button class="nominations-btn nominations-panel nominations" (click)="closeNominationsPanel()">
      <span class="icon-remove open"></span>
    </button>
  </div>

  <div class="panel-content">
    <div class="accordion-item">
      <div class="accordion-header" (click)="expandDraftAccordion()">
        draft({{draftNominations?.length}})<span class="expand-icon" *ngIf="!isDraftAccordionExpanded">+</span><span
          class="expand-icon" *ngIf="isDraftAccordionExpanded">-</span>
      </div>
      <div class="accordion-content" *ngIf="isDraftAccordionExpanded">
        <div class="draft-nominations" *ngFor="let draftNom of draftNominations; let i =index">
          <div class="row counter-part" [ngClass]="{'highlight_counterpart': getMatchedlineItem?.id=== draftNom?.id}">
            <div class="col-md-10 offtaker">
              <span class="content-header cpname">{{draftNom?.headerToItemNav[0]?.nominationNo}}</span>
              <span class="content-body created-date">created on {{draftNom?.headerToItemNav[0]?.createdOn
                                | date:'mediumDate'}} </span>
            </div>
            <div class="col-md-2">
              <div class="list">
                <button class="ellipses-icon"></button>
                <div class="list-content">
                  <a class="list-item create-nomination" (click)="onCreateEthanolNominationFromTR(draftNom)">create
                    nomination</a>
                  <a class="list-item delete-nomination" (click)="deleteNomination(draftNom)">delete</a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-6">
                <span class="content-header">supplier</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.supplierName}}</span>
              </div>
              <div class="col-md-6">
                <span class="content-header">carrier</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.carrierName}}</span>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="content-header">mot</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.modeOfTransportDesc?.toLowerCase() === 'pipeline' ? 'Tank' : draftNom?.headerToItemNav[0]?.modeOfTransportDesc}}</span>
              </div>
              <div class="col-md-6">
                <span class="content-header">nominated</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.scheduledQty | number:'1.2'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="content-header">scheduled date</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.scheduledDate}}</span>
              </div>
              <div class="col-md-6">
                <span class="content-header">location</span>
                <span class="content-body">{{draftNom?.headerToItemNav[0]?.locationId}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-content">
    <div class="accordion-item">
      <div class="accordion-header" (click)="expandPublishedAccordion()">
        Published({{publishedNominations?.length}})<span class="expand-icon"
          *ngIf="!isPublishedAccordionExpanded">+</span><span class="expand-icon"
          *ngIf="isPublishedAccordionExpanded">-</span>
      </div>
      <div class="accordion-content" *ngIf="isPublishedAccordionExpanded">
        <div class="published-nominations" *ngFor="let publishedNom of publishedNominations;">
          <div *ngFor="let pubItem of publishedNom.headerToItemNav;let i = index ">
            <div class="row" *ngIf="i === 0" [ngClass]="{'highlight_counterpart-published': getMatchedlineItem?.id=== publishedNom?.id}">              
              <div class="col-md-12 accordion-item-internal">
                <div class="accordion-header counter-part" (click)="expandPublishedAccordionInternal(publishedNom?.id)">

                  <div class="row headerPadding">

                    <div  class="col-md-1 alligncentercontent">
                      <span class="expand-icon"
                 *ngIf="isPublishedAccordionExpandedInternal==publishedNom?.id; else PubiconName">-</span>

                 <ng-template #PubiconName>
                   <span class="expand-icon">+</span>
               </ng-template>
                 </div>

                  <div class="col-md-9 offtaker">
                    <span class="content-header cpname">{{pubItem?.nominationNo}} </span>
                    <span class="content-body created-date">created on {{pubItem?.createdOn |
                                        date:'mediumDate'}}</span>
                 
                  </div>
                                      <div class="col-md-1 alligncentercontent">
                                        <div class="list">
                                          <button class="ellipses-icon"></button>
                                          <div class="list-content">
                                            <a class="list-item create-nomination"
                                              (click)="onCreateEthanolNominationFromTR(publishedNom)">create nom from reference</a>
                                            <a class="list-item create-nomination" (click)="goToNominations(publishedNom)">go to
                                              nomination</a>
                                            <a class="list-item create-nomination" (click)="deleteNomination(publishedNom)">delete</a>
                                          </div>
                                        </div>
                                      </div>
                  </div>
                </div>
              </div> 
            </div>
                <div class="accordion-content" *ngIf="isPublishedAccordionExpandedInternal==publishedNom?.id">
                  <div [class.muliti_item]="i>0">
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">nominated</span>
                        <span class="content-body">{{pubItem?.scheduledQty}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled material</span>
                        <span class="content-body">{{pubItem?.scheduledMaterialName}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">demand material</span>
                        <span class="content-body">{{pubItem?.demandMaterialName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">supplier</span>
                        <span class="content-body">{{pubItem?.supplierName}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">carrier</span>
                        <span class="content-body">{{pubItem.carrierName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">contract quantity</span>
                        <span class="content-body">{{pubItem?.actualPostedQuantity}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">contract tolerance</span>
                        <span class="content-body">{{pubItem?.quantityToleranceOver}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">contract no</span>
                        <span class="content-body">{{pubItem?.nominationReferenceDocument}}</span>
      
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">mot</span>
                        <span class="content-body">{{pubItem?.modeOfTransportDesc?.toLowerCase() === 'pipeline' ? 'Tank' : pubItem?.modeOfTransportDesc}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled date</span>
                        <span class="content-body">{{pubItem?.scheduledDate}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">location</span>
                        <span class="content-body">{{pubItem?.locationId}}</span>
                      </div>
                    </div>
      
                  </div>  
                </div>     
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-content">
    <div class="accordion-item">
      <div class="accordion-header" (click)='expandUnpublishedAccordion()'>
        Unpublished({{unpublishedNominations?.length}})<span class="expand-icon"
          *ngIf="!isUnpublishedAccordionExpanded">+</span><span class="expand-icon"
          *ngIf="isUnpublishedAccordionExpanded">-</span>
      </div>
      <div class="accordion-content" *ngIf="isUnpublishedAccordionExpanded">
        <div class="unpublished-nominations" *ngFor="let unpublishedNom of unpublishedNominations;"
          [ngStyle]="{'display': (unpublishedNom?.headerToItemNav?.length === 0) ? 'none' : 'block'}">
          <!-- {{ unpublishedNom }} -->
          <div *ngFor="let unPubItem of unpublishedNom.headerToItemNav;let i =index; ">
            <!-- {{ unPubItem.nominationNo }} -->
            <div class="row" *ngIf="i === 0" [ngClass]="{'highlight_counterpart-unpublished': getMatchedlineItem?.id=== unpublishedNom?.id}">
             
              <div class=" col-md-12 accordion-item-internal">
                <div class="accordion-header counter-part" (click)="expandUnPublishedAccordionInternal(unpublishedNom?.id)">
  
                  <div class="row headerPadding">

                    <div class="col-md-1 alligncentercontent">
                      <span class="expand-icon"
    *ngIf="isUnPublishedAccordionExpandedInternal==unpublishedNom?.id; else IconTemplateUnpub">-</span>

    <ng-template #IconTemplateUnpub>
      <span class="expand-icon">+</span>
  </ng-template>
                    </div>

                  <div class="col-md-9 offtaker">
                    <span class="content-header cpname">{{unPubItem?.nominationNo}} </span>
                    <span class="content-body created-date">created on {{unPubItem?.createdOn |
                                        date:'mediumDate'}}</span>
                 
                                      </div>
                                      
                                      <div class="col-md-1 alligncentercontent">
                                        <div class="list">
                                          <button class="ellipses-icon"></button>
                                          <div class="list-content">
                                            <a class="list-item create-nomination"
                                              (click)="onCreateEthanolNominationFromTR(unpublishedNom)">create nom from reference</a>
                                            <a class="list-item create-nomination" (click)="goToNominations(unpublishedNom)">go to
                                              nomination</a>
                                            <a class="list-item create-nomination" (click)="deleteNomination(unpublishedNom)">delete</a>
                                          </div>
                                        </div>
                                      </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="accordion-content" *ngIf="isUnPublishedAccordionExpandedInternal==unpublishedNom?.id">
            <div [class.muliti_item]="i>0">
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">nominated</span>
                  <span class="content-body">{{unPubItem?.scheduledQty}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">scheduled material</span>
                  <span class="content-body">{{unPubItem?.scheduledMaterialName}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">demand material</span>
                  <span class="content-body">{{unPubItem?.demandMaterialName}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">Supplier</span>
                  <span class="content-body">{{unPubItem?.supplierName}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">Carrier</span>
                  <span class="content-body">{{unPubItem?.carrierName}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">contract quantity</span>
                  <span class="content-body">{{unPubItem?.actualPostedQuantity}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">contract tolerance</span>
                  <span class="content-body">{{unPubItem.quantityToleranceOver}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">contract no</span>
                  <span class="content-body">{{unPubItem?.nominationReferenceDocument}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">mot</span>
                  <span class="content-body">{{unPubItem?.modeOfTransportDesc?.toLowerCase() === 'pipeline' ? 'Tank' : unPubItem?.modeOfTransportDesc}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">scheduled date</span>
                  <span class="content-body">{{unPubItem?.scheduledDate}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">location</span>
                  <span class="content-body">{{unPubItem?.locationId}}</span>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
