import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearApiResponse } from 'src/app/nominations/store/action/nominations.action';
import { ToastService } from '../../../services/toast.service';
import { AppState } from '../../store/reducer/app.reducer';

@Component({
  selector: 'np-notification',
  templateUrl: './np-notification.component.html',
  styleUrls: ['./np-notification.component.scss']
})
export class NpNotificationComponent implements OnChanges {
  @Input() data: any = {};
  @Input() show: boolean = false;
  @Input() isContractBalancingDynamicDeals:boolean = false;
  timerRef: any;
  constructor(private store: Store, private appState: Store<AppState>, private toastSr: ToastService) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.show && this.data['type'] !== 'info') {
      this.timerRef = setTimeout(() => this.hide(), 15000);
      this.store.dispatch(clearApiResponse({ updateNominationApiResponse: {}, publishOrDeleteNominationApiResponse: {} }))
    } else {
      this.timerRef = setTimeout(() => this.hide(), 50000);
    }
  }

  hide() {
    this.show = false;
    clearTimeout(this.timerRef);
    this.toastSr.setToastNotification({ show: false, msg: '', type: '' });
  }
}
