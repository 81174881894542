import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {

  //   TOP: STARTING UNITS; SIDE: ENDING UNITS
    /*             MB        BR           GA          KL 
   *        MB     1        1/1000        1/42000      1
   *        BR    1000       1            1/42         1    
   *        GA   42000       42           1            1
   *        KL   1           1            1            1
   */

  private unitArray: number[][] = [

    [1,   1/1000,    1/42000,  1],
    [1000,  1,       1/42,     1],
    [42000, 42,      1,        1],
    [1,     1,       1 ,       1],
  ];

  getUnitEnumFromString(unit: string): Units {

    return Units[unit.toUpperCase() as keyof typeof Units];
  }


  convertUnits(value: number | null, startingUnits: string, endingUnits: string): number | null{

    let beginningIndex: Units = this.getUnitEnumFromString(startingUnits)
    let endingIndex: Units = this.getUnitEnumFromString(endingUnits)

    if(value == null || beginningIndex == null || endingIndex == null){
      return null;
    }
    let conversionMultiply = this.unitArray[endingIndex][beginningIndex]
    
    return value * conversionMultiply
  }
}

export enum Units{
  MB = 0,
  BR = 1,
  GA = 2,
  KL = 3,
}





















