import { Component } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {
  SubscriptionUsers,
  UpdateRuleInventory,
  UpdateRuleReconciliation,
  UpdateRuleActualDemand,
} from '../../shared/interface/settings.interface';
import { AlertsNotificationsComponent } from '../alerts-notifications/alerts-notifications.component';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-alert-toggle',
  templateUrl: './alert-toggle.component.html',
  styleUrls: ['./alert-toggle.component.scss'],
})
export class AlertToggleComponent implements ICellRendererAngularComp {
  constructor(
    private authService: CalAngularService,
    private alertsComponent: AlertsNotificationsComponent,
    private alertSaveService: AlertSaveGuardService,
    private alertsService: AlertsService,
  ) {}

  public cellValue!: string;
  public isOpen = false;
  public rowIndex: any;
  public notifBool: boolean = false;
  public ruleId!: string;
  public updateRuleToggleInventory!: UpdateRuleInventory;
  public updateRuleToggleReconciliation!: UpdateRuleReconciliation;
  public updateRuleToggleActualDemand!: UpdateRuleActualDemand;

  public subscriptions!: SubscriptionUsers[];
  public newActive!: {
    userEmail: string;
    isEmail: boolean;
    isTeams: boolean;
    isWorkspace: boolean;
    isActive: boolean;
  };
  public params!: ICellRendererParams;
  public ruleName!: string;
  public emailList: string[] = [];
  public hoverText: string = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (
      params.data.subscription == null ||
      params.data.subscription.users == null
    ) {
      this.notifBool = false;
      this.subscriptions = params.data.subscription;
    } else {
      for (const element of params.data.subscription.users) {
        this.rowIndex = params.rowIndex;
        if (element.userEmail === this.authService.cvxClaimsPrincipal.email) {
          this.notifBool = element.isActive;
        }
      }
    }

    this.updateText();
  }

  onToggleClick(params: ICellRendererParams) {
    this.notifBool = !this.notifBool;
    this.updateText();

    this.alertSaveService.addContent(this.generateIDText());

    for (let element of params.data.subscription.users) {
      this.emailList.push(element.userEmail);
      if (element.userEmail === this.authService.cvxClaimsPrincipal.email) {
        element.isActive = !element.isActive;
      }
    }

    if (!this.emailList.includes(this.authService.cvxClaimsPrincipal.email)) {
      params.data.subscription.users.push({
        userEmail: this.authService.cvxClaimsPrincipal.email,
        isActive: true,
        isTeams: false,
        isWorkspace: false,
        isEmail: false,
      });
    }
    this.emailList = [];
    console.log(params.data);
    if (params.data.ruleType === 'Inventory') {
      this.updateRuleToggleInventory = {
        ruleId: params.data.ruleId,
        ruleName: params.data.ruleName,
        ruleDescription: params.data.ruleDescription,
        location: params.data.location,
        material: params.data.material,
        materialNumber: params.data.materialNumber,
        materialGroupNumber: params.data.materialGroupNumber,
        desk: params.data.desk,
        deskId: params.data.deskId,
        deskNickName: params.data.deskNickName,
        deskRegion: params.data.deskRegion,
        deskTag: params.data.deskTag,
        udmNickname: params.data.udmNickname,
        ruleType: params.data.ruleType,
        daysToValidate: {
          type: params.data.daysToValidate.type,
          NoOfdays: params.data.daysToValidate.NoOfdays,
        },
        ruleCondition: params.data.ruleCondition,
        parameter: params.data.parameter,
        alertFrequency: params.data.alertFrequency,
        subscription: {
          users: params.data.subscription.users,
        },
        createdBy: params.data.createdBy,
        createdDate: params.data.createdDate,
        updatedBy: params.data.updatedBy,
        updatedDate: params.data.updatedDate,
        isActive: params.data.isActive,
      };

      this.alertsComponent.updateNotifsInventoryPayload.push(
        this.updateRuleToggleInventory,
      );
    } else if (params.data.ruleType === 'Reconciliation') {
      this.updateRuleToggleReconciliation = {
        ruleId: params.data.ruleId,
        ruleName: params.data.ruleName,
        ruleDescription: params.data.ruleDescription,
        location: params.data.location,
        material: params.data.material,
        materialNumber: params.data.materialNumber,
        udmNickname: params.data.udmNickname,
        ruleType: params.data.ruleType,
        alertFrequency: params.data.alertFrequency,
        thersold: params.data.thersold,
        subscription: {
          users: params.data.subscription.users,
        },
        createdBy: params.data.createdBy,
        createdDate: params.data.createdDate,
        updatedBy: params.data.updatedBy,
        updatedDate: params.data.updatedDate,
        isActive: params.data.isActive,
      };
      this.alertsComponent.updateNotifsReconciliationPayload.push(
        this.updateRuleToggleReconciliation,
      );
    } else if (params.data.ruleType === 'ActualDemand') {
      console.log('Actual Demand');
      this.updateRuleToggleActualDemand = {
        ruleId: params.data.ruleId,
        ruleName: params.data.ruleName,
        ruleDescription: params.data.ruleDescription,
        material: params.data.material,
        materialNumber: params.data.materialNumber,
        materialGroupNumber: params.data.materialGroupNumber,
        desk: params.data.desk,
        deskId: params.data.deskId,
        deskTag: params.data.deskTag,
        deskNickName: params.data.deskNickName,
        deskRegion: params.data.deskRegion,
        location: params.data.location,
        udmNickname: params.data.udmNickname,
        ruleType: params.data.ruleType,
        alertFrequency: params.data.alertFrequency,
        thersold: params.data.thersold,
        daysToValidate: {
          type: params.data.daysToValidate.type,
          NoOfdays: params.data.daysToValidate.NoOfdays,
        },
        ruleCondition: params.data.ruleCondition,
        subscription: {
          users: params.data.subscription.users,
        },
        createdBy: params.data.createdBy,
        createdDate: params.data.createdDate,
        updatedBy: params.data.updatedBy,
        updatedDate: params.data.updatedDate,
        isActive: params.data.isActive,
      };
      this.alertsComponent.updateNotifsActualDemandPayload.push(
        this.updateRuleToggleActualDemand,
      );
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  generateIDText(): string {
    return (
      'Toggle: ' +
      this.params.data.ruleName +
      ', ' +
      this.params.data.location +
      ', ' +
      this.params.data.material
    );
  }

  updateText() {
    this.hoverText = this.notifBool
      ? 'disable notifications for rule ' + this.params.data.ruleName
      : 'activate notifications for rule ' + this.params.data.ruleName;
  }
}
