import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
    CellValueChangedEvent,
    ColDef,
    GetRowIdFunc,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
    IDetailCellRendererParams,
    RowGroupingDisplayType,
    SelectionChangedEvent,
    FirstDataRenderedEvent,
    IRowNode
} from 'ag-grid-community';
import { ToastService } from 'src/app/services/toast.service';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ChipRendererComponent } from 'src/app/shared/modules/ag-grid-renderers/chip-renderer/chip-renderer.component';
import { NominationService } from '../../services/nomination.service';
import { CustomHeaderComponent } from '../custom-header.component';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { DatePipe, Location } from '@angular/common';
import { CreateTreminalNominationComponent } from 'src/app/core/components/create-treminal-nomination/create-treminal-nomination.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { select, Store } from '@ngrx/store';
import { searchNomResponse, selectAppliedTRFilters, selectDefaultTRFilters, selectDesk, selectEthanolNomFiltersResponse, selectPipelineNomFiltersResponse, selectRefineryNomFiltersResponse, updateNominationData } from 'src/app/shared/store/selector/app.selector';
import { Subject, takeUntil } from 'rxjs';
import { AutoCompleteComponent } from '../autocomplete/autocomplete.component';
import { publishOrDeleteNominationData, selectEthanolNominationList, selectNominationData, selectNominationList } from '../store/selector/nomination.selector';
import { invokeNominationData } from '../store/action/nominations.action';
import { setReceivedResponseBoolean, updatedOfftakersFalse, updateNomination, setSelectedDesk, saveAppliedTRFilters } from 'src/app/shared/store/action/app.action';
import { FilterPanelComponent } from 'src/app/core/components/filter-panel/filter-panel.component';
import { NumberCellEditorComponent } from 'src/app/shared/modules/number-cell-editor/number-cell-editor.component';
import * as Nom from '../../shared/constants/nominations.constant';
import { NominationEthanolViewComponent } from './nomination-ethanol-view/nomination-ethanol-view.component';
import { NominationPipelineViewComponent } from './nomination-pipeline-view/nomination-pipeline-view.component';
import { NominationRefineryViewComponent } from './nomination-refinery-view/nomination-refinery-view.component';

import { LoaderService } from 'src/app/services/loader.service';
import { HomeComponent } from 'src/app/home/home.component';
import { CargoPlanningComponent } from '../cargo-planning-view/cargo-planning/cargo-planning.component';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { NominationGlobalComponent } from './nomination-global-view/nomination-global.component';
import { NominationCcaViewComponent } from './nomination-cca-view/nomination-cca-view.component';
import { MdmService } from 'src/app/services/mdm.service';

@Component({
    selector: 'app-nomination-grid',
    templateUrl: './nomination-grid.component.html',
    styleUrls: ['./nomination-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NominationGridComponent implements OnInit {
    updatedFilterSelection: any;
    totalTRFiltersResponse: any;
    desk: any;
    searchNomResponse$ = this.store.pipe(select(searchNomResponse));
    openModal: any;
    mainGridIndex: any;
    previousDestinationDate: any;
    updatedDestinationDate: any;
    previousScheduledQty: any;
    updatedScheduledQty: any;
    cellEditedDetails: any;
    trSave!: boolean;
    masterUpdateRowIndex: any;
    detailUpdatedRowIndex: any;
    masterTestData: any[] = [];
    detailTestData: any[] = [];
    initialRowData: any;
    collectionOfId: any[] = [];
    terminalNominationModal = { open: false, modal: 'createTerminalNomination' }
    openNominationConfirmationModal = { open: false, modal: 'openNominationConfirmationModal' }
    public modalHeader!: string;
    public createTerminalNomination = true;
    public groupDisplayType: RowGroupingDisplayType = 'custom';
    public gridApi!: GridApi;
    public rowData: any[] = [];
    public updatedValue: any[] = [];
    public selectedRows: any[] = [];
    public selectedMasterRecords: any[] = [];
    public selectedDetailRecords: any[] = [];
    public selectedMasterDetailRecords: any[] = [];
    public editedIds: any[] = [];
    public detailEditedIds: any[] = [];
    public updatingRow: boolean = false;
    public editNominationNoInner: boolean = false;
    public newScheduledDate: any;
    public newScheduledQty: any;
    public updateNominationNo: any;
    public udmDeliveryLaycanStartDate: any;
    public udmDeliveryLaycanEndDate: any;
    public updateSingleLineSapContract: any;
    public updateSingleLineScheduledQty: any;
    public updateSingleLineScheduledDate: any;
    public updateSingleLineContract: any;
    public updateSingleLineComments: any;
    rpFilterData : any = localStorage.getItem('gCCAF');
    filterListDataFromRP : any = JSON.parse( this.rpFilterData );
    public updateSingleLineUdmDeliveryLaycanStartDate: any;
    public updateSingleLineUdmDeliveryLaycanEndDate: any;
    public updateSingleLineLaycanStartDate: any;
    public updateSingleLineLaycanEndDate: any;
    public singleLineId: any;
    sapContractList: any = [];
    public popupAction: string = "";
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold'
    };
    frameworkComponents;
    public lineItem: any;
    public detailGridHeadersPayload: any;
    public sameLineItemEdited: boolean = false;
    public sapContractDetails: any[] = [];
    public contractLineNumberList: any[] = [];
    @ViewChild(CreateTreminalNominationComponent) createTerminalNominationComponent!: CreateTreminalNominationComponent;
    @ViewChild(FilterPanelComponent) filterPanelComponent!: FilterPanelComponent;
    @ViewChild(NominationEthanolViewComponent) nominationEthanolViewComponent!: NominationEthanolViewComponent;
    @ViewChild(NominationPipelineViewComponent) nominationPipelineViewComponent!: NominationPipelineViewComponent;
    @ViewChild(NominationRefineryViewComponent) nominationRefineryViewComponent!: NominationRefineryViewComponent;
    @ViewChild(CargoPlanningComponent) cargoPlanningComponent!: CargoPlanningComponent;
    @ViewChild(NominationGlobalComponent) nominationGlobalComponent!: NominationGlobalComponent;
    @ViewChild(NominationCcaViewComponent) nominationCcaViewComponent!: NominationCcaViewComponent;
    public paginationPageSize = 40;
    public rowSelection: 'single' | 'multiple' = 'multiple';

    public components: {
        [p: string]: any;
    } = {
            agColumnHeader: CustomHeaderComponent,
            autoComplete: AutoCompleteComponent
        };
    destroy$: Subject<boolean> = new Subject<boolean>();
    selectedDefaultFilters$ = this.appStore.pipe(select(selectDefaultTRFilters));
    selectedAppliedFilters$ = this.appStore.pipe(select(selectAppliedTRFilters));
    nominationData$ = this.store.pipe(select(selectNominationData));
    nominationList$ = this.store.pipe(select(selectNominationList));
    updateNomination$ = this.store.pipe(select(updateNominationData));
    publishDelete$ = this.store.pipe(select(publishOrDeleteNominationData));
    selectedDesk$ = this.store.pipe(select(selectDesk));
    ethanolNominationList$ = this.store.pipe(select(selectEthanolNominationList));
    selectEthanolTRFiltersResponse$ = this.appStore.pipe(select(selectEthanolNomFiltersResponse));
    selectPipelineTRFiltersResponse$ = this.appStore.pipe(select(selectPipelineNomFiltersResponse));
    selectRefineryNomFiltersResponse$ = this.appStore.pipe(select(selectRefineryNomFiltersResponse));
    onPublishSearchCalled: boolean = false;
    isCCAPanamaView: boolean = false;
    public columnDefs: ColDef[] = [
        {
            field: '',
            headerCheckboxSelection: true,
            checkboxSelection: (params: any) => {
                return params?.data?.detailType?.length === 0;
            },
            width: 50,
            maxWidth: 50,
            resizable: false,
            sortable: false,
            lockVisible: true,
            suppressMovable: false,
        },
        {
            valueGetter: (params: any) => {
                let sameValue = false;
                if (params?.data?.detailType?.length > 0) {
                    const detailType = params?.data?.detailType;
                    for (let i = 0; i < detailType.length - 1; i++) {
                        if (detailType[i]?.status?.toLowerCase() === detailType[i + 1]?.status?.toLowerCase()) {
                            sameValue = true;
                        } else {
                            sameValue = false;
                            break;
                        }
                    }
                } else {
                    sameValue = true;
                }
                if (sameValue) {
                    return params?.data?.status;
                } else {
                    return "multiple";
                }
            },
            field: '',
            headerName: 'status',
            cellRenderer: ChipRendererComponent,
            cellRendererSelector: (params: any) => {
                const rowExpansion = {
                    component: "agGroupCellRenderer",
                }
                if (params?.data?.detailType?.length > 0) {
                    return rowExpansion;
                }
                return undefined;
            },
            cellClass: params => {
                return params?.data?.detailType?.length > 0 ? 'detail-type-multiple subtitle-4' : '';
            },
        },
        {
            field: 'nominationNo',
            headerName: 'nomno.',
            valueGetter: (params: any) => {
                let sameValue = false;
                if (params?.data?.detailType?.length > 0) {
                    const detailType = params?.data?.detailType;
                    for (let i = 0; i < detailType.length - 1; i++) {
                        if (detailType[i]?.nominationNo === detailType[i + 1]?.nominationNo) {
                            sameValue = true;
                        } else {
                            sameValue = false;
                            break;
                        }
                    }
                } else {
                    sameValue = true;
                }
                if (sameValue) {
                    return params?.data?.nominationNo;
                } else {
                    return "multiple";
                }
            },
            editable: params => params?.data?.detailType?.length === 0,
            cellEditorParams: { maxLength: 16 },
            cellRendererSelector: (params: any) => {
                const rowExpansion = {
                    component: "agGroupCellRenderer",
                }
                if (params?.data?.detailType?.length > 0) {
                    return rowExpansion;
                }
                return undefined;
            },
            cellRendererParams: (params: any) => params.data,
            cellClass: params => {
                if (params?.data?.detailType?.length > 0) {
                    return 'lockedValues';
                } else {
                    return 'nominationNo';
                }
            },

            icons: {
                groupContracted: '<img src="../../../assets/icons/16_Export.svg" style="height: 12px; width: 12px;padding-right: 2px"/>',
            },
        },
        {
            field: 'nominationKey',
            headerName: 'nomkey',
        },
        {
            field: 'nominationKeyItem',
            headerName: 'lineitem',
            valueGetter: (params: any) => {
                let sameValue = false;
                if (params?.data?.detailType?.length > 0) {
                    const detailType = params?.data?.detailType;
                    for (let i = 0; i < detailType.length - 1; i++) {
                        if (detailType[i]?.nominationKeyItem === detailType[i + 1]?.nominationKeyItem) {
                            sameValue = true;
                        } else {
                            sameValue = false;
                            break;
                        }
                    }
                } else {
                    sameValue = true;
                }
                if (sameValue) {
                    return params?.data?.nominationKeyItem ?? 'blank';
                } else {
                    return "multiple";
                }
            },
            cellRendererSelector: (params: any) => {
                const rowExpansion = {
                    component: "agGroupCellRenderer",
                }
                if (params?.data?.detailType?.length > 0 && params?.value !== 'blank') {
                    return rowExpansion;
                }
                return undefined;
            },
            cellRendererParams: (params: any) => params.data,
            cellClass: params => {
                return params.value === 'multiple' ? 'detail-type-multiple subtitle-4' : '';
            },
        },
        {
            cellRenderer: (params: any) => {
                return '<span class="non-editable">' + params.data.mot + '<img src="../../../assets/icons/lock_grey.svg" class="lock_icon" /> </span>';
            },
            field: '',
            headerName: 'mot'
        },
        {
            field: 'vesselName',
            headerName: 'vessel name'
        },
        {
            valueGetter: (params) => {
                let sameValue = false;
                if (params?.data?.detailType?.length > 0) {
                    const detailType = params?.data?.detailType;
                    for (let i = 0; i < detailType.length - 1; i++) {
                        if (detailType[i]?.detailType === detailType[i + 1]?.detailType) {
                            sameValue = true;
                        } else {
                            sameValue = false;
                            break;
                        }
                    }
                    if (sameValue) {
                        return params?.data?.detailType[0]?.detailType;
                    } else {
                        return "multiple";
                    }
                } else {
                    return params?.data?.udmDetailType;
                }
            },
            cellRenderer: (params: any) => {
                const value = params?.data?.detailType?.length > 0 ? 'multiple' : params?.data?.udmDetailType;
                return '<span class="non-editable">' + value + '<img src="../../../assets/icons/lock_grey.svg" class="lock_icon" /> </span>';
            },
            field: '',
            headerName: 'detail type',
            cellRendererSelector: (params: any) => {
                const rowExpansion = {
                    component: "agGroupCellRenderer",
                }
                if (params?.data?.detailType?.length > 0) {
                    return rowExpansion;
                }
                return undefined;
            },
            cellClass: params => {
                return params?.data?.detailType?.length > 0 ? 'detail-type-multiple subtitle-4' : '';
            },
        },
        {
            field: 'nominationType',
            headerName: 'nom type'
        },
        {
            field: 'referenceDocumentIndicator',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length > 0) {
                    let referenceTypes: any[] = [];
                    let tempArray: any[] = [];
                    let referenceDoc = '';
                    const detailType = params?.data?.detailType;
                    detailType.map((el: any) => {
                        tempArray.push(el.referenceDocumentIndicator);
                    });
                    referenceTypes = [...new Set(tempArray)];
                    referenceTypes.sort((a, b) => { return a - b });
                    if (referenceTypes.length > 1) {
                        referenceTypes.forEach((el: any, index) => {
                            if (index === 0) {
                                referenceDoc = el;
                            } else {
                                referenceDoc = referenceDoc.concat(`, ${el}`);
                            }
                        });
                    } else {
                        referenceDoc = referenceTypes[0];
                    }
                    return referenceDoc;
                } else {
                    return params?.data?.referenceDocumentIndicator;
                }
            },
            headerName: 'ref doc id'
        },
        {
            field: 'loadLocation',
            headerName: 'load location',
            editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
        },
        {
            field: 'counterPart',
            headerName: 'counterpart'
        },
        {
            field: 'udmLordLaycanStartDate',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length === 0) {
                    return params?.data?.udmLordLaycanStartDate;
                } else {
                    return '';
                }
            },
            headerName: 'Load Laycan Start Date',
            editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
            cellEditor: "datePicker",

        },
        {
            field: 'udmLordLaycanEndDate',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length === 0) {
                    return params?.data?.udmLordLaycanEndDate;
                } else {
                    return '';
                }
            },
            headerName: 'Load Laycan End Date',
            editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
            cellEditor: "datePicker",

        },
        {
            field: 'destinationDate',
            headerName: 'scheduled date',
            editable: params => params?.data?.detailType?.length === 0,
            cellEditor: "datePicker",
            cellClass: 'destination-date-cell'
        },
        {
            field: 'sapContract',
            headerName: 'sap contract',
            editable: params => { return (params?.data?.detailType?.length === 0) },
            valueGetter: (params: any) => {
                let sameValue = false;
                if (params?.data?.detailType?.length > 0) {
                    const detailType = params?.data?.detailType;
                    for (let i = 0; i < detailType.length - 1; i++) {
                        if (detailType[i]?.sapContract === detailType[i + 1]?.sapContract) {
                            sameValue = true;
                        } else {
                            sameValue = false;
                            break;
                        }
                    }
                } else {
                    sameValue = true;
                }
                if (sameValue) {
                    return params?.data?.sapContract;
                } else {
                    return "multiple";
                }
            },
            cellClass: params => {
                return params?.data?.detailType?.length > 0 && params?.data?.sapContract ? 'detail-type-multiple subtitle-4 extra_width' : '';
            },
            cellRendererSelector: (params: any) => {
                const rowExpansion = {
                    component: "agGroupCellRenderer",
                }
                if (params?.data?.detailType?.length > 0 && params?.data?.sapContract) {
                    return rowExpansion;
                }
                return undefined;
            },
            cellEditor: AutoCompleteComponent,
            cellEditorParams: () => {
                return {
                    propertyRendered: 'sapContractNumber',
                    showedColumn: 'sapContractNumber',
                    rowData: this.sapContractDetails,
                    columnDefs: [
                        {
                            filter: 'agTextColumnFilter',
                            field: 'sapContractNumber',
                            valueFormatter: (event: any) => {
                                let data = event.data;
                                console.log(data);
                                return data.sapContractNumber;
                            },
                        },
                        {
                            field: 'sapContractNumber',
                            filter: 'agTextColumnFilter',
                            hide: true,
                        },
                    ],
                    popup: true,
                }
            }
        },
        {
            field: 'contractLineItem',
            headerName: 'contract line number',
            editable: params => { return (params?.data?.detailType?.length === 0) },
            cellEditor: AutoCompleteComponent,
            cellEditorParams: (params: any) => ({
                propertyRendered: 'contractLineItem',
                showedColumn: 'contractLineItem',
                rowData: params?.data?.itemId === this.cellEditedDetails?.data?.itemId ? this.contractLineNumberList : [],
                columnDefs: [
                    {
                        filter: 'agTextColumnFilter',
                        field: 'contractLineItem',
                        valueFormatter: (event: any) => {
                            let data = event.data;
                            return data.Name;
                        },
                    },
                    {
                        field: 'contractLineItem',
                        filter: 'agTextColumnFilter',
                        hide: true,
                    },
                ],
                popup: true,
            })
        },
        {
            valueGetter: (params: any) => params?.data?.detailType.length > 0 ? params?.data?.headerStatus : params?.data?.nominationItemStatus,
            headerName: 'TSW Status',
        },
        {
            field: 'udmDeliveryLaycanStartDate',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length === 0) {
                    return params?.data?.udmDeliveryLaycanStartDate;
                } else {
                    return '';
                }
            },
            headerName: 'Delivery Laycan Start Date',
            editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
            cellEditor: "datePicker",

        },
        {
            field: 'udmDeliveryLaycanEndDate',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length === 0) {
                    return params?.data?.udmDeliveryLaycanEndDate;
                } else {
                    return '';
                }
            },
            headerName: 'Delivery Laycan End Date',
            editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
            cellEditor: "datePicker",

        },
        {
            field: 'product',
            headerName: 'product'
        },
        {
            field: 'scheduledQty',
            headerName: 'volume',
            editable: params => params?.data?.detailType?.length === 0,
            cellClass: 'volume-cell',
            valueGetter: function (params) {
                if (params?.data?.scheduledQty === 0 || params?.data?.detailType?.length > 0) {
                    return undefined;
                }
                return params.data.scheduledQty;
            },
            cellEditor: NumberCellEditorComponent
        },
        {
            field: 'destinationLocation',
            headerName: 'destination location'
        },
        {
            field: 'comment',
            valueGetter: (params: any) => {
                if (params?.data?.detailType?.length === 0) {
                    return params?.data?.comment;
                } else {
                    return '';
                }
            },
            headerName: 'comments',
            editable: params => params?.data?.detailType?.length === 0,
        },

    ]

    public getNodeChildDetails!: any;
    nomOffTakerFilterResponse: any[] = [];
    getUpdatedPayload: any[] = [];
    masterGridUpdatedPayload: any[] = [];
    detailGridUpdatedPayload: any[] = [];
    masterValuePayload: any;
    currentlyActiveLineRow: any;
    differentHeaderId: any;
    updateSingleLineNominationNo: any;
    getEthanolFilterResponse: any;
    getPipelineFilterResponse: any;
    getRefineryFilterResponse: any;
    userDetials: any;
    isCargoPlanning: any;
    isSearchOpen: any
    headerToItemNav: any = [];
    headerToItemNavDetail: any = [];
    constructor(private store: Store, private nominationService: NominationService, private toaterService: ToastService, private router: Router, private utilities: Utilities, private datePipe: DatePipe, private appStore: Store<AppState>, private location: Location, private detectChanges: ChangeDetectorRef, private loaderService: LoaderService, private homeComponent: HomeComponent, private myMonitoringService: MyMonitoringService, private mdmService: MdmService, private data: NominationService) {
        this.frameworkComponents = { datePicker: DatePickerComponent };
    }

    ngOnInit(): void {
        this.columnDefs.forEach((columnDef: any) => {
            if (columnDef.editable) {
                columnDef.cellClass = 'highlightEditableCell';
            }
        });
        this.detailCellRendererParams.detailGridOptions.columnDefs.forEach((columnDef: any) => {
            if (columnDef.editable) {
                columnDef.cellClass = 'highlightEditableCell';
            }
        });
        this.userDetials = this.utilities.getUserName(this.nominationService.userDetails$.value._email);
        this.detectChanges.detectChanges();
        this.selectedDesk$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    if (this.desk !== response.selectedDesk) {
                        this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Nominations');
                    }
                    this.desk = response.selectedDesk
                    this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.desk);
                },
                error: (err: any) => {
                    console.log(err);
                },
                complete: () => { },
            });
        this.selectedDefaultFilters$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                    if (this.nominationService.clearFilter.value) {
                        this.updatedFilterSelection = [];
                    }
                    if (response.receivedTotalFiltersResponse && !response.isTRGrid) {
                        this.defaultNominationOffTakerResponse(response);
                    } else if (response.updatedOfftakers && !response.isTRGrid) {
                        this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                        let offTakerFilterArray: any[] = [];
                        this.nomOffTakerFilterResponse = [];
                        this.totalTRFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
                        offTakerFilterArray = this.totalTRFiltersResponse.filter((data: any) => data.filterName === "offtakers")
                        offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
                            if (el.checked) {
                                this.nomOffTakerFilterResponse.push(el.name)
                            }
                        });
                    }
                },
                error: (err: any) => {
                    console.log(err);
                },
                complete: () => { },
            });
        this.selectedAppliedFilters$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    if (this.nominationService.clearFilter.value) {
                        this.nomOffTakerFilterResponse = [];
                    }
                    if (response.receivedUpdatedFilterSelection && !response.isTRGrid) {
                        this.updatedFilterSelection = response.updatedFilterSelection;
                        this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                        let offTakerFilterArray: any[] = [];
                        this.nomOffTakerFilterResponse = [];
                        this.totalTRFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
                        offTakerFilterArray = this.totalTRFiltersResponse.filter((data: any) => data.filterName === "offtakers")
                        offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
                            if (el.checked) {
                                this.nomOffTakerFilterResponse.push(el.name)
                            }
                        });
                        this.getNominations();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                },
                complete: () => { },
            });
        this.defaultFiltersGetNomination();
        this.appliedFiltersGetNomination()
        this.selectedDesk$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    this.desk = response.selectedDesk
                },
                error: (err: any) => {
                    console.log(err);
                },
                complete: () => { },
            });
        setTimeout(() => {
            this.filterPanelComponent?.enableDisableOfftakers();
        }, 2000)
        this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
            if (response?.tab === "/nominations") {
                if (response.function === 'createTerminalNomination' && !this.isCCAPanamaView) {
                    this.toggleCreateTerminalNominationsModal();
                } else if (response.function === 'deleteRowData' || response.function === 'publishRowData') {
                    this.publishOrdeleteRowData(response.type);
                } else if (response.function === 'deleteCCARowData' || response.function === 'publishCCARowData') {
                    this.publishOrDeleteCCARowData(response.type);
                } else if (response?.function === "saveCCAData") {
                    this.saveCCAData();
                } else if (response.function === 'save') {
                    this.save()
                } else if (response.function === 'createNominationWithReference') {
                    this.createNominationWithReference();
                } else if (response.function === 'ethanolCreateNominationModal') {
                    this.toggleEthanolCreateNominationModal();
                } else if (response.function === "saveEthanolNominations") {
                    this.saveEthanolNominations();
                }
                else if (response.function === "editNominationForEthanol") {
                    this.editEthanolNomination(response.type);
                }
                else {
                    this.childUtilitiesFunction(response)
                }
            }
        });
        /**selectedDesl$ and if else desk conditions to be removed and maintain only nominationList$ code here after routing changes implmented */
        this.selectedDesk$.subscribe(dt => {
            if (dt.selectedDesk === "panama") {
                this.nominationList$.pipe(takeUntil(this.destroy$)).subscribe(data => {
                    this.rowData = JSON.parse(JSON.stringify(data));
                    this.rowData.forEach((el: any) => {
                        el.originDate = this.dateCellRenderer(el?.originDate);
                        el.destinationDate = this.dateCellRenderer(el?.destinationDate);
                        el.udmDeliveryLaycanStartDate = this.dateCellRenderer(el?.udmDeliveryLaycanStartDate);
                        el.udmDeliveryLaycanEndDate = this.dateCellRenderer(el?.udmDeliveryLaycanEndDate);
                        el.udmLordLaycanStartDate = this.dateCellRenderer(el?.udmLordLaycanStartDate);
                        el.udmLordLaycanEndDate = this.dateCellRenderer(el?.udmLordLaycanEndDate);
                        el.contractLineItem = this.utilities.removeLeadingZeros(el.contractLineItem);
                        if (el?.detailType?.length > 0) {
                            const detailTypeValue = el?.detailType;
                            detailTypeValue.forEach((val: any) => {
                                val.originDate = this.dateCellRenderer(val?.originDate);
                                val.destinationDate = this.dateCellRenderer(val?.destinationDate);
                                val.udmDeliveryLaycanStartDate = this.dateCellRenderer(val?.udmDeliveryLaycanStartDate);
                                val.udmDeliveryLaycanEndDate = this.dateCellRenderer(val?.udmDeliveryLaycanEndDate);
                                val.udmLordLaycanStartDate = this.dateCellRenderer(val?.udmLordLaycanStartDate);
                                val.udmLordLaycanEndDate = this.dateCellRenderer(val?.udmLordLaycanEndDate);
                                val.contractLineItem = this.utilities.removeLeadingZeros(val.contractLineItem);
                            });
                        }
                    });
                    this.initialRowData = JSON.parse(JSON.stringify(this.rowData));
                    this.nominationService.clearFilter.next(false);
                }); 
            }
            this.sample();
        });
        this.nominationService.getTerminalNominationAFAPI$.subscribe(res => {
            if (res === true) {
                this.getNominations()
            }
        })
        if (this.isCCAPanamaView) {
            this.getSapContractDetails();
        }
        this.homeComponent.cpCargoPlanning$.subscribe((res: any) => {
            this.isCargoPlanning = res
        })
        this.nominationService.getSearchNominationAFAPI$.subscribe((res: any) => {
            this.isSearchOpen = res
        })
        this.mdmService.invokeToggle$.subscribe((isShowPopup: boolean) => {
            if (isShowPopup) {
                this.toggleNominationConfirmationModal();
                this.mdmService.invokeToggleMethod(false);
            }
        })
    }

    childUtilitiesFunction(response: any) {
        if (response.function === "savePipelineNominations") {
            this.savePipelineNominations();
        } else if (response.function === "saveCargoPlanning") {
            this.saveCargoPlanning();
        } else if (response.function === "saveRefineryNominations") {
            this.saveRefineryNominations();
        } else if (response.function === "deleteCargoRowData") {
            this.deleteCargoRowData(response.type);
        } else if (response.function === 'deleteEthanolRowData' || response.function === 'publishEthanolRowData') {
            this.publishOrDeleteEthanolData(response.type);
        } else if (response.function === 'deletePipelineRowData' || response.function === 'publishPipelineRowData') {
            this.publishOrDeletePipelineData(response.type);
            response = {}
        } 
         else if (response.function === 'deleteRefineryRowData' || response.function === 'publishRefineryRowData') {
            this.publishOrDeleteRefineryData(response.type);
         }
        else if (response.function === 'publishEthanolDataFromMoreMenu') {            this.nominationEthanolViewComponent.showWarningPopup();
        } else if (response.function === 'publishPipelineDataFromMoreMenu' && !this.desk?.includes('+ latam +')) {
            this.nominationPipelineViewComponent.showWarningPopup();
        }
        else if (response.function === 'publishPipelineDataFromMoreMenu') {
            this.nominationCcaViewComponent.showWarningPopup();
        }  
        else if (response.function === 'publishRefineyDataFromMoreMenu') {
            this.nominationRefineryViewComponent.showWarningPopup();
        }
        else if (response.function === 'publishPipelineDataFromMoreMenuSearch') {
            this.nominationGlobalComponent.showWarningPopup();
        } else if (response.function === 'navigateToCCAPanamaGrid') {
            this.nominationCcaViewComponent.getPanamaCCANominations();
        } else {
            this.tirggerChildMethodsFromParent(response);
        }
    }

    tirggerChildMethodsFromParent(response: any) {
        if (response.function === 'onPublishEthanol' || response.function === "onDeleteEthanol") {
            this.onPublishEthanol();
        } else if (response.function === 'saveColumnOrderExpansion') {
            this.saveEthanolColumnOrder(response.values);
        } else if (response.function === 'editCustomViewFilters') {
            this.editCustomViewFilters(response.values);
        } else if (response.function === 'ethanolCreateNominationWithReference') {
            // For checking if edit nomination
            if (response.values) {
                response.values["isEditNomination"] = response.type;
            }
            this.createEthanolNominationWithReference(response.values);
        } else if (response.function === 'onPublishFromSearch' || response.function === "onDeleteFromSearch") {
            this.onPublishSearch(true);
        }
    }
    publishOrDeleteEthanolData(event: string) {
        this.nominationEthanolViewComponent.publishOrDeleteNomination(event);
    }

    saveCargoPlanning() {
        this.cargoPlanningComponent?.updateCargoPlanningApi();
    }

    deleteCargoRowData(event: any) {
        this.cargoPlanningComponent?.deleteCargoApi(event);
    }

    publishOrDeletePipelineData(event: string) {
        if (this.isSearchOpen) {
            this.nominationGlobalComponent.publishOrDeleteNomination(event);
        }
        else {
            this.nominationPipelineViewComponent.publishOrDeleteNomination(event);
        }
    }

    publishOrDeleteRefineryData(event: string) {
        if (this.isSearchOpen) {
            this.nominationGlobalComponent.publishOrDeleteNomination(event);
        }
        else {
            this.nominationRefineryViewComponent.publishOrDeleteNomination(event);
        }
    }

    sample() {
        this.selectedDesk$.subscribe(dt => {
            if (dt.selectedDesk === "ethanol + uswc" || dt.selectedDesk === "ethanol + usec") {
                this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
                    next: (response: any) => {
                        if (response.isNomFilterUpdated && this.nominationService.restrictNomApiGetCall$.value) {
                            this.getEthanolFilterResponse = response;
                            this.ethanolNominationList(response);
                        }
                    },
                    error: (err: any) => { },
                    complete: () => { }

                });
            }
            if (dt.selectedDesk?.includes('clean products')) {
                this.selectPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
                    next: (response: any) => {
                        if (response.isPipelineNomFilterUpdated) {
                            this.getPipelineFilterResponse = response;
                            this.ethanolNominationList(response);
                        }
                    },
                    error: (err: any) => { },
                    complete: () => { }

                });
            }
            if (dt.selectedDesk?.includes('refinery')) {
                this.selectRefineryNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
                    next: (response: any) => {
                        if (response.isPipelineNomFilterUpdated) {
                            this.getRefineryFilterResponse = response;
                        }
                    },
                    error: (err: any) => { },
                    complete: () => { }
                });
            }
        })
    }

    /**Need to remove after implementing routing */
    ethanolNominationList(response: any) {
        if (this.nominationService?.getEthanolNominationListPayload || response?.ethanolFilterValues?.length > 0) {
            this.nominationEthanolViewComponent?.getEthanolTruckNominations(response);
        }
    }

    defaultNominationOffTakerResponse(response: any) {
        if (response?.updatedFilterSelection?.offtakers?.length > 0 || response?.updatedFilterSelection?.updatedProduct !== "") {
            this.updatedFilterSelection = response.updatedFilterSelection;
            let offTakerFilterArray: any[] = [];
            this.nomOffTakerFilterResponse = [];
            this.totalTRFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
            offTakerFilterArray = this.totalTRFiltersResponse.filter((data: any) => data.filterName === "offtakers")
            offTakerFilterArray[0].filterValues.forEach((el: any) => {
                if (el.checked) {
                    this.nomOffTakerFilterResponse.push(el.name)
                }
            });
            this.getNominations();
        } else {
            this.getNominations();
        }
    }
    onPublishSearch(getGlobalCalled: any) {
        if (this.isSearchOpen) {
            this.loaderService.setLoading(true);
            setTimeout(() => {
                this.searchNomResponse$
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: (response: any) => {
                            if (getGlobalCalled) {
                                getGlobalCalled = false;
                                this.nominationGlobalComponent.getGlobalNom(response);
                            }

                        }
                    })
            }, 3000)
        }
    }
    onPublishEthanol() {
        this.loaderService.setLoading(true);
        if (this.desk?.includes('+ latam +')) {
            this.loaderService.setLoading(true);
            setTimeout(() => {
                this.nominationCcaViewComponent.getCCANominations(this.getPipelineFilterResponse);
            }, 10000)
        } else if (this.desk?.includes('clean products')) {
            this.loaderService.setLoading(true);
            setTimeout(() => {
                this.nominationPipelineViewComponent.getPipelineNominations(this.getPipelineFilterResponse);
            }, 10000)
        }else if (this.desk?.includes('refinery')) {
            this.loaderService.setLoading(true);
            setTimeout(() => {
                this.nominationRefineryViewComponent.getRefineryNominations(this.getRefineryFilterResponse);
            }, 10000)
        } 
     else {
            this.loaderService.setLoading(true);
            setTimeout(() => {
                this.nominationEthanolViewComponent.getEthanolTruckNominations(this.getEthanolFilterResponse);
            }, 12000)
        }
    }

    saveEthanolColumnOrder(payload: any) {
        if (this.desk === "ethanol + uswc" || this.desk === "ethanol + usec") {
            this.nominationEthanolViewComponent.saveColumnOrder(payload);
        } else if (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) {
            this.nominationPipelineViewComponent.saveColumnOrder(payload);
        }
        else if (this.desk.startsWith("refinery")) {
            this.nominationRefineryViewComponent.saveColumnOrder(payload);
        } 
    }

    editCustomViewFilters(payload: any) {
        if (this.desk === "ethanol + uswc" || this.desk === "ethanol + usec") {
            this.nominationEthanolViewComponent.editCustomViewFilters(payload);
        } else if (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) {
            this.nominationPipelineViewComponent.editCustomViewFilters(payload);
        }
        else if (this.desk.startsWith("refinery")) {
            this.nominationRefineryViewComponent.saveColumnOrder(payload);
        }
    }

    defaultFiltersGetNomination() {
        this.selectedDefaultFilters$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                    if (this.nominationService.clearFilter.value) {
                        this.updatedFilterSelection = [];
                    }
                    if (response.receivedTotalFiltersResponse && !response.isTRGrid) {
                        this.defaultNominationOffTakerResponse(response);
                    } else if (response.updatedOfftakers && !response.isTRGrid) {
                        this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                        let offTakerFilterArray: any[] = [];
                        this.nomOffTakerFilterResponse = [];
                        this.totalTRFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
                        offTakerFilterArray = this.totalTRFiltersResponse.filter((data: any) => data.filterName === "offtakers")
                        offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
                            if (el.checked) {
                                this.nomOffTakerFilterResponse.push(el.name)
                            }
                        });
                    }
                },
                error: (err: any) => { },
                complete: () => { },
            });
    }

    appliedFiltersGetNomination() {
        this.selectedAppliedFilters$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    if (this.nominationService.clearFilter.value) {
                        this.nomOffTakerFilterResponse = [];
                    }
                    if (response.receivedUpdatedFilterSelection && !response.isTRGrid) {
                        this.updatedFilterSelection = response.updatedFilterSelection;
                        this.totalTRFiltersResponse = response.totalTRFiltersResponse;
                        let offTakerFilterArray: any[] = [];
                        this.nomOffTakerFilterResponse = [];
                        this.totalTRFiltersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
                        offTakerFilterArray = this.totalTRFiltersResponse.filter((data: any) => data.filterName === "offtakers")
                        offTakerFilterArray[0]?.filterValues.forEach((el: any) => {
                            if (el.checked) {
                                this.nomOffTakerFilterResponse.push(el.name)
                            }
                        });
                        this.getNominations();
                    }
                },
                error: (err: any) => { },
                complete: () => { },
            });
    }

    publishOrDeleteCCARowData(event: any) {
        this.nominationCcaViewComponent?.publishOrDeleteNominationData(event);
    }

    saveCCAData() {
        this.nominationCcaViewComponent?.saveCCAData();
    }

    publishOrdeleteRowData(event: any) {
        this.selectedMasterDetailRecords = [...this.selectedMasterRecords, ...this.selectedDetailRecords];
        let test: any[] = [];
        this.selectedMasterRecords?.forEach(val => {
            if (val) {
                test.push(
                    {
                        id: val.id,// check what should be for single nom with detailtype=[]
                        sys_status: event.trim() === 'delete' ? val.status : Nom.PUBLISHED,
                        headerToItemNav: [{
                            itemId: val.itemId,   // need to change  id to itemId
                            textLine: `{Author:${val?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                            udmItemStatus: event.trim() === 'delete' ? val.status : Nom.PUBLISHED,
                            deletionIndicator: event.trim() === 'delete' ? 'X' : null
                        }]
                    }
                )
            }
        })
        // only one innergrid for now
        let headerItem: any[] = [];
        this.selectedDetailRecords?.forEach(val => {
            val.detailType?.forEach((lineItem: any) => {
                headerItem.push({
                    itemId: lineItem.itemId,   // need to change  id to itemId
                    textLine: `{Author:${lineItem?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                    udmItemStatus: event.trim() === 'delete' ? lineItem.status : Nom.PUBLISHED,
                    deletionIndicator: event.trim() === 'delete' ? 'X' : null
                })
            })
            headerItem = [...new Set(headerItem)];
            test.push(
                {
                    id: val.id,// check what should be for single nom with detailtype=[]
                    sys_status: event.trim() === 'delete' ? val.status : Nom.PUBLISHED,
                    headerToItemNav: headerItem
                }
            )

        });
        this.publishOrdeleteRowDataWhenData(event, test);
    }
    publishOrdeleteRowDataWhenData(event: any, test: any) {
        if (test?.length > 0 && this.desk === 'panama') {
            this.nominationService.updateNomination(test).subscribe({
                next: (response) => {
                    this.selectedDetailRecords = [];
                    this.selectedMasterRecords = [];
                    setTimeout(() => {
                        if (response.success) {
                            this.toaterService.setToastNotification({
                                show: true,
                                type: 'success',
                                msg: event.trim() === "delete" ? "Nomination has been deleted" : "Nomination is being sent to TSW",
                            });
                        }
                        this.callGetApiAfterDeleteOrPublish();
                    }, 5000);
                }, error: (err) => {
                    this.selectedDetailRecords = [];
                    this.selectedMasterRecords = [];
                    setTimeout(() => {
                        this.callGetApiAfterDeleteOrPublish();
                    }, 5000);
                }
            });
        }
    }

    callGetApiAfterDeleteOrPublish() {
        this.getNominations();
        this.nominationList$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.rowData = JSON.parse(JSON.stringify(data));
            this.rowData.forEach((el: any) => {
                el.originDate = this.dateCellRenderer(el?.originDate);
                el.destinationDate = this.dateCellRenderer(el?.destinationDate);
                el.udmDeliveryLaycanStartDate = this.dateCellRenderer(el?.udmDeliveryLaycanStartDate);
                el.udmDeliveryLaycanEndDate = this.dateCellRenderer(el?.udmDeliveryLaycanEndDate);
                el.udmLordLaycanStartDate = this.dateCellRenderer(el?.udmLordLaycanStartDate);
                el.udmLordLaycanEndDate = this.dateCellRenderer(el?.udmLordLaycanEndDate);
                if (el?.detailType?.length > 0) {
                    const detailTypeValue = el?.detailType;
                    detailTypeValue.forEach((val: any) => {
                        val.originDate = this.dateCellRenderer(val?.originDate);
                        val.destinationDate = this.dateCellRenderer(val?.destinationDate);
                        val.udmDeliveryLaycanStartDate = this.dateCellRenderer(val?.udmDeliveryLaycanStartDate);
                        val.udmDeliveryLaycanEndDate = this.dateCellRenderer(val?.udmDeliveryLaycanEndDate);
                        val.udmLordLaycanStartDate = this.dateCellRenderer(val?.udmLordLaycanStartDate);
                        val.udmLordLaycanEndDate = this.dateCellRenderer(val?.udmLordLaycanEndDate);
                    });
                }
            });
            this.nominationService.clearFilter.next(false);
            this.gridApi.deselectAll();
        });
    }


    onCellClicked(params: any) {
        if (params.column.colId === 'nominationNo') {
            this.getNominationByNominationNumber(params.data);
        }
    }
    getNominationByNominationNumber(data: any) {
        localStorage.setItem('nomId', data.id);
        this.router.navigate(['nominations/nominationDetails'])

    }

    getTMinusSevenDays(getCurrentDate: any, getCurrentDay: any) {
        if (getCurrentDay === 0) {
            return this.datePipe.transform(
                getCurrentDate.setDate(new Date().getDate()) - 7,
                'yyyy-MM-dd'
            );
        } else {
            const getDayDifference =
                getCurrentDate.getDate() -
                7 -
                getCurrentDay +
                (getCurrentDay === 0 ? -6 : 0);
            return this.datePipe.transform(
                new Date(getCurrentDate.setDate(getDayDifference)),
                'yyyy-MM-dd'
            );
        }
    };

    getTPlusSixtyDays(getCurrentDate: any) {
        const date = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 1, 0)
        if (getCurrentDate + 60 === date) {
            return this.datePipe.transform(getCurrentDate + 60, 'yyyy-MM-dd');
        } else {
            const getMonthLastDay = new Date(
                getCurrentDate.getFullYear(),
                getCurrentDate.getMonth() + 3,
                0
            );
            return this.datePipe.transform(getMonthLastDay, 'yyyy-MM-dd');
        }
    };

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        let gTNDeskValue: any = localStorage.getItem('gTNDeskValue');
        var DeskValueTemp : any = JSON.parse(gTNDeskValue);
        this.store.dispatch(setSelectedDesk({ selectedDesk: DeskValueTemp.deskValue , selectedDeskParam: DeskValueTemp.deskParameter , selectedDeskId: DeskValueTemp.id }));
        let rowFound = false;
        const values = {
            offtakers: this.filterListDataFromRP.customers,
            updatedProduct: this.filterListDataFromRP.product,
            updatedfromDate: this.filterListDataFromRP.fromDate,
            updatedToDate: this.filterListDataFromRP.toDate,
          };
        this.store.dispatch(saveAppliedTRFilters({ updatedFilterSelection: JSON.parse(JSON.stringify(values)), receivedUpdatedFilterSelection: true, isClearFilter: false, isTRSaveEnabled: this.trSave, isTRGrid: false }));
        const nodesToSelect: IRowNode[] = [];
        setTimeout(() => {
          let tempValue = localStorage.getItem('nomId');  
          params.api.forEachNode((node: IRowNode) => {
                if (node?.data?.id === tempValue && !rowFound) {
              this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
              params.api.getRowNode(`${node.id}`)?.setSelected(true);
              params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
              localStorage.setItem('nomId', '');
              rowFound = true ;
            }
                if (node.key === tempValue  && !rowFound) {
                params.api.getRowNode(`${node.id}`)?.setSelected(true);
                params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
                localStorage.setItem('nomId', '');
                rowFound = true ;
            }
          });
          localStorage.removeItem('nomId');
          localStorage.removeItem('gTNDeskValue');
          localStorage.removeItem('gCCAF');
        }, 1200);
      };

    getNominations() {
        if(this.filterListDataFromRP){
            var productFilterResponse = this.filterListDataFromRP.product ;
            var offTakerFilterResponse = this.filterListDataFromRP.customers;
        }
        else{
            var productFilterResponse = this.totalTRFiltersResponse?.find((el: any) => el.filterName?.toLowerCase() === "product");
            var offTakerFilterResponse = this.totalTRFiltersResponse?.filter((data: any) => data.filterName?.toLowerCase() === "offtakers");
        }
        const locationFilter = this.totalTRFiltersResponse?.find(
            (filter: any) => filter.filterName?.toLowerCase() === 'location'
        )?.filterValues.find((value: any) => value.checked);
        let postData!: any;
        /**selectedDesl$ and if else desk conditions to be removed and maintain only elaborate postdata of panama code here after routing changes implmented */

        this.selectedDesk$.subscribe(response => {
            if (response.selectedDesk === "panama") {
                postData = this.payloadCreationBasedOnDesk(productFilterResponse, offTakerFilterResponse, locationFilter);
            } else if (response.selectedDesk === "ethanol + uswc" || response.selectedDesk === "ethanol + usec") {
                postData = this.nominationService.getEthanolNominationListPayload;
                this.utilities.getEthanolNomPyaloadMasterData(postData);
            }
        });
        if (postData?.location && postData?.product) {
            this.getTerminalNominations(postData);
        }
    }

    payloadCreationBasedOnDesk(productFilterResponse: any, offTakerFilterResponse: any, locationFilter: any) {
        const getCurrentDate: any = new Date();
        const getCurrentDay = getCurrentDate.getDay();
        return {
            desk: this.updatedFilterSelection?.desk ? this.updatedFilterSelection.desk : this.desk,
            location: locationFilter?.name,
            product: [this.updatedFilterSelection?.updatedProduct
                ? this.updatedFilterSelection.updatedProduct
                : productFilterResponse?.filterValues[0]?.name],
            counterpart: this.nomOffTakerFilterResponse.length > 0 ? this.nomOffTakerFilterResponse : offTakerFilterResponse[0]?.filterValues?.map((item: any) => item.name),
            fromDate:
                this.updatedFilterSelection?.updatedfromDate
                    ? this.updatedFilterSelection?.updatedfromDate
                    : this.getTMinusSevenDays(getCurrentDate, getCurrentDay),
            toDate:
                this.updatedFilterSelection?.updatedToDate
                    ? this.updatedFilterSelection.updatedToDate
                    : this.getTPlusSixtyDays(getCurrentDate),
        };
    }

    getTerminalNominations(data: any) {
        this.store.dispatch(invokeNominationData({ payload: data }));
        this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false, isTRGrid: false }));
        this.store.dispatch(updatedOfftakersFalse({ updatedOfftakers: false }));
    }

    dateCellRenderer(date: any) {
        if (date) {
            const year = Number(date.substr(0, 4));
            const month = Number(date.substr(4, 2));
            const day = Number(date.substr(6, 2));
            const dateFormat = new Date(year, month - 1, day);
            return this.datePipe.transform(dateFormat, "MM/dd/yyyy");
        }
        return '';
    }

    dataExistsAlready(event: CellValueChangedEvent) {
        const rowIndex = this.updatedValue.map(el => el.id).indexOf(event?.data?.id);
        this.updateSingleLineComments = this.updatedValue[rowIndex]?.headerToItemNav[0]?.udmComments ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.udmComments : undefined;
        this.updateSingleLineContract = this.updatedValue[rowIndex]?.headerToItemNav[0]?.nominationReferenceDocumentItem ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.nominationReferenceDocumentItem : undefined;
        this.updateSingleLineSapContract = this.updatedValue[rowIndex]?.headerToItemNav[0]?.nominationReferenceDocument ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.nominationReferenceDocument : undefined;
        this.updateSingleLineScheduledDate = this.updatedValue[rowIndex]?.headerToItemNav[0]?.scheduledDate ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.scheduledDate : undefined;
        this.updateSingleLineScheduledQty = this.updatedValue[rowIndex]?.headerToItemNav[0]?.scheduledQty ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.scheduledQty : undefined;
        this.updateSingleLineNominationNo = this.updatedValue[rowIndex]?.nominationNo ? this.updatedValue[rowIndex]?.nominationNo : undefined;
        this.updateSingleLineUdmDeliveryLaycanStartDate = this.updatedValue[rowIndex]?.udmDeliveryLaycanStartDate ? this.updatedValue[rowIndex]?.udmDeliveryLaycanStartDate : undefined;
        this.updateSingleLineUdmDeliveryLaycanEndDate = this.updatedValue[rowIndex]?.udmDeliveryLaycanEndDate ? this.updatedValue[rowIndex]?.udmDeliveryLaycanEndDate : undefined;
        this.updateSingleLineLaycanStartDate = this.updatedValue[rowIndex]?.headerToItemNav[0]?.laycanStartDate ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.laycanStartDate : undefined;
        this.updateSingleLineLaycanEndDate = this.updatedValue[rowIndex]?.headerToItemNav[0]?.laycanEndDate ? this.updatedValue[rowIndex]?.headerToItemNav[0]?.laycanEndDate : undefined;
    }

    payloadExistanceCheck(event: CellValueChangedEvent) {
        if (this.updatedValue.map(el => el.id).includes(event?.data?.id)) {
            this.dataExistsAlready(event);
        } else {
            this.updateSingleLineComments = null;
            this.updateSingleLineContract = null;
            this.updateSingleLineSapContract = null;
            this.updateSingleLineScheduledDate = null;
            this.updateSingleLineScheduledQty = null;
            this.updateSingleLineNominationNo = null;
            this.updateSingleLineUdmDeliveryLaycanEndDate = null;
            this.updateSingleLineUdmDeliveryLaycanStartDate = null;
            this.updateSingleLineLaycanStartDate = null;
            this.updateSingleLineLaycanEndDate = null;
        }
    }

    getSapContractDetails() {
        this.nominationService.getNominationsRefData(["PanamaSapContractList"]).subscribe(data => {
            this.sapContractDetails = data.result[0].SapContractResponse;
        });
    }

    dataOnEditingSpecificFields(event: CellValueChangedEvent, lineItem: any) {
        if (event.colDef.field === "sapContract") {
            lineItem = this.sapContractDetails.find((dt: any) => {
                if (dt.sapContractNumber === event.value) {
                    return dt.lineItemList;
                }
            });
            lineItem?.lineItemList?.forEach((value: any) => {
                this.contractLineNumberList.push({ contractLineItem: this.utilities.removeLeadingZeros(value) })
            });
            this.updateSingleLineSapContract = event?.data?.sapContract;
        }
        if (event?.colDef?.field === "scheduledQty") {
            this.updateSingleLineScheduledQty = event?.data?.scheduledQty / 1000;
        }
        if (event?.colDef?.field === "destinationDate") {
            const destDate = this.datePipe.transform(event?.data?.destinationDate, "yyyMMdd");
            this.updateSingleLineScheduledDate = destDate;
        }
        if (event?.colDef?.field === "contractLineItem") {
            this.updateSingleLineContract = event?.data?.contractLineItem;
        }
        if (event?.colDef?.field === "comment") {
            this.updateSingleLineComments = event?.data?.comment
        }
        if (event?.colDef?.field === "nominationNo") {
            this.updateSingleLineNominationNo = event?.data?.nominationNo
        }
        if (event?.colDef?.field === "udmDeliveryLaycanStartDate") {
            this.updateSingleLineUdmDeliveryLaycanStartDate = event?.data?.udmDeliveryLaycanStartDate;
        }
        if (event?.colDef?.field === "udmDeliveryLaycanEndDate") {
            this.updateSingleLineUdmDeliveryLaycanEndDate = event?.data?.udmDeliveryLaycanEndDate;
        }
        if (event?.colDef?.field === "laycanStartDate") {
            this.updateSingleLineLaycanStartDate = event?.data?.laycanStartDate;
        }
        if (event?.colDef?.field === "laycanEndDate") {
            this.updateSingleLineLaycanEndDate = event?.data?.laycanEndDate;
        }
    }

    publishUpdatingNomination(event: any) {
        if (event?.data?.status?.toLowerCase() === Nom.PUBLISHED && event?.column?.getColId() === "comment") {
            return Nom.PUBLISHED;
        } else if (event?.data?.status?.toLowerCase() === Nom.PUBLISHED && event?.column?.getColId() !== "comment") {
            return Nom.UNPUBLISHED;
        } else {
            return event?.data?.status
        }
    }

    payloadFormation(event: CellValueChangedEvent) {
        if (event.data.detailType.length === 0) {
            this.headerToItemNav = [this.headerToItemNavFormation(event)];
            const updatedComments = this.headerToItemNav?.filter((el: any) => !!el?.udmComments);
            this.masterValuePayload = {
                id: event?.data?.id,
                sys_status: this.publishUpdatingNomination(event),
                nominationNo: this.updateSingleLineNominationNo ? this.updateSingleLineNominationNo : undefined,
                udmDeliveryLaycanStartDate: this.updateSingleLineUdmDeliveryLaycanStartDate ? this.updateSingleLineUdmDeliveryLaycanStartDate : undefined,
                udmDeliveryLaycanEndDate: this.updateSingleLineUdmDeliveryLaycanEndDate ? this.updateSingleLineUdmDeliveryLaycanEndDate : undefined,
                headerToItemNav: [this.headerToItemNavFormation(event)],
                udmSkipTswPublish: updatedComments?.length > 0 ? true : undefined,
            }
        }
    }

    headerToItemNavFormation(event: CellValueChangedEvent) {
        let updateLineItem = (this.updateSingleLineContract || this.updateSingleLineContract === ' ') ? this.updateSingleLineContract : undefined;
        return {
            itemId: event?.data?.itemId,
            textLine: `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
            scheduledDate: this.updateSingleLineScheduledDate ? this.updateSingleLineScheduledDate : undefined,
            scheduledQty: this.updateSingleLineScheduledQty ? this.updateSingleLineScheduledQty : undefined,
            nominationReferenceDocument: (this.updateSingleLineSapContract || this.updateSingleLineSapContract === ' ') ? this.updateSingleLineSapContract : undefined,
            nominationReferenceDocumentItem: this.updateSingleLineSapContract === ' ' ? " " : updateLineItem,
            udmComments: this.updateSingleLineComments ? this.updateSingleLineComments : undefined,
            laycanStartDate: this.updateSingleLineLaycanStartDate ? this.updateSingleLineLaycanStartDate : undefined,
            laycanEndDate: this.updateSingleLineLaycanEndDate ? this.updateSingleLineLaycanEndDate : undefined,
            udmItemStatus: this.udmItemStatus(event),
        }
    }

    udmItemStatus(event: CellValueChangedEvent) {
        if (!event?.data?.status) {
            return undefined;
        } else if (event?.data?.status?.toLowerCase() === Nom.PUBLISHED && event?.colDef?.field === "comment") {
            return Nom.PUBLISHED;
        } else if (event?.data?.status?.toLowerCase() === Nom.PUBLISHED && event?.colDef?.field !== "comment") {
            return Nom.UNPUBLISHED;
        } else {
            return event?.data?.status;
        }
    }

    onCellValueChanged(event: CellValueChangedEvent) {
        let lineItem;
        this.cellEditedDetails = event;
        this.payloadExistanceCheck(event);
        this.dataOnEditingSpecificFields(event, lineItem);
        this.payloadFormation(event);
        if (!(this.masterGridUpdatedPayload.map(el => el.id).includes(event?.data?.id))) {
            this.masterGridUpdatedPayload.push(this.masterValuePayload)
        } else {
            const rowIndex = this.masterGridUpdatedPayload.map(el => el.id).indexOf(event?.data?.id);
            this.masterGridUpdatedPayload[rowIndex] = this.masterValuePayload;
        }
        this.updatedValue = this.masterGridUpdatedPayload;
        this.nominationService.enableNominationsSaveButton$.next(true);
    }

    refineUpdatedValues() {
        let tempArray: any = [];
        this.updatedValue.forEach(dt => {
            tempArray = dt.headerToItemNav?.filter((el: any) => (el?.nominationReferenceDocument !== undefined) || (el?.nominationReferenceDocumentItem !== undefined)
                || (el?.udmComments !== undefined) || (el?.laycanStartDate !== undefined) || (el?.scheduledQty !== undefined) || (el?.laycanEndDate !== undefined) ||
                (el?.scheduledDate !== undefined))
            dt.headerToItemNav = tempArray;
            if (dt?.headerToItemNav?.length === 0) {
                dt.headerToItemNav = undefined;
            }
        });
        this.updatedValue?.forEach(dt => {
            dt?.headerToItemNav?.map((el: any) => el.detailType = undefined);
        });
    }

    save() {
        this.refineUpdatedValues();
        if (this.updatedValue?.length > 0) {
            this.store.dispatch(updateNomination({ payload: this.updatedValue, publishOrDeleteNominationApiResponse: {} }));
            setTimeout(() => {
                this.getNominations();
                this.updateNomination$.subscribe(response => {
                    if (response?.success) {
                        this.toaterService.setToastNotification({
                            show: true,
                            type: 'success',
                            msg: 'Nomination has been updated',
                        });
                        this.updateRowDataAfterSave();
                    } else if (response.success === false) {
                        this.toaterService.setToastNotification({ show: true, type: "error", msg: response.result?.length > 0 ? response.result[0] : null });
                    }
                    this.masterTestData = [];
                    this.detailTestData = [];
                    this.getUpdatedPayload = [];
                    this.masterGridUpdatedPayload = [];
                    this.detailGridUpdatedPayload = [];
                    this.updatedValue = [];
                    this.editedIds = [];
                    this.masterUpdateRowIndex = null;
                    this.detailUpdatedRowIndex = null;
                    this.editNominationNoInner = false;
                    this.updateNominationNo = null;
                    this.updateSingleLineComments = null;
                    this.updateSingleLineContract = null;
                    this.updateSingleLineNominationNo = null;
                    this.updateSingleLineSapContract = null;
                    this.updateSingleLineScheduledDate = null;
                    this.updateSingleLineScheduledQty = null;
                    this.newScheduledQty = null;
                    this.newScheduledDate = null;
                    this.updatedScheduledQty = null;
                    this.updateSingleLineLaycanEndDate = null;
                    this.updateSingleLineLaycanStartDate = null;
                    this.updateSingleLineUdmDeliveryLaycanEndDate = null;
                    this.updateSingleLineUdmDeliveryLaycanStartDate = null;
                    this.nominationService.enableNominationsSaveButton$.next(false);
                });
            }, 3000);
        }
    }

    updateRowDataAfterSave() {
        this.nominationList$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.rowData = JSON.parse(JSON.stringify(data));
            this.rowData.forEach((el: any) => {
                el.originDate = this.dateCellRenderer(el?.originDate);
                el.destinationDate = this.dateCellRenderer(el?.destinationDate);
                el.udmDeliveryLaycanStartDate = this.dateCellRenderer(el?.udmDeliveryLaycanStartDate);
                el.udmDeliveryLaycanEndDate = this.dateCellRenderer(el?.udmDeliveryLaycanEndDate);
                el.udmLordLaycanStartDate = this.dateCellRenderer(el?.udmLordLaycanStartDate);
                el.udmLordLaycanEndDate = this.dateCellRenderer(el?.udmLordLaycanEndDate);
                if (el?.detailType?.length > 0) {
                    const detailTypeValue = el?.detailType;
                    detailTypeValue.forEach((val: any) => {
                        val.originDate = this.dateCellRenderer(val?.originDate);
                        val.destinationDate = this.dateCellRenderer(val?.destinationDate);
                        val.udmDeliveryLaycanStartDate = this.dateCellRenderer(val?.udmDeliveryLaycanStartDate);
                        val.udmDeliveryLaycanEndDate = this.dateCellRenderer(val?.udmDeliveryLaycanEndDate);
                        val.udmLordLaycanStartDate = this.dateCellRenderer(val?.udmLordLaycanStartDate);
                        val.udmLordLaycanEndDate = this.dateCellRenderer(val?.udmLordLaycanEndDate);
                    });
                }
            });
        });
    }


    public detailCellRendererParams: any = {
        detailGridOptions: {
            stopEditingWhenCellsLoseFocus: true,
            enableRangeSelection: true,
            columnDefs:
                [
                    {
                        field: '',
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 50,
                        maxWidth: 50,
                        resizable: false,
                        sortable: false,
                        lockVisible: true,
                        suppressMovable: false,
                        enableMenu: true
                    },
                    {
                        field: 'status',
                        headerName: 'status',
                        cellRenderer: ChipRendererComponent,
                    },
                    {
                        field: 'nominationNo',
                        headerName: 'nomno.',
                        cellRenderer: "agGroupCellRenderer",
                        editable: true,
                        cellEditorParams: { maxLength: 16 },
                    },
                    {
                        field: 'nominationKey',
                        headerName: 'nomkey',
                    },
                    {
                        field: 'nominationKeyItem',
                        headerName: 'lineitem',
                    },
                    {
                        field: 'mot',
                        headerName: 'mot'
                    },
                    {
                        field: 'vesselName',
                        headerName: 'vessel name'
                    },
                    {
                        field: 'detailType',
                        headerName: 'detail type'
                    },
                    {
                        field: 'nominationType',
                        headerName: 'nom type'
                    },
                    {
                        field: 'referenceDocumentIndicator',
                        headerName: 'ref doc id'
                    },
                    {
                        field: 'loadLocation',
                        headerName: 'load location',
                        editable: params => params?.data?.mot === "TankTransfer",
                    },
                    {
                        field: 'counterPart',
                        headerName: 'counterpart'
                    },
                    {
                        field: 'originDate',
                        headerName: 'origin date',
                        editable: params => params?.data?.mot === "TankTransfer",
                        cellEditor: DatePickerComponent,
                    },
                    {
                        field: 'udmLordLaycanStartDate',
                        headerName: 'Load Laycan Start Date',
                        editable: params => params?.data?.mot === "TankTransfer",
                        cellEditor: DatePickerComponent,

                    },
                    {
                        field: 'udmLordLaycanEndDate',
                        headerName: 'Load Laycan End Date',
                        editable: params => params?.data?.mot === "TankTransfer",
                        cellEditor: DatePickerComponent,

                    },
                    {
                        field: 'destinationDate',
                        headerName: 'scheduled date',
                        editable: true,
                        cellEditor: DatePickerComponent
                    },
                    {
                        field: 'sapContract',
                        headerName: 'sap contract',
                        editable: true,
                        cellEditor: AutoCompleteComponent,
                        cellEditorParams: () => {
                            return {
                                propertyRendered: 'sapContractNumber',
                                showedColumn: 'sapContractNumber',
                                rowData: this.sapContractDetails,
                                columnDefs: [
                                    {
                                        filter: 'agTextColumnFilter',
                                        field: 'sapContractNumber',
                                        valueFormatter: (event: any) => {
                                            let data = event.data;
                                            return data.sapContractNumber;
                                        },
                                    },
                                    {
                                        field: 'sapContractNumber',
                                        valueGetter: (params: any) => console.log(params),
                                        filter: 'agTextColumnFilter',
                                        hide: true,
                                    },
                                ],
                                popup: true,
                            }
                        }
                    },
                    {
                        field: 'contractLineItem',
                        headerName: 'contract line number',
                        editable: true,
                        cellEditor: AutoCompleteComponent,
                        cellEditorParams: (params: any) => ({
                            propertyRendered: 'contractLineItem',
                            showedColumn: 'contractLineItem',
                            rowData: this.setContractLineNoBasedOnSapContractNo(params.data),
                            columnDefs: [
                                {
                                    filter: 'agTextColumnFilter',
                                    field: 'contractLineItem',
                                    valueFormatter: (event: any) => {
                                        let data = event.data;
                                        return data.Name;
                                    },
                                },
                                {
                                    field: 'contractLineItem',
                                    filter: 'agTextColumnFilter',
                                    hide: true,
                                },
                            ],
                            popup: true,
                        })
                    },
                    {
                        field: 'NominationItemStatus',
                        headerName: 'TSW Status',
                    },
                    {
                        field: 'udmDeliveryLaycanStartDate',
                        headerName: 'Delivery Laycan Start Date',
                        editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
                        cellEditor: DatePickerComponent,

                    },
                    {
                        field: 'udmDeliveryLaycanEndDate',
                        headerName: 'Delivery Laycan End Date',
                        editable: params => { return (params?.data?.detailType?.length === 0) && (params?.data?.mot === "TankTransfer") },
                        cellEditor: DatePickerComponent,

                    },
                    {
                        field: 'product',
                        headerName: 'product'
                    },
                    {
                        field: 'scheduledQty',
                        headerName: 'volume',
                        editable: true,
                        cellEditor: NumberCellEditorComponent
                    },
                    {
                        field: 'destinationLocation',
                        headerName: 'destination location'
                    },
                    {
                        field: 'comment',
                        headerName: 'comments',
                        editable: true,
                    },

                ],
            rowSelection: 'multiple',
            onSelectionChanged: (event: SelectionChangedEvent) => {
                const test = [];
                this.selectedRows = event.api.getSelectedRows();
                const entireNom = JSON.parse(JSON.stringify(this.rowData[this.mainGridIndex]));
                entireNom.detailType = this.selectedRows;
                test.push(entireNom);
                this.selectedDetailRecords = test;
                if (this.selectedRows.length > 0) {
                    this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: true });
                } else {
                    this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
                }
            },
            onCellValueChanged: (event: CellValueChangedEvent) => {
                let lineItem;
                this.cellEditedDetails = event;
                this.checkDataExistanceLineItemInPayload(event);
                if (event.colDef.field === 'destinationDate' && !this.newScheduledQty) {
                    this.changeInDestinationDateLineItem(event);
                }
                if ((this.detailGridUpdatedPayload?.length > 0) && (event?.colDef?.field === "scheduledQty")) {
                    this.changeInScheduledQtyLineItem(event);
                } else if (!this.newScheduledDate && event?.colDef?.field === "scheduledQty") {
                    this.changeInScheduledDateNScheduledQtyLineItem(event);
                }
                if (event?.colDef?.field === "destinationDate" && this.detailGridUpdatedPayload?.length > 0) {
                    this.changeInScheduledQtyNScheduledDateLineItem(event);
                }
                if (!!this.newScheduledDate && !!this.newScheduledQty) {
                    this.formingHeaderToItemNavLineItem(event, lineItem);
                } else {
                    this.formingHeaderToItemNavItemNewLineItem(event, lineItem);
                }
                this.changeInHeadersPayloadInLineItem(event);
                this.payloadFormationForChangeLineItem(event);
                if (!(this.updatedValue?.map(el => el?.id)?.includes(this.rowData[this.mainGridIndex]?.id))) {
                    this.updatedValue.push(this.detailGridHeadersPayload);
                } else {
                    const rowIndex = this.updatedValue.map(el => el.id).indexOf(this.currentlyActiveLineRow?.id);  // need to check here itemid or id
                    this.updatedValue[rowIndex] = this.detailGridHeadersPayload;
                }
                this.nominationService.enableNominationsSaveButton$.next(true);
            },
        },
        getDetailRowData: (params: any) => {

            params.successCallback(params.data.detailType);
            this.currentlyActiveLineRow = params?.data;
            this.mainGridIndex = this.rowData.indexOf(params.data);

        },
    } as IDetailCellRendererParams<any>;
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    setContractLineNoBasedOnSapContractNo(data: any) {
        this.contractLineNumberList = [];
        let lineItem = this.sapContractDetails.find((dt: any) => {
            if (dt.sapContractNumber === data.sapContract) {
                return dt.lineItemList;
            }
        });
        lineItem?.lineItemList?.forEach((value: any) => {
            this.contractLineNumberList.push({ contractLineItem: this.utilities.removeLeadingZeros(value) })
        });
        return this.contractLineNumberList;
    }

    changeInDestinationDateLineItem(event: CellValueChangedEvent) {
        this.openModal = true;
        this.previousDestinationDate = event?.oldValue;
        this.editNominationNoInner = true;
        this.newScheduledDate = event?.value;
        this.rowData[this.mainGridIndex]?.detailType.forEach((el: any) => {
            const destDate = this.datePipe.transform(event?.data?.destinationDate, "yyyMMdd");
            const lineValueScheduledDatePayload = {
                itemId: el?.itemId,
                textLine: `{Author:${el?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                scheduledDate: destDate,
                detailType: el?.detailType,
                udmItemStatus: this.publishUpdatingNomination(event),
            };
            this.detailGridUpdatedPayload?.push(lineValueScheduledDatePayload);
        });
    }

    changeInScheduledQtyLineItem(event: CellValueChangedEvent) {
        this.openModal = true;
        this.previousScheduledQty = event?.oldValue;
        this.newScheduledQty = event?.value;
        if (!(this.detailGridUpdatedPayload.map(el => el.itemId).includes(event?.data?.itemId))) {
            this.checkScheduledQtyPayload(event);
        } else {
            for (let i = 0, j = 0; i < this.detailGridUpdatedPayload.length && j < this.currentlyActiveLineRow?.detailType.length; i++, j++) {
                this.editNominationNoInner = true;
                if (this.currentlyActiveLineRow?.detailType[j]?.detailType === event?.data?.detailType && !!event?.data?.detailType) {
                    this.detailGridUpdatedPayload[i]["scheduledQty"] = event?.data?.scheduledQty / 1000;
                    this.detailGridUpdatedPayload[i]["detailType"] = event?.data?.detailType;
                }
            }
        }
    }

    checkScheduledQtyPayload(event: CellValueChangedEvent) {
        this.currentlyActiveLineRow?.detailType?.forEach((dt: any) => {
            if (dt?.detailType === event?.data?.detailType && !!event?.data?.detailType) {
                this.detailGridUpdatedPayload?.push({
                    itemId: dt?.itemId,
                    textLine: `{Author:${dt?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                    scheduledQty: event?.data?.scheduledQty / 1000,
                    detailType: dt?.detailType,
                    udmItemStatus: this.publishUpdatingNomination(event),
                });
            }
        });
    }

    changeInScheduledDateNScheduledQtyLineItem(event: CellValueChangedEvent) {
        this.openModal = true;
        this.previousScheduledQty = event?.oldValue;
        this.newScheduledQty = event?.value;
        this.rowData[this.mainGridIndex]?.detailType.forEach((el: any) => {
            if (el?.detailType === event?.data?.detailType && !!event?.data?.detailType) {
                this.editNominationNoInner = true;
                const lineValueScheduledQtyPayload = {
                    itemId: el?.itemId,
                    textLine: `{Author:${el?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                    scheduledQty: event?.data?.scheduledQty / 1000,
                    detailType: el?.detailType,
                    udmItemStatus: this.publishUpdatingNomination(event),
                };
                this.detailGridUpdatedPayload?.push(lineValueScheduledQtyPayload);
            }
        });
    }

    changeInScheduledQtyNScheduledDateLineItem(event: CellValueChangedEvent) {
        this.openModal = true;
        this.previousDestinationDate = event?.oldValue;
        this.editNominationNoInner = true;
        this.newScheduledDate = event?.value;
        const destDate = this.datePipe.transform(event?.data?.destinationDate, "yyyMMdd");
        this.rowData[this.mainGridIndex]?.detailType.forEach((el: any) => {
            this.detailGridUpdatedPayload.forEach(dt => this.detailEditedIds.push(dt.itemId));
            if (this.detailGridUpdatedPayload.map(data => data.itemId).includes(el.itemId)) {
                this.detailGridUpdatedPayload.forEach(dt => dt["scheduledDate"] = destDate);
            } else {
                const lineValueScheduledDatePayload = {
                    itemId: el?.itemId,
                    textLine: `{Author:${el?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                    scheduledDate: destDate,
                    udmItemStatus: this.publishUpdatingNomination(event),
                };
                this.detailGridUpdatedPayload?.push(lineValueScheduledDatePayload);
            }
        });
    }

    changeInHeadersPayloadInLineItem(event: CellValueChangedEvent) {
        if (event.colDef.field === "nominationNo") {
            this.updateNominationNo = event?.value;
        }
        if (event.colDef.field === "udmDeliveryLaycanEndDate") {
            this.udmDeliveryLaycanEndDate = event?.value
        }
        if (event.colDef.field === "udmDeliveryLaycanStartDate") {
            this.udmDeliveryLaycanStartDate = event?.value
        }
    }

    checkDataExistanceLineItemInPayload(event: CellValueChangedEvent) {
        if (this.differentHeaderId !== this.currentlyActiveLineRow.id) {
            this.detailGridUpdatedPayload = [];
        }
        this.differentHeaderId = JSON.parse(JSON.stringify(this.currentlyActiveLineRow.id));
        if (this.updatedValue.map(el => el.id).includes(this.differentHeaderId)) {
            const headerToItemNav = this.detailGridHeadersPayload?.headerToItemNav
            this.detailGridUpdatedPayload = this.detailGridHeadersPayload?.headerToItemNav ? [...headerToItemNav] : [];
        }
    }

    sapContractLineItemExistingChange(event: CellValueChangedEvent, lineItem: any) {
        if (event.colDef.field === "sapContract") {
            lineItem = this.sapContractDetails.find((dt: any) => {
                if (dt.sapContractNumber === event.value) {
                    return dt.lineItemList;
                }
            });
            lineItem?.lineItemList?.forEach((value: any) => {
                this.contractLineNumberList.push({ contractLineItem: this.utilities.removeLeadingZeros(value) })
            });
            this.detailGridUpdatedPayload?.forEach(dt => {
                if (dt?.itemId === event?.data?.itemId) {
                    dt["detailType"] = event?.data?.detailType;
                    dt["nominationReferenceDocument"] = event?.data?.sapContract;
                }
            });
        }
    }

    contractLineItemLineValueExistingChange(event: CellValueChangedEvent) {
        if (event?.colDef?.field === "contractLineItem") {
            this.detailGridUpdatedPayload?.forEach(dt => {
                if (dt?.itemId === event?.data?.itemId) {
                    dt["detailType"] = event?.data?.detailType;
                    dt["nominationReferenceDocumentItem"] = event?.data?.contractLineItem;
                }
            });
        }
    }

    commentLineItemExistingChange(event: CellValueChangedEvent) {
        if (event?.colDef?.field === "comment") {
            this.detailGridUpdatedPayload?.forEach(dt => {
                if (dt?.itemId === event?.data?.itemId) {
                    dt["detailType"] = event?.data?.detailType;
                    dt["udmComments"] = event?.data?.comment;
                }
            });
        }
    }

    laycanStartDateLineItemExistingChange(event: CellValueChangedEvent) {
        if (event?.colDef?.field === "laycanStartDate") {
            this.detailGridUpdatedPayload?.forEach(dt => {
                if (dt?.itemId === event?.data?.itemId) {
                    dt["detailType"] = event?.data?.detailType;
                    dt["laycanStartDate"] = event?.data?.laycanStartDate;
                }
            });
        }
    }

    laycanEndDateLineItemExistingChange(event: CellValueChangedEvent) {
        if (event?.colDef?.field === "laycanEndDate") {
            this.detailGridUpdatedPayload?.forEach(dt => {
                if (dt?.itemId === event?.data?.laycanEndDate) {
                    dt["detailType"] = event?.data?.detailType;
                    dt["laycanEndDate"] = event?.data?.laycanEndDate;
                }
            });
        }
    }

    formingHeaderToItemNavLineItem(event: CellValueChangedEvent, lineItem: any) {
        if (event?.colDef?.field !== "destinationDate" && event?.colDef?.field !== "scheduledQty" && event?.colDef?.field !== "nominationNo") {
            this.editNominationNoInner = true;
            this.sapContractLineItemExistingChange(event, lineItem);
            this.contractLineItemLineValueExistingChange(event);
            this.commentLineItemExistingChange(event);
            this.laycanStartDateLineItemExistingChange(event);
            this.laycanEndDateLineItemExistingChange(event);
        }
    }

    sapContractLineItemNewChange(event: CellValueChangedEvent, lineItem: any, lineValueOtherVariablePayload: any) {
        if (event.colDef.field === "sapContract") {
            lineItem = this.sapContractDetails.find((dt: any) => {
                if (dt.sapContractNumber === event.value) {
                    return dt.lineItemList;
                }
            });
            lineItem?.lineItemList?.forEach((value: any) => {
                this.contractLineNumberList.push({ contractLineItem: this.utilities.removeLeadingZeros(value) })
            });
            lineValueOtherVariablePayload["itemId"] = event?.data?.itemId;
            lineValueOtherVariablePayload["textLine"] = `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`;
            lineValueOtherVariablePayload["udmItemStatus"] = this.publishUpdatingNomination(event);
            lineValueOtherVariablePayload["nominationReferenceDocument"] = event?.data?.sapContract;
            lineValueOtherVariablePayload["nominationReferenceDocumentItem"] = event?.data?.sapContract === ' ' ? ' ' : undefined;
            lineValueOtherVariablePayload["detailType"] = event?.data?.detailType;
        }
    }

    contractLineItemLineValueNewChange(event: CellValueChangedEvent, lineValueOtherVariablePayload: any) {
        if (event?.colDef?.field === "contractLineItem") {
            lineValueOtherVariablePayload["itemId"] = event?.data?.itemId;
            lineValueOtherVariablePayload["textLine"] = `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`;
            lineValueOtherVariablePayload["udmItemStatus"] = this.publishUpdatingNomination(event);
                lineValueOtherVariablePayload["nominationReferenceDocumentItem"] = event?.data?.contractLineItem;
            lineValueOtherVariablePayload["detailType"] = event?.data?.detailType;
        }
    }

    commentLineItemNewChange(event: CellValueChangedEvent, lineValueOtherVariablePayload: any) {
        if (event?.colDef?.field === "comment") {
            lineValueOtherVariablePayload["itemId"] = event?.data?.itemId;
            lineValueOtherVariablePayload["textLine"] = `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`;
            lineValueOtherVariablePayload["udmItemStatus"] = this.publishUpdatingNomination(event);
            lineValueOtherVariablePayload["udmComments"] = event?.data?.comment;
            lineValueOtherVariablePayload["detailType"] = event?.data?.detailType;
        }
    }

    laycanStartDateLineItemNewChange(event: CellValueChangedEvent, lineValueOtherVariablePayload: any) {
        if (event?.colDef?.field === "laycanStartDate") {
            lineValueOtherVariablePayload["itemId"] = event?.data?.itemId;
            lineValueOtherVariablePayload["textLine"] = `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`;
            lineValueOtherVariablePayload["udmItemStatus"] = this.publishUpdatingNomination(event);
            lineValueOtherVariablePayload["laycanStartDate"] = event?.data?.laycanStartDate;
            lineValueOtherVariablePayload["detailType"] = event?.data?.detailType;
        }
    }

    laycanEndDateLineItemNewChange(event: CellValueChangedEvent, lineValueOtherVariablePayload: any) {
        if (event?.colDef?.field === "laycanEndDate") {
            lineValueOtherVariablePayload["itemId"] = event?.data?.itemId;
            lineValueOtherVariablePayload["textLine"] = `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`;
            lineValueOtherVariablePayload["udmItemStatus"] = this.publishUpdatingNomination(event);
            lineValueOtherVariablePayload["laycanEndDate"] = event?.data?.laycanEndDate;
            lineValueOtherVariablePayload["detailType"] = event?.data?.detailType;
        }
    }

    existingPayloadUpdationLineItem(event: CellValueChangedEvent) {
        const updatedIndex = this.detailGridUpdatedPayload.map(el => el.itemId).indexOf(event?.data?.itemId);
        if (updatedIndex >= 0) {
            if (event?.colDef?.field === "sapContract") {
                this.detailGridUpdatedPayload[updatedIndex]["nominationReferenceDocument"] = event?.value;
            }
            if (event?.colDef?.field === "contractLineItem") {
                this.detailGridUpdatedPayload[updatedIndex]["nominationReferenceDocumentItem"] = event?.value;
            }
            if (event?.colDef?.field === "comment") {
                this.detailGridUpdatedPayload[updatedIndex]["udmComments"] = event?.value;
                this.detailGridUpdatedPayload[updatedIndex]["udmSkipTswPublish"] = true;
            }
            if (event?.colDef?.field === "laycanStartDate" || event?.colDef?.field === "laycanEndDate") {
                this.detailGridUpdatedPayload[updatedIndex][`${event?.colDef?.field}`] = event?.value;
            }
        }
    }

    formingHeaderToItemNavItemNewLineItem(event: CellValueChangedEvent, lineItem: any) {
        let lineValueOtherVariablePayload: any = {};
        this.sapContractLineItemNewChange(event, lineItem, lineValueOtherVariablePayload);
        this.contractLineItemLineValueNewChange(event, lineValueOtherVariablePayload);
        this.commentLineItemNewChange(event, lineValueOtherVariablePayload);
        this.laycanStartDateLineItemNewChange(event, lineValueOtherVariablePayload);
        this.laycanEndDateLineItemNewChange(event, lineValueOtherVariablePayload);
        if ((!(this.detailGridUpdatedPayload?.map(el => el.itemId).includes(event?.data?.itemId)) && !!(lineValueOtherVariablePayload.itemId))) {
            this.detailGridUpdatedPayload?.push(lineValueOtherVariablePayload);
        } else {
            this.existingPayloadUpdationLineItem(event);
        }
    }

    payloadFormationForChangeLineItem(event: CellValueChangedEvent) {
        this.headerToItemNavDetail = this.headerToItemNavOnCondition(event);
        const updatedComments = this.headerToItemNavDetail?.filter((el: any) => !!el.udmComments);
        this.detailGridHeadersPayload = {
            id: this.rowData[this.mainGridIndex].id,
            nominationNo: this.updateNominationNo ? this.updateNominationNo : undefined,
            udmDeliveryLaycanStartDate: this.udmDeliveryLaycanStartDate ? this.udmDeliveryLaycanStartDate : undefined,
            udmDeliveryLaycanEndDate: this.udmDeliveryLaycanEndDate ? this.udmDeliveryLaycanEndDate : undefined,
            sys_status: this.sysStatusFinalisation(event),
            headerToItemNav: this.headerToItemNavOnCondition(event),
            udmSkipTswPublish: updatedComments?.length > 0 ? true : undefined,
        }
    }

    sysStatusFinalisation(event: any) {
        if (this.currentlyActiveLineRow?.status?.toLowerCase() === Nom.PUBLISHED && event?.colDef?.field === "comment") {
            return Nom.PUBLISHED;
        } else if (this.currentlyActiveLineRow?.status?.toLowerCase() === Nom.PUBLISHED) {
            return Nom.UNPUBLISHED;
        } else {
            return this.currentlyActiveLineRow.status;
        }
    }

    headerToItemNavOnCondition(event: CellValueChangedEvent) {
        if (this.detailGridUpdatedPayload?.length > 0) {
            return [...this.detailGridUpdatedPayload];
        } else if (this.newScheduledQty && this.newScheduledDate) {
            return [{
                itemId: event?.data?.itemId,
                textLine: `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                scheduledQty: event?.data?.scheduledQty / 1000,
                scheduledDate: this.datePipe.transform(event?.data?.destinationDate, "yyyMMdd"),
                udmItemStatus: this.publishUpdatingNomination(event),
            }];
        } else if (this.newScheduledQty) {
            return [{
                itemId: event?.data?.itemId,
                textLine: `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                scheduledQty: event?.data?.scheduledQty / 1000,
                udmItemStatus: this.publishUpdatingNomination(event),
            }];
        } else if (this.newScheduledDate) {
            return [{
                itemId: event?.data?.itemId,
                textLine: `{Author:${event?.data?.createdBy},MdfBy:${this.userDetials},SS:UDM}`,
                scheduledDate: this.datePipe.transform(event?.data?.destinationDate, "yyyMMdd"),
                udmItemStatus: this.publishUpdatingNomination(event),
            }];
        } else {
            return undefined;
        }
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };

    toggleCreateTerminalNominationsModal(): void {
        const modal = { open: !this.terminalNominationModal.open, modal: 'createTerminalNomination' }
        this.terminalNominationModal = Object.assign({}, modal);
    }

    toggleNominationConfirmationModal(): void {
        const modal = { open: !this.openNominationConfirmationModal.open, modal: 'openNominationConfirmationModal' }
        this.openNominationConfirmationModal = Object.assign({}, modal);
    }

    toggleEthanolCreateNominationModal(): void {
        this.nominationEthanolViewComponent.createNominationModal();
    }

    createNominationWithReference() {
        this.createTerminalNominationComponent.createNominationWithReference(this.selectedRows);
        const modal = { open: !this.terminalNominationModal.open, modal: 'createTerminalNominationWithReference' };
        this.terminalNominationModal = Object.assign({}, modal);
    }


    editEthanolNomination(data: any) {
        this.nominationEthanolViewComponent.editNominationLatest(data);
    }

    createEthanolNominationWithReference(data: any) {
        this.nominationEthanolViewComponent.createNominationWithReference(data);
    }

    closeTerminalNominationModal(val: any) {
        if (val && val.success === true) {
            this.getNominations();
            this.gridApi.deselectAll();
        }
        const modal = { open: false, modal: 'createTerminalNomination' }
        this.terminalNominationModal = Object.assign({}, modal);
    }

    closeNominationConfirmModal(): void {
        const modal = { open: false, modal: 'openNominationConfirmationModal' }
        this.openNominationConfirmationModal = Object.assign({}, modal);
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.selectedRows = event.api.getSelectedRows();
        this.selectedMasterRecords = this.selectedRows;
        if (this.selectedRows.length > 0) {
            this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: true });
        } else {
            this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
        }
    }

    closeConfirmModal(data: any) {
        this.rowData[this.mainGridIndex]?.detailType?.map((dt: any, i: number) => {
            if (dt.itemId === this.cellEditedDetails.data.itemId && i === this.cellEditedDetails.rowIndex) {
                if (this.cellEditedDetails.colDef.field === 'destinationDate') { dt.destinationDate = this.previousDestinationDate };
                if (this.cellEditedDetails.colDef.field === 'scheduledQty') { dt.scheduledQty = this.previousScheduledQty };
                return dt;
            } else {
                return dt;
            }
        });
        this.checkForDestinationDateReset();
        this.checkForScheduledQtyReset();
        this.gridApi.setRowData(this.rowData);
        this.gridApi.redrawRows();
        this.openModal = false;
    }

    checkForDestinationDateReset() {
        if (this.cellEditedDetails?.colDef?.field === "destinationDate") {
            this.updatedValue?.forEach(dt => {
                if (dt?.id === this.currentlyActiveLineRow?.id) {
                    dt.headerToItemNav.map((el: any) => el.scheduledDate = undefined);
                }
            });
        }
    }

    checkForScheduledQtyReset() {
        if (this.cellEditedDetails?.colDef?.field === "scheduledQty") {
            this.updatedValue?.forEach(dt => {
                if (dt?.id === this.currentlyActiveLineRow?.id) {
                    dt?.headerToItemNav.map((el: any) => {
                        if (el?.detailType === this.cellEditedDetails?.detailType && !!el?.detailType) {
                            el.scheduledQty = undefined;
                        }
                    });
                }
            });
        }
    }

    confirmModalPopup(data: any) {
        this.openModal = false;
    }

    saveEthanolNominations() {
        this.nominationEthanolViewComponent.save();
    }

    savePipelineNominations() {
        this.nominationPipelineViewComponent.save()
    }
    saveRefineryNominations() {
        this.nominationRefineryViewComponent.save()
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.utilities.clearDataToChild();
        this.utilities.unsubscribeDataToChild();
    }
}

