import { Component, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-restricted-input-length-cell-editor',
  templateUrl: './restricted-input-length-cell-editor.component.html',
  styleUrls: ['./restricted-input-length-cell-editor.component.scss']
})
export class RestrictedInputLengthCellEditorComponent implements ICellEditorAngularComp {

  @ViewChild("userInput", {static: true}) userInput: any;
  value: any = "";

  agInit(params: ICellEditorParams<any, any, any>): void {
    this.value = params.value;
  }
  getValue() {
    return this.value;
  }
  isPopup?(): boolean {
    return false;
  }
  afterGuiAttached?(): void {
    return this.userInput.nativeElement.focus();
  }

  onKeyPressEvent(e: KeyboardEvent) {
    if (this.value?.length >= 16 && e.key !== "Backspace") {
      e.preventDefault();
    }
  }

}
