import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/market-elasticity.reducer';
import * as PSD from '../reducer/market-elasticity.reducer';

export const selectMktData = createFeatureSelector<State>('marketelasticity');

export const selectPSDFilterResponse = createSelector(
    selectMktData,
    PSD.getPSDFilterResponse
);

export const selectPSDViewResponse = createSelector(
    selectMktData,
    PSD.getPSDViewResponse    
);

export const selectDesk = createSelector(
    selectMktData,
    PSD.getSelectedDesk
);

