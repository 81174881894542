<div class="ethanol-more-menu">
<span class="text-truncate" [ngClass]="[isNomPresent ? rowStatus : '']">{{ value }}</span>
<div class="more-menu-dropdown">
    <button *ngIf="moremenuIconShow" class="ellipses-icon"  [ngClass]="[compactView ? 'compact':'']"  (click)="moremwnu()"></button>
    <div *ngIf="!isOpen" class="dropdown-content"[ngClass]="[compactView ? 'compact-dropdown':'', rowIndex < 8 ? '' : 'place-top']">
          <a class="dropdown-item ethanol-create-nomination" *ngIf="isDraft" (click)="onCreateDraftNomination()">create nomination</a>
    <a *ngIf="rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published'" class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination from
            reference</a>
        <a *ngIf="rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published'" class="dropdown-item goToNominations"
         (click)="goToNominations()">go to nominations</a>
        <a class="dropdown-item ethanol-seeInNominationPannel" (click)="seeInNominationPannel()">see in nomination panel</a>
        <a class="dropdown-item edit-nomination" *ngIf="rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published'" (click)="onCreateDraftNomination('edit')">edit nomination</a>
        <a class="dropdown-item ethanol-delete" (click)="delete()">delete</a>
        <a *ngIf="!isDraft && isCargo && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)"
            class="dropdown-item contract-balancinf"
            [ngClass]="{'disableVesselOption': disableVesselOption(vesselScheduleUpdateData)}"
            (click)="vessalScheduleUpdate()">vessel schedule update</a>
        </div>
    </div>
</div>