import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { activityChangeLine } from 'src/app/shared/interface/activity-tracker';
import { activitySelector } from '../activity-tracker.component';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit, OnChanges {

  @Input() tableTitle: string = '';
  @Input() selectedOption: activitySelector = {displayName: '', apiValue: ''};
  @Input() rowData: activityChangeLine[] = [];
  displayedColumns!: string[];
  constructor(private dateService: DateService, ) { }

  ngOnInit(): void { 
    this.displayedColumns = ['New', 'Old', 'Changed By', 'Changed On'];
  }

  ngOnChanges(){
    this.rowData.forEach(line => {
      line.changedAt = this.dateService.getStandardDateFromISOString(line.changedAt) +" " + this.dateService.getLocalTimeFromISO(line.changedAt);
      line.newValue =  this.isDate(line.newValue) ? this.dateService.getStandardDateFromISOString(line.newValue) : line.newValue;
      line.oldValue = this.isDate(line.oldValue) ? this.dateService.getStandardDateFromISOString(line.oldValue) : line.oldValue;
      
      if(line.oldValue == null || line.oldValue.includes("null")){
        line.oldValue = '---';
      }

      if(line.newValue == null || line.newValue.includes("null")){
        line.newValue = '---';
      }

      if(line.changedBy == null || line.changedBy.includes("null")){
        line.changedBy = '---';
      }
    });
  }

  isDate(dateString: string): boolean {
    const dateStr: any = this.dateService.getStandardDateFromISOString(dateString);
    const date = new Date(dateStr);
    return !isNaN(date.getTime());
  }
}
