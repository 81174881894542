<div class="edit-modal-popup">
    <span class="error-text" *ngIf="showFormWarning">Atleast one filter or personalization option must be selected for a view to be saved</span>

    <div class="edit-custom-view-form">
        <form [formGroup]="editCustomViewsForm" class="branded">
            <div class="form-group">
                <span style="color: #97002E">*</span><label for="custom-view-name" class="lable-text">name of your custom view</label>
                <input type="text" formControlName="viewName" id="custom-view-name" class="form-control custom">
            </div>
            <div>
                <span style="color: #97002E">*</span><label for="" class="create-title-label lable-text">select the filter options
                    to keep</label>
            </div>
            <div class="filters-list" *ngIf="this.path$.value !== '/bcpDashboard'">
                    <div class="form-group col-3 filter-save-option" *ngFor="let fhName of filterHeaderName">
                    <label for="" class="font-gotham-narrow-bold label filter-list-label">{{ fhName === 'transportSystem' ? 'transport system' : fhName }}</label>
                    <label class="switch">
                        <input type="checkbox" checked
                            [formControlName]="fhName">
                        <span class="slider round"
                            [class.data-on]="editCustomViewsForm.get(fhName)?.value"></span>
                    </label>
                    <div class="filter-list-values">
                        <p class="align-items-center d-flex" *ngFor="let fValues of customCurrentViewControls[fhName]">
                            <input id="{{fValues.name}}" class="flex-shrink-0" type="checkbox" [value]="fValues.name"
                                [checked]="fValues.checked"
                                (change)="onUpdateFilters($event, fValues, fhName)">
                                <label for="{{fValues.name}}">{{ this.updateFilters.desk.startsWith("pipeline") && (fhName !== 'product' &&  fValues.name !== 'all') || (fhName === 'terminal' && fValues.name !== 'all') ? fValues.id : fValues.name  }}</label>
                        </p>
                    </div>
                </div>
            </div>
            <div class="filters-list" *ngIf="this.path$.value === '/bcpDashboard'">
                <div class="form-group col-3 filter-save-option" *ngFor="let fhName of filterHeaderName">
                <label for="" class="font-gotham-narrow-bold label filter-list-label">{{fhName === 'multiDesks' ? 'Desk Filter': fhName }}</label>
                <label class="switch">
                    <input type="checkbox" checked
                        [formControlName]="fhName">
                    <span class="slider round"
                        [class.data-on]="editCustomViewsForm.get(fhName)?.value"></span>
                </label>
                <div class="filter-list-values">
                    <span *ngIf="fhName==='multiDesks'">
                        <p  class="align-items-center d-flex" *ngFor="let fValues of customCurrentViewControls[fhName]">
                            <input id="{{fValues.deskName}}" class="flex-shrink-0" type="checkbox" [value]="fValues.deskName"
                                [checked]="fValues.checked"  [disabled]="fValues.disabled"
                                (change)="onBcpUpdateFilters($event, fValues, fhName)">                           
                            <label for="{{fValues.deskName}}">{{fValues.deskName}}</label>
                        </p>
                    </span>    
                    <span *ngIf="fhName==='locations'">
                        <p class="align-items-center d-flex" *ngFor="let fValues of customCurrentViewControls[fhName]">
                            <input id="{{fValues.location}}" class="flex-shrink-0" type="checkbox" [value]="fValues.location"
                                [checked]="fValues.checked"
                                (change)="onBcpUpdateFilters($event, fValues, fhName)">                           
                            <label for="{{fValues.location}}">{{fValues.location}}</label>
                        </p>
                    </span>
                    <span *ngIf="fhName==='products'">
                        <p class="align-items-center d-flex" *ngFor="let fValues of customCurrentViewControls[fhName]">
                            <input id="{{fValues.productName}}" class="flex-shrink-0" type="checkbox" [value]="fValues.productName"
                                [checked]="fValues.checked"
                                (change)="onBcpUpdateFilters($event, fValues, fhName)">                           
                            <label for="{{fValues.productName}}">{{fValues.productName}}</label>
                        </p>
                    </span>
                </div>                
            </div>
            <span *ngIf="this.selectedDesk?.startsWith('ethanol') || this.selectedDesk?.startsWith('panama')">
                <div class="form-group col-3"></div>
            </span>
        </div>
            <div>
                <span style="color: #97002E">*</span><label for="" class="create-title-label lable-text">select table
                    personalization options to keep</label>
            </div>
            <div class="filters-list personalisation-view">
                <div class="form-group personalisation-save-option">
                    <label for="" class="font-gotham-narrow-bold label personalisation-list-label">columns order</label>
                    <label class="switch">
                        <input type="checkbox" formControlName="columnOrder" [checked] ="setColOrder" >
                        <span class="slider round"
                            [class.data-on]="editCustomViewsForm.get('columnOrder')?.value"></span>
                    </label>
                </div>
                <div class="form-group personalisation-save-option" *ngIf="this.path$.value !== '/bcpDashboard'">
                    <label for="" class="font-gotham-narrow-bold label personalisation-list-label">collapsed/expanded columns</label>
                    <label class="switch">
                        <input type="checkbox" formControlName="columnExpansion" [checked]="setExpanded">
                        <span class="slider round"
                            [class.data-on]="editCustomViewsForm.get('columnExpansion')?.value"></span>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="confirm-buttons">
        <button type="button" class="cancel-button button" (click)="closePopup()">cancel</button>
        <button type="button" class="ok-button button" (click)="confirm()">save changes</button>
    </div>
</div>