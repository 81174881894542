<div class="create-terminal-nomination">
    <form [formGroup]="createTerminalNominationForm">
        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-6">
                <label for="nominationNoTerminal" class="label"><span style="color: #97002E">* </span>nomination no.</label>
                <input type="text" formControlName="nominationNo" class="form-control" id="nominationNoTerminal">
            </div>
            <div class="form-group col-md-6 branded">
                <label for="modeOfTransport" class="label"><span style="color: #97002E">* </span>mode of
                    transportation </label>
                <select class="form-control" formControlName="modeOfTransport" id="modeOfTransport"
                    (change)="onChangeMOT($event)">
                    <option value="" disabled selected>select</option>
                    <option *ngFor="let modeOfTransport of transportationDropdown" [value]="modeOfTransport">
                        {{
                        modeOfTransport | uppercase }}</option>
                </select>
            </div>
            <div style="color: #97002E"
                *ngIf="createTerminalNominationForm.controls['nominationNo'].invalid && (createTerminalNominationForm.controls['nominationNo'].dirty || createTerminalNominationForm.controls['nominationNo'].touched)">
                Nomination
                no. limits to 16 characters</div>
        </div>
        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-6 branded">
                <label for="nominationType" class="label"><span style="color: #97002E">* </span>nomination type </label>
                <select class="form-control" formControlName="nominationType" id="nominationType"
                    (change)="resetProductList('nominationType')">
                    <option value="" disabled selected>select</option>
                    <option *ngFor="let nominationType of nominationTypeDropdown" [value]="nominationType">
                        {{ nominationType }}</option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label for="counterPart" class="label"><span style="color: #97002E">* </span>offtakers </label>
                <select class="form-control" formControlName="counterPart" id="counterPart"
                    (change)="resetProductList('offTakers')">
                    <option value="" disabled selected>select</option>
                    <option *ngFor="let counterPart of counterPartDropdown" [value]="counterPart">{{
                        counterPart | uppercase }}</option>
                </select>
            </div>
        </div>
        <div class="form-row pb-1 pr-2 products">
            <div class="form-group col-md-12">
                <label for="products" class="label"><span style="color: #97002E">* </span>products</label>
                <select class="form-control" style="width: 100%" id="products" (change)="addProductList($event)"
                    formControlName="product">
                    <option value="" disabled selected>add new product</option>
                    <option *ngFor="let products of productDropdown" [value]="products">{{ products | uppercase }}
                    </option>
                </select>
                <ng-container formArrayName="detailTypeRequest">
                    <ng-container
                        *ngFor="let newProduct of createTerminalNominationForm.controls['detailTypeRequest'].value; let i=index trackBy: trackByFn">
                        <div class="row product-list" [formGroupName]="i">
                            <div class="form-group col-md-4">
                                <label for="product" class="label">product </label>
                                <input type="text" [value]="createTerminalNominationForm.controls['product'].value"
                                    class="form-control text-field date-field action-chip product-name" id="product"
                                    [min]="" readonly>
                                <i class="icon-remove" (click)="removeProduct(i)"></i>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="udmDetailType" class="label">detail type </label>
                                    <span class="detail-type" id="udmDetailType"*ngIf="newProduct.udmDetailType === 'inventory'; else other_condition"> utilization</span>
                                    <ng-template #other_condition><span class="detail-type">{{newProduct.udmDetailType}}</span></ng-template>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="scheduledQty" class="label"><span style="color: #97002E">* </span>volume (measured in bbl)</label>
                                <input type="text" (keypress)="onKeyPress($event)" (input)="updateVolume($event, i)" [value]="newProduct.scheduledQty" formControlName="scheduledQty" class="form-control text-field date-field"
                                    id="scheduledQty">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-12">
                <label htmlFor="destinationLocation" class="label"><span style="color: #97002E">* </span>destination
                    location </label>
                <app-select-dropdown #SelectDropdownComponent [items]="destinationLocationDropdown"
                    [defaultValue]="referenceData" (afterChange)="onValueChangeOfTransportSystem($event)"
                    formControlName="destinationLocation">
                </app-select-dropdown>
            </div>
        </div>
        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-6">
                <label for="transportSystem" class="label"><span style="color: #97002E">* </span>transport
                    system </label>
                <select class="form-control" style="width: 100%" id="transportSystem" formControlName="transportSystem">
                    <option value="" disabled selected>select</option>
                    <option *ngFor="let ts of transportSystemDropdown" [value]="ts">{{ ts | uppercase }}
                    </option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label for="destinationDateTerminal" class="label"><span style="color: #97002E">* </span>destination date
                </label>
                <input type="date" formControlName="destinationDate" class="form-control text-field date-field"
                    id="destinationDateTerminal" [min]="">
            </div>
            <span style="color: #97002E" *ngIf="inValidDestinationLocation">{{ invalidDestinationLocationsg
                }}</span>
        </div>
        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-6">
                <button class="button" (click)="closePopup()">Cancel</button>
            </div>
            <div class="form-group col-md-6">
                <button class="button" (click)="onFormSubmit()"
                [disabled]="!createTerminalNominationForm.valid">{{!nominationService.ccaEditNomination ? "create + publish" : "update + publish"}}</button>
            </div>
        </div>
    </form>
</div>