import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InformationPanelService {

  infoPanelOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  infoPanelUndocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  infoPanelData: BehaviorSubject<infoPanelData> = new BehaviorSubject<infoPanelData>({
    name: "",
    date: "",
    id: "",
    type: panelType.NONE,
    data: "",
  });
  infoPanelSelectedTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  infoPanelSelectedSelector: BehaviorSubject<any> = new BehaviorSubject<any>(0);


  showInfoPanel(doShow: boolean){

    if(doShow == this.infoPanelOpen.value){
      return
    }

    this.clearCurrentState("DOCK");
    if(doShow){
      this.infoPanelOpen.next(true);
    }else{
      this.infoPanelOpen.next(false);
    }
  }

  undockInfoPanel(unDockPanel: boolean){

    if(unDockPanel == this.infoPanelUndocked.value){
      return
    }

    this.clearCurrentState("OPEN");
    if(unDockPanel){
      this.infoPanelUndocked.next(true);
    }else{
      this.infoPanelUndocked.next(false);
    }
  }

  dockUnDockToggle(){

    if(this.infoPanelOpen.value){
      this.infoPanelOpen.next(false);
      this.infoPanelUndocked.next(true);
    }else if(this.infoPanelUndocked.value){
      this.infoPanelUndocked.next(false);
      this.infoPanelOpen.next(true);
    }
  }

  private clearCurrentState(valueToClear: string){
    if(valueToClear == 'OPEN'){
      this.infoPanelOpen.next(false);
    }else if(valueToClear == 'DOCK'){
      this.infoPanelUndocked.next(false);
    }
  }

  setPanelData(dataToPass: infoPanelData){
    this.infoPanelData.next(dataToPass);
  }

  updateSelectedTab(index: number){
    this.infoPanelSelectedTab.next(index);
  }

  updateSelectedSelector(selector: any){
    this.infoPanelSelectedSelector.next(selector);
  }
  constructor() { }
}

export interface infoPanelData{
  name: string, 
  date: string, 
  id: string,
  type: panelType,
  data: any,
}

export enum panelType{
  NONE = "none",
  CP = "cargoPlan",
  NOM = "nomination",
}