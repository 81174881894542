<div class="create-custom-view" [class.expanded]="isExpanded">
    <form [formGroup]="createCustomViewForm">
        <div class="form-group">
            <label for="customViewName" class="create-title-label font-gotham-bold">name your custom view<span
                    style="color: #97002E">*</span></label>
            <input type="text" id="customViewName" class="form-control" formControlName="customViewName"
                placeholder="custom view name here">
        </div>
        <div>
            <label for="" class="create-title-label">select the filter options to keep<span
                    style="color: #97002E">*</span></label>
        </div>
        <div class="filters-list">
            <div class="form-group filter-save-option" *ngFor="let fhName of filterHeaderName">
                <label for="" class="font-gotham-narrow-bold label filter-list-label">{{ fhName === 'transportSystem' ? 'transport system' : fhName }}</label>
                <label class="switch">
                    <input type="checkbox" [formControlName]="fhName" checked
                        (change)="onChangeSlider()" checked>
                    <span class="slider round"
                        [class.data-on]="createCustomViewForm.get(fhName)?.value"></span>
                </label>
            </div>
        </div>
        <div>
            <label for="" class="create-title-label">select table personalization options to keep<span
                    style="color: #97002E">*</span></label>
        </div>
        <div class="filters-list">
            <div class="form-group personalisation-save-option col-order-toggles">
                <label for="" class="font-gotham-narrow-bold label personalisation-list-label">columns order</label>
                <label class="switch">
                    <input type="checkbox" formControlName="columnOrder" checked>
                    <span class="slider round" [class.data-on]="createCustomViewForm.get('columnOrder')?.value"></span>
                </label>
            </div>
            <div class="form-group personalisation-save-option col-order-toggles" *ngIf="this.currentScreen!=='bcpdashboard'">
                <label for="" class="font-gotham-narrow-bold label personalisation-list-label">collapsed/expanded columns</label>
                <label class="switch">
                    <input type="checkbox" formControlName="columnExpansion" checked>
                    <span class="slider round"
                        [class.data-on]="createCustomViewForm.get('columnExpansion')?.value"></span>
                </label>
            </div>
        </div>
        <div class="save-create-button">
            <a class="save-create-btn" (click)="saveCustomView()">save and create new</a>
            <svg id="Save_text_button" data-name="Save text button" xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" viewBox="0 0 16 16">
                <g id="_16_Circle_Plus" data-name="16_Circle_Plus">
                    <path id="Path_12396" data-name="Path 12396"
                        d="M8.667,2a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,8.667,2Zm0,11.953a5.287,5.287,0,1,1,5.287-5.287,5.287,5.287,0,0,1-5.287,5.287Z"
                        transform="translate(-0.666 -0.666)" fill="#0066b2" />
                    <path id="Path_12397" data-name="Path 12397"
                        d="M11,7H9.667V9.667H7V11H9.667v2.667H11V11h2.667V9.667H11Z"
                        transform="translate(-2.334 -2.334)" fill="#0066b2" />
                    <rect id="Rectangle_4379" data-name="Rectangle 4379" width="16" height="16" fill="none" />
                </g>
            </svg>
        </div>
    </form>
</div>