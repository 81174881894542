import { createAction, props } from "@ngrx/store";
import { SettingsParametersData, UpdateTankParameters, SettingsParameterFilters } from "src/app/shared/interface/settings.interface";


export const loadParamsData = createAction(
  '[Load Tank Parameters Data API]',
  props<{ payload: any}>(),
)

export const loadParamsDataSuccess = createAction(
  '[Load Tank Params Data API Success]',
  props<{ tankParametersData: SettingsParametersData[] }>(),
);

export const loadParamsDataFailure = createAction(
  '[Load Tank Params Data API Failure]',
  props<{ error: any }>(),
);

export const updateParamsData = createAction(
  '[Update Tank Parameters Data API]',
  props<{ payload: any}>(),
)

export const updateParamsDataSuccess = createAction(
  '[Update Tank Params Data API Success]',
  props<{ updateTankParametersData: UpdateTankParameters[] }>(),
);

export const updateParamsDataFailure = createAction(
  '[Update Tank Params Data API Failure]',
  props<{ error: any }>(),
);

export const updateParameterFilters = createAction(
  '[Parameter Filters] Update Parameter Filters',
  props<{ SettingsParameterFilters: SettingsParameterFilters }>()
);

export const UpdateParameterFilterData = createAction(
  '[Alert Filters] Set UpdateParameterFilterData',
  props<{ UpdateParameterFilterData: boolean }>()
);
