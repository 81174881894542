import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-duplicate-cargo-popup-cca',
  templateUrl: './duplicate-cargo-popup-cca.component.html',
  styleUrls: ['./duplicate-cargo-popup-cca.component.scss']
})
export class DuplicateCargoPopupCcaComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();
  public createDuplicateCargoForm: FormGroup;
  public createCargoList: any = [];
  inValidDestinationLocation: boolean = false;
  invalidDestinationLocationsg: string = '';

  constructor(private fb: FormBuilder,  public nominationService: NominationService, private toasterService: ToastService,
    public cargoPlanningService: CargoPlanningService
    ) {
    this.createDuplicateCargoForm = this.fb.group({
      duplicate: ['', [Validators.required]],
      cycle: ['',  [Validators.required]]
    })
   }

  ngOnInit(): void {
  }
  closePopup(){
    this.closeModalPopup.emit(false);
    this.createDuplicateCargoForm.reset({
      duplicate: '',
      cycle:''
    })
  }
  submitForm(){
    this.onFormSubmit()
  }
  onFormSubmit(){
    let obj: any = {};
    obj.id = this.createCargoList.id
    obj.numberOfDuplicates = this.createDuplicateCargoForm.get('duplicate')?.value;
    obj.supplyCycle = this.createDuplicateCargoForm.get('cycle')?.value;
    obj.sys_createdBy = this.nominationService.userDetails$.value._email;
    const requestBodyArray = [obj];
    this.updateAPI(requestBodyArray)

  }
  presetNominationDataForCargo(){
    this.createCargoList = this.nominationService.isCpGridSelected[0];
  }
 
  updateAPI(requestBodyArray: any) {
    this.cargoPlanningService.duplicateCargo(requestBodyArray).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: 'cargo has been created'
          });
        }
        this.closePopup();
        this.closeModalPopup.emit(response);
      },
      error: (err) => {
        this.closePopup();

        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err;
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message,
        });
      },
      complete: () => {

      },
    });
  }
}
