<div class="cargo-planning-details-grid row">
    <div>
        <div class="ag-theme-alpine ag-grid-table cargo-planning-detail">
            <ag-grid-angular class="ag-theme-alpine ag-grid-table" style="height: 285px; width: 2000px"
                [columnDefs]="colDefs" [suppressMenuHide]="true" [rowData]="rowData" [embedFullWidthRows]="true"
                (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                (cellValueChanged)="onCellValueChanged($event)">
            </ag-grid-angular>
        </div>
    </div>
    <div class="btn create-cargo-btn">
        <button class="branded details-btn" [disabled]="params.data.status !== 'pendingNomination'" (click)="onCreateNominationsFromCargo($event)">create nomination</button>
    </div>
</div>