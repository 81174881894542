import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';




@Component({
  selector: 'app-statusbar-info',
  templateUrl: './statusbar-info.component.html',
  styleUrls: ['./statusbar-info.component.scss']
})
export class StatusbarInfoComponent implements IStatusPanelAngularComp {
  private params!: IStatusPanelParams;
  displayInfo: any;
  pstDate: any;

  agInit(params: any): void {
   this.displayInfo = params['displayInfo'] ? params['displayInfo'] : "";
   this.pstDate = params['pstDate'] ? params['pstDate'] : "";
  }

}
