<div class="panel-content">
    <ng-container>
        <div>
            <div class="content__header">location</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText0'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('locationName', $any($event.target).value)"
                    [(ngModel)]="searchValue[0]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.locationName; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationName', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">SIS location code</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('locationCode', $any($event.target).value)"
                    [(ngModel)]="searchValue[1]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.locationCode; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationCode', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">status</div>
            <form class="branded">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.status; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="statusCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('status', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">track inventory</div>
            <form class="branded">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.trackInv; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="trackInvCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('trackInv', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">material</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText2'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('materialDescription', $any($event.target).value)"
                    [(ngModel)]="searchValue[2]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.materialDescription; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="materialDescriptionCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('materialDescription', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">ID</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText3'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('materialNumber', $any($event.target).value)"
                    [(ngModel)]="searchValue[3]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.materialNumber; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="materialNumberCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('materialNumber', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">group material</div>
            <form class="branded">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.groupMaterial; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="groupMaterialCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('groupMaterial', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">material group name</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText4'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('materialGroupName', $any($event.target).value)"
                    [(ngModel)]="searchValue[4]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.materialGroupName; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="materialGroupNameCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('materialGroupName', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">material group number</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText5'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('materialGroupNumber', $any($event.target).value)"
                    [(ngModel)]="searchValue[5]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of filters.materialGroupNumber; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="materialGroupNumberCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('materialGroupNumber', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">demand</div>
            <div class="content__header" title="filters by +/- 7 days">seasonal start date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText6'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmDemandForecastStartDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[6]">
            </form>
        </div>
        <div>
            <div class="content__header" title="filters by +/- 7 days">seasonal end date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText7'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmDemandForecastEndDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[7]">
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">inventory</div>
            <div class="content__header" title="filters by +/- 7 days">seasonal start date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText8'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmInvStartDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[8]">
            </form>
        </div>
        <div>
            <div class="content__header" title="filters by +/- 7 days">seasonal end date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText9'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmInvEndDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[9]">
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">liftings</div>
            <div class="content__header" title="filters by +/- 7 days">seasonal start date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText10'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmActualLiftStartDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[10]">
            </form>
        </div>
        <div>
            <div class="content__header" title="filters by +/- 7 days">seasonal end date</div>
            <form *ngIf="showDateFilters" class="branded">
                <input class="form-control admin-search" title="filters by +/- 7 days" [name]="'searchText11'" type="date"
                    placeholder="search" (input)="applyDateFilters('spmActualLiftEndDate', $any($event.target).value)"
                    [(ngModel)]="searchValue[11]">
            </form>
            <hr>
        </div>
    </ng-container>
</div>

<div class="panel-footer d-flex justify-content-between p-3">
  <span class="clear-filter" (click)="clearFilters()">clear</span>
  <span class="clear-filter" (click)="filterMaterialMappings()">apply</span>
</div>
