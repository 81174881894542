import { Action, createReducer, on } from "@ngrx/store";
import { Desks, MaterialMappingFilters, MaterialMappingNested } from "../../../../../src/app/shared/interface/mdm-admin.interface";
import { loadMaterialMappingDataSuccess, loadDesksDataSuccess, updateMaterialMappingSuccess, setDefaultMaterialMappingFilters, updateMaterialMappingTable, materialMappingFiltersUpdated, updateMaterialMappingFilters, resetMaterialMappingScreen, updateEditedMaterialMapping, addEditedMaterialMapping } from "../action/admin-material-mapping.action";

export interface State {
  materialMappingData: MaterialMappingNested [];
  materialMappingFilters: MaterialMappingFilters;
  addEditedChanges: boolean;
  updateMaterialMappingTable:boolean;
  updateMaterialMappingFilter: boolean;
  initializeMaterialMappingsData:boolean;
  initializeMaterialMappingFilter: boolean;
  desks: Desks[];
  initializeDesksData: boolean;
}

export const initialState: State = {
  materialMappingData : [],
  materialMappingFilters: {
    locationName: [],
    status: [],
    materialDescription: [],
    materialNumber: [],
    groupMaterial: [],
    materialGroupName: [],
    materialGroupNumber: [],
    trackInv: [],
    locationCode: []
  },
  addEditedChanges: false,
  updateMaterialMappingTable: false,
  updateMaterialMappingFilter: false,
  initializeMaterialMappingsData: true,
  initializeMaterialMappingFilter: false,
  desks: [],
  initializeDesksData: true
};

const materialMappingReducer = createReducer(
  initialState,
  on(loadMaterialMappingDataSuccess,(state,{materialMappingData}) => ({
    ...state,
    materialMappingData : materialMappingData,
    updateMaterialMappingTable: true,
    initializeMaterialMappingsData: false,
    initializeMaterialMappingFilter: true
  })),
  on(loadDesksDataSuccess, (state, { desks }) => ({
    ...state,
    desks: desks,
    initializeDesksData: false,
  })),
  on(updateMaterialMappingSuccess, (state) => ({
    ...state
  })),
  on(setDefaultMaterialMappingFilters, (state, { materialMappingFilters, materialMappingData }) => ({
    ...state,
    materialMappingFilters: materialMappingFilters,
    materialMappingData: materialMappingData,
    initializeMaterialMappingFilter: false,
    updateMaterialMappingTable: false,
    updateMaterialMappingFilter: true
  })),
  on(updateMaterialMappingFilters, (state, { materialMappingFilters, materialMappingData }) => ({
    ...state,
    materialMappingFilters: materialMappingFilters,
    materialMappingData: materialMappingData,
    updateMaterialMappingFilter: true
  })),
  on(updateMaterialMappingTable, (state, { updateMaterialMappingTable }) => ({
    ...state,
    updateMaterialMappingTable: updateMaterialMappingTable
  })),
  on(materialMappingFiltersUpdated, (state, { updateMaterialMappingFilter }) => ({
    ...state,
    updateMaterialMappingFilter: updateMaterialMappingFilter
  })),
  on(resetMaterialMappingScreen, (state) => ({
    ...state,
    addEditedChanges: false,
    updateMaterialMappingTable: false,
    updateMaterialMappingFilter: false,
    initializeMaterialMappingsData: true,
    initializeMaterialMappingFilter: false,
    initializeDesksData: true
  })),
  on(updateEditedMaterialMapping, (state, { materialMappingData }) => ({
    ...state,
    addEditedChanges: true,
    materialMappingData: materialMappingData
  })),
  on(addEditedMaterialMapping, (state) => ({
    ...state,
    addEditedChanges: false,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
    return materialMappingReducer(state, action);
}

export const getMateriMappingData = (state: State) => ({ materialMappingData: state.materialMappingData, updateMaterialMappingTable: state.updateMaterialMappingTable, initializeMaterialMappingsData:state.initializeMaterialMappingsData, addEditedChanges: state.addEditedChanges });

export const getMaterialMappingFilter = (state: State) => ({ materialMappingFilters: state.materialMappingFilters, initializeMaterialMappingFilter: state.initializeMaterialMappingFilter, updateMaterialMappingFilter: state.updateMaterialMappingFilter, updateMaterialMappingTable: state.updateMaterialMappingTable });

export const getDesksData = (state: State) => ({ desks: state.desks, initializeDesksData: state.initializeDesksData });