import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/contract-balancing.reducer';
import * as CB from '../reducer/contract-balancing.reducer';

export const selectCBData = createFeatureSelector<State>('contractbalancing');

export const selectContractFilterResponse = createSelector(
    selectCBData,
    CB.getCBFilterResponse
);

export const selectCBContractDetailsResponse = createSelector(
    selectCBData,
    CB.getCBContractDetailsResponse
);

export const selectCBMTDTrackingResponse = createSelector(
    selectCBData,
    CB.getCBMTDTrackingResponse
);

export const selectCBMPRResponse = createSelector(
    selectCBData,
    CB.getCBMPRResponse
);

export const selectCBDPRResponse = createSelector(
    selectCBData,
    CB.getCBDPRResponse
);

