<div
  class="filter-dropdown-main-container"
  *ngIf="dealsData$ | async as deslFilters"
>
  <div
    appCloseDropdownDircetive
    (clickedOutSide)="closeTransactionDropdown($event)"
    class="transaction-container"
    [class.active]="showTransactionDropdown"
    [attr.title]="(deslFilters.selectedFilters.primary.transaction.length > 1
    ? 'multiple'
    : deslFilters.selectedFilters.primary.transaction.length == 1 &&
      deslFilters.selectedFilters.primary.transaction[0] != 'all'
    ? deslFilters.selectedFilters.primary.transaction[0]
    : 'transaction') | lowercase"
  >
    <div (click)="toggleViewDropdown('transaction')" class="transaction-inner-container">
      <p class="selected-view-item">
        {{
          deslFilters.selectedFilters.primary.transaction.length > 1
            ? "multiple"
            : deslFilters.selectedFilters.primary.transaction.length == 1 &&
              deslFilters.selectedFilters.primary.transaction[0] != "all"
            ? deslFilters.selectedFilters.primary.transaction[0]
            : "transaction"
        }}
       
      </p>
      <span
      [class.icon-chevron-down]="!showTransactionDropdown"
      [class.icon-chevron-up]="showTransactionDropdown"
      class="dropdown-arrow-icon"
    ></span>
    </div>
    <div
      class="transaction-container__inner-container"
      *ngIf="showTransactionDropdown"
    >
      <form class="branded">
        <div
          *ngFor="
            let filter of deslFilters.filters.primary.transaction;
            let i = index
          "
        >
          <input
            type="checkbox"
            class="formcheckbox"
            name="formcheckbox"
            attr.id="formcheckbox-{{ i }}"
            [value]="filter"
            (change)="applyTransactionFilters(filter)"
            [checked]="isTransactionSelected(filter)"
            [disabled]="isTransactionDisabled(filter)"
          />
          <label attr.for="formcheckbox-{{ i }}">{{ filter }}</label>
        </div>
      </form>
    </div>
  </div>
  <div
    appCloseDropdownDircetive
    (clickedOutSide)="closeMaterialDropdown($event)"
    class="material-container"
    [class.active]="showMatrialDropdown"
    [attr.title]="(deslFilters.selectedFilters.primary.material.values.length > 1
    ? 'multiple'
    : deslFilters.selectedFilters.primary.material.values.length == 1 &&
      deslFilters.selectedFilters.primary.material.values[0] != 'all'
    ? deslFilters.selectedFilters.primary.material.values[0]
    : 'material') | lowercase"
  >
    <div (click)="toggleViewDropdown('material')" class="material-inner-container">
      <p class="selected-view-item">
        {{
          deslFilters.selectedFilters.primary.material.values.length > 1
            ? "multiple"
            : deslFilters.selectedFilters.primary.material.values.length == 1 &&
              deslFilters.selectedFilters.primary.material.values[0] != "all"
            ? deslFilters.selectedFilters.primary.material.values[0]
            : "material"
        }}
       
      </p>
      <span
      [class.icon-chevron-down]="!showMatrialDropdown"
      [class.icon-chevron-up]="showMatrialDropdown"
      class="dropdown-arrow-icon"
    ></span>
    </div>
    <div
      class="material-container__inner-container"
      *ngIf="showMatrialDropdown"
    >
      <input
        type="search"
        id="materialsearch"
        class="materialsearch"
        placeholder="search for material"
        (input)="
          search($event)
        "
        [value]="searchMaterial"
      />
      <div class="material-tabs">
        <span
          [class.active]="
            deslFilters.selectedFilters.primary.material.tab === 'tsw'
          "
          (click)="selectMaterialTab('tsw')"
          >tsw</span
        >
        <span
          [class.active]="
            deslFilters.selectedFilters.primary.material.tab === 'sra'
          "
          (click)="selectMaterialTab('sra')"
          >sra</span
        >
      </div>
      <div *ngIf="deslFilters.selectedFilters.primary.material.tab === 'tsw'">
        <form class="branded">
          <div
            *ngFor="
              let filter of deslFilters.filters.primary.material.tsw;
              let i = index
            "
          >
            <input
              type="checkbox"
              name="formcheckbox"
              attr.id="formcheckbox-{{ i }}"
              [value]="filter"
              (change)="applyMaterialFilters(filter)"
              [checked]="isMaterialSelected(filter)"
              [disabled]="isMaterialDisabled(filter)"
            />
            <label attr.for="formcheckbox-{{ i }}">{{ filter }}</label>
          </div>
        </form>
      </div>
      <div *ngIf="deslFilters.selectedFilters.primary.material.tab === 'sra'">
        <form class="branded">
          <div
            *ngFor="
              let filter of deslFilters.filters.primary.material.sra;
              let i = index
            "
          >
            <input
              type="checkbox"
              name="formcheckbox"
              attr.id="formcheckbox-{{ i }}"
              [value]="filter"
              (change)="applyMaterialFilters(filter)"
              [checked]="isMaterialSelected(filter)"
              [disabled]="isMaterialDisabled(filter)"
            />
            <label attr.for="formcheckbox-{{ i }}">{{ filter }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    appCloseDropdownDircetive
    (clickedOutSide)="closeCycleDropdown($event)"
    class="cycle-container"
    [class.active]="showCycleDropdown"
    [attr.title]="(deslFilters.selectedFilters.primary.cycle.length > 1
    ? 'multiple'
    : deslFilters.selectedFilters.primary.cycle.length == 1 &&
      deslFilters.selectedFilters.primary.cycle[0] != 'all'
    ? deslFilters.selectedFilters.primary.cycle[0]
    : 'cycle') | lowercase"
  >
    <div (click)="toggleViewDropdown('cycle')" class="cycle-inner-container">
      <p class="selected-view-item">
        {{
          deslFilters.selectedFilters.primary.cycle.length > 1
            ? "multiple"
            : deslFilters.selectedFilters.primary.cycle.length == 1 &&
              deslFilters.selectedFilters.primary.cycle[0] != "all"
            ? deslFilters.selectedFilters.primary.cycle[0]
            : "cycle"
        }}
       
      </p>
      <span
      [class.icon-chevron-down]="!showCycleDropdown"
      [class.icon-chevron-up]="showCycleDropdown"
      class="dropdown-arrow-icon"
    ></span>
    </div>
    <div class="cycle-container__inner-container" *ngIf="showCycleDropdown">
      <form class="branded">
        <div
          *ngFor="
            let filter of deslFilters.filters.primary.cycle;
            let i = index
          "
        >
          <input
            type="checkbox"
            name="formcheckbox"
            attr.id="formcheckbox-{{ i }}"
            [value]="filter"
            (change)="applyCycleFilters(filter)"
            [checked]="isCycleSelected(filter)"
            [disabled]="isCycleDisabled(filter)"
          />
          <label attr.for="formcheckbox-{{ i }}">{{ filter }}</label>
        </div>
      </form>
    </div>
  </div>
</div>
