import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { NomTooltipComponent } from '../nom-tooltip/nom-tooltip.component';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  // Row Data: The data to be displayed.
  rowData: any[] = [
    { sType: 'O', lineKey: '10', status: '4', qty: '100', scheduledDate: '06/13/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: '', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas' },
    { sType: 'D', lineKey: '20', status: '1', qty: '10', scheduledDate: '06/13/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas' },
    { sType: 'X', lineKey: '140', status: '2', qty: '110', scheduledDate: '06/13/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: '', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas' },
    { sType: 'O', lineKey: '80', status: '4', qty: '102', scheduledDate: '06/13/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas' },
    { sType: 'O', lineKey: '111', status: '3', qty: '300', scheduledDate: '06/13/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas' }
  ];

  // Column Definitions: Defines & controls grid columns.
  colDefs: ColDef<any>[] = [
    {
      headerName: '',
      field: 'description',
      cellRenderer: NomTooltipComponent,
      tooltipValueGetter: (p: ITooltipParams) =>
        p.value,
      minWidth: 100
    },
    { field: 'sType' },
    { field: 'lineKey' },
    { field: 'status' },
    { field: 'qty' },
    { field: 'scheduledDate' },
    { field: 'arriveTime' },
    { field: 'scheduledProduct' },
    { field: 'demandProduct' },
    { field: 'description' },
    { field: 'rdi' },
    { field: 'sapContract' },
    { field: 'contractLineNo' },
    { field: 'batchOriginLocation' },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
  };

  addNewRow() {

  }
  
}