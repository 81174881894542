
export const Terminal: any = { //Terminal to port mapping
"LIGHTERING":"PASCAGOULA MS",
"CHEVRON":"PASCAGOULA MS",
"BAYOU CASOTTE":"PASCAGOULA MS",
"XXXX - GULF LNG TERMINAL - OUT OF SERVICE":"PASCAGOULA MS",
"EMPRESA NAC. PORTUARIA (EX ANCHORAGE)":"PUERTO CASTILLA",
"ARCENILLAS/BLUE OIL (Ex-CARPESA)":"PUERTO QUETZAL",
"MARINA PAZ VELA TERMINAL":"PUERTO QUETZAL",
"PACIFIC OIL":"PUERTO QUETZAL",
"ZETA GAS DE CENTRO AMERICA":"PUERTO QUETZAL",
"DARSA TERMINAL":"PUERTO QUETZAL",
"PUERTO QUETZAL POWER (EX. ENRON)":"PUERTO QUETZAL",
"COLTERMINALES (VOPAK)":"BARRANQUILLA",
"ANCHORAGE":"GUANAJA",
"DECAL PANAMA":"TABOGUILLA ISLAND",
"PUMA ENERGY /  EMPRESA NACIONAL PORTUARIA":"TELA",
"ECOPETROL (STA.MARTA)":"POZOS COLORADOS",
"TERMINALES DEL ATLANTICO, S.A.(EX GOVERNMENT PIER )":"SANTO TOMAS DE CASTILLA",
"CANAL TRANSIT (DO NOT USE - NOT REQUIRED)":"PANAMA CANAL ZONE (DO NOT USE)",
"XXXX - TRANSIT - DO NOT USE (DUPLICATE)":"XXXX - PANAMA CANAL TRANSIT - DO NOT USE (DUPLICATE)",
"PORT MAGDALENA TERMINAL (EX - ATLANTIC OIL TERMINAL)":"BARRANQUILLA",
"COMPASS TERMINAL":"BARRANQUILLA",
"SP PALERMO":"BARRANQUILLA",
"MONOMEROS":"BARRANQUILLA",
"ATLANTIC COAL TERMINAL":"BARRANQUILLA",
"SP MICHELLMAR TERMINAL":"BARRANQUILLA",
"ESSO COLOMBIANA TERMINAL":"BARRANQUILLA",
"SP MICHELLMAR S. A.":"BARRANQUILLA",
"GAS DEL CARIBE":"OMOA",
"CHEVRON TERMINAL SAN ANDRES":"SAN ANDRES ISLAND",
"MELAZAS DE ESCUINTLA":"SAN JOSE",
"XXXX - ESSO - (Out Of Service)":"SAN JOSE",
"PUMA (COPENSA)":"SAN JOSE",
"OTSA (CHEVRONTEXACO)":"SAN JOSE",
"CI INTERNATIONAL":"SANTA MARTA",
"LIGHTERING PANAMA OFFSHORE":"LIGHTERING PANAMA (PANPAC)",
"ANCHORAGE - LIGHTERING (BALBOA)":"LIGHTERING PANAMA (PANPAC)",
"PTP":"PUERTO ARMUELLES",
"OFFSHORE LIGHTERING AREA":"PUERTO ARMUELLES",
"PUNTA ARENAS TERMINAL":"PUERTO BOLIVAR",
"CARBONES DEL CERREJON":"PUERTO BOLIVAR",
"ECOPETROL SA TUMACO TERMINAL":"TUMACO",
"GOVERNMENT WHARF (EMPRESA NACIONAL PORTUARIA)":"SAN LORENZO",
"ARCO (RICHMOND)":"RICHMOND CA",
"FISH OIL TERMINAL":"BALBOA",
"BALBOA TERMINAL":"BALBOA",
"PANAMA PORTS CO (AUTH PORT NACIONAL)":"BALBOA",
"PATSA (EX PIMPSA)":"BALBOA",
"XXXX - DECAL TABOGUILLA -DUPLICATE SEE  PORT TABOGUILLA ISLAND":"BALBOA",
"XXXX -ANCHORAGE - LIGHTERING(OOS:USE LIGHTERING PANAMA) - DO NOT USE(DUPLICATE)":"BALBOA",
"CALIFORNIA OILS CORPORATION":"RICHMOND CA",
"FOSS MARITIME COMPANY":"RICHMOND CA",
"CHEVRON LONG WHARF MARINE OIL TERMINAL":"RICHMOND CA",
"CONOCO PHILLIPS - KINDER MORGAN":"RICHMOND CA",
"PT. POTRERO MARINE TERMINAL (PRIVATELY OWNED)":"RICHMOND CA",
"BP LUBRICANTS USA INC (PRIVATELY OWNED)":"RICHMOND CA",
"PACIFIC ATLANTIC TERMINAL":"RICHMOND CA",
"BP WEST COAST PRODUCTS (PRIVATELY OWNED)":"RICHMOND CA",
"IMTT (RICHMOND)":"RICHMOND CA",
"CEPA (PORT AUTHORITY COMMON USER)":"ACAJUTLA",
"CENERGICA Ex-COASTAL TECHNOLOGY OFFSHORE":"ACAJUTLA",
"ALBA PETROLEOS- OUT OF SERVICE":"ACAJUTLA",
"RASA REFINERY":"ACAJUTLA",
"XXXX ECOPETROL - OUT OF SERVICE":"COVENAS",
"ECOPETROL OFFSHORE":"COVENAS",
"ENERGIA DEL PACIFICO LNG TERMINAL.":"ACAJUTLA",
"ECOPETROL TERMINAL":"MAMONAL",
"SPEC LNG TERMINAL (HOEGH GRACE)":"MAMONAL",
"OKIANUS TERMINAL":"CARTAGENA (COL)",
"PUERTO BAHIA":"CARTAGENA (COL)",
"ALGRANEL":"CARTAGENA (COL)",
"PETROQUIMICA TERMINAL":"CARTAGENA (COL)",
"DIQUE DE LACURRA":"CARTAGENA (COL)",
"TEXACO MAMONAL TERMINAL":"CARTAGENA (COL)",
"SOCIEDAD PORTUARIAPUERTOS DEL CARIBE":"CARTAGENA (COL)",
"PUERTOS DEL CARIBE SOCIEDAD(EXXON/COPEC/TERPEL)":"CARTAGENA (COL)",
"OIL TANKING TERMINAL":"CARTAGENA (COL)",
"VOPAK TERMINAL":"CARTAGENA (COL)",
"Ship to Ship":"CARTAGENA (COL)",
"PROPILCO/MEXICHEM":"CARTAGENA (COL)",
"XXXX - DOW QUIMICA - DO NOT USE (DUPLICATE)":"CARTAGENA (COL)",
"XXXX REPSOL REFINERY- DO NOT USE (DUPLICATE)":"CARTAGENA (COL)",
"ECOPETROL(MAMONAL)":"CARTAGENA (COL)",
"XXXX-COLTERMINALES TERMINAL - (OUT OF SERVICE)":"CARTAGENA (COL)",
"MOBIL":"CARTAGENA (COL)",
"CHEVRON TEXACO":"CARTAGENA (COL)",
"PORT SOCIETY OF BUENAVENTURA PIER NO 14":"BUENAVENTURA",
"UNITED FRUIT":"ALMIRANTE",
"COLON OIL AND SERVICES S.A. (OILTANKING) COCO SOLO":"CRISTOBAL",
"EXPLOSIVE ANCHORAGE A":"CRISTOBAL",
"PANAMA PORTS CO.(Ex-AUTH PORT NACIONAL)":"CRISTOBAL",
"TELFER TANKS":"CRISTOBAL",
"CUTUCO":"LA UNION",
"PETROTERMINAL DE PANAMA":"CHIRIQUI GRANDE",
"REFINERIA PANAMA":"BUERTO LAS MINAS",
"UNO/MOLASSES/CHEMICALS":"PUERTO CORTES",
"REFINERIA TEXACO DE HONDURAS":"PUERTO CORTES",
"XXXX - GAS DEL CARIBE - DO NOT USE (DUPLICATE)":"PUERTO CORTES",
"TEH/LOGRA UNO HONDURAS":"PUERTO CORTES",
"TRANSIT":"PANAMA CANAL",
"MELONES OIL TERMINAL":"MELONES",
"TEXACO/SANTO TOMAS TERMINAL":"PUERTO BARRIOS",
"COBIGUA":"PUERTO BARRIOS",
"CHEVRON (EX UNITED FRUIT)":"PUERTO BARRIOS",
"SAUSE BROS":"RICHMOND CA",
"REFPAN LAS MINAS VOPAK TERMINAL":"BUERTO LAS MINAS",
"PUNTA RINCON INTERNATIONAL TERMINAL":"PUNTA RINCON",
"ZONA FRANCA - PLEXAPORT":"CARTAGENA (COL)",
"TGM PETCOKE TERMINAL":"PUERTO QUETZAL",
"COSTA NORTE TERMINAL":"CRISTOBAL",
}
export const RegionTerminal: any = {
    'LMINASCHEV': 'ECCA',
    'ACAJUTLATM': 'ECCA',
    'GTEXPORT': 'WCCA',
    'LMINASTRM': 'WCCA',
    'OTSATRM': 'WCCA',
    'PETROPOTRM': 'Panama',
    'PBARRIOTRM': 'Panama',
    'PASCAGOU': 'ECCA',
    'PATSA': 'WCCA'
}
export const LocSraCode: any = {
    "GTEXPORT": 'GTEXPORT',
    "GUATEMAR": 'GUATEMAR',
    "PBARRIOTRM": 'PBARRIOTRM',
    "PCORTESTRM": 'PCORTESTRM',
    "CPCI-X": 'CPCI-X',
    "OTSATRM": 'OTSATRM',
    "ACAJUTLATM": 'ACAJUTLATM',
    // "CPCI-X": 'WCCA',
    "PLT": 'PLT',
    "PSAT-X": 'PSAT-X',
    "LMINASCHEV": "LMINASCHEV",
    "LMINASTRM": "LMINASTRM",
    "PATSA": "PATSA"
}
interface ProdSraCode {
    [key:string]: number|null,
}
export const prodTSWCode: ProdSraCode = {
    "91 RON Export_ECCA": 380044990,
    "95 RON Export_ECCA": 380052990,
    "Jet A_ECCA": 440035000,
    "Avgas_ECCA": 200206990,
    "Kero_ECCA": 217176990,
    "LSD_ECCA": 850022000,
    "Jet A_PANAMA": 440035000,
    "ULSD_PANAMA": 850026000,
    "95 RON Export_PANAMA": 380053990,
    "91 RON Export_PANAMA": 380045990,
    "MGO_PANAMA": 890024000,
    "Avgas_PANAMA": 200206990,
    "LSD_WCCA": 850022000,
    "ULSD_WCCA": 850026000,
    "RDS_WCCA": 272089990,
    "91 RON Export_WCCA": 380044990,
    "95 RON Export_WCCA": 380052990,
    "BO_WCCA": null,
    "Jet A_WCCA": 440035000,
    "Avgas_WCCA": 200206990,
}
export const newTSWDescMap: any ={
  '91 RON Export_ECCA_Load':'91RON-B MOGAS US-EXPORT',
'95 RON Export_ECCA_Load':'95RON-B MOGAS US-EXPORT',
'Jet A_ECCA_Load':'JET A - EXPORT',
'Avgas_ECCA_Load':'AVGAS 100LL',
'Kero_ECCA_Load':'KEROSENE (CO/GT/HN/SV)',
'LSD_ECCA_Load':'LS  EXPORT DF2',
'ULSD_ECCA_Load':'ULS EXPORT DF2',
'91 RON Export_ECCA_Discharge':'MOGAS-REG (GT/HN/SV)',
'95 RON Export_ECCA_Discharge':'MOGAS-PRM (GT/HN/SV)',
'Jet A_ECCA_Discharge':'JET A (GT/HN/PA/SV)',
'Avgas_ECCA_Discharge':'AVGAS 100 (CO/PA)',
'Kero_ECCA_Discharge':'KEROSENE (CO/GT/HN/SV)',
'LSD_ECCA_Discharge':'LOW SULFUR DIESEL (PAN, HN, GT, SV)',
'Jet A_PANAMA_Load':'JET A - EXPORT',
'ULSD_PANAMA_Load':'ULS EXPORT DF2',
'95 RON Export_PANAMA_Load':'95RON-B MOGAS US-EXPORT',
'91 RON Export_PANAMA_Load':'91RON-B MOGAS US-EXPORT',
'Avgas_PANAMA_Load':'AVGAS 100LL',
'LSD_PANAMA_Load':'LS  EXPORT DF2',
'Jet A_PANAMA_Discharge':'JET A (GT/HN/PA/SV)',
'ULSD_PANAMA_Discharge':'ULTRA LOW SULFUR DIESEL (PAN)',
'95 RON Export_PANAMA_Discharge':'MOGAS-PRM (PA)',
'91 RON Export_PANAMA_Discharge':'MOGAS-REG (PA)',
'Avgas_PANAMA_Discharge':'AVGAS 100 (CO/PA)',
'LSD_PANAMA_Discharge':'LOW SULFUR DIESEL (PAN, HN, GT, SV)',
'ULSD_WCCA_Load':'ULS EXPORT DF2',
'91 RON Export_WCCA_Load':'91RON-B MOGAS US-EXPORT',
'95 RON Export_WCCA_Load':'95RON-B MOGAS US-EXPORT',
'LSD_WCCA_Load': 'LS  EXPORT DF2',
'Jet A_WCCA_Load':'JET A - EXPORT',
'Avgas_WCCA_Load':'AVGAS 100LL',

'91 RON Export_WCCA_Discharge':'MOGAS-REG (GT/HN/SV)',
'95 RON Export_WCCA_Discharge':'MOGAS-PRM (GT/HN/SV)',
'LSD_WCCA_Discharge': 'LOW SULFUR DIESEL (PAN, HN, GT, SV)',

'Jet A_WCCA_Discharge':'JET A (GT/HN/PA/SV)',
'Avgas_WCCA_Discharge':'AVGAS 100 (CO/PA)',
}
export const newTSWCodeMap: any ={
    "91 RON Export_ECCA_Load":255663990,
  "95 RON Export_ECCA_Load":254554990,
  "Jet A_ECCA_Load":216102990,
  "Avgas_ECCA_Load":200285990,
  "Kero_ECCA_Load":217176990,
  "LSD_ECCA_Load":272096990,
  "ULSD_ECCA_Load":272095990,
  "91 RON Export_ECCA_Discharge":380044990,
  "95 RON Export_ECCA_Discharge":380052990,
  "Jet A_ECCA_Discharge":440035000,
  "Avgas_ECCA_Discharge":200285990,
  "Kero_ECCA_Discharge":217176990,
  "LSD_ECCA_Discharge":850022000,
  "Jet A_PANAMA_Load":216102990,
  "ULSD_PANAMA_Load":272095990,
  "95 RON Export_PANAMA_Load":254554990,
  "91 RON Export_PANAMA_Load":255663990,
  "MGO_PANAMA_Load":890024000,
  "Avgas_PANAMA_Load":200285990,
  "LSD_PANAMA_Load":272096990,
  "Jet A_PANAMA_Discharge":440035000,
  "ULSD_PANAMA_Discharge":850026000,
  "95 RON Export_PANAMA_Discharge":380053990,
  "91 RON Export_PANAMA_Discharge":380045990,
  "MGO_PANAMA_Discharge":890024000,
  "Avgas_PANAMA_Discharge":200206990,
  "LSD_PANAMA_Discharge":850022000,
  "ULSD_WCCA_Load":272095990,
  "RDS_WCCA_Load":272089990,
  "91 RON Export_WCCA_Load":255663990,
  "95 RON Export_WCCA_Load":254554990,
  "BO_WCCA_Load":240667990,
  // "BO_WCCA_Load":240664990,
  // "BO_WCCA_Load":246747990,
  // "BO_WCCA_Load":246748990,
  // "BO_WCCA_Load":246809990,
  // "BO_WCCA_Load":246802990,
  // "BO_WCCA_Load":246823990,
  "Jet A_WCCA_Load":216102990,
  "Avgas_WCCA_Load":200285990,
  "ULSD_WCCA_Discharge":850026000,
  "RDS_WCCA_Discharge":272089990,
  "91 RON Export_WCCA_Discharge":380044990,
  "95 RON Export_WCCA_Discharge":380052990,
  "BO_WCCA_Discharge":240667990,
  // "BO_WCCA_Discharge":240664990,
  // "BO_WCCA_Discharge":246747990,
  // "BO_WCCA_Discharge":246748990,
  // "BO_WCCA_Discharge":246809990,
  // "BO_WCCA_Discharge":246802990,
  // "BO_WCCA_Discharge":246823990,
  "Jet A_WCCA_Discharge":216102990,
  "Avgas_WCCA_Discharge":200285990,
  "LSD_WCCA_Discharge": 850022000,
  "LSD_WCCA_Load": 272096990
  }
export const locOrigin :any = {
    "pasagoula": "pasagoula",
    "richmond": "richmond",
} 

export const Supplier:any = {
  "Chevron Products Company":"Chevron Corporation",
  "Trafigura Trading, LLC":"Farringford N.V.",
  "Chevron Guatemala Inc.":"Chevron Corporation",
  "Refineria Texaco de Honduras, S.A.":"Chevron Corporation",
  "Inversiones Chevron LTDA. de C.V.":"Chevron Corporation",
  "Inversiones GLP S.A.S. ESP":"Third Party",
  "Petroperu - Petroleos del Peru SA":"Third Party",
  "Chevron Petroleum Company_6246": "Chevron Corporation",
  "Suncor Energy (U.S.A.) Marketing Inc":"Suncor Energy, Inc.",
  "Pacifica Petroleum S.A.":"Third Party",
  "BB Energy USA LLC":"Third Party",
  "Chevron Panama Fuels Limited":"Chevron Corporation",
  "Third Party": "Third Party",
  "CCN 0061 Chevron Products Company (a Chevron U.S.A. Inc. division)":"Chevron Corporation",
 "CCN 0966 Chevron USA Inc. - Chevron Products Company - International Logistics and Trading":"Chevron Corporation",
 "Trafigura Pte Ltd":"Third Party",
 "Freepoint Commodities LLC": "Third Party",
 "B.B. Energy Trading Limited": "Third Party",
 "Phillips 66 Company": "Third Party",
 "Inversiones Chevron, S.A. de C.V.":"Third Party",
 "BP Oil International Limited": "Third Party",
 "Phillips 66 Energy Trading LLC": "Third Party",
 "Shell Trading (US) Company": "Third Party",
}
 
export const nomPop: any = {
    'eccadesk':19,
    'wccadesk':20,
    'panamadesk':21
}

export const Vessel: any = ['Combi (PASC)','Mogas (RIC)','Diesel (PASC)','TBD (PASC)','TBD']
export const McatStatus: any = ['published', 'unpublished', 'inprogress'];
export const ApprovalStatus: any = ['approved', 'pending', 'completed', 'draft'];
export const DeskNames: any = ['ECCA', 'WCCA', 'Panama'];
export const RegionNames: any = {
    'ECCA': 'EAST COAST CENTRAL AMERICA',
    'WCCA': 'WEST COAST CENTRAL AMERICA',
    'Panama': 'Panama',
};
export const NomStatusList: any = ['linked', 'pending', 'partiallyLinked'];
export const productList: any ={
    panamadesk:['91 RON Export', '95 RON Export', 'Avgas', 'Jet A', 'MGO',  'ULSD'],
    eccadesk:['91 RON Export', '95 RON Export', 'Avgas', 'Jet A', 'Kero', 'LSD'],
    wccadesk:['91 RON Export', '95 RON Export', 'Avgas', 'BO','Jet A', 'LSD', 'RDS',  'ULSD'],
    alldesk:['91 RON Export', '95 RON Export', 'Avgas', 'BO', 'Jet A', 'Kero', 'LSD', 'MGO', 'RDS', 'ULSD']
} 
export const latamDesks: any = [{ region: "ECCA", deskName: "eccadesk", deskId: "19", deskValue: "clean products + latam + ecca desk" }, { region: "WCCA", deskName: "wccadesk", deskId: "20", deskValue: "clean products + latam + wcca desk" },
{ region: "Panama", deskName: "panamadesk", deskId: "21", deskValue: "clean products + latam + panama desk" },{ region: "All", deskName: "alldesk", deskId: "25", deskValue: "clean products + latam + all desk" }
]

export const dummyDeskData = {
  "success": true,
  "value": {
    "regionDeskMapping": [
      {
        "region": "LATAM",
        "deskMappings": [
          {
            "deskId": 19,
            "deskName": "ECCA",
            "deskPortMapping": [
              {
                "portName": "PUERTO BARRIOS",
                "countryName": "Guatemala",
                "countryCode": "GT",
                "unLoCode": "GTPBR",
                "portTerminalMapping": [
                  {
                    "terminalName": "CHEVRON (EX UNITED FRUIT)",
                    "unLoCode": "GTPBR",
                    "portName": "PUERTO BARRIOS"
                  },
                  {
                    "terminalName": "COBIGUA",
                    "unLoCode": "GTPBR",
                    "portName": "PUERTO BARRIOS"
                  },
                  {
                    "terminalName": "TEXACO/SANTO TOMAS TERMINAL",
                    "unLoCode": "GTPBR",
                    "portName": "PUERTO BARRIOS"
                  }
                ]
              },
              {
                "portName": "SANTO TOMAS DE CASTILLA",
                "countryName": "Guatemala",
                "countryCode": "GT",
                "unLoCode": "GTSTC",
                "portTerminalMapping": [
                  {
                    "terminalName": "TERMINALES DEL ATLANTICO, S.A.(EX GOVERNMENT PIER )",
                    "unLoCode": "GTSTC",
                    "portName": "SANTO TOMAS DE CASTILLA"
                  }
                ]
              },
              {
                "portName": "GUANAJA",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNGJA",
                "portTerminalMapping": [
                  {
                    "terminalName": "ANCHORAGE",
                    "unLoCode": "HNGJA",
                    "portName": "GUANAJA"
                  }
                ]
              },
              {
                "portName": "LA CEIBA",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNLCE",
                "portTerminalMapping": []
              },
              {
                "portName": "MAHOGANY BAY",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNRTM",
                "portTerminalMapping": []
              },
              {
                "portName": "OMOA",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNOMO",
                "portTerminalMapping": [
                  {
                    "terminalName": "GAS DEL CARIBE",
                    "unLoCode": "HNOMO",
                    "portName": "OMOA"
                  }
                ]
              },
              {
                "portName": "PORT OF ROATAN",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNRTB",
                "portTerminalMapping": []
              },
              {
                "portName": "PUERTO CASTILLA",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNPCA",
                "portTerminalMapping": [
                  {
                    "terminalName": "EMPRESA NAC. PORTUARIA (EX ANCHORAGE)",
                    "unLoCode": "HNPCA",
                    "portName": "PUERTO CASTILLA"
                  }
                ]
              },
              {
                "portName": "PUERTO CORTES",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNPCR",
                "portTerminalMapping": [
                  {
                    "terminalName": "REFINERIA TEXACO DE HONDURAS",
                    "unLoCode": "HNPCR",
                    "portName": "PUERTO CORTES"
                  },
                  {
                    "terminalName": "TEH/LOGRA UNO HONDURAS",
                    "unLoCode": "HNPCR",
                    "portName": "PUERTO CORTES"
                  },
                  {
                    "terminalName": "UNO/MOLASSES/CHEMICALS",
                    "unLoCode": "HNPCR",
                    "portName": "PUERTO CORTES"
                  }
                ]
              },
              {
                "portName": "SAN LORENZO",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNSLO",
                "portTerminalMapping": [
                  {
                    "terminalName": "GOVERNMENT WHARF (EMPRESA NACIONAL PORTUARIA)",
                    "unLoCode": "HNSLO",
                    "portName": "SAN LORENZO"
                  }
                ]
              },
              {
                "portName": "TELA",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNTEA",
                "portTerminalMapping": [
                  {
                    "terminalName": "PUMA ENERGY /  EMPRESA NACIONAL PORTUARIA",
                    "unLoCode": "HNTEA",
                    "portName": "TELA"
                  }
                ]
              },
              {
                "portName": "TRUJILLO",
                "countryName": "Honduras",
                "countryCode": "HN",
                "unLoCode": "HNTJI",
                "portTerminalMapping": []
              },
              {
                "portName": "BARRANQUILLA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBAQ",
                "portTerminalMapping": [
                  {
                    "terminalName": "ATLANTIC COAL TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COLTERMINALES (VOPAK)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COMPASS TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "ESSO COLOMBIANA TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "MONOMEROS",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "PORT MAGDALENA TERMINAL (EX - ATLANTIC OIL TERMINAL)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP MICHELLMAR TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP PALERMO",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  }
                ]
              },
              {
                "portName": "BUENAVENTURA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBUN",
                "portTerminalMapping": [
                  {
                    "terminalName": "PORT SOCIETY OF BUENAVENTURA PIER NO 14",
                    "unLoCode": "COBUN",
                    "portName": "BUENAVENTURA"
                  }
                ]
              },
              {
                "portName": "CARTAGENA (COL)",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCTG",
                "portTerminalMapping": [
                  {
                    "terminalName": "ALGRANEL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "CHEVRON TEXACO",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "DIQUE DE LACURRA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ECOPETROL(MAMONAL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "MOBIL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OIL TANKING TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OKIANUS TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PETROQUIMICA TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PROPILCO/MEXICHEM",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTO BAHIA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTOS DEL CARIBE SOCIEDAD(EXXON/COPEC/TERPEL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "Ship to Ship",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "SOCIEDAD PORTUARIAPUERTOS DEL CARIBE",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "TEXACO MAMONAL TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "VOPAK TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ZONA FRANCA - PLEXAPORT",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  }
                ]
              },
              {
                "portName": "COVENAS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCVE",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL OFFSHORE",
                    "unLoCode": "COCVE",
                    "portName": "COVENAS"
                  }
                ]
              },
              {
                "portName": "MAMONAL",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COMAM",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL TERMINAL",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  },
                  {
                    "terminalName": "SPEC LNG TERMINAL (HOEGH GRACE)",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  }
                ]
              },
              {
                "portName": "MAMONAL",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COMAM",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL TERMINAL",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  },
                  {
                    "terminalName": "SPEC LNG TERMINAL (HOEGH GRACE)",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  }
                ]
              },
              {
                "portName": "POZOS COLORADOS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPOC",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL (STA.MARTA)",
                    "unLoCode": "COPOC",
                    "portName": "POZOS COLORADOS"
                  }
                ]
              },
              {
                "portName": "PUERTO BOLIVAR",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPBO",
                "portTerminalMapping": [
                  {
                    "terminalName": "CARBONES DEL CERREJON",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  },
                  {
                    "terminalName": "PUNTA ARENAS TERMINAL",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  }
                ]
              },
              {
                "portName": "PUERTO BOLIVAR",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPBO",
                "portTerminalMapping": [
                  {
                    "terminalName": "CARBONES DEL CERREJON",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  },
                  {
                    "terminalName": "PUNTA ARENAS TERMINAL",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  }
                ]
              },
              {
                "portName": "PUERTO BRISA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO001",
                "portTerminalMapping": []
              },
              {
                "portName": "PUERTO DRUMMOND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO002",
                "portTerminalMapping": []
              },
              {
                "portName": "RIO CORDOBA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CORCO",
                "portTerminalMapping": []
              },
              {
                "portName": "SAN ANDRES ISLAND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSAC",
                "portTerminalMapping": [
                  {
                    "terminalName": "CHEVRON TERMINAL SAN ANDRES",
                    "unLoCode": "COSAC",
                    "portName": "SAN ANDRES ISLAND"
                  }
                ]
              },
              {
                "portName": "SANTA MARTA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSMR",
                "portTerminalMapping": [
                  {
                    "terminalName": "CI INTERNATIONAL",
                    "unLoCode": "COSMR",
                    "portName": "SANTA MARTA"
                  }
                ]
              },
              {
                "portName": "TOLU",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTLU",
                "portTerminalMapping": []
              },
              {
                "portName": "TUMACO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTCO",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL SA TUMACO TERMINAL",
                    "unLoCode": "COTCO",
                    "portName": "TUMACO"
                  }
                ]
              },
              {
                "portName": "TURBO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTRB",
                "portTerminalMapping": []
              },
              {
                "portName": "PASCAGOULA MS",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USPGL",
                "portTerminalMapping": [
                  {
                    "terminalName": "BAYOU CASOTTE",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "CHEVRON",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "LIGHTERING",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  }
                ]
              },
              {
                "portName": "RICHMOND CA",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USRCH",
                "portTerminalMapping": [
                  {
                    "terminalName": "ARCO (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP LUBRICANTS USA INC (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP WEST COAST PRODUCTS (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CALIFORNIA OILS CORPORATION",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CHEVRON LONG WHARF MARINE OIL TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CONOCO PHILLIPS - KINDER MORGAN",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "FOSS MARITIME COMPANY",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "IMTT (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PACIFIC ATLANTIC TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PT. POTRERO MARINE TERMINAL (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "SAUSE BROS",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  }
                ]
              }
            ],
            "udmCreatedOn": null,
            "udmCreatedBy": null,
            "udmLastModifiedOn": null,
            "udmLastModifiedBy": null
          },
          {
            "deskId": 20,
            "deskName": "WCCA",
            "deskPortMapping": [
              {
                "portName": "PUERTO QUETZAL",
                "countryName": "Guatemala",
                "countryCode": "GT",
                "unLoCode": "GTPRQ",
                "portTerminalMapping": [
                  {
                    "terminalName": "DARSA TERMINAL",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  },
                  {
                    "terminalName": "MARINA PAZ VELA TERMINAL",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  },
                  {
                    "terminalName": "PACIFIC OIL",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  },
                  {
                    "terminalName": "PUERTO QUETZAL POWER (EX. ENRON)",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  },
                  {
                    "terminalName": "TGM PETCOKE TERMINAL",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  },
                  {
                    "terminalName": "ZETA GAS DE CENTRO AMERICA",
                    "unLoCode": "GTPRQ",
                    "portName": "PUERTO QUETZAL"
                  }
                ]
              },
              {
                "portName": "SAN JOSE",
                "countryName": "Guatemala",
                "countryCode": "GT",
                "unLoCode": "GTSNJ",
                "portTerminalMapping": [
                  {
                    "terminalName": "MELAZAS DE ESCUINTLA",
                    "unLoCode": "GTSNJ",
                    "portName": "SAN JOSE"
                  },
                  {
                    "terminalName": "OTSA (CHEVRONTEXACO)",
                    "unLoCode": "GTSNJ",
                    "portName": "SAN JOSE"
                  },
                  {
                    "terminalName": "PUMA (COPENSA)",
                    "unLoCode": "GTSNJ",
                    "portName": "SAN JOSE"
                  }
                ]
              },
              {
                "portName": "ACAJUTLA",
                "countryName": "El Salvador",
                "countryCode": "SV",
                "unLoCode": "SVAQJ",
                "portTerminalMapping": [
                  {
                    "terminalName": "CENERGICA Ex-COASTAL TECHNOLOGY OFFSHORE",
                    "unLoCode": "SVAQJ",
                    "portName": "ACAJUTLA"
                  },
                  {
                    "terminalName": "CEPA (PORT AUTHORITY COMMON USER)",
                    "unLoCode": "SVAQJ",
                    "portName": "ACAJUTLA"
                  },
                  {
                    "terminalName": "ENERGIA DEL PACIFICO LNG TERMINAL.",
                    "unLoCode": "SVAQJ",
                    "portName": "ACAJUTLA"
                  },
                  {
                    "terminalName": "RASA REFINERY",
                    "unLoCode": "SVAQJ",
                    "portName": "ACAJUTLA"
                  }
                ]
              },
              {
                "portName": "CUTUCO",
                "countryName": "El Salvador",
                "countryCode": "SV",
                "unLoCode": "SVEZB",
                "portTerminalMapping": []
              },
              {
                "portName": "BARRANQUILLA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBAQ",
                "portTerminalMapping": [
                  {
                    "terminalName": "ATLANTIC COAL TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COLTERMINALES (VOPAK)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COMPASS TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "ESSO COLOMBIANA TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "MONOMEROS",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "PORT MAGDALENA TERMINAL (EX - ATLANTIC OIL TERMINAL)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP MICHELLMAR TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP PALERMO",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  }
                ]
              },
              {
                "portName": "BUENAVENTURA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBUN",
                "portTerminalMapping": [
                  {
                    "terminalName": "PORT SOCIETY OF BUENAVENTURA PIER NO 14",
                    "unLoCode": "COBUN",
                    "portName": "BUENAVENTURA"
                  }
                ]
              },
              {
                "portName": "CARTAGENA (COL)",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCTG",
                "portTerminalMapping": [
                  {
                    "terminalName": "ALGRANEL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "CHEVRON TEXACO",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "DIQUE DE LACURRA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ECOPETROL(MAMONAL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "MOBIL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OIL TANKING TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OKIANUS TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PETROQUIMICA TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PROPILCO/MEXICHEM",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTO BAHIA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTOS DEL CARIBE SOCIEDAD(EXXON/COPEC/TERPEL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "Ship to Ship",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "SOCIEDAD PORTUARIAPUERTOS DEL CARIBE",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "TEXACO MAMONAL TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "VOPAK TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ZONA FRANCA - PLEXAPORT",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  }
                ]
              },
              {
                "portName": "COVENAS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCVE",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL OFFSHORE",
                    "unLoCode": "COCVE",
                    "portName": "COVENAS"
                  }
                ]
              },
              {
                "portName": "MAMONAL",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COMAM",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL TERMINAL",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  },
                  {
                    "terminalName": "SPEC LNG TERMINAL (HOEGH GRACE)",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  }
                ]
              },
              {
                "portName": "POZOS COLORADOS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPOC",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL (STA.MARTA)",
                    "unLoCode": "COPOC",
                    "portName": "POZOS COLORADOS"
                  }
                ]
              },
              {
                "portName": "PUERTO BOLIVAR",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPBO",
                "portTerminalMapping": [
                  {
                    "terminalName": "CARBONES DEL CERREJON",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  },
                  {
                    "terminalName": "PUNTA ARENAS TERMINAL",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  }
                ]
              },
              {
                "portName": "PUERTO BRISA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO001",
                "portTerminalMapping": []
              },
              {
                "portName": "PUERTO DRUMMOND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO002",
                "portTerminalMapping": []
              },
              {
                "portName": "RIO CORDOBA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CORCO",
                "portTerminalMapping": []
              },
              {
                "portName": "SAN ANDRES ISLAND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSAC",
                "portTerminalMapping": [
                  {
                    "terminalName": "CHEVRON TERMINAL SAN ANDRES",
                    "unLoCode": "COSAC",
                    "portName": "SAN ANDRES ISLAND"
                  }
                ]
              },
              {
                "portName": "SANTA MARTA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSMR",
                "portTerminalMapping": [
                  {
                    "terminalName": "CI INTERNATIONAL",
                    "unLoCode": "COSMR",
                    "portName": "SANTA MARTA"
                  }
                ]
              },
              {
                "portName": "TOLU",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTLU",
                "portTerminalMapping": []
              },
              {
                "portName": "TUMACO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTCO",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL SA TUMACO TERMINAL",
                    "unLoCode": "COTCO",
                    "portName": "TUMACO"
                  }
                ]
              },
              {
                "portName": "TURBO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTRB",
                "portTerminalMapping": []
              },
              {
                "portName": "PASCAGOULA MS",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USPGL",
                "portTerminalMapping": [
                  {
                    "terminalName": "BAYOU CASOTTE",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "CHEVRON",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "LIGHTERING",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  }
                ]
              },
              {
                "portName": "RICHMOND CA",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USRCH",
                "portTerminalMapping": [
                  {
                    "terminalName": "ARCO (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP LUBRICANTS USA INC (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP WEST COAST PRODUCTS (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CALIFORNIA OILS CORPORATION",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CHEVRON LONG WHARF MARINE OIL TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CONOCO PHILLIPS - KINDER MORGAN",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "FOSS MARITIME COMPANY",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "IMTT (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PACIFIC ATLANTIC TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PT. POTRERO MARINE TERMINAL (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "SAUSE BROS",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  }
                ]
              }
            ],
            "udmCreatedOn": null,
            "udmCreatedBy": null,
            "udmLastModifiedOn": null,
            "udmLastModifiedBy": null
          },
          {
            "deskId": 21,
            "deskName": "Panama",
            "deskPortMapping": [
              {
                "portName": "AGUADULCE",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAAGD",
                "portTerminalMapping": []
              },
              {
                "portName": "ALMIRANTE",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPAM",
                "portTerminalMapping": []
              },
              {
                "portName": "BAHIA DE LAS MINAS",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPBM",
                "portTerminalMapping": [
                  {
                    "terminalName": "VOPAK TERMINAL (EX - REFINERIA PANAMA)",
                    "unLoCode": "PAPBM",
                    "portName": "BAHIA DE LAS MINAS"
                  }
                ]
              },
              {
                "portName": "BALBOA",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PABLB",
                "portTerminalMapping": [
                  {
                    "terminalName": "BALBOA TERMINAL",
                    "unLoCode": "PABLB",
                    "portName": "BALBOA"
                  },
                  {
                    "terminalName": "FISH OIL TERMINAL",
                    "unLoCode": "PABLB",
                    "portName": "BALBOA"
                  },
                  {
                    "terminalName": "PANAMA PORTS CO (AUTH PORT NACIONAL)",
                    "unLoCode": "PABLB",
                    "portName": "BALBOA"
                  },
                  {
                    "terminalName": "PATSA (EX PIMPSA)",
                    "unLoCode": "PABLB",
                    "portName": "BALBOA"
                  }
                ]
              },
              {
                "portName": "BOCAS DEL TORO",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PABOC",
                "portTerminalMapping": []
              },
              {
                "portName": "CHIRIQUI GRANDE",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PACHG",
                "portTerminalMapping": [
                  {
                    "terminalName": "PETROTERMINAL DE PANAMA",
                    "unLoCode": "PACHG",
                    "portName": "CHIRIQUI GRANDE"
                  }
                ]
              },
              {
                "portName": "COLON",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAONX",
                "portTerminalMapping": [
                  {
                    "terminalName": "OILTANKING COLON",
                    "unLoCode": "PAONX",
                    "portName": "COLON"
                  }
                ]
              },
              {
                "portName": "CRISTOBAL",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PACTB",
                "portTerminalMapping": [
                  {
                    "terminalName": "COLON OIL AND SERVICES S.A. (OILTANKING) COCO SOLO",
                    "unLoCode": "PACTB",
                    "portName": "CRISTOBAL"
                  },
                  {
                    "terminalName": "COSTA NORTE LNG TERMINAL",
                    "unLoCode": "PACTB",
                    "portName": "CRISTOBAL"
                  },
                  {
                    "terminalName": "EXPLOSIVE ANCHORAGE A",
                    "unLoCode": "PACTB",
                    "portName": "CRISTOBAL"
                  },
                  {
                    "terminalName": "PANAMA PORTS CO.(Ex-AUTH PORT NACIONAL)",
                    "unLoCode": "PACTB",
                    "portName": "CRISTOBAL"
                  },
                  {
                    "terminalName": "TELFER TANKS",
                    "unLoCode": "PACTB",
                    "portName": "CRISTOBAL"
                  }
                ]
              },
              {
                "portName": "FORT AMADOR",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PA001",
                "portTerminalMapping": []
              },
              {
                "portName": "LA PALMA",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPLP",
                "portTerminalMapping": []
              },
              {
                "portName": "LIGHTERING PANAMA (PANPAC)",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PA003",
                "portTerminalMapping": [
                  {
                    "terminalName": "ANCHORAGE - LIGHTERING (BALBOA)",
                    "unLoCode": "PA003",
                    "portName": "LIGHTERING PANAMA (PANPAC)"
                  },
                  {
                    "terminalName": "LIGHTERING PANAMA OFFSHORE",
                    "unLoCode": "PA003",
                    "portName": "LIGHTERING PANAMA (PANPAC)"
                  }
                ]
              },
              {
                "portName": "LIGHTERING PANAMA (PANPAC)",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PA003",
                "portTerminalMapping": [
                  {
                    "terminalName": "ANCHORAGE - LIGHTERING (BALBOA)",
                    "unLoCode": "PA003",
                    "portName": "LIGHTERING PANAMA (PANPAC)"
                  },
                  {
                    "terminalName": "LIGHTERING PANAMA OFFSHORE",
                    "unLoCode": "PA003",
                    "portName": "LIGHTERING PANAMA (PANPAC)"
                  }
                ]
              },
              {
                "portName": "MANZANILLO INTERNATIONAL (MIT)",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAMIT",
                "portTerminalMapping": []
              },
              {
                "portName": "MELONES",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAMEL",
                "portTerminalMapping": [
                  {
                    "terminalName": "MELONES OIL TERMINAL",
                    "unLoCode": "PAMEL",
                    "portName": "MELONES"
                  }
                ]
              },
              {
                "portName": "PANAMA CANAL",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPCN",
                "portTerminalMapping": [
                  {
                    "terminalName": "TRANSIT",
                    "unLoCode": "PAPCN",
                    "portName": "PANAMA CANAL"
                  }
                ]
              },
              {
                "portName": "PEDREGAL",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPED",
                "portTerminalMapping": []
              },
              {
                "portName": "PUERTO ARMUELLES",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAAML",
                "portTerminalMapping": [
                  {
                    "terminalName": "OFFSHORE LIGHTERING AREA",
                    "unLoCode": "PAAML",
                    "portName": "PUERTO ARMUELLES"
                  },
                  {
                    "terminalName": "PTP",
                    "unLoCode": "PAAML",
                    "portName": "PUERTO ARMUELLES"
                  }
                ]
              },
              {
                "portName": "PUERTO ARMUELLES",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAAML",
                "portTerminalMapping": [
                  {
                    "terminalName": "OFFSHORE LIGHTERING AREA",
                    "unLoCode": "PAAML",
                    "portName": "PUERTO ARMUELLES"
                  },
                  {
                    "terminalName": "PTP",
                    "unLoCode": "PAAML",
                    "portName": "PUERTO ARMUELLES"
                  }
                ]
              },
              {
                "portName": "PUNTA RINCON",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAPRI",
                "portTerminalMapping": [
                  {
                    "terminalName": "PUNTA RINCON INTERNATIONAL TERMINAL",
                    "unLoCode": "PAPRI",
                    "portName": "PUNTA RINCON"
                  }
                ]
              },
              {
                "portName": "TABOGUILLA ISLAND",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PATBG",
                "portTerminalMapping": [
                  {
                    "terminalName": "DECAL PANAMA",
                    "unLoCode": "PATBG",
                    "portName": "TABOGUILLA ISLAND"
                  }
                ]
              },
              {
                "portName": "VACAMONTE",
                "countryName": "Panama",
                "countryCode": "PA",
                "unLoCode": "PAVAC",
                "portTerminalMapping": []
              },
              {
                "portName": "BARRANQUILLA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBAQ",
                "portTerminalMapping": [
                  {
                    "terminalName": "ATLANTIC COAL TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COLTERMINALES (VOPAK)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "COMPASS TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "ESSO COLOMBIANA TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "MONOMEROS",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "PORT MAGDALENA TERMINAL (EX - ATLANTIC OIL TERMINAL)",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP MICHELLMAR TERMINAL",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  },
                  {
                    "terminalName": "SP PALERMO",
                    "unLoCode": "COBAQ",
                    "portName": "BARRANQUILLA"
                  }
                ]
              },
              {
                "portName": "BUENAVENTURA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COBUN",
                "portTerminalMapping": [
                  {
                    "terminalName": "PORT SOCIETY OF BUENAVENTURA PIER NO 14",
                    "unLoCode": "COBUN",
                    "portName": "BUENAVENTURA"
                  }
                ]
              },
              {
                "portName": "CARTAGENA (COL)",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCTG",
                "portTerminalMapping": [
                  {
                    "terminalName": "ALGRANEL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "CHEVRON TEXACO",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "DIQUE DE LACURRA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ECOPETROL(MAMONAL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "MOBIL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OIL TANKING TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "OKIANUS TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PETROQUIMICA TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PROPILCO/MEXICHEM",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTO BAHIA",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "PUERTOS DEL CARIBE SOCIEDAD(EXXON/COPEC/TERPEL)",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "Ship to Ship",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "SOCIEDAD PORTUARIAPUERTOS DEL CARIBE",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "TEXACO MAMONAL TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "VOPAK TERMINAL",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  },
                  {
                    "terminalName": "ZONA FRANCA - PLEXAPORT",
                    "unLoCode": "COCTG",
                    "portName": "CARTAGENA (COL)"
                  }
                ]
              },
              {
                "portName": "COVENAS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COCVE",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL OFFSHORE",
                    "unLoCode": "COCVE",
                    "portName": "COVENAS"
                  }
                ]
              },
              {
                "portName": "MAMONAL",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COMAM",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL TERMINAL",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  },
                  {
                    "terminalName": "SPEC LNG TERMINAL (HOEGH GRACE)",
                    "unLoCode": "COMAM",
                    "portName": "MAMONAL"
                  }
                ]
              },
              {
                "portName": "POZOS COLORADOS",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPOC",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL (STA.MARTA)",
                    "unLoCode": "COPOC",
                    "portName": "POZOS COLORADOS"
                  }
                ]
              },
              {
                "portName": "PUERTO BOLIVAR",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COPBO",
                "portTerminalMapping": [
                  {
                    "terminalName": "CARBONES DEL CERREJON",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  },
                  {
                    "terminalName": "PUNTA ARENAS TERMINAL",
                    "unLoCode": "COPBO",
                    "portName": "PUERTO BOLIVAR"
                  }
                ]
              },
              {
                "portName": "PUERTO BRISA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO001",
                "portTerminalMapping": []
              },
              {
                "portName": "PUERTO DRUMMOND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CO002",
                "portTerminalMapping": []
              },
              {
                "portName": "RIO CORDOBA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "CORCO",
                "portTerminalMapping": []
              },
              {
                "portName": "SAN ANDRES ISLAND",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSAC",
                "portTerminalMapping": [
                  {
                    "terminalName": "CHEVRON TERMINAL SAN ANDRES",
                    "unLoCode": "COSAC",
                    "portName": "SAN ANDRES ISLAND"
                  }
                ]
              },
              {
                "portName": "SANTA MARTA",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COSMR",
                "portTerminalMapping": [
                  {
                    "terminalName": "CI INTERNATIONAL",
                    "unLoCode": "COSMR",
                    "portName": "SANTA MARTA"
                  }
                ]
              },
              {
                "portName": "TOLU",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTLU",
                "portTerminalMapping": []
              },
              {
                "portName": "TUMACO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTCO",
                "portTerminalMapping": [
                  {
                    "terminalName": "ECOPETROL SA TUMACO TERMINAL",
                    "unLoCode": "COTCO",
                    "portName": "TUMACO"
                  }
                ]
              },
              {
                "portName": "TURBO",
                "countryName": "Colombia",
                "countryCode": "CO",
                "unLoCode": "COTRB",
                "portTerminalMapping": []
              },
              {
                "portName": "PASCAGOULA MS",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USPGL",
                "portTerminalMapping": [
                  {
                    "terminalName": "BAYOU CASOTTE",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "CHEVRON",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  },
                  {
                    "terminalName": "LIGHTERING",
                    "unLoCode": "USPGL",
                    "portName": "PASCAGOULA MS"
                  }
                ]
              },
              {
                "portName": "RICHMOND CA",
                "countryName": "United States",
                "countryCode": "US",
                "unLoCode": "USRCH",
                "portTerminalMapping": [
                  {
                    "terminalName": "ARCO (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP LUBRICANTS USA INC (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "BP WEST COAST PRODUCTS (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CALIFORNIA OILS CORPORATION",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CHEVRON LONG WHARF MARINE OIL TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "CONOCO PHILLIPS - KINDER MORGAN",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "FOSS MARITIME COMPANY",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "IMTT (RICHMOND)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PACIFIC ATLANTIC TERMINAL",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "PT. POTRERO MARINE TERMINAL (PRIVATELY OWNED)",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  },
                  {
                    "terminalName": "SAUSE BROS",
                    "unLoCode": "USRCH",
                    "portName": "RICHMOND CA"
                  }
                ]
              }
            ],
            "udmCreatedOn": null,
            "udmCreatedBy": null,
            "udmLastModifiedOn": null,
            "udmLastModifiedBy": null
          }
        ]
      }
    ]
  },
  "statusCode": 200
}
export const ProdSraCode: any = {
    "262926990":"Prod1",
    "380045990":"Prod2",
    "380053990":"Prod3",
    "670041000":"Prod4",
    "850022000":"Prod5",
    "850026000":"Prod6",
    "200206990":"Prod7",
    "217175990":"Prod8",
    "440035000":"Prod9",
    "380044990":"Prod10",
    "380052990": "Prod11",  
    "276351990":"Prod12",
    "276352990": "Prod13",
    "380050990": "Prod14",
    "380058990": "Prod15", 
    "217176990": "Prod16",  
    "269599990": "Prod17", 
}

export const productLabels:any={
    "lsdQty":"LSD" ,
    "ron91Qty": "91 RON Export",
    "ron95Qty": "95 RON Export",
    "keroQty":"Kero",
    "jetAQty":"Jet A",
    "avgasQty":"Avgas",
    "ulsdQty":"ULSD",
    "rdsQty":"RDS",
    "boQty":"BO",
    "mgoQty":"MGO"
}

export const prodTSWDesc: any = {
    "91 RON Export_ECCA": "MOGAS-REG (GT/HN/SV)",
    "95 RON Export_ECCA": "MOGAS-PRM (GT/HN/SV)",
    "Jet A_ECCA": "JET A (GT/HN/PA/SV)",
    "Avgas_ECCA": "AVGAS 100 (CO/PA)",
    "Kero_ECCA": "KEROSENE (CO/GT/HN/SV)",
    "LSD_ECCA": "LOW SULFUR DIESEL (PAN, HN, GT, SV)",
    "Jet A_PANAMA": "JET A (GT/HN/PA/SV)",
    "ULSD_PANAMA": "ULTRA LOW SULFUR DIESEL (PAN)",
    "95 RON Export_PANAMA": "MOGAS-PRM (PA)",
    "91 RON Export_PANAMA": "MOGAS-REG (PA)",
    "MGO_PANAMA": "Marine Diesel Oil",
    "Avgas_PANAMA": "AVGAS 100 (CO/PA)",
    "LSD_WCCA": "LOW SULFUR DIESEL (PAN, HN, GT, SV)",
    "ULSD_WCCA": "ULTRA LOW SULFUR DIESEL (PAN)",
    "RDS_WCCA": "RDS Diesel Bdstock Export",
    "91 RON Export_WCCA": "MOGAS-REG (GT/HN/SV)",
    "95 RON Export_WCCA": "MOGAS-PRM (GT/HN/SV)",
    "BO_WCCA": null,
    "Jet A_WCCA": "JET A (GT/HN/PA/SV)",
    "Avgas_WCCA": "AVGAS 100 (CO/PA)"
}

export const vessels = ['Combi (PASC)','Mogas (RIC)','Diesel (PASC)','TBD (PASC)','TBD']

export const cargoTypes = ['Load', 'Discharge'];

export const uomTypes=['MB','GA','BR'];

