<div class="delete-alerts">
  <div class="label-3">you can't delete this rule because there are other users getting notifications for it.</div>
  <div class="label-1">list of users getting notifications by this rule<button class="expand-button" (click)="showUsers=true"><img src="../../assets/icons/icon-expand-list.svg" alt="plus-icon"></button></div>
  <div *ngIf="showUsers == true">
    <hr>
    <ul>
      <li class="label-2" *ngFor="let i of selectedUserList">
        {{ i }}
      </li>
    </ul>
  </div>
  <div>&nbsp;</div>
  <div class="label-3">but you can turn your notification off for this rule.</div>
  <div>&nbsp;</div>
  <div class="form-row pb-1 pr-2">
      <div class="form-group col-md-6 branded">
          <button class="cancel-btn" (click)="closePopup(); showUsers=false">cancel</button>
      </div>
      <div class="form-group col-md-6 branded">
          <button class="delete-rule-btn" (click)="updateAlert(); showUsers=false">turn notification off<img src="../../assets/icons/icon-alert-silence.svg" alt="delete-icon"></button>
      </div>
  </div>
</div>


