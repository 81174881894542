<div class="create-nominations-panel-wrapper">
  <div class="panel-header d-flex justify-content-between">
    <div class="panel-title">nomination panel</div>
    <div *ngIf="!isWindowOpened">
      <button class="nominations-btn nominations-panel nominations" (click)="closeNominationsPanel()">
        <span class="icon-remove open"></span>
      </button>
    </div>
  </div>
  <div class="admin-side-panel">
    <div class="additionalCheckbox">
      <p class="align-items-center d-flex">
        <input type="checkbox" class="flex-shrink-0" name="chkAddInfo" [checked]="false"
          (change)="applyAddInfo($event)">&nbsp;
        <span class="text-ellipsis">
          <strong>additional information</strong>
        </span>
      </p>
    </div>
  </div>

  <div class="nom-info-main-wrapper"  *ngFor="let data of lstAllNominations;let i = index" >
    <div class="nom-title" >
      <span class="icon-chevron-down dropdown-trigger" (click)="collapseNomiDetail($event,data?.nominationKey)" ></span><strong> {{data?.nominationNo}} </strong>
      <span> | {{data?.nominationKey}} </span>      
      <span class="align-qty-right">
        <div class="custom-dropdown">
          <button class="icon-btn action-icon dropdown" style="top:3px;right: -20px;"></button>
        
          <div class="dropdown-menu-wrap">
            <div class="dropdown-menu">
                <div class="dropdown-item dropdown-label">
                    <li (click)="onCreateDraftNomination('edit',data?.nominationNo)">
                        edit nomination
                    </li>
                </div>
            </div>
          </div>
        </div>
      </span>
      <span class="align-qty-right"><strong>{{data?.scheduledQty}}</strong>
      </span>
    </div>
    <div class="nom-tab-list collapse" id="nomiDetails-{{data?.nominationKey}}">
      <div class="tab-content">
        <nomination-panel [nominationDetails]="data.details" [addDetails]="chkAddDetails"></nomination-panel>
      </div>
    </div>
  </div>

</div>