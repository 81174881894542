import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-inventory-field',
  templateUrl: './inventory-field.component.html',
  styleUrls: ['./inventory-field.component.scss']
})
export class InventoryFieldComponent implements OnInit {

  @Input() selectedReference!: string;
  @Input() selectedNoOfDays!: string;
  @Input() selectedRules!: string;
  @Input() selectedParameters!: string;
  @Output() public inventoryData = new EventEmitter<{selectedReference: string, selectedNoOfDays: string, selectedRules: string, selectedParameters: string}>();


  public referenceDropdown = ["next"]; //Removed "previous"
  public amountOfDaysDropdown = ["5", "10"];
  public ruleDropdown = ["<=",">="];
  private minParameterDropdown: string[] = ["MinESL","MinInventory"];
  private  maxParameterDropdown: string[] = ["MaxESL", "MaxInventory"];
  parameterOptions: string[] = [];  

  constructor() { }

  ngOnInit(): void {
    this.editCheckSetup();
  }

  shareInventoryDetails(){
    this.inventoryData.emit({selectedReference: this.selectedReference, selectedNoOfDays: this.selectedNoOfDays,selectedRules: this.selectedRules,selectedParameters: this.selectedParameters});
  }

  updateParameters(){
    this.selectedParameters = '';
    this.parameterDropDownSetup();
    this.shareInventoryDetails();
  }

  //If we edit, all @Input values are defiend, so we can set the dropdown to avoid errors 
  editCheckSetup(){
    if(this.selectedReference != '' && this.selectedNoOfDays != '' && this.selectedRules != '' && this.selectedParameters != ''){
       this.parameterDropDownSetup();
    }
  }

  parameterDropDownSetup(){
    this.parameterOptions = this.selectedRules == '<=' ? this.minParameterDropdown: this.maxParameterDropdown; 
  }
}
