import { createAction, props } from "@ngrx/store";
import { FilterObj } from "../../interface/contract-balancing.interface";
import { EthanolFilterValues } from "../../interface/ethanol-view.interface";
import { PipelineFilterValues } from "../../interface/pipeline-view.interface";
import { AppliedFilters, FilterValues, GetTRPayload, RouteFilterValues } from "../../interface/terminal-replenishment.interface";
import { CargoPlanCcaFilterValues } from "../../interface/cargo-plan-cca-interface";
import { MDMDeskValues } from 'src/app/shared/interface/mdm.interface';

export const setAPIStatus = createAction(
    '[App API] set success or failure status',
    props<{ apiStatus: string, apiResponseMessage: string }>()
);

export const saveDefaultTRFilters = createAction(
    '[App API] save Default TR Filters',
    props<{ totalTRFiltersResponse: FilterValues[], receivedTotalFiltersResponse: boolean, updatedOfftakers: boolean, receivedUpdatedFilterSelection: boolean, isTRGrid: boolean }>()
);

export const getDefaultTRFilters = createAction(
    '[App API] get Default TR Filters',
    props<{ totalTRFiltersResponse: FilterValues[], updatedFilterSelection: AppliedFilters, receivedTotalFiltersResponse: boolean, updatedOfftakers: boolean, isTRGrid: boolean, isClearFilter: boolean, }>()
);

export const saveupdatedURLParams = createAction(
    '[App API] save updated URL Parameters',
    props<{ totalTRFiltersResponse: FilterValues[], receivedTotalFiltersResponse: boolean, updatedOfftakers: boolean, receivedUpdatedFilterSelection: boolean }>()
);

export const updateURLParams = createAction(
    '[App API] update URL Parameters',
    props<{ updateURLParamsState: RouteFilterValues }>()
);

export const saveAppliedTRFilters = createAction(
    '[App API] save Applied TR Filters',
    props<{ updatedFilterSelection: AppliedFilters, receivedUpdatedFilterSelection: boolean, isClearFilter: boolean, isTRSaveEnabled: boolean, isTRGrid: boolean }>()
);


export const getAppliedTRFilters = createAction(
    '[App API] get Default TR Filters',
    props<{ updatedFilterSelection: AppliedFilters, receivedUpdatedFilterSelection: boolean, isTRGrid: boolean }>()
);

export const enableClearFilter = createAction(
    '[App API] enable Clear Filter',
    props<{ isClearFilter: boolean }>()
);

export const setReceivedResponseBoolean = createAction(
    '[App API] set ReceivedResponse Boolean',
    props<{ isClearFilter: boolean, receivedTotalFiltersResponse: boolean, receivedUpdatedFilterSelection: boolean, isTRGrid: boolean }>()
);

/* export const getReceivedResponseBoolean = createAction(
    '[App API] get ReceivedResponse Boolean',
    props<{ receivedTotalFiltersResponse: boolean, receivedUpdatedFilterSelection: boolean }>()
); */

export const setClearFiltersBoolean = createAction(
    '[App API] set ReceivedResponse Boolean',
    props<{ isClearFilter: boolean }>()
);

export const triggerTRSave = createAction(
    '[TerminalReplenishment API] trigger TRSave',
    props<{ triggeredTRDataSave: boolean, isTRSaveEnabled: boolean, isClearFilter: boolean, receivedTotalFiltersResponse: boolean, receivedUpdatedFilterSelection: boolean }>()
);

export const enableTRSave = createAction(
    '[TerminalReplenishment API] enable TRSave',
    props<{ isTRSaveEnabled: boolean }>()
);

export const saveTRData = createAction(
    '[TerminalReplenishment API] save TRData',
    props<GetTRPayload>()
);

export const triggerTankParamSave = createAction(
    '[MDM API] trigger TankParamSave',
    props<{ triggeredTankParamDataSave: boolean, isTankParamSaveEnabled: boolean }>()
);
  
export const enableTankParamSave = createAction(
    '[MDM API] enable TankParamSave',
    props<{ isTankParamSaveEnabled: boolean }>()
);
  
export const triggerNotifParamSave = createAction(
    '[Rules API] trigger NotifParamSave',
    props<{ triggeredNotifParamDataSave: boolean, isNotifParamSaveEnabled: boolean }>()
);

export const enableNotifParamSave = createAction(
    '[Rules API] enable NotifParamSave',
    props<{ isNotifParamSaveEnabled: boolean }>()
);

export const triggerAdminSettingsSave = createAction(
  '[Admin API] trigger AdminSettingsSave',
  props<{ triggeredAdminSettingsSave: boolean, isAdminSettingsSaveEnabled: boolean }>()
);

export const enableAdminSettingsSave = createAction(
  '[Admin API] enable AdminSettingsSave',
  props<{ isAdminSettingsSaveEnabled: boolean }>()
);

export const setAdminView = createAction(
  '[Admin View API] save Admin View',
  props<{ adminView: string }>()
);

export const setIsAdminViewSwitched = createAction(
    '[Admin View API] set Admin View Switched',
    props<{ isAdminViewSwitched: boolean }>()
);

export const showHideDeletedRules = createAction(
    '[RuleEngine API] show or hide deleted rules',
    props<{triggeredDeletedRules: boolean}>()
)

export const setEnableTableView = createAction(
    '[TerminalReplenishment API] change Table View',
    props<{ isTableView: boolean }>()
);

export const setSwitchView = createAction(
    '[Ethanol View] change switch view',
    props<{ switchView: string, isSwitchUpdated: boolean }>()
);

export const setSelectedDesk = createAction(
    '[Desk Selection] Get Selected Desk',
    props<{ selectedDesk: string, selectedDeskParam: string, selectedDeskId: any }>()
);

export const setTableHeaders = createAction(
    '[Table Headers] Get Table Headers',
    props<{ allTableHeaders: any, isTableHeadersUpdated: boolean }>()
);

export const saveEthanolTRFiltersResponse = createAction(
    'save Ethanol TR Filters',
    props<{ ethanolFilterValues: EthanolFilterValues[], ethanolFromDate?: any, ethanolToDate?: any, isfilterUpdated?: boolean, ethanolTruckToggle?: boolean, unitOfMeasure?: any }>()
);

export const saveEthanolNomFiltersResponse = createAction(
    'save Ethanol Nom Filters',
    props<{ ethanolFilterValues: EthanolFilterValues[], ethanolFromDate?: any, ethanolToDate?: any, isNomFilterUpdated?: boolean }>(),
);

export const saveEthanolNomFiltersUpdated = createAction(
    'save Ethanol Nom Filters Updated',
    props<{ isNomFilterUpdated?: boolean }>()
);

export const savePipelineNomFiltersUpdated = createAction(
    'save Pipeline Nom Filters Updated',
    props<{ isPipelineNomFilterUpdated?: boolean }>()
);

export const saveCargoPlanCcaFiltersUpdated = createAction(
    'save Cargo Plan Cca Filters Updated',
    props<{ isCargoPlanCcaFilterUpdated?: boolean }>()
);

export const initialisePipelineNomFilters = createAction(
    'save Pipeline Nom Filters Updated',
    props<{ isPipelineNomFilterInitialised?: boolean, screenName?: string }>()
);

export const savePipelineTRFiltersUpdated = createAction(
    'save Pipeline TR Filters Updated',
    props<{ isPipelineFilterUpdated?: boolean }>()
);

export const userDetails = createAction(
    'get loggedin user data',
    props<{ userDetails: any }>()
);

export const updatedOfftakersFalse = createAction(
    '[App API] setting updated offtakers to false once the update is done',
    props<{ updatedOfftakers: boolean }>()
);

export const invokeTrNominationList = createAction(
    '[Get TR Nomination List API]',
    props<{ payload?: any }>(),
);

export const invokeTrPipelineNominationList = createAction(
    '[Get TR Pipeline Nomination List API]',
    props<{ payload?: any }>(),
);

export const getTrNominationListSuccess = createAction(
    '[Get TR Nomination List Success API]',
    props<{ trNominationList: any }>(),
);

export const getTrPipelineNominationListSuccess = createAction(
    '[Get TR Pipeline Nomination List Success API]',
    props<{ trPipelineNominationList: any }>(),
);

export const createNomination = createAction(
    '[Create Nomination API]',
    props<{ payload: any }>(),
);

export const createNominationSuccess = createAction(
    '[Create Nomination Success API]',
    props<{ createdNominationResponse: any }>(),
);

export const clearCreateNominationApiResponse = createAction(
    '[Clear create Nomination API Response]',
    props<{ createNominationApiResponse: any }>(),
)

export const setUDMScreen = createAction(
    '[App API] set UDM Screen',
    props<{ screen: string, receivedTotalFiltersResponse: boolean }>()
);


export const getTRTruckDataAPISuccess = createAction(
    '[Truck API] get API Success',
    props<{ trTruckApiResponse: any }>()
);

export const invokeGetTRTruckDataAPI = createAction(
    '[Truck API] Invoke getTRTruckData API',
    props<{ payload: any, isPivotActive: any }>()
);


export const saveTRDataPayload = createAction(
    '[App API] save TRData payload',
    props<{ trDataPayLoad: any }>()
);

export const saveTankParamDataPayload = createAction(
    '[MDM API] save TankParamData payload',
    props<{ tankParamDataPayload: any }>()
);

export const saveNotifParamDataPayload = createAction(
  '[Rules API] save NotifParamData payload',
  props<{ notifParamDataPayload: any }>()
);

export const modifyUpdatedFiltersSelection = createAction(
    '[App API] modify UpdatedFilters Selection payload',
    props<{ updatedFilters: any, updatedOfftakers: boolean }>()
);
export const setPipelineCompactView = createAction(
    '[Pipeline View] change pipeline compact view',
    props<{ pipelineCompactView: boolean, isPipelineCompact: boolean }>()
);
export const setBCPView = createAction(
    '[App API] set BCP View',
    props<{ BCPView: string }>()
);

export const updateCBHeaderCheckboxesState = createAction(
    '[Contract Balancing API] update CB Header Checkboxes state',
    props<{ cbHeaderCheckboxes: FilterObj[], cbHeaderCheckedCount: number }>()
);

export const updateNomination = createAction(
    '[Update Nomination API]',
    props<{ payload: any, publishOrDeleteNominationApiResponse: any }>(),
);

export const updateNominationSuccess = createAction(
    '[Upate Nomination Success API]',
    props<{ updatedNominationResponse: any, publishOrDeleteNominationApiResponse: any }>(),
);

export const saveEthanolModalPopupRefResponse = createAction(
    '[Save Ethanol Modal Popup Ref Response API]',
    props<{ ethanolModalPopupRefResponse: any }>(),
);

export const savePipelineTRFiltersResponse = createAction(
    'save Pipeline TR Filters',
    props<{ pipelineFilterValues: PipelineFilterValues[], pipelineFromDate?: any, pipelineToDate?: any, isPipelineFilterUpdated?: boolean }>()
);

export const savePipelineNomFiltersResponse = createAction(
    'save Cargo Plan Cca Filters',
    props<{ pipelineNomFilterValues: PipelineFilterValues[], pipelineFromDate?: any, pipelineToDate?: any, isPipelineNomFilterUpdated?: boolean }>()
);

export const saveCargoPlanCcaFiltersResponse = createAction(
    'save Pipeline Nom Filters',
    props<{ cargoPlanCcaFilterValues: CargoPlanCcaFilterValues[], cargoPlanCcaFromDate?: any, cargoPlanCcaToDate?: any, isCargoPlanCcaFilterUpdated?: boolean }>()
);

export const getTRPipelineDataAPISuccess = createAction(
    '[Pipeline API] get API Success',
    props<{ trPipelineApiResponse: any }>()
);


export const invokeGetTRPipelineDataAPI = createAction(
    '[Pipeline API] Invoke getTRPipelineData API',
    props<{ payload: any, isPivotActive: any }>()
);

export const setPipelineSwitchView = createAction(
    '[Pipeline View] change pipeline switch view',
    props<{ pipelineSwitchView: string, isPipelineSwitchUpdated: boolean }>()
);

export const setPipelineTableHeaders = createAction(
    '[Table Headers] Get Table Headers',
    props<{ allPipelineTableHeaders: any, isPipelineTableHeadersUpdated: boolean }>()
);

export const setPipelineFiltersResponse = createAction(
    '[App API] payload',
    props<{ value: any, isUpdated:boolean }>()
);
export const searchNomActionResponse = createAction (
    'save Nom Filters',
    props<{ searchPayload: any,  isPipelineNomUpdated?: boolean }>()
)

export const savePipelineNomSearchResponse = createAction (
    'save Nom Search',
    props<{ pipelineNomSearchValues: any,  isPipelineNomSearchUpdated?: boolean }>()
)

export const setdeskIDSearchedResponse = createAction (
    'save desk Search',
    props<{ deskSearchValues: any,  deskSetUpdated?: boolean }>()
)

export const setSaveMdmSearchResponse = createAction (
    'save desk MDM Search',
    props<{ deskSearchMdm: any,  deskSetUpdate?: boolean }>()
)

export const setBcpSwitchView = createAction(
    '[BCP View] change BCP switch view',
    props<{ bcpSwitchView: string, isBcpSwitchUpdated: boolean }>()
  );

export const setBCPCompactView = createAction(
    '[BCP View] change BCP compact view',
    props<{ bcpCompactView: boolean, isBCPCompact: boolean }>()
);

export const saveCargoFiltersCCA = createAction(
    '[Cargo Filters CCA] Api Integration',
    props<{ cargoCCAFilters: any[], cargoCCAStartDate?: any, cargoCCAEndDate?: any, isCargoCCAUpdated: boolean }>()
);

export const savelinkedNomFiltersResponse = createAction(
    'save linked Nom CCA Filters',
    props<{ cargoCCAFilters: any[], cargoCCAStartDate?: any, cargoCCAEndDate?: any }>(),
);

export const isCargoCCAUpdated = createAction(
    '[Cargo Filters are Updated in CCA]',
    props<{ isCargoCCAUpdated: boolean }>()
);

export const reInitiateFiltersForCCANomLinking = createAction(
    '[Link Nominations with Cargo] reinitiate filters',
    props<{ reInitiateFilters: boolean, deskFilterValue?: any }>()
);

export const setDynamicDealsCount = createAction(
    '[Contract Balancing] set dynamic deals count',
    props<{ cbDynamicDealsCount: number }>()
);

export const setAllDeskInfo = createAction(
    '[Desk Info] Get ALL Desk',
    props<{ deskInfo: MDMDeskValues[] }>()
);

export const saveMktElsEFiltersResponse = createAction(
    'save Market elasticity Filters',
    props<{ mktElsFilterValues: PipelineFilterValues[], pipelineFromDate?: any, pipelineToDate?: any, isMktElsFilterUpdated?: boolean }>()
);

export const setDeskWiseLocationsAndProducts = createAction(
    '[Desk Wise Locations and Products]',
    props<{deskWiseInfo:any}>() 
);