import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-FullWidthCellRenderer',
    templateUrl:'./FullWidthCellRenderer.html',
    styleUrls: ['./FullWidthCellRenderer.scss']

})

export class FullWidthCellRenderer implements ICellRendererAngularComp {
  public data: any;


  agInit(params: ICellRendererParams): void {
    this.data = params.node.data;

  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
