import { createFeatureSelector, createSelector} from '@ngrx/store';
import { State } from '../reducer/cb-dynamic-deals.reducer';

export const data = createFeatureSelector<State>('cbDynamicDeals');
export const dealsAllData = createSelector(data, (state) => state);
export const dealsSearchData = createSelector(data, (state) => state);

export const filteredDealsData = createSelector(data, (state) => state.filteredData);
export const selectUpdateCustomDateRangeFlag = createSelector(data, (state) => state);

export const primaryFilters = createSelector(data, (state) => state.filters.primary);
export const selectMaterialTsw = createSelector(primaryFilters, (state) => state.material.tsw);
export const selectMaterialSra = createSelector(primaryFilters, (state) => state.material.sra);
export const selecttransactionfilters = createSelector(primaryFilters, (state) => state.transaction);
export const selectcyclefilters = createSelector(primaryFilters, (state) => state.cycle);

export const selectedPrimaryFilters = createSelector(data, (state) => state.selectedFilters.primary);
export const selectedTransactionFilters = createSelector(selectedPrimaryFilters, (state) => state.transaction);
export const selectedMaterialTab = createSelector(selectedPrimaryFilters, (state) => state.material.tab);
export const selectedMaterialFilters = createSelector(selectedPrimaryFilters, (state) => state.material.values);
export const selectedCycleFilters = createSelector(selectedPrimaryFilters, (state) => state.cycle);



export const secondaryFilters = createSelector(data, (state) => state.filters.secondary);
export const selectExposureFilters = createSelector(secondaryFilters, (state) => state.exposure);
export const selectTermFilters = createSelector(secondaryFilters, (state) => state.term);
export const selectCounterPartyFilters = createSelector(secondaryFilters, (state) => state.counterParty);
export const selectLocationFilters = createSelector(secondaryFilters, (state) => state.location);

export const selectedSecondaryFilters = createSelector(data, (state) => state.selectedFilters.secondary);
export const selectedExposureFilters = createSelector(selectedSecondaryFilters, (state) => state.exposure);
export const selectedTermFilters = createSelector(selectedSecondaryFilters, (state) => state.term);
export const selectedCounterPartyFilters = createSelector(selectedSecondaryFilters, (state) => state.counterParty);
export const selectedLocationFilters = createSelector(selectedSecondaryFilters, (state) => state.location);
