<div class="comment-card maxHeight">

    <!-- Header for card with name/date & edit/delete action ellipsis -->
    <div class="d-flex flex-row justify-content-between">
        <name-header *ngIf="commentPublished" [userName]="comment.createdBy" [dateToShow]="comment.createdDate"></name-header>
        <name-header *ngIf="!commentPublished" [creatingNewItem]="true" [userName]="''" [dateToShow]="''"></name-header>
        <div *ngIf="commentPublished && comment.email == currentUserEmail" class="more-menu">
            <div class="more-menu-dropdown">
              <button  class="ellipses-icon"></button>
                <div class="dropdown-content place-top">
                    <a class="dropdown-item" (click)="setEditComment()">edit</a>
                    <a class="dropdown-item" (click)="deleteComment()">delete</a>
                </div>
            </div>
          </div>
    </div>

    <!-- Section used to display the comment text, either a  -->
    <div class="content-section">
        <p *ngIf="!isInputActive" class="mt-4 mb-0" >{{commentContent}}</p>
        <form *ngIf="isInputActive" class="p-0">
            <mat-form-field class="text-input-section">
                <mat-label>Add a comment</mat-label>
                <textarea matInput [(ngModel)]="commentContent" name="commentContent"
                    class="commentArea"></textarea>
            </mat-form-field>
        </form>
    </div>

    <!-- Bottom row section used for viewing tags and for cacel/create/edit  -->
    <div class="d-flex flow-row justify-content-between content-section">
        <div class="d-flex flex-row flex-wrap m-2 tagColumn">
            <span *ngFor="let tag of tags; index as indx" class="blueSelector" (click)="removeTag(indx)" [ngClass]="{'tagRemover': isInputActive}">            
                <img src="../../../../../assets/icons/icon_tag.svg">
                {{tag}},&nbsp;</span>
            <add-tag *ngIf="isInputActive" [hidden]="totalTagLengthOptions <= tags.length"  
                [selectedTags]="tags" (selectedTag)="updateTagList($event)" (lengthOfAllOptions)="updateTotalLength($event)"></add-tag>
        </div>
        <div *ngIf="isInputActive" class="d-flex flex-row justify-content-between button-holder">
            <button mat-flat-button (click)="cancelChanges()" class="button no-background mr-2 p-0">
                cancel
            </button>
            <button mat-flat-button *ngIf="isInputActive && createNewComment" class="button blue p-0"
                [disabled]="commentContent.length == 0" [ngClass]="{'disabled': commentContent.length == 0}"
                (click)="createComment()">
                create
            </button>
            <button mat-flat-button *ngIf="isInputActive && !createNewComment" 
                [disabled]="commentContent.length == 0" [ngClass]="{'disabled': commentContent.length == 0}"
                (click)="updateComment()" class="button blue p-0">
                update
            </button>
        </div>
    </div>  
</div>
