<div class="filter-panel-wrapper" [class.filter-panel-opened]="!closeFilterPanel"
    [class.filter-panel-closed]="closeFilterPanel">
    <div class="panel-header d-flex justify-content-between pt-10">
        <!-- <div class="panel-title">filters</div> -->
        <div *ngIf="router.url ==='/nominations' && ((!isCCAPanamaView && selectedDesk.includes('clean products') && !(selectedDesk.includes('clean products + latam')))|| (selectedDesk == 'floridadesk' || selectedDesk == 'pnwdesk'))"
            class="tab">
            <button class="btn tablinks p-3 justify-content-between  " [class.active]="true"
                (click)="tabChange($event,'Filter')">Filter</button>
            <button *ngIf="!iscargoScreenOpened" class="btn tablinks  p-3 justify-content-between "
                [class.active]="false" (click)="tabChange($event,'Search')">Search</button>
        </div>
    </div>
    <div *ngIf="!isSearchOpened" id="Filter" class="tabcontent">

        <div class="panel-title">filters</div>

        <ng-container
            *ngIf="router.url !== '/contracts/contract-balancing' && router.url !== '/bcpDashboard' && router.url !== '/admin/parameters' && router.url !== '/settings/alerts' && router.url !== '/admin/locations' && router.url !== '/admin/materialMapping' && router.url !== '/admin/desks' && router.url !== '/admin/transport' && router.url !== '/contracts/dynamicdeals'; else render_other_filters">
            <ng-container *ngIf="selectedDesk === 'panama' || isCCAPanamaView && router.url!=='/cargoPlanning'">
                <div class="panel-content">
                    <ng-container *ngIf="locationFilters.length">
                        <div class="content__header">location</div>
                        <form class="location-filter-block branded">
                            <p *ngFor="let value of locationFilters; let i = index;">
                                <input type="radio" id="{{value.name}}" name="location-filter" [checked]="value.checked"
                                    (change)="filteByLocation(value)"
                                    [disabled]="(isCCAPanamaView && currentScreen === 'replenishmentplanning'  && locationDisable(value))">
                                <label for="{{value.name}}" [title]="value.name">{{value.name}} </label>
                            </p>
                        </form>
                    </ng-container>

                    <ng-container *ngIf="filtersResponse.length">
                        <ng-container *ngFor="let response of filtersResponse">
                            <ng-container *ngIf="response.inputType === 'checkBox'">
                                <div class="content__header">{{response.filterName}}</div>
                                <form class="branded">
                                    <p *ngFor="let value of response.filterValues; let i = index;">
                                        <input type="checkbox" name="formcheckbox" attr.id="formcheckbox{{i}}"
                                            [value]="value.name" (change)="filterTRData($event,response.filterName)"
                                            [checked]="value.checked" >
                                        <label attr.for="formcheckbox{{i}}">{{value.name}}</label>
                                    </p>
                                </form>
                                <hr>
                            </ng-container>
                            <ng-container *ngIf="response.inputType === 'radioButton'">
                                <div class="content__header">{{response.filterName}}</div>
                                <form class="branded product-filter">
                                    <section *ngFor="let value of response.filterValues; let i = index;"><input
                                            type="radio" attr.id="radio1{{i}}" name="radio1" [value]="value.name"
                                            [checked]="filtersInitialized? i === 0 : value.name===getProductFromEvent"
                                            (change)="filterTRData($event, response.filterName)">
                                        <label attr.for="radio1{{i}}">{{value.name}}</label>
                                    </section>
                                </form>
                                <hr>
                            </ng-container>
                        </ng-container>
                        <date-range-picker [dateRangeData]="dateRangeData" [restrictStartDate]="true"
                            (updateDateRangeData)="dateRangegroup($event)">
                        </date-range-picker>
                    </ng-container>
                </div>
            </ng-container>
            <div class="panel-footer" *ngIf="filtersResponse.length && (selectedDesk === 'panama'|| isCCAPanamaView) && router.url!=='/cargoPlanning'">
                <span class="clear-filter" (click)="clearFilter()">reset to default</span>
            </div>
            <!-- for ethanol truck view -->
            <div class="panel-content" *ngIf="selectedDesk === 'ethanol + uswc' || selectedDesk === 'ethanol + usec'">
                <ng-container>
                    <div *ngFor="let response of updateSearchData; let i = index; trackBy:indexTracker">
                        <div
                            *ngIf="response.headerName && (!(response.headerName === 'supplier' && switchView === 'carrier' ) && !(response.headerName === 'carrier' && switchView === 'supplier' ) && (response.headerName !== 'unit of measure' || currentScreen !== 'nominations') && !(response.headerName === 'customer' && currentScreen === 'replenishmentplanning'))">
                            <div class="content__header">{{response.headerName}}
                                <span [class]="response.headerName +'_icon'"></span>
                            </div>
                            <form class="branded"
                                *ngIf="response.headerName !== 'unit of measure' && response.headerName !== 'terminal ownership'">
                                <input class="form-control ethonal-view_search"
                                    *ngIf="response.headerName !== 'mode of transport' && response.headerName !== 'unit of measure'"
                                    [name]="'searchText'+i" [(ngModel)]="searchText[i]" type="search"
                                    placeholder="search" (input)="updateFilterList($any($event.target).value, i)">
                                <div class="ethonal-view_container">
                                    <p *ngFor="let ethanolFilterValues of response.list"
                                        class="align-items-center d-flex"
                                        [ngClass]="{'sticky-header' : ethanolFilterValues.name === 'all'}">
                                        <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox"
                                            *ngIf="!(currentScreen === 'replenishmentplanning' && ethanolFilterValues.id?.includes('-X'))"
                                            [id]="ethanolFilterValues.name" [value]="ethanolFilterValues.name"
                                            [checked]="ethanolFilterValues.checked"
                                            [disabled]="(response.headerName !== 'mode of transport' && isCheckboxDisabled(response.headerName,ethanolFilterValues, ethanolFilterValues.checked))
                                    || ( response.headerName === 'mode of transport' && ethanolFilterValues.checked && selectedMOTCount === 1)"
                                            (change)="ethanolFilterData($event, response.headerName, i)">
                                        <label class="text-ellipsis" [title]="ethanolFilterValues.name"
                                            *ngIf="!(currentScreen === 'replenishmentplanning' && ethanolFilterValues.id?.includes('-X'))"
                                            [title]="response.headerName==='terminal'? ethanolFilterValues.name + ' (' +ethanolFilterValues.id + ')' : ethanolFilterValues?.name"
                                            [htmlFor]="ethanolFilterValues.name">
                                            {{response.headerName==='product' || response.headerName==='supplier' || response.headerName==='customer' || response.headerName==='carrier' || response.headerName ==='mode of
                                            transport' || ethanolFilterValues.name === 'all' ?
                                            ethanolFilterValues.name : response.headerName==='terminal' ? ethanolFilterValues.id : ethanolFilterValues.id}}
                                        </label>
                                    </p>
                                </div>
                            </form>
                            <ng-container
                                *ngIf="((response.headerName === 'unit of measure' && currentScreen === 'replenishmentplanning') || response.headerName === 'terminal ownership')">
                                <form class="branded">
                                    <p class="align-items-center d-flex mb-2"
                                        *ngIf="response.headerName === 'unit of measure'">
                                        <app-toggle-switch *ngIf="currentScreen === 'replenishmentplanning'"
                                            [label]="'mot'" [checked]="response.truck" [displayStatus]="true"
                                            (change)="ethanolTruckToggle($event, response.headerName, i)">
                                        </app-toggle-switch>
                                    </p>
                                    <p *ngFor="let ethanolViewFilterValues of response.list; let i = index"
                                        class="align-items-center d-flex">
                                        <input type="radio" name="ethanolRadio" class="flex-shrink-0 mb-2"
                                            [id]="ethanolViewFilterValues.name" [value]="ethanolViewFilterValues.name"
                                            [checked]="ethanolViewFilterValues.checked"
                                            [disabled]="(response.headerName === 'unit of measure' && currentScreen === 'nominations')"
                                            (change)="ethanolFilterData($event, response.headerName, i)">
                                        <label class="text-ellipsis mb-2"
                                            [htmlFor]="ethanolViewFilterValues.name">{{ethanolViewFilterValues.name}}</label>
                                    </p>
                                </form>
                            </ng-container>
                            <hr>
                        </div>
                    </div>
                    <date-range-picker [dateRangeData]="ethanolDateRangeData" [restrictStartDate]="true"
                        (updateDateRangeData)="ethanolDateRangegroup($event)">
                    </date-range-picker>
                </ng-container>
            </div>

            <div class="panel-footer d-flex justify-content-between p-3" *ngIf="selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec' ">
                <span class="clear-filter" (click)="ehtanolClearFilter()">clear</span>
                <span class="clear-filter" (click)="ehtanolApplyFilter()">apply</span>
            </div>
            <!--pipeline filters -->
            <div class="panel-content bk-test"
                *ngIf="(isPipelineFiltersEnabled || router.url === '/marketElasticity' || selectedDesk.includes('refinery'))  && router.url !== '/cargoPlanning' && !isCCAPanamaView">
                <ng-container>
                    <div *ngFor="let response of updateSearchData; let i = index; trackBy:indexTracker">
                        <div *ngIf="response.headerName" [class]="{'filter-collapse': filterAccord[i]}">
                            <div (click)="accordionFn(i)"
                                class="content__header d-flex justify-content-between cursor-pointer">
                                <span>{{getHeaderName(response)}}
                                    <span
                                        [class]="response.headerName.toLowerCase() === 'location'? 'terminal_icon' : response.headerName +'_icon'"></span></span>
                                <span
                                    [class]="{'icon-chevron-up': !filterAccord[i], 'icon-chevron-down': filterAccord[i]}"></span>
                            </div>
                            <form class="branded">
                                <input class="form-control ethonal-view_search" [name]="'searchText'+i"
                                    [(ngModel)]="searchText[i]" type="search" placeholder="search"
                                    (input)="updatePipelineFilterList($any($event.target).value, i, response.headerName)">
                                <div class="ethonal-view_container">
                                    <p *ngFor="let pipelineFilterValues of response.list"
                                        class="align-items-center d-flex"
                                        [ngClass]="{'sticky-header' : pipelineFilterValues.name === 'all'}">
                                        <input type="checkbox" class="flex-shrink-0"
                                            *ngIf="isPipelineCargotabActive(pipelineFilterValues,response.headerName)"
                                            name="pipelineCheckbox"
                                            [id]="((pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name)+i"
                                            [value]="(pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name"
                                            [checked]="pipelineFilterValues.checked"
                                            (change)="pipelineFilterData($event, response.headerName, i, pipelineFilterValues)">
                                        <label class="text-ellipsis"
                                            *ngIf="isPipelineCargotabActive(pipelineFilterValues,response.headerName)"
                                            [title]= "getTitle(pipelineFilterValues,response.headerName)"
                                            [htmlFor]="((pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name)+i">
                                            {{response.headerName==='product' || response.headerName ==='mode of
                                            transport' || pipelineFilterValues.name === 'all' ?
                                            pipelineFilterValues.name : pipelineFilterValues.id}}
                                         </label>
                                    </p>
                                </div>
                            </form>
                            <hr>
                        </div>
                    </div>
                    <date-range-picker [dateRangeData]="pipelineDateRangeData" [restrictStartDate]="true"
                        (updateDateRangeData)="pipelineDateRangegroup($event)">
                    </date-range-picker>
                </ng-container>
            </div>
            <div class="panel-footer d-flex justify-content-between p-3"
            *ngIf="(isPipelineFiltersEnabled || router.url === '/marketElasticity') && router.url !== '/cargoPlanning'  && !isCCAPanamaView || selectedDesk.includes('refinery')">
            <span class="clear-filter" (click)="pipelineClearFilter()">clear</span>
                <span class="clear-filter" (click)="applyPipelineFilter()">apply</span>
            </div>

        </ng-container>

        <ng-container *ngIf="selectedDesk.includes('clean products + latam') && router.url==='/cargoPlanning'">
            <div class="panel-content">
                <span *ngFor="let value of cargoFiltersCCA">
                    <div class="content__header">
                        {{ value.filterName }}</div>
                    <form class="location-filter-block branded">
                        <section *ngFor="let response of value.list">
                            <input type="checkbox" [id]="response.value" [name]="response.value"
                                [value]="response.value" [checked]="response.checked"
                                (change)="onChangeCargoCCA($event, value.filterName)">
                            <label [for]="response.value">{{ response.value }}</label>
                        </section>
                    </form>
                </span>
                <date-range-picker [dateRangeData]="cargoCCADateRangeData" [restrictStartDate]="true"
                    (updateDateRangeData)="dateRangegroupCargo($event)">
                </date-range-picker>
            </div>
            <div class="panel-footer d-flex justify-content-between p-3">
                <span class="clear-filter" (click)="ccaClearFilter()">clear</span>
                <span class="clear-filter" (click)="applyCargoCCAFilter()">apply</span>
            </div>
        </ng-container>

        <ng-template #render_other_filters>
            <ng-container *ngIf="router.url === '/contracts/contract-balancing'; then render_contract_filters"></ng-container>
        </ng-template>
        <ng-template #render_contract_filters>
            <app-contract-balancing-filter *ngIf="deskListResponse.length"></app-contract-balancing-filter>
        </ng-template>

        <ng-container *ngIf="router.url === '/admin/locations'; then render_locations_filters"></ng-container>
        <ng-template #render_locations_filters>
            <app-locations-filter></app-locations-filter>
        </ng-template>

        <ng-container *ngIf="router.url === '/admin/desks'; then render_desks_filters"></ng-container>
        <ng-template #render_desks_filters>
            <app-desks-filter></app-desks-filter>
        </ng-template>

        <ng-container
            *ngIf="router.url === '/admin/materialMapping'; then render_material_mapping_filters"></ng-container>
        <ng-template #render_material_mapping_filters>
            <app-material-mapping-filter></app-material-mapping-filter>
        </ng-template>

        <ng-container *ngIf="router.url === '/admin/transport'; then render_transport_filters"></ng-container>
        <ng-template #render_transport_filters>
            <app-transport-filter></app-transport-filter>
        </ng-template>

        <ng-container *ngIf="router.url === '/bcpDashboard'; then render_bcp_filters"></ng-container>
        <ng-container *ngIf="isSearchOpened">
            <app-search-panel></app-search-panel>
        </ng-container>
        <ng-template #render_bcp_filters>
            <app-bcp-dashboard-filter></app-bcp-dashboard-filter>
        </ng-template>

        <ng-container
            *ngIf="router.url === '/settings/alerts'; then render_settings_alert_rules_filters"></ng-container>
        <ng-template #render_settings_alert_rules_filters>
            <app-settings-alert-rules-filter></app-settings-alert-rules-filter>
        </ng-template>

        <ng-container
            *ngIf="router.url === '/admin/parameters'; then render_settings_parameters_filters"></ng-container>
        <ng-template #render_settings_parameters_filters>
            <app-settings-parameters-filter></app-settings-parameters-filter>
        </ng-template>

        <!-- <ng-container *ngIf="router.url === '/marketElasticity'; then render_mkt_filters"></ng-container>
        <ng-template #render_mkt_filters>
            <app-market-elasticity-filter></app-market-elasticity-filter>
        </ng-template> -->
        

        <ng-container
            *ngIf="router.url === '/contracts/dynamicdeals'; then render_contract_dynamic_deals_filters"></ng-container>
        <ng-template #render_contract_dynamic_deals_filters>
            <app-contract-dynamic-deals-filter></app-contract-dynamic-deals-filter>
        </ng-template>

    </div>
    <ng-container *ngIf="isSearchOpened">
        <app-search-panel></app-search-panel>
    </ng-container>
</div>