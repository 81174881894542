import { Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import { CommentI } from 'src/app/shared/interface/comment.interface';
import { CommentsService, behaviorComment,} from 'src/app/services/comments.service';
import { infoPanelData, panelType, } from 'src/app/services/information-panel.service';

@Component({
  selector: 'comment-panel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.scss'],
})
export class CommentPanelComponent implements OnInit, OnChanges {
  @Input() data: infoPanelData = {
    name: '',
    date: '',
    id: '',
    type: panelType.CP,
    data: '',
  };

  constructor(private commentService: CommentsService) {}

  commentList: CommentI[] = [];

  ngOnInit(): void {
    this.subscribeToCommentUpdates();
  }
  
  private subscribeToCommentUpdates(): void {
    this.commentService.commentListUpdate.subscribe((updateValue: behaviorComment | null) => {
      if (updateValue) {
        this.updateCommentList(updateValue);
      }
    });
  }
  
  private updateCommentList(updateValue: behaviorComment): void {
    switch (updateValue.behaviorType) {
      case 'add':
        this.commentList.unshift(updateValue.comment);
        break;
      case 'delete':
        const indexToRemove = this.commentList.findIndex(e => e.id === updateValue.comment.id);
        if (indexToRemove !== -1) {
          this.commentList.splice(indexToRemove, 1);
        }
        break;
    }
    this.sortCommentsByDate();
  }
  
  ngOnChanges(): void {
    this.loadComments();
  }
  
  private loadComments(): void {
    this.commentService.getCommentsById(this.data.id, this.data.type).subscribe({
      next: (response: any) => {
        this.commentList = response.result;
        this.sortCommentsByDate();
      },
      error: (err: any) => {
        console.error(err);
      }
    });
  }
  
  private sortCommentsByDate(): void {
    this.commentList.sort((a, b) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf());
  }
  
}
