<div class="panel-content">
  <ng-container>
    <div>
      <date-range-picker [dateRangeData]="dateRangeData" [restrictStartDate]="true"
        (updateDateRangeData)="dateRangegroup($event)">
      </date-range-picker>
      <hr>
    </div>
    <div>
      <div class="content__header">Transport System
      </div>
      <form class="branded">
        <input class="form-control contract-balancing-search " [name]="'searchText0'" type="search" placeholder="search"
          (input)="updateFilterList($any($event.target).value, 'locations',0)" [(ngModel)]="searchValue[0]">
        <div class="contract-balancing-side-panel">
          <p *ngFor="let obj of updateSearchData.transportSys; let i = index; " class="align-items-center d-flex">
            <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name" [value]="obj.name"
              [checked]="obj.checked" (change)="filterData($event, 'transportSys', i)">
            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
              {{obj.name}}
            </label>
          </p>
        </div>
      </form>
      <hr>
    </div>
    <div>
      <div class="content__header">Terminal
      </div>
      <form class="branded">
        <input class="form-control contract-balancing-search " [name]="'searchText1'" type="search" placeholder="search"
          (input)="updateFilterList($any($event.target).value, 'suppliers',1)" [(ngModel)]="searchValue[1]">
        <div class="contract-balancing-side-panel">
          <p *ngFor="let obj of updateSearchData.terminals; let i = index; " class="align-items-center d-flex">
            <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name" [value]="obj.name"
              [checked]="obj.checked" (change)="filterData($event, 'terminals', i)">

            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
              {{obj.name}}
            </label>
          </p>
        </div>
      </form>
      <hr>
    </div>
    <div>
      <div class="content__header"> Products
      </div>
      <form class="branded">
        <input class="form-control contract-balancing-search " [name]="'searchText3'" type="search" placeholder="search"
          (input)="updateFilterList($any($event.target).value, 'contractNumbers',3)" [(ngModel)]="searchValue[2]">
        <div class="contract-balancing-side-panel">
          <p *ngFor="let obj of updateSearchData.products; let i = index; " class="align-items-center d-flex">
            <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name" [value]="obj.name"
              [checked]="obj.checked" (change)="filterData($event, 'products', i)">

            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
              {{obj.name}}
            </label>
          </p>
        </div>
      </form>
      <hr>
    </div>
  </ng-container>
</div>

<div class="panel-footer">
  <span class="clear-filter" (click)="cbClearFilter()">reset to default</span>
</div>
