import { createAction, props } from "@ngrx/store";
import { Desks, LocationData, DeskLocation, CreateDeskLocationMapping, DeskFilters, DeskFunctionalGroup } from "../../../shared/interface/mdm-admin.interface";

export const loadLocationsData = createAction(
  '[Locations Data API] Load Locations Data',
  props<{ queryParams: any }>(),
);

export const loadLocationsDataSuccess = createAction(
  '[Locations Data API] Load Locations Data API Success',
  props<{ locationsData: LocationData[] }>(),
);

export const loadDesksData = createAction(
  '[Desks Data API] Load Desks Data',
  props<{ queryParams: any }>(),
);

export const loadDesksDataSuccess = createAction(
  '[Desks Data API] Load Desks Data API Success',
  props<{ desks: Desks[] }>(),
);

export const loadDesksDataFailure = createAction(
  '[Desks Data API] Load Desks Data API Failure',
  props<{ error: any }>(),
);

export const updateDesksDataFailure = createAction(
  '[Desks Data API] Update Desks Data API Failure',
  props<{ error: any }>(),
);

export const setDefaultDesksFilter = createAction(
  '[Desks Filter] Set Default Desks Filter',
  props<{ desksFilter: DeskFilters,desksData: DeskFunctionalGroup[]}>()
);

export const updateDesksFilter = createAction(
  '[Desks Filter] Update Desks Filter',
  props<{ desksFilter: DeskFilters,desksData: DeskFunctionalGroup[]}>()
);

export const updateDesksTable = createAction(
  '[Desks Filter] Set updateDesksTable',
  props<{ desksData: DeskFunctionalGroup[], updateDesksTable: boolean, initializeDesksFilter: boolean }>()
);

export const desksFilterUpdated = createAction(
  '[Desks Filter] Set updateDesksFilter',
  props<{ updateDesksFilter: boolean }>()
);

export const loadDeskLocations = createAction(
  '[Desks Locations Data API] Load Desks Locations Data',
  props<{ deskIds: { deskIds: number[] } }>(),
);

export const loadDeskLocationsSuccess = createAction(
  '[Desks Locations Data API] Load Desks Locations Data API Success',
  props<{ deskLocations: DeskLocation[] }>(),
);

export const loadDeskLocationsFailure = createAction(
  '[Desks Locations Data API] Load Desks Locations Data API Failure',
  props<{ error: any }>(),
);
export const deleteDeskLocationMapping = createAction(
  '[Delete Desks Location Data API] Delete desk location mapping',
  props<{ deskLocationId : string }>(),
);
export const deleteDeskLocationMappingSuccess = createAction(
  '[Delete Desks Location Data API] Delete desk location mapping success'
);
export const resetDesksScreen = createAction(
  '[Reset Desks Location Screen] Reset Desks Location Screen'
);
export const createDeskLocationMapping = createAction(
  '[Create Desks Location Mapping Data API] Create desk location mapping',
  props<{ createDeskLocationId : CreateDeskLocationMapping }>(),
);
export const createDeskLocationMappingSuccess = createAction(
  '[Create Desks Location Mapping Data API] Create desk location mapping success',
   props<{ newDeskLocationId : number }>(),
);
export const recievedNewDeskLocationId = createAction(
  '[Create Desks Location Mapping Data API] Get new desk location ID'
);