import { Component, Input, OnInit } from '@angular/core';
import { lineData } from 'src/app/shared/interface/activity-tracker';

@Component({
  selector: 'at-card-back',
  templateUrl: './card-back.component.html',
  styleUrls: ['./card-back.component.scss']
})
export class CardBackComponent implements OnInit {

  @Input() cardValues! : cardGridInterface; 

  constructor() { }

  ngOnInit(): void {
  }

}


export interface cardGridInterface{
  id: string, 
  headerValue: string, 
  tableValues: lineData[];
  
}
