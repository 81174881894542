import { createAction, props } from "@ngrx/store";
import { EditedLocationData, LocationData, LocationFilters } from "../../../../app/shared/interface/mdm-admin.interface";

export const loadLocationsData = createAction(
  '[Locations Data API] Load Locations Data',
  props<{ queryParams: any }>(),
);

export const loadLocationsDataSuccess = createAction(
  '[Locations Data API] Load Locations Data API Success',
  props<{ locationsData: LocationData[] }>(),
);

export const updateLocationsData = createAction(
  '[Locations Data API] Update Locations Data API',
  props<{ editedLocationsData: EditedLocationData[] }>(),
);

export const updateLocationsDataSuccess = createAction(
  '[Locations Data API] Update Locations Data API Success',
  props<{ editedLocationsData: EditedLocationData[] }>(),
);

export const loadLocationsDataFailure = createAction(
  '[Locations Data API] Load Locations Data API Failure',
  props<{ error: any }>(),
);

export const updateLocationsDataFailure = createAction(
  '[Locations Data API] Update Locations Data API Failure',
  props<{ error: any }>(),
);

export const setDefaultLocationFilters = createAction(
  '[Locations Filter] Set Default Locations Filter',
  props<{ locationFilters: LocationFilters, locationsData: LocationData[] }>()
);

export const updateLocationFilters = createAction(
  '[Locations Filter] Update Locations Filter',
  props<{ locationFilters: LocationFilters, locationsData: LocationData[] }>()
);

export const updateLocationsTable = createAction(
  '[Locations Filter] Set updateLocationsTable',
  props<{ updateLocationsTable: boolean }>()
);

export const locationFiltersUpdated = createAction(
  '[Locations Filter] Set updateLocationFilters',
  props<{ updateLocationFilters: boolean }>()
);

export const updateEditedLocations = createAction(
  '[Edited Locations Data] Add Edited Locations Data',
  props<{ locationsData: LocationData[] }>(),
);

export const addEditedLocations = createAction(
  '[Edited Locations Data] Set When Location Data Edited',
);

export const resetLocationsScreen = createAction(
  '[Locations Screen] Reset locations screen'
);