import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { getUserDetails, selectDesk, selectEthanolTRFiltersResponse, trNominationList } from 'src/app/shared/store/selector/app.selector';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { invokeTrNominationList, saveEthanolNomFiltersResponse, updateNomination } from 'src/app/shared/store/action/app.action';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from 'src/app/services/api.service';
import { MarketElasticityService } from 'src/app/services/market-elasticity.service';
import { ColumnApi, GridApi, ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'app-create-nomination-elasticity-side-panel',
  templateUrl: './create-nomination-elasticity-side-panel.component.html',
  styleUrls: ['./create-nomination-elasticity-side-panel.component.scss']
})

export class CreateNominationElasticitySidePanelComponent implements ICellRendererAngularComp {

  @Input() isWindowOpened = false;
  @Output() dockInfoWindow = new EventEmitter();
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDesk$ = this.store.pipe(select(selectDesk));
  desk: string = '';
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  dateRangeData: { fromDate: any, toDate: any };
  defaultFromDate!: string;
  defaultToDate!: string;
  pipelineDateRangeData: { fromDate: any, toDate: any };
  chkAddDetails: boolean = false;
  lstAllNominations: any = [];
  
  public params: any;  
  public gridApi2!: GridApi;
  gridColumnApi2!: ColumnApi;

  constructor(private apiService: ApiService,
    private mktService: MarketElasticityService,
    private appStore: Store<AppState>, private nominationService: NominationService, public toasterService: ToastService, private utilities: Utilities, private store: Store, private router: Router, private appState: Store<AppState>, public datePipe: DatePipe,) {
    const { fromDate, toDate } = this.getDefaultDates();
    this.dateRangeData = { fromDate, toDate };
    this.pipelineDateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate };
  }

  agInit(params: ICellRendererParams<any, any, any>): void {

    this.params = params;
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;   

  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  

  getDefaultDates = () => {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth() - 2;
    const endDate = new Date(), endDateY = endDate.getFullYear(), endDateM = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(endDateY, endDateM + 1, 0);

    this.defaultFromDate = this.datePipe.transform(firstDay,
      'yyyy-MM-dd'
    )!;
    this.defaultToDate = this.datePipe.transform(lastDay,
      'yyyy-MM-dd'
    )!;

    const fromDate = this.defaultFromDate;
    const toDate = this.defaultToDate;
    return { fromDate, toDate };
  };

  dateRangegroup(event: any) {
    let getudatedDateRangeFlag = event.valid;
    let getFromDateFromEvent = event.controls.start.value;
    let getToDateFromEvent = event.controls.end.value;
    getFromDateFromEvent = this.datePipe.transform(new Date(getFromDateFromEvent),
      'yyyy-MM-dd'
    );
    getToDateFromEvent = this.datePipe.transform(new Date(getToDateFromEvent),
      'yyyy-MM-dd'
    );
    if (getudatedDateRangeFlag) {
      this.pipelineDateRangeData.fromDate = getFromDateFromEvent;
      this.pipelineDateRangeData.toDate = getToDateFromEvent;
      const dateObj = { fromDate: getFromDateFromEvent, toDate: getToDateFromEvent };
    }
  }

  ngOnInit(params: ICellRendererParams<any, any, any>): void {  

    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

    //subscribing to get selected desk
    this.selectedDesk$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.desk = response.selectedDesk
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });

    this.mktService.nominationParamPSD$.subscribe((value: any) => {
      this.params = value;

      let colId : string = this.params.column.colId;

      let nomKeyName = colId.replace("nominationNumber","nominationKey");
      let nomKeyValue = this.params.data[nomKeyName];

      this.getNominationDetails(nomKeyValue);

    });

  }

  goToNominations(nom: any) {
    this.nominationService.restrictNomApiGetCall$.next(true);
    //this.store.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.ethanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.ethanolFilterResponse.ethanolFromDate, ethanolToDate: this.ethanolFilterResponse.ehtanolToDate, isNomFilterUpdated: true }));
    this.router.navigate(['/nominations']);
    this.nominationService.goToNominationData$.next({ isTrue: true, data: nom })
  }

  closeNominationsPanel() {
    this.nominationService.sidePanel$.next(false);
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  getNominationDetails(nomKey: any) {
    this.lstAllNominations = [];

    this.mktService.getNominationDetailfromSource(nomKey).subscribe({
      next: (response: any) => {
        if (response != null) {

          let nominationDetails = response.result;

          this.getAllNominationsList(nominationDetails);

        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
      },
    });
  }

  getAllNominationsList(nominationDetails: any) {
    this.lstAllNominations = [];
    let objDistinct = [... new Set(nominationDetails.map((x: { id: any; }) => x.id))];

    objDistinct.forEach((item: any) => {

      let lstNomi = nominationDetails.filter((x: { id: any; }) => x.id == item);

      let originNominationDetails = lstNomi.filter((x: { scheduleType: any; }) => x.scheduleType == "O");
      let destNominationDetails = lstNomi.filter((x: { scheduleType: any; }) => x.scheduleType == "D");

      let objNom = {
        nominationNo: lstNomi[0].nominationNo,
        nominationKey: lstNomi[0].nominationKey,
        scheduledQty: lstNomi[0].scheduledQty,
        details: {
          originNomin : originNominationDetails,
          destNomin : destNominationDetails,
        },
      };

      this.lstAllNominations.push(objNom);

    });
  };

  async onCreateDraftNomination(actionType?: any,nomKey?: any) {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
   
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    this.params.value = nomKey;
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    pipelineNomination["screenName"] = 'side-panel-tr';
    if (actionType === 'edit') {
      pipelineNomination["editNomination"] = true;
      this.mktService.setOpenNominationPSD(true);
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editPipelineNominationFromME',
      type: '',
      values: pipelineNomination
    }; 
    
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
    this.closeNominationsPanel();
  }
  
  openInfoWindow() {
    // window.open("https://www.w3schools.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    this.closeNominationsPanel();
    const origin = window.location.origin;
    const url = `${origin}/marketElasticity/information-panel`
    this.popupWindow(url, 'nomination panel', window, 800, 800);
  }

  dockInfoPanel() {
    this.dockInfoWindow.emit();
    // this.nominationService.sidePanel$.next(true);
  }

  popupWindow(url: string, windowName: string, win: any, w: any, h: any) {
    const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  }

  collapseNomiDetail(e: any,id:any) {
    let headEle = e.target;
    let ele = <HTMLInputElement>document.getElementById("nomiDetails-" + id);
    if (ele.classList.contains('show')) {
      ele.classList.remove("show");
      headEle.classList.remove("expanded");
    } else {
      setTimeout(() => {
        ele.className = "collapse show";
        headEle.classList.add("expanded");
      }, 0)
    }
  }

  applyAddInfo(event: any) {
    this.chkAddDetails = event.target.checked
  }


}
