import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { formatDate } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { selectMaterialMappingData } from '../../../../../app/admin-material-mapping/store/selector/admin-material-mapping.selector';
import { addEditedMaterialMapping, setDefaultMaterialMappingFilters, updateMaterialMappingFilters } from '../../../../../app/admin-material-mapping/store/action/admin-material-mapping.action';
import { AdminView, MaterialMappingData, MaterialMappingFilters, MaterialMappingNested, TextFilter,LocationNameCode } from '../../../../../app/shared/interface/mdm-admin.interface';
import { selectAdminView, selectIsAdminSettingsSave } from '../../../../shared/store/selector/app.selector';

@Component({
  selector: 'app-material-mapping-filter',
  templateUrl: './material-mapping-filter.component.html',
  styleUrls: ['./material-mapping-filter.component.scss']
})
export class MaterialMappingFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  locationNameCode: LocationNameCode[]=[];
  materialMappingData: MaterialMappingNested[] = [];
  filters: MaterialMappingFilters = {
    locationName: [],
    status: [],
    locationCode:[],
    materialDescription: [],
    materialNumber: [],
    groupMaterial: [],
    materialGroupName: [],
    materialGroupNumber: [],
    trackInv: []
  };
  seasonalDateFilters: {
    spmInvStartDate: string,
    spmInvEndDate: string,
    spmDemandForecastStartDate: string,
    spmDemandForecastEndDate: string,
    spmActualLiftStartDate: string,
    spmActualLiftEndDate: string,
  } = {
    spmInvStartDate: "",
    spmInvEndDate: "",
    spmDemandForecastStartDate: "",
    spmDemandForecastEndDate: "",
    spmActualLiftStartDate: "",
    spmActualLiftEndDate: "",
  };
  seasonalDateFilterNames: string[] = ['spmInvStartDate', 'spmInvEndDate', 'spmDemandForecastStartDate', 'spmDemandForecastEndDate', 'spmActualLiftStartDate', 'spmActualLiftEndDate'];
  showDateFilters: boolean = true;
  relatedFilters: { relatedFilterNames: string[] }[] = [
    { relatedFilterNames: [ 'materialDescription', 'materialNumber' ] },
    { relatedFilterNames: [ 'materialGroupName', 'materialGroupNumber' ] },
    { relatedFilterNames: [ 'locationName', 'locationCode' ] }
  ];
  adminView: string = "";

  destroy$: Subject<boolean> = new Subject<boolean>();
  materialMappingData$ = this.store.pipe(select(selectMaterialMappingData));
  selectIsAdminSettingsSave$ = this.store.pipe(select(selectIsAdminSettingsSave));
  selectedAdminView$ = this.store.pipe(select(selectAdminView));

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.materialMappingData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.materialMappingData.length && response.initializeMaterialMappingFilter) {
          this.materialMappingData = JSON.parse(JSON.stringify(response.materialMappingData));
          this.formatDefaultMaterialMappingFilters();
          this.store.dispatch(setDefaultMaterialMappingFilters({
            materialMappingFilters: JSON.parse(JSON.stringify(this.filters)),
            materialMappingData: JSON.parse(JSON.stringify(this.materialMappingData))
          }));
        }
        if (response.addEditedChanges) {
          this.materialMappingData = JSON.parse(JSON.stringify(response.materialMappingData));
          this.store.dispatch(addEditedMaterialMapping());
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });

    this.selectedAdminView$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.adminView = response.adminView;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });

    this.selectIsAdminSettingsSave$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.triggeredAdminSettingsSave && this.adminView === AdminView.MaterialMapping) {
          this.formatMaterialMappingFilters();
          this.store.dispatch(updateMaterialMappingFilters({
            materialMappingFilters: JSON.parse(JSON.stringify(this.filters)),
            materialMappingData: JSON.parse(JSON.stringify(this.materialMappingData))
          }));
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
  }

  formatMaterialDescriptionFilters(locationMaterial: MaterialMappingData, checkFilter: boolean, showFilter: boolean) {
    if (!this.filters.materialDescription.filter((materialDesc: TextFilter) => materialDesc.name === locationMaterial.materialDescription).length) {
      if(locationMaterial.materialDescription!== "")
      {
        this.filters.materialDescription.push({ name: locationMaterial.materialDescription, checked: checkFilter, show: showFilter });
      }
     
    }
  }
  formatMaterialNumberFilters(locationMaterial: MaterialMappingData, checkFilter: boolean, showFilter: boolean) {
    if (!this.filters.materialNumber.filter((materialNumber: TextFilter) => materialNumber.name === locationMaterial.materialNumber).length) {
      this.filters.materialNumber.push({ name: locationMaterial.materialNumber, checked: checkFilter, show: showFilter });
    }
  }

  formatMaterialGroupNameFilters(locationMaterial: MaterialMappingData, checkFilter: boolean, showFilter: boolean) {
    if (!this.filters.materialGroupName.filter((materialGroupName: TextFilter) => materialGroupName.name === locationMaterial.materialGroupDescription).length) {
      this.filters.materialGroupName.push({ name: locationMaterial.materialGroupDescription, checked: checkFilter, show: showFilter });
    }
  }

  formatMaterialGroupNumberFilters(locationMaterial: MaterialMappingData, checkFilter: boolean, showFilter: boolean) {
    if (!this.filters.materialGroupNumber.filter((materialGroupNumber: TextFilter) => Number(materialGroupNumber.name) === Number(locationMaterial.materialGroup)).length) {
      this.filters.materialGroupNumber.push({ name: String(locationMaterial.materialGroup), checked: checkFilter, show: showFilter });
    }
  }

  formatStatusFilters(addStatusFilters: { addActive: boolean, addInactive: boolean, showActive: boolean, showInactive: boolean }, checkFilter: boolean) {
    if (addStatusFilters.addActive) {
      this.filters.status.push({ name: 'active', checked: checkFilter, show: addStatusFilters.showActive });
    }
    if (addStatusFilters.addInactive) {
      this.filters.status.push({ name: 'inactive', checked: checkFilter, show: addStatusFilters.showInactive });
    }
  }
  formatTrackInvFilters(addTrackInvFilters: { addActive: boolean, addInactive: boolean, showActive: boolean, showInactive: boolean }, checkFilter: boolean) {
    if (addTrackInvFilters.addActive) {
      this.filters.trackInv.push({ name: 'active', checked: checkFilter, show: addTrackInvFilters.showActive });
    }
    if (addTrackInvFilters.addInactive) {
      this.filters.trackInv.push({ name: 'inactive', checked: checkFilter, show: addTrackInvFilters.showInactive });
    }
  }
  formatGroupMaterialFilters(addGroupMaterialFilters: { addGroup: boolean, addUngroup: boolean, showGroup: boolean, showUngroup: boolean }, checkFilter: boolean) {
    if (addGroupMaterialFilters.addGroup) {
      this.filters.groupMaterial.push({ name: 'grouped', checked: checkFilter, show: addGroupMaterialFilters.showGroup });
    }
    if (addGroupMaterialFilters.addUngroup) {
      this.filters.groupMaterial.push({ name: 'ungrouped', checked: checkFilter, show: addGroupMaterialFilters.showUngroup });
    }
  }

  formatSeasonalDates(date: string | null | undefined): string {
    if (date === null || date === undefined || date === 'null' || date === '')
      return ''
    else
      return formatDate(String(date), 'MM/dd/yyyy', 'en-US')
  }

  formatDefaultMaterialMappingFilters() {
    const allOption: TextFilter = { name: 'all', checked: true, show: true };
    this.filters = {
      locationName: [],
      status: [{ ...allOption}],
      locationCode:[{...allOption}],
      materialDescription: [],
      materialNumber: [{ ...allOption}],
      groupMaterial: [{ ...allOption}],
      materialGroupName: [],
      materialGroupNumber: [],
      trackInv: [{ ...allOption}]
    };
    const addStatusFilters: { addActive: boolean, addInactive: boolean, showActive: boolean, showInactive: boolean } = { addActive: true, addInactive: true, showActive: false, showInactive: false };
    const addGroupMaterialFilters: { addGroup: boolean, addUngroup: boolean, showGroup: boolean, showUngroup: boolean } = { addGroup: true, addUngroup: true, showGroup: false, showUngroup: false };
    const uniqueLocations = new Set<string>();
    const uniqueLocationscode = new Set<string>();
    this.materialMappingData.forEach((materialMapping: MaterialMappingNested) => {

      if (!uniqueLocations.has(materialMapping.locationName)) {
        uniqueLocations.add(materialMapping.locationName);
        this.filters.locationName.push({ name: materialMapping.locationName, checked: allOption.checked, show: allOption.show });
      }
      if (!uniqueLocationscode.has(materialMapping.locationCode)) {
        uniqueLocationscode.add(materialMapping.locationCode);
        this.filters.locationCode.push({ name: materialMapping.locationCode, checked: allOption.checked, show: allOption.show });
      }

      this.locationNameCode.push({locationName:materialMapping.locationName, locationcode:materialMapping.locationCode});
      materialMapping.show = allOption.show;
      materialMapping.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
        locationMaterial.spmInvStartDate = this.formatSeasonalDates(locationMaterial.spmInvStartDate);
        locationMaterial.spmInvEndDate = this.formatSeasonalDates(locationMaterial.spmInvEndDate);
        locationMaterial.spmDemandForecastStartDate = this.formatSeasonalDates(locationMaterial.spmDemandForecastStartDate);
        locationMaterial.spmDemandForecastEndDate = this.formatSeasonalDates(locationMaterial.spmDemandForecastEndDate);
        locationMaterial.spmActualLiftStartDate = this.formatSeasonalDates(locationMaterial.spmActualLiftStartDate);
        locationMaterial.spmActualLiftEndDate = this.formatSeasonalDates(locationMaterial.spmActualLiftEndDate);
        locationMaterial.show = allOption.show;
        if (locationMaterial.active)
          addStatusFilters.showActive = true;
        else
          addStatusFilters.showInactive = true;
        this.formatMaterialDescriptionFilters(locationMaterial, allOption.checked, allOption.show);
        this.formatMaterialNumberFilters(locationMaterial, allOption.checked, allOption.show);
        if (locationMaterial.aggregateUnderGroup)
          addGroupMaterialFilters.showGroup = true;
        else
          addGroupMaterialFilters.showUngroup = true;
        this.formatMaterialGroupNameFilters(locationMaterial, allOption.checked, allOption.show);
        this.formatMaterialGroupNumberFilters(locationMaterial, allOption.checked, allOption.show);
      });
    });

    // sort location
    this.filters.locationName.sort((a, b) => a.name.localeCompare(b.name));
    this.filters.locationName.unshift(allOption);

    // sort material
    this.filters.materialDescription.sort((a, b) => a.name.localeCompare(b.name));
    this.filters.materialDescription.unshift(allOption);

    // sort material numbers
    this.filters.materialGroupName.sort((a, b) => a.name.localeCompare(b.name));
    this.filters.materialGroupName.unshift(allOption);
    
    this.formatStatusFilters(addStatusFilters, allOption.checked);
    this.formatTrackInvFilters(addStatusFilters, allOption.checked);
    this.formatGroupMaterialFilters(addGroupMaterialFilters, allOption.checked);
    this.showDateFilters = true;
  }

  formatToggleFilters(filters: { filterName: string, propertyName: string, enabledToggleName: string, disabledToggleName: string }[]): void {
    filters.forEach((toggleFilter: { filterName: string, propertyName: string, enabledToggleName: string, disabledToggleName: string }) => {
      this.formatLocationMaterialsToggle(toggleFilter);
    });
  }

  formatLocationMaterialsToggle(toggleFilter: { filterName: string, propertyName: string, enabledToggleName: string, disabledToggleName: string }): void {
    const hasEnabledOption: boolean = this.filters[toggleFilter.filterName as keyof typeof this.filters].filter((filter: TextFilter) => filter.name === toggleFilter.enabledToggleName && filter.show).length > 0;
    const hasDisabledOption: boolean = this.filters[toggleFilter.filterName as keyof typeof this.filters].filter((filter: TextFilter) => filter.name === toggleFilter.disabledToggleName && filter.show).length > 0;
    let hideEnabledOption: boolean = true;
    let hideDisabledOption: boolean = true;
    this.materialMappingData.forEach((materialMapping: MaterialMappingNested) => {
      materialMapping.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
        if (!hasEnabledOption && locationMaterial[toggleFilter.propertyName as keyof typeof locationMaterial]) {
          this.filters[toggleFilter.filterName as keyof typeof this.filters].find((filter: TextFilter) => filter.name === toggleFilter.enabledToggleName)!.show = true;
          hideEnabledOption = false;
        } else if (locationMaterial[toggleFilter.propertyName as keyof typeof locationMaterial]) {
          hideEnabledOption = false;
        }
        if (!hasDisabledOption && !locationMaterial[toggleFilter.propertyName as keyof typeof locationMaterial]) {
          this.filters[toggleFilter.filterName as keyof typeof this.filters].find((filter: TextFilter) => filter.name === toggleFilter.disabledToggleName)!.show = true;
          hideDisabledOption = false;
        } else if (!locationMaterial[toggleFilter.propertyName as keyof typeof locationMaterial]) {
          hideDisabledOption = false;
        }
      });
    });
    if (hideEnabledOption)
      this.filters[toggleFilter.filterName as keyof typeof this.filters].find((filter: TextFilter) => filter.name === toggleFilter.enabledToggleName)!.show = false;
    if (hideDisabledOption)
      this.filters[toggleFilter.filterName as keyof typeof this.filters].find((filter: TextFilter) => filter.name === toggleFilter.disabledToggleName)!.show = false;
  }

  formatMaterialMappingFilters(): void {
    const toggleFilters: { filterName: string, propertyName: string, enabledToggleName: string, disabledToggleName: string }[] = [
      {filterName: 'status', propertyName: 'active', enabledToggleName: 'active', disabledToggleName: 'inactive'},
      {filterName: 'trackInv', propertyName: 'trackInv', enabledToggleName: 'active', disabledToggleName: 'inactive'},
      {filterName: 'groupMaterial', propertyName: 'aggregateUnderGroup', enabledToggleName: 'grouped', disabledToggleName: 'ungrouped'}
    ];
    this.formatToggleFilters(toggleFilters);
  }

  showLocationsInTable(): void {
    this.materialMappingData.forEach((location: MaterialMappingNested) => {
      const showLocationMaterials: boolean = location.locationMaterials.filter((locationMaterial: MaterialMappingData) => locationMaterial.show).length > 0;
      if (!showLocationMaterials)
        location.show = showLocationMaterials;
    });
  }

  applyToggleFiltersInTable(filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    this.materialMappingData.forEach((location: MaterialMappingNested) => {
      location.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
        const materialEnabled: boolean = (locationMaterial[filter.propertyName as keyof typeof locationMaterial] === true && filterOption.name === this.filters[filter.filterName as keyof typeof this.filters][1].name);
        const materialDisabled: boolean = (locationMaterial[filter.propertyName as keyof typeof locationMaterial] !== true && filterOption.name === this.filters[filter.filterName as keyof typeof this.filters][2].name);
        if (materialEnabled || materialDisabled) {
          locationMaterial.show = filterOption.checked;
        }
      });
    });
  }

  filterTable(filterNames: { filterName: string, propertyName: string }[]): void {
    this.materialMappingData.forEach((location: MaterialMappingNested) => {
      location.show = true;
      location.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
        locationMaterial.show = true;
      });
    });
    filterNames.forEach((filter: { filterName: string, propertyName: string }) => {
      this.filters[filter.filterName as keyof typeof this.filters].filter((filterOption: TextFilter) => filterOption.name !== 'all').forEach((filterOption: TextFilter) => {
        if (!filterOption.checked && (filter.filterName === 'locationName' || filter.filterName === 'locationCode')) {
          const locationData: MaterialMappingNested = this.materialMappingData.find((location: MaterialMappingNested) => (location.locationName === filterOption.name || location.locationCode === filterOption.name))!;
          locationData.show = filterOption.checked;
        } else if (!filterOption.checked && (filter.filterName === 'status' || filter.filterName === 'trackInv' || filter.filterName === 'groupMaterial')) {
          this.applyToggleFiltersInTable(filter, filterOption);
        } else if (!filterOption.checked) {
          this.hideLocationMaterials(filter, filterOption);
        }
      });
    });
    this.filterSeasonalDatesFromTable();
    this.showLocationsInTable();
  }

  hideLocationMaterials(filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    this.materialMappingData.forEach((location: MaterialMappingNested) => {
      location.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
        if (String(locationMaterial[filter.propertyName as keyof typeof locationMaterial]) === filterOption.name) {
          locationMaterial.show = filterOption.checked;
        }
      });
    });
  }

  applyCheckboxFilters(filterName: string, filterItem: any, index: number): void {
    this.filters[filterName as keyof typeof this.filters][index].checked = filterItem.target.checked;
    const isRelatedFilter: boolean = this.relatedFilters.filter((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName)).length > 0;
    const foundFilter: TextFilter[] = this.filters[filterName as keyof typeof this.filters];
    if (index !== 0) {
      // Check or uncheck clicked filter if filter option selected is not 'all'
      // Check or uncheck the all option for clicked filter
      const filterItems: TextFilter[] = this.filters[filterName as keyof typeof this.filters].slice(1, this.filters[filterName as keyof typeof this.filters].length);
      const checkAllOption: boolean = filterItems.length === filterItems.filter((filterOption: TextFilter) => filterOption.checked).length;
      foundFilter[0].checked = checkAllOption;
      if (isRelatedFilter) {
        // Check or uncheck for related filter
        const relatedFilterGroup: { relatedFilterNames: string[] } = this.relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          // Check or uncheck the all option for related filter
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          if(filterName==="locationName")
            {
            var currentitemselected=filterItem.currentTarget.value;
            var releateditem=this.locationNameCode.filter(x=>x.locationName===currentitemselected)
            index=foundRelatedFilter.findIndex(x => x.name ===releateditem[0].locationcode);
            }
            else if(filterName==="locationCode"){
              var currentitemselected=filterItem.currentTarget.value;
            var releateditem=this.locationNameCode.filter(x=>x.locationcode===currentitemselected)
            index=foundRelatedFilter.findIndex(x => x.name ===releateditem[0].locationName);
            }
          
          foundRelatedFilter[index].checked = filterItem.target.checked;
          foundRelatedFilter[0].checked = checkAllOption;
        });
      }
    } else {
      // Check or uncheck all filter options for clicked filter
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        // Check or uncheck all filter options for filters that are directly related
        const relatedFilterGroup: { relatedFilterNames: string[] } = this.relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
      this.showDateFilters = filterItem.target.checked;
    }
  }

  applyDateFilters(filterName: string, filterItem: any): void {
    if (filterItem !== "")
      this.seasonalDateFilters[filterName as keyof typeof this.seasonalDateFilters] = formatDate(String(filterItem), 'MM/dd/yyyy', 'en-US');
    else
      this.seasonalDateFilters[filterName as keyof typeof this.seasonalDateFilters] = "";
  }

  filterMaterialMappings(): void {
    const filterNames: { filterName: string, propertyName: string }[] = [
      { filterName: 'locationName', propertyName: 'locationName' },
      { filterName: 'locationCode', propertyName: 'locationCode' },
      { filterName: 'status', propertyName: 'active' },
      { filterName: 'trackInv', propertyName: 'trackInv' },
      { filterName: 'materialDescription', propertyName: 'materialDescription' },
      { filterName: 'materialNumber', propertyName: 'materialNumber' },
      { filterName: 'groupMaterial', propertyName: 'aggregateUnderGroup' },
      { filterName: 'materialGroupName', propertyName: 'materialGroupDescription' },
      { filterName: 'materialGroupNumber', propertyName: 'materialGroup' }
    ];
    this.filterTable(filterNames);
    this.store.dispatch(updateMaterialMappingFilters({
      materialMappingFilters: JSON.parse(JSON.stringify(this.filters)),
      materialMappingData: JSON.parse(JSON.stringify(this.materialMappingData))
    }));
  }

  getAdjustedDate(date: any, range: number): Date {
    let newDate: Date = new Date(date);
    return new Date(newDate.setDate(newDate.getDate() + range));
  }

  filterSeasonalDatesFromTable(): void {
    this.seasonalDateFilterNames.forEach((filter: string) => {
      const filterValue: string = this.seasonalDateFilters[filter as keyof typeof this.seasonalDateFilters];
      if (this.seasonalDateFilters[filter as keyof typeof this.seasonalDateFilters] !== "") {
        const earlierDate: Date = this.getAdjustedDate(filterValue, -7);
        const laterDate: Date = this.getAdjustedDate(filterValue, 7);
        this.materialMappingData.forEach((location: MaterialMappingNested) => {
          location.locationMaterials.forEach((locationMaterial: MaterialMappingData) => {
            const materialDate: Date = new Date(String(locationMaterial[filter as keyof typeof locationMaterial]!));
            if (!(materialDate >= earlierDate && materialDate <= laterDate)) {
              locationMaterial.show = false;
            }
          });
        });
      }
    });
  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText = [];
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          if (filter.name.toLowerCase().includes(text.value.toLowerCase()) && filter.name !== 'all') {
            filter.show = true;
          } else {
            filter.show = false;
          }
        });
      } else {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          filter.show = true;
        });
      }
    });
  }

  clearFilters(): void {
    this.searchText = [];
    this.searchValue = [];
    this.seasonalDateFilters.spmInvStartDate = "";
    this.seasonalDateFilters.spmInvEndDate = "";
    this.seasonalDateFilters.spmDemandForecastStartDate = "";
    this.seasonalDateFilters.spmDemandForecastEndDate = "";
    this.seasonalDateFilters.spmActualLiftStartDate = "";
    this.seasonalDateFilters.spmActualLiftEndDate = "";
    this.formatDefaultMaterialMappingFilters();
    this.store.dispatch(setDefaultMaterialMappingFilters({
      materialMappingFilters: JSON.parse(JSON.stringify(this.filters)),
      materialMappingData: JSON.parse(JSON.stringify(this.materialMappingData))
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
