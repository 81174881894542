import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nomination-panel',
  templateUrl: './nomination-panel.component.html',
  styleUrls: ['./nomination-panel.component.scss']
})
export class NominationPanelComponent implements OnInit {
  @Input() nominationDetails: any;
  @Input() addDetails: any;
  constructor() { }

  ngOnInit() {
    
   }

  collapseMenu(e:any) {
    let headEle = e.target;
    let ele = e.target.nextElementSibling;
    if(ele.classList.contains('show')) {
      ele.classList.remove("show");
      headEle.classList.remove("expanded");
    } else {
      setTimeout(() => {
        ele.className = "collapse show";
        headEle.classList.add("expanded");
      },0)
    }
  }


}