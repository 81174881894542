import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { MyCellRendererParams } from '../more-menu/more-menu.component';
import { Router } from '@angular/router';
import { Utilities } from '../../utilities/utilities';
import { RelatedNomination } from './models/related-nomination-model';
import { UDMDesk } from '../../constants/terminal-replenishment.constant';

@Component({
  selector: 'app-in-transit-more-menu',
  templateUrl: './more-menu-in-transit.template.html',
  styleUrls: ['./more-menu-in-transit.styles.scss']
})
export class InTransitMoreMenuComponent implements OnDestroy {
  @Input() params: any;

  values: { [key: string]: any } = {};

  constructor(
    private router: Router,
    private utilities: Utilities
  ) {}

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.initializeParams(params);    
  }

  private initializeParams(params: ICellRendererParams<any, any, any>): void {
    if(params.data['relatedNominations']) this.values['relatedNominations'] = params.data['relatedNominations'];
    this.values['inTransitQty'] = params.valueFormatted;
    this.values['isEditable'] = this.values['relatedNominations'].length > 0;
  }

  public editNomination(data: RelatedNomination) {
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editRelatedNomination',
      type: 'group',
      values: data
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  ngOnDestroy() {
      this.values = {};
  }
}