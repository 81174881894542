import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmModalObj } from '../shared/interface/interface';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {
  private confirmModalObj$ = new BehaviorSubject<ConfirmModalObj>({
    show: false,
    msg: '',
  });
  checkConfirmModal$ = this.confirmModalObj$.asObservable();

  setModalAction(obj: ConfirmModalObj) {
    this.confirmModalObj$.next(obj);
  }
}
