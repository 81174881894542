<div class="create-cca-cargo-duplicate">
    <form [formGroup]="createDuplicateCargoForm" (keydown.enter)="$event.preventDefault()">
        <div class="form-group col-md-12">
            <label for="duplicate" class="label"><span class="text-danger">* </span>Number of Duplicates </label>
            <input type="number" formControlName="duplicate" class="form-control" id="duplicate" pattern ="\d+">
        </div>
        <div class="form-group col-md-12">
            <label for="cycle" class="label"><span class="text-danger">* </span>Supply cycle</label>
            <input type="number" formControlName="cycle" class="form-control" id="cycle" pattern ="\d+">
        </div>
    <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6">
            <button class="button" (click)="closePopup()">cancel</button>
        </div>
        <div class="form-group col-md-6" >
            <button class="button"  [disabled]="!createDuplicateCargoForm.valid" (click)="submitForm() "
            >create</button>
        </div>
    </div>
    </form>
</div>