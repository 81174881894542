import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-demand-field',
  templateUrl: './demand-field.component.html',
  styleUrls: ['./demand-field.component.scss']
})
export class DemandFieldComponent implements OnInit {
  public amountOfDaysDropdown = ["3", "5", "7"];
  public ruleDropdown = ["<=",">="];
  @Input() selectedNoOfDays!: string;
  @Input() selectedRules!: string;
  @Input() selectedPercent!: number;
  @Output() public demandData = new EventEmitter<{selectedNoOfDays: string, selectedRules: string, selectedPercent: number}>();
  badThreshold: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  shareDemandDetails(){
    this.demandData.emit({selectedNoOfDays: this.selectedNoOfDays,selectedRules: this.selectedRules, selectedPercent: this.selectedPercent});
  }

  checkThresholdBoundaries(){
    this.badThreshold = this.selectedPercent == null || this.selectedPercent < 1 || this.selectedPercent > 99? true: false;
    this.shareDemandDetails()
  }
}
