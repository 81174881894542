import { ToastService } from 'src/app/services/toast.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, Renderer2, ElementRef, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateRange, MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Utilities } from '../utilities/utilities';
import { NominationService } from 'src/app/services/nomination.service';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CustomDateRangePickerTabConfig } from '../interface/custom-date-range-picker.interface';
import { loadDynamicDeals } from 'src/app/dynamicdeals/store/action/cb-dynamic-deals.action';
import { Store } from '@ngrx/store';
import { getPayloadForDynamicDeals, getSelectedDeskId } from 'src/app/dynamicdeals/store/reducer/cb-dynamic-deals.reducer';
import { months } from 'src/app/dynamicdeals/store/reducer/cb-dynamic-deals.reducer';
import { selectUpdateCustomDateRangeFlag } from 'src/app/dynamicdeals/store/selector/cb-dynamic-deals.selector';
import { CloseDropdownDirective } from '../filter-dropdown/directives/close-dropdown.directive';
@Component({
  selector: 'app-custom-date-range-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    CloseDropdownDirective
  ],
  templateUrl: './custom-date-range-picker.component.html',
  styleUrls: ['./custom-date-range-picker.component.scss']
})
export class CustomDateRangePickerComponent implements OnInit,AfterViewInit {
  @Input() selectedRangeValue!: DateRange<Date>;
  @Input() changedDeskObj:any;  
  //@Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  showCustomPicker = false;
  selectedTabIndex = 0;
  //dateRange = {start: null, end: null};
  pickerTabs:CustomDateRangePickerTabConfig[] = [{label:'month',isTabSelected: true},{label:'year',isTabSelected: false},{label:'custom',isTabSelected: false}];
  monthsArr:string[] = months;
  years:number[]=[];
  selectedMonths:Set<string> = new Set();
  selectedMonthsIndex:number[]=[];
  selectedYears:Set<number> = new Set();
  selectedTab!:CustomDateRangePickerTabConfig;
  isDefaultInputPicker:boolean = true;
  selectedYearRange:string = '';
  selectedDate!:Date;
  @ViewChild('customPicker') customPicker!: MatDateRangePicker<Date>;
  @ViewChild(MatDateRangePicker) matDateRangePicker!: MatDateRangePicker<any>;
  @ViewChild('selectedDateRange') selectedDateRange!: ElementRef;
  startAt:any;
  @Output() selectedValues = new EventEmitter();
  @ViewChild('customDateRangePickerRef') customDateRangePickerRef!: ElementRef;
  updateCustomDateRangeFlag$ = this.store.select(selectUpdateCustomDateRangeFlag)

  constructor( private datePipe: DatePipe,private utilities: Utilities,private toasterService: ToastService,private nsServe: NominationService, private renderer: Renderer2, private cdr: ChangeDetectorRef,private store: Store) {
     }

  ngAfterViewInit(){
    // to set default date range in date filter input
    this.setSelectedDateRange();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['changedDeskObj']?.previousValue?.deskParameter !== changes['changedDeskObj']?.currentValue?.deskParameter){
      this.showCustomPicker = false;
    }
  }

  openCustomPicker() {
    this.showCustomPicker = true;
  }

  ngOnInit(): void {
    const getLocalStorageValues = localStorage.getItem(`cbCustomDateRangeValues_desk${getSelectedDeskId()}`);
    if(getLocalStorageValues){
      this.restoreCustomDateRangePickerFromLocalStorage(getLocalStorageValues);
    }else{
      this.initializeCustomDateRangePicker();
    }

    this.updateCustomDateRangeFlag$.subscribe(data =>{
      if(data?.updateCustomDateRangePickerFlag){
        const getLocalStorageValues = localStorage.getItem(`cbCustomDateRangeValues_desk${getSelectedDeskId()}`);
        if(getLocalStorageValues){
          this.restoreCustomDateRangePickerFromLocalStorage(getLocalStorageValues);
        }else{
          this.initializeCustomDateRangePicker();
        }
        // to reset values in custom date filter input
        this.setSelectedDateRange();
      }
    });
  }

  restoreCustomDateRangePickerFromLocalStorage(getLocalStorageValues:any){
    //this.years= [];
    getLocalStorageValues = JSON.parse(getLocalStorageValues);
    // tabs
    this.selectedTab = getLocalStorageValues?.selectedTab;
    this.pickerTabs = getLocalStorageValues?.pickerTabs;
    // month tab
    this.selectedMonths.clear();
    getLocalStorageValues?.selectedMonths?.forEach((month:any)=>{
      this.selectedMonths.add(month);
    });
    // year tab
    this.selectedYears.clear();
    getLocalStorageValues?.selectedYears?.forEach((year:any)=>{
      this.selectedYears.add(year);
    });
    this.years = getLocalStorageValues?.years;
    this.selectedYearRange = getLocalStorageValues?.selectedYearRange;

    // custom tab
    this.startAt = getLocalStorageValues?.selectedRangeValue?.start?(new Date(getLocalStorageValues?.selectedRangeValue?.start)):(new Date());
    this.selectedRangeValue =getLocalStorageValues?.selectedRangeValue?.start?(new DateRange<Date>(new Date(getLocalStorageValues?.selectedRangeValue?.start), new Date(getLocalStorageValues?.selectedRangeValue?.end))):(new DateRange<Date>(null, null));
  }

  initializeCustomDateRangePicker(isReset?:boolean){
    this.pickerTabs = [{label:'month',isTabSelected: true},{label:'year',isTabSelected: false},{label:'custom',isTabSelected: false}];
    this.years= [];
    this.selectedYears.clear();
    this.selectedMonths.clear();
    this.selectedTab = this.pickerTabs[0];
    //to reset month, year selected in custom tab
    this.startAt = new Date();
    // to reset date range selection
    this.selectedRangeValue = new DateRange<Date>(null, null);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const eleventhYearFromCurrentYear = currentYear + 11;
    this.selectedYearRange = `${currentYear} - ${eleventhYearFromCurrentYear}`;
    this.selectedYears.add(currentYear);
    for(let i=currentYear;i<=eleventhYearFromCurrentYear;i++){
      this.years.push(i);
    }
  
    // Add current month as default
    const currentMonth = months[new Date().getMonth()];
    this.selectedMonths.add(currentMonth);
    if(isReset){
      this.selectedTab = this.pickerTabs[0];
      this.pickerTabs?.forEach((tab:CustomDateRangePickerTabConfig)=>{
        tab.isTabSelected = tab.label === this.selectedTab.label;
      });
      // to reset values in custom date filter input
      this.setSelectedDateRange();
      // to emit resetted values to home component
      const selectedDatePickerValues = this.getFirstAndLastDatesOfSelectedMonths();
      this.selectedValues.emit(selectedDatePickerValues);
      localStorage.removeItem(`cbCustomDateRangeValues_desk${getSelectedDeskId()}`);
      
      const startDate = `${this.datePipe.transform(selectedDatePickerValues.firstDate, "yyyy-MM-dd")} 00:00:00`;
      const endDate = `${this.datePipe.transform(selectedDatePickerValues.lastDate, "yyyy-MM-dd")} 23:59:59`;
      localStorage.setItem(`datefilterPayload_Cb_desk${getSelectedDeskId()}`,JSON.stringify({startDate:startDate,endDate:endDate}));
      const payload = getPayloadForDynamicDeals();
      this.store.dispatch(loadDynamicDeals({payload,fromDateChange:true}));
      //to reset month, year selected in custom tab
      this.startAt = new Date();

      this.toasterService.setToastNotification({ show: false, msg: '', type: '' });
    }
 }

  get convertedSelectedYears():number[] {
    return this.getSelectedYearsArr();
  }

  getSelectedYearsArr() {
    return Array.from(this.selectedYears);
  }

  toggleMonthSelection(month:string,index:any):void {
    if(this.selectedMonths.has(month)){
      // Temporarily remove the month to test if the remaining months are consecutive
      this.selectedMonths.delete(month);
      if(!this.areMonthsConsecutive(Array.from(this.selectedMonths))) {
        // If not consective, re-add the month
        this.selectedMonths.add(month);
      }
    } else {
      if(this.selectedMonths.size < 3){
        if(this.selectedMonths.size > 0) {
          this.checkIfNewMonthWithinRange(month);
        } else {
          this.selectedMonths.add(month);
        }
       } else {
        // Reset selection to only the new month
        this.selectedMonths.clear();
        this.selectedMonths.add(month);
       }
    }
  }

  private checkIfNewMonthWithinRange(month:any) {
    const selectedArray = Array.from(this.selectedMonths).sort((a,b)=>months.indexOf(a) - months.indexOf(b));
    const firstSelectedIndex = months.indexOf(selectedArray[0]);
    const lastSelectedIndex = months.indexOf(selectedArray[selectedArray.length - 1]);
    const currentIndex = months.indexOf(month);
    if(currentIndex === firstSelectedIndex - 1 || currentIndex === lastSelectedIndex + 1 ) {
      this.selectedMonths.add(month);
    } else {
      // Reset selection to only the new month
      this.selectedMonths.clear();
      this.selectedMonths.add(month);
    }
  }

  private areMonthsConsecutive(selectedMonths: string[]): boolean {
    if(selectedMonths.length <= 1){
      return  true;
    }
    const indices = selectedMonths.map(month=> months.indexOf(month)).sort((a,b)=>a-b);
    for(let i=1;i< indices.length;i++){
      if(indices[i] !== indices[i-1] +1){
        return false;
      }
    }
    return true;
  }

  isSelected(month:string):boolean {
    return this.selectedMonths.has(month);
  }

  get getSelectedMonths(): string{
    const selectedMonthsArr:any = this.getSelectedMonthsInOrder();
    if(selectedMonthsArr.length){
      return selectedMonthsArr?.join(' & ');
    } else {
      return months[new Date().getMonth()];
    }
  }

  getSelectedMonthsArr(){
    return Array.from(this.selectedMonths);
  }

  getSelectedMonthsInOrder(): string[] {
    return Array.from(this.selectedMonths).sort((a,b)=>months.indexOf(a) - months.indexOf(b));
  }
  
  displayTabContent(selectedTab:CustomDateRangePickerTabConfig){
    this.pickerTabs?.forEach((tab:CustomDateRangePickerTabConfig)=>{
      tab.isTabSelected = tab.label === selectedTab.label;
    });
    this.selectedTab = selectedTab;
  }

  selectedChange(m: any) {
      if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
          this.selectedRangeValue = new DateRange<Date>(m, null);
      } else {
          const start = this.selectedRangeValue.start;
          const end = m;
          if (end < start) {
              this.selectedRangeValue = new DateRange<Date>(end, start);
          } else {
              this.selectedRangeValue = new DateRange<Date>(start, end);
          }
      }
  }

  isYearSelected(year:number):boolean {
    return this.selectedYears.has(year);
  }

  toggleYearSelection(year:number):void {
    this.selectedYears.clear();
    this.selectedYears.add(year);
  }

  decreaseYearRange(){
    this.years = [];
    const getSelectedStartYear = +this.selectedYearRange.split("-")[0];
    const getSelectedEndYear = +this.selectedYearRange.split("-")[1];
    const calculatedStartYear = getSelectedStartYear-11;
    const calculatedEndYear = getSelectedEndYear-11;
    this.selectedYearRange = `${calculatedStartYear} - ${calculatedEndYear}`;
   
    for(let i=calculatedStartYear;i<=calculatedEndYear;i++){
      this.years.push(i);
    }
  }

  increaseYearRange(){
    this.years = [];
    const getSelectedStartYear = +this.selectedYearRange.split("-")[0];
    const getSelectedEndYear = +this.selectedYearRange.split("-")[1];
    const calculatedStartYear = getSelectedStartYear+11;
    const calculatedEndYear = getSelectedEndYear+11;
    this.selectedYearRange = `${calculatedStartYear} - ${calculatedEndYear}`;
    
    for(let i=calculatedStartYear;i<=calculatedEndYear;i++){
      this.years.push(i);
    }
  }

  reset(){
    this.showCustomPicker = false;
    const resetDatePicker = true;
    this.initializeCustomDateRangePicker(resetDatePicker);
  }

  cancel(targetElement?:any){
    if(!targetElement?.className?.includes("mat-calendar")){
      this.showCustomPicker = false;
    }
    
  }

  onMouseHoverLeft()
  {
    this.showCustomPicker = false;

  }

  apply(){
    let selectedDatePickerValues;
    let selectedFromDateToDateObj:any ={};
     // if date range is selected in custom tab and apply is clicked
    if(this.selectedTab.label === 'custom' && this.selectedRangeValue?.start && this.selectedRangeValue?.end){
      this.showCustomPicker = false;
      //this.selectedRangeValueChange.emit(this.selectedRangeValue);
      const formattedStartDate = this.selectedRangeValue.start;
      const formattedEndDate = this.selectedRangeValue.end;
      const formattedDateRange =this.formatDateRange(formattedStartDate,formattedEndDate);
      this.setSelectedDateRange(formattedDateRange);
      selectedDatePickerValues = {firstDate: this.selectedRangeValue.start, lastDate: this.selectedRangeValue.end};

      selectedFromDateToDateObj.startDate = this.selectedRangeValue.start;
      selectedFromDateToDateObj.endDate = this.selectedRangeValue.end;
    }
    // if no date range is selected in custom tab and clicked apply directly
    if(this.selectedTab.label === 'custom' && (!this.selectedRangeValue.start || !this.selectedRangeValue.end)){
      const infoMsg = (this.selectedRangeValue.start && !this.selectedRangeValue.end)? 'Please select an end date':'Please select a date range';
      this.toasterService.setToastNotification({ show: true, type: "info", msg: infoMsg });
      return;
    } else {
      this.toasterService.setToastNotification({ show: false, msg: '', type: '' });
    }

    // if we click apply from month or year tab
    if(this.selectedTab.label === 'month' || this.selectedTab.label === 'year'){
      this.showCustomPicker = false;
      this.setSelectedDateRange();
      selectedDatePickerValues = this.getFirstAndLastDatesOfSelectedMonths();
      selectedFromDateToDateObj.startDate = selectedDatePickerValues.firstDate;
      selectedFromDateToDateObj.endDate = selectedDatePickerValues.lastDate;
    }
    const cbCustomDateRangeValues = {
      pickerTabs: this.pickerTabs,
      selectedTab: this.selectedTab,
      selectedYears: this.getSelectedYearsArr(),
      selectedMonths: this.getSelectedMonthsInOrder(),
      selectedYearRange: this.selectedYearRange,
      selectedRangeValue: {
        start: this.selectedRangeValue.start,
        end: this.selectedRangeValue.end
      },
      years: this.years,
      selectedDatePickerValues
    };
    localStorage.setItem(`cbCustomDateRangeValues_desk${getSelectedDeskId()}`,JSON.stringify(cbCustomDateRangeValues));
    const {startDate, endDate}= selectedFromDateToDateObj;
    localStorage.setItem(`datefilterPayload_Cb_desk${getSelectedDeskId()}`,JSON.stringify({startDate:startDate,endDate:endDate}));
    const payload = getPayloadForDynamicDeals();
    this.store.dispatch(loadDynamicDeals({payload,fromDateChange:true}));
     
    this.selectedValues.emit(selectedDatePickerValues)
  }

  formatDateRange(startDate:any,endDate:any) {
    const formattedDateRange = `${this.datePipe.transform(startDate, "MM/dd")} - ${this.datePipe.transform(endDate, "MM/dd/yyyy")}`;
    return formattedDateRange;
  }

  getFirstAndLastDatesOfSelectedMonths():{firstDate: Date | null, lastDate: Date | null} {
    const selectedMonthsInOrder = this.getSelectedMonthsInOrder();
    const selectedYear = this.getSelectedYearsArr()[0];
    if(selectedMonthsInOrder.length === 0  && selectedYear){
      return { firstDate:this.getFirstAndLastDatesOfSelectedYear().firstDateOfYear, lastDate: this.getFirstAndLastDatesOfSelectedYear().lastDateOfYear };
    }

    const firstMonthIndex = months.indexOf(selectedMonthsInOrder[0]);
    const lastMonthIndex = months.indexOf(selectedMonthsInOrder[selectedMonthsInOrder.length - 1]);
    
    const firstDate = new Date(selectedYear, firstMonthIndex,1);
    const lastDate = new Date(selectedYear, lastMonthIndex + 1, 0); // Last day of the last selected month
    
    return {firstDate, lastDate};

  }

  getFirstAndLastDatesOfSelectedYear(){
    const selectedYear = this.getSelectedYearsArr()[0];
    
    const firstDateOfYear = new Date(selectedYear, 0 ,1);
    const lastDateOfYear = new Date(selectedYear, 11, 31);

    return {firstDateOfYear, lastDateOfYear};
  }

  // to set selected values in custom date filter input
  setSelectedDateRange(value?:string){
    if(this.selectedTab.label === 'month' || this.selectedTab.label === 'year'){
      const selectedMonthsArr:any = this.getSelectedMonthsInOrder();
      const selectedYear = this.getSelectedYearsArr();
      // If both months and year selected
      if(selectedMonthsArr.length && selectedYear.length){
        this.selectedDateRange.nativeElement.value =  `${selectedMonthsArr?.join(', ')}, ${selectedYear[0]}`;
      } 
      // if all months are deselected and only year selected
      if(!selectedMonthsArr.length && selectedYear.length){
        const {firstDateOfYear,lastDateOfYear} = this.getFirstAndLastDatesOfSelectedYear();
        const formattedFirstAndLastDateOfYear = this.formatDateRange(firstDateOfYear,lastDateOfYear);
        this.selectedDateRange.nativeElement.value =  formattedFirstAndLastDateOfYear;
      } 
      // to get first and last date of selected months and year
      // this.getFirstAndLastDatesOfSelectedMonths() //this will return {firstDate, lastDate}
    }
    if(this.selectedTab.label === 'custom'){
      // to view selected date range values on page refresh
      const formattedStartDate = this.selectedRangeValue?.start;
      const formattedEndDate = this.selectedRangeValue?.end;
      const formattedDateRange =this.formatDateRange(formattedStartDate,formattedEndDate);
      value = value??formattedDateRange;
      this.selectedDateRange.nativeElement.value = value;
      // to retain date range selection
      if (this.selectedRangeValue?.start && this.selectedRangeValue?.end){
        this.startAt = new Date(this.selectedRangeValue?.start);
        this.selectedRangeValue =new DateRange<Date>(this.selectedRangeValue?.start, this.selectedRangeValue?.end);
      }
      // to get first and last date of selected range while in custom tab
      // const formattedStartDate = this.selectedRangeValue.start;
      // const formattedEndDate = this.selectedRangeValue.end;
    }
  } 
}
