import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { TextFilter, TransportFunctionalGroup, DeskTransportFilters, TransportRegionDeskMapping, DeskTsMapping, TransportSystem } from '../../../../shared/interface/mdm-admin.interface';
import { selectDeskTs } from '../../../../../app/admin-transport/store/selector/admin-transport.selector';
import { setDefaultTransportsFilter, updateTransportsFilter } from '../../../../../app/admin-transport/store/action/admin-transport.action';

@Component({
  selector: 'app-transport-filter',
  templateUrl: './transport-filter.component.html',
  styleUrls: ['./transport-filter.component.scss']
})
export class TransportFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  deskTsData : TransportFunctionalGroup[] = [];
  filters: DeskTransportFilters = {
    transportFunctionalGroup: [],
    region: [],
    transportSystem: [],
    modeOfTransport: [],
    transportSystemName:[]
  };
  relatedFilters: { relatedFilterNames: string[] }[] = [
    { relatedFilterNames: [ 'transportSystem','transportSystemName' ] }
  ];

  destroy$: Subject<boolean> = new Subject<boolean>();
  deskTsData$ = this.store.pipe(select(selectDeskTs));

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.deskTsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
          if (response.deskTs.length && response.initializeTransportFilter) {
          this.deskTsData = JSON.parse(JSON.stringify(response.deskTs));
          this.formatDefaultDeskTsFilters();
          this.store.dispatch(setDefaultTransportsFilter({
            transportFilter: JSON.parse(JSON.stringify(this.filters)),
            deskTs: JSON.parse(JSON.stringify(this.deskTsData))
          }));
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });

  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText = [];
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          if (filter.name.toLowerCase().includes(text.value.toLowerCase()) && filter.name !== 'all') {
            filter.show = true;
          } else {
            filter.show = false;
          }
        });
      } else {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          filter.show = true;
        });
      }
    });
  }

  formatDefaultDeskTsFilters() {
    const allOption: TextFilter = { name: 'all', checked: true, show: true };
    this.filters = {
      transportFunctionalGroup: [{ ...allOption}],
      region: [{ ...allOption}],
      transportSystem: [{ ...allOption}],
      modeOfTransport: [{ ...allOption}],
      transportSystemName:[{ ...allOption}]
    };

    const uniqueModeOfTransport = new Set<string>();
    const uniqueRegions = new Set<string>();
    const uniqueTransportSystem = new Set<string>();
    const uniqueTransportSystemName = new Set<string>();
     this.deskTsData.forEach(functionalGroup => {
      // Functional Group Check
      if (functionalGroup.functionalGroup !== null && functionalGroup.functionalGroup !== '' && functionalGroup.functionalGroup !== 'null') {
        this.filters.transportFunctionalGroup.push({ name: functionalGroup.functionalGroup, checked: allOption.checked, show: allOption.show });
      }
      functionalGroup.show = allOption.show;
      functionalGroup.regionDeskMapping.forEach(region => {
        // Region Check
        if (region.region !== null && region.region !== '' && region.region !== 'null' ) {
          this.filters.region.push({ name: region.region, checked: allOption.checked, show: allOption.show });
        }
        else if(!uniqueRegions.has(region.region)) {
          uniqueRegions.add(region.region);
          this.filters.region.push({ name: "n/a", checked: allOption.checked, show: allOption.show });
        }
        region.show = allOption.show;
        this.setDeskTsFilters(region, allOption.show, allOption.checked, uniqueModeOfTransport, uniqueTransportSystem, uniqueTransportSystemName);
      });
    });
    //set all on first index of array
    const tsIndex =  this.filters.transportSystem.findIndex(x => x.name === 'all');
    if (tsIndex) {
    const [ ts ] =  this.filters.transportSystem.splice(tsIndex, 1); // delete ts
    this.filters.transportSystem.unshift(ts);
    }//add at first index

    const motIndex =  this.filters.modeOfTransport.findIndex(x => x.name === 'all');
    if (motIndex) {
    const [ mot ] =  this.filters.modeOfTransport.splice(motIndex, 1); // delete mot
    this.filters.modeOfTransport.unshift(mot);
    }//add at first index

    const tsnIndex =  this.filters.transportSystemName.findIndex(x => x.name === 'all');
    if (tsnIndex) {
    const [ tsn ] =  this.filters.transportSystemName.splice(tsnIndex, 1); // delete tsn
    this.filters.transportSystemName.unshift(tsn);
    }//add at first index
  }

  setDeskTsFilters(region: TransportRegionDeskMapping, showFilter: boolean, checkFilter: boolean, uniqueModeOfTransport: Set<string>, uniqueTransportSystem: Set<string>, uniqueTransportSystemName: Set<string>): void {
    region.deskTsMappings.forEach(deskTsMapping => {
      deskTsMapping.show = showFilter;
      deskTsMapping.transportSystems.forEach(transport => {
        if (transport.transportSystem !== null && transport.transportSystem !== '' && transport.transportSystem !== 'null' ) {
          if (!uniqueTransportSystem.has(transport.transportSystem)) {
            uniqueTransportSystem.add(transport.transportSystem);
            this.filters.transportSystem.push({ name: transport.transportSystem, checked: checkFilter, show: showFilter });
          }
          if (!uniqueModeOfTransport.has(transport.modeOfTransport)) {
            uniqueModeOfTransport.add(transport.modeOfTransport);
            this.filters.modeOfTransport.push({ name: transport.modeOfTransport, checked: checkFilter, show: showFilter });
          }
          if (!uniqueTransportSystemName.has(transport.transportSystemName)) {
            uniqueTransportSystemName.add(transport.transportSystemName);
            this.filters.transportSystemName.push({ name: transport.transportSystemName, checked: checkFilter, show: showFilter });
          }
          transport.show = showFilter;
        }
        });
        //sort the array A-Z
        this.filters.transportSystem.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.filters.modeOfTransport.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.filters.transportSystemName.sort((a, b) => (a.name < b.name ? -1 : 1));
    });
  }

  showDeskTsMappings(region: TransportRegionDeskMapping): void {
    region.deskTsMappings.forEach(deskTsMapping => {
      deskTsMapping.show = true
      deskTsMapping.transportSystems.forEach(transport => {
        transport.show = true
      });
    });
  }

  filterTable(filterNames: { filterName: string, propertyName: string }[]): void {
    this.deskTsData.forEach(functionalGroup => {
      functionalGroup.show = true
      functionalGroup.regionDeskMapping.forEach(region => {
        region.show = true
        this.showDeskTsMappings(region);
      });
    });
    filterNames.forEach((filter: { filterName: string, propertyName: string }) => {
      this.filters[filter.filterName as keyof typeof this.filters].filter((filterOption: TextFilter) => filterOption.name !== 'all' && filterOption.show).forEach((filterOption: TextFilter) => {
        if (!filterOption.checked) {
          // hide deselected functional groups
          if(filter.filterName === "transportFunctionalGroup") {
            const functionalGroup: TransportFunctionalGroup = this.deskTsData.find((functionalGroup: TransportFunctionalGroup) => functionalGroup.functionalGroup === filterOption.name)!;
            functionalGroup.show = filterOption.checked;
          }
          // hide deselected regions and transports
          else if (filter.filterName === "region" || filter.filterName === "transportSystem" || filter.filterName === "transportSystemName" || filter.filterName === "modeOfTransport") {
            this.deskTsData.forEach(functionalGroup => {
              this.hideRegionsAndTransports(functionalGroup, filter, filterOption);
            });
          }
        }
      });
    });
    this.hideNestedTables();
  }

  hideRegionsAndTransports(functionalGroup: TransportFunctionalGroup, filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    functionalGroup.regionDeskMapping.forEach(region => {
      if (((filterOption.name !== 'n/a' && region.region === filterOption.name)
        || ((region.region === null || region.region === '' || region.region === 'null') && filterOption.name === 'n/a'))
        && filter.filterName === "region"
      ) {
        region.show = filterOption.checked;
      } else if (filter.filterName === "transportSystem" || filter.filterName === "transportSystemName" || filter.filterName === "modeOfTransport") {
        region.deskTsMappings.forEach(deskTsMapping => {
          deskTsMapping.transportSystems.forEach((transportSystem: TransportSystem) => {
            if ((transportSystem.transportSystem === filterOption.name && filter.filterName === "transportSystem")
              || (transportSystem.modeOfTransport === filterOption.name && filter.filterName === "modeOfTransport")
            ) {
              transportSystem.show = filterOption.checked;
            }
          });
        });
      }
    });
  }

  hideNestedTables(): void {
    this.deskTsData.forEach((functionalGroup: TransportFunctionalGroup) => {
      const showFunctionalGroups: boolean = functionalGroup.regionDeskMapping.filter((region: TransportRegionDeskMapping) => region.show).length > 0;
      if (!showFunctionalGroups) {
        functionalGroup.show = showFunctionalGroups;
      }
      functionalGroup.regionDeskMapping.forEach(region => {
        const showRegions: boolean = region.deskTsMappings.filter((desk: DeskTsMapping) => desk.show).length > 0;
        if (!showRegions) {
          region.show = showRegions;
        }
      });
    });
  }

  filterTransport(): void {
    const filterNames: { filterName: string, propertyName: string }[] = [
      { filterName: 'transportFunctionalGroup', propertyName: 'transportFunctionalGroup' },
      { filterName: 'region', propertyName: 'region' },
      { filterName: 'transportSystem', propertyName: 'transportSystem' },
      { filterName: 'modeOfTransport', propertyName: 'modeOfTransport' },
      { filterName: 'transportSystemName', propertyName: 'transportSystemName' },
    ];
    this.filterTable(filterNames);
    this.store.dispatch(updateTransportsFilter({
      transportFilter: JSON.parse(JSON.stringify(this.filters)),
      deskTs: JSON.parse(JSON.stringify(this.deskTsData))
    }));
  }

  applyCheckboxFilters(filterName: string, filterItem: any, index: number): void {
    this.filters[filterName as keyof typeof this.filters][index].checked = filterItem.target.checked;
    const isRelatedFilter: boolean = this.relatedFilters.filter((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName)).length > 0;
    const foundFilter: TextFilter[] = this.filters[filterName as keyof typeof this.filters];
    if (index !== 0) {
      // Check or uncheck clicked filter if filter option selected is not 'all'
      // Check or uncheck the all option for clicked filter
      const filterItems: TextFilter[] = this.filters[filterName as keyof typeof this.filters].slice(1, this.filters[filterName as keyof typeof this.filters].length);
      const checkAllOption: boolean = filterItems.length === filterItems.filter((filterOption: TextFilter) => filterOption.checked).length;
      foundFilter[0].checked = checkAllOption;
      if (isRelatedFilter) {
        // Check or uncheck for related filter
        const relatedFilterGroup: { relatedFilterNames: string[] } = this.relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          // Check or uncheck the all option for related filter
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter[index].checked = filterItem.target.checked;
          foundRelatedFilter[0].checked = checkAllOption;
        });
      }
    } else {
      // Check or uncheck all filter options for clicked filter
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        // Check or uncheck all filter options for filters that are directly related
        const relatedFilterGroup: { relatedFilterNames: string[] } = this.relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
  }

  clearFilters(): void {
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultDeskTsFilters();
    this.store.dispatch(setDefaultTransportsFilter({
      transportFilter: JSON.parse(JSON.stringify(this.filters)),
      deskTs: JSON.parse(JSON.stringify(this.deskTsData))
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
