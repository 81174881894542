import { createAction, props } from '@ngrx/store';
import { CBContractDetailsResponse, CBDPRResponse, CBFilterResponse, CBMPRResponse, CBMTDResponse } from 'src/app/shared/interface/contract-balancing.interface';
import { State } from '../reducer/contract-balancing.reducer';


export const invokeCBFilterDataApi = createAction(
    '[Contract Balancing API] Invoke CBFilterData API',
    props<{ dateRange: any, enableRefreshTableFlagArr: any[], desk: any, deskId: number }>()
);

export const getCBFilterDataApiSuccess = createAction(
    '[Contract Balancing API] get CB Filter Data api Success',
    props<{ allCBFilterData: State, dateRange: any, enableRefreshTableFlagArr: any[] }>()
);

export const updateCBFilterData = createAction(
    '[Contract Balancing API] update CB Filter Data and update total refreshTableFlag',
    props<{ cbFilterResponse: CBFilterResponse, refreshTableFlagArr: any[] }>()
);

export const invokeCBContractDetailsDataApi = createAction(
    '[Contract Balancing API] Invoke CBContractDetailsData API and disable refreshContractDetailsTable flag',
    props<{ payload: any }>()
);

export const getCBContractDetailsDataApiSuccess = createAction(
    '[Contract Balancing API] get CB Contract Details Data api Success',
    props<{ cbContractDetailsData: CBContractDetailsResponse[], refreshContractDetailsTable: boolean }>()
);

export const invokeCBMTDTrackingDataApi = createAction(
    '[Contract Balancing API] Invoke CBMTDTrackingData API and disable refreshMTDTrackingTable flag',
    props<{ payload: any }>()
);

export const getCBMTDTrackingDataApiSuccess = createAction(
    '[Contract Balancing API] get CB MTDTracking Data api Success',
    props<{ cbMTDData: CBMTDResponse[], refreshMTDTrackingTable: boolean }>()
);

export const invokeCBMPRDataApi = createAction(
    '[Contract Balancing API] Invoke CBMPRData API and disable refreshMPRTable flag',
    props<{ payload: any }>()
);

export const getCBMPRDataApiSuccess = createAction(
    '[Contract Balancing API] get CB MPR Data api Success',
    props<{ cbMPRData: CBMPRResponse[], refreshMPRTable: boolean }>()
);

export const invokeCBDPRDataApi = createAction(
    '[Contract Balancing API] Invoke CBDPRData API and disable refreshDPRTable flag',
    props<{ payload: any }>()
);

export const getCBDPRDataApiSuccess = createAction(
    '[Contract Balancing API] get CB DPR Data api Success',
    props<{ cbDPRData: CBDPRResponse[], refreshDPRTable: boolean }>()
);

export const updateRefreshTableFlag = createAction(
    '[Contract Balancing API] update Refresh Table Flag for selected CB Table',
    props<{ refreshTableFlag: any }>()
);