import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() label : string = ''
  @Input() checked : boolean = false;
  @Input() displayStatus : boolean = false;
  @Output() change = new EventEmitter();

  status: string =''

  ngOnInit() {
    this.status = this.checked ? 'on' : 'off';
  }

  toggle(event: any){
    this.status = event.target.checked ? 'on' : 'off';
    this.change.emit(event)
    event.stopPropagation()
  }

}
