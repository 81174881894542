import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { HomeComponent } from '../../../../home/home.component';
import { AlertsService } from '../../../../services/alerts.service';
import { AlertsNotificationsComponent } from '../../../../settings-alerts/alerts-notifications/alerts-notifications.component';
import { SubscriptionUsers, UpdateRuleInventory, UpdateRuleReconciliation } from '../../../../shared/interface/settings.interface';

@Component({
  selector: 'app-deny-edit-alert',
  templateUrl: './deny-edit-alert.component.html',
  styleUrls: ['./deny-edit-alert.component.scss']
})
export class DenyEditAlertComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();

  selectedUserList: string[] = []
  public showUsers = false;
  selectedMaterial = '';
  selectedTerminal = '';
  selectedRuleType = '';
  selectedReference = '';
  selectedNoOfDays = '';
  selectedRules = '';
  selectedParameters = '';
  selectedPercent = '';
  selectedRuleName = '';
  selectedFreq = '';
  selectedFreqFull = '';
  selectedSummary = '';
  selectedId = '';
  selectedCreatedBy = '';
  selectedCreatedDate = '';
  selectedUpdatedBy = '';
  selectedUpdatedDate = '';
  materialNumber = '';
  materialGroup = '';
  udmNickname = '';
  public updateRuleToggleInventory!: UpdateRuleInventory;
  public updateRuleToggleReconciliation!: UpdateRuleReconciliation;
  public subscriptions!: SubscriptionUsers[];
  subscriptionList!: SubscriptionUsers[]
  public newActive!: { userEmail: string, isEmail: boolean, isTeams: boolean, isWorkspace: boolean, isActive: boolean };
  public terminalDropdown: string[] = [];
  public materialDropdown: string[] = [];
  public rulesDropdown: string[] = [];
  public ruleNamesList: string[] = [];

  constructor(private alertsService: AlertsService, private authService: CalAngularService, private alertsComponent: AlertsNotificationsComponent, private homeComponent: HomeComponent) { }

  ngOnInit(): void {
    this.getAlertRulesData()
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentMaterial.subscribe(() => this.getRulesByTerminalMaterial(this.selectedTerminal, this.selectedMaterial))
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
    this.alertsService.currentRuleName.subscribe(() => this.getRuleDetails(this.selectedTerminal, this.selectedMaterial, this.selectedRuleName))
  }

  getAlertRulesData() {
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        response.result?.forEach((element: any) => {
          this.terminalDropdown.push(element.location);
          for (const i of element.locationMaterials) {
            if (!this.materialDropdown.includes(i.material)) {
              this.materialDropdown.push(i.material);
            } for (const j of i.locationMaterialRules) {
              this.ruleNamesList.push(j.ruleName);
            }
          }
        })
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getRulesByTerminalMaterial(selectedTerminal: string, selectedMaterial: string) {
    this.rulesDropdown = [];
    this.selectedRuleType = '';
    this.selectedReference = '';
    this.selectedNoOfDays = '';
    this.selectedRules = '';
    this.selectedParameters = '';
    this.selectedPercent = '';
    this.selectedRuleName = '';
    this.selectedFreq = '';
    this.selectedSummary = '';
    this.selectedCreatedBy = '';
    this.selectedCreatedDate = '';
    this.selectedUpdatedBy = '';
    this.selectedUpdatedDate = '';
    this.selectedUserList = [];
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        response.result?.forEach((element: any) => {
          if (element.location === selectedTerminal) {
            for (const i of element.locationMaterials) {
              if (i.material === selectedMaterial) {
                for (const j of i.locationMaterialRules) {
                    this.rulesDropdown.push(j.ruleName);
                }
              }
            }
          }

        })
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getRuleDetails(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    this.selectedTerminal = selectedTerminal
    this.selectedMaterial = selectedMaterial
    this.selectedRuleName = selectedRuleName
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchUpdate(response, selectedTerminal, selectedMaterial, selectedRuleName)
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getTerminalsSearchUpdate(response: any, selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchUpdate(i, selectedMaterial, selectedRuleName)
        }
      }

    })
  }

  getMaterialsSearchUpdate(i: any, selectedMaterial: string, selectedRuleName: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        this.getRulesSearchUpdate(j, selectedRuleName)
      }
    }
  }

  getRulesSearchUpdate(j: any, selectedRuleName: string) {
    if (j.ruleName === selectedRuleName) {

      this.selectedRuleType = j.ruleType;
      this.selectedCreatedBy = j.createdBy;
      this.selectedCreatedDate = j.createdDate;
      this.selectedUpdatedBy = j.updatedBy;
      this.selectedUpdatedDate = j.updatedDate;
      this.materialNumber = j.materialNumber;
      this.materialGroup = j.materialGroupNumber;
      this.udmNickname = j.udmNickname;
      this.subscriptionList = [{ userEmail: ' ', isEmail: false, isTeams: false, isWorkspace: false, isActive: false }];
      for (let i of j.subscription.users) {
        this.subscriptionList.push(i);
        if (i.isActive === true && !this.selectedUserList.includes(i.userEmail)) {
          this.selectedUserList.push(i.userEmail)
        }
      }
      this.subscriptionList.shift()
      if (this.selectedRuleType === 'Inventory') {
        this.selectedReference = j.daysToValidate.type
        this.selectedNoOfDays = j.daysToValidate.NoOfdays;
        this.selectedParameters = j.parameter;
        this.selectedRules = j.ruleCondition;
        this.selectedFreq = j.alertFrequency.charAt(0)
        this.selectedFreqFull = j.alertFrequency.toLowerCase()
        this.selectedSummary = j.ruleDescription
        this.selectedId = j.ruleId
      } else if (this.selectedRuleType === 'Reconciliation') {
        this.selectedPercent = j.thersold
        this.selectedFreq = j.alertFrequency.charAt(0)
        this.selectedFreqFull = j.alertFrequency.toLowerCase()
        this.selectedSummary = j.ruleDescription
        this.selectedId = j.ruleId
      }
    }
  }

  closePopup() {
    this.alertsService.changeLocation('');
    this.alertsService.changeMaterial('');
    this.alertsService.changeRuleName('');
    this.closeModalPopup.emit(false);
  }

  updateAlert() {
    this.subscriptions = [{ userEmail: ' ', isEmail: false, isTeams: false, isWorkspace: false, isActive: false }];
    for (const element of this.subscriptionList) {
      if (element.userEmail !== this.authService.cvxClaimsPrincipal.email) {
        this.subscriptions.push(element)
      } else if (element.userEmail === this.authService.cvxClaimsPrincipal.email) {
        this.newActive = {
          userEmail: element.userEmail,
          isEmail: element.isEmail,
          isTeams: element.isTeams,
          isWorkspace: element.isWorkspace,
          isActive: false
        }
        this.subscriptions.push(this.newActive) 
      }
    }
    if (this.selectedRuleType === 'Inventory') {
      this.subscriptions.shift()
      this.updateRuleToggleInventory = {
        ruleId: this.selectedId,
        ruleName: this.selectedRuleName,
        ruleDescription: this.selectedSummary,
        location: this.selectedTerminal,
        material: this.selectedMaterial,
        materialNumber: this.materialNumber,
        materialGroupNumber: this.materialGroup,
        desk: this.alertsService.getDesk(),
        deskId: localStorage.getItem("deskId")!,
        deskNickName: this.alertsService.getDeskNickname(),
        deskRegion: this.alertsService.getDeskRegion(),
        deskTag: this.alertsService.getDeskTag(),        
        udmNickname: this.udmNickname,
        ruleType: this.selectedRuleType,
        daysToValidate: {
          type: this.selectedReference,
          NoOfdays: parseInt(this.selectedNoOfDays),
        },
        ruleCondition: this.selectedRules,
        parameter: this.selectedParameters,
        alertFrequency: this.selectedFreqFull,
          subscription: {
            users: this.subscriptions
          },
          createdBy: this.selectedCreatedBy,
          createdDate: this.selectedCreatedDate,
          updatedBy: this.selectedUpdatedBy,
          updatedDate: this.selectedUpdatedDate,
          isActive: true
        }

      this.alertsService.updateAlert(this.updateRuleToggleInventory).subscribe({
        next: () => {
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.closePopup();
          this.alertsComponent.getAlertRulesData()
          this.homeComponent.expandedMode$.next(false);
          this.homeComponent.collpaseMode$.next(true);
        },
      })

    } else if (this.selectedRuleType === 'Reconciliation') {
      this.subscriptions.shift()
      this.updateRuleToggleReconciliation = {
        ruleId: this.selectedId,
        ruleName: this.selectedRuleName,
        ruleDescription: this.selectedSummary,
        location: this.selectedTerminal,
        material: this.selectedMaterial,
        materialNumber: this.materialNumber,
        udmNickname: this.udmNickname,
        ruleType: this.selectedRuleType,
        alertFrequency: this.selectedFreqFull,
        thersold: parseInt(this.selectedPercent),
        subscription: {
          users: this.subscriptions
        },
        createdBy: this.selectedCreatedBy,
        createdDate: this.selectedCreatedDate,
        updatedBy: this.selectedUpdatedBy,
        updatedDate: this.selectedUpdatedDate,
        isActive: true
        };

      this.alertsService.updateAlert(this.updateRuleToggleReconciliation).subscribe({
        next: () => {
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.closePopup();
          this.alertsComponent.getAlertRulesData()
          this.homeComponent.expandedMode$.next(false);
          this.homeComponent.collpaseMode$.next(true);
        },
      })

    }
  }

}
