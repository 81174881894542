import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationState } from '../shared/interface/workspace-alert.interface';
import { AlertRuleResponse } from '../shared/interface/settings.interface';


@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public restrictLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanelOpenCondition$: BehaviorSubject<NotificationState> = new BehaviorSubject<NotificationState>(NotificationState.UserOpen);
  public sidePanelData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private updateLocation = new BehaviorSubject('')
  private updateMaterial = new BehaviorSubject('')
  private updateRuleName = new BehaviorSubject('')
  private updateEnableEdit = new BehaviorSubject<boolean>(true);
  private createAlertPopup = new BehaviorSubject<boolean>(false);

  currentLocation = this.updateLocation.asObservable()
  currentMaterial = this.updateMaterial.asObservable()
  currentRuleName = this.updateRuleName.asObservable()
  currentEditStatus = this.updateEnableEdit.asObservable()

  constructor(private apiService: ApiService) { }
  
  getTankParams({payload}: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  getTankParamsNonDeconstructed(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }


  updateTankParams({payload}: any): Observable<any> {
    return this.apiService
      .put(
        `${environment.mdmAPI}/UpdateTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createAlert(alert: any) {

    return this.apiService
      .post(
        `${environment.alertAPI}/CreateRule`,
        'alert', alert
      )
      .pipe(
        map((response: any) => {
          return response
        })
      )
  };

  getWorkspaceAlert(payload: any): Observable<any>{
    
    return this.apiService
      .get(
        `${environment.alertAPI}/GetWorkspaceAlert/?fromdate=${payload.date}&user=${payload.user}`,
        'alert',
      ).pipe(
        map((data) => {
          return data;
        })
      )
  }


  dismissWorkspaceAlert(payload: any): Observable<any>{

    return this.apiService
      .put(
        `${environment.alertAPI}/DimissWorkspaceAlert/?id=${payload.id}&user=${payload.user}&isActive=${payload.isActive}`,
        'alert',
    ).pipe(
      map((response) => {
        return response;
      })
    );

  }

  getAlertRules(): Observable<any> {
    return this.apiService
      .get(
        `${environment.alertAPI}/GetRules`,
        'alert',
      )
      .pipe(
        map((data) => {
          return data;
        })
      )
  };

  updateAlert(alert: any) {
    return this.apiService
    .put(
      `${environment.alertAPI}/UpdateRule`,
      'alert', alert
    )
    .pipe(
      map((response: any) => {
        return response
      })
    )
  };

  deleteAlert(alert: any) {
    return this.apiService
    .trueDelete(
      `${environment.alertAPI}/deleteRule?id=${alert.ruleId}&type=${alert.ruleType}&isActive=${alert.isActive}`,'alert'
    )
    .pipe(
      map((response: any) => {
        return response
      })
    )
  };

  changeLocation(location: string) {
    this.updateLocation.next(location)
  };

  changeMaterial(material: string) {
    this.updateMaterial.next(material)
  }

  changeRuleName(ruleName: string) {
    this.updateRuleName.next(ruleName)
  }

  changeEditStatus(data: any){
    this.updateEnableEdit.next(data);
  }

  openSidePanel(openFrom: NotificationState, alertsToFocusOn: AlertRuleResponse[], openSidePanel: boolean){

    /*If we are using a non-trivial open strategy, 
    we will assign the alerts the side panel should provide interest in */
    if(openFrom === NotificationState.TR){
      this.sidePanelData$.next(alertsToFocusOn);  
    }

    //Let the side panel know what opened it for conditional setup, and if we need to adjust it  
    this.sidePanelOpenCondition$.next(openFrom);

    if(this.sidePanel$.value === false){
      this.sidePanel$.next(openSidePanel);
    }
  }

  getDeskStringSplit(){
    /* There are 3 variations so far: 
    *  1. Single style (ex: panama)
    *  2. Double Style (ex: ethanol + usec)
    *  3. Triple Style (ex: clean products + usec + florida desk)
    */
    return localStorage.getItem("desk")!.split(" + ");
  }

  getDeskTag(){
    //in each case, the first word has been the first instance of the string options
    if(this.getDeskStringSplit()[0].toLowerCase().includes('panama')){
      return "latam";
    }else if(this.getDeskStringSplit()[0].toLowerCase().includes('clean products')){
      return "pipeline";
    }
    return this.getDeskStringSplit()[0];
  }
  
  //NICKNAME IS USED TO MAKE API CALL FOR THE PIPELINE DATA THAT BACKEND NEEDS. AS SUCH, IT IS THE pl + DESK_PARAM NAME
  getDeskNickname(){
    let compareValue = this.getDeskStringSplit();
    if(compareValue.includes("clean products")){
      return "pl"+ localStorage.getItem("deskParam");
    }else if(compareValue.includes("ethanol")){
      return compareValue[1];
    }else if(compareValue.includes("panama")){
      return compareValue[0];
    }
    return null;       
  }

  //REGION IS USWC OR USEC, WILL MOSTLY APPLY TO PIPELINE, BUT INCLUDE FOR ETHANOL BECUASE WHY NOT TO BE HONEST
  getDeskRegion(){
    if(localStorage.getItem("desk")!.toLowerCase().includes("clean products") || localStorage.getItem("desk")!.includes("ethanol")){
      if(localStorage.getItem("desk")!.includes("usec")){
        return "usec"
      }else{
        return "uswc"
      }
    }else if(localStorage.getItem("desk")!.includes("panama")){
      return "panama";
    }
    return null;
  }
  getDesk(){
    let splitList = this.getDeskStringSplit();
    if(splitList.length === 1){
      return splitList[0]; 
    }else if(splitList.length === 2){
      return splitList[1];
    }else{
      return splitList[2].replace(' desk', "");
    }
  }

  setIsCreateAlertsPopupOpen(data: any) {
    this.createAlertPopup.next(data);
  }
  getIsCreateAlertsPopupOpen(): Observable<any> {
    return this.createAlertPopup.asObservable();
  }

}
