import { selectDesk, selectEthanolTRFiltersResponse } from './../../../shared/store/selector/app.selector';
import { saveEthanolTRFiltersResponse } from './../../../shared/store/action/app.action';
import { Utilities } from './../../../shared/utilities/utilities';
import { takeUntil, Subject } from 'rxjs';
import { getUserDetails } from 'src/app/shared/store/selector/app.selector';
import { AppState } from './../../../shared/store/reducer/app.reducer';
import { formatDate } from "@angular/common";
import { Component, ChangeDetectorRef, EventEmitter, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { NominationService } from "src/app/services/nomination.service";
import { ToastService } from "src/app/services/toast.service";
import { triggerTRSave } from "src/app/shared/store/action/app.action";
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
@Component({
    selector: 'edit-nomination',
    templateUrl: './edit-nomination.component.html',
    styleUrls: ['./edit-nomination.component.scss']
})
export class EditNominationComponent {
    public editDetailsForm!: FormGroup;
    destroy$: Subject<boolean> = new Subject<boolean>();
    @Output() closeModalPopup = new EventEmitter();
    @Output() refreshGrid = new EventEmitter();
    nominationData!: any;
    editDetails!: any;
    nomValues!: any;
    customer!: string;
    counterPart!: string;
    nominationStatus : any;
    heelsStatus: any;
    reserveStatus: any;
    safetyStatus: any;
    userEmail:any;
    userDetails$ = this.appState.pipe(select(getUserDetails));
    selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
    getEhtanolFilterResponse: any = []
    selectedDesk$ = this.store.pipe(select(selectDesk));
    selectedDeskParams: any;

    constructor(private fb: FormBuilder, private nominationService: NominationService, private changeDetection: ChangeDetectorRef, private toaterService: ToastService, private store: Store, private appState: Store<AppState>, private utilities: Utilities, private loaderService: LoaderService, private myMonitoringService: MyMonitoringService) {
        if (this.nomValues?.screenName === "ethanoltr") {
            this.editDetailsForm = this.fb.group({
                nominationVolume: ['', [Validators.required, Validators.maxLength(20)]],
                destinationDate: ['', [Validators.required]],
            });
        } else {
            this.editDetailsForm = this.fb.group({
                nominationVolume: ['', [Validators.required, Validators.maxLength(20)]],
                nominationId: [''],
                reserveVolume: ['', [Validators.maxLength(20)]],
                destinationDate: ['', [Validators.required]],
                heelsVolume: ['', [Validators.maxLength(20)]],
                reserveId: [''],
                heelsId: [''],
                safetyVolume: [''],
                safetyId: ['']
            });
        }
        this.userDetails$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    if (response.userDetails.isUser) {
                        this.userEmail = this.utilities.getUserName(response.userDetails.email);
                    }
                },
                error: (err: any) => { },
                complete: () => { },
            });
        this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
            next: (response: any) => {
                if (response.isfilterUpdated) {
                    this.getEhtanolFilterResponse = response
                }
            }, error: (err: any) => {
                console.log(err);
            }, complete: () => { }

        })

        this.selectedDesk$
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              this.selectedDeskParams = response.selectedDeskParam;
            },
            error: (err: any) => {
              console.log(err);
            },
            complete: () => { },
          });

    }

    closePopup() {
        this.editDetailsForm.reset({
            nominationVolume: '',
            reserveVolume: '',
            heelsVolume: '',
            safetyVolume: '',
            safetyId: '',
            heelsId: '',
            reserveId: '',
            nominationId: '',
            destinationDate: ''
        });
        this.changeDetection.detectChanges();
        this.closeModalPopup.emit(false);
    }

    get detailTypeRequest() {
        this.changeDetection.detectChanges();
        return this.editDetailsForm.get('detailTypeRequest') as FormArray;
    }

    presetNominationData() {
        this.changeDetection.detectChanges();
        if (this.nomValues?.screenName === 'ethanoltr') {
            this.editDetailsForm.get('nominationVolume')?.setValue(this.nomValues?.scheduledQty);
            const formattedDate = formatDate(this.editDetails.data[`date`], 'yyyy-MM-dd', 'en-US');
            this.editDetailsForm.get('destinationDate')?.setValue(formattedDate);
        } else {
            this.customer = this.editDetails?.colDef?.field?.split('.')[0];
            this.counterPart = this.customer.split('_')[1];
            this.nominationStatus = this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].status;
            this.heelsStatus = this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].status;
            this.reserveStatus = this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].status;
            this.safetyStatus = this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].status;
            this.editDetailsForm.get('nominationVolume')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty);
            this.editDetailsForm.get('nominationId')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].itemId);
            this.editDetailsForm.get('reserveVolume')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].reserveQty);
            this.editDetailsForm.get('heelsVolume')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].heelsQty);
            this.editDetailsForm.get('reserveId')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].itemId);
            this.editDetailsForm.get('heelsId')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].itemId);
            const formattedDate = formatDate(this.editDetails.data[`date`], 'yyyy-MM-dd', 'en-US');
            this.editDetailsForm.get('destinationDate')?.setValue(formattedDate);
            this.editDetailsForm.get('safetyVolume')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].safetyQty);
            this.editDetailsForm.get('safetyId')?.setValue(this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].itemId);
        }
    }

    onFormSubmit() { //NOSONAR lightweight logging
        let requestBody: any[] = [];
        const intialDate = formatDate(this.editDetails.data[`date`], 'yyyy-MM-dd', 'en-US');
        const destinationDate = intialDate === this.editDetailsForm.get('destinationDate')?.value ? undefined : (this.editDetailsForm.get('destinationDate')?.value).replaceAll('-', ''); //NOSONAR lightweight logging
        this.nominationData = this.editDetailsForm.value;
        const nomination = this.editDetailsForm.get('nominationVolume')?.value ? {
            itemId: this.editDetailsForm.get('nominationId')?.value,
            scheduledDate: destinationDate,
            scheduledQty: this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty === this.editDetailsForm.get('nominationVolume')?.value ? undefined : this.editDetailsForm.get('nominationVolume')?.value / 1000,
            udmItemStatus: this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].status?.toLowerCase() === 'published' ? 'Unpublished' : this.editDetails.data[`nomination_${this.counterPart}_nominationDetails`].status
        } : null;
        const reserve = this.editDetailsForm.get('reserveVolume')?.value ? {
            itemId: this.editDetailsForm.get('reserveId')?.value,
            scheduledDate: destinationDate,
            scheduledQty: this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].reserveQty  === this.editDetailsForm.get('reserveVolume')?.value ? undefined: this.editDetailsForm.get('reserveVolume')?.value / 1000,
            udmItemStatus: this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].status?.toLowerCase() === 'published' ? 'Unpublished' : this.editDetails.data[`nomination_${this.counterPart}_reserveDetails`].status
        } : null;
        const heels = this.editDetailsForm.get('heelsVolume')?.value ? {
            itemId: this.editDetailsForm.get('heelsId')?.value,
            scheduledDate: destinationDate,
            scheduledQty: this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].heelsQty === this.editDetailsForm.get('heelsVolume')?.value ? undefined : this.editDetailsForm.get('heelsVolume')?.value / 1000,
            udmItemStatus: this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].status?.toLowerCase() === 'published' ? 'Unpublished' : this.editDetails.data[`nomination_${this.counterPart}_heelsDetails`].status
        } : null;
        const safety = this.editDetailsForm.get('safetyVolume')?.value ? {
            itemId: this.editDetailsForm.get('safetyId')?.value,
            scheduledDate: destinationDate,
            scheduledQty: this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].safetyQty === this.editDetailsForm.get('safetyVolume')?.value ? undefined : this.editDetailsForm.get('safetyVolume')?.value / 1000,
            udmItemStatus: this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].status?.toLowerCase() === 'published' ? 'Unpublished' : this.editDetails.data[`nomination_${this.counterPart}_safetyDetails`].status
        } : null;
        this.payloadFormation(nomination, reserve, heels, safety, requestBody);
    }

    payloadFormation(nomination: any, reserve: any, heels: any, safety: any, requestBody: any) {
        if (nomination && (nomination?.scheduledQty || nomination?.scheduledDate)) {
            requestBody.push(nomination);
        }
        if (reserve && (reserve?.scheduledQty || reserve?.scheduledDate)) {
            requestBody.push(reserve);
        }
        if (heels && (heels?.scheduledQty || heels?.scheduledDate)) {
            requestBody.push(heels);
        }
        if (safety && (safety?.scheduledQty || safety?.scheduledDate)) {
            requestBody.push(safety);
        }
        this.updateNominationFromTR(requestBody);
    }

    payloadFormationEthanolEdit(action: any) {
        let IsMultiTerminal = this.nomValues?.IsMultiTerminal
        let heirchyData = this.editDetails.colDef.field.split('_');
        let carrier = heirchyData[IsMultiTerminal ? 5 : 4];
        let textLineCarrier = carrier?.toLowerCase() === 'misc' ? null : carrier;
        let supplierVal = heirchyData[IsMultiTerminal ? 4 : 3];
        let textLineSupplier = supplierVal?.toLowerCase() === 'misc' ? null : supplierVal;
        let isRepublishClicked = action === 'republish';
        let udmDailyBreakdownQty = [
            {
                scheduledDate: (this.editDetailsForm.get('destinationDate')?.value)?.replaceAll('-', ''),
                scheduledQty: +(this.editDetailsForm.get('nominationVolume')?.value),
            }
        ]
        let EditedRowObj = [{
            id: this.nomValues?.id,
            modeOfTransport: this.nomValues?.modeOfTransport,
            modeOfTransportDesc: this.nomValues?.modeOfTransportDesc,
            udmModeOfTransportDesc: this.nomValues?.modeOfTransportDesc,
            deskFilter: this.selectedDeskParams,
            sys_status: isRepublishClicked ? 'inprocess' : this.nomValues?.sys_status,
            headerToItemNav: [
                {
                    itemId: this.nomValues?.itemId,
                    udmItemStatus: isRepublishClicked ? 'inprocess' : this.nomValues?.rowItemStatus,
                    scheduledDate: (this.editDetailsForm.get('destinationDate')?.value)?.replaceAll('-', ''),
                    scheduledQty: +(this.editDetailsForm.get('nominationVolume')?.value),
                    textLine: `{Author:${this.nomValues?.createdBy},MdfBy:${this.userEmail},SS:UDM}`,
                    shipperText: this.nomValues?.modeOfTransportDesc?.toLowerCase() === 'truck' ? `{Type:null,Carrier:${this.utilities.padSupplierCarrier(textLineCarrier)},Supplier:${this.utilities.padSupplierCarrier(textLineSupplier)}}` : undefined,
                    udmDailyBreakdownQty: this.nomValues?.modeOfTransportDesc?.toLowerCase() === 'truck' ? udmDailyBreakdownQty : undefined
                }
            ]
        }];
        
        this.nominationService.updateNomination(EditedRowObj).subscribe({
            next: (response: any) => {
                if (response.success) {
                    this.toaterService.setToastNotification({
                        show: true,
                        type: 'success',
                        msg: "Nomination has been updated"
                    });
                }
                this.closeModalPopup.emit(response);
                this.loaderService.setLoading(true);
                setTimeout(() => {
                    this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: true, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure }));
                }, 12000)
            }
        })
    }

    getReferenceDocumentIndicator(IsMultiTerminal: any) {
        return IsMultiTerminal ? "K" : "X"
    }

    updateNominationFromTR(requestBody: any) {
        const finalPayload = [{
            id : this.editDetails?.data[`nomination_${this.counterPart}_nominationDetails`].id, // as id will be same for all detail types we took from nomination
            headerToItemNav: requestBody,
            sys_status: requestBody.some((item: any) => item?.udmItemStatus?.toLowerCase() === 'draft') ? 'draft' : 'Unpublished'
        }]
        this.myMonitoringService.logEvent('Update Nomination from Replenishment Planning screen', {category:"Replenishment Planning"})
        this.nominationService.updateNominationFromTRScreen(finalPayload).subscribe((response: any) => {
            if (response.statusCode === 200) {
                this.toaterService.setToastNotification({
                    show: true,
                    type: 'success',
                    msg: "Nomination has been updated"
                })
                this.changeDetection.detectChanges();
                this.closeModalPopup.emit(false);
                this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true }));
            }
        });
    }

    onKeyPress(event: KeyboardEvent) {
        const keyCode = event.key;
        if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyCode))) {
            event.preventDefault();
        }
    }

}
