<label class="label">Port/Terminal</label>

<div *ngIf="!hasData">
    
     There are no changes to display.
    
</div>

<div class="d-flex flex-row justify-content-left overflowScroll" *ngIf="hasData">

    <p *ngFor="let header of headerNavigation; let i = index" (click)="updateSelection(i)"
        class="m-0 mt-1 mr-1 ml-1 header-size"
        [ngClass]="this.selectedTabIndex == i ? 'tab-button-selected': 'tab-button'">
     {{header.displayname}}
    </p>
</div>
<hr class="seletion-line m-0 mb-2">
<div *ngIf="this.activityTrackerFormedData != null">
    <div *ngFor="let activityTrackerData of getPortDeterminalData()">
        <hide-list class="margin-bottom mt-2" *ngFor="let selection of activityTrackerData.activityTrackerData" [hideList]="false">
            <gray-header header [displayText]="selection.displayName" [arrowVisible]="true" [flipArrow]="false">   </gray-header>
            <div content>
                <div class="blueRectangle mt-1">
                    <p class="m-0 ml-3 optionName">{{selection.optionalName}}</p>
                </div>
                <div class="totalWidth mt-3 d-flex flex-row">
                    <expand-card *ngFor="let card of selection.groupTypechanges" [hasContent]="card.changes.length > 0"
                        class="m-1">
                        <at-card-front [cardInfo]="card" front></at-card-front>
                        <activity-table [tableTitle]="card.displayname" [rowData]="card.changes" back></activity-table>
                    </expand-card>
                </div>
            </div>
        </hide-list>
    </div>
</div>