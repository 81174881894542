<div class="header active" (click)="toggleAddButton()" *ngIf="addButtonSelectable">
    + <span class="underline">add comment</span>
</div>
<div class="header disabled" *ngIf="!addButtonSelectable">
    + <span class="underline">add comment</span>
</div>

<div *ngIf="!addButtonSelectable" class="mb-4">
    <comment-card [isInputActive]="true" [createNewComment]="true" [commentPublished]="false"
        [screenInformation]="screenInformation" (commentCreated)="resetAdd($event)">
    </comment-card>
</div>