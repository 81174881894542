<app-np-loader *ngIf="showSpinner"></app-np-loader>
<div class="main-container d-flex flex-row justify-content-center">
    <div class="activity-container d-flex flex-column">
        <div class="d-flex align-items-center">
            <h1 class="title-text"><span *ngIf="isMultipleView">multi-nomination </span>activity tracker - {{this.selectedNomination.nominationNo}}</h1>
        </div>
        <div *ngIf="isMultipleView" class="d-flex flex-column mb-2">
            <label class="m-0 label-2">View Nomination</label>
            <select class="form-control wide-dropdown" [(ngModel)]="selectedNomination" (change)="updateViewActivityNomination()">
                <option [ngValue]="option" *ngFor="let option of nominationDataSet; let i = index">
                    <span *ngIf="option.nominationKey != null && option.nominationKeyItem != null">{{option.nominationKey}}-{{option.nominationKeyItem}}</span>
                    <span *ngIf="option.nominationKey != null && option.nominationKeyItem == null">{{option.nominationKey}}- missing key item value</span>
                    <span *ngIf="option.nominationKey == null && option.nominationKeyItem == null"> Line {{i}} (Missing nom key and key item)</span>
                </option>
            </select>
        </div>
        <div class="d-flex">
            <button *ngFor="let header of tooltipHeader; let i = index" (click)="updateDropdown(header, i)" 
            [ngClass]="this.selectedHeaderIndex == i ? 'header-button-selected': 'header-button'"> 
                {{header}}</button>
        </div>
        <hr class="m-0 gray-color mb-3">    

        <div class="dropdown mb-1">
            <select class="form-control" [(ngModel)]="selectedOption" (change)="updateRowData()">
                <option [ngValue]="option" *ngFor="let option of this.selectedDropdown">{{option.displayName}}</option>
            </select>
        </div>
        <gray-header [displayText]="selectedOption.displayName + ' Changes'" [arrowVisible]="false"></gray-header>
        <activity-table [selectedOption]="selectedOption" [rowData]="rowData"></activity-table>
        <div *ngIf="true">
            <hr>
            <p class="title-text">Comments</p>
            <p>Coming soon!</p>
        </div>
    </div>
</div>