import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NominationService } from './nomination.service';
@Injectable({
  providedIn: 'root',
})
export class ReplenishmentPlanningService {
  public applyCustomView$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public applyColOrder$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public rpColOrderAndExpansion: any = {};
  public rpSetDefaultCustomViewFilterDetails: any = {}
  constructor(private apiService: ApiService, private nominationService: NominationService) { }


  getTerminalReplenishmentData(postData: any): Observable<any[]> {
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
    return this.apiService
      .post(
        `${environment.replenishmentPlanningAPI}/GetReplenishmentPanamaData`,
        'replenishmentPlanning',
        postData
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  saveTerminalNomination(nominationBody: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.nominationAPI}/AddNominations`,
        'nomination',
        nominationBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveTerminalReplenishmentData(updatedTRBody: any[]): Observable<any> {
    return this.apiService
      .post(
        `${environment.replenishmentPlanningAPI}/SaveReplenishmentPanamaData`,
        'replenishmentPlanning',
        updatedTRBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getRefData(type: any): Observable<any> {
    let url = `${environment.replenishmentPlanningAPI}/GetRefData?&type=${type}`;
    return this.apiService
      .get(
        url,
        'replenishmentPlanning',
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getPipelineFilterData(type: any): Observable<any> {
    let url = `${environment.replenishmentPlanningAPI}/GetPipelineFilterData?type=pl${type}`;
    return this.apiService
      .get(
        url,
        'replenishmentPlanning',
      )
      .pipe(
        map((data) => {
      
          return data;
        })
      );
  }
 
  
  getPipelineFilterTypeData(type: any, transportSystem: any): Observable<any> {
    let url = `${environment.replenishmentPlanningAPI}/GetPipelineFilterData?type=pl${type}&transportSystem=${transportSystem}`;
    return this.apiService
      .get(
        url,
        'replenishmentPlanning',
      )
      .pipe(
        map((data) => {
      
          return data;
        })
      );
  }

  getPanamaTankInformation(): Observable<any> {
    return this.apiService
      .get(
        `${environment.replenishmentPlanningAPI}/GetTankDetails`,
        'replenishmentPlanning',
      )
      .pipe(shareReplay());
  }

  getContractBalancingSmartFilter(payload:any): Observable<any> {
    return this.apiService
      .post(`${environment.contractBalancingAPI}/GetContractBalancingSmartFilter`, 'contractBalancing', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  applyView(requestBody: any) {
    return this.apiService.get(`${environment.stateManagementApi}/GetViewByName?emailId=${requestBody.emailId}&viewName=${encodeURIComponent(requestBody.viewName)}&desk=${requestBody.desk}`, 'stateManagement', requestBody).pipe(map((response) => { 
      this.applyCustomView$.next(null);
      return response 
    }));
  }
}
