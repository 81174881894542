<div class="create-ethanol-nomination">
 

  <form class="branded" [formGroup]="createTerminalNominationForm">
    <span class="label">mode of transportation</span>
    <div for="mode-of-trans" class="form-row">
      <div *ngFor="let mot of motOptions; let i = index;"
        class="radio-container mb-0 d-flex align-items-center form-group branded">
        <input type="radio" name="mot" id="radio-{{i}}" [value]="mot?.name" tabindex="0" [checked]="mot?.checked === true"
          (change)="motUpdateFormFields(mot?.name)">
        <label  class="label" id="radio-{{i}}" (keydown.enter)="motUpdateFormFields(mot?.name)" tabindex="0">{{mot?.name}}</label>
      </div>
    </div>
    <div class="input-wrap">
    <div class="form-row pb-1 pr-2 d-flex">
      <div class="form-group col-md-9">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="nominationNo" class="label"> <span class="astric">* </span> nomination name/id</label>
            <input type="text" class="form-control" formControlName="nominationNo" placeholder="Type here">
            <div class="astric"
              *ngIf="createTerminalNominationForm.controls['nominationNo'].invalid && (createTerminalNominationForm.controls['nominationNo'].dirty || createTerminalNominationForm.controls['nominationNo'].touched)">
              Nomination no. limits to 16 characters</div>
          </div>

          <div class="form-group col-md-4">
            <label for="transport-sys" class="label"> <span class="astric">* </span> transport system </label>
            <select class="form-control" (change) = 'onTransportSystemChange()' formControlName="transportSystem" id="transport-sys">
              <option value="">select</option>
              <option *ngFor="let transportSystem of transportSystemDropdown" [value]="transportSystem?.value">
                {{ transportSystem?.name }}</option>
            </select>
          </div>
          
          <div class="form-group col-md-4" *ngIf="modeOfTransport === 'truck'">
            <label for="transport-sys" class="label"> <span class="astric">* </span> truck time frame options </label>
            <select class="form-control" (change)="updateTruckTimeFrame()"  formControlName="udmTimeFrameOption" id="transport-sys">
              <option value="">select</option>
              <option *ngFor="let timeFrame of this.modalPopUpRefData?.TimeFrameOption" [value]="timeFrame">
                {{ timeFrame }}</option>
            </select>
            </div>


          <div class="form-group col-md-4">
            <label for="supplier" class="label"> supplier </label>
            <app-select-dropdown tabindex="0" #SupplierSelectDropdownComponent [items]="supplierDropdown" formControlName="supplier"
              id="supplier" [defaultValue]="supplierReferenceData">
            </app-select-dropdown>
          </div>

          <div class="form-group col-md-4">
            <label for="carrier" class="label"> carrier </label>
            <app-select-dropdown tabindex="0" #CarrierSelectDropdownComponent [items]="carrierDropdown" formControlName="carrier"
              id="carrier" [defaultValue]="carrierReferenceData">
            </app-select-dropdown>
          </div>
          <div class="form-group col-md-4">
            <label for="carrier" class="label"> customer </label>
            <app-select-dropdown #CustomerSelectDropdownComponent [items]="customerDropdown" formControlName="customer"
              id="customer" [defaultValue]="customerReferenceData" (afterChange)="onValueChange($event)">
            </app-select-dropdown>
            <div class="mt-2">
              <span class="text-danger" *ngIf="showCustomerWarning">Note: customer information is only  applicable for sale description </span>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="modeOfTransport === 'barge'">
            <label for="barge-name" class="label">  barge name
            </label>
            <app-select-dropdown #BargeNameSelectDropdownComponent [items]="bargeSelectDropdown"
              [defaultValue]="bargeNameReferenceData" formControlName="bargeName" id="barge">
            </app-select-dropdown>
          </div>
          <div class="form-group col-md-4">
            <label for="btCustodyChain" class="label">
                bt custody chain
                </label>
            <input type="text" formControlName="btCustodyChain" class="form-control"
                id="btCustodyChain">
          </div>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label htmlFor ="nominationNotes" class="label">nomination notes</label>
            <textarea tabindex="0" class="form-control nomNotes" formControlName="nominationNotes" maxlength="120" rows="3"></textarea>
    </div>
      </div>
      <div class="form-row col-md-12 pb-1 pr-2 align-items-center">
        <div class="form-group col-md-3 ">
            <input type="checkbox" #isAdditional [(ngModel)] = 'addCheckbox' (keydown.enter)="toggleAddi()" (change)="onAddititonalData(isAdditional)"
                formControlName="selectMultipleCheckbox1">
            <label class="ml-2 " for="selectMultipleCheckbox1">additional information</label>
        </div>
        </div>
      </div>

    <ng-container formArrayName="transport">
      <ng-container *ngFor="let transportControl of transportArray().controls; let i= index" [formGroupName]="i">
        <div class="table-wrapper">
          <div>
            <div *ngIf="i===0">
              <span class="label table-type" *ngIf="transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw'">origin</span>
              <table aria-describedby="origin">
                <tr>
                  <th>Confirm</th>
                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> description</th>
                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> rdi</th>
                  <th><span class="text-danger"></span> TSW Status</th>

                  <th *ngIf="!showTruckMonthly"><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled date</th>
                  <th *ngIf="showTruckMonthly"><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled month</th>
                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> location</th>

                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled Product
                  </th>
                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> demand product
                  </th>

                  <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> qty(bbl)</th>
                  <th>sap contract</th>
                  <th>contract line no.</th>
                  <th *ngIf="isAdditionalData">batch origin location</th>
                  <th *ngIf="isAdditionalData">valuation type origin location</th>
                  <th *ngIf="isAdditionalData">batch destination location</th>
                  <th *ngIf="isAdditionalData">valuation type destination location</th>

                </tr>
                <ng-container formArrayName="origin">
                  <ng-container *ngFor="let list of origin(i).controls; let j = index" [formGroupName]="j">

                    <tr>
                      <td><input type="checkbox" class="form-control text-field cell-form-checkbox"
                        formControlName="confirmationIndicator"></td>
                      <td>
                        <div class="statwidth">
                          <select class="form-control" formControlName="nominationType"
                            (change)="nominationTypeDesc(i,j)">
                            <option value="">select</option>
                            <option *ngFor="let nominationTypeDetails of originNominationTypeDropdown"
                              [ngValue]="nominationTypeDetails?.name">
                              {{ nominationTypeDetails?.name }}</option>
                          </select>
                        </div>
                      </td>
                      <td class="statwidth">
                        <select class="form-control rdi-select" formControlName="rdi" (change)="nominationTypeRdi(i,j)">
                          <option value="">select</option>
                          <option *ngFor="let nominationTypeDetails of originNominationTypeDropdown"
                            [ngValue]="nominationTypeDetails?.referenceDocumentIndicator">
                            {{ nominationTypeDetails?.referenceDocumentIndicator }}</option>
                        </select>
                      </td>

                      <td><input  type="number" class="form-control text-field"
                        formControlName="tswStatus" readonly>
                </td>  
                     
                      <td class="statwidth" *ngIf="!showTruckMonthly"><input type="date" class="form-control text-field date-field"
                          formControlName="scheduledDate"></td>
                          
                      <td *ngIf="showTruckMonthly" class="statwidth"><input type="month" class="form-control text-field date-field"
                        formControlName="scheduledDate"></td>

                      <td class="statwidth">
                        <app-select-dropdown class="location-dropdown"  #OriginSelectDropdownComponent [items]="destinationLocationDropdown"
                          [defaultValue]="locationOriginArray[i][j]" formControlName="originLocation" id="origin-loc"
                          (afterChange)="onLocationValueChange($event)">
                        </app-select-dropdown>
                      </td>

                      <td class="statwidth">
                        <select class="form-control" formControlName="scheduledMaterial">
                          <option value="">select</option>
                          <option *ngFor="let productDetails of productsDropdown" [ngValue]="productDetails?.value">
                            {{ productDetails?.name }}</option>
                        </select>
                      </td>

                      <td class="statwidth">

                        <select class="form-control" formControlName="demandMaterial">
                          <option value="">select</option>
                          <option *ngFor="let productDetails of productsDropdown" [ngValue]="productDetails?.value">
                            {{ productDetails?.name }}</option>
                        </select>
                      </td>

                
                      <td class="statwidth"><input type="text" [class.readonly] ='disbaleQTY' class="form-control text-field"
                          formControlName="scheduledQty"></td>
                      <td><input type="text" class="form-control text-field" formControlName="sapcontract"></td>
                      <td><input type="text" class="form-control text-field" formControlName="contractLineNo" (input)="onContractLineChange($event,j,i,'origin');"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="batchOriginLocation"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="valuationTypeOriginLoc"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="batchDestinationLocation"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="valuationTypeDestinationLoc"></td>
                      
                      
                          <td *ngIf="modeOfTransport !== 'truck'">
                            <div class="custom-dropdown" tabindex="0">
                                <button class="icon-btn action-icon action-button"></button>
                                <div class="dropdown-menu-wrap">
                                    <div class="dropdown-menu">
                                        <button class="dropdown-item action-btn-item"
                                            (click)="copyOriginRow(i,j)">duplicate row
                                        </button>
                                        <button *ngIf="isMultipleRowItemOrigin[i]?.length>1"
                                            class="dropdown-item action-btn-item text-danger"
                                            (click)="deleteOriginRow(i,j)">delete
                                        </button>
                                        <button class="dropdown-item action-btn-item text-act-btn"
                                                (click)="clearOriginRow(i,j)">clear all fields

                                       </button>

                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
              <div class="my-3 d-flex">
                <button class="button button-text" [class.disabled] = 'disableAddLocation' (click)="addOriginRow(i)">
                  add location
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="hideDestination">
            <div *ngIf="transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw'">
              <span class="label table-type">destination</span>
              <table aria-describedby="destination">
                <tr>
                  <th>Confirm</th>
                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> description</th>
                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> rdi</th>
                  <th><span class="text-danger"></span> TSW Status</th>

                  <th  *ngIf="!showTruckMonthly "><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled date</th>
                  <th  *ngIf="showTruckMonthly"><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled month</th>

                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> location
                  </th>
                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled
                    product</th>

                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> demand
                    product</th>


                  <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> qty(bbl)
                  </th>
                  <th>sap contract</th>
                  <th>contract line no.</th>
                  <th *ngIf="isAdditionalData">batch origin location</th>
                  <th *ngIf="isAdditionalData">valuation type origin location</th>
                  <th *ngIf="isAdditionalData">batch destination location</th>
                  <th *ngIf="isAdditionalData">valuation type destination location</th>
                </tr>
                <ng-container *ngIf="transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw'" formArrayName="destination">
                  <ng-container *ngFor="let list of destination(i).controls; let k = index" [formGroupName]="k">
                    <tr>
                      <td><input type="checkbox" class="form-control text-field cell-form-checkbox"
                        formControlName="confirmationIndicator"></td>
                      <td>
                        <div class="statwidth">
                          <select class="form-control" formControlName="nominationTypeDesti"
                            (change)="nominationTypeDestDesc(i,k)">
                            <option value="">select</option>
                            <option *ngFor="let nominationTypeDetails of destNominationTypeDropdown"
                              [ngValue]="nominationTypeDetails?.name">
                              {{ nominationTypeDetails?.name }}</option>
                          </select>
                        </div>

                      </td>
                      <td class="statwidth">
                        <select class="form-control rdi-select" formControlName="nominationTypeRdiDesti"
                          (change)="nominationTypeRdiDest(i,k)">
                          <option value="">select</option>
                          <option *ngFor="let nominationTypeDetails of destNominationTypeDropdown"
                            [ngValue]="nominationTypeDetails?.referenceDocumentIndicator">
                            {{ nominationTypeDetails?.referenceDocumentIndicator }}</option>
                        </select>

                      </td>
                      <td><input  type="number" class="form-control text-field"
                        formControlName="tswStatus" readonly>

                    </td>
                      <td *ngIf="!showTruckMonthly"><input class="form-control text-field date-field" formControlName="scheduledDate" type="date">
                      </td>
                      <td *ngIf="showTruckMonthly"><input class="form-control text-field date-field" formControlName="scheduledDate" type="month">
                      </td>
                      <td class="statwidth">
                        <app-select-dropdown class="location-dropdown" #OriginSelectDropdownComponent [items]="destinationLocationDropdown"
                          [defaultValue]="locationDestiArray[i][k]" formControlName="originLocationDesti" id="origin-loc"
                          (afterChange)="onLocationValueChange($event)">
                        </app-select-dropdown>
                      </td>
                      <td class="statwidth">
                        <select class="form-control" formControlName="scheduledMaterial" id="sheduleMate">
                          <option value="">select</option>
                          <option *ngFor="let productDetails of productsDropdown" [ngValue]="productDetails?.value">
                            {{ productDetails?.name }}</option>
                        </select>
                      </td>

                      <td class="statwidth">
                        <select class="form-control" formControlName="demandMaterial" id="demandMate">
                          <option value="">select</option>
                          <option *ngFor="let productDetails of productsDropdown" [ngValue]="productDetails?.value">
                            {{ productDetails?.name }}</option>
                        </select>
                      </td>
        
                      <td><input class="form-control text-field" [class.readonly] ='disbaleQTY' formControlName="scheduledQty"
                          type="text"></td>
                    
                      <td><input class="form-control text-field" type="text" formControlName="sapcontract"></td>
                      <td><input type="text" class="form-control text-field" formControlName="contractLineNo" (input)="onContractLineChange($event,k,i,'destination');"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="batchOriginLocation"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="valuationTypeOriginLoc"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="batchDestinationLocation"></td>
                      <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                          formControlName="valuationTypeDestinationLoc"></td>
    
                          <td *ngIf="modeOfTransport !== 'truck'">
                            <div class="custom-dropdown" tabindex="0">
                              <button class="icon-btn action-icon action-button"></button>
                              <div class="dropdown-menu-wrap">
                                  <div class="dropdown-menu">
                                      <button class="dropdown-item action-btn-item"
                                          (click)="copyDestinationRow(i,k)">duplicate row
                                      </button>
                                      <button *ngIf="isMultipleRowItemDestination[i]?.length>1"
                                          class="dropdown-item action-btn-item text-danger"
                                          (click)="deletedestinationRow(i,k)">delete
                                      </button>
                                      <button class="dropdown-item action-btn-item text-act-btn"
                                              (click)="clearDestinationRow(i,k)">clear all fields
                                      </button>
                                  </div>
                              </div>
                          </div>
                          </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
              <div class="my-3">
                <button class="button button-text" [class.disabled]='disableAddLocation' (click)="addDestinationRow(i)">
                  add location
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-danger mb-5" *ngIf="errorMessage[i] && isOriginRequired[i] && isDestinationRequired[i]">
          <span>
            Origin Total Volume = {{originQtyValue[i]}}; Destination Total Volume = {{destinQtyValue[i]}}
          </span>
        </div>


      </ng-container>
    </ng-container>
    <div class="form-row pb-1 pr-2">
    </div>

    <div class="form-row pb-1 form-button-container">
      <div class="form-group">
        <button class="button" (click)="closePopup()" (keydown.enter)="closePopup()" tabindex="0">Cancel</button>
      </div>
      <div class="form-group">
        <button class="button create-btn" [disabled]="!createTerminalNominationForm.valid" (keydown.enter)="submitForm('publish')"  tabindex="0" (click)="submitForm('publish')"> <span *ngIf="editingNomination">update</span> <span *ngIf="!editingNomination">Create </span>  <span> + Publish</span> </button>
      </div>
    </div>
  </form>
</div>
