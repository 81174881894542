import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/admin-material-mapping.reducer';
import * as MaterialMapping from '../reducer/admin-material-mapping.reducer';

export const selectMaterialMappingData = createFeatureSelector<State>('adminMaterialMapping');
export const selectDesksData = createFeatureSelector<State>('adminMaterialMapping');

export const getMaterialMappingData = createSelector(
  selectMaterialMappingData,
  MaterialMapping.getMateriMappingData
);

export const getMaterialMappingFilter = createSelector(
  selectMaterialMappingData,
  MaterialMapping.getMaterialMappingFilter
);

export const getDesksData = createSelector(
  selectDesksData,
  MaterialMapping.getDesksData
);