<section class="product-view-component-section">
  <div class="content">
    <div class="text-right">
      <button class="btn add-movement-btn" (click)="addNewRow()"> add movement + </button>
    </div>
    <ag-grid-angular class="ag-theme-alpine ag-grid-table" [rowData]="rowData" [columnDefs]="colDefs"
      [defaultColDef]="defaultColDef">
    </ag-grid-angular>
  </div>
</section>
