<header>
  <div class="udm-header">
    <div class="udm-header__desk" [style.--desksVisible]="desksVisible">
      <span class="title">desk</span>
      <custom-dropdown [optionList]="list" (change)="getDeskName($event)"></custom-dropdown>
    </div>
    <div class="udm-header__logo">
      <a id="hallmark" href="/" title = "Main Navigation Button"></a>
      <div class="app-name"><a href="/">{{siteName}}</a></div>
    </div>
    <div class="udm-header__settings">
      <div class="user-details-container" #userDetailsSection>
        <div>
          <a class="notif-icon" *ngIf="!this.alertsNotificationService.showBell.value"  (click)="toggleCreateAlertNotificationPanel()" title="Alert Notification Bell"></a>
          <a class="notif-icon-active" *ngIf="this.alertsNotificationService.showBell.value"  (click)="toggleCreateAlertNotificationPanel()" title="Alert Notification Bell New Alert"></a>
          <a class="notif-name"  (click)="toggleCreateAlertNotificationPanel()">notifications</a>
        </div>
        <div class="initals-avatar">
          <p class="initals-avatar">{{userAvatar}}</p>
        </div>
        <div class="user-details">
          <p class="details-heading">welcome,</p>
          <div class="dropdown-container">
            <p class="user-name-text" (click)="toggleDropdown()">
              {{userName}} <span
                *ngIf="(selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec' || (selectedDesk.startsWith('clean products') && !nominationService.isCargoScreen.value) || selectedDesk === 'panama')"
                [class]="dropdownIcon"></span>
            </p>

            <div class="dropdown-list-container"
              *ngIf="isDropdownOpen && (selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec' || (selectedDesk.startsWith('clean products') && !nominationService.isCargoScreen.value) || selectedDesk === 'panama')">
              <div *ngFor="let item of dropdownItems" class="dropdown-box">
                <span [class]="item.icon" class="dropdownItems-icon"></span>
                <p *ngIf="item.name !== 'admin' && item.name !== 'alerts & notifications'" class="drop-down-item-text" (click)="onClickCustomViews($event)">{{ item.name }}</p>
                <p *ngIf="item.name === 'alerts & notifications'" class="drop-down-item-text"><a class="drop-down-item-text" href="/settings/alerts">{{ item.name }}</a></p>
                <p *ngIf="item.name === 'admin'" class="drop-down-item-text"><a class="drop-down-item-text" href="/admin/desks">{{ item.name }}</a></p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>