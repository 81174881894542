import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { HomeComponent } from '../../home/home.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertLocationData } from 'src/app/shared/interface/settings.interface';
import { CalAngularService } from '@cvx/cal-angular';
import { AlertsNotificationsComponent } from '../alerts-notifications/alerts-notifications.component';

@Component({
  selector: 'app-alert-actions',
  templateUrl: './alert-actions.component.html',
  styleUrls: ['./alert-actions.component.scss']
})
export class AlertActionsComponent implements ICellRendererAngularComp {

  updateLocation:string='';
  updateMaterial:string='';
  updateRuleName:string='';
  allowUpdate:boolean = true;
  getActiveFlag!:boolean;

  rulesGridData: AlertLocationData[] = [];
  selectedDeskOption: string = '';
  currentUser!: string;
  currentUserEmail!: string;
  selectedCreatedBy = '';
  selectedCreatedDate = '';
  selectedUpdatedBy = '';
  selectedUpdatedDate = '';
  selectedUserList: string[] = [];

  constructor(private authService: CalAngularService, private homeComponent: HomeComponent, private alertsService: AlertsService, private alertsComponent: AlertsNotificationsComponent) { }

  public params!: ICellRendererParams

  agInit(params: ICellRendererParams): void {
    this.currentUser = this.authService.cvxClaimsPrincipal.name;
    this.currentUserEmail = this.authService.cvxClaimsPrincipal.email;
    this.params = params
    this.alertsService.currentLocation.subscribe((location: string) => this.updateLocation = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.updateMaterial = material)
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.updateRuleName = ruleName)
    this.alertsService.currentEditStatus.subscribe((enableEdit: boolean) => this.allowUpdate = enableEdit)
    this.getActiveFlag = params.data.isActive
  }

  updateAlert() {
    this.alertsService.changeLocation(this.params.data.location);
    this.alertsService.changeMaterial(this.params.data.material);
    this.alertsService.changeRuleName(this.params.data.ruleName);
    this.alertsService.changeEditStatus(this.allowUpdate);

    this.selectedUserList = [];
    for(let user of this.params.data.subscription.users){
      if ((user.isActive === true) || (user.isActive === false && this.params.data.createdBy === this.authService.cvxClaimsPrincipal.name)) {
        this.selectedUserList.push(user.userEmail)
      }
    }
    if(this.selectedUserList.length === 0 
      || (this.selectedUserList.length === 1 && this.selectedUserList[0] === this.authService.cvxClaimsPrincipal.userName) 
      || (this.params.data.createdBy === this.authService.cvxClaimsPrincipal.name)){
      
      this.alertsComponent.toggleCreateAlertModal();
    }
    else{
      this.alertsComponent.toggleDenyUpdateAlertModal();
    }
  }

  deleteAlert(): void {
    this.homeComponent.toggleDeleteAlertModal();
    this.alertsService.changeLocation(this.params.data.location);
    this.alertsService.changeMaterial(this.params.data.material);
    this.alertsService.changeRuleName(this.params.data.ruleName);
  }

  subscriberList(): void {
    this.homeComponent.toggleSubscriberAlertModal();
    this.alertsService.changeLocation(this.params.data.location);
    this.alertsService.changeMaterial(this.params.data.material);
    this.alertsService.changeRuleName(this.params.data.ruleName);
  }

  restoreAlert(): void {
    this.homeComponent.toggleRestoreDeletedModal();
    this.alertsService.changeLocation(this.params.data.location);
    this.alertsService.changeMaterial(this.params.data.material);
    this.alertsService.changeRuleName(this.params.data.ruleName);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

}
