import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, shareReplay, takeUntil } from 'rxjs';
import { invokeCBFilterDataApi, updateCBFilterData } from 'src/app/contract-balancing/store/action/contract-balancing.action';
import { selectContractFilterResponse } from 'src/app/contract-balancing/store/selector/contract-balancing.selector';
import { ContractBalancingService } from 'src/app/services/contract-balancing.service';
import { selectCBHeaderCheckboxState, selectDesk } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-contract-balancing-filter',
  templateUrl: './contract-balancing-filter.component.html',
  styleUrls: ['./contract-balancing-filter.component.scss']
})
export class ContractBalancingFilterComponent implements OnInit {
  searchText: any = [];
  updateSearchData: any = [];
  selectContractFilterResponse$ = this.store.pipe(select(selectContractFilterResponse));
  selectedCBHeaderCheckboxState$ = this.store.pipe(select(selectCBHeaderCheckboxState));
  destroy$: Subject<boolean> = new Subject<boolean>();
  filterResponse: any;
  dateRangeData: { fromDate: any, toDate: any };
  defaultFromDate!: string;
  defaultToDate!: string;
  searchValue: any = [];
  cbHeaderCheckboxes: any[] = [];
  cbTableHeaderMappingWithFlags: any = {
    "contract details table": "refreshContractDetailsTable",
    "mtd tracking": "refreshMTDTrackingTable",
    "monthly performance review": "refreshMPRTable",
    "deal performance review": "refreshDPRTable",
  };
  desk$ = this.store.pipe(select(selectDesk));
  selectedDesk: any;
  selectedDeskId: number = 0;
  filterApiInvoked: boolean = false;
  constructor(private store: Store, private datePipe: DatePipe,
    private utilities: Utilities,
    private contractBalancingService: ContractBalancingService) {
    const { fromDate, toDate } = this.getDefaultDates();
    this.dateRangeData = { fromDate, toDate };
  }

  ngOnInit(): void {
    this.selectContractFilterResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.filterResponse = response.cbFilterResponse;
        this.filterApiInvoked = false;
        this.updateSearchData = JSON.parse(JSON.stringify(response.cbFilterResponse));
        if (response.cbFilterResponse.fromDate !== '') {
          this.dateRangeData = { fromDate: response.cbFilterResponse.fromDate, toDate: response.cbFilterResponse.toDate };
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedCBHeaderCheckboxState$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.cbHeaderCheckboxes = response.cbHeaderCheckboxes;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.filterApiInvoked = false;
        this.selectedDesk = this.utilities.cbDesk(response);
        this.selectedDeskId = +response.selectedDeskId;
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.contractBalancingService.updatedDesk$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if(!(this.filterApiInvoked)){
        this.fetchCBFilterApi();
      }
    });
  }


  fetchCBFilterApi() {
    const enableRefreshTableFlagArr: any = [];
    this.cbHeaderCheckboxes.forEach((item:any) => {
      let key = this.cbTableHeaderMappingWithFlags[item.name];
      let value = item.checked;
      let enableRefreshTableFlag:any={};
      enableRefreshTableFlag[key] = value;
      enableRefreshTableFlagArr.push(enableRefreshTableFlag);
    })
    if(this.selectedDeskId > 0)
    {
        this.filterApiInvoked = true;
        this.store.dispatch(invokeCBFilterDataApi({ dateRange: this.dateRangeData, enableRefreshTableFlagArr, desk: this.selectedDesk, deskId: this.selectedDeskId }));
    }
  }

  updateFilterList(event: any, filterName: any, index: number) {
    const searchObj = { value: event?.toLowerCase(), filterName };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any, i: any) => {
      if (text.value.length) {
        if (text.filterName === filterName) {
          let updatedFilterList = [...this.filterResponse[filterName].filter((ele: any) => ele.name?.toLowerCase().includes(text.value?.toLowerCase()))];
          this.updateSearchData[filterName] = JSON.parse(JSON.stringify(updatedFilterList));
        }
      } else {
        this.updateSearchData[filterName] = JSON.parse(JSON.stringify(this.filterResponse[filterName]));
      }
    })
  }

  filterData(event: any, filterName: any, index: number) {
    const value = event?.target?.value?.toLowerCase();
    const isChecked = event?.target?.checked;
    if (value === 'all') {
      this.updateSearchData[filterName].forEach((value: any) => {
        value.checked = isChecked
      });
    } else {
      this.updateSearchData[filterName][index].checked = isChecked;
    }

    const originalFilterResponse = JSON.parse(JSON.stringify(this.filterResponse));
    // To update original filterResponse obj with the modified or filtered filterResponse obj
    originalFilterResponse[filterName].forEach((obj: any) => {
      this.updateSearchData[filterName].forEach((filteredObj: any) => {
        if (obj.name === filteredObj.name) {
          obj.checked = filteredObj.checked;
        }
      })
    });
    // To clear search input in filter panel
    this.searchValue = [];
    // To uncheck all checkbox based on rest of the checkboxes 
    let isCheckedTrue = originalFilterResponse[filterName].filter((value: any) => value.name !== 'all').every((element: any) => element.checked === true);
    let getValueAll = originalFilterResponse[filterName].findIndex((element: any) => element.name?.toLowerCase() === 'all');
    if (getValueAll !== -1) {
      originalFilterResponse[filterName][getValueAll].checked = isCheckedTrue;
    }

    this.updateNgrxFilterData(originalFilterResponse);
  }

  dateRangegroup(event: any) {
    let getudatedDateRangeFlag = event.valid;
    let getFromDateFromEvent = event.controls.start.value;
    let getToDateFromEvent = event.controls.end.value;
    getFromDateFromEvent = this.datePipe.transform(new Date(getFromDateFromEvent),
      'yyyy-MM-dd'
    );
    getToDateFromEvent = this.datePipe.transform(new Date(getToDateFromEvent),
      'yyyy-MM-dd'
    );
    if (getudatedDateRangeFlag) {
      this.updateSearchData["fromDate"] = getFromDateFromEvent;
      this.updateSearchData["toDate"] = getToDateFromEvent;
      const dateObj = { fromDate: getFromDateFromEvent, toDate: getToDateFromEvent };
      this.updateAndInvokeFilterDataApi(this.updateSearchData, dateObj);
    }
  }

  updateAndInvokeFilterDataApi(filterRes: any, dateObj: any) {
    const checkedTableHeaders = this.cbHeaderCheckboxes.filter((obj: any) => obj.checked);
    const refreshTableFlagArr: any = [];
    const enableRefreshTableFlagArr: any = [];
    checkedTableHeaders.forEach((value: any) => {
      const flagName: any = this.cbTableHeaderMappingWithFlags[value.name];
      const disableRefreshTableFlag = { [flagName]: false };
      const enableRefreshTableFlag = { [flagName]: true };
      refreshTableFlagArr.push(disableRefreshTableFlag);
      enableRefreshTableFlagArr.push(enableRefreshTableFlag);
    });
    const updateFilterObj = { cbFilterResponse: JSON.parse(JSON.stringify(filterRes)), refreshTableFlagArr };
    this.store.dispatch(updateCBFilterData(updateFilterObj));

    const dateFilters = { fromDate: dateObj.fromDate, toDate: dateObj.toDate };
    if(this.selectedDeskId > 0)
      this.store.dispatch(invokeCBFilterDataApi({ dateRange: dateFilters, enableRefreshTableFlagArr, desk: this.selectedDesk, deskId: this.selectedDeskId }));
  }

  updateNgrxFilterData(filterRes: any) {
    const checkedTableHeaders = this.cbHeaderCheckboxes.filter((obj: any) => obj.checked);
    const refreshTableFlagArr: any = [];
    checkedTableHeaders.forEach((value: any) => {
      const flagName: any = this.cbTableHeaderMappingWithFlags[value.name];
      const refreshTableFlag = { [flagName]: true };
      refreshTableFlagArr.push(refreshTableFlag);
    });
    const updateFilterObj = { cbFilterResponse: JSON.parse(JSON.stringify(filterRes)), refreshTableFlagArr };
    this.store.dispatch(updateCBFilterData(updateFilterObj));
  }

  getDefaultDates = () => {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth() - 2;
    const endDate = new Date(), endDateY = endDate.getFullYear(), endDateM = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(endDateY, endDateM + 1, 0);

    this.defaultFromDate = this.datePipe.transform(firstDay,
      'yyyy-MM-dd'
    )!;
    this.defaultToDate = this.datePipe.transform(lastDay,
      'yyyy-MM-dd'
    )!;
    console.log(this.defaultFromDate)
    console.log(this.defaultToDate)
    const fromDate = this.defaultFromDate;
    const toDate = this.defaultToDate;
    return { fromDate, toDate };
  };

  cbClearFilter() {
    const allCBFilterDataCopy = JSON.parse(JSON.stringify(this.filterResponse));
    for (let i in allCBFilterDataCopy) {
      if (allCBFilterDataCopy.hasOwnProperty(i) && (i !== "fromDate" && i !== "toDate")) {
        allCBFilterDataCopy[i].forEach((value: any) => {
          value.checked = true;
        });
      }
    }
    const { fromDate, toDate } = this.getDefaultDates();
    allCBFilterDataCopy["fromDate"] = fromDate;
    allCBFilterDataCopy["toDate"] = toDate;
    this.dateRangeData = { fromDate, toDate };
    this.searchText = [];
    // To clear search input in filter panel
    this.searchValue = [];
    const dateObj = { fromDate, toDate };
    this.updateAndInvokeFilterDataApi(allCBFilterDataCopy, dateObj);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
