import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-nom-tooltip',
  templateUrl: './nom-tooltip.component.html',
  styleUrls: ['./nom-tooltip.component.scss']
})
export class NomTooltipComponent implements ICellRendererAngularComp {

// Init Cell Value
public value!: string;
agInit(params: ICellRendererParams): void {
    this.refresh(params);
}

// Return Cell Value
refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
}

}