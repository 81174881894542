
import {
  Component
} from '@angular/core';
import { Subject } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { searchNomActionResponse } from 'src/app/shared/store/action/app.action';
import { NominationService } from 'src/app/services/nomination.service';
import { selectPipelineNomFiltersResponse } from 'src/app/shared/store/selector/app.selector';


@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent {
  searchDropdown!: string;
  searchTextValue!: string;
  defaultFromDate: any;
  dateRangeData: { fromDate: any; toDate: any; };
  pipelineDateRangeData: { fromDate: any; toDate: any; };
  router: any;
  isSearchOpened!: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchList: any = ['Nomination no','Nomination key','SAP contract']
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));

  isApplySearchCalled: boolean = false;

  constructor(
    private store: Store,private nominationService: NominationService
  ) {
      this.dateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultFromDate }
    this.pipelineDateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultFromDate };

  }
  
  searchClearFilter(event: any) {
    event.stopPropagation();
    this.searchTextValue = ''
  }

  onValueChange(evt: any){
    this.searchDropdown = evt
  }
  
  applySearchFilterforSearch(event: any){
     event.stopPropagation();

    if (!this.isApplySearchCalled) {
      // apply filter api call
      let searchValue: any = {
        'Nomination no': 'nominationNo',
        'Nomination key': 'nominationKey', 'SAP contract': 'headerToItemNav.nominationReferenceDocument'
      }
      let payload = {
        no: this.searchTextValue.toLocaleUpperCase(),
        dropdown: searchValue[this.searchDropdown]
      }
      this.isApplySearchCalled = true;

      this.store.dispatch(searchNomActionResponse({ searchPayload: payload, isPipelineNomUpdated: true }));
      this.nominationService.getTerminalNominationAFAPI$.next(false);
      this.nominationService.getSearchNominationAFAPI$.next(true);
      this.isApplySearchCalled = false;

    }


  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
