import { Action, createReducer, on } from "@ngrx/store";
import { CBContractDetailsResponse, CBDPRResponse, CBFilterResponse, CBMPRResponse, CBMTDResponse } from "src/app/shared/interface/contract-balancing.interface";
import { getCBContractDetailsDataApiSuccess, getCBDPRDataApiSuccess, getCBFilterDataApiSuccess, getCBMPRDataApiSuccess, getCBMTDTrackingDataApiSuccess, updateCBFilterData, updateRefreshTableFlag } from "../action/contract-balancing.action";

export interface State {
    cbFilterResponse: CBFilterResponse;
    cbContractDetailsData: CBContractDetailsResponse[];
    cbMTDData: CBMTDResponse[];
    cbMPRData: CBMPRResponse[];
    cbDPRData: CBDPRResponse[];
    refreshContractDetailsTable: boolean;
    refreshMTDTrackingTable: boolean;
    refreshMPRTable: boolean;
    refreshDPRTable: boolean;
}

export const initialState: State = {
    cbFilterResponse: {
        locations: [],
        suppliers: [],
        contractNumbers: [],
        sraDealNumbers: [],
        months: [],
        fromDate: '',
        toDate: ''
    },
    cbContractDetailsData: [],
    cbMTDData: [],
    cbMPRData: [],
    cbDPRData: [],
    refreshContractDetailsTable: false,
    refreshMTDTrackingTable: false,
    refreshMPRTable: false,
    refreshDPRTable: false,
};

const contractBalancingReducer = createReducer(
    initialState,
    on(getCBFilterDataApiSuccess, (state, { allCBFilterData, dateRange, enableRefreshTableFlagArr }: any) => {
        let allCBFilterDataCopy = JSON.parse(JSON.stringify(allCBFilterData));
        const cbFilterData = Object.keys(allCBFilterDataCopy);
        if (!cbFilterData.includes("error")) {
            for (let i in allCBFilterDataCopy) {
                console.log("allCBFilterDataCopy[i] " + i);
                // To restrict adding the option all for filters other than location
                // if (i.toLowerCase() === 'locations') {
                //     if (allCBFilterDataCopy[i].length) {
                //         let modifiedObj = allCBFilterDataCopy[i].map((value: any) => ({ name: value, checked: true }));
                //         modifiedObj.unshift({ name: 'all', checked: true });
                //         allCBFilterDataCopy[i] = modifiedObj;
                //     }
                // } else {
                //     if (allCBFilterDataCopy[i].length) {
                //         let modifiedObj = allCBFilterDataCopy[i].map((value: any, i: any) => {
                //             let checkedValue = false;
                //             if (i <= 9) {
                //                 checkedValue = true;
                //             }
                //             return { name: value, checked: checkedValue };
                //         });
                //         //modifiedObj.unshift({ name: 'all', checked: true });
                //         allCBFilterDataCopy[i] = modifiedObj;
                //     }
                // }
                if (allCBFilterDataCopy[i].length) {
                    let modifiedObj = allCBFilterDataCopy[i].map((value: any) => ({ name: value, checked: true }));
                    modifiedObj.unshift({ name: 'all', checked: true });
                    allCBFilterDataCopy[i] = modifiedObj;
                }
            }
        } else {
            allCBFilterDataCopy = {};
        }

        allCBFilterDataCopy["fromDate"] = dateRange.fromDate;
        allCBFilterDataCopy["toDate"] = dateRange.toDate;
        const totalRefreshTableFlagObj = enableRefreshTableFlagArr.reduce((acc: any, curr: any) => ({
            ...acc,
            ...curr
        }), {});
        return {
            ...state,
            cbFilterResponse: allCBFilterDataCopy,
            ...totalRefreshTableFlagObj
        }
    }),
    on(updateCBFilterData, (state, { cbFilterResponse, refreshTableFlagArr }) => {
        const totalRefreshTableFlagObj = refreshTableFlagArr.reduce((acc, curr) => ({
            ...acc,
            ...curr
        }), {});
        return {
            ...state,
            cbFilterResponse,
            ...totalRefreshTableFlagObj
        }
    }),
    on(getCBContractDetailsDataApiSuccess, (state, { cbContractDetailsData, refreshContractDetailsTable }: any) => {
        return {
            ...state,
            cbContractDetailsData,
            refreshMTDTrackingTable: false,
            refreshMPRTable: false,
            refreshDPRTable: false,
            refreshContractDetailsTable
        }
    }),
    on(getCBMTDTrackingDataApiSuccess, (state, { cbMTDData, refreshMTDTrackingTable }: any) => {
        return {
            ...state,
            cbMTDData,
            refreshContractDetailsTable: false,
            refreshMPRTable: false,
            refreshDPRTable: false,
            refreshMTDTrackingTable
        }
    }),
    on(getCBMPRDataApiSuccess, (state, { cbMPRData, refreshMPRTable }: any) => {
        return {
            ...state,
            cbMPRData,
            refreshContractDetailsTable: false,
            refreshMTDTrackingTable: false,
            refreshDPRTable: false,
            refreshMPRTable
        }
    }),
    on(getCBDPRDataApiSuccess, (state, { cbDPRData, refreshDPRTable }: any) => {
        return {
            ...state,
            cbDPRData,
            refreshContractDetailsTable: false,
            refreshMTDTrackingTable: false,
            refreshMPRTable: false,
            refreshDPRTable
        }
    }),
    on(updateRefreshTableFlag, (state, { refreshTableFlag }) => ({
        ...state,
        ...refreshTableFlag
    })),

);

export function reducer(state: State | undefined, action: Action): any {
    return contractBalancingReducer(state, action);
}

export const getCBFilterResponse = (state: State) => ({ cbFilterResponse: state.cbFilterResponse, refreshContractDetailsTable: state.refreshContractDetailsTable, refreshMTDTrackingTable: state.refreshMTDTrackingTable, refreshMPRTable: state.refreshMPRTable, refreshDPRTable: state.refreshDPRTable });

export const getCBContractDetailsResponse = (state: State) => ({ cbContractDetailsData: state.cbContractDetailsData, refreshContractDetailsTable: state.refreshContractDetailsTable });

export const getCBMTDTrackingResponse = (state: State) => ({ cbMTDData: state.cbMTDData, refreshContractDetailsTable: state.refreshContractDetailsTable });

export const getCBMPRResponse = (state: State) => ({ cbMPRData: state.cbMPRData, refreshContractDetailsTable: state.refreshContractDetailsTable });

export const getCBDPRResponse = (state: State) => ({ cbDPRData: state.cbDPRData, refreshContractDetailsTable: state.refreshContractDetailsTable });


