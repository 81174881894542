import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducer/terminal-replenishment.reducer';
import * as TR from '../reducer/terminal-replenishment.reducer';

export const selectTRData = createFeatureSelector<State>('terminalreplenishment');

export const selectTRView = createSelector(
    selectTRData,
    TR.getSelectedTRView
);
export const selectCustomerDetails = createSelector(
    selectTRData,
    TR.getCustomerDetails
);