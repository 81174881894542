<div class="push-modal">
    <div class="d-flex justify-content-start" *ngIf="headerIcon.length || header.length">
        <div [class]="headerIcon" *ngIf="modalPopupObjective === 'pushMcat'"></div>
        <div [class]="headerLinkIcon" *ngIf="modalPopupObjective === 'linkingNomination'"></div>
        <div class="header">{{header}}</div>
    </div>
    <div>&nbsp;</div>
    <div *ngIf="modalPopupObjective !== 'pushMcat'">&nbsp;</div>
    <div class="description">{{description}}</div>
    <div *ngIf="modalPopupObjective !== 'pushMcat'">&nbsp;</div>
    <!-- <div *ngIf="message">&nbsp;</div> -->
    <div class="message" >{{message}}</div>
    <div>&nbsp;</div>
    <mat-checkbox *ngIf="modalPopupObjective === 'pushMcat'">mcat</mat-checkbox>
    <ng-content></ng-content>
</div>