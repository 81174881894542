import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractBalancingService {
  updatedDesk$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  constructor(private apiService: ApiService) { }

  getCBFilterData({ fromDate, toDate }: any, selectedDesk: any, selectedDeskID: number): Observable<any> {
    const encodeURITime = encodeURIComponent(` 00:00:00`);
    return this.apiService
      .get(`${environment.contractBalancingAPI}/GetContractBalancingFilter?fromDate=${fromDate}${encodeURITime}&toDate=${toDate} 23:59:59&desk=${selectedDesk}&deskId=${selectedDeskID}`, 'contractBalancing')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCBContractDetailsData({ payload }: any): Observable<any> {
    return this.apiService
      .post(`${environment.contractBalancingAPI}/GetContractDetailsAsync?`, 'contractBalancing', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCBMTDTrackingData({ payload }: any): Observable<any> {
    return this.apiService
      .post(`${environment.contractBalancingAPI}/GetMonthToDateTrackingAsync`, 'contractBalancing', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCBMPRData({ payload }: any): Observable<any> {
    return this.apiService
      .post(`${environment.contractBalancingAPI}/GetMonthlyPerformanceReviewAsync?`, 'contractBalancing', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getCBDPRData({ payload }: any): Observable<any> {
    return this.apiService
      .post(`${environment.contractBalancingAPI}/GetDealPerformanceReviewAsync?`, 'contractBalancing', payload)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}