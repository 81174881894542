import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MdmService } from 'src/app/services/mdm.service';
import { NominationService } from 'src/app/services/nomination.service';

@Component({
  selector: 'app-nomination-edit-delete-confirm-model',
  templateUrl: './nomination-edit-delete-confirm-model.component.html',
  styleUrls: ['./nomination-edit-delete-confirm-model.component.scss']
})
export class NominationEditDeleteConfirmModelComponent implements OnInit {

  @Output() closeNominationModalPopup = new EventEmitter<void>();
  @Output() confirmNominationModalPopup = new EventEmitter<void>();
  alertMsg:string='';

  constructor(private data: NominationService, private mdmService : MdmService) { 
  }

  ngOnInit(): void {
    this.data.currentPopupMsg.subscribe(msg=> {
      if(msg=="delete"){
        this.alertMsg = "Are you sure you want to delete this nomination?";
      }
      else{
        this.alertMsg = "Are you sure you want to save and publish your changes to this nomination?";
      }
    });    
  }

    closePopup(){
        this.closeNominationModalPopup.emit();
    }
    confirm(){
        this.mdmService.actionConfirm(true);
        this.closeNominationModalPopup.emit();
    }

}