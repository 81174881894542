<form>
  <div class="content__header">date range</div>
  <mat-form-field color="red" appearance="fill">
    <mat-date-range-input [rangePicker]="picker" [dateFilter]="
      !(this.dateRangegroup.get('start').value) && restrictStartDate ? currentDateFilter : noFilter"
      [formGroup]="dateRangegroup">
      <input matStartDate formControlName="start" (dateChange)="onStartdateRangePickerFunction($event)"
        placeholder="Start date" readonly>
      <input matEndDate formControlName="end" placeholder="End date" (dateChange)="dateRangePickerFunction()" readonly>
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker disabled="false" startView="month"></mat-date-range-picker>
  </mat-form-field>
</form>