import { Component } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SubscriptionUsers, UpdateRuleInventory, UpdateRuleReconciliation } from '../../../shared/interface/settings.interface';
import { AlertsNotificationsComponent } from '../../alerts-notifications/alerts-notifications.component';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-alert-toggle-all',
  templateUrl: './alert-toggle-all.component.html',
  styleUrls: ['./alert-toggle-all.component.scss']
})
export class AlertToggleAllComponent implements ICellRendererAngularComp {

  constructor(private authService: CalAngularService, private alertsComponent: AlertsNotificationsComponent, private alertSaveService: AlertSaveGuardService, private alertsService: AlertsService) { }

  public cellValue!: string;
  public isOpen = false;
  public rowIndex: any;
  public notifBool: boolean=false;
  public ruleId!: string;
  public updateRuleToggleInventory!: UpdateRuleInventory;
  public updateRuleToggleReconciliation!: UpdateRuleReconciliation;
  public subscriptions!: SubscriptionUsers[];
  public newActive!: { userEmail: string, isEmail: boolean, isTeams: boolean, isWorkspace: boolean, isActive: boolean };
  public params!: ICellRendererParams
  public ruleName!: string;
  public emailList: string[] = [];
  public terminalName!: string;
  public toggleValue!: boolean;
  public activeList: boolean[] = [];
  public starterEmailList: string[] = [];
  public nextEmailList: string[] = [];
  public numberRulesList: string[] = [];
  public hoverText: string = '';

  agInit(params: ICellRendererParams): void {
    this.params = params
    for (const element of params.data.locationMaterials) {
      for (const i of element.locationMaterialRules) {
        this.numberRulesList.push(i.ruleName)
        for (const j of i.subscription.users) {
          this.starterEmailList.push(j.userEmail)
          if (j.userEmail === this.authService.cvxClaimsPrincipal.email) {
            this.activeList.push(j.isActive)
          }
        }
      }
    }
    this.createNextEmailList()
    if (this.activeList.includes(false) || (this.nextEmailList.length < this.numberRulesList.length)) {
      this.toggleValue = false
      this.notifBool = this.toggleValue
    } else {
      this.toggleValue = true
      this.notifBool = this.toggleValue
    }
    this.updateText();

  }

  createNextEmailList() {
    for (let email of this.starterEmailList) {
      if (email === this.authService.cvxClaimsPrincipal.email) {
        this.nextEmailList.push(email)
      }
    }
  }

  onToggleClick(params: ICellRendererParams) {
    this.notifBool = !this.notifBool;
    this.updateText()

    this.alertSaveService.addContent(this.generateIDText());
    for (let element of params.data.locationMaterials) {
      this.searchLocationMaterialRules(element)
    }

      this.emailList = [];
  }

  searchLocationMaterialRules(element: any) {
    for (let i of element.locationMaterialRules) {
      this.emailList = [];
      this.searchSubscription(i)
    }
  }

  searchSubscription(i: any) {
    for (let j of i.subscription.users) {
      this.emailList.push(j.userEmail)
      this.checkEmails(j, i)
    }
    if (!this.emailList.includes(this.authService.cvxClaimsPrincipal.email)) {
            i.subscription.users.push({
              userEmail: this.authService.cvxClaimsPrincipal.email,
              isActive: this.toggleValue,
              isTeams: false,
              isWorkspace: false,
              isEmail: false,
            })
            for (let j of i.subscription.users) {
              if (j.userEmail === this.authService.cvxClaimsPrincipal.email) {
                j.isActive = this.toggleValue
                if (i.ruleType === 'Inventory' ) {
                  this.ruleTypeUpdateInventory(i);

                } else if (i.ruleType === 'Reconciliation') {
                  this.ruleTypeUpdateReconciliation(i);
                  }
              }
            }
      }
  }

  checkEmails(j: any, i:any) {
    if (j.userEmail === this.authService.cvxClaimsPrincipal.email) {
      j.isActive = !this.toggleValue
      if (i.ruleType === 'Inventory' ) {
        this.ruleTypeUpdateInventory(i);

      } else if (i.ruleType === 'Reconciliation') {
        this.ruleTypeUpdateReconciliation(i)
        }
    }
  }

  ruleTypeUpdateInventory(i: UpdateRuleInventory) {
    this.updateRuleToggleInventory = {
      ruleId: i.ruleId,
      ruleName: i.ruleName,
      ruleDescription: i.ruleDescription,
      location: i.location,
      material: i.material,
      materialNumber: i.materialNumber,
      materialGroupNumber: i.materialGroupNumber,
      desk: i.desk,
      deskId: i.deskId,
      deskNickName: i.deskNickName,
      deskRegion: i.deskRegion,
      deskTag: i.deskTag,      
      udmNickname: i.udmNickname,
      ruleType: i.ruleType,
      daysToValidate: {
        type: i.daysToValidate.type,
        NoOfdays: i.daysToValidate.NoOfdays,
      },
      ruleCondition: i.ruleCondition,
      parameter: i.parameter,
      alertFrequency: i.alertFrequency,
      subscription: {
        users: i.subscription.users
      },
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
      isActive: i.isActive
    }

  this.alertsComponent.updateNotifsInventoryPayload.push(this.updateRuleToggleInventory)
  }

  ruleTypeUpdateReconciliation(i: UpdateRuleReconciliation) {
    this.updateRuleToggleReconciliation = {
      ruleId: i.ruleId,
      ruleName: i.ruleName,
      ruleDescription: i.ruleDescription,
      location: i.location,
      material: i.material,
      materialNumber: i.materialNumber,
      udmNickname: i.udmNickname,
      ruleType: i.ruleType,
      alertFrequency: i.alertFrequency,
      thersold: i.thersold,
      subscription: {
        users: i.subscription.users
      },
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
      isActive: i.isActive
      };
    this.alertsComponent.updateNotifsReconciliationPayload.push(this.updateRuleToggleReconciliation)
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  generateIDText(){
    return "Toggle All: " + this.params.data.location;
  }

  updateText(){

    this.hoverText = this.notifBool ? "turn off to disable terminal alerts" : "turn on to enable terminal alerts";
  }
}
