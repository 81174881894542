import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-reconciliation-field',
  templateUrl: './reconciliation-field.component.html',
  styleUrls: ['./reconciliation-field.component.scss']
})
export class ReconciliationFieldComponent implements OnInit {

  @Input() selectedPercent!: string;
  @Output() public reconciliationData = new EventEmitter<{selectedPercent: string}>();
  public percentDropdown = ["15"];

  constructor() { }

  ngOnInit(): void {
  }

  shareReconciliationDetails(){
    this.reconciliationData.emit({selectedPercent: this.selectedPercent});
  }

}
