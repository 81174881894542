<div class="vessel-schedule-update">
    <span class="vessel-schedule-update_popupClose" (click)="closeModalPopUp()">X</span>
    <form [formGroup]="vesselScheduleUpdateForm" (keydown.enter)="$event.preventDefault()">
        <div class="input-wrap">
            <div class="form-row pb-1 d-flex align-items-center">
                <div class="form-group mr-4">
                    <label for="vesselName" class="label vessel-schedule-update_header">vessel</label>
                    <input type="text" formControlName="vesselName" [(ngModel)]="vesselName" class="form-control vessel-schedule-update_input" />
                </div>
                <div class="form-group">
                    <label for="nominationNo" class="label vessel-schedule-update_header">nomination no</label>
                    <input type="text" formControlName="nominationNo" [(ngModel)]="nominationNo" class="form-control vessel-schedule-update_input" />
                </div>
            </div>
            <div class="form-row pb-1 d-flex align-items-center">
                <div class="form-group mr-4">
                    <label for="oldLaycanDate" class="label vessel-schedule-update_header">old laycan date</label>
                    <input type="date" formControlName="oldLaycanDate" [(ngModel)]="oldLaycanEndDate" class="form-control vesselScheduleUpdateDate vesselScheduleUpdateDate_OldDate"/>
                </div>
                <div class="form-group">
                    <label for="newLaycanDate" class="label vessel-schedule-update_header">new laycan date</label>
                    <input type="date" formControlName="newLaycanDate" [min]="laycanStartDate" class="form-control vesselScheduleUpdateDate" required (change)="getUpadtedNewLaycanDate()"/>
                </div>
            </div>
            <div class="form-row pb-1 d-flex align-items-center">
                <p class="vessel-schedule-update_note"><strong>NOTE:</strong> Future cargoes for same vessel will shift by "{{updateDateDiff || "X"}}" amount of days​.</p>
            </div>
            <div class="form-row pb-1 d-flex justify-content-end">
                <button class="vessel-schedule-update_btn_cancel mr-3" (click)="closeModalPopUp()">cancel</button>
                <button class="vessel-schedule-update_btn_submit" (click)="onVesselUpdate()" [ngClass]="{'vessel-schedule-update_btn_submit_disable': !vesselScheduleUpdateForm.valid}">submit</button>
            </div>
        </div>
    </form>
</div>