<div class="d-flex">
  <div class="more-menu" *ngIf="showUi">
<div class="d-flex">
  <div class="more-menu" *ngIf="showUi">
    <div class="more-menu-dropdown">
      <button class="ellipses-icon" (click)="moreMenu()"></button>
      <div *ngIf="!isOpen" class="dropdown-content"
        [ngClass]="{'place-top': rowIndex > 8, 'displayHover' : isOpen, 'place-bottom': rowIndex < 8 }">
        <a class="dropdown-item edit-nomination" (click)="editNomination()">edit cargo</a>
        <a class="dropdown-item edit-nomination" (click)="openActivityTracker()">see change activity</a>
        <a class="dropdown-item edit-nomination" (click)="openComments()">cargo plan comments</a>
      </div>
    </div>
  </div>
</div>