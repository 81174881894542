import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "../reducer/nominations.reducer";

export const selectNominationData = createFeatureSelector<State>("nomination");

export const selectNominationList = createSelector(
    selectNominationData,
    state => state?.nominationList,
);

export const selectNominationDetail = createSelector(
    selectNominationData,
    state => state.nominationDetail,
);

export const publishOrDeleteNominationData = createSelector(
    selectNominationData,
    state => state.publishOrDeleteNominationApiResponse,
);

export const nominationRefData = createSelector(
    selectNominationData,
    state => state.nominationRefData,
);

export const selectEthanolNominationList = createSelector(
    selectNominationData,
    state => state.ethanolNominationList,
);