<div class="modal fade" [ngClass]="{ show: show }" id="staticBackdrop" data-backdrop="static" data-keyboard="false"
  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title-wrap">
          <span class="label" *ngIf="label">{{ label }}</span>
          <h5 class="modal-title" id="staticBackdropLabelTitle">
            {{ headerTitle }}
          </h5>
        </div>
        <em *ngIf="subHeaderText !== ''" class="pull-right mandatory-text">{{ subHeaderText }}</em>
      </div>
      <div class="modal-body d-flex flex-column">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer d-flex justify-content-start" *ngIf="!noModalButtons">
        <button *ngIf="secondaryButtonLabel.length" type="button" (click)="secondaryClick()"
          class="button secondary m-0 mr-3" data-dismiss="modal">
          {{ secondaryButtonLabel }}
        </button>
        <button *ngIf="primaryButtonLabel.length" type="button" (click)="primaryClick()"
          class="button primary m-0 mr-3">
          {{ primaryButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>