import { Component } from '@angular/core';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { Store } from '@ngrx/store';
import { NominationService } from './services/nomination.service';
import { userDetails } from './shared/store/action/app.action';
import { AppState } from './shared/store/reducer/app.reducer';
import { MyMonitoringService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pmmi-udm';
  currentUserProfile!: ICvxClaimsPrincipal;
  isLoggedIn: boolean = false;


  constructor(private authService: CalAngularService, public store: Store, private appStore: Store<AppState>, public nominaitonService: NominationService, private myMonitoringService: MyMonitoringService) {
    this.login();
  }
  login() {
    this.authService.userInitiatedSignIn().subscribe({
      next: (user: any) => {
        this.isLoggedIn = user?.isUser;
        if (this.isLoggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          this.nominaitonService.userDetails$.next(this.currentUserProfile);
          let userName = this.currentUserProfile.userName;
          let userCai = this.currentUserProfile.cai;
          this.myMonitoringService.setAuthenticatedUserContext(userName);
          this.appStore.dispatch(userDetails({ userDetails: user }))
        } else {
          console.log('user not yet signed in');
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log('Login Completed');
      },
    });
  }
}
