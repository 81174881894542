<div class="panel-content">
    <ng-container>
        <div>
            <div class="content__header">exposure
            </div>
            <form class="branded">
                <div class="contract-balancing-side-panel-container">
                    <p *ngFor="let filterObj of secondaryFiltersObj.exposure.exposure_Array | slice:0:secondaryFiltersObj.exposure.displayCount; let i = index;"
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'exposure'"
                            [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'exposure', i)" [disabled]="filterObj.disabled">
                        <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'exposure'">
                            {{filterObj.name}}
                        </label>
                    </p>
                    <p *ngIf="secondaryFiltersObj.exposure.displayCount < secondaryFiltersObj.exposure.exposure_Array.length" class="see-more-link" (click)="seeMoreValues('exposure')">see more +</p>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">term
            </div>
            <form class="branded">
                <div class="contract-balancing-side-panel-container">
                    <p *ngFor="let filterObj of secondaryFiltersObj.term.term_Array | slice:0:secondaryFiltersObj.term.displayCount; let i = index;"
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'term'"
                            [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'term', i)" [disabled]="filterObj.disabled">

                        <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'term'">
                            {{filterObj.name}}
                        </label>
                    </p>
                    <p *ngIf="secondaryFiltersObj.term.displayCount < secondaryFiltersObj.term.term_Array.length" class="see-more-link" (click)="seeMoreValues('counterParty')">see more +</p>
                </div>
            </form>
            <hr>
        </div>
       <div>
            <div class="content__header">counterparty
            </div>
            <form class="branded">
                <input class="form-control ethonal-view_search" style="font-family: GothamBold;" [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateFilterList($any($event.target).value, 'counterParty',0)" [value]="searchText[0]?.value">
                <div class="contract-balancing-side-panel-container" [class.increase-container-height]="secondaryFiltersObj.counterParty.counterParty_Array.length > 4">
                        <p *ngFor="let filterObj of secondaryFiltersObj.counterParty.counterParty_Array | slice:0:secondaryFiltersObj.counterParty.displayCount; let i = index;" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'counterParty'" [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'counterParty', i)" [disabled]="filterObj.disabled">
                            <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'counterParty'">
                                {{filterObj.name}}
                            </label>
                        </p>
                        <p *ngIf="secondaryFiltersObj.counterParty.displayCount < secondaryFiltersObj.counterParty.counterParty_Array.length" class="see-more-link" (click)="seeMoreValues('counterParty')">see more +</p>
                </div>
            </form>
            <hr>
        </div>
         <div>
            <div class="content__header">location
            </div>
            <form class="branded">
                <input class="form-control ethonal-view_search" style="font-family: GothamBold;" [name]="'searchText2'" type="search"
                    placeholder="search" (input)="updateFilterList($any($event.target).value, 'location',1)" [value]="searchText[1]?.value">
                <div class="contract-balancing-side-panel-container" [class.increase-container-height]="secondaryFiltersObj.location.location_Array.length > 4">
                    <p *ngFor="let filterObj of secondaryFiltersObj.location.location_Array | slice:0:secondaryFiltersObj.location.displayCount; let i = index;" class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'location'" [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'location', i)" [disabled]="filterObj.disabled">
                        <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'location'">
                            {{filterObj.name}}
                        </label>
                    </p>
                    <p *ngIf="secondaryFiltersObj.location.displayCount < secondaryFiltersObj.location.location_Array.length" class="see-more-link" (click)="seeMoreValues('location')">see more +</p>
                </div>
            </form>
            <hr>
        </div>
    </ng-container>
</div>

<div class="panel-footer">
    <span class="clear-filter" (click)="resetFilters()">reset</span>
    <span class="apply-filter" (click)="applyFilters()">apply</span>
</div>