import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-light-header',
  templateUrl: './light-header.component.html',
  styleUrls: ['./light-header.component.scss']
})
export class LightHeaderComponent implements OnInit {

  @Input() header: string = '';
  @Input() listLength: number = 0;
  @Input() arrowVisible: boolean = false;
  @Input() flipArrow: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  changeArrowDirection(){
    this.flipArrow = !this.flipArrow;
  }
}
