import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommentI } from 'src/app/shared/interface/comment.interface';
import { infoPanelData, panelType } from 'src/app/services/information-panel.service';
import { DateService } from 'src/app/services/date.service';
import { CommentBehaviorType, CommentsService } from 'src/app/services/comments.service';

@Component({
  selector: 'comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit, OnChanges {

  @Input() comment!: CommentI;
  @Input() isInputActive: boolean = false;
  @Input() createNewComment: boolean = false;
  @Input() commentPublished: boolean = false;
  @Output() commentCreated = new EventEmitter<boolean>();
  commentContent: string = ''; //input used only to get comment placeholder b4 backend
  @Input() totalTagLengthOptions = 0;
  @Input() screenInformation: infoPanelData = { name: "", date: "", id: "", type: panelType.CP, data: ""};

  commentBackup: string = '';
  tagBackup: string[] = [];
  tags: string[] = [];

  currentUserEmail: string = '';
  
  constructor(private changeDetector: ChangeDetectorRef, private dateService: DateService, private commentService: CommentsService) {

    this.currentUserEmail = localStorage.getItem('userEmail') != null? localStorage.getItem('userEmail')!: ''; 
   }

  ngOnInit(): void {
  }

  ngOnChanges(){

    if(this.comment != null){
      this.commentContent = this.comment.commentText;
      this.tags = this.generateTags(this.comment.commentTag);
    }
  }

  generateTags(booleanList: any){

    let generatedList = [];

    if(booleanList.volume){
      generatedList.push("Volume");
    }
    if(booleanList.decisionCapture){
      generatedList.push("Decision Capture");
    }  
    if(booleanList.general){
      generatedList.push("General");
    }      
    if(booleanList.date){
      generatedList.push("Date");
    }
    return generatedList;

  }


  setEditComment(){
    if(!this.isInputActive){
      this.isInputActive = !this.isInputActive; 
      this.commentBackup = this.commentContent;
      this.tagBackup = [...this.tags];
    }
  }

  cancelChanges(){

    if(this.isInputActive && this.createNewComment){
      this.commentCreated.emit(true);
    }
    this.isInputActive = false;
    this.commentContent = this.commentBackup;
    this.tags = [...this.tagBackup];
  }

  updateComment(){

    let payload: CommentI = this.comment;

    payload.commentText = this.commentContent;
    payload.commentTag = this.commentService.generateTagList(this.tags);
    payload.updatedDate = this.dateService.getCurrentTimeInISO();

    this.commentService.updateComment(payload).subscribe()
    this.commentBackup = '';
    this.tagBackup = [];
    this.isInputActive = false;
  }

  createComment(){

    let payload: CommentI = {
      commentTypeId: this.screenInformation.id,
      commentType: this.commentService.getCommentTypeFromPanelType(this.screenInformation.type),
      commentTag: this.commentService.generateTagList(this.tags),
      email: localStorage.getItem("userEmail")!,
      createdBy: localStorage.getItem("userName")!,
      createdDate: this.dateService.getCurrentTimeInISO(),
      updatedDate: '',
      isActive: true,
      commentText: this.commentContent,
    };

    this.commentService.createComment(payload).subscribe({
      complete: () => {
        this.commentService.commentListUpdate.next(
          {
            behaviorType: CommentBehaviorType.ADD,
            comment: payload,
          }
        );
      }
    });
    this.commentCreated.emit(true);
  }


  deleteComment(){

    if(this.comment.id == null){
      return;
    }
    
    this.commentService.deleteComment(this.comment.id!, this.comment.commentType, false).subscribe({
      complete: () => {
        this.commentService.commentListUpdate.next(
          {
            behaviorType: CommentBehaviorType.DEL,
            comment: this.comment,
          }
        );
      }
    });
  }

  updateTagList(tagToAdd: string){
    this.tags = [...this.tags, tagToAdd];
    this.changeDetector.detectChanges();
  }

  updateTotalLength(lengthOfAllTags: number){
    this.totalTagLengthOptions = lengthOfAllTags;
  }

  removeTag(indextoRemove: number){
    if(this.isInputActive){
      this.tags.splice(indextoRemove, 1);
      this.tags = [...this.tags,];
      this.changeDetector.detectChanges();
    }
  }

}
