import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CargoPlanningService {
  public cargoSchedulePayloadUpdates: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public createNominaitonsFromCargo: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public createNominationFromCargoWithReference: BehaviorSubject<any> = new BehaviorSubject<any>({ initiate: false, data: {} });
  public vesselNameOrVehicleName: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public locationNameAndId: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public scheduledMaterialAndDemandMaterialName: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public isCargoScreen: any = false;
  public restrictAutoAdjustment = false;
  public isEditCargo = false;
  public cargoCCAFilters: any = {};
  public cargoVesselTypes: any = [];
  public cargoCCADateFiltersInitiated: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public editCargoData: any;

  constructor(private apiService: ApiService) { }

  getFilterCargoPlanning(postData: any) {
    return this.apiService
      .post(
        `${environment.cargoApi}/GetCargoPlanData`, 'cargo', postData
      )
      .pipe();
  }

  updateCargoPlanning(postData: any) {
    return this.apiService
      .patch(
        `${environment.cargoApi}/UpdateCargoPlan`, 'cargo', postData
      )
      .pipe(shareReplay());
  }

  updateLinkNominationWithCargo(postData: any) {
    return this.apiService
      .patch(
        `${environment.cargoApi}/LinkNominationWithCargo`, 'cargo', postData
      )  
  }

  duplicateCargo(postData: any){
    return this.apiService.
    post(
        `${environment.ccaCargoApi}/DuplicateCcaCargoPlan`,'cargoDuplicate', postData
    ).pipe();
  }

  getCargoRefData(postData: any) {
    return this.apiService
    .post(
      `${environment.ccaCargoApi}/GetCCACargoReferenceData`, 'cargoRefData', postData
    ).pipe();
  }
}
