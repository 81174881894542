import { createAction, props } from "@ngrx/store";
import { State } from "../reducer/settings-alerts.reducer";
import { SettingsAlertFilters } from "src/app/shared/interface/settings.interface";


export const loadRulesData = createAction(
  '[Load Alert Rules Data API]',
);

export const loadRulesDataSuccess = createAction(
  '[Load Alert Rules Data API Success]',
  props<{ alertRulesData: State }>(),
);

export const loadRulesDataFailure = createAction(
  '[Load Alert Rules Data API Failure]',
  props<{ error: any }>(),
);

export const updateAlertFilters = createAction(
  '[Alert Filters] Update Alert Filters',
  props<{ SettingsAlertFilters: SettingsAlertFilters }>()
);

export const UpdatAlertFilterData = createAction(
  '[Alert Filters] Set UpdatAlertFilterData',
  props<{ UpdatAlertFilterData: boolean }>()
);

