import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CbDynamicDealsService {

  constructor(private apiService: ApiService) { }

    getDynamicMockDeals(): Observable<any> {
      return this.apiService
        .get(
          `${environment.contractBalancingAPI}/GetDynamicDealsMockData`,
          'cbDashboard',
        )
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error: any) => of({ value: { data: [] }}))
        )
    }

    getDynamicDeals(dealsPayload:any): Observable<any> {
      return this.apiService
        .post(
          `${environment.contractBalancingAPI}/GetDynamicDeals`,
          'cbDashboard',dealsPayload
        )
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error: any) => of({ value: { data: [] }}))
        )
    }

    getCBSearchResults(payload:any): Observable<any> {
      const url = `${environment.contractBalancingAPI}/SearchContractBalancing`;
      return this.apiService
        .post(url, 'contractBalancing', payload)
        .pipe(
          map((data) => {
            return data;
          })
        );
    }
}
