import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { infoPanelData, panelType } from 'src/app/services/information-panel.service';
import { displayActivityTrackerData, activityTrackerFields,tabTrackerData } from 'src/app/shared/interface/activity-tracker';
import { ActivityTrackerService } from 'src/app/services/activity-tracker.service';
import { activitytrackerutility } from 'src/app/shared/utilities/activitytrackerutility'

@Component({
  selector: 'activity-tracker',
  templateUrl: './activity-trackern.component.html',
  styleUrls: ['./activity-trackern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTrackernComponent implements OnInit {

  //used to capture information from the information panel itself
  @Input() screenInformation: infoPanelData = { name: "", date: "", id: "", type: panelType.NONE, data: ""};
  activityTrackerFormedData!: displayActivityTrackerData;

  //used for the top level selector
  headerNavigation: tabTrackerData[] = [{ displayname: '', id: '', displayValue: '',  activityTrackerData:[] }];
  selectedTabIndex: number = 0;
  hasData: boolean = false;
  activityTrackerData!: activityTrackerFields;

  doneWithAPI = false;

  constructor(private atService: ActivityTrackerService, private changeDetection: ChangeDetectorRef,
    private activityutility: activitytrackerutility) { }

  ngOnInit(): void {
    this.setupHeaderList();
    this.getCCAATData();
  }

  ngOnChanges() {
    this.setupHeaderList();
    this.getCCAATData();
  }

  updateSelection(index: number) {

    if (index == this.selectedTabIndex) {
      return;
    }
    this.selectedTabIndex = index;
  }

  changeDropdowns() {
    this.getCCAATData();
  }

  getCCAATData() {
    this.doneWithAPI = false;   
    this.atService.getCCAActivityTrackerData(this.screenInformation.id)
      .subscribe({
        next: (response: any) => {
        
          this.activityTrackerData = response;
          this.ccaprepareActivityTrackerData(this.activityTrackerData, this.screenInformation.id);

        },
        error: (err: any) => {

          console.log(err);
        },
        complete: () => {
          this.doneWithAPI = true;
        }
      })

  }

  ccaprepareActivityTrackerData(activityTrackerData: activityTrackerFields, id: string) {

    this.activityTrackerFormedData = this.activityutility.prepareActivityTrackerData(activityTrackerData, id);
    this.hasData = this.activityTrackerFormedData.trackerData.length > 0 && this.activityTrackerFormedData.displayValue != null; 

    this.setupHeaderList();
    this.getPortDeterminalData();
    this.changeDetection.detectChanges();
  }

  setupHeaderList() {

    let portTermSet: Set<string> = new Set<string>();
    this.headerNavigation = [];

    if(this.activityTrackerFormedData?.trackerData == null){
      return;
    }
    this.activityTrackerFormedData.trackerData.forEach((portTerm: any) => {
      let currentValue: tabTrackerData = {
        id: portTerm.id,
        displayname: portTerm.displayname,
        displayValue: portTerm.displayValue,
        activityTrackerData:[] 
      }

      if (!portTermSet.has(currentValue.displayname)) {
        portTermSet.add(currentValue.displayname);
        this.headerNavigation.push(currentValue)
      }

    })

    this.changeDetection.detectChanges();

  }

  getPortDeterminalData() {
    let iterateValue = this.activityTrackerFormedData.trackerData;
    return this.activityTrackerFormedData.trackerData.filter(_ => { return _.id == this.headerNavigation[this.selectedTabIndex].id })
  }
}
