export const PSD_COMMON_HEADERS: string[] = ["availableInventory", "productionForecast", "productionOverride", "nominationNumber", "nominationVol", "shipmentAdjustment", "comments", "whatifShipment", "whatifInventory"];
export const PSD_CONSUMPTION_COLS: string[] = ["consumption", "consumptionOverride"];
export const WHAT_IF_PAYLOAD = {
    "rowIndex": 0,
    "id": null,
    "modeOfTransport": "PL",
    "modeOfTransportDesc": "Pipeline",
    "udmModeOfTransportDesc": "Pipeline",
    "nominationNo": "",
    "transportSystem": "",
    "deskFilter": "pipelinedesk",
    "tdVehNo": "",
    "sys_status": "draft",
    "udmDesk": 18
}

export const WHAT_IF_PAYLOAD_2 = {
    "itemId": null,
    "udmItemStatus": "draft",
    "scheduledDate": null,
    "scheduledQty": null,
    "scheduledUom": "MB",
    "confirmationIndicator": "X",
    "scheduledMaterial": null,
    "scheduledMaterialDesc": null,
    "demandMaterial": null,
    "scheduleType": "O",
    "referenceDocumentIndicator": null,
    "locationId": "PASADENA",
    "textLine": "{Author:null,MdfBy:null,SS:UDM}",
    "contractPartner": null,
    "udmDisableValidations": true
}