import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';
import { ContractsguardService } from './services/contractsguard.service';

const routes: Routes = [
  /* {
    path: '',
    component: AppComponent,
    pathMatch: 'full',
    canActivate: [CalGuardService],
  }, */
  {
    path: 'replenishmentPlanning',
    canActivate: [CalGuardService],
    loadChildren: () =>
      import('./replenishment-planning/replenishment-planning.module').then(
        (m) => m.ReplenishmentPlanningModule
      ),
  },
  {
    path: 'marketElasticity',
    canActivate: [CalGuardService],
    loadChildren: () =>
      import('./market-elasticity/market-elasticity.module').then(
        (m) => m.MarketElasticityModule
      ),
  },
 {
    path: 'nominations',
    loadChildren: () => import('./nominations/nominations.module').then(m => m.NominationsModule)
  },
  {
    path: 'cargoPlanning',
    loadChildren: () => import('./cargo-planning/cargo-planning.module').then(m => m.CargoPlanningModule)
  },
  {
    path: 'bcpDashboard',
    loadChildren: () => import('./bcp-dashboard/bcp-dashboard.module').then(m => m.BcpDashboardModule)
  },
  {
    path: 'contracts/contract-balancing',
    loadChildren: () => import('./contract-balancing/contract-balancing.module').then(m => m.ContractBalancingModule)
  },
  {

    path: 'contracts/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    
  },
  {
    path: 'admin/parameters',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'settings/alerts',
    loadChildren: () => import('./settings-alerts/settings-alerts.module').then(m => m.SettingsAlertsModule)
  },
  { 
    path: 'admin/locations',
    loadChildren: () => import('./admin-locations/admin-locations.module').then(m => m.AdminLocationsModule)
  },
  {
    path: 'admin/desks',
    loadChildren: () => import('./admin-desks/admin-desks.module').then(m => m.AdminDesksModule)
  },
  {
    path: 'admin/materialMapping',
    loadChildren: () => import('./admin-material-mapping/admin-material-mapping.module').then(m => m.AdminMaterialMappingModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'admin/transport',
    loadChildren: () => import('./admin-transport/admin-transport.module').then(m => m.AdminTransportModule)
  },
  {
    path: 'activityTracker',
    loadChildren: () => import('./activity-tracker/activity-tracker.module').then(m => m.ActivityTrackerModule)
  },
  {
    path: 'contracts/dynamicdeals',
    loadChildren: () => import('./dynamicdeals/dynamicdeals.module').then(m => m.DynamicdealsModule)
  },
  {
    path: 'contracts/dealdetail/:dealNumber/:contractNumber',
    loadChildren: () => import('./contract-balancing/dynamicdeal-detail/dynamicdeal-detail.module').then(m=>m.DynamicDealDetailModule)
  },
  {
    path: 'contracts',
    redirectTo: 'contracts/dynamicdeals',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'replenishmentPlanning' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
