<div *ngIf="show" id="alert-shim-wrap" [ngClass]="{
    'notification-warning': data.type === 'warning',
    'notification-error': data.type === 'error',
    'notification-success': data.type === 'success',
    'notification-info': data.type==='info' && !isContractBalancingDynamicDeals,
    'notification-info-dark': data.type==='info' && isContractBalancingDynamicDeals
}">
    <div id="alert-shim">
        <a id="alert-shim-close" href="javascript:void(0)" (click)="hide()">close</a>
        <p [class.dark-text]="isContractBalancingDynamicDeals">{{data.type}}: <strong [class.dark-text]="isContractBalancingDynamicDeals">{{data.msg}}</strong></p>
    </div>
</div>