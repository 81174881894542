import { createAction, props } from "@ngrx/store";
import { CbDynamicDealsData } from "src/app/shared/interface/cb-dynamic-deals.interface";



  //dynamic deals
  export const loadDynamicDeals = createAction(
    '[Dynamic Deals API] Load Dynamic Deals',
    props<{payload:any}>()
  );

  export const loadDynamicDealsMock = createAction(
    '[Dynamic Deals API] Load Dynamic Deals mock'
  );
  
  export const loadDynamicDealSuccess = createAction(
    '[Dynamic Deals API] Load Dynamic Deals API Success',
    props<{ cbDynamicDeals: any[] }>(),
  );
  
  export const loadDynamicDealFailure = createAction(
    '[Dynamic Deals API] Load Dynamic Deals API Failure',
    props<{ error: any }>(),
  );

  export const applyDDPrimaryFilter = createAction(
    '[Dynamic Deals Data Filters] Apply Dynamic Deals Primary Filter',
    props<{filters:any}>()
  );

  export const applyDDSecondaryFilter = createAction(
    '[Dynamic Deals Data Filters] Apply Dynamic Deals Secondary Filter',
    props<{filters:any}>()
  );

  export const resetDDSecondaryFilter = createAction(
    '[Dynamic Deals Data Filters] Reset Dynamic Deals Secondary Filter'
  );
  export const resetAllFilters = createAction(
    '[Dynamic Deals Data Filters] Reset Dynamic Deals All Filters'
  );

  export const selectMaterialTab = createAction(
    '[Dynamic Deals Data Filters] Select Material Tab',
    props<{tab:string}>()
  );
  export const toggleDealHeader = createAction(
    '[Dynamic Deals Data] Togggle Deal Header',
    props<{index:any}>()
  );

  export const updateCustomDateRangePicker = createAction(
    '[Dynamic Deals API] Refresh Custom Date Range Picker',
    props<{updateCustomDateRangePickerFlag:boolean}>()
  );
