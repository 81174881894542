<div class="more-menu">
    <span class="text-truncate">{{ values['inTransitQty'] }}</span>
    <div *ngIf="values['isEditable']" class="more-menu-dropdown">
        <button class="ellipses-icon"></button>
        <div class="dropdown-content">
            <ng-container *ngIf="values['relatedNominations']?.length">
                <ng-container *ngFor="let nomination of values['relatedNominations']">
                  <a class="dropdown-item edit-nomination" (click)="editNomination(nomination)">edit nomination {{nomination.nominationNo}}</a>
                </ng-container>
              </ng-container>
        </div>
    </div>
</div>