<div class="search-dropdown {{disabledStatus}}" [ngClass]="{'open':shown && list !== undefined, 'hasValue': value !== 'select'}">
    <div class="search-dropdown__btn" [ngClass]="{'hasValue': value !== 'select'}" (click)="show()" #dropdownContainer tabindex="0" (keydown.enter)="show()">
    <span class="updated-value selectedOptions"> {{selectedOptions.join(',')}}</span>
    <div *ngIf="multiple">
      <span class="updated-value default-option" *ngIf="selectedOptions.length === 0">select</span>
    </div>
    <div *ngIf="!multiple">
      <span class="updated-value default-option" [ngClass]="{'hasValue': value !== 'select'}"  (click)="triggerEvent()">{{value}}</span>
    </div>
  </div>
  <div class="search-dropdown__dropdown">
    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword"
      (keyup)="search(keyword)" #input />
    <div class="search-dropdown__dropdown__scroller">
      <div class="search-dropdown__dropdown__item" [hidden]="keyword.length > 0">
        <span (click)="select('select')" class="flex-grow-1"> select </span>
        <div *ngIf="multiple">
          <span class="close-btn icon-remove" title="close" (click)="closeDropdown()"></span>
          <img src="../../../../assets/icons/16_Trashcan.svg" title="delete selection" alt="delete icon"
            (click)="uncheckAll()" class="clear-icon close-btn">
        </div>
      </div>
      <div (keydown)="onKeyDown($event)" class="list-holder">
        <div class="search-dropdown__dropdown__item" *ngFor="let item of list; let i = index;"  [title]="item">
          <div *ngIf="multiple" class="flex-grow-1">
            <label for="{{item}}-{{i}}" class="dropdown-inner-item d-flex align-items-center">
              <input type="checkbox" #checkboxes id="{{item}}-{{i}}" class="option-select-checkbox mr-2"
                (change)="changeSelection($event)" value="{{item}}">
              {{item}}</label>
          </div>
          <span *ngIf="!multiple" class="flex-grow-1" (focus)="focusedIndex = i" [class.focused]="i === focusedIndex" tabindex="0" (keydown.enter)="select(item)" (click)="select(item)">{{item}}</span>
        </div>
      </div>
     
      <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0">No Result Found</div>
    </div>
  </div>
</div>