<div class="more-menu">
  <ng-container *ngIf="!nominationTab">
    <ng-container *ngIf="details;else showOnlyValue">
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    </ng-container>
    <ng-template #showOnlyValue><span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span></ng-template>
  </ng-container>
  <div class="more-menu-dropdown">
    <button *ngIf="moremenuIconShow && ( (details?.id!== '' && details?.status!== ''))" class="ellipses-icon"
      (click)="moremwnu()"></button>
    <div *ngIf="!isOpen" class="dropdown-content" [ngClass]="[!isDetailGrid ? (getRowIndex(pipelineParentObj) < 10 ? '' : 'place-top') : (rowIndex < 5 ? '' : 'place-top')]">
      <a *ngIf="!isDetailGrid && !isIntank" class="dropdown-item edit-nomination" (click)="editNomination(pipelineParentKey)">edit
      nomination</a>
      <a *ngIf="!isDetailGrid" class="dropdown-item create-nom-with-ref"
        (click)="createNomWithRef(pipelineParentKey)">create nom. with
        reference</a>
        <a *ngIf="!isDetailGrid && nominationTab && !lineItem && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)" class="dropdown-item contract-balancinf"
      (click)="vesselScheduleUpdate()" [ngClass]="{'disableVesselOption': disableVesselOption(childGridData)}">vessel schedule update</a>
      <a *ngIf="!isDetailGrid" class="dropdown-item contract-balancinf"
        (click)="contractBalancing()">contract
        balancing</a>
      <a class="dropdown-item publish" (click)="publish(pipelineParentKey)">publish</a>
      <a class="dropdown-item delete"
        (click)="delete(pipelineParentKey)">delete</a>
      <a *ngIf="!isDetailGrid" class="dropdown-item see-details" (click)="seeDetails()">see details</a>
      <a class="dropdown-item see-details" (click)="openActivityTracker()"> see nomination activity </a>
      <a *ngIf="!isDetailGrid" class="dropdown-item see-details" (click)="openComments()"> nomination comments </a>
    </div>
  </div>


</div>