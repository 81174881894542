<div class="dropdown">
  <button class="dropdown-button" #toggleButton [ngClass]="{'active': true}" (click)="displayMenu($event)">
    <span *ngIf="!selectedDataContent" class="ml-1">{{SelectedDesk}}</span>
    <span *ngIf="selectedDataContent" class="ml-1">
      <ng-container [ngTemplateOutlet]="selectedDataContent"></ng-container>
    </span>

  </button>
  <div class="dropdown-menu-wrap">
    <div class="dropdown-menu" #menu *ngIf="displayMenuItems">
      <div *ngFor="let desk of deskList; let i = index ">
        <div *ngIf="desk.items && desk.items.length > 0; else single_item">
          <span class="dropdown-text pl-3 option-item">{{desk.name}}</span>
          <div *ngFor="let item of desk.items" [ngClass]="item.subOptions && item.subOptions.length > 0 ? 'parent': ''"
            class="branded radio-btn p-0 m-0">
            <div class="d-flex justify-content-between sub-menu-link" [class.disable-option]="item.name === 'ecca' || item.name === 'wcca'">
              <button class="dropdown-item button-text pl-3"
                (click)="!(item.subOptions && item.subOptions.length > 0) && updateSelectedDesk($event, item)" [disabled]="item.name === 'ecca' || item.name === 'wcca'">{{item.name}}
              </button>
              <img src="../../../assets/icons/chevron_right.svg" alt="right icon" class="right-icon"
                *ngIf="item.subOptions && item.subOptions.length > 0">
            </div>

            <div class="dropdown-menu sub-menu" #subMenu *ngIf="item.subOptions && item.subOptions.length > 0">
              <div *ngFor="let subItem of item.subOptions">
                <div>
                  <div class="branded radio-btn p-0 m-0">
                    <button class="dropdown-item button-text pl-3 w-100"
                      (click)="updateSelectedDesk($event, subItem)">{{subItem.name}}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="dropdown-divider"></div>
        </div>
        <ng-template #single_item>
          <div class="branded radio-btn p-0 m-0">
            <button *ngIf="!desk.dataContent" class="dropdown-item button-text pl-3" [disabled]="desk.disable"
              (click)="updateSelectedDesk($event, desk)">{{desk.name}}</button>
            <button *ngIf="desk.dataContent" class="dropdown-item button-text pl-3"
              [class.dropdown-contetnt-element]="'dropdown-contetnt-element'"
              (click)="updateSelectedDesk($event, desk)">
              <ng-container [ngTemplateOutlet]="desk.dataContent"></ng-container>
            </button>
            <div class="dropdown-divider my-1"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

</div>