import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AlertLocationMaterialRulesData, SettingsParameterFilters, SettingsParameterLocationsFilter, SettingsParameterUniqueMaterialsFilter, SettingsParameterMaterialsFilter, SettingsParametersFilter, SettingsUniqueParametersFilter } from '../../../shared/interface/settings.interface';
import { selectDesk } from '../../../shared/store/selector/app.selector';
import { updateParameterFilters } from '../../../settings/store/action/parameters.action';
import { UDMDesk } from '../../../shared/constants/terminal-replenishment.constant';
import { MdmService } from 'src/app/services/mdm.service';
import { MDMDeskValues, MDMDeskLocations, MDMLocationMaterials } from 'src/app/shared/interface/mdm.interface';
import { AlertsService } from "src/app/services/alerts.service";

@Component({
  selector: 'app-settings-parameters-filter',
  templateUrl: './settings-parameters-filter.component.html',
  styleUrls: ['./settings-parameters-filter.component.scss']
})
export class SettingsParametersFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  locationsearchValue: string = "";
  materialsearchValue: string = "";
  rulesearchValue: string = "";

  updateSettingsParameterLocationSearchData: SettingsParameterLocationsFilter[] = [];
  baseSettingsParameterLocationSearchData: SettingsParameterLocationsFilter[] = [];
  selectallornoneSettingsParameterLocationSearchData: SettingsParameterLocationsFilter[] = [];

  updateSettingsParameterMaterialSearchData: SettingsParameterMaterialsFilter[] = [];
  updateSettingsParameterUniqueMaterialSearchData: SettingsParameterUniqueMaterialsFilter[] = [];
  baseSettingsParameterUniqueMaterialSearchData: SettingsParameterUniqueMaterialsFilter[] = [];
  selectallornoneSettingsParameterMaterialSearchData: SettingsParameterUniqueMaterialsFilter[] = [];

  updateSettingsParameterSearchData: SettingsParametersFilter[] = [];
  updateSettingsUniqueParameterSearchData: SettingsUniqueParametersFilter[] = [];
  baseSettingsUniqueParameterSearchData: SettingsUniqueParametersFilter[] = [];
  selectallornoneSettingsParameterSearchData: SettingsUniqueParametersFilter[] = [];

  materialsIdList: string[] = [];
  ValutypesList: string[] = [];

  alertLocationMaterialRules: AlertLocationMaterialRulesData[] = [];

  settingsparameterfilters: SettingsParameterFilters = { locationsFilter: [], materialsFilter: [], parametersFilter: [] };
  updatedSettingsParameterLocationsFilter: SettingsParameterLocationsFilter[] = [];
  updatedSettingsParameterMaterialsFilter: SettingsParameterMaterialsFilter[] = [];
  updatedSettingsParametersFilter: SettingsParametersFilter[] = [];

  deskValues: MDMDeskValues[] = [];
  deskList: number[] = [];
  locValues: MDMDeskLocations[] = [];
  public matValues: MDMLocationMaterials[] = [];
  public matList: string[] = [];
  public matNameList: string[] = [];

  locationsforSelectedDesk: string[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  desk$ = this.store.pipe(select(selectDesk));
  selectedDesk: any;
  alreadyRunning: boolean = false;

  constructor(private store: Store, private mdmService: MdmService, private settingsService: AlertsService) { }

  ngOnInit(): void {
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.selectedDesk?.toLowerCase() != this.selectedDesk) {
          this.selectedDesk = response.selectedDesk?.toLowerCase();
          localStorage.setItem('selectedDesk', this.selectedDesk);
          this.getDeskData();
        } else {
          const savedDesk = localStorage.getItem('selectedDesk');
          if (savedDesk) {
            this.selectedDesk = savedDesk;
          } else {
            this.selectedDesk = UDMDesk.Panama;
          }
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
  }

  getDeskData() {

    if(this.alreadyRunning){
      return;
    }
    this.alreadyRunning = true;

    this.deskList = [];
    this.mdmService.getAllDesks().subscribe({
      next: (response: any) => {
        this.deskValues = response.value
        for (let k of this.deskValues) {
          if (Number(k.id) === Number(localStorage['deskId'])) {
            this.deskList.push(Number(k.id));
            this.getAllLocationsforSelectedDesk();
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    })
  }

  getAllLocationsforSelectedDesk() {
    const locPayload = {
      "deskIds": this.deskList,
      "filterExchangeTerminals": true
    };

    this.locationsforSelectedDesk = [];
    this.baseSettingsParameterLocationSearchData = [];
    this.updateSettingsParameterLocationSearchData = [];

    this.mdmService.getDeskLocation(locPayload).subscribe({
      next: (response: any) => {
        this.locValues = response.value
        for (let i of this.locValues) {
          this.locationsforSelectedDesk.push(i.locationCode);
          this.baseSettingsParameterLocationSearchData.push({ locationcode: i.locationCode, location: i.locationCode, checked: true })
          this.updateSettingsParameterLocationSearchData.push({ locationcode: i.locationCode, location: i.locationCode, checked: true })
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getMaterialData();
      },
    })
  }

  getMaterialData() {
    const matPayload = {
      "deskIds": this.deskList,
      "activeOnly": true,
      "locationIds": this.locationsforSelectedDesk
    };

    this.materialsIdList = [];
    this.updateSettingsParameterUniqueMaterialSearchData = [];
    this.baseSettingsParameterUniqueMaterialSearchData = [];
    this.updateSettingsParameterMaterialSearchData = [];

    this.mdmService.getMaterialLocationMappings(matPayload).subscribe({
      next: (response: any) => {
        this.matValues = response.value.value
        for (let i of this.matValues) {
          for (let j of i.locationMaterials) {
            this.matList.push(j.materialNumber)
            this.matNameList.push(j.materialDescription)
            if (!this.materialsIdList.includes(j.materialNumber)) {
              this.updateSettingsParameterUniqueMaterialSearchData.push({ materialnumber: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
              this.baseSettingsParameterUniqueMaterialSearchData.push({ materialnumber: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
              this.materialsIdList.push(j.materialNumber)
            }
            this.updateSettingsParameterMaterialSearchData.push({ location: i.locationCode, materialnumber: j.materialNumber, material: j.materialDescription, udmmaterial: j.udmNickname, checked: true })
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getParamsData();
      },
    })
  }

  getParamsData() {
    const payload = {
      "locationCodes": this.locationsforSelectedDesk,
      "materialNumbers": this.matList
    };

    this.ValutypesList = [];
    this.updateSettingsUniqueParameterSearchData = [];
    this.baseSettingsUniqueParameterSearchData = [];
    this.updateSettingsParameterSearchData = [];

    this.settingsService
      .getTankParamsNonDeconstructed(payload)
      .pipe()
      .subscribe({
        next: (data: any) => {
          data.value?.forEach((locelement: any) => {
            locelement.locationMaterials?.forEach((matelement: any) => {
              this.createParameterNameList(locelement, matelement);
            });
          });
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
        },
      });

    this.settingsparameterfilters.parametersFilter = this.updateSettingsUniqueParameterSearchData;
    this.settingsparameterfilters.parametersFilter.unshift({ parametername: 'all', checked: true });
    this.settingsparameterfilters.materialsFilter = this.updateSettingsParameterUniqueMaterialSearchData;
    this.settingsparameterfilters.materialsFilter.unshift({ materialnumber: 'all', material: 'all', udmmaterial: 'all', checked: true });
    this.settingsparameterfilters.locationsFilter = this.updateSettingsParameterLocationSearchData;
    this.settingsparameterfilters.locationsFilter.unshift({ locationcode: 'all', location: 'all', checked: true });
    this.store.dispatch(updateParameterFilters({ SettingsParameterFilters: JSON.parse(JSON.stringify(this.settingsparameterfilters)) }));
    this.alreadyRunning = false;
  }

  createParameterNameList(locelement: any, matelement: any) {
    matelement.valuationTypes?.forEach((valuelement: any) => {
      if (!this.ValutypesList.includes(valuelement.valuationType)) {
        this.updateSettingsUniqueParameterSearchData.push({ parametername: valuelement.valuationType, checked: true })
        this.baseSettingsUniqueParameterSearchData.push({ parametername: valuelement.valuationType, checked: true })
        this.ValutypesList.push(valuelement.valuationType)
      }
      this.updateSettingsParameterSearchData.push({ location: locelement.locationCode, material: matelement.materialNumber, parametername: valuelement.valuationType, checked: true })
    });
  }

  updateParameterLocationFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsparameterfilters.locationsFilter.filter((ele: any) => ele.location.toLowerCase().includes(text.value.toLowerCase()) && ele.location !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsParameterLocationSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsParameterLocationSearchData = this.settingsparameterfilters.locationsFilter;
      }
      else {
        this.updateSettingsParameterLocationSearchData = this.settingsparameterfilters.locationsFilter;
      }
    });
  }

  updateParameterMaterialsFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsparameterfilters.materialsFilter.filter((ele: any) => ele.material.toLowerCase().includes(text.value.toLowerCase()) && ele.material !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsParameterUniqueMaterialSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsParameterUniqueMaterialSearchData = this.settingsparameterfilters.materialsFilter;
      }
      else {
        this.updateSettingsParameterUniqueMaterialSearchData = this.settingsparameterfilters.materialsFilter;
      }
    });
  }

  updateParametersFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.settingsparameterfilters.parametersFilter.filter((ele: any) => ele.parametername.toLowerCase().includes(text.value.toLowerCase()) && ele.parametername !== 'all')];
        if (updatedFilterList.length > 0)
          this.updateSettingsUniqueParameterSearchData = JSON.parse(JSON.stringify(updatedFilterList));
        else
          this.updateSettingsUniqueParameterSearchData = this.settingsparameterfilters.parametersFilter;
      }
      else {
        this.updateSettingsUniqueParameterSearchData = this.settingsparameterfilters.parametersFilter;
      }
    });
  }

  filterByParamaterLocation(event: any): void {
    if (event.target.value === 'all') {
      this.selectallornoneSettingsParameterLocationSearchData = [];
      this.settingsparameterfilters.locationsFilter.forEach((location: SettingsParameterLocationsFilter) => {
        location.checked = event.target.checked;
        this.selectallornoneSettingsParameterLocationSearchData.push(location);
      });
      this.settingsparameterfilters.locationsFilter = this.selectallornoneSettingsParameterLocationSearchData;
    }

    this.updateSelectedLocationFilter(event);
    this.store.dispatch(updateParameterFilters({ SettingsParameterFilters: JSON.parse(JSON.stringify(this.settingsparameterfilters)) }));
  }

  updateSelectedLocationFilter(event: any): void {
    let selectedLocationFilter = this.settingsparameterfilters.locationsFilter.find((locationFilter: SettingsParameterLocationsFilter) => locationFilter.location === event.target.value);
    this.settingsparameterfilters.locationsFilter.forEach((location: SettingsParameterLocationsFilter) => {
      if (location.locationcode === selectedLocationFilter?.locationcode)
        location.checked = event.target.checked;
    });

    let locationsExceptall = this.settingsparameterfilters.locationsFilter.filter((location: SettingsParameterLocationsFilter) => location.locationcode != 'all')
    let allLocationsChecked: boolean = locationsExceptall.filter((location: SettingsParameterLocationsFilter) => location.checked === true).length === (this.settingsparameterfilters.locationsFilter.length - 1);
    this.settingsparameterfilters.locationsFilter.forEach((location: SettingsParameterLocationsFilter) => {
      if (location.locationcode === 'all')
        location.checked = allLocationsChecked;
    });

    this.materialsIdList = [];
    let allSelectedlocations = this.settingsparameterfilters.locationsFilter.filter((location: SettingsParameterLocationsFilter) => location.checked === true)
    allSelectedlocations.forEach((selectdloc: SettingsParameterLocationsFilter) => {
      this.updateSettingsParameterMaterialSearchData.forEach((mat: SettingsParameterMaterialsFilter) => {
        if (mat.location === selectdloc.locationcode && !(this.materialsIdList.includes(mat.material)))
          this.materialsIdList.push(mat.material)
      });
    });

    this.updateSettingsParameterUniqueMaterialSearchData = this.baseSettingsParameterUniqueMaterialSearchData.filter((mat: SettingsParameterUniqueMaterialsFilter) => this.materialsIdList.includes(mat.material));
    this.settingsparameterfilters.materialsFilter = this.updateSettingsParameterUniqueMaterialSearchData;
    this.settingsparameterfilters.materialsFilter.unshift({ materialnumber: 'all', material: 'all', udmmaterial: 'all', checked: true });

    this.LoadParamsForSelectedLocationMaterials();
  }

  filterByParameterMaterial(event: any): void {
    if (event.target.value === 'all') {
      this.selectallornoneSettingsParameterMaterialSearchData = [];
      this.settingsparameterfilters.materialsFilter.forEach((material: SettingsParameterUniqueMaterialsFilter) => {
        material.checked = event.target.checked;
        this.selectallornoneSettingsParameterMaterialSearchData.push(material);
      });
      this.settingsparameterfilters.materialsFilter = this.selectallornoneSettingsParameterMaterialSearchData;
    }

    this.updateSelectedMaterialFilter(event);
    this.store.dispatch(updateParameterFilters({ SettingsParameterFilters: JSON.parse(JSON.stringify(this.settingsparameterfilters)) }));
  }

  updateSelectedMaterialFilter(event: any): void {
    let selectedMaterialFilter = this.settingsparameterfilters.materialsFilter.find((materialFilter: SettingsParameterUniqueMaterialsFilter) => materialFilter.materialnumber === event.target.value);
    this.settingsparameterfilters.materialsFilter.forEach((material: SettingsParameterUniqueMaterialsFilter) => {
      if (material.materialnumber === selectedMaterialFilter?.materialnumber)
        material.checked = event.target.checked;
    });

    let materialsExceptall = this.settingsparameterfilters.materialsFilter.filter((material: SettingsParameterUniqueMaterialsFilter) => material.materialnumber != 'all')
    let allMaterialsChecked: boolean = materialsExceptall.filter((material: SettingsParameterUniqueMaterialsFilter) => material.checked === true).length === (this.settingsparameterfilters.materialsFilter.length - 1);
    this.settingsparameterfilters.materialsFilter.forEach((material: SettingsParameterUniqueMaterialsFilter) => {
      if (material.materialnumber === 'all')
        material.checked = allMaterialsChecked;
    });

    this.LoadParamsForSelectedLocationMaterials();
  }

  LoadParamsForSelectedLocationMaterials(): void {
    this.ValutypesList = [];
    let allParamSelectedlocations = this.settingsparameterfilters.locationsFilter.filter((location: SettingsParameterLocationsFilter) => location.checked === true)
    allParamSelectedlocations.forEach((selectdloc: SettingsParameterLocationsFilter) => {
      let allParamSelectedmaterials = this.settingsparameterfilters.materialsFilter.filter((material: SettingsParameterUniqueMaterialsFilter) => material.checked === true)
      allParamSelectedmaterials.forEach((selectedmat: SettingsParameterUniqueMaterialsFilter) => {
        this.updateSettingsParameterSearchData.forEach((valueType: SettingsParametersFilter) => {
          if (valueType.location === selectdloc.locationcode && valueType.material === selectedmat.materialnumber && !(this.ValutypesList.includes(valueType.parametername)))
            this.ValutypesList.push(valueType.parametername)
        });
      });
    });

    this.updateSettingsUniqueParameterSearchData = this.baseSettingsUniqueParameterSearchData.filter((param: SettingsUniqueParametersFilter) => this.ValutypesList.includes(param.parametername));
    this.settingsparameterfilters.parametersFilter = this.updateSettingsUniqueParameterSearchData;
    this.settingsparameterfilters.parametersFilter.unshift({ parametername: 'all', checked: true });
  }

  filterByParameter(event: any): void {
    if (event.target.value === 'all') {
      this.settingsparameterfilters.parametersFilter.forEach((rule: SettingsUniqueParametersFilter) => {
        rule.checked = event.target.checked;
      });
    } else {
      this.updateSelectedRuleFilter(event);
    }

    this.store.dispatch(updateParameterFilters({ SettingsParameterFilters: JSON.parse(JSON.stringify(this.settingsparameterfilters)) }));
  }

  updateSelectedRuleFilter(event: any): void {
    let selectedParameterFilter = this.settingsparameterfilters.parametersFilter.find((paramFilter: SettingsUniqueParametersFilter) => paramFilter.parametername === event.target.value);
    this.settingsparameterfilters.parametersFilter.forEach((param: SettingsUniqueParametersFilter) => {
      if (param.parametername === selectedParameterFilter?.parametername)
        param.checked = event.target.checked;
    });

    let parametersExceptall = this.settingsparameterfilters.parametersFilter.filter((param: SettingsUniqueParametersFilter) => param.parametername != 'all')
    let allParametersChecked: boolean = parametersExceptall.filter((param: SettingsUniqueParametersFilter) => param.checked === true).length === (this.settingsparameterfilters.parametersFilter.length - 1);
    this.settingsparameterfilters.parametersFilter.forEach((param: SettingsUniqueParametersFilter) => {
      if (param.parametername === 'all')
        param.checked = allParametersChecked;
    });
  }

  clearFilters(): void {
    this.locationsearchValue = '';
    this.materialsearchValue = '';
    this.rulesearchValue = '';

    this.getDeskData();
    this.store.dispatch(updateParameterFilters({ SettingsParameterFilters: JSON.parse(JSON.stringify(this.settingsparameterfilters)) }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}