import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import { Router, CanDeactivate, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertSaveGuardService implements CanDeactivate<boolean>{

  changeMap: Map<string, null>;

  changeList = new BehaviorSubject<string[]>([]);
  listObservable$: Observable<string[]> = this.changeList.asObservable();

  private hrefSource = new BehaviorSubject<boolean>(false);
  hrefObservable$: Observable<boolean> = this.hrefSource.asObservable();

  private deskSource = new BehaviorSubject<boolean>(false);
  deskObservable$: Observable<boolean> = this.deskSource.asObservable();

  private navigationSource = new BehaviorSubject<string>('');
  navigationObservable$: Observable<string> = this.navigationSource.asObservable();

  constructor(private router: Router){
    this.changeMap = new Map<string, null>();
  }

  ngOnInit(){
    this.router.events.subscribe((event) =>{

      if(event instanceof NavigationEnd){
        this.clearSettings();       
      }
    });
  }
  
  addContent(toggledValue: string){
    this.changeMap.has(toggledValue) ? 
        this.changeMap.delete(toggledValue) : this.changeMap.set(toggledValue, null);
    
    this.changeList.next(Array.from(this.changeMap.keys()));
  }

  setHrefSource(updateValue: boolean){
    this.hrefSource.next(updateValue);
  }

  setDeskSource(updateValue: boolean){
    this.deskSource.next(updateValue);
  }

  setNavigationAddress(updateValue: string){
    this.navigationSource.next(updateValue);
  }

  isUsingHrefBlock(): boolean{
    return this.hrefSource.value;
  }

  isUsingDeskBlock(): boolean{
    return this.deskSource.value;
  }


  extractURL(): string{
    return this.navigationSource.value;
  }

  clearSettings(){
    this.changeMap = new Map<string, null>();
    this.setHrefSource(false);
    this.setDeskSource(false);
    this.setNavigationAddress('');
    this.changeList.next([]);
  }

  routeAway(){
    if(this.isUsingHrefBlock()){
      window.location.href = this.extractURL();
    }else if(this.isUsingDeskBlock()){
      console.log(this.router.url)
      this.router.navigateByUrl(this.router.url);
      this.clearSettings();
    }else{
      this.router.navigateByUrl(this.extractURL());
      this.clearSettings();
    }
  }

  canDeactivate(){
    if(this.changeMap.size === 0){
      return true;
    }else{
      return false;
    }
  }
}
