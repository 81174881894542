import { createAction, props } from '@ngrx/store';
import { State } from '../reducer/market-elasticity.reducer';

export const invokePSDFilterDataApi = createAction(
    '[Market Elasticity API] Invoke PSD FilterData API',
    props<{ dateRange: any, enableRefreshTableFlagArr: any[], desk: any }>()
);

export const getPSDFilterDataApiSuccess = createAction(
    '[Market Elasticity API] get PSD Filter Data api Success',
    props<{ allPSDFilterData: State, dateRange: any, enableRefreshTableFlagArr: any[] }>()
);

export const invokePSDViewData = createAction(
    '[Market Elasticity API] Market Elasticity PSD View',
    props<{ meFilters: any, isUpdated:boolean }>()
);

export const getPSDViewDataApiSuccess = createAction(
    '[Market Elasticity API] Market Elasticity PSD View Success',
    props<{PSDViewData: any}>()
);




