import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LocationData, AdminView, LocationFilters, TextFilter, CoordinateFilter } from '../../../../shared/interface/mdm-admin.interface';
import { selectLocationsData } from '../../../../admin-locations/store/selector/admin-locations.selector';
import { addEditedLocations, setDefaultLocationFilters, updateLocationFilters } from '../../../../admin-locations/store/action/admin-locations.action';
import { selectAdminView } from '../../../../../app/shared/store/selector/app.selector';

@Component({
  selector: 'app-locations-filter',
  templateUrl: './locations-filter.component.html',
  styleUrls: ['./locations-filter.component.scss']
})
export class LocationsFilterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  locationsData$ = this.store.pipe(select(selectLocationsData));
  selectedAdminView$ = this.store.pipe(select(selectAdminView));
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  adminView: string = "";
  locationsData: LocationData[] = [];
  filters: LocationFilters = {
    locationName: [],
    locationCode: [],
    exchangeTerminal: []
  };
  relatedFilters: string[] = ['locationName', 'locationCode'];
  toggleFilters: string[] = ['exchangeTerminal'];
  coordinateFilters: CoordinateFilter[] = [
    { filterName: 'latitude', filterValue: NaN },
    { filterName: 'longitude', filterValue: NaN }
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedAdminView$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.adminView = response.adminView;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });

    this.locationsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (this.adminView === AdminView.Locations) {
          if (response.locationsData.length && response.initializeLocationFilters) {
            this.locationsData = JSON.parse(JSON.stringify(response.locationsData));
            this.formatDefaultLocationFilters();
            this.store.dispatch(setDefaultLocationFilters({
              locationFilters: JSON.parse(JSON.stringify(this.filters)),
              locationsData: JSON.parse(JSON.stringify(this.locationsData))
            }));
          }
          if (response.addEditedChanges) {
            this.locationsData = JSON.parse(JSON.stringify(response.locationsData));
            this.store.dispatch(addEditedLocations());
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });
  }

  // Create initial filters with default selections
  formatDefaultLocationFilters(): void {
    const allOption: TextFilter = { name: 'all', checked: true, show: true };
    this.filters = {
      locationName: [{ ...allOption}],
      locationCode: [{ ...allOption}],
      exchangeTerminal: [{ ...allOption}]
    };
    this.coordinateFilters.forEach((filter: CoordinateFilter) => {
      filter.filterValue = NaN;
    });
    this.locationsData.forEach((location: LocationData) => {
      location.show = allOption.show;
      this.relatedFilters.forEach((filterName: string) => {
        if (location[filterName as keyof typeof location]) {
          const filterValue: string = String(location[filterName as keyof typeof location]);
          this.filters[filterName as keyof typeof this.filters].push({
            name: filterValue,
            checked: allOption.checked,
            show: allOption.show
          });
        }
      });
    });
    this.initializeToggleFilters(allOption);
  }

  initializeToggleFilters(allOption: TextFilter): void {
    this.toggleFilters.forEach((toggleFilter: string) => {
      this.filters[toggleFilter as keyof typeof this.filters] = [allOption];
      const enabledToggleName: string = 'yes';
      const disabledToggleName: string = 'no';
      const showEnabledOption: boolean = this.locationsData.filter((location: LocationData) =>
        location[toggleFilter as keyof typeof location]
        && location[toggleFilter as keyof typeof location] === true
        && location.show
      ).length > 0;
      const showDisabledOption: boolean = this.locationsData.filter((location: LocationData) => (
        location[toggleFilter as keyof typeof location] === null ||
        location[toggleFilter as keyof typeof location] === undefined ||
        location[toggleFilter as keyof typeof location] === false)
        && location.show
      ).length > 0;
      this.filters[toggleFilter as keyof typeof this.filters].push({ name: enabledToggleName, checked: allOption.checked, show: showEnabledOption});
      this.filters[toggleFilter as keyof typeof this.filters].push({ name: disabledToggleName, checked: allOption.checked, show: showDisabledOption});
    });
  }

  filterLocations(): void {
    this.filterLocationsTable();
    this.store.dispatch(updateLocationFilters({
      locationFilters: JSON.parse(JSON.stringify(this.filters)),
      locationsData: JSON.parse(JSON.stringify(this.locationsData))
    }));
  }

  applyCheckboxFilters(filterName: string, filterItem: any, index: number): void {
    this.filters[filterName as keyof typeof this.filters][index].checked = filterItem.target.checked;
    const relatedFilterGroup: string[] = this.relatedFilters.filter((relatedFilter: string) => relatedFilter !== filterName);
    const isRelatedFilter: boolean = relatedFilterGroup.length < this.relatedFilters.length;
    const foundFilter: TextFilter[] = this.filters[filterName as keyof typeof this.filters];
    if (index !== 0) {
      // Check or uncheck clicked filter if filter option selected is not 'all'
      // Check or uncheck the all option for clicked filter
      const filterItems: TextFilter[] = this.filters[filterName as keyof typeof this.filters].slice(1, this.filters[filterName as keyof typeof this.filters].length);
      const checkAllOption: boolean = filterItems.length === filterItems.filter((filterOption: TextFilter) => filterOption.checked).length;
      foundFilter[0].checked = checkAllOption;
      if (isRelatedFilter) {
        // Check or uncheck for related filter
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          // Check or uncheck the all option for related filter
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter[index].checked = filterItem.target.checked;
          foundRelatedFilter[0].checked = checkAllOption;
        });
      }
    } else {
      // Check or uncheck all filter options for clicked filter
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        // Check or uncheck all filter options for filters that are directly related
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.filters[relatedFilterName as keyof typeof this.filters];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
  }

  applyCoordinateFilters(filterItem: any, index: number): void {
    if (filterItem.target.value !== "")
      this.coordinateFilters[index].filterValue = Number(filterItem.target.value);
    else
      this.coordinateFilters[index].filterValue = NaN;
  }

  filterLocationsTable(): void {
    this.locationsData.forEach((location: LocationData, index: number) => {
      location.show = true;
      const foundFilter: TextFilter = this.filters.locationCode[index+1];
      if (!foundFilter.checked)
        location.show = false;
      this.toggleFilters.forEach((filter: string) => {
        this.filters[filter as keyof typeof this.filters]
        .filter((filterOption: TextFilter) => filterOption.name !== 'all')
        .forEach((filterOption: TextFilter) => {
          const locationDisabled: boolean = location[filter as keyof typeof location] === false || location[filter as keyof typeof location] === null || location[filter as keyof typeof location] === undefined;
          if (((filterOption.name === 'active' || filterOption.name === 'yes') && !filterOption.checked && !locationDisabled)
            || ((filterOption.name === 'inactive' || filterOption.name === 'no') && !filterOption.checked && locationDisabled)
          )
            location.show = false;
        });
      });
      this.coordinateFilters.forEach((filter: CoordinateFilter) => {
          if (Number(location[filter.filterName as keyof typeof location]) < filter.filterValue)
            location.show = false;
      });
    });
  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText = [];
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          if (filter.name.toLowerCase().includes(text.value.toLowerCase()) && filter.name !== 'all') {
            filter.show = true;
          } else {
            filter.show = false;
          }
        });
      } else {
        this.filters[filterName as keyof typeof this.filters].forEach((filter: TextFilter) => {
          filter.show = true;
        });
      }
    });
  }

  clearFilters(): void {
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultLocationFilters();
    this.store.dispatch(setDefaultLocationFilters({
      locationFilters: JSON.parse(JSON.stringify(this.filters)),
      locationsData: JSON.parse(JSON.stringify(this.locationsData))
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
