import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { Utilities } from '../../utilities/utilities';
import { getUserDetails, selectDesk } from '../../store/selector/app.selector';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/reducer/app.reducer';
import { Subject, takeUntil } from 'rxjs';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { InformationPanelService, infoPanelData, panelType } from 'src/app/services/information-panel.service';
import { MarketElasticityService } from 'src/app/services/market-elasticity.service';
import { NominationService } from 'src/app/services/nomination.service';
import { MdmService } from 'src/app/services/mdm.service';
import { invokeTrPipelineNominationList, savePipelineTRFiltersUpdated, updateNomination } from '../../store/action/app.action';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { ToastService } from 'src/app/services/toast.service';

export interface MyCellRendererParams {
  rowDataRecord: any;
  parentRecord: any;
  totalRecords: any;
  isDetailGrid: any;
  isIntankMot: any;
}
@Component({
  selector: 'more-menu-mkt-els-nom',
  templateUrl: './more-menu-mkt-els-nom.component.html',
  styleUrls: ['./more-menu-mkt-els-nom.component.scss']
})
export class MoreMenuMktElsNomComponent {
  public isOpen = false;
  public showUi = false;
  public rowIndex: any;
  public value: any;
  pipelineParentKey: string='';
  pipelineParentObj:any;
  public rowDataRecord: any;
  public desk: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  public params: any;  

  constructor(
    private utilities: Utilities,
    private router: Router,
    private appStore: Store<AppState>,
    private cpService: CargoPlanningService,
    private infoPanelService: InformationPanelService,
    private mktService: MarketElasticityService,
    public nominationService: NominationService,
    private mdmService : MdmService,
    private store: Store,
    private myMonitoringService: MyMonitoringService,
    private toasterService: ToastService,
    private appState: Store<AppState>
    ){ }

  agInit(params: any): void {
    if(params.data){
      this.rowIndex = params.node?.rowIndex;
      this.pipelineParentKey = params.data.uid;
      this.pipelineParentObj = params.data;
      const field = params["colDef"]["field"]
      const prodNum = params["colDef"]["groupId"].split("_")[1];
      const systemStatus = params.data[`systemStatus_${prodNum}_PASADENA`];
      if(params.data[field]) {
        this.showUi = systemStatus === "draft";
        this.value = JSON.parse(JSON.stringify(params.data[field]));
      }
    }
    this.rowDataRecord = params;
    this.params = params;
    this.getUserandDeskDetails() 
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      }
    );

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          // this.isCCAView = this.utilities.checkIfCCAView(response.selectedDesk);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      // this.mktService.nominationParamPSD$.subscribe((value: any) => {
      //   this.params = value;
  
      //   let colId : string = this.params.column.colId;
  
      //   let nomKeyName = colId.replace("nominationNumber","nominationKey");
      //   let nomKeyValue = this.params.data[nomKeyName];
      //   this.params.value = nomKeyValue;  
      // });
  }

  moreMenu() {
    this.isOpen = !this.isOpen;
  }
  
 async editNomination(){
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    pipelineNomination["screenName"] = 'side-panel-tr';    
    pipelineNomination["editNomination"] = true;
    this.mktService.setOpenNominationPSD(true);
    
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editPipelineNominationFromME',
      type: '',
      values: pipelineNomination
    }; 
    
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  async delete(){
    let action = 'delete';
    this.nominationService.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true); 
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
      if(actionConfirmed){
        if(action !== ''){
          this.deleteAfterConfirm();
          this.mdmService.actionConfirm(false);
          this.mdmService.invokeReplenishmentToggleMethod(false); 
          action = '';
        }      
      }
    });
  }

  async deleteAfterConfirm() {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
    //this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);
    const nominationDetails: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    const reqPayload = [];
    const colDataField = this.params.colDef.field;
   
    const deletedId = this.params.data[colDataField.replace("whatifShipment","udmId")];
    const deletedItemId = this.params.data[colDataField.replace("whatifShipment","lineItemId")];
    const deletedUdmItemStatus = this.params.data[colDataField.replace("whatifShipment","systemStatus")];
    const deletedItemNomKey = this.params.data[colDataField.replace("whatifShipment","nomkey")];

    let requestBody =
    {
      id: deletedId,
      modeOfTransportDesc: nominationDetails.modeOfTransportDesc,
      deskFilter: "pipelinedesk",
      sys_status: deletedItemNomKey ? "InProcess" : nominationDetails.sys_status,
      headerToItemNav: [{
        itemId: deletedItemId,
        udmItemStatus: deletedItemNomKey ? "InProcess" : deletedUdmItemStatus,
        deletionIndicator: "X",
        textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
      }]
    };
    // Checking if we delete from whatif column and not any nom column
    if (!deletedItemId) {
      requestBody.headerToItemNav = [];
      nominationDetails.headerToItemNav.forEach((elem: any) => {
        elem.forEach((ele: any) => {
          const obj = {
            itemId: ele.itemId,
            udmItemStatus: ele.sys_status,
            deletionIndicator: "X",
            textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
          }
          requestBody.headerToItemNav.push(obj);
        })
      })
    }
    reqPayload.push(requestBody);
    this.store.dispatch(updateNomination({ payload: reqPayload, publishOrDeleteNominationApiResponse: {} }));
    this.myMonitoringService.logEvent(`Deleted clean products nomination`, { category: "Market Elasticity" })
    this.toasterService.setToastNotification({
      show: true,
      type: 'success',
      msg: "Nomination has been deleted",
    });
    setTimeout(() => {
      // if(this.desk?.includes('clean products + latam')){
      //   this.store.dispatch(savePipelineTRFiltersUpdated({ isPipelineFilterUpdated: true }))
      // } else {
      //   //this.getTerminalNominationAF(this.payLoad);
      // }
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'deletePipelineNomination',
        type: '',
      }
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }, 3000)
  }

  getTerminalNominationAF(payload: any) {
    this.utilities.getPipelineNominationMasterData(payload)
    payload.screenName = this.router.url === '/MarketElasticity' ? 'TR' : undefined,
    this.appState.dispatch(invokeTrPipelineNominationList({ payload }));
  }

  openActivityTracker(){
    let valueToPass: infoPanelData = {
      name: this.pipelineParentObj.voyageName,
      date: this.pipelineParentObj.loadLaycanStartDate, 
      id: this.pipelineParentObj.id,
      type: panelType.CP,
      data: this.pipelineParentObj,
    }

    this.infoPanelService.setPanelData(valueToPass);
    this.infoPanelService.undockInfoPanel(true);
  }

  openSidePanel(){
    this.infoPanelService.showInfoPanel(true);
  }
}
