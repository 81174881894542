import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DismissAlertService } from 'src/app/services/dismiss-alert.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertRuleResponse } from 'src/app/shared/interface/settings.interface';


@Component({
  selector: 'app-dismiss-workspace-alert',
  templateUrl: './dismiss-workspace-alert.component.html',
  styleUrls: ['./dismiss-workspace-alert.component.scss']
})
export class DismissWorkspaceAlertComponent implements OnInit {

  @Output() closeModalPopup = new EventEmitter();

  alertTitle: string = '';
  alertToDismiss!: AlertRuleResponse;


  constructor(private dismissService: DismissAlertService, private alertService: AlertsService) { }


  ngOnInit(): void {

    let importantInformation = this.dismissService.getAlertInformation();
    this.alertTitle = importantInformation.alertName;
    this.alertToDismiss = importantInformation.alertToDismiss;


  }

  closePopup(){
    this.dismissService.setModalShow(false);
  }

  dismissPopup(){
    this.dismissService.dismissCurrentCard();
    this.dismissAlert();        
    this.dismissService.setModalShow(false);
  }


  dismissAlert(){
    let dismissPayload = {
      id: this.alertToDismiss.id,
      user: this.alertToDismiss.user,
      isActive: false,
    }     
    this.alertService.dismissWorkspaceAlert(dismissPayload).subscribe(response => true); 
  }
}
