import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, ITooltipParams } from 'ag-grid-community';
import { NomTooltipComponent } from '../nom-tooltip/nom-tooltip.component';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-terminal-view',
  templateUrl: './terminal-view.component.html',
  styleUrls: ['./terminal-view.component.scss']
})
export class TerminalViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }


  public gridApi!: GridApi; 
  gridColumnApi!: ColumnApi;
  onGridReadyParams!: GridReadyEvent<any, any>;

  // Row Data: The data to be displayed.
  rowData: any[] = [
    { sType: 'D', lineKey: '23', status: '3', qty: '44', scheduledDate: '08/12/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: '', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas', valTypeOriginLocation: 'Vegas' },
    { sType: 'X', lineKey: '26', status: '4', qty: '55', scheduledDate: '04/11/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas', valTypeOriginLocation: 'New York' },
    { sType: 'O', lineKey: '10', status: '0', qty: '10', scheduledDate: '02/06/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: '', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas', valTypeOriginLocation: 'Washington DC' },
    { sType: 'X', lineKey: '8', status: '1', qty: '65', scheduledDate: '07/03/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas', valTypeOriginLocation: 'New York' },
    { sType: 'X', lineKey: '99', status: '2', qty: '70', scheduledDate: '01/02/24', arriveTime: '11:30', scheduledProduct: 'SUBO RU C', demandProduct: 'SUBO RU C', description: 'Some desc', rdi: 'x', sapContract: 'contractor', contractLineNo: '1232', batchOriginLocation: 'Vegas', valTypeOriginLocation: 'Austin' }
  ];

  // Column Definitions: Defines & controls grid columns.
  colDefs: ColDef<any>[] = [
    {
      headerName: '',
      field: 'description',
      cellRenderer: NomTooltipComponent,
      tooltipValueGetter: (p: ITooltipParams) =>
        p.value,
      minWidth: 90
    },
    { field: 'sType' },
    { field: 'lineKey' },
    { field: 'status' },
    { field: 'qty' },
    { field: 'scheduledDate' },
    { field: 'arriveTime' },
    { field: 'scheduledProduct' },
    { field: 'demandProduct' },
    { field: 'description' },
    { field: 'rdi' },
    { field: 'sapContract' },
    { field: 'contractLineNo' },
    { field: 'batchOriginLocation', minWidth: 150 },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    wrapHeaderText: true,
    minWidth: 120
  };

  addNewRow() {
    const newRow = { 
      sType: '', 
      lineKey: '', 
      status: '', 
      qty: '', 
      scheduledDate: '', 
      arriveTime: '', 
      scheduledProduct: '', 
      demandProduct: '', 
      description: '', 
      rdi: '', 
      sapContract: '', 
      contractLineNo: '', 
      batchOriginLocation: '', 
      valTypeOriginLocation: '' };

      this.rowData.push(newRow);
      this.gridApi.setRowData(this.rowData)
  }

  onGridReady(params: GridReadyEvent) {
    // this.onGridReadyParams = params
    // this.gridApi = params.api; 
    // this.gridColumnApi = params.columnApi;
  }

}