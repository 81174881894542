import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastObj } from '../shared/interface/interface';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastMsgObj$ = new BehaviorSubject<ToastObj>({
    show: false,
    type: '',
    msg: ''
  });
  checkToastNotification$ = this.toastMsgObj$.asObservable();

  setToastNotification(obj: ToastObj) {
    this.toastMsgObj$.next(obj);
  }
}
