import { Component, Input } from '@angular/core';
import { NominationService } from 'src/app/services/nomination.service';

@Component({
  selector: 'app-custom-view-side-panel',
  templateUrl: './custom-view-side-panel.component.html',
  styleUrls: ['./custom-view-side-panel.component.scss']
})
export class CustomViewSidePanelComponent {

  @Input() desk: any;
  @Input() selectedCustomView: any;
  @Input() updatedDesk!: any;
  @Input() deskParam!: any;
  public isCreateViewsAccordianExpanded: boolean = false;
  public isManageViewsAccordianExpanded: boolean = false;
  public customViewDetails: any = {};

  constructor(public nominationService: NominationService) { }


  onCloseCustomViewSidePanel() {
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
  }

  customViewDetail(event: any) {
    this.customViewDetails = { emailId: event.emailId, viewName: event.viewName};
  }

  customViewAccordion(event: any) {
    if (event.target.innerText === "create new custom view" || event.target.classList.contains("trigger-new-custom")) {
      this.nominationService.createCustomView$.next(!this.nominationService.createCustomView$.value);
      this.nominationService.manageCustomView$.next(false);
    } else {
      this.nominationService.manageCustomView$.next(!this.nominationService.manageCustomView$.value);
      this.nominationService.createCustomView$.next(false);
    }
  }

}
