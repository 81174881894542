import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { panelType } from './information-panel.service';
import { CommentI, commentType } from '../shared/interface/comment.interface';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  commentListUpdate: BehaviorSubject<behaviorComment | null> = new BehaviorSubject<behaviorComment | null>(null);



  constructor(private apiService: ApiService) { }

  //Get all comments for a given nomination/cargo plan ID - used in information panel 
  //use both id and type to ensure no GUID collision is possible
  getCommentsById(id: string, type: string){

    return this.apiService
      .get(
        `${environment.commentAPI}/GetCommentsById?id=${id}&type=${type}`,
        'comments'
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }


  createComment(payload: any){

    return this.apiService
      .post(
        `${environment.commentAPI}CreateComment`,
        'comments',
        payload
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }


  

  deleteComment(id: string, type: string, isActive: boolean){

    return this.apiService
      .trueDelete(
        `${environment.commentAPI}DeleteComment?id=${id}&type=${type}&isActive=${isActive}`,
        'comments',
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  updateComment(payload: any){

    return this.apiService
      .put(
        `${environment.commentAPI}UpdateComment`,
        'comments',
        payload
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }


  getCommentTypeFromPanelType(panelType: panelType): commentType{

    if(panelType == "cargoPlan"){
      return commentType.Car;
    }else if(panelType == "nomination"){
      return commentType.NOM;
    }else{
      return commentType.UND;
    }
  }

  generateTagList(tagList: string[]): {volume: boolean, decisionCapture: boolean, date: boolean, general: boolean} {

    return {
      volume: tagList.includes("Volume"),
      decisionCapture: tagList.includes("Decision Capture"),
      general: tagList.includes("General"),
      date: tagList.includes("Date"),
    }
  }



}


export interface behaviorComment{

  behaviorType: CommentBehaviorType,
  comment: CommentI,
}

export enum CommentBehaviorType{
  DEL = "delete",
  ADD = "add",
}