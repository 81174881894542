<div>
    <div class="form-row pb-1 pr-2">
        &nbsp;
            <span class="label-3">if</span> &nbsp;
            <span class="label-4">actual demand</span> &nbsp;
            <span class="label-3">in the next</span> &nbsp;
        
        <div class="form-group col-md-4">
            <select class="form-control"  [(ngModel)]="selectedNoOfDays" (change)="shareDemandDetails()">
                <option value="" disabled selected>amount of days</option>
                <option *ngFor="let days of amountOfDaysDropdown">
                    {{days}}</option>
            </select>
        </div>
        <span class="label-3"> days is</span> &nbsp;

        <div class="form-group col-md-3">
            <select class="form-control" [(ngModel)]="selectedRules" (change)="shareDemandDetails()">
                <option value="" disabled selected>rule</option>
                <option *ngFor="let rule of ruleDropdown">
                    {{rule}}</option>
            </select>
        </div>
        
        <div class="form-group col-md-4">
            <input type="number" class="form-control" placeholder="enter threshold" [(ngModel)]="selectedPercent" (change)="checkThresholdBoundaries()">
        </div>

    </div>
    <span *ngIf="badThreshold" class="label-4r">threshold is out of bounds, please set to a value between 1% to 99%</span>
</div>
