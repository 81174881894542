import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appCloseDropdownDircetive]',
  standalone: true
})
export class CloseDropdownDirective {

  @Output() clickedOutSide = new EventEmitter<any>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click',['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if(!clickedInside){
      this.clickedOutSide.emit(targetElement);
    }

  }

}
