import { Action, createReducer, on } from "@ngrx/store";
import { BCPFilters, BCPRefData, BCPSummaryData, BCPView } from "src/app/shared/interface/bcp-dashboard.interface";
import { loadBCPRefDataAPISuccess, loadBcpDataSuccess, saveBCPView, setDefaultBCPFilters, updateBCPFilters, updateBCPSummaryTable } from "../action/bcp-dashboard.action";

export interface State {
    selectedBCPView: string;
    BCPSummaryData: BCPSummaryData[];
    BCPRefDataResponse: BCPRefData[];
    BCPFilters: BCPFilters;
    updateBCPSummaryTable: boolean;
}

export const initialState: State = {
    selectedBCPView: BCPView.Summary,
    BCPSummaryData: [],
    BCPRefDataResponse: [],
    BCPFilters: { locationsFilter: [], productsFilter: [],desksFilter:[], offTakers:[] },
    updateBCPSummaryTable: false
   
};

const bcpDashboardReducer = createReducer(
    initialState,
    on(loadBcpDataSuccess, (state, { bcpDashboardData }) => ({
        ...state,
        ...bcpDashboardData,
    })),
    on(saveBCPView, (state, { selectedBCPView }) => ({
        ...state,
        selectedBCPView,
    })),
    on(loadBCPRefDataAPISuccess, (state, { BCPRefDataResponse }) => ({
      ...state,
      BCPRefDataResponse: BCPRefDataResponse
    })),
    on(setDefaultBCPFilters, (state, { BCPFilters ,updateBCPSummaryTable}) => ({
      ...state,
      BCPFilters: BCPFilters,
      updateBCPSummaryTable: updateBCPSummaryTable
    })),
    on(updateBCPFilters, (state, { BCPFilters }) => ({
      ...state,
      BCPFilters: BCPFilters,
      updateBCPSummaryTable: true
    })),    
    on(updateBCPSummaryTable, (state) => ({
      ...state,
      updateBCPSummaryTable: false
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return bcpDashboardReducer(state, action);
}

export const getSelectedBCPView = (state: State) => ({ selectedBCPView: state.selectedBCPView });

export const getBCPSummaryData = (state: State) => ({ BCPSummaryData: state.BCPSummaryData });

export const getBCPRefDataResponse = (state: State) => ({ BCPRefDataResponse: state.BCPRefDataResponse });

export const getBCPFilters = (state: State) => ({ BCPFilters: state.BCPFilters, updateBCPSummaryTable: state.updateBCPSummaryTable });
