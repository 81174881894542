import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { InformationPanelService } from 'src/app/services/information-panel.service';

import { PopoutWindowComponent } from 'angular-popout-window';
import { tabEnum } from './info-content/info-content.component';

@Component({
  selector: 'information-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss'],
})
export class InformationPanelComponent implements OnInit, OnChanges {
  @ViewChild('infoPanelPopout') infoPanelPopout!: PopoutWindowComponent;
  showUndockButton: boolean = false;
  selectedTabIndex: number = 0;
  selectedSelector: tabEnum = tabEnum.DET;
  headers: any = [
    { displayValue: 'details', selector: tabEnum.DET },
    { displayValue: 'notifications', selector: tabEnum.ALRT },
    { displayValue: 'comments', selector: tabEnum.COM },
    { displayValue: 'activity tracker', selector: tabEnum.AT },
  ];
  constructor(public infoPanelService: InformationPanelService) {}

  ngOnInit(): void {
    this.infoPanelService.infoPanelUndocked.subscribe((isDocked) => {
      if (this.infoPanelPopout != null) {
        if (isDocked) {
          this.infoPanelPopout.windowTitle = 'Information Panel - UDM';
          this.infoPanelPopout.windowWidth = screen.width - screen.width * 0.2;
          this.infoPanelPopout.windowHeight =
            screen.height - screen.height * 0.2;
          this.infoPanelPopout.popOut();
          this.showUndockButton = true;
        } else {
          this.infoPanelPopout.popIn();
          this.showUndockButton = false;
        }
      }
    });

    this.infoPanelService.infoPanelSelectedTab.subscribe((tab) => { 
      this.selectedTabIndex = tab;
      this.selectedSelector = this.headers[tab].selector;
    }); 

    this.infoPanelService.infoPanelSelectedSelector.subscribe((selector) => {
      this.selectedSelector = selector;
      this.selectedTabIndex = this.headers.findIndex((header: any) => {
        return header.selector == selector;
      });
    });
  }

  ngOnChanges(): void {
    this.infoPanelService.infoPanelSelectedTab.subscribe((tab) => { 
      this.selectedTabIndex = tab;
      this.selectedSelector = this.headers[tab].selector;
    }); 

    this.infoPanelService.infoPanelSelectedSelector.subscribe((selector) => {
      this.selectedSelector = selector;
      this.selectedTabIndex = this.headers.findIndex((header: any) => {
        return header.selector == selector;
      });
    });
  }
}
