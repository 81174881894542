<p class="header">{{cardValues.headerValue}}</p>
<div class="table-responsive" *ngIf="cardValues.tableValues.length != 0">
    <table class="table table-sm">
        <thead>
            <tr style="border-bottom: 1px solid #000;">
                <th resizable>New {{cardValues.headerValue}}</th>
                <th resizable>Original {{cardValues.headerValue}}</th>
                <th resizable>Changed By</th>
                <th resizable>Changed On</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of this.cardValues.tableValues" class="cell-style">
                <td class="cell-value p-0 pl-3">{{row.newValue}}</td>
                <td class="cell-value p-0 pl-3">{{row.oldValue}}</td>
                <td class="cell-value p-0 pl-3">{{row.changedBy}}</td>
                <td class="cell-value p-0 pl-3">{{row.changedAt}}</td>
            </tr>
        </tbody>
    </table>
</div>