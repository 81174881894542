<div class="d-flex flex-row align-items-center m-2">
    <div class="initals-avatar mr-2">
        <p class="initals-avatar m-0"> {{initials}}</p>
    </div>
    <div class="name mr-4">
        {{userName}}
    </div>
    <div class="date">
        {{dateToShow}}        
    </div>
</div>