import { Component, OnInit ,  ViewChild} from '@angular/core';
import { savePipelineNomFiltersResponse, savePipelineNomSearchResponse, searchNomActionResponse, setSaveMdmSearchResponse, setdeskIDSearchedResponse } from '../../../shared/store/action/app.action';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { CellClickedEvent, CellValueChangedEvent, ColDef, GetMainMenuItemsParams, GridApi, GridReadyEvent, ICellRendererParams, IDetailCellRendererParams, ISelectCellEditorParams, IsRowMaster, FirstDataRenderedEvent, IRowNode, ColumnApi, IAggFunc, IAggFuncParams, RowGroupingDisplayType } from 'ag-grid-community';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { CreatePipelineNominationComponent } from 'src/app/core/components/create-pipeline-nomination/create-pipeline-nomination.component';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectPipelineNomFiltersResponse, selectUDMScreen, searchNomResponse } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ToastService } from 'src/app/services/toast.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { VesselScheduleUpdateComponent } from 'src/app/core/components/vessel-schedule-update/vessel-schedule-update.component';
import { UDMDesk, deskLink, cpSpecific, region, deskName } from 'src/app/shared/constants/terminal-replenishment.constant';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CellInfoHoverComponent } from 'src/app/shared/modules/cell-info-hover/cell-info-hover.component';
import { MdmService } from 'src/app/services/mdm.service';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { AutoCompleteComponent } from 'src/app/nominations/autocomplete/autocomplete.component';
import * as Nom from '../../../shared/constants/nominations.constant'
import { MoreMenuSearchComponent } from 'src/app/shared/modules/more-menu-search/more-menu-search.component';

@Component({
  selector: 'app-nomination-global',
  templateUrl: './nomination-global.component.html',
  styleUrls: ['./nomination-global.component.scss']
})
export class NominationGlobalComponent implements OnInit {

  
  private gridApi!: GridApi<any>;
  frameworkComponents;
  destroy$: Subject<boolean> = new Subject<boolean>();
  gridColumnApi!: ColumnApi;
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  searchNomResponse$ = this.store.pipe(select(searchNomResponse));
  selectedDeskOption!: any;
  saveOriginalDesk!: any;
  searchSelectedDesk!: any
  getPipelineFilterResponse!: any;
  terminalFilterData!: any[];
  productFilterData!: any[];
  transportSystemFilterData!: any[];
  public selectedRows: any[] = [];
  updatePayload: any[] = [];
  headerToItemNav: any[] = [];
  currentParentRow!: any;
  detailHeaderToItemNav: any[] = [];
  detailHeaderToItemNavHeader: any[] = [];
  public rowData!: any[];
  public locCode: string = ''; 
  createNominationPipelineModal = { open: false, modal: 'createPipelineNomination' };
  headerPayload: any[] = [];
  detailPayload: any[] = [];
  warningPopup: boolean = false;
  // vendorNotificationPreview = { open: false, modal: 'createPreviewForNomination' }
  @ViewChild(CreatePipelineNominationComponent) createPipelineNominationComponent!: CreatePipelineNominationComponent;
  @ViewChild(VesselScheduleUpdateComponent) pipelineVesselScheduleUpdate!: VesselScheduleUpdateComponent;
  pipelineNominationModal = { open: false, modal: 'createPipelineNomination' }
  vesselScheduleUpdateModal = { open: false, modal: 'pipelineVesselScheduleUpdate' }
  masterGridSelectedRows: any[] = [];
  detailGridSeectedRows: any[] = [];
  copyRowData: any[] = [];
  parentGridIndex: any;
  mainGridIndex!: number;
  modeOfTransport!: any[];
  public bblTrucks: boolean = false;
  public bblTrucks3: boolean = false;
  public materialCode: any[] = [];
  public locationCodeNonCp: any[] = [];
  getonRowSelect: any = [];
  suppliers: any = [];
  sapContracts: any[] = [];
  currentlyActiveLineRow: any;
  differentHeaderId: any;
  nomType: any = [];
  SAPContractPopup: boolean = false;
  userEmail: any;
  userFullEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  allowForGetCustomView: boolean = false;
  motType: any;
  goToNominationId: any;
  goToNominationNumber:any
  currentScreen: any;
  region: any;
  selectedDeskParameter: any;
  isPipelineNomEdit: boolean = false;
  modalTitle = "creating new pipeline nomination";
  secondLevelGridColumnDef: any = [];
  deskSelectedNom: any;
 isCargo:any=false; 
  dummySecondLevelGridColumnDef: any = [];
  updateUdmCargoPascagoulaData: any = {};
  updateUdmCargoPascagoulaDataChildLevel: any = {};
  applyColOrderExpansionParameters: any = {};
  globalRes: any;
  isDeletecalled: boolean = false
  constructor(private appStore: Store<AppState>, private nominationService: NominationService, private store: Store, private router: Router, private utilities: Utilities, private serv: NominationService, private datePipe: DatePipe, private toaterService: ToastService, private ethanolService: EthanolTruckService, private cpService: CargoPlanningService, private loaderService: LoaderService, private myMonitoringService: MyMonitoringService,     private mdmService: MdmService, private replenishmentService: ReplenishmentPlanningService
    ) {
    this.frameworkComponents = { datePicker: DatePickerComponent };
  }

  ngOnInit(): void {
    this.columnDefs.forEach((columnDef: any) => {
      if (columnDef.editable) {
        columnDef.cellClass = 'highlightEditableCell';
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.goToNominationId = null
        this.goToNominationNumber = null;
      }
    })

    this.nominationService.triggerDeselectForCargo.subscribe(res => {
      if(res){
        this.gridApi?.deselectAll();
      }
    })
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
            this.userFullEmail = response.userDetails.email;
            localStorage.setItem("userEmail", this.userFullEmail);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(this.selectedDeskOption !== response.selectedDesk){
            this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Nominations');
          }
          this.selectedDeskOption = response.selectedDesk;
          

          this.selectedDeskParameter = response.selectedDeskParam;
          this.isCargo = ( this.selectedDeskOption?.toLowerCase() === UDMDesk.PipelineUsecFloridaDesk  ||  this.selectedDeskOption?.toLowerCase() === UDMDesk.PipelineUswcPNWDesk || this.selectedDeskOption === UDMDesk.PipelineUsecRichmondDesk || this.selectedDeskOption === UDMDesk.PipelineUsecPascagoulaDesk);
          
            this.changeHeaderNamesNonCP();
          
          this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
          this.updateModalTitle();
        },
        error: (err: any) => { },
      });
    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineNomFilterUpdated && !this.nominationService?.isCargoScreen?.value) {
          this.getPipelineFilterResponse = response;
          const productFilterArray = this.getPipelineFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "product");
          this.materialCode = productFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
          })
          const locationFilterArray = this.getPipelineFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
          this.locationCodeNonCp = locationFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
         })
          this.cpService.isCargoScreen = true;
        }
      },
      error: (err: any) => { },
      complete: () => { }

    });
    this.utilities.sendActionToChild$.subscribe(action => {
      if (action === 'expand') {
        this.gridApi.forEachNode(index => {
          index.setExpanded(true);
        })
      }
      else {
        this.gridApi.forEachNode(index => {
          index.setExpanded(false);
        })
      }
    });

    this.nominationService.applyEditedViewToUi$.subscribe(res => {
      if (res && this.router.url === '/nominations' && localStorage.getItem("selectedView")) {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    });

    this.nominationService.applyCustomView$.subscribe(res => {
      if (res && localStorage.getItem("selectedView") && this.router.url === '/nominations') {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    })

    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
      if (response.function === 'createPipelineNomination') {
        this.isPipelineNomEdit = false;
        this.toggleCreatePipelineNominationsModal();
      }
      else if (response.function === 'pipelineCreateNominationWithReference') {
        this.isPipelineNomEdit = false;
        this.createNominationWithReferenceforSearch(response.values)
      }
      else if(response.function === 'pipelineVesselScheduleUpdate'){
        this.updateVesselScheduleDate(response)
      } else if(response.function === 'onCreatePipelineNominationFromSearch'){
        if(response?.values?.headerToItemNav.length){
          this.ethanolService.modalPopoOpen$.next(true);
          this.createPipelineNominationComponent.trNominationForPipeline = response.values;
          const modal = { open: true, modal: 'createPipelineNomination' };
          this.isPipelineNomEdit = response.values?.editNomination;
          this.pipelineNominationModal = { ...modal };
          this.createPipelineNominationComponent.presetNominationDataForPipelineSearch();
        }
    }
    else if(response.function === 'editPopupNomNo'){
      this.isPipelineNomEdit = true;
      this.createNominationWithReferenceforSearch(response);
    } else if(response.function === 'pipelineCreateNominationWithReferenceNonCPSearch'){
      this.isPipelineNomEdit = false;
      this.createNominationWithReferenceforSearch(response);
    }
      else if(response.function === 'pipelineCreateNominationWithReferenceSearch'){
        this.isPipelineNomEdit = false;
        this.createNominationWithReferenceforSearchFromHome(response);
      }
    })

    this.nominationService.goToNominationData$.subscribe((value: any) => {
      if (Object.keys(value).length && value?.isTrue) {
        const fieldType = value?.data?.colDef?.field;
        let fieldDataName = fieldType?.split('_')[3] ? fieldType?.split('_').slice(0, 3).join('_') : fieldType;
        const getId = fieldDataName ? value?.data?.data[`${fieldDataName}_id_Child`] : value?.data?.id;
        this.goToNominationId = getId;
        this.goToNominationNumber = fieldDataName? value?.data?.data[`${fieldDataName}`]: value?.data?.nominationNo;
      }
    })

    this.nominationService.applyTrCoulmnOrder$.subscribe(res => {
      this.callColOrderAndExpansion(res);
    })
    this.nominationService.resetToDefaultView$.subscribe(res => {
      if (res) {
        this.gridColumnApi?.resetColumnState();
      }
    });
    this.callGetViewByNameMethod();
    this.searchNomResponse$
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: any) => {
            this.globalRes = response;
          },
          error: (err: any) => {
            console.log(err);
          },
          complete: () => { },
        });
    this.nominationService.getSearchNominationAFAPI$.subscribe(res => {
      if (res) {
        // this.nominationService.getSearchNominationAFAPI$.next(false)
        if (this.globalRes.isPipelineNomUpdated) {
          this.appStore.dispatch(searchNomActionResponse({ searchPayload: this.globalRes.searchPayload, isPipelineNomUpdated: false }));
          this.getGlobalNom(this.globalRes);
          return 
        }
      }
    })

  }
  updateModalTitle(){
    if(this.selectedDeskParameter?.toLowerCase() === 'floridadesk'){
      this.modalTitle = "create new nomination";
    }
    else{
      this.modalTitle = "creating new pipeline nomination";
    }
  }

  callGetViewByNameMethod() {
    let selectedView = localStorage.getItem("selectedView");
    if (selectedView) {
      this.getCustomViewByName(selectedView);
    }
  }

  getCustomViewByName(res: any) {
    let payload = { emailId: this.userFullEmail, viewName: res, desk: this.utilities.updateAPIParams() };
    this.nominationService.applyView(payload).subscribe(res => {
      if (res.statusCode === 200) {
        this.formPayloadForFilters(res.value)
      }
    })
  }

  formPayloadForFilters(res: any) {
    let filterList: any[] = [];
    if (this.selectedDeskOption.startsWith("clean products") || this.selectedDeskOption.startsWith("pipeline")) {
      this.customViewPipelineFiltersApply(res, filterList);
    }
    this.nominationService.updatedFIlterValuesFromView$.next(filterList);
  }

  customViewPipelineFiltersApply(res: any, filterList: any) {
    let sDate, eDate;
    const { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = res.customViews[0].pipelineFilters;
    if (res.customViews[0].pipelineFilters.modeOfTransport && (this.selectedDeskOption === UDMDesk?.PipelineUsecFloridaDesk || this.selectedDeskOption === UDMDesk?.PipelineUsecPascagoulaDesk || this.selectedDeskOption === UDMDesk?.PipelineUsecRichmondDesk || this.selectedDeskOption === UDMDesk?.PipelineUswcPNWDesk)) {
      filterList.push({ headerName: "mode of transport", list: [...modeOfTransport] });
    }
    if (res.customViews[0].pipelineFilters.transportSystemStatus) {
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }
    else {
      transportSystem.forEach((transportSystem: any) => {
        transportSystem.checked = true;
      });
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }

    if (res.customViews[0].pipelineFilters.terminalStatus) {
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }
    else {
      terminal.forEach((terminal: any) => {
        terminal.checked = true;
      });
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }

    if (res.customViews[0].pipelineFilters.productStatus) {
      filterList.push({ headerName: "product", list: [...product] });
    }
    else {
      product.forEach((product: any) => {
        product.checked = true;
      });
      filterList.push({ headerName: "product", list: [...product] });
    }
    sDate = `${fromDate?.substring(0, 4)}-${fromDate?.substring(4, 6)}-${fromDate?.substring(6)}`;
    eDate = `${toDate?.substring(0, 4)}-${toDate?.substring(4, 6)}-${toDate?.substring(6)}`;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.nomDefaultSetCustomView = { pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }
    this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));
    this.applyColOrderExpansionParameters = {
      colOrder: res.customViews[0].pipelineFilters?.columnOrders,
      colExpand: res.customViews[0].pipelineFilters?.expandedColumns
    }
  }

  public isRowMaster: IsRowMaster = (dataItem: any) => {
    if (dataItem?.headerToItemNav?.length > 1) {
      return true;
    } else if (dataItem?.headerToItemNav?.length === 1 && dataItem?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    const nodesToSelect: IRowNode[] = [];
    params.api.forEachNode((node: IRowNode) => {
      if (node?.data?.id === localStorage.getItem('nomId')) {
        params.api.getRowNode(`${node.id}`)?.setSelected(true)
        params.api.ensureIndexVisible(Number(node.id), 'top')
        localStorage.setItem('nomId', '')
      }
      if (node?.data?.id === this.goToNominationId) {
        this.nominationService.goToNominationData$.next({ isTrue: false, data: null })
        params.api.getRowNode(`${node.id}`)?.setSelected(true)
        params.api.ensureIndexVisible(Number(node.id), 'top')
      }
    });
  }

  onFirstDataRenderedNonCP(params: FirstDataRenderedEvent) {
    let rowFound = false;
    params.api.forEachNode((node: IRowNode) => {
      if (node?.key === this.goToNominationNumber && !rowFound) {
        this.nominationService.goToNominationData$.next({ isTrue: false, data: null })
        params.api.getRowNode(`${node.id}`)?.setSelected(true)
        params.api.ensureIndexVisible(Number(node.rowIndex), 'top')
        rowFound = true;
      }
    });
  }

  onRowSelectNonCp(event: any) { 
    let isSearchTab:boolean = false
    this.nominationService.getSearchNominationAFAPI$.subscribe((data:any)=>{
      isSearchTab = data; 
    }   
    )
    if (event.node.uiLevel === 1 || (isSearchTab && event.node.uiLevel === 0)) {
      this.masterGridSelectedRows = []
      this.selectedRows = []
      const selectedRowsFromChildrenNode = event?.node?.allLeafChildren?.reduce((acc: any, curr: any) => {
        return [...acc, curr.data]
      }, []);

      this.selectChildrenFn(selectedRowsFromChildrenNode, event);
    }
    else{
      this.serv.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });

    }
  }
  manuallyPush(event: any){
    // As event.api.getSelectedRows() wont work for grouped rows, manually pushing and removing
    if (event?.event?.target?.checked || event?.source?.toLowerCase() === 'rowclicked' || event?.source?.toLowerCase() === 'api') {
      this.masterGridSelectedRows?.push(...this.selectedRows)
    } else {
      this.selectedRows?.forEach((data: any, index: any) => {
        this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c: any) => c.itemId !== data.itemId);
      })
    }
   
  }
selectChildrenFn(selectedRowsFromChildrenNode: any, event: any){
  if (selectedRowsFromChildrenNode) {
    this.selectedRows = selectedRowsFromChildrenNode;

    // Adding udmNominationGrouping flag for selected rows
    this.selectedRows?.forEach((obj: any) => {
      obj["udmNominationGrouping"] = false;
      obj["isNonCPDesk"] = true;
    })
    this.serv.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    this.serv.nomSelected$.next(false);
    this.serv.selectedNom$.next(this.selectedRows);

    this.manuallyPush(event)
    // Retrieve unique nomination number from this.masterGridSelectedRows
   this.uniqueNomNo()
  }
}
uniqueNomNo(){
  const uniqueNominationNo = [...new Set(this.masterGridSelectedRows.map((a: any) => a.nominationNo))];
  if (uniqueNominationNo?.length === 1) {
    this.serv.nomSelected$.next(true);
  }
  const unPublishedNomArray: string[] = [];
  this.selectedRows?.forEach((data: any) => {
    if ((data?.headerToItemNav?.length > 0 && data.headerToItemNav?.every((status: any) => status?.udmItemStatus?.toLowerCase() === 'published') || (data?.headerToItemNav?.length === 0 && data?.sys_status?.toLowerCase() === 'published'))) {
      unPublishedNomArray.push("published")
    }
    else {
      unPublishedNomArray.push("unpublished")
    }
  })
  if (this.selectedRows?.length > 0) {
    this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: unPublishedNomArray?.every((status: any) => status === 'published') });
  } else {
    this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
  }
}
  onRowSelect(event: any) {
    this.isSameSupplierSelected()
  }

  isSameSupplierSelected() {
    this.suppliers = this.gridApi.getSelectedRows().map((item: { supplier: any; }) => item.supplier)
    this.motType = this.gridApi.getSelectedRows().map(item => item.modeOfTransportDesc);
    this.nomType = this.gridApi.getSelectedRows().map((item => item.headerToItemNav?.every((lineItem: { udmItemStatus: string; }) => lineItem.udmItemStatus?.toLowerCase() === 'published')));
    this.suppliers = [...new Set(this.suppliers)];
    this.nomType = [... new Set(this.nomType)];
    this.motType = [... new Set(this.motType)];
    if (this.suppliers.length > 1) {
      this.nominationService.isSameRowSelected$.next(false);
    }
    else {
      this.nominationService.isSameRowSelected$.next(true)
    }
    if (this.nomType[0] || this.motType.length === 1 && this.motType[0]?.toLowerCase() === 'truck') {
      this.nominationService.isSelectedPublished$.next(true);
    }
    else {
      this.nominationService.isSelectedPublished$.next(false);
    }
  }

  setTerminalFilterData(terminalFilterArray: any) {
    if (terminalFilterArray?.length > 0) {
      terminalFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.terminalFilterData.push(dt?.id);
        }
      });
    }
  }
  setProductFilterData(productFilterArray: any) {
    if (productFilterArray?.length > 0) {
      productFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.productFilterData.push(dt?.id);
        }
      });
    }
  }

  setTransportSystemFilterData(transportSystemFilterArray: any) {
    if (transportSystemFilterArray?.length > 0) {
      transportSystemFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.transportSystemFilterData.push(dt?.id);
        }
      });
    }
  }

  setMotFilterData(motArray: any){
    if (motArray?.length > 0) {
      motArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.modeOfTransport.push(dt?.name);
        }
      });
    }
  }

  processID(id: any, desk:number, deskList: any, calledFrom: string) {

    let result: any;
    let currentMasterList = deskList.find((e: any) => e.deskId === desk);
    let loc : any= ''
    if (calledFrom === 'locationCode') {
     loc = currentMasterList.deskLocations.filter((e: any) => e.locationCode === id)
      this.locCode = id;
      result = loc[0].locationName;
    }
     if(calledFrom == 'demandMaterial') {
      let demand =  currentMasterList.deskLocations.filter((e: any) => e.locationCode === this.locCode)[0].materialLocations[0].filter((e:any) => e.materialNumber === id )
     result = demand[0].materialDescription
     }
     if(calledFrom == 'scheduledMaterial') {
      let scheduledMaterial = currentMasterList.deskLocations.filter((e: any) => e.locationCode === this.locCode)[0].materialLocations[0].filter((e:any) => e.materialNumber === id )
      result = scheduledMaterial[0].materialDescription
     }
  
    return result
  }
  getGlobalNom(postData: any) {
    this.serv.getAllNominations(postData.searchPayload).subscribe((data: any) => {
      this.rowData = data.result;
      this.rowData.forEach(el => {
        el.nominationReferenceDocumentItem = this.utilities.removeLeadingZeros(el.nominationReferenceDocumentItem);
        el.scheduledDate = this.dateCellRenderer(el.scheduledDate);//"20240604"
        el.scheduledTime = el.scheduledTime?.toLowerCase();
        el.udmLaycanStartDate = this.dateCellRenderer(el.udmLaycanStartDate);
        el.udmLaycanEndDate = this.dateCellRenderer(el.udmLaycanEndDate);
        el.modeOfTransportDesc = this.setMotIfVessel(el);
        el.nominationNo = el.nominationNo?.toUpperCase();
        el.nominationKey = this.isCargo && (el.nominationKey === null ||el.nominationKey === '' || el.nominationKey === undefined) ? el.id : el.nominationKey;
      });
    })
  }

 
  setMotIfVessel(value: any) {
    return value.modeOfTransportDesc;
  }
  isNotMultiple(event: any, nomPayload: any) {
    this.masterGridSelectedRows?.forEach(val => {
      const hToItemNavValue: any = [];
      const isStatusDelete = event.trim() === 'delete';
      if (val) {
        if(val?.udmNominationGrouping){ // check if the data is for multi transport
         
          if (val.headerToItemNav?.length > 0) {
            this.setHeaderToItemNavMultiple(val, hToItemNavValue, isStatusDelete, nomPayload, event);
          }
        }
        
      else{
        this.isNotMultipleElseCondition(event, val, nomPayload, hToItemNavValue, isStatusDelete);
      }

      }
    })
  }

  isNotMultipleElseCondition(event: any, val: any, nomPayload: any, hToItemNavValue: any, isStatusDelete: any){
    if (val.headerToItemNav?.length > 0) {
      this.setHeaderToItemNav(val, hToItemNavValue, isStatusDelete,event);
    }
    let updateSys_status = val?.sys_status?.toLowerCase() === "published" ? Nom.INPROCESS : val?.sys_status
    nomPayload.push(
      {
        id: val.id,
        deskFilter: "pipelinedesk",
        sys_status:event.trim() === 'delete' ? updateSys_status  : Nom.INPROCESS,
        udmPublishEvent: event.trim() === 'delete' ? this.accessForNecessaryDesk() : undefined,
        headerToItemNav: hToItemNavValue,
        udmDesk: val.udmDesk[0]
      }
    )
  }
  masterGridPublishDeleteRecord(event: any, nomPayload: any) {
    const isStatusDelete = event.trim() === 'delete';
    if (!this.masterGridSelectedRows[0]?.udmNominationGrouping) {
      this.isNotMultiple(event, nomPayload)
    }
    else {
      this.groupTransport(nomPayload, event, isStatusDelete)
    }
  }
  groupTransport(nomPayload: any, event: any, isStatusDelete: any) {
    let obj: any = {};
    this.masterGridSelectedRows[0]?.headerToItemNav.forEach((el: any, i: any) => {
      if (Object.keys(obj).length === 0 || !Object.keys(obj).includes(el.id)) {
        obj[el.id] = [el]
      }
      else {
        obj[el.id].push(el)
      }
    })
    Object.keys(obj).forEach((key: any) => {
      let hToItemNav: any = []

      obj[key].forEach((elem: any) => {
        const deletedUdmItemStatus = elem?.udmItemStatus;
        let deletedItemNomKey = elem?.nominationKey;
        deletedItemNomKey = deletedItemNomKey?.length;
        let updatedStatus =deletedUdmItemStatus;
        if(deletedItemNomKey){
          updatedStatus ="InProcess";
        }
        hToItemNav.push({
          itemId: elem.itemId,
          udmItemStatus:   event.trim() === 'delete' ? (updatedStatus) : "InProcess",
        })
      })

      const deletedNomStatus = this.masterGridSelectedRows[0]?.sys_status;
      let deletedItemNomKey = this.masterGridSelectedRows[0]?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus =deletedNomStatus;
      if(deletedItemNomKey){
        updatedStatus ="InProcess";
      }

      nomPayload.push({

        id: key,
        deskFilter: "pipelinedesk",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        sys_status: event.trim() === 'delete' ? (updatedStatus) : "InProcess",
         headerToItemNav: hToItemNav,
      })
    })

  }
  setHeaderToItemNav(val: any, hToItemNavValue: any, isStatusDelete: any, event:any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedNomStatus =deletedUdmItemStatus;
      if(deletedItemNomKey){
        updatedNomStatus ="InProcess";
      }
      hToItemNavValue?.push({
        itemId: el.itemId,
        udmItemStatus:  isStatusDelete ? (updatedNomStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
      });
    });
  }
  setHeaderToItemNavMultiple(val: any, hToItemNavValue: any, isStatusDelete: any, nomPayload: any, event: any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus =deletedUdmItemStatus;
      if(deletedItemNomKey){
        updatedStatus ="InProcess";
      }
      let headerVal: any = []
      headerVal?.push({
        itemId: el.itemId,
        udmItemStatus: event.trim() === 'delete' ? (updatedStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`

      });
      const deletedNomStatus = val?.sys_status;
      let deletedNomKey = val?.nominationKey;
      deletedItemNomKey = deletedNomKey?.length;
      let updatedNomStatus =deletedNomStatus;
      if(deletedItemNomKey){
        updatedNomStatus ="InProcess";
      }
      nomPayload.push(
        {
          id: el.id,
          deskFilter: "pipelinedesk",
          sys_status: event.trim() === 'delete' ? (updatedNomStatus) : "InProcess",
          headerToItemNav: headerVal
        }
      )
      hToItemNavValue = [...hToItemNavValue, ...headerVal]
    });
  }

  async publishOrDeleteNomination(event: any) {
    this.selectedRows = [...this.masterGridSelectedRows, ...this.detailGridSeectedRows];
    let nomPayload: any[] = [];    
    if(this.masterGridSelectedRows.some((obj:any)=>obj.isNonCPDesk)){
      // Finding unique id's from masterGridSelectedRows
      let uniqueIDs = [...new Set(this.masterGridSelectedRows.map((a:any) => a.id))];
      // Transforming masterGridSelectedRows with headerToItemNav
      uniqueIDs.forEach((uniqueId:any)=>{
        let temp = this.masterGridSelectedRows;
        if(this.masterGridSelectedRows.some((obj:any)=>obj.headerToItemNav)){
          temp = this.masterGridSelectedRows.filter((a:any)=>!a.headerToItemNav)
        }
        let tempMasterGridSelectedRows = JSON.parse(JSON.stringify(temp));
        const tempHeaderToItemNav = JSON.parse(JSON.stringify(tempMasterGridSelectedRows.filter((b:any)=>b.id === uniqueId)));
        const uniqueNominationIdIndex = tempMasterGridSelectedRows.findIndex((obj:any)=>obj.id === uniqueId);
        tempMasterGridSelectedRows[uniqueNominationIdIndex]["headerToItemNav"] = JSON.parse(JSON.stringify(tempHeaderToItemNav));
        // Removing other nomination objects without headerToItemNav from this.masterGridSelectedRows
        // Filtering obj with headerToItemNav
        tempMasterGridSelectedRows = tempMasterGridSelectedRows.filter((c:any)=>c.headerToItemNav);
        // Removing existing objs from with matching uniqueId 
        this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c:any)=>c.id !== uniqueId);
        // Adding back filtered obj with headerToItemNav to this.masterGridSelectedRows
        this.masterGridSelectedRows.push(...tempMasterGridSelectedRows);
      })
      
      
    }
    this.masterGridPublishDeleteRecord(event, nomPayload);
    // only one innergrid for now
    let headerItem: any[] = [];

    this.setWarningMsgorApi(nomPayload, event)
    this.selectedRows = [];
  }
  setWarningMsgorApi(nomPayload: any, event: any) {
    /**Call update nominations api */
    if (nomPayload?.length > 0 && !this.warningPopup) {
      this.publishDeleteApiCall(event, nomPayload);

    }
  }

  showWarningPopup() {
    this.warningPopup = true;
  }
  publishDeleteApiCall(event: any, nomPayload: any) {
    console.log(JSON.stringify(nomPayload));
    //return;
     this.isDeletecalled = true;
    if (this.selectedDeskOption.includes('clean products')) {
      this.serv.updateNomination(nomPayload).subscribe({
        next: () => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.selectedRows = [];
          nomPayload = []
          this.updateUdmCargoPascagoulaData = null
          this.updateUdmCargoPascagoulaDataChildLevel = null
          this.toaterService.setToastNotification({
            show: true,
            type: 'success',
            msg: event.trim() === "delete" ? "Nomination has been deleted" : "Nomination is being sent to TSW",
          });
        },
        error: (err: any) => { },
        complete: () => { },
      });
      setTimeout(() => {
        this.searchNomResponse$
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: any) => {
            if(this.isDeletecalled){
              this.getGlobalNom(response);
              this.isDeletecalled = false;
            }
          }})
        this.gridApi.deselectAll();
      }, 3000)
    }
  }
  updatePayloadFormation() {
    if (this.headerPayload?.length > 0 && this.detailPayload?.length > 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.headerPayload));
      this.detailPayload?.map((element: any) => {
        if (!(this.updatePayload?.map((el: any) => el?.id).includes(element?.id))) {
          this.updatePayload?.push(element);
        } else {
          const rowIndex = this.updatePayload?.map((el: any) => el?.id).indexOf(element?.id);
          this.updatePayload[rowIndex]["headerToItemNav"] = element?.headerToItemNav;
        }
      });
    } else if (this.headerPayload?.length > 0 && this.detailPayload?.length === 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.headerPayload));
    } else if (this.headerPayload?.length === 0 && this.detailPayload?.length > 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.detailPayload));
    }
  }

  save() {
    this.updatePayloadFormation()
    console.log(JSON.stringify(this.updatePayload));
    //return;
    if (this.updatePayload?.length > 0) {
      this.masterGridSelectedRows = [];
      this.detailGridSeectedRows = [];
      this.serv.updateNomination(this.updatePayload).subscribe({
        next: (response: any) => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.headerPayload = [];
          this.detailPayload = [];
          this.gridApi.deselectAll();
          this.toaterService.setToastNotification({
            show: true,
            type: "success",
            msg: "Nomination has been updated",
          });
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
          this.updateUdmCargoPascagoulaData = {}
          this.updateUdmCargoPascagoulaDataChildLevel = {}
        },
        error: (err: any) => {
          this.headerPayload = [];
          this.detailPayload = [];
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
        },
        complete: () => { },
      });
    }
  }

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'subtitle-6 font-gotham-narrow-book',
    headerClass: 'font-gotham-bold'
  };
  columnDefs:any =[];
  dummyColumnDefs: any = [];
  isCargoClone:any;
  groupDisplayType : RowGroupingDisplayType = 'singleColumn'
  
  getRowStyle = (params : any) => {
    if (!params?.node?.childrenAfterGroup || params?.node?.childrenAfterGroup.length === 0) {
      return { background: 'lightyellow' };
    }
    return undefined;
  };

autoGroupColumnDef = {
  headerName: 'Group',
  filter: 'agGroupColumnFilter',
  // minWidth: 220,
  cellRendererParams: {
      suppressCount: true,
      checkbox: true,
      innerRenderer: CellInfoHoverComponent,
  }
};

public aggFuncs: {
  [key: string]: IAggFunc;
} = {
    checkMultiple: (params: IAggFuncParams) => {
      return params.values.every((val: string) => val === params.values[0]) ? params.values[0] : 'multiple';
    },
    originVolSum: (params: IAggFuncParams) => {
      let total = 0;
      params.rowNode.allLeafChildren.forEach((row: any) => {
        if (row.data.scheduleType === 'O' || row.data.scheduleType === 'IT')
          total += +row.data.scheduledQty;
      });
      return total;
    },
      checkDesks: (params: IAggFuncParams)=> {
        return params.values[0]
      },
      checkNoms:(params: IAggFuncParams)=>{
        return params.values[0]
      },
      checkId:(params: IAggFuncParams)=> {
        return params.values[0]
      },
      checkEarlyDate: (params: IAggFuncParams) => {
        let arr = params.rowNode.allLeafChildren.length?params.rowNode.allLeafChildren:[];
        if(!arr.length) return;
        let param:any= arr?.reduce((a:any, b:any) => {
          if(a.data.scheduledDate)
            return new Date(a.data.scheduledDate) < new Date(b.data.scheduledDate) ? a : b
        }, arr[0]);
        let date = param.data.scheduledDate;
        return date;
      }
};

  changeHeaderNamesNonCP() { //NOSONAR 
    this.isCargoClone = this.isCargo;
    this.columnDefs = [
      {
        field: "actions", headerName: 'actions', width: 100, wrapHeaderText: true, autoHeaderHeight: true,
        maxWidth: 100,
        cellRendererFramework: MoreMenuSearchComponent,
        cellRendererParams: {
          totalRecords: this.rowData?.length,
        }

      },
      {
        field: "udmItemStatus", headerName: "status", width: 125,
        aggFunc: 'checkMultiple',
        cellClass: (params: any) => {
          if (params?.value?.toLowerCase() === "unpublished" || params?.value?.toLowerCase() === "multiple") {
            return "unpublished";
          } else if (params?.value?.toLowerCase() === "published") {
            return "published";
          } else {
            return "";
          }
        }
      },
      {
        field: "nominationNo", headerName: this.isCargo ? "nomno." : "nomination no",
        width: 130, wrapHeaderText: true, autoHeaderHeight: true,
        // rowGroup: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: "id", headerName: "id",
        width: 130, wrapHeaderText: true, autoHeaderHeight: true, hide: true,
        aggFunc: 'checkId',
      },
      {
        field: "nominationKey", headerName: "nomkey", width: 125,
        rowGroup: true,
        hide: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: "udmDesk", headerName: "udmDesk",
        width: 130, wrapHeaderText: true, autoHeaderHeight: true,
        rowGroup: true,
        aggFunc: 'checkMultiple',
        valueGetter: (params: any) => {
          return params?.data?.udmDeskId ? deskName[params?.data?.udmDeskId] : ''
        }
      },
      {
        field: "partnerNominationNumber", headerName: "partner nom no", width: 150, wrapHeaderText: true,
        autoHeaderHeight: true,
        valueGetter: (params: any) => {
          let sameValue = false;
          if (params?.data?.headerToItemNav?.length > 1) {
            const headerToItemNav = params?.data?.headerToItemNav;
            for (let i = 0; i < headerToItemNav.length - 1; i++) {
              if (headerToItemNav[i]?.partnerNominationNumber === headerToItemNav[i + 1]?.partnerNominationNumber) {
                sameValue = true;
              } else {
                sameValue = false;
                break;
              }
            }
          } else {
            sameValue = true;
          }
          if (sameValue) {
            return params?.data?.partnerNominationNumber;
          } else {
            return "multiple";
          }
        },
      },
      {
        field: "vehicleName",
        headerName: "vessel/barge/pipeline name",
        hide: !this.isCargo, width: 125,

        cellEditor: AutoCompleteComponent,
        cellEditorParams: () => {
          return {
            propertyRendered: 'name',
            showedColumn: 'name',
            region: this.region,
            desk: this.selectedDeskOption,
            deskParameter: this.selectedDeskParameter,
            rowData: {},
            columnDefs: [
              {
                filter: 'agTextColumnFilter',
                field: 'name',
                valueFormatter: (event: any) => {
                  let data = event.data;
                  return Object.keys(data).length > 0 ? `${data?.name}_(${data?.id})` : '';
                },
              },
              {
                field: 'name',
                filter: 'agTextColumnFilter',
                hide: true,
              },
            ],
            popup: true,
          }
        },
        aggFunc: 'checkMultiple'
      },
      {
        field: (this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) ? "carrierName" : "carrier", headerName: "carrier", hide: !this.isCargo, width: 125,
        aggFunc: 'checkMultiple',
      },
      {
        field: "transportSystem", headerName: "transport system",
        aggFunc: 'checkMultiple',
        width: 120, wrapHeaderText: true, autoHeaderHeight: true,
      },
      {
        field: "scheduledQty", headerName: "volume", width: 125,
        valueFormatter: (params: any) => (this.utilities.formatNumberPipeline(params?.value)), aggFunc: 'originVolSum'
      },
      {
        field: "locationId", headerName: "location Id", width: 125, aggFunc: 'checkMultiple',
      },
      {
        field: "scheduledMaterial", headerName: "scheduled material code", width: 125, aggFunc: 'checkMultiple',
      },
      {
        field: "demandMaterial", headerName: "demand Material code", width: 125, aggFunc: 'checkMultiple',
      },
      {
        field: "actualPostedQuantity", headerName: "actual volume", width: 125,
      },
      {
        field: "unitOfMeasurementName", headerName: "unit of measure",
        aggFunc: 'checkMultiple',
        width: 120, wrapHeaderText: true, autoHeaderHeight: true,
      },
      {
        field: "udmLaycanStartDate", headerName: "laycan start date", hide: !this.isCargo, width: 200, cellEditor: DatePickerComponent
      },
      {
        field: "udmLaycanEndDate",
        headerName: "laycan end date",
        hide: !this.isCargo, width: 200,
        cellEditorParams: { resetToMin: true },
        cellEditor: DatePickerComponent
      },
      {
        field: "scheduledDate",
        headerValueGetter: (params: any) => this.isCargo ? 'TSW schedule date' : "schedule date",
        width: 140,
        aggFunc: 'checkEarlyDate',
        wrapHeaderText: true, autoHeaderHeight: true,
        editable: true,
        cellEditor: DatePickerComponent
      },
      {
        field: "scheduledTime",
        headerName: "arrival time",
        aggFunc: 'checkMultiple',
        width: 120,
        wrapHeaderText: true, autoHeaderHeight: true,
      },

      {
        field: "tswNominationType", headerName: "nomination type", width: 150, wrapHeaderText: true, autoHeaderHeight: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: "scheduleType", headerName: "TSW nomination type",
        width: 150, wrapHeaderText: true, autoHeaderHeight: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: "nominationReferenceDocument", headerName: "sap contract no", wrapHeaderText: true, autoHeaderHeight: true, width: 150,
        aggFunc: 'checkMultiple',
        onCellValueChanged: (params: any) => {
          params.node.setDataValue(params.column.colId, params.newValue?.replace(/\b0+/g, "")?.trim())
        }
      },
      {
        field: "nominationReferenceDocumentItem", headerName: "contract line item no",
        width: 150, wrapHeaderText: true, autoHeaderHeight: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: "referenceDocumentIndicator", headerName: "ref doc id", width: 100, wrapHeaderText: true, autoHeaderHeight: true,
        aggFunc: 'checkMultiple',
      },
      {
        field: 'nominationItemStatus',
        headerName: 'TSW status',
        aggFunc: 'checkMultiple',
        width: 120,
        wrapHeaderText: true, autoHeaderHeight: true,
        valueGetter: (params: any) => {
          let sameValue = false;
          if (params?.data?.headerToItemNav?.length > 1) {
            const headerToItemNav = params?.data?.headerToItemNav;
            for (let i = 0; i < headerToItemNav.length - 1; i++) {
              if (headerToItemNav[i]?.nominationItemStatus?.toLowerCase() === headerToItemNav[i + 1]?.nominationItemStatus?.toLowerCase()) {
                sameValue = true;
              } else {
                sameValue = false;
                break;
              }
            }
          } else {
            sameValue = true;
          }
          if (sameValue) {
            return params?.data?.nominationItemStatus;
          } else {
            return "multiple";
          }
        },
      },
      {
        field: "udmComments", headerName: "comments", width: 150,
        aggFunc: 'checkMultiple',
      },
      // adding additional properties which are needed for delete and publish from three dots in parent level
      {
        field: "createdBy",
        headerName: "createdBy",
        hide: true
      },
      {
        headerName: "Confirm",
        field: "confirmationIndicator",

        cellRenderer: (params: any) => {
          if (!params?.node?.group) {
            const input = document.createElement('input');
            input.type = 'checkbox';
            input.checked = params.value;
            const clonedParamsDataObj = { ...params.data };
            input.addEventListener('change', (event: any) => {
              clonedParamsDataObj.confirmationIndicator = event.target.checked;
              const confirmationIndicatorValue = this.utilities.getConfirmationIndicatorValue(clonedParamsDataObj, true);
              params.node.setDataValue(params.colDef.field, confirmationIndicatorValue);
            });
            return input;
          }
          return null;
        },
        valueGetter: (params: any) => {
          return params?.data?.confirmationIndicator === "X";
        },
        cellClassRules: {
          'disable-option disable-option-with-opacity': (params: any) => !params?.node?.group && (params?.data?.nominationItemStatus === "5")
        },
      },
    ];

    this.columnDefs.forEach((columnDef: any) => {
      if (columnDef.editable) {
        columnDef.cellClass = 'highlightEditableCell';
      }
    });

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  public groupDefaultExpanded = 1;

 

  colCreationForPascagoulaDesk(){
    this.dummySecondLevelGridColumnDef = JSON.parse(JSON.stringify(this.secondLevelGridColumnDef));
      ['voyageStatus','compon','date','VOY','equipment','status B','HOU/DEST','ETDK','Current Location','RA','Navarick','Nav Nom','Inspection'].forEach((ele:any)=>{
        if (!this.secondLevelGridColumnDef.map((el:any)=>el.headerName).includes(ele)) {
          if (ele === 'status B') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__statusB`,
              
            })
          }else if (ele === 'HOU/DEST') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__HOU_DEST`,
              
              cellEditor: DatePickerComponent
            })
          }else if (ele === 'ETDK') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__ETDK`,
              
              cellEditor: DatePickerComponent
            })
          }else if (ele === 'date') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__date`,
              
              cellEditor: DatePickerComponent
            })
          }
          else if (ele === 'Current Location') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__currentLocation`,
              
            })
          }else if (ele === 'Navarick') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__navarick`,
              
            })
          }else if (ele === 'Nav Nom') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__navNom`,
              
            })
          }else if (ele === 'Inspection') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__inspection`,
              
            })
          }
          else{
          this.secondLevelGridColumnDef.push({
            headerName: ele,
            field: `udmCargoPascagoulaData__${ele}`,
            
          })
        } 
        }
        
      })
  }

  public detailCellRendererParams: any = (params: ICellRendererParams) => {
    const res = {} as IDetailCellRendererParams;
    this.currentParentRow = params.data;
    this.mainGridIndex = this.copyRowData.map(el => el.id).indexOf(params?.data?.id);
    if (params?.data?.headerToItemNav?.length >= 1) {
      this.formationOfSecondLevelGrid(res, params);
    } else if (params?.data?.headerToItemNav?.length === 1 && params?.data?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      this.thirdLevelGridFormation(res);
    }
    return res;
  };

  getUpdatedChildItems(childItems:any,combinedItems:any){
    childItems.forEach((item:any)=>{
      if (Array.isArray(item) && item?.length) {
         combinedItems.push(...item)
      }
    });
    if(combinedItems.length){
      childItems = combinedItems;
      childItems.forEach((ele:any)=>{
        ele['udmItemStatus']=ele?.sys_status;
        ele['nominationNo']=ele?.nominationNumber;
        ele['scheduledUom']=ele?.scheduledUOM;
      });
    }
    return childItems;
  }

  formationOfSecondLevelGrid(res: any, params: any) {
    this.currentlyActiveLineRow = params?.data;
    let childItems = params.data.headerToItemNav;
    const combinedItems:any = [];
    childItems = this.getUpdatedChildItems(childItems,combinedItems);

    res.getDetailRowData = function (params: any) {
      params.successCallback(childItems);
    };
    res.detailGridOptions = {
      //columnDefs: this.getSecondGridFormation(),
      rowSelection: 'multiple',
      enableRangeSelection:true,
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold'
      },
      masterDetail: true,
      detailCellRendererParams: {
        detailGridOptions: {
          getMainMenuItems: (params: GetMainMenuItemsParams) => {
            if (params.column.getId() === "bbl190Truck" || params.column.getId() === "bbl250Truck") {
              return [
                {
                  name: "190 bbl truck",
                  action: () => {
                    this.bblTrucks = false;
                    this.gridApi.redrawRows();
                  },
                },
                {
                  name: "250 bbl truck",
                  action: () => {
                    this.bblTrucks3 = true;
                    this.gridApi.redrawRows();
                    this.bblTrucks3 = false;
                  }
                }
              ];
            } else {
              return params.defaultItems
            }
          },
          columnDefs: this.bblTrucks3 ? [
            {
              field: 'scheduledDate',
              headerName: 'date',
            },
            {
              field: 'scheduledQty',
              headerName: 'scheduled mov. volumes',
            },
            {
              headerName: '250 bbl truck',
              field: 'bbl250Trucks',
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [1, 2, 3],
              },
            } as unknown as ISelectCellEditorParams
          ] : [
            {
              field: 'scheduledDate',
              headerName: 'date',
            },
            {
              field: 'scheduledQty',
              headerName: 'scheduled mov. volumes',
            },
            {
              headerName: '190 bbl truck',
              field: 'bbl190Truck',
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [1, 2, 3],
              },
            } as unknown as ISelectCellEditorParams
          ],
          defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: 'subtitle-6 font-gotham-narrow-book',
            headerClass: 'font-gotham-bold'
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.udmDailyBreakdownQty);
        },
      } as IDetailCellRendererParams,
      // onCellValueChanged: (event: CellValueChangedEvent) => {
      //   this.handleDetailCellValueChanged(event);
      // }
    };
  }

  handleDetailCellValueChanged(event:any,isNonCargo?:boolean){
    this.existanceOfPayloadCheck(event);
        let rowDetail:any;
        // Find id inside headerToItemNav as it is different from multi transport system
        if(!isNonCargo){
          rowDetail = this.copyRowData.find((val:any)=>val.itemId ===event?.data?.itemId);
        }else{
          rowDetail = event?.data;
        }
      
        let keys = Object.keys(rowDetail);
        const headerToItemPayload: any = {
          itemId: event?.data?.itemId,
          udmItemStatus: event?.data?.udmItemStatus,
          textLine: `{Author:${event?.data?.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
          scheduledDate: this.datePipe.transform(event?.data?.scheduledDate, "yyyMMdd"),
        }
        this.updateUdmCargoPascagoulaDataChildLevel = {};
        // Filtering unwanted keys for noncargo desks
        if(isNonCargo){
          const keysToInclude = ["itemId","udmItemStatus","scheduledDate","scheduledQty","nominationReferenceDocument","nominationReferenceDocumentItem","partnerNominationNumber","locationName","locationId","scheduledMaterial","scheduledMaterialName","demandMaterial","demandMaterialName","udmComments","contractPartner","udmEndLoadDate","confirmationIndicator"];
          keys = keys.filter(value=>keysToInclude.includes(value));
        }
        for (const key of keys) {
          this.addUpdatedRowsToPayload(rowDetail, key, event, headerToItemPayload,isNonCargo)
        }
        if (!(this.detailHeaderToItemNav?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
          this.detailHeaderToItemNav.push(headerToItemPayload);
        } else {
          const detailPayloadIndex = this.detailHeaderToItemNav?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
          this.detailHeaderToItemNav[detailPayloadIndex] = headerToItemPayload;          
        }
      
        const payload = {
          id: event?.data?.id,
          sys_status: rowDetail?.nominationStatus,
          deskFilter: "pipelinedesk",
          modeOfTransportDesc: event?.data?.modeOfTransportDesc,          
          headerToItemNav: this.detailHeaderToItemNav,
          udmPublishEvent: this.accessForNecessaryDesk(),
          udmNominationGrouping: rowDetail?.udmNominationGrouping ? this.uniqueUID() : ''

        }
        this.finalUpdatePayloadFormation(payload,event);
  }
  uniqueUID() {
    return (new Date()).getTime().toString(36);
  }
  finalUpdatePayloadFormation(payload: any,event:any) {
    if (!(this.detailPayload?.map(el => el.id).includes(event?.data?.id))) {
      this.detailPayload.push(payload);
    } else {
      const payloadIndex = this.detailPayload.map(el => el.id).indexOf(event?.data?.id);
      this.detailPayload[payloadIndex] = payload;
    }
  }

  existanceOfPayloadCheck(event:any) {
    if (this.differentHeaderId !== event?.data?.id) {
      this.detailHeaderToItemNav = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(event?.data?.id));
    if (this.detailPayload?.map(el => el?.id)?.includes(event?.data?.id)) {
      const ind = this.detailPayload?.map(el => el?.id)?.indexOf(event?.data?.id);
      this.detailHeaderToItemNav = this.detailPayload[ind]?.headerToItemNav
    }
  }

  existanceOfPayloadCheckHeader(event:any) {
    if (this.differentHeaderId !== event?.data?.id) {
      this.detailHeaderToItemNavHeader = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(event?.data?.id));
    if (this.headerPayload?.map(el => el?.id)?.includes(event?.data?.id)) {
      const ind = this.headerPayload?.map(el => el?.id)?.indexOf(event?.data?.id);
      this.detailHeaderToItemNavHeader = this.headerPayload[ind]?.headerToItemNav
    }
  }

  addUpdatedRowsToPayload(rowDetail: any, key: any, event: any, headerToItemPayload: any,isNonCargo?:boolean) {
    if ((rowDetail[key] !== event?.data[key] && key !== "itemId" && key !== "udmItemStatus" && key !== "udmDailyBreakdownQty" && !isNonCargo) || (key !== "itemId" && key !== "udmItemStatus" && key !== "udmDailyBreakdownQty" && isNonCargo)) {
      if (key === "scheduledMaterialName") {
        headerToItemPayload["scheduledMaterialDesc"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } else if (key === "demandMaterialName") {
        headerToItemPayload["demandMaterialDesc"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } else if (key === "scheduledDate") {
        headerToItemPayload["scheduledDate"] = `${event?.data[key].substring(6, 10)}${event?.data[key].substring(0, 2)}${event?.data[key].substring(3, 5)}`;
      } else if (key === "locationName") {
        headerToItemPayload["locationName"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } 
      this.addUpdatedRowToCreatePayload(rowDetail, key, event, headerToItemPayload,isNonCargo)
      }
  }
addUpdatedRowToCreatePayload(rowDetail: any, key: any, event: any, headerToItemPayload: any,isNonCargo?:boolean){
   if (key?.toLowerCase()?.includes('udmcargopascagouladata_')) {
    const updateNewcellPayload = key.split('__');
    this.updateUdmCargoPascagoulaDataChildLevel[updateNewcellPayload[1]] = event?.data[key];
    headerToItemPayload['udmCargoPascagoulaData'] = this.updateUdmCargoPascagoulaDataChildLevel;
  } else if (key === "nominationReferenceDocument" && event?.data[key] === "") {
    headerToItemPayload["nominationReferenceDocument"] = " ";
    headerToItemPayload["nominationReferenceDocumentItem"] = " ";
  } else if (event?.data[key] === "" && key !== "confirmationIndicator") {
    headerToItemPayload[key] = " ";
  } else if(key === "nominationReferenceDocumentItem"){ 
        headerToItemPayload[key] =this.utilities.removeLeadingZeros(headerToItemPayload[key] = event?.data[key])
  }
  // else if (key === "udmDesks") {
  //   headerToItemPayload["udmDesks"] = event?.data[key][0];
  //   this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
  // } 
   else {
        headerToItemPayload[key] = event?.data[key];
      }
}
  updatePayloadForCargoDesks(event:any,key:any,headerToItemPayload:any,isNonCargo:any){
    if(!isNonCargo){
      if (key === "scheduledMaterialName") {
        const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
        headerToItemPayload["scheduledMaterial"] = scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id;
        
      } else if (key === "demandMaterialName") {
        const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
        headerToItemPayload["demandMaterial"] = demandMaterial.find((el: any) => el.name === event?.data[key])?.id;
      
      } else if (key === "locationName") {
        const locations = this.cpService?.locationNameAndId?.value;
        headerToItemPayload["locationId"] = locations?.find((el: any) => el?.name === event?.data[key])?.id;
      }
    }
   
  }

  thirdLevelGridFormation(res: any) {
    res.getDetailRowData = function (params: any) {
      params.successCallback(params.data.headerToItemNav[0].udmDailyBreakdownQty);
    }
    res.template = '<div ref="eDetailGrid" style="width: 600px; margin-left: 450px"></div>';
    res.detailGridOptions = {
      getMainMenuItems: (params: GetMainMenuItemsParams) => {
        if (params.column.getId() === "bbl190Truck" || params.column.getId() === "bbl250Truck") {
          return [
            {
              name: "190 bbl truck",
              action: () => {
                this.bblTrucks = false;
                this.gridApi.redrawRows();
              },
            },
            {
              name: "250 bbl truck",
              action: () => {
                this.bblTrucks = true;
                this.gridApi.redrawRows();
                this.bblTrucks = false;
              }
            }
          ];
        } else {
          return params.defaultItems
        }
      },
      columnDefs: this.bblTrucks ? [
        {
          field: 'scheduledDate',
          headerName: 'date',
          width: 200
        },
        {
          field: 'scheduledQty',
          headerName: 'scheduled mov. volumes',
          width: 200
        },
        {
          headerName: '250 bbl truck',
          field: 'bbl250Trucks',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [1, 2, 3],
          },
          width: 200,
        }
      ] : [
        {
          field: 'scheduledDate',
          headerName: 'date',
          width: 200
        },
        {
          field: 'scheduledQty',
          headerName: 'scheduled mov. volumes',
          width: 200
        },
        {
          headerName: '190 bbl truck',
          field: 'bbl190Truck',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [1, 2, 3],
          },
          width: 200,
        }
      ],
      enableRangeSelection:true,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold',
        autoHeight: true,
      },
    };
  }

  formationOfNominationUpdateHeaderPayload(key: any, rowIndex: any, event: any, payload: any) {
    if (this.copyRowData[rowIndex][key] !== event?.data[key] && key !== "id" && key !== "modeOfTransportDesc" && key !== "sys_status" && key !== "headerToItemNav") {
      if (key === "vehicleName") {
        this.vehicleNameAdditionToUpdatePayload(event, payload, key);
      }  else if (key === 'nominationNo') {
        payload["nominationNo"] = event?.data[key].toUpperCase();
      }
      else {
        this.positionOfUpdatedFieldInPayload(key, rowIndex, payload, event);
      }
    }
  }

  vehicleNameAdditionToUpdatePayload(event: any, payload: any, key: any) {
    const vehicleNames: any = this.cpService?.vesselNameOrVehicleName?.value;
    payload["vehicleName"] = event?.data[key];
    payload["vehicleCode"] = vehicleNames?.find((el: any) => el?.name === event?.data[key])?.id;
    if ((this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) && ['barge', 'vessel'].includes(payload['modeOfTransportDesc']?.toLowerCase())) {
      payload["tdVehNo"] = vehicleNames?.find((el: any) => el?.name === event?.data[key])?.id;
    }
  }

  positionOfUpdatedFieldInPayload(key: any, rowIndex: any, payload: any, event: any) {
    if (key === 'scheduledQty' || key === "scheduledDate" || key === "locationName" || key === "scheduledMaterialName" || key === "demandMaterialName" || key === "partnerNominationNumber" || key === "contractPartner" || key === "nominationReferenceDocument" || key === "nominationReferenceDocumentItem") {
      this.formateNomUpdateHeaderPayload(key, rowIndex, event, payload);
    } else {
      this.udmCargoPascagoulaDataPayloadOrHeaderPaylaod(key, payload, event);
    }
  }

  udmCargoPascagoulaDataPayloadOrHeaderPaylaod(key: any, payload: any, event: any) {
    if (key !== "demandMaterialName" && key !== "scheduledMaterialName" && key !== "scheduledDate" && key !== "scheduledQty" && key !== "locationName" && key !== "udmCargoPascagoulaData" && key !== "partnerNominationNumber" && key !== "contractPartner" || key !== "nominationReferenceDocument" || key !== "nominationReferenceDocumentItem") {
      if (key?.toLowerCase()?.includes('udmcargopascagouladata_')) {
        const updateNewcellPayload = key.split('__');
        this.updateUdmCargoPascagoulaData[updateNewcellPayload[1]] = event?.data[key];
        payload['udmCargoPascagoulaData'] = this.updateUdmCargoPascagoulaData;
      } else {
        payload[key] = event?.data[key];
      }
    }
  } 

  formateNomUpdateHeaderPayload(key: any, rowIndex: any, event: any, payload: any){
    if (key === 'scheduledQty' && payload.headerToItemNav) {
      payload.headerToItemNav[0][key] = event?.data[key];
    }
    else if (key === 'scheduledDate' && payload.headerToItemNav) {        
      payload.headerToItemNav[0][key] = `${event?.data[key].substring(6,10)}${event?.data[key].substring(0,2)}${event?.data[key].substring(3,5)}`;
    } else if (key === 'locationName' && payload.headerToItemNav) {
      const locations = this.cpService?.locationNameAndId?.value;
      payload.headerToItemNav[0][key] = event?.data[key];
      payload.headerToItemNav[0]["locationId"] = locations?.find((el: any) => el?.name === event?.data[key])?.id
    } else if (key === 'nominationReferenceDocument' && payload.headerToItemNav && event?.data[key] === "") {
      payload.headerToItemNav[0][key] = " ";
      payload.headerToItemNav[0]["nominationReferenceDocumentItem"] = " ";
    } else if (key === 'nominationReferenceDocumentItem' && payload.headerToItemNav && event?.data[key] === "") {
      payload.headerToItemNav[0][key] = " ";
    } else if (key === 'nominationReferenceDocument' && payload.headerToItemNav && event?.data[key] !== "") {
      payload.headerToItemNav[0]["nominationReferenceDocument"] = event?.data[key];
    } else if (key === 'nominationReferenceDocumentItem' && payload.headerToItemNav && event?.data[key] !== "") {
      payload.headerToItemNav[0]["nominationReferenceDocumentItem"] = event?.data[key];
    } else {
      this.formateNomUpdateHeaderToItemNavPayloadForScheduledMaterialOrDemandMaterial(key, payload, event, rowIndex);
    }
  }

  formateNomUpdateHeaderToItemNavPayloadForScheduledMaterialOrDemandMaterial(key: any, payload: any, event: any, rowIndex: any) {
    if (key === 'scheduledMaterialName' && payload.headerToItemNav) {
      const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload.headerToItemNav[0]["scheduledMaterialDesc"] = event?.data[key];
      payload.headerToItemNav[0]["scheduledMaterial"] = scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id;
    } else if (key === 'demandMaterialName' && payload.headerToItemNav) {
      const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload.headerToItemNav[0]["demandMaterialDesc"] = event?.data[key];
      payload.headerToItemNav[0]["demandMaterial"] = demandMaterial.find((el: any) => el.name === event?.data[key])?.id;
    } else if (key === 'partnerNominationNumber' && payload.headerToItemNav) {
      payload.headerToItemNav[0]["partnerNominationNumber"] = event?.data[key] === "" ? " " : event?.data[key];
    } else if (key === 'contractPartner' && payload.headerToItemNav) {
      payload.headerToItemNav[0]["contractPartner"] = event?.data[key] === "" ? " " : event?.data[key];
    }
    else {
      this.firstEditOfHeaderToItemNav(key, event, payload, rowIndex);
    }
  }

  firstEditOfHeaderToItemNav(key: any, event: any, payload: any, rowIndex: any) { 
    if (key === 'scheduledQty' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledQty: event?.data[key]
      }]
    }
    else if (key === 'scheduledDate' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledDate: `${event?.data[key].substring(6, 10)}${event?.data[key].substring(0, 2)}${event?.data[key].substring(3, 5)}`
      }]
    }else if (key === 'locationName' && !payload.headerToItemNav) {
      const locations = this.cpService?.locationNameAndId?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        locationName: event?.data[key],
        locationId: locations?.find((el: any) => el?.name === event?.data[key])?.id
      }]
    } else if (key === 'partnerNominationNumber' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        partnerNominationNumber: event?.data[key] === "" ? " " : event?.data[key]
      }]
    }
    else {
      this.firstEditDisplay(key, payload, rowIndex, event)
    }
  }
 firstEditDisplay(key: any, payload: any, rowIndex: any, event: any){
   if (key === 'contractPartner' && !payload.headerToItemNav) {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      contractPartner: event?.data[key] === "" ? " " : event?.data[key]
    }]
  } else if (key === 'nominationReferenceDocument' && !payload.headerToItemNav && event?.data[key] === "") {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      nominationReferenceDocument: " ",
      nominationReferenceDocumentItem: " "
    }]
  } else if (key === 'nominationReferenceDocumentItem' && !payload.headerToItemNav && event?.data[key] === "") {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      nominationReferenceDocumentItem: " "
    }]
  } else {
    this.firstEditOfHeaderToItemNavScheduledMaterialNameOrDemandMaterialName(key, payload, rowIndex, event)
  }
 }
  firstEditOfHeaderToItemNavScheduledMaterialNameOrDemandMaterialName(key: any, payload: any, rowIndex: any, event: any) {
    if (key === 'scheduledMaterialName' && !payload.headerToItemNav) {
      const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledMaterialDesc: event?.data[key],
        scheduledMaterial: scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id
      }]
    } else if (key === 'demandMaterialName' && !payload.headerToItemNav) {
      const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        demandMaterialDesc: event?.data[key],
        demandMaterial: demandMaterial.find((el: any) => el.name === event?.data[key])?.id
      }]
    }
  }

  /* For clicking on SAP contract without product filled in */
  onSAPContractSelect(event: CellClickedEvent) {
    if (event.column.getColId() === 'nominationReferenceDocument' && (event.data.demandMaterial === null || event.data.scheduledMaterial === null)) {
      this.SAPContractPopup = true;
    }
  }

  accessForNecessaryDesk() {
    if (this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) {
      return true;
    } else {
      return false;
    }
  }

  onCellValueChangedNonCP(event:CellValueChangedEvent){
    if(event?.colDef?.field === 'scheduledMaterialName' || event?.colDef?.field === 'demandMaterialName'){
      event.data[event.colDef.field] = event?.newValue;
      const id = event.colDef.field.replace("Name", "");
      event.data[id] = this.materialCode.filter(code=>code.name === event?.newValue)[0]?.value;
    }
    if(event?.colDef?.field === 'locationName'){
      event.data[event.colDef.field] = event?.newValue;
      const id = event.colDef.field.replace("Name", "Id");
      event.data[id] = this.locationCodeNonCp.filter(code=>code.name === event?.newValue)[0]?.value;
    }
    this.onCellValueChanged(event,true);
  }


  onCellValueChanged(event: CellValueChangedEvent, isNonCp?: boolean, checkBoxEvent?: any) {
    // Checking if non CP desk and forming payload with headerToItemNav array manually from event.data obj
    let colDefAttr: string = event?.colDef?.field ? event?.colDef?.field : '';

    if (isNonCp) {
      this.handleNonCPDeskCellValueChanged(event);
    } else if (!['vehicleName', 'udmLaycanEndDate'].includes(colDefAttr)) {
        this.handleDetailCellValueChanged(event, !this.isCargo);
      } else {
        this.existanceOfPayloadCheckHeader(event);
        this.mainGridIndex = this.copyRowData.map(el => el.id).indexOf(event?.data?.id);
        this.cellValueChangedRefactor(event, checkBoxEvent, isNonCp);
        this.gridApi?.redrawRows();
      }

  }
  cellValueChangedRefactor(event: any,checkBoxEvent: any, isNonCp: any){
    if (this.headerPayload?.find((val: any) => val.id !== event?.data?.id)) {
      this.detailHeaderToItemNavHeader = [];
    }
    const rowIndex = this.copyRowData?.map(el => el?.id).indexOf(event?.data?.id);
    let keys: any;
    if (rowIndex >= 0) {
      keys = Object.keys(this.copyRowData[rowIndex]);
    }

    const headerToItemPayload: any = {
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['sys_createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,

    }
    if (event?.colDef?.field === 'confirmationIndicator') {
      event.data["confirmationIndicator"] = checkBoxEvent?.target?.checked ? 'X' : null;
      // For unchecking and checking confirmation indicator once unchecked from UI for CP desks, as it is only for parent rows we are directly consuming headerToItemNav[0]
      event.data.headerToItemNav[0]["confirmationIndicator"] = checkBoxEvent?.target?.checked ? 'X' : null;
    }
    this.cellValueExtension(event,headerToItemPayload,keys,rowIndex, isNonCp, checkBoxEvent )

   }
  cellValueExtension(event: CellValueChangedEvent, headerToItemPayload: any,keys:any,rowIndex: any, isNonCp?: boolean, checkBoxEvent?: any){
    

    if (!(this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
      this.detailHeaderToItemNavHeader.push(headerToItemPayload);
    } else {
      const detailPayloadIndex = this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
      this.detailHeaderToItemNavHeader[detailPayloadIndex] = headerToItemPayload;
    }

    const payload: any = {
      id: event?.data?.id,
      sys_status: event?.data?.sys_status,
      deskFilter: "pipelinedesk",
      modeOfTransportDesc: event?.data?.modeOfTransportDesc,
      udmPublishEvent: this.accessForNecessaryDesk(),
      headerToItemNav: this.detailHeaderToItemNavHeader
    }

    this.updateUdmCargoPascagoulaData = {};
    for (const key of keys) {
      this.formationOfNominationUpdateHeaderPayload(key, rowIndex, event, payload)
    }
    if (!(this.headerPayload.map(el => el.id).includes(event?.data?.id))) {
      this.headerPayload.push(payload);
    } else {
      const payloadIndex = this.headerPayload.map(el => el.id).indexOf(event?.data?.id);
      this.headerPayload[payloadIndex] = payload;
    }
  }
  handleNonCPDeskCellValueChanged(event:any){
    for (const [key, value] of Object.entries(event?.data)) {
      event.data["headerToItemNav"]={...event?.data["headerToItemNav"],[key]:value}
    }
     this.handleDetailCellValueChanged(event,true);
     
  }

  formattedDate(value: any) {
    const date = new Date(value);
    const fmdt = date.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return fmdt;
  }
 
  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }
  createNominationModal() {
    const modal = { open: true, modal: 'createNominationModal' }
    this.createNominationPipelineModal = JSON.parse(JSON.stringify(modal));
  }

  getCheckedNames(element: any) {
    const list = element.list.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.name);
    return list;
  }
  
  closePipelineNominationModal(val: any) {
     this.selectedRows = []
    if (val && val.success === true) {
      let postData!: any;
      /**selectedDesk$ and if else desk conditions to be removed and maintain only postData code here after routing changes implmented */
      setTimeout(()=>{
        this.searchNomResponse$
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: any) => {
            this.getGlobalNom(response);
          }
        })
      },2500)
    }
    const modal = { open: false, modal: 'createPipelineNomination' }
    this.pipelineNominationModal = { ...modal };
  }


  closePipelinevesselScheduleUpdateModal(val:any){
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'pipelineVesselScheduleUpdate' };
    this.vesselScheduleUpdateModal = { ...modal };    
  }

  toggleCreatePipelineNominationsModal(): void {
    const modal = { open: !this.pipelineNominationModal.open, modal: 'createPipelineNomination' }
    this.pipelineNominationModal = { ...modal };
    this.createPipelineNominationComponent.initialValues()

  }
  async createNominationWithReferenceforSearchFromHome(data: any){
    let currSelection = this.selectedRows[0];
    data.deskSet = currSelection.udmDesk[0]
    this.createNominationWithReferenceforSearch(data)
  }

  async isCargoDeskLogic(data: any, specificDesks: any, regionSelected: any) {
      let mdmRes = await firstValueFrom(this.mdmService.getAggregateData(parseInt(data.deskSet)));
      let res = mdmRes.value.value[0]
      data.data = this.selectedRows[0]
      this.utilities.deskSearchMdm = res;
      let pipelineNomSearchValues = { value: this.utilities.getRequiredPipelineFilterJsonStructureForSearch(res, false, this.currentScreen === "replenishmentplanning") }
      this.appStore.dispatch(savePipelineNomSearchResponse({ pipelineNomSearchValues: JSON.parse(JSON.stringify(pipelineNomSearchValues)), isPipelineNomSearchUpdated: true }));
      if (data?.data?.id) {

        const pipelineNomination: any = await this.utilities.getPipelineNomSearchPayload2(data, this.userEmail, this.selectedDeskOption, true);
        const selectedTabFunction = {
          tab: this.router.url,
          function: 'onCreatePipelineNominationFromSearch',
          type: '',
          values: pipelineNomination,
          editNomination: data?.editNomination
        };
        const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(modalPopupAction);
      }
  }

  
  async createNominationWithReferenceforSearch(data: any) {
    let specificDesks = cpSpecific[data.deskSet];
    let regionSelected = region[data.deskSet];

    this.nominationService.getSearchNominationAFAPI$.subscribe(res => {
      this.saveOriginalDesk = this.selectedDeskOption
      if (res) {
        this.selectedDeskOption = deskLink[data.deskSet]
        data.desk = deskLink[data.deskSet];
        this.appStore.dispatch(setdeskIDSearchedResponse({ deskSearchValues: data.deskSet, deskSetUpdated: true }));
      }
      else {
        this.selectedDeskOption = this.saveOriginalDesk
      }
    })
    if (data?.type === 'group') {
      data.values = !data.values ? this.masterGridSelectedRows[0]?.nominationNo : data.values;
        this.appStore.dispatch(setdeskIDSearchedResponse({ deskSearchValues: data.deskSet, deskSetUpdated: true }));
        this.isCargoDeskLogic(data, specificDesks, regionSelected)
        this.nominationService.sidePanel$.next(false);
    }
    else if (data) {
      this.createPipelineNominationComponent.createNominationForSearch(data);
    } else {
      const params = { data: this.selectedRows[0] };
      if (params.data) {
        const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params, this.userEmail, this.selectedDeskOption, true);
        this.createPipelineNominationComponent.createNominationForSearch(pipelineNomination);
      }
    }

    const modal = { open: true, modal: 'createPipelineNomination' };
    this.pipelineNominationModal = JSON.parse(JSON.stringify(modal));
  }

  updateVesselScheduleDate(response:any) {
    this.pipelineVesselScheduleUpdate.updateVesselScheduleDate(response)
    const modal = { open: true, modal: 'pipelineVesselScheduleUpdate' };
    this.vesselScheduleUpdateModal = JSON.parse(JSON.stringify(modal));    
  }

  saveColumnOrder(payload: any) {
    const allState = this.gridColumnApi?.getColumnState();
    const columnOrderId = allState.map(state => {
      return { colId: state.colId, hide: state.hide }
    })
    payload.payload["nominationColumnOrderState"] = payload.doColumnOrder ? columnOrderId : [];
    this.ethanolService.customViewPayload$.next(payload.payload);
  }

  editCustomViewFilters(payload: any) {
    if (payload.columnOrderChecked) {
      const allState = this.gridColumnApi?.getColumnState();
      const columnOrderId = allState?.map(state => {
        return { colId: state.colId, hide: state.hide }
      })
      payload.filterValues.pipelineFilters.columnOrders["nominationColumnOrder"] = columnOrderId;
    } else {
      payload.filterValues.pipelineFilters.columnOrders["nominationColumnOrder"] = [];
    }
  }

  callColOrderAndExpansion(res: any) {
    let val: any = Object.values(res);
    if (val.length && localStorage.getItem("selectedView")) {
      this.applyColOrderAndExpansion(res);
    }
  }

  applyColOrderAndExpansion(res: any) {
    if (res.colOrder?.nominationColumnOrder?.length > 0) {
      this.loaderService.setLoading(true);
      setTimeout(() => {
        this.gridColumnApi?.applyColumnState({
          state: res.colOrder?.nominationColumnOrder,
          applyOrder: true,
        })
        this.allowForGetCustomView = false;
        this.loaderService.setLoading(false);
      }, 5000)
    }
    else {
      this.gridColumnApi?.resetColumnState();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
function groupRowAggNodes(Node: { new(): Node; prototype: Node; readonly ELEMENT_NODE: 1; readonly ATTRIBUTE_NODE: 2; readonly TEXT_NODE: 3; readonly CDATA_SECTION_NODE: 4; readonly ENTITY_REFERENCE_NODE: 5; readonly ENTITY_NODE: 6; readonly PROCESSING_INSTRUCTION_NODE: 7; readonly COMMENT_NODE: 8; readonly DOCUMENT_NODE: 9; readonly DOCUMENT_TYPE_NODE: 10; readonly DOCUMENT_FRAGMENT_NODE: 11; readonly NOTATION_NODE: 12; readonly DOCUMENT_POSITION_DISCONNECTED: 1; readonly DOCUMENT_POSITION_PRECEDING: 2; readonly DOCUMENT_POSITION_FOLLOWING: 4; readonly DOCUMENT_POSITION_CONTAINS: 8; readonly DOCUMENT_POSITION_CONTAINED_BY: 16; readonly DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC: 32; }) {
  throw new Error('Function not implemented.');
}

