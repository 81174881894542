import { Action, createReducer, on } from "@ngrx/store";
import { Desks, DeskLocation, CreateDeskLocationMapping, DeskFilters, DeskFunctionalGroup } from "../../../shared/interface/mdm-admin.interface";
import { loadLocationsDataSuccess,  loadDesksDataSuccess, setDefaultDesksFilter, updateDesksFilter, updateDesksTable, desksFilterUpdated, loadDeskLocationsSuccess, deleteDeskLocationMappingSuccess, resetDesksScreen, createDeskLocationMappingSuccess, createDeskLocationMapping, recievedNewDeskLocationId } from "../action/admin-desks.action";

export interface State {
  desksData: DeskFunctionalGroup[];
  initializeLocationsData: boolean;
  desks: Desks[];
  desksFilter: DeskFilters;
  initializeDesksData: boolean;
  initializeDesksFilter: boolean;
  updateDesksTable: boolean;
  updateDesksFilter: boolean;
  deskLocations: DeskLocation[];
  initializeDeskLocations: boolean;
  createDeskLocationMapping: CreateDeskLocationMapping;
  formatDesksData: boolean;
  addDeskLocationMapping: boolean;
  newDeskLocationId: number;
  setNewDeskLocationId: boolean;
}

export const initialState: State = {
  desksData: [],
  initializeLocationsData: false,
  desks: [],
  desksFilter: {
    functionalGroup: [],
    region: [],
    locationName: [],
    locationCode: [],
  },
  initializeDesksData: true,
  initializeDesksFilter: false,
  updateDesksTable: false,
  updateDesksFilter: false,
  deskLocations: [],
  initializeDeskLocations: false,
  createDeskLocationMapping: {
    deskId:NaN ,
    locationCode:''
  },
  formatDesksData: false,
  addDeskLocationMapping: false,
  newDeskLocationId: NaN,
  setNewDeskLocationId: false
};

const desksReducer = createReducer(
  initialState,
  on(loadLocationsDataSuccess, (state, { locationsData }) => ({
      ...state,
      locationsData: locationsData,
      initializeLocationsData: false,
      formatDesksData: true,
  })),
  on(loadDesksDataSuccess, (state, { desks }) => ({
    ...state,
    desks: desks,
    initializeDesksData: false,
    initializeDeskLocations: true,
  })),
  on(setDefaultDesksFilter, (state, { desksFilter, desksData }) => ({
    ...state,
    desksFilter: desksFilter,
    desksData: desksData,
    initializeDesksFilter: false,
    updateDesksFilter: true
  })),
  on(updateDesksFilter, (state, { desksFilter, desksData }) => ({
    ...state,
    desksFilter: desksFilter,
    desksData: desksData,
    updateDesksFilter: true
  })),
  on(updateDesksTable, (state, { desksData, updateDesksTable, initializeDesksFilter }) => ({
    ...state,
    updateDesksTable: updateDesksTable,
    desksData: desksData,
    formatDesksData: false,
    initializeDesksFilter: initializeDesksFilter
  })),
  on(desksFilterUpdated, (state, { updateDesksFilter }) => ({
    ...state,
    updateDesksFilter: updateDesksFilter
  })),
  on(loadDeskLocationsSuccess, (state, { deskLocations }) => ({
    ...state,
    deskLocations: deskLocations,
    initializeDeskLocations: false,
    initializeLocationsData: true,
  })),
  on(deleteDeskLocationMappingSuccess, (state) => ({
    ...state
  })),
  on(createDeskLocationMapping, (state) => ({
    ...state,
    addDeskLocationMapping: true
  })),
  on(createDeskLocationMappingSuccess, (state, {newDeskLocationId} ) => ({
    ...state,
    newDeskLocationId: newDeskLocationId,
    setNewDeskLocationId: true
  })),
  on(resetDesksScreen, (state) => ({
    ...state,
    initializeLocationsData: false,
    initializeDesksData: true,
    initializeDesksFilter: false,
    updateDesksFilter: false,
    updateDesksTable: false,
    initializeDeskLocations: false
  })),
  on(recievedNewDeskLocationId, (state) => ({
    ...state,
    setNewDeskLocationId: false
  }))
);

export function reducer(state: State | undefined, action: Action): any {
    return desksReducer(state, action);
}

export const getLocationsData = (state: State) => ({ locationsData: state.desksData, initializeLocationsData: state.initializeLocationsData });

export const getDesksData = (state: State) => ({ desks: state.desks, initializeDesksData: state.initializeDesksData, updateDesksTable: state.updateDesksTable, updateDesksFilter: state.updateDesksFilter });

export const getDesksFilter = (state: State) => ({ desksFilter: state.desksFilter, initializeDesksFilter: state.initializeDesksFilter, updateDesksFilter: state.updateDesksFilter, updateDesksTable: state.updateDesksTable });

export const getDeskLocationsData = (state: State) => ({ deskLocations: state.deskLocations, initializeDeskLocations: state.initializeDeskLocations, initializeDesksData: state.initializeDesksData, addDeskLocationMapping: state.addDeskLocationMapping });