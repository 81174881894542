import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import {
  InformationPanelService,
  infoPanelData,
} from 'src/app/services/information-panel.service';

@Component({
  selector: 'info-panel-content',
  templateUrl: './info-content.component.html',
  styleUrls: ['./info-content.component.scss'],
})
export class InfoContentComponent implements OnInit {
  data!: infoPanelData;
  dateFormat!: string;
  headers: any = [
    { displayValue: 'details', selector: tabEnum.DET },
    {
      displayIcon: '../../../../../assets/icons/icon-AT-Notification.svg',
      displayValue: 'notifications',
      selector: tabEnum.ALRT,
    },
    { displayValue: 'comments', selector: tabEnum.COM },
  ];

  @Input() selectedTabIndex: number = 0;
  @Input() selectedSelector: tabEnum = tabEnum.DET;
  
  constructor(private infoPanelService: InformationPanelService) {}

  ngOnInit(): void {
    this.infoPanelService.infoPanelData.subscribe((data: infoPanelData) => {
      this.data = data;
    });
    this.infoPanelService.infoPanelSelectedTab.subscribe((tab) => {
      this.updateSelection(tab);
    });
    this.infoPanelService.infoPanelSelectedSelector.subscribe((selector) => {
      this.selectedSelector = selector;
      this.updateTab(selector);
    });

    this.infoPanelService.infoPanelUndocked.subscribe((undocked: boolean) => {
      if (undocked && this.headers.length == 3) {
        this.headers.push({
          displayValue: 'activity tracker',
          selector: tabEnum.AT,
        });
        this.updateSelection(this.headers.length - 1);
      } else if (!undocked && this.headers.length == 4) {
        this.headers.pop();
        this.updateSelection(0);
      }
    });
  }

  public updateSelection(index: number) {
    if (index == this.selectedTabIndex) {
      return;
    }
    this.selectedTabIndex = index;
    this.selectedSelector = this.headers[this.selectedTabIndex].selector;
  }

  public updateTab(tab: tabEnum) {
    this.selectedTabIndex = this.headers.findIndex((header: any) => {
      return header.selector == tab;
    });
  }
}

export enum tabEnum {
  DET = 'details',
  ALRT = 'alert',
  COM = 'comments',
  AT = 'activityTracker',
}
