import { Injectable } from "@angular/core";
import  * as signalR  from '@microsoft/signalr';
import { firstValueFrom, Subject} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalAngularService } from '@cvx/cal-angular';


@Injectable()
export class RealTimeAlertService {
 private hubConnection: any;
 messages: Subject<[]> = new Subject();
 constructor(private authService: CalAngularService) { }
 connect() {
  const userId = this.authService.cvxClaimsPrincipal.email.toLowerCase();
  this.hubConnection = new signalR.HubConnectionBuilder()
  .withUrl(environment.rtAlertAPI +'/?userid='+ userId,{ 
    accessTokenFactory: async() => {
      const tok = await firstValueFrom(this.authService.getAADToken([environment.rtAlertTokenURL], false));
      return tok?.toString() ?? '';
    }
   })
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Information)
  .build();

  this.hubConnection.start()
  .then(() => console.log('Connection started'))
  .catch((err: { toString: () => any; }) => console.error(err.toString()))

  this.hubConnection.on('notify', (data: any) => {
      console.log("Real time message recieved")
      this.messages.next(data);
  });
}

}
