<div class="panel-content">
    <ng-container>
        <div>
            <div class="content__header">terminal</div>
            <form class="branded">
                <input class="form-control settings-parmeter-search " [name]="'searchText0'" type="search"
                    placeholder="search" (input)="updateParameterLocationFilterList($any($event.target).value)"
                    [(ngModel)]="locationsearchValue">
                <div class="settings-parameter-side-panel">
                    <p *ngFor="let obj of updateSettingsParameterLocationSearchData let i = index; "
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.location"
                            [value]="obj.location" [checked]="obj.checked" (change)="filterByParamaterLocation($event)">
                        <label class="text-ellipsis" [title]="obj.location" [htmlFor]="obj.location">
                            {{obj.location}}
                        </label>
                    </p>
                </div>
            </form>
            <hr>
        </div>
        <div *ngIf="updateSettingsParameterUniqueMaterialSearchData.length">
            <div class="content__header">material
            </div>
            <form class="branded">
                <input class="form-control settings-parmeter-search " [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateParameterMaterialsFilterList($any($event.target).value)"
                    [(ngModel)]="materialsearchValue">
                <div class="settings-parameter-side-panel">
                    <p *ngFor="let obj of updateSettingsParameterUniqueMaterialSearchData; let i = index; "
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="MaterialCheckbox" [id]="obj.materialnumber"
                            [value]="obj.materialnumber" [checked]="obj.checked"
                            (change)="filterByParameterMaterial($event)">
                        <label class="text-ellipsis" [title]="obj.materialnumber" [htmlFor]="obj.materialnumber">
                            {{obj.material}}
                        </label>
                    </p>
                </div>
            </form>
        </div>
        <div *ngIf="updateSettingsUniqueParameterSearchData.length">
            <div class="content__header">parameter
            </div>
            <form class="branded">
                <input class="form-control settings-parmeter-search " [name]="'searchText2'" type="search"
                    placeholder="search" (input)="updateParametersFilterList($any($event.target).value)"
                    [(ngModel)]="rulesearchValue">
                <div class="settings-alert-rules-side-panel">
                    <p *ngFor="let obj of updateSettingsUniqueParameterSearchData; let i = index; "
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="RuleCheckbox" [id]="obj.parametername"
                            [value]="obj.parametername" [checked]="obj.checked" (change)="filterByParameter($event)">
                        <label class="text-ellipsis" [title]="obj.parametername" [htmlFor]="obj.parametername">
                            {{obj.parametername}}
                        </label>
                    </p>
                </div>
            </form>
        </div>
    </ng-container>
</div>

<div class="panel-footer">
    <span class="clear-filter" (click)="clearFilters()">clear</span>
</div>