import { Component, ChangeDetectorRef, OnInit} from '@angular/core';

import { AlertsService } from 'src/app/services/alerts.service';
import { AlertRuleResponse } from 'src/app/shared/interface/settings.interface';
import { CalAngularService } from '@cvx/cal-angular';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { DismissAlertService } from 'src/app/services/dismiss-alert.service';
import { AlertCardDivider, DeskFilterAlert, NotificationState } from 'src/app/shared/interface/workspace-alert.interface';
import { AlertsNotificationService } from 'src/app/services/alerts-notification.service';

@Component({
  selector: 'app-create-alert-notifications-side-panel',
  templateUrl: './create-alert-notifications-side-panel.component.html',
  styleUrls: ['./create-alert-notifications-side-panel.component.scss']
})
export class CreateAlertNotificationsSidePanelComponent implements OnInit {

  userAlerts: AlertRuleResponse[] = [];
  filteredUserAlerts: AlertRuleResponse[] = [];
  dividedCardList: any = [];
  deskFilterList: DeskFilterAlert[] = [];

  selectedDesk: string = '';
  selectedMaterial = '';
  selectedTerminal = '';
  selectedRuleType = '';

  desksList: string[] = [];
  locList: string[] = [];
  public matNameList: string[] = [];
  public rulesDropdown: string[] = [];


  dismissedAnAlert: boolean = false;

  dismissAlertModal = { open: false, modal: 'dismissModal' };
  subscriptionDataFromParent: any;

  constructor(private alertService: AlertsService,
    private authService: CalAngularService,
    private utilities: Utilities,
    private dismissAlertService: DismissAlertService,
    private alertsNotificationService: AlertsNotificationService,
    private changeDetector: ChangeDetectorRef,) { }

  ngOnInit(): void {


    //Subscribe to service so when any change (dismiss/SignalR) to the user's alerts occur, it will propogate to side panel
    this.alertsNotificationService.userAlertsSubscribeEmitter.subscribe(response => {


      let previousCount = this.userAlerts.length;
      this.userAlerts = response.filter(_ => _.isActive);

      let currentCount =  this.userAlerts.length;


      this.determineShowBellActive(previousCount, currentCount);

      //If user used bell to open side panel, we just open normally
      if(this.alertService.sidePanelOpenCondition$.value === NotificationState.UserOpen || this.dismissedAnAlert === true){

        this.filterUserAlerts();
        this.deskSetup();
        this.generateNonSelectedDeskList();  

      //Interaction with TR screen requires incremental build-up of drop downs based on alert properties
      }else if(this.alertService.sidePanelOpenCondition$.value === NotificationState.TR){

        this.setUpdateParameters();
        this.filterUserAlerts();
        this.generateDividedCardList();  
      }
    });


    //Do not open new panel each click, we can simply update view based on a change of interaction from user via TR and/or SignalR
    this.alertService.sidePanelOpenCondition$.subscribe(response => {

      if(this.alertService.sidePanelOpenCondition$.value === NotificationState.UserOpen && this.alertService.sidePanel$.value === true){

        this.setDropDownArraysToEmpty(this.desksList, this.locList, this.matNameList, this.rulesDropdown, this.deskFilterList);
        this.resetSelectedProperties(this, ['selectedDesk', 'selectedTerminal', 'selectedMaterial', 'selectedRuleType']);
        this.filterUserAlerts();
        this.deskSetup();
        this.generateNonSelectedDeskList();  
        


      }else if(this.alertService.sidePanelOpenCondition$.value === NotificationState.TR){

        this.setDropDownArraysToEmpty(this.desksList, this.locList, this.matNameList, this.rulesDropdown, this.deskFilterList);
        this.resetSelectedProperties(this, ['selectedDesk', 'selectedTerminal', 'selectedMaterial', 'selectedRuleType']);
        this.setUpdateParameters();
        this.filterUserAlerts();
        this.generateDividedCardList();  
      }

    })

      this.dismissAlertService.showModalObservable$.subscribe(showModal => this.dismissAlertModal.open = showModal);
  }

  AfterViewChecked(){
    this.changeDetector.detectChanges();
  }


  determineShowBellActive(previousCount: number, currentCount: number){

    if(previousCount < currentCount){
      this.alertsNotificationService.showBell.next(true);
    }
  }

  //When we use TR screen, we have pre-determined filters based on alert location in TR
  setUpdateParameters(){

    //capture the first alert due to all alerts being same filter setup
    let alertOfInterest = this.alertService.sidePanelData$.value[0];

    this.selectedDesk = alertOfInterest.desk.toLowerCase();
    this.createDeskList();

    this.filterUserAlerts();
    this.createLocationList();
    this.selectedTerminal = alertOfInterest.location;


    this.filterUserAlerts();
    this.createMaterialList();
    this.selectedMaterial = alertOfInterest.material;


    this.createRuleList();
  }

  deskSetup() {

    this.createDeskList();
    this.generateDividedCardList();
  }

  updateForTerminalSelection() {

    this.clearMaterialsDropdown();
    this.clearRulesDropdown();
    this.clearTerminalDropdown();
    this.filterUserAlerts();
    this.createLocationList();
    this.generateDividedCardList();
  }

  updateForMaterialSelection() {

    this.clearRulesDropdown();
    this.clearMaterialsDropdown();
    this.filterUserAlerts();
    this.createMaterialList();
    this.generateDividedCardList();
  }

  updateForRuleSelection() {

    this.clearRulesDropdown();
    this.filterUserAlerts();
    this.createRuleList();
    this.generateDividedCardList();
  }

  ruleDropDownUpdate() {

    this.filterUserAlerts();
    this.generateDividedCardList();
  }

  createDeskList() {

    let deskSet = new Set<string>();
    this.userAlerts.forEach(_ => {
      deskSet.add(_.desk.toLowerCase());
    });

    this.desksList = Array.from(deskSet);
  }

  createLocationList() {

    if (this.selectedDesk === "") {
      this.deskFilterList = [];
      return;
    }

    this.generateNonSelectedDeskList();

    let terminalSet = new Set<string>();
    this.filteredUserAlerts.forEach(_ => {
      terminalSet.add(_.location);
    });

    this.locList = Array.from(terminalSet);
  }

  createMaterialList() {

    if (this.selectedTerminal === "") {
      return;
    }

    let materialSet = new Set<string>();
    this.filteredUserAlerts.forEach(_ => {
      materialSet.add(_.material);
    });

    this.matNameList = Array.from(materialSet);
  }


  createRuleList() {

    if (this.selectedMaterial === "") {
      return;
    }

    let ruleSet = new Set<string>();
    this.filteredUserAlerts.forEach(_ => {
      ruleSet.add(_.ruleType)
    })
    this.rulesDropdown = Array.from(ruleSet);

  }


  filterUserAlerts() {

    if (this.selectedDesk === '') {
      this.filteredUserAlerts = this.userAlerts;
    } else {

      this.filteredUserAlerts = this.returnFilterList();
    }
  }

  returnFilterList() {

    return this.userAlerts.filter(currentAlert =>
      (currentAlert.desk.toLowerCase().includes(this.selectedDesk.toLowerCase()))
      && (currentAlert.location.toLowerCase().includes(this.selectedTerminal.toLowerCase()))
      && (currentAlert.material.toLowerCase().includes(this.selectedMaterial.toLowerCase()))
      && (currentAlert.ruleType.toLowerCase().includes(this.selectedRuleType.toLowerCase())));
  }

  generateDividedCardList() {

    this.dividedCardList = [];
    let dividerSet: Set<string> = this.generateDividerSet();
    this.dividedCardList = [];
    this.dividedCardList = this.generateFullDividedList(dividerSet);
  }

  generateDividerSet(){

    let dividerSet: Set<string> = new Set<string>();

    this.filteredUserAlerts.forEach(currentAlert => {

      if (this.selectedDesk === '') {
        dividerSet.add(currentAlert.desk);
      } else if (this.selectedTerminal === '') {
        dividerSet.add(currentAlert.location);
      } else if (this.selectedMaterial === '') {
        dividerSet.add(currentAlert.material);
      } else if (this.selectedRuleType === '') {
        dividerSet.add(currentAlert.ruleType);
      }
    })

    return dividerSet;
  }

  generateFullDividedList(dividerSet: Set<string>){

    let dividerCardList: AlertCardDivider[] = [];

    if (dividerSet.size > 0) {

      dividerSet.forEach(currentDivider => {
        let currentCardDivider: AlertCardDivider = { divider: currentDivider, alertList: [] };
        this.buildCardDivider(currentDivider, currentCardDivider);
        dividerCardList.push(currentCardDivider);
      })
      
    } else {

      let currentCardDivider: AlertCardDivider = { divider: '', alertList: [] };
      this.generateDividerListBasedOnRuleType(currentCardDivider);
      dividerCardList.push(currentCardDivider);
    }

    return dividerCardList;
  }

  buildCardDivider(divider: string, cardDivider: AlertCardDivider): void{
    this.filteredUserAlerts.forEach(currentAlert => {

      if (this.selectedDesk === '' && currentAlert.desk === divider 
          || this.selectedTerminal === '' && currentAlert.location === divider
          || this.selectedMaterial === '' && currentAlert.material === divider
          || this.selectedRuleType === '' && currentAlert.ruleType === divider) {
            cardDivider.alertList.push(currentAlert);
      }
    });
  }

  generateDividerListBasedOnRuleType(cardDivider: AlertCardDivider): void{
    this.filteredUserAlerts.forEach(currentAlert => {

      if (this.selectedRuleType.toLowerCase() === currentAlert.ruleType.toLowerCase()) {
        cardDivider.alertList.push(currentAlert);
      }
    })
  }

  generateNonSelectedDeskList() {

    if (this.selectedDesk === "") {
      return;
    }

    this.deskFilterList = [];

    let nonSelectedDeskMap = new Map<string, number>();

    this.userAlerts.forEach(_ => {

      if (_.desk.toLowerCase() !== this.selectedDesk.toLowerCase()) {

        if (!nonSelectedDeskMap.has(_.desk.toLowerCase())) {
          nonSelectedDeskMap.set(_.desk.toLowerCase(), 1);
        } else {
          nonSelectedDeskMap.set(_.desk.toLowerCase(), nonSelectedDeskMap.get(_.desk.toLowerCase())! + 1);
        }
      }
    });

    nonSelectedDeskMap.forEach((value, key) => {

      this.deskFilterList.push({
        deskName: key,
        numberOfItems: value,
      })
    })
  }

  changeDeskFilterUponClick(event: string) {
    this.selectedDesk = event;
    this.updateForTerminalSelection();
  }


  closeAlertNotificationPanel() {
    this.alertService.sidePanel$.next(false);
  }

  clearRulesDropdown() {
    this.rulesDropdown = [];
    this.selectedRuleType = '';
  }

  clearMaterialsDropdown() {
    this.matNameList = [];
    this.selectedMaterial = '';
  }

  clearTerminalDropdown() {
    this.locList = [];
    this.selectedTerminal = '';

  }

  removeDismissedAlert(alertToRemove: AlertRuleResponse) {

    this.dismissedAnAlert = true;

    this.userAlerts = this.userAlerts.filter(_ => _.id !== alertToRemove.id);

    let alteredRule: boolean = this.handleRuleDropDownDismiss(alertToRemove);
    let alteredMaterial: boolean = this.handleMaterialDropDownDismiss(alertToRemove, alteredRule);
    this.handleTerminalDropDownDismiss(alertToRemove, alteredMaterial);
    this.handleDeskDropDown(alertToRemove);   

    this.filterUserAlerts();
    this.generateDividedCardList();
  }

  
  hasMoreMaterialAlerts(alertToRemove: AlertRuleResponse){
      
    return this.userAlerts.filter(_ => _.desk.toLowerCase() === alertToRemove.desk.toLowerCase() 
      && _.location.toLowerCase() === alertToRemove.location.toLowerCase()
      && _.material.toLowerCase() === alertToRemove.material.toLowerCase()
      && _.ruleType.includes('')).length !== 0;
  }

  hasMoreTerminalAlerts(alertToRemove: AlertRuleResponse){
    return this.userAlerts.filter(_ => _.desk.toLowerCase() === alertToRemove.desk.toLowerCase() 
      &&  _.location.toLowerCase() === alertToRemove.location.toLowerCase()).length !== 0;
  }


  hasMoreDeskAlerts(alertToRemove: AlertRuleResponse){
    return this.userAlerts.filter(_ => _.desk.toLowerCase() === alertToRemove.desk.toLowerCase()).length !== 0;
  }
  
  handleRuleDropDownDismiss(alertToRemove: AlertRuleResponse): boolean{

    if(this.rulesDropdown.length !== 0 && this.rulesDropdown.filter(_ => this.lowerCaseCompare(_, alertToRemove.ruleType)).length === 0 && !this.hasMoreMaterialAlerts(alertToRemove)){
      
      this.rulesDropdown = [];



      this.matNameList = this.matNameList.filter(_ => _.toLowerCase() !== alertToRemove.material.toLowerCase());
      this.resetSelectedProperties(this, ['selectedMaterial', 'selectedRuleType']);
      return true;

    }else if(this.hasMoreMaterialAlerts(alertToRemove) && this.rulesDropdown.filter(_ => this.lowerCaseCompare(_, alertToRemove.ruleType)).length !== 0){

      if(this.filteredUserAlerts.filter(_ => this.lowerCaseCompare(_.ruleType, alertToRemove.ruleType)).length === 1){
        this.rulesDropdown = this.rulesDropdown.filter(_ => _.toLowerCase() !== alertToRemove.ruleType.toLowerCase());
      }
      this.selectedRuleType = '';
    }

    return false;
  }

  handleMaterialDropDownDismiss(alertToRemove: AlertRuleResponse, alteredRule: boolean): boolean{


    if((this.matNameList.length !== 0 || (alteredRule && this.matNameList.length === 0)) &&  this.matNameList.filter(_ => _.toLowerCase() !== alertToRemove.material.toLowerCase()).length === 0 && !this.hasMoreTerminalAlerts(alertToRemove)){


      this.resetSelectedProperties(this, ['selectedTerminal', 'selectedMaterial']);
      this.setDropDownArraysToEmpty(this.matNameList, this.rulesDropdown);
      return true;

    
    }else if(this.matNameList.filter(_ => _.toLowerCase() !== alertToRemove.material.toLowerCase()).length !== 0 && this.hasMoreMaterialAlerts(alertToRemove)){

      this.rulesDropdown = this.rulesDropdown.filter(_ => _.toLowerCase() !== alertToRemove.ruleType.toLowerCase());
      this.selectedRuleType = '';

    }else if(this.matNameList.filter(_ => _.toLowerCase() !== alertToRemove.material.toLowerCase()).length !== 0 && this.hasMoreTerminalAlerts(alertToRemove)){

      this.matNameList = this.matNameList.filter(_ => _.toLowerCase() !== alertToRemove.material.toLowerCase());
      this.resetSelectedProperties(this, ['selectedMaterial', 'selectedRuleType']);
      this.rulesDropdown = [];
    }

    return false;
  }

  handleTerminalDropDownDismiss(alertToRemove: AlertRuleResponse, alteredMaterial: boolean){

    if(this.locList !== undefined && (this.locList.length !== 0 || (alteredMaterial && this.locList.length === 0)) &&  this.locList.filter(_ => _.toLowerCase() !== alertToRemove.location.toLowerCase()).length === 0 && !this.hasMoreDeskAlerts(alertToRemove)){

      this.desksList = this.desksList.filter(_ => _.toLowerCase() !== alertToRemove.desk.toLowerCase())
      this.resetSelectedProperties(this, ['selectedTerminal', 'selectedMaterial', 'selectedRuleType']);
      this.setDropDownArraysToEmpty(this.locList, this.matNameList, this.rulesDropdown);
    
    }else if(this.locList !== undefined && this.locList.filter(_ => this.lowerCaseCompare(_, alertToRemove.location)).length !== 0 && !this.hasMoreMaterialAlerts(alertToRemove) && this.hasMoreTerminalAlerts(alertToRemove)){

      this.matNameList = this.removeAlertMaterial(alertToRemove);
      this.selectedMaterial = '';
      
    }else if(this.locList !== undefined && this.locList.filter(_ => this.lowerCaseCompare(_, alertToRemove.location)).length !== 0 && !this.hasMoreMaterialAlerts(alertToRemove) && !this.hasMoreTerminalAlerts(alertToRemove)){
      
      this.matNameList = this.removeAlertMaterial(alertToRemove);
      this.locList = this.locList.filter(_ => _.toLowerCase() !== alertToRemove.location.toLowerCase());
      this.resetSelectedProperties(this, ['selectedTerminal', 'selectedMaterial']);
    }
  }

  handleDeskDropDown(alertToRemove: AlertRuleResponse){
    
    if(this.userAlerts.filter(_ => _.desk.toLowerCase() === alertToRemove.desk.toLowerCase()).length === 0 || (this.selectedDesk === '' && !this.hasMoreDeskAlerts(alertToRemove))){

      this.deskFilterList = [];
      this.selectedDesk = '';
      this.desksList = this.desksList.filter(_ => _.toLowerCase() !== alertToRemove.desk.toLowerCase());
    }
  }

  removeAlertMaterial(alertToRemove: AlertRuleResponse): string[]{

    let materialSet = new Set<string>();

    this.userAlerts.forEach(_ => {

      if(_.desk.toLowerCase() === alertToRemove.desk.toLowerCase()
      && _.location.toLowerCase() === alertToRemove.location.toLocaleLowerCase()
      && _.material.toLowerCase() !== alertToRemove.material.toLowerCase()){

        materialSet.add(_.material.toLowerCase());
      }
    });
    return Array.from(materialSet);
  }

  setDropDownArraysToEmpty(...values: any[][]){
    for(let value of values){
      value.length = 0;
    }
  }

  resetSelectedProperties(obj: any, properties: string[]) {
    for (let prop of properties) {
      obj[prop] = '';
    }
  }

  lowerCaseCompare(stringOne: string, stringTwo: string){
    return stringOne.toLowerCase() !== stringTwo.toLowerCase()
  }
}