import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of } from 'rxjs';
import { DeskFunctionalGroup, MaterialMappingFilter } from '../shared/interface/mdm-admin.interface';
import { dummyDeskData } from '../shared/constants/cca-dummy.constatnt';


@Injectable({
  providedIn: 'root'
})
export class MdmService {

  constructor(private apiService: ApiService) { }
  private deleteRequested = new BehaviorSubject<boolean>(false);
  private actionConfirmed = new  BehaviorSubject<boolean>(false);
  private invokeToggle = new BehaviorSubject<boolean>(false);
  private invokeReplenishmentToggle = new BehaviorSubject<boolean>(false);
  private locationToDelete = new BehaviorSubject<string>("");
  private transportToDelete = new BehaviorSubject<string>("");
  private desksData = new BehaviorSubject<DeskFunctionalGroup[]>([]);
  deleteRequested$ = this.deleteRequested.asObservable();
  actionConfirmed$ = this.actionConfirmed.asObservable();
  invokeToggle$ = this.invokeToggle.asObservable();
  invokeReplenishmentToggle$ = this.invokeReplenishmentToggle.asObservable();
  locationToDelete$ = this.locationToDelete.asObservable();
  transportToDelete$ = this.transportToDelete.asObservable();
  desksData$ = this.desksData.asObservable();
  public pavisResponse: any;

  setDesksData(val : DeskFunctionalGroup[]) {
    this.desksData.next(val);
  }
  requestDelete(val : boolean) {
    this.deleteRequested.next(val);
  }
  actionConfirm(val: boolean){
    this.actionConfirmed.next(val);
  }

  invokeToggleMethod(val: boolean){
    this.invokeToggle.next(val);
  }

  invokeReplenishmentToggleMethod(val: boolean){
    this.invokeReplenishmentToggle.next(val);
  }
  
  locationDelete(val : string) {
    this.locationToDelete.next(val);
  }

  transportDelete(val : string) {
    this.transportToDelete.next(val);
  }

  getAllDesks(nestedView?:any): Observable<any> {
    let param = nestedView ? `?nestedView=${nestedView}` : ''
    return this.apiService
    .get(
      `${environment.mdmAPI}/GetAllDesks${param}`,
      'mdm'
    )
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDeskLocation(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetDeskLocation`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAllDeskTsMappings():Observable<any>{
    return this.apiService
      .get(
        `${environment.mdmAPI}/GetAllDeskTsMappings`,
        'mdm',
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTransportSystemDetails(payload : any):Observable<any>{
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetTransportSystemDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  createDesk(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/CreateDesk`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteDesk(payload: any): Observable<any> {
    return this.apiService
      .delete(
        `${environment.mdmAPI}/DeleteDesk?id=${payload.deskId}`,
        'mdm',
        payload.body
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }
  deleteDeskLocationMapping(payload: any): Observable<any> {
    return this.apiService
      .trueDelete(
        `${environment.mdmAPI}/DeleteDeskLocationMapping?deskLocationId=${payload.deskLocationId}`,
        'mdm',
        payload.body
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  deleteDeskTransportMapping(payload: any): Observable<any> {

    return this.apiService
      .trueDelete(
        `${environment.mdmAPI}/DeleteDeskTsMapping?id=${payload.id}`,
        'mdm',
        payload.body
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  getDesksLocation(): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetDeskLocation`,
        'mdm',
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMaterialLocationMappings(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetMaterialLocationMappings`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAllLocations(showDetails: string, trackInvOnly: string): Observable<any> {
    return this.apiService
      .get(
        `${environment.mdmAPI}/GetAllLocations?showDetails=${showDetails}&trackInvOnly=${trackInvOnly}`,
        'mdm'
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  updateLocation(editedLocationsData: any): Observable<any> {
    return this.apiService
      .put(
        `${environment.mdmAPI}/updateLocation`,
        'mdm',
        editedLocationsData
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  getMaterialMapping(payLoad: MaterialMappingFilter): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetMaterialLocationMappings`,
        'mdm',
        payLoad
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  updateMaterialMapping(editedMaterialMappingData: any): Observable<any> {
    return this.apiService
      .put(
        `${environment.mdmAPI}/UpdateMaterialLocationMapping`,
        'mdm',
        editedMaterialMappingData
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  createDeskLocationMapping(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/CreateDeskLocationMapping`,
        'mdm',
        payload
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  createDeskTsMapping(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/CreateDeskTsMapping`,
        'mdm',
        payload
      )
      .pipe(
        map((data) => {
          return data
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }
  getAggregateData(id: any, payloadBody?:any): Observable<any> {
    id=(isNaN(id)) ? 1 : id;
    let payload = {
      "logicalOperator": "AND",
      "conditions": [
          {
              "field": "active",
              "operator": "eq",
              "value": true
          },
          ...(payloadBody ?? []),
          ]
      }
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetAggregateData?deskId=${id}&filterDeskTs=true`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getVehiclesByTs(payload:any){
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetVehiclesByTs`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getVehicles(payload:any){
   let vehiclePayload = {
      "vehicleTypes": [
        payload
      ]
    }
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetVehicles`,
        'mdm',
        vehiclePayload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMDMDataForBCP(id: any): Observable<any> {   
    let payload = {
      logicalOperator: "AND",
      conditions: [
          {
              field: "exchangeTerminal",
              value: false,
              operator: "eq"
          },
          {
              field: "active",
              value: true,
              operator: "eq"
          },
          {
            field: "trackInv",
            value: true,
            operator: "eq"
        }    
      ]
  };
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetMultiDeskAggregateData?deskIds=${id}`,
        'mdm',
        payload        
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPavisPort ():Observable<any>{
    return this.apiService
      .get(
        `${environment.mdmAPI}/GetDeskPavisPorts`,
        'mdm',
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSupplierCarrier(payload:any){
    return this.apiService
    .post(
      `${environment.mdmAPI}/GetPartnerRoles`,
      'mdm',
      payload,
    )
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  
  

  
  getDeskPortTerminal(res?: any, desk:string ='', port:string='', terminal:string='', params:any = ''): any {
    let deskList: any = [];
    let portList: any = [];
    let terminalList: any = [];
    let countryName: any = '';
    if (res.value.regionDeskMapping[0].region === 'LATAM') {
      res.value.regionDeskMapping[0].deskMappings.map((deskArray: any) => {
        // let checkTerminalDesk: any;
        // deskArray.deskPortMapping.map((res) => {
        //   checkTerminalDesk = res.portTerminalMapping.find((res) => res.terminalName === params?.data?.terminal)
        // })
        // if ((portName === '' || deskArray.deskPortMapping.find((res) => res.portName === portName)) && (terminalName === '' || checkTerminalDesk))
          deskList.push(deskArray.deskName);


        // if (desk === '' || deskArray.deskName ===  desk) {
          deskArray.deskPortMapping.map((portArray: any) => {
            // if (( params?.data?.terminal === '' || portArray.portTerminalMapping.find((res) => res.terminalName ===  params?.data?.terminal)))
              portList.push(portArray.portName);

            if (port === '' || portArray.portName ===  port) {
              portArray.portTerminalMapping.map((terminalArray: any) => {
                terminalList.push(terminalArray.terminalName);
                return terminalArray;
              })
              countryName = port === '' ? '' : portArray.countryName
            }
            return portArray;
          })
        // }
        return deskArray;
      });
      deskList = [...new Set(deskList)];
      portList = [...new Set(portList)];
      terminalList = [...new Set(terminalList)];
      if(params){
        let portValue = (portList.includes(port))?port:''
        let terminalValue = terminalList.includes(terminal)?terminal:''
        params.data['port'] = portValue
        params.data['terminal'] = terminalValue
        params.data['LocationName'] = countryName
      }
      
      let data = { deskArray: deskList, portArray: portList, terminalArray: terminalList, countryName };
      return data;
    }
  }
  getTswLocationByPort (port: string):Observable<any>{
    return this.apiService
      .get(
        `${environment.mdmAPI}/GetTswLocationByPort?unLoCode=${port}`,
        'mdm',
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}