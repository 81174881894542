import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertRuleResponse } from '../shared/interface/settings.interface';


@Injectable({
  providedIn: 'root'
})
export class DismissAlertService {


  private alertToDismiss!: AlertRuleResponse;
  dismissAlertSubject: BehaviorSubject<string> = new BehaviorSubject("");

  private alertName!: string;
  private alertID!: string;
  hideCard = new BehaviorSubject<boolean>(false);
  hideCardObsrevable$ = this.hideCard.asObservable();

  showModal = new BehaviorSubject<boolean>(false);
  showModalObservable$ = this.showModal.asObservable();



  provideAlertInformation(alertName: string, alertLocalID: string, cardAlert: AlertRuleResponse){
    this.alertName = alertName;
    this.alertID = alertLocalID;
    this.alertToDismiss = cardAlert;
  }

  getAlertInformation(){
    return {
      alertName: this.alertName,
      alertID: this.alertID,
      alertToDismiss: this.alertToDismiss, 
    };
  }

  
  setModalShow(changeValue: boolean): void{
    
    if(changeValue){
      this.hideCard.next(false);
    } 
    
    this.showModal.next(changeValue);
  }

  dismissCurrentCard(){

      this.dismissAlertSubject.next(this.alertToDismiss.id);
  }
}
