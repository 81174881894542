import { Action, createReducer, on } from "@ngrx/store";
import { LocationData, EditedLocationData, LocationFilters } from "../../../../app/shared/interface/mdm-admin.interface";
import { loadLocationsDataSuccess, updateLocationsDataSuccess, setDefaultLocationFilters, updateLocationFilters, updateLocationsTable, locationFiltersUpdated, resetLocationsScreen, updateEditedLocations, addEditedLocations } from "../action/admin-locations.action";

export interface State {
    editedLocationsData: EditedLocationData[];
    locationsData: LocationData[];
    locationFilters: LocationFilters;
    initializeLocationsData: boolean;
    initializeLocationFilters: boolean;
    updateLocationsTable: boolean;
    updateLocationFilters: boolean;
    addEditedChanges: boolean;
}

export const initialState: State = {
    editedLocationsData: [],
    locationsData: [],
    locationFilters: {
      locationName: [],
      locationCode: [],
      exchangeTerminal: []
    },
    initializeLocationsData: true,
    initializeLocationFilters: false,
    updateLocationsTable: false,
    updateLocationFilters: false,
    addEditedChanges: false
};

const locationsReducer = createReducer(
    initialState,
    on(loadLocationsDataSuccess, (state, { locationsData }) => ({
        ...state,
        locationsData: locationsData,
        initializeLocationsData: false,
        initializeLocationFilters: true,
        updateLocationsTable: true
    })),
    on(updateLocationsDataSuccess, (state, { editedLocationsData }) => ({
      ...state,
      editedLocationsData: editedLocationsData
    })),
    on(setDefaultLocationFilters, (state, { locationFilters, locationsData }) => ({
      ...state,
      locationFilters: locationFilters,
      locationsData: locationsData,
      initializeLocationFilters: false,
      updateLocationsTable: false,
      updateLocationFilters: true
    })),
    on(updateLocationFilters, (state, { locationFilters, locationsData }) => ({
      ...state,
      locationFilters: locationFilters,
      locationsData: locationsData,
      updateLocationFilters: true
    })),
    on(updateLocationsTable, (state, { updateLocationsTable }) => ({
      ...state,
      updateLocationsTable: updateLocationsTable
    })),
    on(locationFiltersUpdated, (state, { updateLocationFilters }) => ({
      ...state,
      updateLocationFilters: updateLocationFilters
    })),
    on(resetLocationsScreen, (state) => ({
      ...state,
      initializeLocationsData: true,
      initializeLocationFilters: false,
      updateLocationsTable: false,
      updateLocationFilters: false,
      addEditedChanges: false
    })),
    on(updateEditedLocations, (state, { locationsData }) => ({
      ...state,
      addEditedChanges: true,
      locationsData: locationsData
    })),
    on(addEditedLocations, (state) => ({
      ...state,
      addEditedChanges: false,
    }))
);

export function reducer(state: State | undefined, action: Action): any {
    return locationsReducer(state, action);
}

export const getLocationsData = (state: State) => ({
  locationsData: state.locationsData,
  editedLocationsData: state.editedLocationsData,
  locationFilters: state.locationFilters,
  initializeLocationsData: state.initializeLocationsData,
  initializeLocationFilters: state.initializeLocationFilters,
  updateLocationsTable: state.updateLocationsTable,
  updateLocationFilters: state.updateLocationFilters,
  addEditedChanges: state.addEditedChanges
});