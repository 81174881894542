<div class="ethanol-more-menu">
<span [ngClass]="[isNomPresent ? rowStatus : '']">{{ value }}</span>
<div class="more-menu-dropdown">
<button *ngIf="moremenuIconShow" class="ellipses-icon" (click)="moremwnu()"></button>
<div *ngIf="!isOpen" class="dropdown-content" [ngClass]="[compactView ? 'compact-dropdown':'', rowIndex < 8 ? '' : 'place-top']">
         <a class="dropdown-item ethanol-create-nomination" *ngIf="isDraft" (click)="onCreateDraftNomination()">create nomination</a>
    <a *ngIf="rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published'|| rowStatus?.toLowerCase() ==='failed'" class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination from
        reference</a>
    <a *ngIf="transLoadSplitConditionCheckUSEC || transLoadSplitConditionCheckUSWC" class="dropdown-item transload-split"
        (click)="transloadSplit()">transload transfer split</a>  
    <a *ngIf="rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='failed'" class="dropdown-item goToNominations"
        (click)="goToNominations()">go to nominations</a>
    <a class="dropdown-item ethanol-seeInNominationPannel" *ngIf="rowStatus?.toLowerCase() !=='failed' && !isTransloadColumn" (click)="seeInNominationPannel()">see in nomination panel</a>
    <a class="dropdown-item edit-nomination" *ngIf="(rowStatus?.toLowerCase() ==='published' && isEditable) || rowStatus?.toLowerCase() ==='failed'" (click)="editNomination()">edit nomination</a>
    <a class="dropdown-item ethanol-delete" *ngIf="!isTransloadColumn" (click)="delete()">delete</a>
</div>
</div>
</div>