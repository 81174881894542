import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { Utilities } from '../../utilities/utilities';
import { getUserDetails, selectDesk } from '../../store/selector/app.selector';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/reducer/app.reducer';
import { Subject, takeUntil } from 'rxjs';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { InformationPanelService, infoPanelData, panelType } from 'src/app/services/information-panel.service';
import { MarketElasticityService } from 'src/app/services/market-elasticity.service';

export interface MyCellRendererParams {
  rowDataRecord: any;
  parentRecord: any;
  totalRecords: any;
  isDetailGrid: any;
  isIntankMot: any;
}
@Component({
  selector: 'more-menu-mkt-els-nom',
  templateUrl: './more-menu-mkt-els-nom.component.html',
  styleUrls: ['./more-menu-mkt-els-nom.component.scss']
})
export class MoreMenuMktElsNomComponent {
  public isOpen = false;
  public showUi = false;
  public rowIndex: any;
  public value: any;
  pipelineParentKey: string='';
  pipelineParentObj:any;
  public rowDataRecord: any;
  public desk: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  public params: any;  

  constructor(
    private utilities: Utilities,
    private router: Router,
    private appStore: Store<AppState>,
    private cpService: CargoPlanningService,
    private infoPanelService: InformationPanelService,
    private mktService: MarketElasticityService
    ){ }

  agInit(params: any): void {
    if(params.data){
      this.rowIndex = params.node?.rowIndex;
      this.pipelineParentKey = params.data.uid;
      this.pipelineParentObj = params.data;
      // this.showUi = params.data.ccaStatus !== 'completed';
      const field = params["colDef"]["field"];
      if(params.data[field]) {
        this.showUi = true;
        this.value = JSON.parse(JSON.stringify(params.data[field]));
      }
    }
    this.rowDataRecord = params;
    this.params = params;
    this.getUserandDeskDetails() 
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      }
    );

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          // this.isCCAView = this.utilities.checkIfCCAView(response.selectedDesk);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      // this.mktService.nominationParamPSD$.subscribe((value: any) => {
      //   this.params = value;
  
      //   let colId : string = this.params.column.colId;
  
      //   let nomKeyName = colId.replace("nominationNumber","nominationKey");
      //   let nomKeyValue = this.params.data[nomKeyName];
      //   this.params.value = nomKeyValue;  
      // });
  }

  moreMenu() {
    this.isOpen = !this.isOpen;
  }
  
 async editNomination(){

    let colId : string = this.params.column.colId;

    let nomKeyName = colId.replace("whatifShipment","nominationNumber");
    let nomKeyValue = this.params.data[nomKeyName];
    if(nomKeyValue){
      let nominNoObj = nomKeyValue.split(",")[0];
      let nominNo = nominNoObj.split("(")[0];    
      this.params.value = nominNo;console.log(nominNo);
    }
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    pipelineNomination["screenName"] = 'side-panel-tr';
    
      pipelineNomination["editNomination"] = false;
      this.mktService.setOpenNominationPSD(true);
    
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editPipelineNominationFromME',
      type: '',
      values: pipelineNomination
    }; 
    
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  openActivityTracker(){
    let valueToPass: infoPanelData = {
      name: this.pipelineParentObj.voyageName,
      date: this.pipelineParentObj.loadLaycanStartDate, 
      id: this.pipelineParentObj.id,
      type: panelType.CP,
      data: this.pipelineParentObj,
    }

    this.infoPanelService.setPanelData(valueToPass);
    this.infoPanelService.undockInfoPanel(true);   

  }
  openSidePanel(){
    this.infoPanelService.showInfoPanel(true);
  }
}
