import { createAction, props } from "@ngrx/store";
import { Desks, EditedMaterialMappingData, MaterialMappingFilter, MaterialMappingFilters, MaterialMappingNested } from "../../../../../src/app/shared/interface/mdm-admin.interface";

export const loadMaterialMappingData = createAction(
  '[Material Location Mapping Data API] Load Material Mapping Data',
  props<{ MaterialMappingFilter: MaterialMappingFilter }>(),
);

export const loadMaterialMappingDataSuccess = createAction(
  '[Material Location Mapping Data API] Load Material Mapping Data API Success',
  props<{ materialMappingData: MaterialMappingNested[] }>(),
);

export const updateMaterialMappingTable = createAction(
  '[Locations Filter] Set updateMaterialMappingTable',
  props<{ updateMaterialMappingTable: boolean }>()
);

export const loadDesksData = createAction(
  '[Desks Data API] Load Desks Data',
  props<{ queryParams: any }>(),
);

export const loadDesksDataSuccess = createAction(
  '[Desks Data API] Load Desks Data API Success',
  props<{ desks: Desks[] }>(),
);

export const updateMaterialMappingData = createAction(
  '[Material Location Mapping Data API] Update Material Location Data API',
  props<{ editedMaterialMappingData: EditedMaterialMappingData[] }>(),
);

export const updateMaterialMappingSuccess = createAction(
  '[Material Location Mapping Data API] Update Material Location Data API Success',
);

export const updateEditedMaterialMapping = createAction(
  '[Material Location Mapping Data API] Add Edited Material Location Data',
  props<{ materialMappingData: MaterialMappingNested[] }>(),
);

export const addEditedMaterialMapping = createAction(
  '[Material Location Mapping Data] Set When Material Location Data Edited',
);

export const loadMaterialMappingFailure = createAction(
  '[Material Location Mapping Data API] Load Material Location Data API Failure',
  props<{ error: any }>(),
);

export const updateMaterialMappingFailure = createAction(
  '[Material Location Mapping Data API] Update Material Location Data API Failure',
  props<{ error: any }>(),
);

export const setDefaultMaterialMappingFilters = createAction(
  '[Material Location Mapping Filter] Set Default Material Location Filter',
  props<{ materialMappingFilters: MaterialMappingFilters, materialMappingData: MaterialMappingNested [] }>()
);

export const updateMaterialMappingFilters = createAction(
  '[Material Location Mapping Filter] Update Material Location Filter',
  props<{ materialMappingFilters: MaterialMappingFilters, materialMappingData: MaterialMappingNested [] }>()
);

export const updateLocationsTable = createAction(
  '[Material Location Mapping Filter] Set updateMaterialMappingTable',
  props<{ updateMaterialMappingTable: boolean }>()
);

export const materialMappingFiltersUpdated = createAction(
  '[Material Location Mapping Filter] Set updateMaterialMappingFilter',
  props<{ updateMaterialMappingFilter: boolean }>()
);

export const resetMaterialMappingScreen = createAction(
  '[Material Location Mapping Screen] Reset material mapping screen'
);