<ag-grid-angular class="ag-theme-alpine ag-grid-table ethanol-view" [columnDefs]="columnDefs2" [defaultColDef]="defaultColDef2"
      [rowData]="rowData2" [rowSelection]="'multiple'" [animateRows]="true"  [isFullWidthRow]="isFullWidthRow"
      [fullWidthCellRenderer]="fullWidthCellRenderer" (cellValueChanged)="onEhtanolCellValueChanged($event)" [getRowStyle]="getRowStyle"
      [columnHoverHighlight]="true" [getRowHeight]="getRowHeight" [statusBar]="statusBar" [enableRangeSelection]="true" (gridReady)="onGridReady2($event)" (columnMoved) ="onColumnMoved($event)" (columnGroupOpened)="onColumnGroupOpened($event)" (dragStopped)="columnValueChanged($event)" (cellEditingStopped)="onEthanolCellEditingStopped($event)" (bodyScroll)="onEthanolBodyScrolled($event)">
    </ag-grid-angular>

    <np-modal [show]="confirmModal.open" class="confirm-modal" [headerTitle]="'Are you sure you want to save the data?'"
  [noModalButtons]="true">
  <date-change-confirm-model [uswcShellCarson]='isShellCarson' (closeModalPopup)="closeConfirmModal($event)"
    (confirmModalPopup)="confirmModalPopup($event)">
  </date-change-confirm-model>
</np-modal>
