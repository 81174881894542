import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {

  constructor(private fb: FormBuilder) { }

  fiterFormGroup!: FormGroup;


  ngOnInit() {
    this.fiterFormGroup = this.fb.group({
      nominationNo: [''],
      nominationKey: [''],
      trasportSystem: [''],
      cycleId: [''],
      status: ['']
    })
  }

}