import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';


@Component({
  selector: 'app-vendor-notification-preview',
  templateUrl: './vendor-notification-preview.component.html',
  styleUrls: ['./vendor-notification-preview.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class VendorNotificationPreviewComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();
  @ViewChild('toEmail', { static: true }) toEmail!: ElementRef;
  @ViewChildren("truckWeek") truckElements!:QueryList<ElementRef>;


  toEmailInput: any; 
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  nominationIds: any = [];
  nominationData: any = [];
  selectedfilterType:string = 'select';
  selectedfilterLocation:string = 'select'
  selectedfiltercounterPart: string = 'select'
  months: any = [];
  suppliers: any = [];
  hasEmail: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  fromEmail:any;
  user:any;
  fromUserName:any;
  userDesg:any;
  userEmail:any;
  userContact:any;
  showToolTip!: boolean;
  subjectLine:any = "Next Week's Nomination";
  subjectLinePipeline:string = "discharge instructions"
  comments:string = '';
  startRangeForInput:any;
  endRangeForInput:any;
  getTruckBbls : string ='';
  startRange: any;
  endRange: any;
  dateDifference: any;
  desk:any;
  PipelineUswcPNWDesk:any = "pipelineuswcpnwdesk";
  PipelineUsecFloridaDesk:any = "pipelineusecfloridadesk";
  PipelineUsecRichmondDesk:any = "pipelineuswcrichmonddesk";
  PipelineUsecpascagoulaDesk:any = "pipelineusecpascagouladesk";
  CleanProductsEccaDesk:any = "clean products + latam + ecca desk";
  CleanProductsWccaDesk:any = "clean products + latam + wcca desk";
  CleanProductsPanamaDesk:any = "clean products + latam + panama";
  comment:string[]=[]
  uniqueLoadType: string[]=[];
  uniqueLocation: string[] = [];
  uniquecounterPart: string[]= [];
  updatedNominationPipelineData: any;
  updateTruckBbl: any;
  truckBbblRepharse: any;
  getItemId: any;
  monthlyTruckData!: any;
  showErrorMessage: boolean = false;
  errorText!: string;
  noDataText: any;



  constructor(private nsService: NominationService,private changeDetection: ChangeDetectorRef, private appStore: Store<AppState>, private toasterService: ToastService, private datePipe: DatePipe,private utilities: Utilities) { }

  ngOnInit(): void {
    this.desk = this.utilities.updateAPIParams();
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.user = response.user;
            this.fromEmail = response.userDetails.email;
            this.fromUserName = response.userDetails.name;
            this.userDesg = response.userDetails.jobTitle
            this.userEmail = response.userDetails.email;
            this.userContact = response.userDetails.phoneNumber;
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

      this.nsService.resetToDefaultView$.subscribe(res => {
        if(res){
          this.closeModal();
        }
      })
    this.nsService.getTruckBBLs$.subscribe((res:any)=> this.getTruckBbls = res)
  }

  closeModal() {
    this.showErrorMessage = false;
    this.noDataText = false;
    this.comments = "";
    this.closeModalPopup.emit(true);
    this.toEmailInput = "";
    this.hasEmail = false;
    this.selectedfilterType = 'select';
    this.selectedfilterLocation = 'select';
    this.selectedfiltercounterPart = 'select';
    this.uniqueLoadType = [];
    this.uniqueLocation = [];
    this.uniquecounterPart = [];
    this.comment = [];
    this.updatedNominationPipelineData = JSON.parse(JSON.stringify(this.nominationData));    
  }

  formatHtmlForPayload() {
    let emaiLbody = "";
    if (this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk|| this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk) {
      emaiLbody += `
      <div>
      <p>Nomination number - ${this.nominationData?.nominationNo}</p>
      <p>MOT - ${this.nominationData?.modeOfTransport}</p>
      <p> Vessel/barge/pipeline - ${this.nominationData?.vehicleName}</p>
      </div>
      <br>
      <table border="2" width="600" style="border-width: 1px;border-collapse:collapse;">
      ${this.updatePipelineVNpayload()}
      </table>`;
    }
    else {
      for (let nomination of this.nominationData) {
        emaiLbody += `
      <br>
        <p style="font-size: 16px;">Below is the demand for <b>${this.datePipe.transform(this.startRangeForInput, 'MM/dd/yyyy')} - ${this.datePipe.transform(this.endRangeForInput, 'MM/dd/yyyy')}</b> for the following terminals.</p><p style="font-size: 16px;">As of today, here is the projected ethanol demand for <b>terminal(s)</b> for ${nomination.destinationLocation}:</b></p><br><table border="2" width="600" style="border-collapse:collapse;border-width: 1px;"><tr style="text-align: center;"><td colspan="3">Chevron Account</td></tr>
      ${this.validateElement(nomination)}
      ${this.createPaylodForDailyData(nomination)}
      </table>`;
      }
    }
    return emaiLbody;
  }

  updatePipelineVNpayload() {
    let emailBody = ""
    emailBody += `<tr style="text-align: center;">
    <td>Type</td>
    <td>Location</td>
    <td>counterParty</td>
    <td>Product</td>
    <td>Volume(mb)</td>
    <td>ETA</td>
    <td>Comments</td>
    </tr>`

    for (let [i, nomination] of this.updatedNominationPipelineData.pipelineVendorNotificationList.entries()) {
      emailBody += `${this.getPipelineVNData(i,nomination)}`
    }
    return emailBody
  }

  getPipelineVNData(i:any,nomination:any) {
    let emailBody = ""    
    emailBody += `<tr style="text-align: center;">
    <td style="padding-left: 10px; padding-right: 10px;">${nomination?.type}</td>
    <td style="padding-left: 10px; padding-right: 10px;">${nomination?.location}</td>
    <td style="padding-left: 10px; padding-right: 10px;">${nomination?.counterPart || ''}</td>
    <td style="padding-left: 10px; padding-right: 10px;">${nomination?.product}</td>
    <td style="padding-left: 10px; padding-right: 10px;">${nomination?.volume}</td>
    <td style="padding-left: 10px; padding-right: 10px;">
    ${nomination.eta.substring(4,6)}/${nomination.eta.substring(6,nomination.eta.length)}/${nomination.eta.substring(0,4)}
    </td>
    <td style="padding-left: 10px; padding-right: 10px;">${this.comment[i] || "Not Available"}</td>
  </tr>`
    return emailBody
  }
  selectedType() {
    this.updatedNominationPipelineData = JSON.parse(JSON.stringify(this.nominationData));
    this.selectedfilterLocation = "select";
    this.selectedfiltercounterPart = "select";
    if (this.selectedfilterType !== "select") {
      this.updatedNominationPipelineData.pipelineVendorNotificationList = this.updatedNominationPipelineData.pipelineVendorNotificationList.filter((loadType: any) =>
      loadType.type === this.selectedfilterType
      )
    }
  }

  selectedLocation() {
    this.updatedNominationPipelineData = JSON.parse(JSON.stringify(this.nominationData));
    this.selectedfilterType = "select";
    this.selectedfiltercounterPart = "select";
    if (this.selectedfilterLocation !== "select") {
      this.updatedNominationPipelineData.pipelineVendorNotificationList = this.updatedNominationPipelineData.pipelineVendorNotificationList.filter((loadType: any) =>
         loadType.location === this.selectedfilterLocation
      )
      
    }
  }

  selectedcounterPart() {
    this.updatedNominationPipelineData = JSON.parse(JSON.stringify(this.nominationData));
    this.selectedfilterLocation = "select";
    this.selectedfilterType = "select";
    if (this.selectedfiltercounterPart !== "select") {
      this.updatedNominationPipelineData.pipelineVendorNotificationList = this.updatedNominationPipelineData.pipelineVendorNotificationList.filter((loadType: any) =>
        loadType.counterPart === this.selectedfiltercounterPart
      )
    }
  }

  validateElement(nomination:any){
    let emailBody = ""
    if (nomination.nominationNo && this.dateDifference !=='week' && this.dateDifference !=='month') {
     emailBody +=` <tr style="height: 25px;">
     <td  style="padding-left: 5px;">nomination no</td>
      <td colspan="2" style="padding-left: 5px;">${nomination.nominationNo}</td></tr>`
    }
    if (this.startRangeForInput || this.endRangeForInput) {
      emailBody +=`<tr class="h-25" *ngIf="nomination.nominationNo">
      <td style="padding-left: 5px;"">date range</td>
      <td colspan="2" style="padding-left: 5px;">${this.datePipe.transform(this.startRangeForInput, 'MM/dd/yyyy')} <b>through</b> ${this.datePipe.transform(this.endRangeForInput, 'MM/dd/yyyy')} </td>
  </tr>`
    }
    if (nomination.month && this.dateDifference ==='month') {
      emailBody += `<tr>
      <td style="padding-left: 5px;"> month</td>
      <td colspan="2" style="padding-left: 5px;">${nomination.month}</td></tr>`
    }
    if (nomination.scheduledDate && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody += `
      <tr><td style="padding-left: 5px;"> scheduled date</td>
      <td colspan="2" style="padding-left: 5px;">${nomination.scheduledDate.slice(4,6)}/${nomination.scheduledDate.slice(6,nomination.scheduledDate.length)}/${nomination.scheduledDate.slice(0,4)}</td></tr>`
    }
    if (nomination.supplier && nomination.contractNumber && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody += `<tr><td  style="padding-left: 5px;">contract number </td><td colspan="2" style="padding-left: 5px;">${nomination.contractNumber}</td></tr>`
    }
    if (nomination.sapNumber && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody +=`<tr><td  style="padding-left: 5px;">SAP number </td><td colspan="2" style="padding-left: 5px;">${nomination.sapNumber}</td></tr>`
    }
    if (nomination.supplier) {
      emailBody += `<tr><td  style="padding-left: 5px;">supplier </td><td colspan="2" style="padding-left: 5px;">${nomination.supplier}</td></tr>`
    }
    if (nomination.carrier && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody += `<tr><td  style="padding-left: 5px;">carrier </td><td colspan="2" style="padding-left: 5px;">${nomination.carrier}</td></tr>`
    }
    if (nomination.originLocation && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody += `<tr><td  style="padding-left: 5px;">origin location </td><td colspan="2" style="padding-left: 5px;">${nomination.originLocation ??  ''}</td></tr>`
    }
    emailBody += this.getDestintionLocation(nomination.destinationLocation);

    if (nomination.totalTrucks_rails_deliveries && this.dateDifference === 'week') {
      let bbl685Rail = nomination.totalTrucks_rails_deliveries;
      emailBody +=`<tr>
      <td style="padding-left: 5px;">Total ${ this.getMotDesc(nomination)}</td>
      <td colspan="2" style="padding-left: 5px;">${nomination?.modeOfTransportDesc.toLowerCase() === 'truck'?nomination.totalTrucks_rails_deliveries : bbl685Rail}</td></tr>`
    }
    emailBody +=`${this.extendedValidateElement(nomination)}`
    return emailBody
  }

  getDestintionLocation(data:any){
    if (data) {
      return `<tr><td style="padding-left: 5px;" >destination location </td><td colspan="2" style="padding-left: 5px;">${data ??  ''}</td></tr>`
    }
    else {
      return "";
    }
  }

  extendedValidateElement(nomination:any){
    let emailBody =""
    if (nomination.totalBbls && this.dateDifference === 'month') {
      emailBody += `<tr><td  style="padding-left: 5px;">total bbls</td><td colspan="2" style="padding-left: 5px;">${nomination.totalBbls ?? ''}</td></tr>`
    }
    if (nomination.totalGallons && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody +=`<tr> <td  style="padding-left: 5px;">total gallons</td><td colspan="2" style="padding-left: 5px;">${nomination.totalGallons}</td></tr>`
    }
    if (nomination.supplier && nomination.contractBbls && this.dateDifference ==='month') {
      emailBody +=`<tr><td  style="padding-left: 5px;">contract Volume</td><td colspan="2" style="padding-left: 5px;">${nomination.contractBbls}</td></tr>`
    }
    if (nomination.supplier && nomination.contractTolerance && this.dateDifference !=='week' && this.dateDifference !=='month') {
      emailBody +=`<tr> <td  style="padding-left: 5px;">contract tolerance</td><td colspan="2" style="padding-left: 5px;">${nomination.contractTolerance}% </td></tr>`
    }
    return emailBody
  }
  getMotDesc(nomination:any){
    let nomMotDesc = nomination.modeOfTransportDesc?.toLowerCase();
    let motDesc = "";
    switch(nomMotDesc){
      case 'pipeline':
        motDesc = "Tank Transfers";
        break;
      case 'barge':
        motDesc = 'Barges Volume';
        break;
      case 'rail':
        motDesc = "Rail Cars";
        break;
      default:
        motDesc = nomination.modeOfTransportDesc + "s";
    }
    return motDesc;
  }

  railOrTruckElements(nomination:any){
    let htmlEmailBody = ""
    if (nomination?.modeOfTransportDesc.toLowerCase() === 'rail' && nomination.weeklyData) {
    for(let data of nomination.weeklyData){
        htmlEmailBody += `
          <tr>
              <td style="padding-left : 5px;">${data.dateRange.substring(3,5)}/${data.dateRange.substring(0,2)}/${data.dateRange.substring(6,10)} - ${data.dateRange.substring(14,16)}/${data.dateRange.substring(11,13)}/${data.dateRange.substring(17)} </td>
              <td style="padding-left : 5px; ">${data.bbl685Rails}</td>
              <td style="padding-left : 5px; ">${data.scheduledQtyBbl}</td>
          </tr>`
      }
    }
    if ((nomination?.modeOfTransportDesc.toLowerCase() === 'truck' || nomination?.modeOfTransportDesc.toLowerCase() === 'pipeline') && nomination.dailyBreakdownQty && this.dateDifference !== 'month') {
    for(let data of nomination.dailyBreakdownQty){

      htmlEmailBody += `
        <tr>
            <td style="padding-left : 5px;">${data.scheduledDate.substring(4, 6)}/${data.scheduledDate.substring(6, data.scheduledDate.length)}/${data.scheduledDate.substring(0, 4)} </td>
            <td colspan="2" style="padding-left : 5px; ">${nomination.modeOfTransportDesc === 'Truck' ? data[this.updateTruckBbl[0][0]]: data.scheduledQty}</td>
        </tr>`
      }
    }

    return htmlEmailBody
  }
  createPaylodForDailyData(nomination: any) {
    if (nomination.dailyBreakdownQty) {
      let html = ``;
      if (nomination.modeOfTransportDesc === 'Pipeline' && this.dateDifference !=='month') {
        html += `
      <tr style="text-align: center;">
      <td colspan="3">Transfers By Day (BBL)</td>
      </tr> 
      <td style="padding-left : 5px;">Date Range</td>
      <td colspan="2" style="padding-left : 5px;"># in bbls</td>
  </tr>
  ${this.railOrTruckElements(nomination)}`;
      }
      else if ((nomination?.modeOfTransportDesc.toLowerCase() === 'truck' && this.dateDifference !=='month') && nomination.dailyBreakdownQty) {
        html += `
        <tr style="text-align: center;">
        <td colspan="3">Movement By Day</td>
        </tr>
        <td style="padding-left : 5px;">Date Range</td>
        <td style="padding-left : 5px;"># of Trucks (${this.truckBbblRepharse})</td>
        </tr>
        ${this.railOrTruckElements(nomination)}`;
    }
      else if (nomination?.modeOfTransportDesc.toLowerCase() === 'rail' && nomination.weeklyData && this.dateDifference !=='month') {
        html += `<td colspan="3">Movement By week</td>
        </tr>
        <tr>
        <td style="padding-left : 5px;">Date Range</td>
              <td style="padding-left : 5px;"># of railcars</td>
              <td colspan="2" style="padding-left : 5px;">BBLS</td>
          </tr>
   ${this.railOrTruckElements(nomination)}`;
    }
      return html;
    }
    else {
      return "";
    }
  }

  validateRailData(){
    const total =  this.nominationData[0].weeklyData.reduce((sum:any, item:any) => sum + Number(item.bbl685Rails),0);

    if(total === 0 && !this.nominationData[0].totalTrucks_rails_deliveries ){
      this.errorText =  "Total rail cars and movement by week rail cars quanity are empty";
    } else if(Number(total) !== Number(this.nominationData[0].totalTrucks_rails_deliveries)){
      this.showErrorMessage  =  true
      this.errorText =  "Total rail cars and movement by week rail cars quanity are not matching";
    }
    else {
      this.showErrorMessage  =  false;
    }
  }

  validateTrcukData(){
    if(this.dateDifference === 'week' && this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'truck'){
      this.nominationData[0].dailyBreakdownQty.forEach((item: any, index:any) => {
        item[this.updateTruckBbl[0][0]] =  Array.from(this.truckElements)[index].nativeElement.value;
     })
    }
    const total =  this.nominationData[0].dailyBreakdownQty.reduce((sum:any, item:any) => sum + Number(item[this.updateTruckBbl[0][0]]),0);
    if(total === 0 && !this.nominationData[0].totalTrucks_rails_deliveries ){
      this.errorText =  "Total truck and movement by week trucks  quanity are empty";
    } else if(Number(total) !== Number(this.nominationData[0].totalTrucks_rails_deliveries)){
      this.showErrorMessage  =  true
      this.errorText =  "Total rail cars and movement by week rail cars quanity are not matching";
    }
    else {
      this.showErrorMessage  =  false;
    }
  }

  validateVolume(){
    if(this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'rail'){
    this.validateRailData();
    }
    else if(this.dateDifference === 'week' && this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'truck'){
      this.validateTrcukData();
    }
  }

  validateNestedData(){
    let key = this.dateDifference === 'week' ? 'totalTrucks_rails_deliveries' : 'totalBbls';
    let dataStatus;
    if(this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'rail'){
       dataStatus = this.nominationData[0].weeklyData.some((item:any) => {
        return item.bbl685Rails === "" || item.scheduledQtyBbl === "";
      })  || !this.nominationData[0].totalTrucks_rails_deliveries
    }
    else if(this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'truck'){
      if(this.dateDifference === 'week'){
        dataStatus =  this.nominationData[0].dailyBreakdownQty.some((item: any, index:any) => {
         return item[this.updateTruckBbl[0][0]] === "";
       }) || ( !this.nominationData[0][key]);
      }
    }
    return dataStatus;
  }

  validateEmptyValues(){
    this.hasEmail = this.toEmailInput.length ? false : true;
    if( ['rail','truck'].includes(this.nominationData[0].modeOfTransportDesc.toLowerCase())){
        this.noDataText = this.validateNestedData() ? true : false;   
        if(this.dateDifference === 'month'){
          this.noDataText = this.nominationData[0].totalBbls === '';
        }  
      }

      if(this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'pipeline'){
        this.noDataText = this.nominationData[0].dailyBreakdownQty.some((item:any) => {
              return  item.scheduledQty === '';
        })
      }

      if(this.nominationData[0].modeOfTransportDesc.toLowerCase() === 'barge'){
       this.noDataText = this.nominationData[0].totalTrucks_rails_deliveries === '';
      }

      return this.noDataText;
  
  }
  createPayLoad() {
    if(this.validateEmptyValues()){
      return;
    }

    let updateSubLine = `${([this.PipelineUsecRichmondDesk,this.PipelineUsecFloridaDesk,this.PipelineUsecpascagoulaDesk,this.PipelineUswcPNWDesk,this.CleanProductsEccaDesk, this.CleanProductsWccaDesk , this.CleanProductsPanamaDesk].includes(this.desk))?(this.nominationData.nominationNo || '') : (this.nominationData[0].destinationLocation || this.nominationData[0].originLocation)} ${this.subjectLine}`
    if (this.toEmailInput.length) {
      this.hasEmail = false;
      let html = "";
      html = this.updateHtmlHeader()
      let payload: any = {
        from: this.fromEmail,
        to: this.toEmailInput.toString().replaceAll(" ", '').split(","),
        subject: updateSubLine,
        body: html
      }
      this.nsService.triggerEmail(payload).subscribe({
        next: (response) => {
          if (response.success) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: response.result,
            });
            let tempNomData = this.nominationIds.filter((nomData: any) => {
              let getNominationNo = (this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk|| this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk)? this.nominationData.nominationNo : this.nominationData[0].nominationNo
              return nomData.no !== getNominationNo;
            })

            this.nominationIds = [];
            this.comment = [];
            tempNomData.forEach((nom:any, index:any) => {
              this.nominationIds.push({id:nom.id, no:nom.no, checked:index === 0})
            })
            this.nominationIds.forEach((nom:any) => {
              
            })
            this.changeDetection.detectChanges();
            this.updateGetItemPreview()

          }
        },
        error: (err: any) => {
          console.log(err);
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err?.result,
          });
          this.closeModal()
        },
        complete: () => { }
      })
    }
    else {
      this.hasEmail = true;
    }
  }
  updateHtmlHeader() {
    let html = ''
    if (this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk || this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk) {
       html = `<html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body>${this.formatHtmlForPayload()}</body></html>`;
    }
    else {
       html = `<html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head><body><p style="font-size: 16px;">Team,</p>${this.formatHtmlForPayload()}<br/><br/><p style="font-size: 16px;" ><b>Comments:</b> ${this.comments || 'Not available'}<br/><p style="font-size: 16px;" >Plan accordingly and let me know if there are any other questions or concerns</p><br /> <p style="font-size: 16px;">Thanks,</p><p style="margin: 0;font-size: 16px;font-weight: 600;"> <b>${this.fromUserName}</b> </p><p style="margin: 0; font-size: 16px">${this.userDesg}</p> <p style="margin: 0; font-size: 16px">${this.userEmail}</p><p style="margin: 0; font-size: 16px">${this.userContact ?? ''}</p></body></html>`;
    }
    return html
  }

  updateGetItemPreview() {
    if (this.nominationIds.length) {
      this.getItemPreview(this.nominationIds[0].id);
    }
    if (!this.nominationIds.length) {
      this.closeModal();
    }
  }

  toolTipAction(status: boolean) {
    this.showToolTip = status;

  }

  applyDateRange(){
    let payload: any
    this.showErrorMessage = false;
    this.noDataText = false;
    if (this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk|| this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk) {
      payload = {id:[this.nominationIds[0].id], desk: "cleanProduct", startDate:this.startRangeForInput, endDate:this.endRangeForInput, appliedDateChange:true};
    }
    else {
      payload = { id: [this.getItemId], startDate: this.startRangeForInput, endDate: this.endRangeForInput, appliedDateChange: true };
    }
    this.getVendorData(payload,false);
  }

  getItemPreview(item: any) {
    if ([this.PipelineUsecFloridaDesk, this.PipelineUsecRichmondDesk, this.PipelineUsecpascagoulaDesk, this.PipelineUswcPNWDesk, this.CleanProductsEccaDesk, this.CleanProductsWccaDesk].includes(this.desk)) {
      this.subjectLine = 'discharge instructions'
    } else {
      this.subjectLine = "Next Week's Nomination";
    }
    let payload:any
    if (this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk || this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk) {
      payload = { id: [item], desk: "cleanProduct" };
    }
    else {
      payload = { id: [item]};
      this.getItemId = item;
    }
    this.getVendorData(payload, true);
  } 


  getVendorData(payload:any, dateReset:boolean){ 
    let deskCheck = this.desk === this.PipelineUsecFloridaDesk || this.desk === this.PipelineUsecRichmondDesk || this.desk === this.PipelineUsecpascagoulaDesk|| this.desk === this.PipelineUswcPNWDesk || this.desk === this.CleanProductsEccaDesk || this.desk === this.CleanProductsWccaDesk
    if(this.desk === UDMDesk.EthanolUswcVal || this.desk === UDMDesk.EthanolUsecVal){
      this.utilities.getVendorNotificationMaterDataPayload(payload);
    }   
    this.nsService.getVendorNotificationsData(payload).subscribe({
      next: (response) => {
        if (response.statusCode === 200) {
          this.nominationData = deskCheck ? response.result : response.result.vendorNotificationLst;
          this.updatedNominationPipelineData = JSON.parse(JSON.stringify(this.nominationData));
          this.setUniqueLoadTypeLocationCounterPart();
          this.startRangeForInput =  this.datePipe.transform( response.result.startDate, "yyyy-MM-dd");
          this.endRangeForInput =  this.datePipe.transform( response.result.endDate, "yyyy-MM-dd");
          this.toEmailInput = response.result.emailRecipients;
          this.updateTruckBblData(response);
          if((!response.result.startDate || !response.result.endDate)){
            this.startRangeForInput =  this.datePipe.transform(this.startRange, "yyyy-MM-dd");
            this.endRangeForInput =  this.datePipe.transform( this.endRange, "yyyy-MM-dd");
          }
          if(dateReset){
              this.startRange =  this.datePipe.transform( response.result.startDate, "yyyy-MM-dd");
              this.endRange =  this.datePipe.transform( response.result.endDate, "yyyy-MM-dd");
          }
         this.getDateDifference(response.result.startDate,response.result.endDate);
         this.monthlyTruckData = this.nominationData[0].totalBbls && this.dateDifference === 'month';
        }
      },
      error: (err: any) => {
        console.log(err);
        this.nominationData = [];
      },
      complete: () => { }
    })
  }

  updateTruckBblData(data:any) {    
    if (data?.result?.vendorNotificationLst[0]?.modeOfTransportDesc?.toLowerCase() === 'truck') {
      this.updateTruckBbl = Object.entries(this.nominationData[0]?.dailyBreakdownQty[0]).filter(([key, value]) => key.includes("bbl") && value !== null)
      this.truckBbblRepharse = `${this.updateTruckBbl[0][0]?.substring(3,6)} ${this.updateTruckBbl[0][0]?.substring(0,3)} ${this.updateTruckBbl[0][0]?.substring(6,this.updateTruckBbl[0][0]?.length)}`
    }
  }

  getDateDifference(startDate :any,endDate:any){
    const start = new Date(startDate);
    const end = new Date(endDate);
    if(start.getDate() === 1 && end.getDate() === new Date(end.getFullYear(), end.getMonth() + 1,0).getDate()){
      this.dateDifference = 'month';
    }else{
      this.dateDifference = 'week';
    }
   }

  setUniqueLoadTypeLocationCounterPart(){
    this.updatedNominationPipelineData?.pipelineVendorNotificationList?.forEach((loadType:any)=>{
      if (!this.uniqueLoadType.includes(loadType.type)) {
        this.uniqueLoadType.push(loadType.type)
      }
      if (!this.uniqueLocation.includes(loadType?.location)) {
        this.uniqueLocation.push(loadType?.location)
      }
      if (!this.uniquecounterPart.includes(loadType?.counterPart) && loadType?.counterPart !== null) {
        this.uniquecounterPart.push(loadType?.counterPart)
      } 
    }) 
  }

  selectedRows(selectedRows:any){
    if ([this.PipelineUsecFloridaDesk, this.PipelineUsecRichmondDesk, this.PipelineUsecpascagoulaDesk, this.PipelineUswcPNWDesk, this.CleanProductsEccaDesk, this.CleanProductsWccaDesk].includes(this.desk)) {
      this.subjectLine = 'discharge instructions'
    }
    this.nominationData = [];
    this.nominationIds = selectedRows?.map((item: {
      nominationNo: any; id: any; 
})  => { return { id:item.id, no:item.nominationNo,checked:false }} );
    if (this.nominationIds?.length > 0) {
      this.nominationIds[0].checked = true;
      this.getItemPreview(this.nominationIds[0].id);
    }
  }

}
