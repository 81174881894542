import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UserInformationService } from 'src/app/services/user-information.service';
import { userInformation, vacation } from '../interface/user-information';
import { ToastService } from 'src/app/services/toast.service';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';

@Component({
  selector: 'user-vacation',
  templateUrl: './user-vacation.component.html',
  styleUrls: ['./user-vacation.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
})
export class UserVacationComponent implements OnInit {

  @Output() closeModalPopup = new EventEmitter();
  userVacationData: userInformation = {
    userEmail: '',
    userVacations: {
      vacationList: []
    }
  };

  initialUserVacationData!: userInformation;

  selectedDateRange!: DateRange<Date>;
  calendarInteractable: boolean = false;
  @ViewChild('calendar', {static: false}) calendar!: MatCalendar<Date>;
  selectedIndex!: number | null;

  //below varaible is vital for proper date range selection in the mat-calendar itself, used with 
  calendarDateRangeControl!: DateRange<Date>;
  isNew: boolean = false;
  
  constructor(private userInfoService: UserInformationService, private toasterService: ToastService,  private selectionModel: MatRangeDateSelectionModel<Date>,
    private selectionStrategy: DefaultMatCalendarRangeStrategy<Date>, private toast: ToastService) { }

  ngOnInit(): void {

    this.userInfoService.getUserVacation(localStorage.getItem("userEmail")!).subscribe({
      next: (result: any) => {
      
        this.userVacationData = result.result;
        if(result == null){
          this.userVacationData = {
            userEmail: localStorage.getItem("userEmail")!,
            userVacations: {
              vacationList: []
            }
          }
        }

        this.userVacationData.userVacations.vacationList.sort((a,b) => {
          return new Date(a.vacationStartDate).getTime() - new Date(b.vacationStartDate).getTime();
        })

        let list = this.userVacationData.userVacations.vacationList;
        let currentDate = new Date().setHours(0, 0, 0, 0);

        list = list.filter(date => {
          let vacationEndDate = new Date(date.vacationEndDate).setHours(0, 0, 0, 0);
          return vacationEndDate >= currentDate;
        })
        this.userVacationData.userVacations.vacationList = list;
        this.initialUserVacationData = JSON.parse(JSON.stringify(this.userVacationData));
   
      },
      error: (err: any) => {

          this.toast.setToastNotification({
            show: true,
            type: 'error',
            msg: 'Cannot load user vacation information'
          });
      },
      complete: () => {
      },
    })  
  }

  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );
  
    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );

    if(this.selectedDateRange.start != null && this.selectedDateRange.end != null){
      let replaceDate: any = {
        vacationEndDate: this.selectedDateRange.end,
        vacationStartDate: this.selectedDateRange.start,
      }


      if(replaceDate.vacationEndDate != null && replaceDate.vacationStartDate.setHours(0,0,0,0) < new Date().setHours(0,0,0,0)){
        this.toast.setToastNotification({ show: true, type: 'error', msg: "Start date cannot be earlier than today."});
        return;
      }


      if(this.isNew){
        this.userVacationData.userVacations.vacationList.push(replaceDate);
        this.isNew = false;
        this.selectedDateRange = new DateRange<Date>(null, null);
      }else if(this.selectedIndex != null){
        this.userVacationData.userVacations.vacationList[this.selectedIndex] = replaceDate;
        this.selectedDateRange = new DateRange<Date>(null, null);
      }else{
        console.log("Error adding user vacation");
      }
      this.calendarInteractable = false;
      this.selectedIndex = null;
      this.resetCalendarSelection();
    }
  }


  beginAdd(){

    //enable calendar and let user make new 
    this.calendarInteractable = true;
    this.calendar._goToDateInView(new Date(), 'month');
    this.isNew = true;
    this.selectedDateRange = new DateRange<Date>(null, null);
    this.selectedIndex = null;
  }


  selectVacationDate(vacation: vacation, index: number){
    this.selectedDateRange = new DateRange(new Date(vacation.vacationStartDate), new Date(vacation.vacationEndDate));
    this.selectedIndex = index;
    this.calendarInteractable = true;
    this.isNew = false;
    this.resetCalendarSelection();
    this.calendar._goToDateInView(new Date(vacation.vacationStartDate), 'month');
  }


  deleteVacation(){

    this.resetCalendarSelection();
    this.calendarInteractable = false;
    this.selectedDateRange = new DateRange<Date>(null, null);
    
    if(!this.isNew){
      this.userVacationData.userVacations.vacationList.splice(this.selectedIndex!, 1);
      this.selectedIndex = null;
    }
  }

  closePopup(){
    this.resetValues();
    this.selectedDateRange = new DateRange<Date>(null, null);
    this.closeModalPopup.emit(false);
  }

  resetValues(){
    this.resetCalendarSelection();
    this.selectedIndex = null;
    this.isNew = false;
    this.calendarInteractable = false;
  }


  //This function is vital for handlign the mat-calendar date selection
  //If this is not ran when appropriate, selections will persist between selection
  resetCalendarSelection(){
      //reset the vacation setup
      const selection = new DateRange<Date>(null, null);
      this.selectionModel.updateSelection(selection, this);
  }

  close(){

    //reset the user vacation data to original state if not saved
    this.userVacationData = JSON.parse(JSON.stringify(this.initialUserVacationData));

    this.closePopup();
  }

  save(){

    this.userInfoService.updateAndInsertUserInformation(this.userVacationData).subscribe({
      next: (result: any) => {      
      },
      error: (err: any) => {

        this.toast.setToastNotification({
          show: true,
          type: 'error',
          msg: `Cannot load user vacation information, ${err}`
        });
      },
      complete: () => {
        this.toast.setToastNotification({ show: true, type: 'success', msg: "Saved your vacations."})
        this.initialUserVacationData = JSON.parse(JSON.stringify(this.userVacationData));
        this.closePopup();
      },
    })
  }
}