import { Component, EventEmitter, Output, Input } from "@angular/core";
@Component({
    selector: 'date-change-confirm-model',
    templateUrl: './date-change-confirm-model.component.html',
    styleUrls: ['./date-change-confirm-model.component.scss']
})
export class DateChangeConfirmModelComponent {

    @Output() closeModalPopup = new EventEmitter();
    @Output() confirmModalPopup = new EventEmitter();
    @Input() nominationComponent: boolean = false;
    @Input() uswcShellCarson:boolean = false;
    alertMsg:string='';
    closePopup(){
        this.closeModalPopup.emit(false);
    }
    confirm(){
        this.confirmModalPopup.emit(false)
    }
}