<div appCloseDropdownDircetive (clickedOutSide)="cancel($event)" class="custom-date-range-wrapper">
  <div class="custom-date-range-input-container">
    <ng-container >
      <mat-form-field class="input-container__form-field">
        <input matInput (focus)="openCustomPicker()" readonly #selectedDateRange class="form-field__input">
        <mat-datepicker-toggle matSuffix (click)="openCustomPicker()"></mat-datepicker-toggle>
    </mat-form-field>
    </ng-container>
  </div>
     
  <div *ngIf="showCustomPicker" class="custom-picker-wrapper" #customDateRangePickerRef>
    <div class="custom-picker-wrapper__header">
      <div class="header__tab-header">
        <ng-container *ngFor="let tab of pickerTabs">
          <div class="tab" (click)="displayTabContent(tab)" [class.tab-selected]="tab.isTabSelected">
            {{tab.label}}
          </div>
        </ng-container>
      </div>
      <div class="header__spacer"></div>
      <div class="header__tab-content">
        <div class="header__tab-content-info">
          <div class="tab-content__selected-year-month" *ngIf="selectedTab?.label === 'month'">{{convertedSelectedYears[0]}}</div>
          <div class="tab-content__selected-year-month" *ngIf="selectedTab?.label === 'month'">choose up to 3 consecutive months to filter</div>
          <div class="tab-content__selected-year-month" *ngIf="selectedTab?.label === 'year'">{{getSelectedMonths}}</div>
          <div class="tab-content__selected-year-selection" *ngIf="selectedTab?.label === 'year'">
            <span class="selected-years">{{selectedYearRange}}</span>
            <div class="year-action-icons">
              <span class="left-caret-icon" (click)="decreaseYearRange()"></span>
              <span class="right-caret-icon" (click)="increaseYearRange()"></span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedTab?.label === 'month'">
        <div class="tab-content-month">
          <div class="month-grid">
              <div class="month-wrapper" *ngFor="let month of monthsArr; let i =index" [class.selected]="isSelected(month)" (click)="toggleMonthSelection(month,i)">
                <span class="month-label" [class.month-selected]="isSelected(month)">{{month}}</span>
                <span *ngIf="isSelected(month)" class="tick-mark"></span>
              </div>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="selectedTab?.label === 'year'">
        <div class="tab-content-year">
          <div class="year-grid">
            <div class="year-wrapper" *ngFor="let year of years" [class.selected]="isYearSelected(year)" (click)="toggleYearSelection(year)">
              <span class="year-label" [class.year-selected]="isYearSelected(year)">{{year}}</span>
            </div>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="selectedTab?.label === 'custom'">
        <div class="tab-content-custom">
          <div class="custom-grid">
            <mat-calendar class="custom-date-range-picker" [selected]="selectedRangeValue" (selectedChange)="selectedChange($event)" [startAt]="startAt" >
            </mat-calendar>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
    <div class="custom-picker-wrapper__footer" [class.action-button]="selectedTab.label === 'custom'">
      <div class="footer-reset" (click)="reset()">reset</div>
      <span class="spacer"></span>
      <div class="footer-cancel" (click)="cancel()">cancel</div>
      <div class="footer-apply" (click)="apply()">apply</div>
    </div>
  </div>
</div>
