import { createAction, props } from "@ngrx/store";

export const invokeNominationData = createAction(
    '[Invoke Nomination API]',
    props<{ payload: any }>(),
);

export const getTerminalNominationAPI = createAction(
    '[Get Terminal Nomination API]',
    props<{ nominationData: any }>(),
);

export const invokeNominationDetail = createAction(
    '[Invoke Nomination Detail API]',
    props<{ nomId: any, desk:any }>(),
);

export const invokeNominationGroupingDetail = createAction(
    '[Invoke Nomination Grouping Detail API]',
    props<{ nomId: any, desk:any, udmNomGroupingId: any,region:any, masterData:any  }>(),
);

export const getNominationDetail = createAction(
    '[Get Nomination Detail API]',
    props<{ nominationDetail: any }>(),
);

export const publishOrDeleteNomination = createAction(
    '[Publish Or Delete API]',
    props<{ payload: any, updateNominationApiResponse: any }>(),
);

export const publishOrDeleteNominationSuccess = createAction(
    '[Publish Or Delete Success API]',
    props<{ publishOrDeleteNominationResponse: any, updateNominationApiResponse: any }>(),
);

export const invokeNominationRefData = createAction(
    '[Get Nomination Ref Data API]',
    props<{ payload?: any }>(),
);

export const getNominationRefDataSuccess = createAction(
    '[Get Nomination Ref Data Success API]',
    props<{ nominationRefData: any }>(),
);

export const clearApiResponse = createAction(
    '[Clearing the API response] removing the api response once the service request is done',
    props<{ updateNominationApiResponse: any, publishOrDeleteNominationApiResponse: any }>(),
);

export const invokeEthanolNominationList = createAction(
    '[Get Ethanol Nomination List API]',
    props<{ payload: any }>(),
);

export const ethanolNominationListSuccess = createAction(
    '[Ethanol Nomination List Success]',
    props<{ ethanolNominationData: any }>(),
);

export const invokeNominationGroupingDetailByNo = createAction(
    '[Invoke Nomination Detail by Nomination No]',
    props<{ payload: any }>(),
);