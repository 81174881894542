import { Injectable } from '@angular/core';
import { displayActivityTrackerData, activityTrackerFields, tabTrackerData, ccatrackerData, frontCardData, groupTypeData,cardData } from 'src/app/shared/interface/activity-tracker';
import * as activityConstant from '../constants/activity-tracker.constants';



@Injectable({ providedIn: 'root' })
export class activitytrackerutility {
    displayActivityTracker: displayActivityTrackerData = { id: "", displayValue: "", displayname: "", trackerData: [] };

    prepareActivityTrackerData(activityTrackerData: activityTrackerFields, id: string): displayActivityTrackerData {
      
        this.displayActivityTracker.id = id;
        this.displayActivityTracker.displayValue = activityTrackerData.voyageName;
        this.displayActivityTracker.displayname = activityTrackerData.voyageName;
        this.displayActivityTracker.trackerData = Array<tabTrackerData>();
        let tabheaderData: tabTrackerData = { id: "", displayValue: "", displayname: "", activityTrackerData: Array<groupTypeData>() }
        let headerString = "HeaderChanges";
        tabheaderData.id = headerString;
        tabheaderData.displayValue = headerString;
        tabheaderData.displayname = "Cargo Changes";
        tabheaderData.activityTrackerData = [];
        if (activityTrackerData.caaHeaderFields.length == 0) {
            if (activityTrackerData.caaItemFields.length == 0) {
                tabheaderData.displayname = "";
            }
        }

        let commonfrontCardCollection: frontCardData[] = [];

        activityTrackerData.caaHeaderFields.forEach(currentValue => {

            // Sorting according to Date Descending          

            currentValue.changes = currentValue.changes.sort((a, b) => new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime());

            let headerFrontCard: frontCardData = {
                id: "HeaderChanges", changetype: this.findKeyFromValue(currentValue.fields, activityConstant.ccagreyHeader), field: currentValue.fields,
                displayname: this.genearateDisplayName(currentValue.fields), optionalName: ""
                , changedDate: currentValue.changes[0].changedAt, changedValue: currentValue.changes[0].newValue,
                changes: []
            };

            commonfrontCardCollection.push(headerFrontCard);
            currentValue.changes.forEach(tracker => {
                // Make distinct
                let card: cardData = {
                    id: currentValue.fields, changedAt: tracker.changedAt, changedBy: tracker.changedBy,
                    newValue: tracker.newValue, oldValue: tracker.oldValue
                };
                headerFrontCard.changes.push(card);
            });


        });
        if (activityTrackerData.caaItemFields.length == 0) {
            let headergroupTypeData: groupTypeData[] = [];
            let changetypegrouped: any;
            changetypegrouped = commonfrontCardCollection.reduce(
                (result: any, keyValue: any) => {
                    (result[keyValue['changetype']] = result[keyValue['changetype']] || [])
                        .push(keyValue);
                    return result;
                }, {});
            for (let key in changetypegrouped) {
                let childItems: frontCardData[] = changetypegrouped[key];
                let grouptrackerData: groupTypeData = { id: "", changetype: key, displayName: this.getValueByKey(activityConstant.greyHeaderDisplay, key), optionalName: childItems[0].optionalName, groupTypechanges: childItems }
                headergroupTypeData.push(grouptrackerData)
                tabheaderData.activityTrackerData.push(grouptrackerData);
            }
            this.displayActivityTracker.trackerData.push(tabheaderData);
        }

        activityTrackerData.caaItemFields.forEach(currentValue => {

            let tabData: tabTrackerData = { id: "", displayValue: "", displayname: "", activityTrackerData: Array<groupTypeData>() }
            let headerString = "(" + currentValue.loadDischarge.charAt(0) + ") " + currentValue.port + "/" + currentValue.terminal;
            let terminalDisplayString = currentValue.terminal ?? "---";
            let headerDisplayString = "(" + currentValue.loadDischarge.charAt(0) + ") " + currentValue.port + "/" + terminalDisplayString;
            tabData.id = headerString;
            tabData.displayValue = headerString;
            tabData.displayname = headerDisplayString;
            tabData.activityTrackerData = [];
            let frontCardCollection: frontCardData[] = [];
            let groupTypeData: groupTypeData[] = [];


            this.displayActivityTracker.trackerData.push(tabData);
            // Sorting according to Date Descending          

            currentValue.fields.forEach(tracker => {
                // Sorting according to Date Descending                
                let groupbyKey: string;
                groupbyKey = this.findKeyFromValue(tracker.field, activityConstant.ccagroupByActivity);

                if (groupbyKey != "") {
                    let grouped: any;
                    grouped = tracker.changes.reduce(
                        (result: any, keyValue: any) => {
                            (result[keyValue[groupbyKey]] = result[keyValue[groupbyKey]] || [])
                                .push(keyValue);
                            return result;
                        }, {});
                    for (let key in grouped) {
                        let childItems: ccatrackerData[] = grouped[key];
                        childItems = childItems.sort((a, b) => new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime());
                        let groupType = this.findKeyFromValue(tracker.field, activityConstant.ccagreyHeader);

                        let optionaName = "";
                        let displayName = "";
                        if (groupType === "dateChanges") {
                            optionaName = tracker.changes[0].supplierReceiver;
                        }
                        if (groupType === "volumeChanges") {
                            displayName = childItems[0].cargoGrade;
                        } else {
                            displayName = this.genearateDisplayName(tracker.field);
                        }
                        let headerFrontCard: frontCardData = {
                            id: childItems[0].cargoIterinaryId, changetype: groupType, field: tracker.field, displayname: displayName// childItems[0].cargoGrade
                            , changedDate: childItems[0].changedAt, changedValue: childItems[0].newValue, optionalName: optionaName,
                            changes: []
                        };
                        //tabData.activityTrackerData.push(headerFrontCard);
                        frontCardCollection.push(headerFrontCard);
                        let trackerCardCollection: cardData[] = [];
                        childItems.forEach(trackerchange => {

                            let card: cardData = {
                                id: trackerchange.cargoIterinaryId, changedAt: trackerchange.changedAt, changedBy: trackerchange.changedBy,
                                newValue: trackerchange.newValue, oldValue: trackerchange.oldValue
                            };
                            trackerCardCollection.push(card);

                        });
                        const res = trackerCardCollection.findIndex(({ newValue, oldValue, changedAt, changedBy }, i, a) => i == a.findIndex(e => e.newValue == newValue && e.oldValue == oldValue && e.changedAt == changedAt && e.changedBy == changedBy));
                        headerFrontCard.changes.push(...new Set(trackerCardCollection));

                    }
                } else {

                    tracker.changes = tracker.changes.sort((a, b) => new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime());
                    let headerFrontCard: frontCardData = {
                        id: tracker.changes[0].cargoIterinaryId, changetype: this.findKeyFromValue(tracker.field, activityConstant.ccagreyHeader), field: tracker.field,
                        displayname: this.genearateDisplayName(tracker.field), optionalName: ""
                        , changedDate: tracker.changes[0].changedAt, changedValue: tracker.changes[0].newValue,
                        changes: []
                    };
                    frontCardCollection.push(headerFrontCard);
                    let trackerCardCollection: cardData[] = [];

                    tracker.changes.forEach(trackerchange => {
                        let card: cardData = {
                            id: trackerchange.cargoIterinaryId, changedAt: trackerchange.changedAt, changedBy: trackerchange.changedBy,
                            newValue: trackerchange.newValue, oldValue: trackerchange.oldValue
                        };
                        // Make distinct
                        trackerCardCollection.push(card);
                    });
                    const res = trackerCardCollection.findIndex(({ newValue, oldValue, changedAt, changedBy }, i, a) => i == a.findIndex(e => e.newValue == newValue && e.oldValue == oldValue && e.changedAt == changedAt && e.changedBy == changedBy));
                    headerFrontCard.changes.push(...new Set(trackerCardCollection));
                    const uniqueData = this.getUniqueObjectsByProperties(trackerCardCollection, ['newValue', 'oldValue','changedBy']);
                    
                }

            }
            );
            commonfrontCardCollection.forEach(frontcard =>
                frontCardCollection.push(frontcard)
            );
            let changetypegrouped: any;

            changetypegrouped = frontCardCollection.reduce(
                (result: any, keyValue: any) => {
                    (result[keyValue['changetype']] = result[keyValue['changetype']] || [])
                        .push(keyValue);
                    return result;
                }, {});
            const groupedArray = Object.entries(changetypegrouped);
            const changetypegroupedArray = groupedArray.sort((a: any, b: any) => activityConstant.ccaheaderorder.indexOf(a[0]) - activityConstant.ccaheaderorder.indexOf(b[0]));

            for (let key in changetypegroupedArray) {
                let childItems: any = changetypegroupedArray[key];
                let grouptrackerData: groupTypeData = { id: "", changetype: childItems[0], displayName: this.getValueByKey(activityConstant.greyHeaderDisplay, childItems[0]), optionalName: childItems[1][0].optionalName, groupTypechanges: childItems[1] }
                groupTypeData.push(grouptrackerData)
                tabData.activityTrackerData.push(grouptrackerData);
            }


        });
        return this.displayActivityTracker;

    }

    getUniqueObjectsByProperties<T>(arr: T[], properties: (keyof T)[]): T[] {
        const seen = new Set();
      
        return arr.filter(obj => {
          const key = properties.map(prop => obj[prop]).join('|');
          if (!seen.has(key)) {
            seen.add(key);
            return true;
          }
          return false;
        });
      }

    groupByKey(array: any, key: any) {
        return array
            .reduce((hash: any, obj: any) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }

    getValueByKey(obj: object, value: any): string {
        for (const [key, val] of Object.entries(obj)) {
            if (key === value) {
                return val;
            }
        }
        return "";
    }

    getKeyByValue(obj: object, value: any): string {

        for (const [key, val] of Object.entries(obj)) {
            if (val === value) {
                return key;
            }
        }
        return "";
    }

    findKeyFromValue(valueToFind: string, obj: any) {
        let foundKey: string = "";
        let matchedKey: string;
        for (const key in obj) {
            matchedKey = this.getKeyByValue(obj[key], valueToFind);
            if (matchedKey !== "") {
                foundKey = key;
                break;
            }
        }
        return foundKey;

    }
    genearateDisplayName(field: string) {
        let matchedKey: string = "";
        matchedKey = this.getValueByKey(activityConstant.displayFrontCardName, field);
        return matchedKey;

    }
}