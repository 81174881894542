<div class="ag-theme-alpine ag-grid-table cargo-planning-list">
  <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="colDefs" [defaultColDef]="defaultColDef"
    [rowSelection]="'multiple'" [rowData]="rowData" [animateRows]="true" [suppressMenuHide]="true"
    [embedFullWidthRows]="true" [components]="components" [getRowId]="getRowId"
    (gridReady)="onGridReady($event)" [masterDetail]="true" [detailCellRenderer]="detailCellRenderer"
    [detailCellRendererParams]="detailCellRendererParams" (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged($event)" [frameworkComponents]="frameworkComponents">
  </ag-grid-angular>
  <np-modal class="pipeline-nomination-modal"
    [show]="createNominationPipelineModal.open || cargoPlanningService.createNominaitonsFromCargo.value.open"
    [headerTitle]="cargoPlanningService.createNominaitonsFromCargo.value.open ? modalTitle : 'creating new cargo plan.'"
    [subHeaderText]="'*required fields'" [noModalButtons]="true">
    <app-create-pipeline-nomination
      (closeModalPopup)="closePipelineNominationModal($event)"></app-create-pipeline-nomination>
  </np-modal>
</div>