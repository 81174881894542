import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInformationService {

  constructor(private apiService: ApiService) { }


 
  getUserVacation(email: string){
    return this.apiService
    .get(`${environment.alertAPI}/GetUserVacation?userEmail=${email}`, 'alert')
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }


  updateAndInsertUserInformation(payload: any) {
    return this.apiService
    .post(
      `${environment.alertAPI}/UpdateAndInsertUserVacation`,
      'alert', payload
    )
    .pipe(
      map((response: any) => {
        return response
      })
    )
  };


}
