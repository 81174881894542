import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpContext,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { ToastService } from './toast.service';
export const MICOR_SERVICE_TYPE = new HttpContextToken<string>(() => 'nomination');
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  counter = 0;
  constructor(private http: HttpClient, private loadService: LoaderService, private toasterService: ToastService) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  private handleServerError(err: HttpErrorResponse) {
    if (err.status === 403) {
      this.toasterService.setToastNotification({
        show: true,
        type: 'error',
        msg: "value can not be saved as user has read only access"
      })
    }
      else if (err.error.success === false) {
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.error.result
        })
      } else if (err.error.Detail) {
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.error.Detail
        })
      }
    let errorResponse = err.error?.result ?? err.error.Detail;
    if(err.error.ErrorMessage?.length){
      errorResponse = err.error.ErrorMessage?.join(", ")
    }
    return throwError(() => new Error(errorResponse));
  }

  post(path: string, microService: string, body: object = {}): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http
      .post(`${path}`, JSON.stringify(body), {
        // ...this.httpOptions,
        context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)
      })
      .pipe(
        map((res) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return this.handleServerError(err);
        })
      );
  }

  patch(path: string, microService: string, body: object = {}): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http
      .patch(`${path}`, JSON.stringify(body), {
        // ...this.httpOptions,
        context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)
      })
      .pipe(
        map((res) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return this.handleServerError(err);
        })
      );
  }

  put(path: string, microService: string, body: object = {}): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http
      .put(`${path}`, JSON.stringify(body), {
        // ...this.httpOptions,
        context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)
      })
      .pipe(
        map((res) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return this.handleServerError(err);
        })
      );
  }

  get(path: string, microService: string, body?: object): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http.get(`${path}`, {
      // ...this.httpOptions,
      context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)
    }).pipe(
      map((res) => {
        if (this.counter >= 1) {
          this.counter -= 1;
          if (this.counter === 0) {
            this.loadService.setLoading(false);
          }
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (this.counter >= 1) {
          this.counter -= 1;
          if (this.counter === 0) {
            this.loadService.setLoading(false);
          }
        }
        return this.handleServerError(err);
      })
    );
  }

  delete(path: string, microService: string, body: object): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http
      .put(`${path}`, JSON.stringify(body), {
        context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)
      })
      .pipe(
        map((res) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          if (this.counter >= 1) {
            this.counter -= 1;
            if (this.counter === 0) {
              this.loadService.setLoading(false);
            }
          }
          return this.handleServerError(err);
        })
      );
  }

  trueDelete(path: string, microService: string,  body?: object): Observable<any> {
    this.counter += 1;
    if (this.counter > 0) {
      this.loadService.setLoading(true);
    }
    return this.http
      .delete(`${path}`, {context: new HttpContext().set(MICOR_SERVICE_TYPE, microService)})
      .pipe(
      map((res) => {
        if (this.counter >= 1) {
          this.counter -= 1;
          if (this.counter === 0) {
            this.loadService.setLoading(false);
          }
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (this.counter >= 1) {
          this.counter -= 1;
          if (this.counter === 0) {
            this.loadService.setLoading(false);
          }
        }
        return this.handleServerError(err);
      })
    );
  }
}
