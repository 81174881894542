<div class="subscriber-alerts">
    <div class="label-1"><img src="../../assets/icons/icon-refresh.svg" alt="subscriber-icon"> list of users getting notifications by this rule <span class="close-button" (click)="closePopup();">X</span></div>
    <div>
        <hr>
        <p *ngIf="selectedUserList.length === 0" class = "styled-text">No users are recieving notifications for this rule, you may edit, delete, or subscribe </p>
        <ul *ngIf="selectedUserList.length !== 0">
        <li class="label-2" *ngFor="let i of selectedUserList">
            {{ i }}
        </li>
        </ul>
    </div>
</div>


