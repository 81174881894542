import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-number-cell-editor',
  templateUrl: './number-cell-editor.component.html',
  styleUrls: ['./number-cell-editor.component.scss']
})
export class NumberCellEditorComponent implements ICellEditorAngularComp {
  value: any = '';

  agInit(params: any): void {
    this.value = params.value;
  }

  onKeyPress(event: KeyboardEvent): void {
    const keyCode = event.key;
    if (!(['0','1','2','3','4','5','6','7','8','9'].includes(keyCode))) {
      event.preventDefault();
    }
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return false;
  }
}
