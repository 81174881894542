import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectEthanolTRFiltersResponse, selectPipelineTRFiltersResponse, selectPipelineNomFiltersResponse, selectUDMScreen } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { EthanolViewComponent } from '../../../../replenishment-planning/ethanol-view/ethanol-view.component'
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { DatePipe } from '@angular/common';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { TRScreen, UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { getBCPFilters } from 'src/app/bcp-dashboard/store/selector/bcp-dashboard.selector';
@Component({
  selector: 'app-create-custom-view',
  templateUrl: './create-custom-view.component.html',
  styleUrls: ['./create-custom-view.component.scss']
})
export class CreateCustomViewComponent implements OnInit {
  @Input('isExpanded') isExpanded!: any;
  @Input() desk: any;
  @Input() deskParam: any;
  filterList$ = this.appStore.pipe(select(selectEthanolTRFiltersResponse));
  pipelineFilterList$ = this.appStore.pipe(select(selectPipelineTRFiltersResponse));
  pipelineNomFilterList$ = this.appStore.pipe(select(selectPipelineNomFiltersResponse));
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  bcpFilters$ = this.store.pipe(select(getBCPFilters));
  destroy$: Subject<boolean> = new Subject<boolean>();
  public filterHeaderName: any[] = [];
  public createCustomViewForm!: FormGroup;
  public currentFilterValues: any = {};
  public userDetails: any = {};
  @ViewChild(EthanolViewComponent) ethanolViewComponent!: EthanolViewComponent;
  currentScreen: any;
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  constructor(private appStore: Store<AppState>, private router: Router, private utilities: Utilities, private ethanolService: EthanolTruckService, private datePipe: DatePipe, private nominationService: NominationService, private toasterService: ToastService,
    private store: Store) {
    this.createCustomViewForm = new FormGroup({
      customViewName: new FormControl('', Validators.required),
      columnOrder: new FormControl(true),
      columnExpansion: new FormControl(true),
    });
  }


  initFiltersForEthanol(){
    this.filterList$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        response.ethanolFilterValues.forEach((el: any) => {
          if (el?.headerName !== "terminal ownership" && el?.headerName !== "mode of transport" && el?.headerName !== "unit of measure" && el?.headerName !== 'customer') {
            this.filterHeaderName.push(el.headerName);
            this.addControlToForm(el.headerName);
          }
          const [filterName, filterValues] = [el.headerName, el?.list];
          this.currentFilterValues[filterName] = JSON.parse(JSON.stringify(filterValues));
        });
        this.currentFilterValues["dateRange"] = { fromDate: response.ethanolFromDate, toDate: response.ehtanolToDate };
      }
    });
  }

  initFilterForBCP()
  {
    this.bcpFilters$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        next: (response: any) => {
          if(this.desk.startsWith('clean products'))
          {
              this.filterHeaderName.push("multiDesks");
              this.addControlToForm("multiDesks");              
              this.currentFilterValues["multiDesks"] = JSON.parse(JSON.stringify(response.BCPFilters.desksFilter));
          }
          this.filterHeaderName.push("locations");
          this.addControlToForm("locations");          
          this.currentFilterValues["locations"] = JSON.parse(JSON.stringify(response.BCPFilters.locationsFilter));

          this.filterHeaderName.push("products");
          this.addControlToForm("products");          
          this.currentFilterValues["products"] = JSON.parse(JSON.stringify(response.BCPFilters.productsFilter));
          if(this.desk?.includes('panama'))
          {
              this.currentFilterValues["offTakers"] = JSON.parse(JSON.stringify(response.BCPFilters.offTakers));
          }
        },
        error: (err: any) => {
            console.log(err);
        },
        complete: () => { },
    });
  }
  
  
  ngOnInit(): void {
    this.userDetails$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        this.userDetails = response.userDetails;
      }
    });
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen;          
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    
    if(this.currentScreen == TRScreen.BCPDashboard)
      this.initFilterForBCP();
    else if (this.desk === 'ethanol + uswc'|| this.desk === 'ethanol + usec') {
      this.initFiltersForEthanol();
    } else if (this.desk.startsWith('clean products')) {
      this.addFilterTogglesFromPipeline()
    }
  }

  addFilterTogglesFromPipeline() {
    if (this.currentScreen === "replenishmentplanning") {
      this.addFilterTogglesForPipelineTR();
    } else if (this.currentScreen === "nominations") {
      this.addFilterTogglesForPipelineNom();
    }
  }

  addFilterTogglesForPipelineNom() {
    this.pipelineNomFilterList$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        response.pipelineNomFilterValues.forEach((el: any) => {
          if (el?.headerName !== "terminal ownership" && el?.headerName !== "mode of transport" && el?.headerName !== "unit of measure") {
            if (el.headerName === "transport system") {
              this.filterHeaderName.push("transportSystem");
              this.addControlToForm("transportSystem");
            } else {
              this.filterHeaderName.push(el.headerName);
              this.addControlToForm(el.headerName);
            }
          }
          const [filterName, filterValues] = [el.headerName, el?.list];
          this.currentFilterValues[filterName] = JSON.parse(JSON.stringify(filterValues));
        });
        this.currentFilterValues["dateRange"] = { fromDate: response.pipelineFromDate, toDate: response.pipelineToDate };
      }
    });
  }

  addFilterTogglesForPipelineTR() {
    this.pipelineFilterList$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        response.pipelineFilterValues.forEach((el: any) => {
          if (el?.headerName !== "terminal ownership" && el?.headerName !== "mode of transport" && el?.headerName !== "unit of measure") {
            if (el.headerName === "transport system") {
              this.filterHeaderName.push("transportSystem");
              this.addControlToForm("transportSystem");
            } else {
              this.filterHeaderName.push(el.headerName);
              this.addControlToForm(el.headerName);
            }
          }
          const [filterName, filterValues] = [el.headerName, el?.list];
          this.currentFilterValues[filterName] = JSON.parse(JSON.stringify(filterValues));
        });
        this.currentFilterValues["dateRange"] = { fromDate: response.pipelineFromDate, toDate: response.pipelineToDate };
      }
    });
  }

  addControlToForm(headerName: any) {
    this.createCustomViewForm.addControl(headerName, new FormControl(true));
  }
  onChangeSlider() {
    console.log(this.createCustomViewForm.value);
  }
  
  validateToggles() {
    if (!this.createCustomViewForm.value['customViewName']) {
      this.toasterService.setToastNotification({
        show: true,
        type: 'warning',
        msg: "You have not provided a name for this view. Please enter a name",
      });
      return;
    }
    let formmValues = Object.values(this.createCustomViewForm.value);
    let name = this.createCustomViewForm.value['customViewName'];
    let index = formmValues.indexOf(name);
    formmValues.splice(index, 1);
    let isAllTurnedOff = formmValues.every(val => { return val === false; })
    if (isAllTurnedOff) {
      this.toasterService.setToastNotification({
        show: true,
        type: 'warning',
        msg: "Atleast one filter or personalization option must be selected for a view to be saved",
      });
    }
    return !isAllTurnedOff;
  }

  formPayLoadWithFilterValues(){
    let payload: any = {};
    for (const [key, value] of Object.entries(this.currentFilterValues)) {
      if (key !== "dateRange") {
        if (key === "terminal ownership") {
          payload["terminalOwnership"] = value;
        } else if (key === "mode of transport") {
          payload["modeOfTranstport"] = value;
        } else if (key === "unit of measure") {
          payload["unitOfMeasure"] = value;
        } else if (key === "transport system") {
          payload["transportSystem"] = value;
        }  else {
          if (this.createCustomViewForm.get(key)?.value) {
            payload[key] = value;
          } else {
            payload[key] = value;
            payload[key].map((el: any) => el.checked = true);
          }
          payload[key] = value;
        }
      } 
      payload["fromDate"] =   this.getData(key,value)['fromDate']
      payload["toDate"] =   this.getData(key,value)['toDate']
      
    }
    return payload;
  }

  getData(key:any,value:any){
    let payload:any = {};
    if(key === "dateRange"){
      const values: any = value;
      payload["fromDate"] = values["fromDate"];
      payload["toDate"] = values["toDate"];
    }
    return payload;
  }
  saveCustomView() {
    this.utilities.colWidth.next(this.desk);
    let validated: any = this.validateToggles();
    if (validated && this.createCustomViewForm.value['customViewName']) {
      let payload = this.formPayLoadWithFilterValues();
      const passToComponent: any = { payload: payload, doColumnOrder: this.createCustomViewForm.get("columnOrder")?.value, doColumnExpansion: this.createCustomViewForm.get("columnExpansion")?.value };
      const sendDataToComponent = {
        tab: this.router.url,
        function: "saveColumnOrderExpansion",
        type: '',
        values: passToComponent,
      };
      const sendData = this.utilities.sendDataToChild(sendDataToComponent);
      this.utilities.addUnsubscribableList(sendData);
      if(localStorage.getItem("nomColData") && this.createCustomViewForm.get("columnOrder")?.value){
        const data:any = localStorage.getItem("nomColData");
        passToComponent.payload.nominationColumnOrderState = JSON.parse(data).colOrder;
      }

      if(localStorage.getItem("trColOrder") && this.createCustomViewForm.get("columnOrder")?.value){
        const data:any = localStorage.getItem("trColOrder");
        passToComponent.payload.columnOrderState = JSON.parse(data).colOrder;
      }

      if(localStorage.getItem("trColExpandData") && this.createCustomViewForm.get("columnExpansion")?.value){
        const data:any = localStorage.getItem("trColExpandData");
        passToComponent.payload.columnExpansion = JSON.parse(data).colOrder;
        passToComponent.payload.columnWidths = this.utilities.savedWidths;
      }
      this.createCustomViewApi(passToComponent.payload); 
    }
  }
  createCustomViewApi(payload: any) {
    const requestBody: any = {
      id: null,
      emailId: this.userDetails.email,
      desk: this.utilities.updateAPIParams(),      
      customViews: [
        {
          viewName: this.createCustomViewForm.get("customViewName")?.value,
        }
      ],
    }

    if(this.currentScreen == TRScreen.BCPDashboard)
    {   
      if(this.desk === 'panama')   
        requestBody.desk = this.desk;
      else if (this.desk === 'ethanol + uswc' || this.desk === 'ethanol + usec')       
        requestBody.customViews[0]["ethanolFilters"] = null;
      else if (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline"))   
      requestBody.customViews[0]["pipelineFilters"] = null;
      
      requestBody.customViews[0]["bcpFilters"] = {
          multiDeskStatus : (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) ? this.createCustomViewForm.get("multiDesks")?.value : false,
          locationStatus : this.createCustomViewForm.get("locations")?.value,
          productStatus : this.createCustomViewForm.get("products")?.value,
          multiDesks : (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) ? payload?.multiDesks : [],
          locations : payload?.locations,
          products : payload?.products,
          offTakers: (this.desk.includes('panama') ? payload?.offTakers : []),
          columnOrders: {
            bcpColumnOrder: payload?.bcpColumnOrderState ?? []            
          },
      }
    }
    else if (this.desk === 'ethanol + uswc' || this.desk === 'ethanol + usec') {  
      requestBody.customViews[0]["ethanolFilters"] = {
        terminalOwnership: payload?.terminalOwnership,
        unitOfMeasure: payload?.unitOfMeasure,
        modeOfTransport: payload?.modeOfTranstport,
        supplier: payload?.supplier,
        carrier: payload?.carrier,
        supplierStatus: this.createCustomViewForm.get("supplier")?.value,
        carrierStatus: this.createCustomViewForm.get("carrier")?.value,
        terminal: payload?.terminal,
        terminalStatus: this.createCustomViewForm.get("terminal")?.value,
        fromDate: this.datePipe.transform(payload?.fromDate, "yyyyMMdd"),
        toDate: this.datePipe.transform(payload?.toDate, "yyyyMMdd"),
        columnOrders: {
          replenishmentColumnOrder: payload?.columnOrderState ?? [],
          nominationColumnOrder: payload?.nominationColumnOrderState ?? [],
        },
        expandedColumns: payload?.columnExpansion ?? [],
        columnWidths: payload?.columnWidths ?? []
      };
      requestBody.customViews[0]["bcpFilters"] = null;

    } else if (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) {
      requestBody.customViews[0]["pipelineFilters"] = {
        transportSystemStatus: this.createCustomViewForm.get("transportSystem")?.value,
        productStatus: this.createCustomViewForm.get("product")?.value,
        modeOfTransport: this.desk === UDMDesk.PipelineUsecFloridaDesk || this.desk === UDMDesk.PipelineUswcPNWDesk || this.desk === UDMDesk.PipelineUsecPascagoulaDesk || this.desk === UDMDesk.PipelineUsecRichmondDesk ? payload?.modeOfTranstport : null,
        transportSystem: payload.transportSystem,
        product: payload.product,
        terminal: payload?.terminal,
        terminalStatus: this.createCustomViewForm.get("terminal")?.value,
        fromDate: this.datePipe.transform(payload?.fromDate, "yyyyMMdd"),
        toDate: this.datePipe.transform(payload?.toDate, "yyyyMMdd"),
        columnOrders: {
          replenishmentColumnOrder: payload?.columnOrderState ?? [],
          nominationColumnOrder: payload?.nominationColumnOrderState ?? [],
        },
        expandedColumns: payload?.columnExpansion ?? [],
        columnWidths: payload?.columnWidths ?? []
      };
      requestBody.customViews[0]["bcpFilters"] = null;
    }
    this.nominationService.createCustomViews(requestBody).subscribe({
      next: (response) => {
        this.nominationService.setSelectedView = requestBody.customViews[0].viewName;
        localStorage.setItem("selectedView",requestBody.customViews[0].viewName);
        this.nominationService.updateViewNameForStateManagement$.next(requestBody.customViews[0].viewName);
        this.nominationService.customViewSidePanel$.next(false);
        this.resetFilter();
        this.toasterService.setToastNotification({
          show: true,
          type: 'success',
          msg: "New Custom View was created. You can manage your custom content using the User Menu at the top right.",
        });
        localStorage.removeItem("nomColData");
        localStorage.removeItem("trColExpandData");
        localStorage.removeItem("trColOrder");
        this.nominationService.triggerGetAllFromCreateView$.next({isTrigger:true,selectedView:requestBody.customViews[0].viewName});
        this.nominationService.restrictLoader$.next(false);
        this.nominationService.applyEditedViewToUi$.next(requestBody.customViews[0].viewName);
      },
      error: (err:any) => {
        console.log({err})
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message
        });
      }
    });
  }

  resetFilter() {
    if(this.currentScreen == TRScreen.BCPDashboard)
    {
      this.createCustomViewForm.reset({
        customViewName: "",
        multiDesks:true,        
        locations:true,
        products: true,
        columnOrder: true        
      });
    }
    else if (this.desk === 'ethanol + uswc' || this.desk === 'ethanol + usec') {
      this.createCustomViewForm.reset({
        customViewName: "",
        terminal: true,
        carrier: true,
        supplier: true,
        columnOrder: true,
        columnExpansion: true
      });
    } else if (this.desk.startsWith("clean products") || this.desk.startsWith("pipeline")) {
      this.createCustomViewForm.reset({
        customViewName: "",
        terminal: true,
        transportSystem: true,
        product: true,
        columnOrder: true,
        columnExpansion: true
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}