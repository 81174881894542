<div class="edit-details">
    <form [formGroup]="editDetailsForm">
        <div
            *ngIf="(this.nomValues?.screenName === 'ethanoltr'||this.nomValues?.screenName === 'pipelinetr') ;else panamaEdit">
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6">
                    <label for="nominationVolume" class="label">nomination volume</label>
                    <input formControlName="nominationVolume" class="form-control" (keypress)="onKeyPress($event)">
                </div>
            </div>
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6 branded">
                    <label for="destinationDate" class="label">date </label>
                    <input type="date" formControlName="destinationDate" class="form-control text-field date-field"
                        id="destinationDate" [min]="">
                </div>
            </div>
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-4">
                    <button class="button" (click)="closePopup()">Cancel</button>
                </div>
                <div class="form-group col-md-4">
                    <button class="button" (click)="payloadFormationEthanolEdit('republish')"
                        [disabled]="!editDetailsForm.valid || nomValues?.modeOfTransportDesc?.toLowerCase() === 'truck'">Re-Publish</button>
                </div>
                <div class="form-group col-md-4">
                    <button class="button" (click)="payloadFormationEthanolEdit('save')"
                        [disabled]="!editDetailsForm.valid">Save</button>
                </div>
            </div>
        </div>
        <ng-template #panamaEdit>
            <div class="form-row pb-1 pr-2">
                <div *ngIf="nominationStatus" class="form-group col-md-6">
                    <label for="nominationVolume" class="label">nomination volume</label>
                    <input formControlName="nominationVolume" class="form-control" (keypress)="onKeyPress($event)">
                </div>
                <div *ngIf="counterPart==='TERPEL' && reserveStatus" class="form-group col-md-6 branded">
                    <label for="reserveVolume" class="label">reserve volume</label>
                    <input formControlName="reserveVolume" class="form-control" (keypress)="onKeyPress($event)">
                </div>
                <div *ngIf="counterPart==='DELTA' && safetyStatus" class="form-group col-md-6 branded">
                    <label for="safetyVolume" class="label">safety volume</label>
                    <input formControlName="safetyVolume" class="form-control" (keypress)="onKeyPress($event)">
                </div>
            </div>
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6 branded">
                    <label for="destinationDate" class="label">date </label>
                    <input type="date" formControlName="destinationDate" class="form-control text-field date-field"
                        id="destinationDate" [min]="">
                </div>
                <div *ngIf="counterPart==='TERPEL' && heelsStatus" class="form-group col-md-6">
                    <label for="heelsVolume" class="label">heels volume </label>
                    <input formControlName="heelsVolume" class="form-control" (keypress)="onKeyPress($event)">
                </div>
            </div>
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6">
                    <button class="button" (click)="closePopup()">Cancel</button>
                </div>
                <div class="form-group col-md-6">
                    <button class="button" (click)="onFormSubmit()" [disabled]="!editDetailsForm.valid">Update</button>
                </div>
            </div>
        </ng-template>
    </form>
</div>