import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertRuleResponse } from 'src/app/shared/interface/settings.interface';
import { DismissAlertService } from 'src/app/services/dismiss-alert.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { Subscription } from 'rxjs';
import { AlertsNotificationService } from 'src/app/services/alerts-notification.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-workspace-alerts-summary-card',
  templateUrl: './workspace-alerts-summary-card.component.html',
  styleUrls: ['./workspace-alerts-summary-card.component.scss']
})
export class WorkspaceAlertsSummaryCardComponent implements OnInit {

  @Input() cardAlert!: AlertRuleResponse;

  alertDesk!: string;
  alertTerminal!: string;
  alertMaterial!: string;
  alertDisabled!: boolean;
  alertDate!: string;
  alertBoldText!: string;
  alertIndicatorText!: string;
  @Output() closeCard = new EventEmitter<AlertRuleResponse>();
  private dismissSubscription!: Subscription;

  cardDateText!: string;
  showDismiss = true;

  private redirectCardLocationStorageKey = 'alertredirectcard-location';
  private redirectCardMaterialStorageKey = 'alertredirectcard-material';
  private redirectCardRuleStorageKey = 'alertredirectcard-rule';
  redirectCardLocation: string = '';
  redirectCardMaterial: string = '';
  redirectCardRule: string = '';

  constructor(private dismissAlertService: DismissAlertService, private alertService: AlertsService, private alertNotificationService: AlertsNotificationService,
              private dateService: DateService,
  ) {
  }

  ngOnInit(): void {
    this.alertDisabled = false;
    this.alertDesk = this.cardAlert.desk;

    this.alertDesk = this.cardAlert.desk;
    this.alertTerminal = this.cardAlert.location;
    this.alertMaterial = this.cardAlert.material;
    this.alertDate = this.cardAlert.alertDate;
    this.formatDescriptionString();


    this.cardDateText = this.generateDateText();

    if (this.cardAlert.udmNickname == null && this.cardAlert.desk.toLowerCase() === "panama") {
      this.showDismiss = false;
    }

    this.dismissSubscription = this.dismissAlertService.dismissAlertSubject.subscribe(alertToDismissId => {


      if (alertToDismissId === this.cardAlert.id) {


        this.alertDisabled = true;
        this.closeCard.emit(this.cardAlert);
      }
    });
  }

  ngOnDestroy(): void {
    this.dismissSubscription.unsubscribe();
  }

  openDismissModal() {

    this.dismissAlertService.provideAlertInformation(this.alertBoldText, this.cardAlert.id, this.cardAlert);
    this.dismissAlertService.setModalShow(true);
  }

  formatDescriptionString() {
    const wordList = this.cardAlert.message.split(' ');
    wordList.pop();
    wordList.pop();

    if (this.cardAlert.ruleType.toLowerCase() === "inventory" || this.cardAlert.ruleType.toLowerCase() === "actualdemand") {
      this.alertBoldText = wordList[0] + " " + wordList[1];
      this.alertIndicatorText = wordList.slice(2).join(' ');
    }
    else if (this.cardAlert.ruleType.toLowerCase() === "reconciliation") {
      this.alertBoldText = "unreconciled inventory!";
      this.alertIndicatorText = wordList.join(' ');
    }else{
      this.alertIndicatorText = wordList.join(' ');
    }
  }


  takeUserToIssue() {
    //Alerts always bring the user back to replenishment planning for alert handling
    let urlBasis = this.getUrlBasis();

    //Get the desk from the alert, and then we will format it to prevent % characters and space characters converted for URL
    let deskPortion = this.cardAlert.desk.toLowerCase();
    deskPortion = deskPortion.split("%").join("%25").split(" ").join("%20");

    let deskIdPortion = this.cardAlert.deskId ?? '';


    //Location will be obtianed from the card, and formatted in teh same manner for URL to prevent spaces and % characters
    let locationPortion = this.cardAlert.location.toLowerCase();
    locationPortion = locationPortion.split("%").join("%25").split("-").join("%2D").split(" ").join("%20");

    let materialPortion = "";
    
    //Panama uses udmNicknames right now while the rest of the side panels will use material number later (for now will assume material name)
    materialPortion = this.cardAlert.desk.toLowerCase() === "panama" ?
      this.cardAlert.udmNickname!.split("%").join("%25").split(" ").join("%20"):
      this.cardAlert.material.split("%").join("%25").split(" ").join("%20");

      let materialGroupPortion = this.cardAlert.materialGroupNumber != null? this.cardAlert.materialGroupNumber.split("-").join("%2D"): '';

    //follow the format used for email and teams alerts, so we can re-use code
    urlBasis += "?" + "desk=" + deskPortion + "&" 
                    + "deskId=" + deskIdPortion + "&"
                    + "location=" + locationPortion + "&" 
                    + "product=" + materialPortion  + "&"
                    + "materialNumber=" + this.cardAlert.materialNumber + "&"
                    + "materialGroup=" + materialGroupPortion;
    window.location.href = urlBasis;
  }

  getUrlBasis(){

    if(this.cardAlert.ruleType.toLowerCase() == 'inventory' || this.cardAlert.ruleType.toLowerCase() == 'actualdemand'){
      return "/replenishmentPlanning";
    }else if(this.cardAlert.ruleType.toLowerCase() == 'reconciliation'){
      return "/replenishmentPlanning";
    }

    return "";
  }

  generateDateText() {

    return "created " + this.dateService.getSpelledDateFromISOString(this.cardAlert.createdDate) 
          + " - " + this.dateService.getLocalTimeFromISO(this.cardAlert.createdDate);

  }

  gotoAlertNotifications() {    
    this.redirectCardLocation = this.cardAlert.location.toLowerCase();
    localStorage.setItem(this.redirectCardLocationStorageKey, this.redirectCardLocation);
    this.redirectCardMaterial = this.cardAlert.materialNumber? this.cardAlert.materialNumber : "";
    localStorage.setItem(this.redirectCardMaterialStorageKey, this.redirectCardMaterial);
    this.redirectCardRule = this.cardAlert.ruleId;    
    localStorage.setItem(this.redirectCardRuleStorageKey, this.redirectCardRule);
    window.location.href = '/settings/alerts';
  }
}
