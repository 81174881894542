import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AutoCompleteComponent } from '../../autocomplete/autocomplete.component';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { NominationService } from 'src/app/services/nomination.service';
import { CreatePipelineNominationComponent } from 'src/app/core/components/create-pipeline-nomination/create-pipeline-nomination.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectDesk } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';



interface LocationDetails {
  locationDetails: any,
  userFullEmail: any
}
@Component({
  selector: 'app-cargo-planning-detail-cell-renderer',
  templateUrl: './cargo-planning-detail-cell-renderer.component.html',
  styleUrls: ['./cargo-planning-detail-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CargoPlanningDetailCellRendererComponent implements ICellRendererAngularComp, OnInit {

  params!: ICellRendererParams & LocationDetails;
  masterGridApi!: GridApi;
  detailGridApi!: GridApi;
  rowId!: string;
  rowData!: any[];
  public frameworkComponents: any;
  public scheduledType = [{ id: "O" }, { id: "D" }];
  pipelineNominationModal = { open: false, modal: 'createPipelineNomination' };
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDeskParameter: any;
  selectedDeskId: number = 0;
  selectedDesk: any;
  region: any;
  @ViewChild(CreatePipelineNominationComponent) createPipelineNominationComponent!: CreatePipelineNominationComponent;
  colDefs: ColDef[] = [
    {
      headerName: "location",
      field: "locationName",
      sortable: true,
      editable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          region: this.region,
          desk: this.selectedDesk,
          deskParameter: this.selectedDeskParameter,
          modeOfTransport: this.params.data.mot,
          transportSystem: this.params.data.transportSystem,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.name}_(${data?.id})` : '';
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      headerName: "load/discharge",
      field: "scheduleType",
      sortable: true,
      editable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'id',
          showedColumn: 'id',
          desk: this.selectedDesk,
          rowData: this.scheduledType,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'id',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data?.id;
              },
            },
            {
              field: 'id',
              valueGetter: (params: any) => console.log(params),
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      headerName: "counterparty",
      field: "contractPartner",
      sortable: true,
      editable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300,
    },
    {
      headerName: "sap contract",
      field: "nominationReferenceDocument",
      sortable: true,
      editable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300,
      onCellValueChanged:(params:any)=> {params.node.setDataValue(params.column.colId, params.newValue?.replace(/\b0+/g, "")?.trim())
      }
    },
    {
      headerName: "contract line no",
      field: "nominationReferenceDocumentItem",
      sortable: true,
      editable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'contractLineNumber',
          showedColumn: 'contractLineNumber',
          region: this.region,
          desk: this.selectedDeskParameter,
          deskId: this.selectedDeskId,
          deskParameter: this.selectedDeskParameter,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'contractLineNumber',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.contractLineNumber} | ${data?.sraDealDetailReferenceNumber} | ${data?.location} | ${data?.modeOfTransportDescription} | ${data.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}` : '';
              },
            },
            {
              field: 'contractLineNumber',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      },
    },
    {
      headerName: "TSW Scheduled Date (ETA)",
      field: "uiScheduledDate",
      sortable: true,
      editable: true,
      cellEditor: "datePicker",
      minWidth: 200,
      width: 200,
      maxWidth: 300,
    },
    {
      headerName: "ETB",
      field: "uiEtb",
      sortable: true,
      editable: true,
      cellEditor: "datePicker",
      minWidth: 200,
      width: 200,
      maxWidth: 300,
    },
    {
      headerName: "end load/discharge date",
      field: "uiEndLoadDate",
      editable: true,
      sortable: true,
      minWidth: 200,
      width: 200,
      maxWidth: 300, cellEditorParams: {resetToMin: true},
      cellEditor: DatePickerComponent
    },
  ];
  locationDetails: any;
  cargoScheduleUpdates: any = [];
  copyRowData: any;
  uniqueProds!: any[];
  cargoSchedulesPayload: any = {};
  headerToItemNav!: any[];
  newCargoSchedulesArray: any = [];
  constructor(private cargoPlanningService: CargoPlanningService, private nominationService: NominationService, private appStore: Store<AppState>, private utilities: Utilities) {
    this.frameworkComponents = { datePicker: DatePickerComponent };
  }
  ngOnInit(): void {
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedDesk = response.selectedDesk;
          this.selectedDeskId = +response.selectedDeskId;
          this.selectedDeskParameter = response.selectedDeskParam;
          this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        },
        error: (err: any) => { },
        complete: () => { },
      });
  }

  agInit(params: ICellRendererParams<any, any, any> & LocationDetails): void {
    this.params = params;
    this.locationDetails = params?.locationDetails
    this.masterGridApi = params.api;
    this.rowData = params?.data?.cargoSchedules;
    this.rowId = params?.node?.id!;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  onGridReady(params: GridReadyEvent) {
    this.detailGridApi = params.api;
    const gridInfo = {
      id: this.rowId,
      api: params.api
    }
    let products: any[] = [];
    for (let i in this.rowData) {
      const productList = this.rowData[i].products;
      for (let j in productList) {
        products.push(productList[j].demandMaterialDesc);
        this.rowData[i][productList[j].demandMaterialDesc?.replace(/[{(.)}]/g, '')] = productList[j].scheduledQty;
      }
    }
    this.uniqueProds = [...new Set(products)];
    for (let i in this.uniqueProds) {
      const grid = {
        field: this.uniqueProds[i]?.replace(/[{(.)}]/g, ''),
        headerName: this.uniqueProds[i],
        editable: true,
        minWidth: 350,
        width: 350,
        maxWidth: 400,
      }
      if (this.colDefs?.map((el: any) => el?.headerName)?.includes(this.uniqueProds[i])) {
        const rowIndex = this.colDefs?.map((el: any) => el?.headerName)?.indexOf(this.uniqueProds[i]);
        this.colDefs[rowIndex] = grid;
      } else {
        this.colDefs.push(grid);
      }
    }
    this.copyRowData = JSON.parse(JSON.stringify(this.rowData));
    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
    this.detailGridApi.setColumnDefs(this.colDefs);
  }

  onCellValueChanged(event: CellValueChangedEvent) { //NOSONAR lightweight logging
    if (this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value?.length > 0) {
      this.headerToItemNav = JSON.parse(JSON.stringify(this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value));
    } else {
      this.headerToItemNav = [];
    }
    if (this.cargoScheduleUpdates?.map((el: any) => el?.id)?.includes(event?.data?.id)) {
      this.cargoSchedulesPayload = JSON.parse(JSON.stringify(this.cargoScheduleUpdates?.find((el: any) => el?.id === event?.data?.id)));
    } else {
      this.cargoSchedulesPayload = {};
    }
    const detailRowIndex = this.copyRowData?.map((el: any) => el?.id)?.indexOf(event?.data?.id);
    const keys = Object.keys(this.copyRowData[detailRowIndex]);
    for (const key of keys) {
      if (key !== "products" && event?.data[key] !== this.copyRowData[detailRowIndex][key] && this.uniqueProds?.map((el: any) => el?.replace(/[{(.)}]/g, '')?.toLowerCase())?.includes(key?.toLowerCase())) {
        this.cellValueChangedFunctionSplitif(event, key);
      } else if (key !== "products" && key !== "kepplerEta" && event?.data[key] !== this.copyRowData[detailRowIndex][key] && !this.uniqueProds?.map((el: any) => el?.toLowerCase())?.includes(key?.toLowerCase())) {
        this.cellValueChangedFunctionSplit(event);
      }
    }
    this.cargoSchedulesPayload["id"] = event?.data?.id;
    if (this.cargoScheduleUpdates?.map((el: any) => el?.id)?.includes(this.cargoSchedulesPayload?.id)) {
      const rowIndex = this.cargoScheduleUpdates?.map((el: any) => el?.id)?.indexOf(this.cargoSchedulesPayload?.id);
      this.cargoScheduleUpdates[rowIndex] = JSON.parse(JSON.stringify(this.cargoSchedulesPayload));
    } else {
      this.cargoScheduleUpdates.push(this.cargoSchedulesPayload);
    }
    const payload = {
      id: this.params?.data?.id,
      status: this.params?.data?.status === "published" || this.params?.data?.status === "failed" ? "unpublished" : this.params?.data?.status,
      nomRefId: this?.params?.data?.nomRefId,
      sys_modifiedOn: new Date().toISOString(),
      sys_modifiedBy: this.params?.userFullEmail,
      cargoSchedules: this.cargoScheduleUpdates,
    }
    if (this.headerToItemNav?.map((el: any) => el?.id)?.includes(this?.params?.data?.id)) {
      const rowIndex = this.headerToItemNav?.map((el: any) => el?.id)?.indexOf(this.params?.data?.id);
      this.headerToItemNav[rowIndex] = JSON.parse(JSON.stringify(payload));
    } else {
      this.headerToItemNav.push(payload);
    }
    this.cargoPlanningService?.cargoSchedulePayloadUpdates?.next(this.headerToItemNav);
    this.nominationService?.enableNominationsSaveButton$?.next(true);
  }

  cellValueChangedFunctionSplitif(event: any, key: any){
    const product = {
      demandMaterial: event?.data?.products?.find((el: any) => el?.demandMaterialDesc?.replace(/[{(.)}]/g, '') === key)?.demandMaterial,
      demandMaterialDesc:  event?.data?.products?.find((el: any) => el?.demandMaterialDesc?.replace(/[{(.)}]/g, '') === key)?.demandMaterialDesc,
      scheduledMaterial: event?.data?.products?.find((el: any) => el?.scheduledMaterialDesc?.replace(/[{(.)}]/g, '') === key)?.scheduledMaterial || null,
      scheduledMaterialDesc: event?.data?.products?.find((el: any) => el?.scheduledMaterialDesc?.replace(/[{(.)}]/g, '') === key)?.scheduledMaterialDesc || null,
      scheduledQty: Number(event?.data[key]),
      nomRefItemId: event?.data?.products?.find((el: any) => el?.demandMaterialDesc?.replace(/[{(.)}]/g, '') === key)?.nomRefItemId
    }
    if (this.cargoSchedulesPayload?.products?.length > 0 && this.cargoSchedulesPayload?.products?.map((el: any) => el?.demandMaterial).includes(product?.demandMaterial)) {
      const rowIndex = this.cargoSchedulesPayload?.products?.map((el: any) => el?.demandMaterial)?.indexOf(product?.demandMaterial);
      this.cargoSchedulesPayload["products"][rowIndex] = JSON.parse(JSON.stringify(product));
    } else if (!this.cargoSchedulesPayload["products"]) {
      this.cargoSchedulesPayload["products"] = [];
      this.cargoSchedulesPayload["products"]?.push(product);
    } else {
      this.cargoSchedulesPayload["products"]?.push(product);
    }
  }

  cellValueChangedFunctionSplit(event: any){
    if (event?.column?.getColId() === "scheduleType") {
      this.cargoSchedulesPayload[event?.column?.getColId()] = JSON.parse(JSON?.stringify(event?.data[event?.column?.getColId()]));
      this.cargoSchedulesPayload["referenceDocumentIndicator"] = JSON.parse(JSON?.stringify(event?.data?.referenceDocumentIndicator));
    } else if (event?.column?.getColId() === "uiScheduledDate") {
      let d = JSON.parse(JSON?.stringify(event?.data[event?.column?.getColId()])).split("/");
      const year = Number(d[2]);
      const month = Number(d[0]);
      const day = Number(d[1]);
      this.cargoSchedulesPayload["scheduledDate"] = new Date(Date.UTC(year, month - 1, day));
    } else if (event?.column?.getColId() === "uiEtb") {
      let d = JSON.parse(JSON?.stringify(event?.data[event?.column?.getColId()])).split("/");
      const year = Number(d[2]);
      const month = Number(d[0]);
      const day = Number(d[1]);
      this.cargoSchedulesPayload["etb"] = new Date(Date.UTC(year, month - 1, day));
    } else if (event?.column?.getColId() === "uiEndLoadDate") {
      let d = JSON.parse(JSON?.stringify(event?.data[event?.column?.getColId()])).split("/");
      const year = Number(d[2]);
      const month = Number(d[0]);
      const day = Number(d[1]);
      this.cargoSchedulesPayload["endLoadDate"] = event?.data[event?.column?.getColId()] === "01/01/0001" ? "0001-01-01T00:00:00" : new Date(Date.UTC(year, month - 1, day));
    } else if (event?.column?.getColId() === "locationName") {
      const locations = this.cargoPlanningService?.locationNameAndId?.value;
      this.cargoSchedulesPayload["locationName"] = event?.data[event?.column?.getColId()];
      this.cargoSchedulesPayload["locationId"] = locations?.find((el: any) => el?.name === event?.data[event?.column?.getColId()])?.id;
    } else if (event?.column?.getColId() === "nominationReferenceDocument" && event?.data[event?.column?.getColId()] === "") {
      this.cargoSchedulesPayload["nominationReferenceDocument"] = " ";
      this.cargoSchedulesPayload["nominationReferenceDocumentItem"] = " ";
    } else if (event?.column?.getColId() === "nominationReferenceDocumentItem" && event?.data[event?.column?.getColId()] === "") {
      this.cargoSchedulesPayload["nominationReferenceDocumentItem"] = " ";
    } else if (event?.column?.getColId() === "contractPartner" && event?.data[event?.column?.getColId()] === "") {
      this.cargoSchedulesPayload["contractPartner"] = " ";
    } else {
      this.cargoSchedulesPayload[event?.column?.getColId()] = JSON.parse(JSON?.stringify(event?.data[event?.column?.getColId()]));
    }
  }

  onCreateNominationsFromCargo(event: any) {
    const modal = { open: true, modal: 'createPipelineNomination' };
    this.pipelineNominationModal = { ...modal };
    const nominationWithReferenceData = JSON.parse(JSON.stringify(this.params?.data));
    this.deMergeDataStructure(nominationWithReferenceData);
    this.cargoPlanningService.restrictAutoAdjustment = true;
    this.cargoPlanningService?.createNominationFromCargoWithReference?.next({ initiate: true, data: nominationWithReferenceData });
    this.cargoPlanningService?.createNominaitonsFromCargo?.next({ open: true, modal: 'createPipelineNomination' });
    this.newCargoSchedulesArray = []
  }

  deMergeDataStructure(data: any) {
    this.newCargoSchedulesArray = [];
    data?.cargoSchedules?.forEach((element: any) => {
      element?.products?.forEach((dt: any, i: number) => {
        this.newCargoSchedulesArray.push({ ...element, ...dt });
      });
    });
    this.newCargoSchedulesArray?.forEach((element: any) => {
      const keys = Object.keys(element);
      keys?.map((key: any) => {
        if (this.uniqueProds?.map((el: any) => el?.toLowerCase())?.includes(key?.toLowerCase())) {
          delete element[key];
        }
      });
      delete element?.products;
    });
    data["headerToItemNav"] = this.newCargoSchedulesArray;
    data["vehicleName"] = data?.vesselName;
  }
}
