import { Component, OnInit } from '@angular/core';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { getUserDetails, selectDesk, selectEthanolTRFiltersResponse, trNominationList } from 'src/app/shared/store/selector/app.selector';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { invokeTrNominationList, saveEthanolNomFiltersResponse, updateNomination } from 'src/app/shared/store/action/app.action';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ToastService } from 'src/app/services/toast.service';
import * as Nom from '../../../shared/constants/nominations.constant'
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { MdmService } from 'src/app/services/mdm.service';





@Component({
  selector: 'app-create-nomination-ethanol-side-panel',
  templateUrl: './create-nomination-ethanol-side-panel.component.html',
  styleUrls: ['./create-nomination-ethanol-side-panel.component.scss']
})
export class CreateNominationEthanolSidePanelComponent implements OnInit {

  isDraftAccordionExpanded: boolean = false;
  isPublishedAccordionExpanded = false;
  isUnpublishedAccordionExpanded = false;
  isPublishedAccordionExpandedInternal : any;
  isUnPublishedAccordionExpandedInternal : any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDesk$ = this.store.pipe(select(selectDesk));
  desk: string = '';
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse));
  trNominationList$ = this.appState.pipe(select(trNominationList));
  trNominationForEthanol!: any;
  payLoad: any;
  sidePanelData: any = []
  draftNominations: any = []
  publishedNominations: any = []
  unpublishedNominations: any = []
  tankTransferNominations: any;
  nomSidepanelData: any;
  getClickedLineItem: any;
  getMatchedlineItem: any;
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  ethanolFilterResponse: any;
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName: any = '';
  selectedDeskParams: any;

  constructor(private appStore: Store<AppState>, private nominationService: NominationService, public toasterService: ToastService, private utilities: Utilities, private store: Store, private router: Router, private appState: Store<AppState>, public datePipe: DatePipe, private data: NominationService,
    private mdmService : MdmService) { }

  ngOnInit(): void {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    //subscribing to get selected desk
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk;
          this.selectedDeskParams = response.selectedDeskParam;
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.desk.split('+')[this.desk.split('+').length -1];
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.trNominationList$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.sidePanelData = response;
          this.filterSidePanelData();
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    //subscribing to get filter data and forming payload for sidepanel api
    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.ethanolFilterResponse = JSON.parse(JSON.stringify(response));
        if (response || response.isfilterUpdated) {
          this.payLoad = {
            desk: (this.desk.toLowerCase() === UDMDesk.EthanolUsec)?UDMDesk.EthanolUsecVal:UDMDesk.Ethanol,
            modeOfTransport: this.filterMOTResponse(response, 'mode of transport'),
            fromDate: response.ethanolFromDate,
            toDate: response.ehtanolToDate,
            terminal: this.filterResponse(response, 'terminal'),
            carrier: this.filterResponse(response, 'carrier'),
            supplier: this.filterResponse(response, 'supplier')
          };
        }
      },

    })

    this.nominationService.ethanolSidePanelData$.subscribe((data: any) => {
      this.getClickedLineItem = data
      this.getTerminalNominationAF(this.payLoad);
    }
    )

    this.filterSidePanelData();
  }

  getTerminalNominationAF(payload: any) {
    this.utilities.getEthanolNomPyaloadMasterData(payload);
    this.appState.dispatch(invokeTrNominationList({ payload }));
  }


  //filtering id's for sidepanel api payload from filter data
  filterResponse(response: any, filter: any) {
    let data = response.ethanolFilterValues.find(
      (value: any) =>
        value?.headerName?.toLowerCase() === filter
    )?.list;

    data = data?.length ? data.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.id) : [];
    return data;
  }

  filterMOTResponse(response: any, filter: any) {
    let mot: any = [];
    let data = response?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === filter);

    if (data?.length > 0) {
      data[0]?.list?.forEach((dt: any) => {
        if (dt?.checked) {
          if (dt?.name === "tank") {
            mot.push("pipeline");
          } else {
            mot.push(dt?.name);
          }
        }
      })
    } else {
      mot = [];
    }
    return mot;
  }

  // filtering side panel api respose for DRAFT,published,unpublished
  filterSidePanelData() {
    if (this.sidePanelData.length) {
      if (Object.keys(this.getClickedLineItem).length) {
        this.getMatchedlineItem = this.sidePanelData.find((val: any) => val.id === this.getClickedLineItem.data[this.getClickedLineItem.colDef.field + '_id'])
      }
      this.nomSidepanelData = JSON.parse(JSON.stringify(this.sidePanelData));
      this.formateScheduledDate();
      this.draftNominations = this.nomSidepanelData.filter((nom: any) => nom.sys_status?.toLowerCase() === "draft" || nom?.status?.toLowerCase() === "draft");
      console.log(this.draftNominations);
      if (this.getMatchedlineItem?.sys_status?.toLowerCase() === 'draft') {
        this.draftNominations.unshift(...this.draftNominations.splice(this.draftNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
        this.isDraftAccordionExpanded = true
        this.isPublishedAccordionExpanded = false;
        this.isUnpublishedAccordionExpanded = false;
      }
      this.publishedNominations = this.nomSidepanelData.filter((nom: any) => nom.sys_status?.toLowerCase() === "published" || nom?.status?.toLowerCase() === "published");
      if (this.getMatchedlineItem?.sys_status?.toLowerCase() === 'published') {
        this.publishedNominations.unshift(...this.publishedNominations.splice(this.publishedNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
        this.isDraftAccordionExpanded = false;
        this.isPublishedAccordionExpanded = true;
        this.isUnpublishedAccordionExpanded = false;
      }
      this.unpublishedNominations = this.nomSidepanelData.filter((nom: any) => (nom.sys_status?.toLowerCase() === "created" || nom.sys_status?.toLowerCase() === "unpublished") ||
        (nom.status?.toLowerCase() === "created" || nom.status?.toLowerCase() === "unpublished"));
      if (this.getMatchedlineItem?.sys_status?.toLowerCase() === 'unpublished') {
        this.unpublishedNominations.unshift(...this.unpublishedNominations.splice(this.unpublishedNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
        this.isDraftAccordionExpanded = false;
        this.isPublishedAccordionExpanded = false;
        this.isUnpublishedAccordionExpanded = true;
      }
    }
  }

  formateScheduledDate() {
    if (this.nomSidepanelData.length > 1) {
      this.nomSidepanelData.forEach((el: any) => {
        el.scheduledDate = this.dateCellRenderer(el?.scheduledDate);
        el.headerToItemNav.forEach((lineItem: any) => {
          lineItem.scheduledDate = this.dateCellRenderer(lineItem?.scheduledDate);
        })
      });
    }

  }

  goToNominations(nom: any) {
         // this set of code is for the transfer of desk
        let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.selectedDeskParams , id: this.SelectedDeskId };
        if(!this.SelectedDesk && !this.SelectedDeskParam){
          deskOj = this.SelectedDeskName;
        }
        localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
        // this set of code is for the transfer of nomination 
        localStorage.setItem('nomId', nom.id);
        //this set of code is for the transfer of filter
        this.nominationService.restrictNomApiGetCall$.next(true);
        localStorage.setItem('gEFRRP',JSON.stringify(this.ethanolFilterResponse));
        // this set of code is to open nomination in a new tab 
        window.open("/nominations");
        this.nominationService.goToNominationData$.next({ isTrue: true, data: nom })
  }

  deleteNomination(nominationDetails: any){
    this.data.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true);
    this.mdmService.actionConfirmed$.subscribe(result=>{
      if(result){
        if(nominationDetails !== ''){
          this.deleteNominationAfterConfirm(nominationDetails);
        }        
       this.mdmService.actionConfirm(false);
       this.mdmService.invokeReplenishmentToggleMethod(false);
       nominationDetails = ''; 
      }
    });
  }

  //delete nomination from sidepanel
  deleteNominationAfterConfirm(nominationDetails: any) {
    const reqPayload = [];
    const requestBody:any = {
      id: nominationDetails.id,
      deletionIndicator: 'X',
      textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
      sys_status: nominationDetails?.sys_status,
      deskFilter: this.selectedDeskParams,
      modeOfTransportDesc: nominationDetails?.modeOfTransportDesc
    }
    reqPayload.push(requestBody);
    this.utilities.getCreateUpdateMasterDataPayload(reqPayload);
    this.store.dispatch(updateNomination({ payload: reqPayload, publishOrDeleteNominationApiResponse: {} }));
    this.toasterService.setToastNotification({
      show: true,
      type: 'success',
      msg: "Nomination has been deleted",
    });
    setTimeout(() => {
      this.getTerminalNominationAF(this.payLoad);
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'deleteEthanolNomination',
        type: '',
      }
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }, 3000)
  }

  closeNominationsPanel() {
    this.nominationService.sidePanel$.next(false);
  }
  expandDraftAccordion() {
    this.isDraftAccordionExpanded = !this.isDraftAccordionExpanded;
    this.isPublishedAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }

  expandPublishedAccordion() {
    this.isPublishedAccordionExpanded = !this.isPublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }
  expandPublishedAccordionInternal(accordianId: any)
  {
    if(this.isPublishedAccordionExpandedInternal==accordianId)
      {
        this.isPublishedAccordionExpandedInternal=false;
      }else{
  this.isPublishedAccordionExpandedInternal=accordianId;

    }
  }

  expandUnPublishedAccordionInternal(accordianId: any)
  {
    if(this.isUnPublishedAccordionExpandedInternal==accordianId)
      {
        this.isUnPublishedAccordionExpandedInternal=false;
      }else{
  this.isUnPublishedAccordionExpandedInternal=accordianId;

    }
  }

  expandUnpublishedAccordion() {
    this.isUnpublishedAccordionExpanded = !this.isUnpublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isPublishedAccordionExpanded = false;
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }


  onCreateEthanolNominationFromTR(nomination: any) {
    nomination.screenName = 'side-panel-tr';
    this.nominationService.isModalPopupOpen$.next(false);
    if (!nomination.scheduledDate.includes('/')) {
      nomination.scheduledDate = this.dateCellRenderer(nomination.scheduledDate);
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'onCreateEthanolNominationFromTR',
      type: '',
      values: nomination
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }


}
