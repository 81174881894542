<div class="create-pipeline-nomination">
    <form [formGroup]="createPipelineNominationForm" (keydown.enter)="$event.preventDefault()">
        <ng-container>
            <!-- <div class="mot-container"
                *ngIf="selectedDeskParameter === 'floridadesk' || selectedDeskParameter === 'pnwdesk' || selectedDeskParameter === 'pascagouladesk' || selectedDeskParameter === 'richmonddesk'">
                <span class="small-text label mot-text"><span class="text-danger">* </span> vehicle identifier</span>
                <div class="form-row">
                    <div *ngFor="let scenario of motData; let i = index;"
                        class="radio-container mb-0 d-flex align-items-center form-group branded">
                        <input type="radio" class="mot-btns" (change)="updateFormFields(scenario?.value)"
                            name="scenario"  [value]="scenario?.name" 
                            [checked]="scenario?.checked === true"  id="radio-{{i}}">
                        <label for="radio-{{i}}" class="label" (keydown.enter)="updateFormFields(scenario?.value)"  tabindex="0">{{scenario.value}}</label>
                    </div>
                </div>
            </div> -->

        </ng-container>
        <div class="input-wrap">
            <div class="form-row pb-1 pr-2 d-flex">
                <div class="form-group col-md-9">
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="nominationNoPipeline" class="label"><span class="text-danger">*
                                </span>nomination
                                name/id</label>
                            <input type="text" formControlName="nominationNo" class="form-control"
                                id="nominationNoPipeline">
                            <div class="text-danger"
                                *ngIf="createPipelineNominationForm.controls['nominationNo'].invalid && (createPipelineNominationForm.controls['nominationNo'].dirty || createPipelineNominationForm.controls['nominationNo'].touched)">
                                nomination
                                no. limits to 16 characters</div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="d-flex justify-content-between">
                                <label htmlFor="transportSystem" class="label"><span class="text-danger">* </span>
                                    transport
                                    system</label>
                                <!-- <label class="text-black"
                                    *ngIf="(selectedDeskParameter !== 'floridadesk' && selectedDeskParameter !== 'pnwdesk' && selectedDeskParameter !== 'pascagouladesk' && selectedDeskParameter !== 'richmonddesk')"><input
                                        type="checkbox" #isMultiple formControlName="selectMultipleCheckbox"
                                        (change)="onTransportChange(isMultiple)" [disabled]="showMultipleTables">
                                    multiple</label> -->
                            </div>

                            <app-select-dropdown [items]="transportSystemArray" formControlName="transportSystem"
                                [defaultValue]="defaultTransportSystem" #transportSystemDropdown
                                (afterChange)="onTransportSystemChange()" [disabled]="isMultipleTransport">
                            </app-select-dropdown>
                        </div>
                        <div class="form-group col-md-4">
                            <!-- *ngIf="((!inCpTab) && (!nomCargoFieldObj.vesselFields && !nomCargoFieldObj.bargeFields))"> -->
                            <label htmlFor="cycleId" class="label">cycle Id</label>
                            <div class="d-flex align-items-center checkbox-container">
                                <input type="text" formControlName="cycleId" class="form-control">
                            </div>
                        </div>
                        <!-- <div class="form-group col-md-4"
                            *ngIf="(nomCargoFieldObj.bargeFields && (inCpTab || inCpNomTab)) ">
                            <div class="d-flex justify-content-between">
                                <label for="tug" class="label">tug</label>

                            </div>

                            <input type="text" class="form-control text-field" formControlName="tug">
                        </div> -->

                        <!-- <div class="form-group col-md-4"
                            *ngIf="((inCpTab || inCpNomTab) && (nomCargoFieldObj.vesselFields || nomCargoFieldObj.bargeFields) )">
                            <div class="d-flex justify-content-between">
                                <label for="vessel" class="label" *ngIf="nomCargoFieldObj.vesselFields"> <span
                                        class="text-danger">* </span> vessel name</label>
                                <label for="barge" class="label" *ngIf="nomCargoFieldObj.bargeFields"> <span
                                        class="text-danger">* </span> barge name</label>
                            </div>
                            <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="vesselName"
                                [defaultValue]="vesselNameValue">
                            </app-select-dropdown>
                        </div>
                        <div class="form-group col-md-4"
                            *ngIf="((inCpTab || inCpNomTab) && (nomCargoFieldObj.railFields) )">
                            <div class="d-flex justify-content-between">
                                <label for="rail" class="label"><span class="text-danger">* </span> rail name</label>
                            </div>

                            <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="railName"
                                [defaultValue]="vesselNameValue">
                            </app-select-dropdown>
                        </div> -->
                        <div class="form-group col-md-4">
                            <label for="btCustodyChain" class="label">
                                bt custody chain
                                </label>
                            <input type="text" formControlName="btCustodyChain" class="form-control"
                                id="btCustodyChain">
                        </div>
                        <!-- <div *ngIf="((trNominationForPipeline?.editNomination) && (trNominationForPipeline.nominationKey!=null) && (isSearchPopup==false) && (isCargoDesks==true) )" class="form-group col-md-4" >
                            <label for="nominationKey" class="label">
                                nom key
                                </label>
                            <input type="text" formControlName="nominationKey" class="form-control" readonly="true"
                                id="nominationKey">
                        </div> -->
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label htmlFor="nominationNotes" class="label">nomination notes</label>
                    <textarea class="form-control nomNotes" formControlName="nominationNotes" maxlength="120"
                        rows="3"></textarea>
                </div>
            </div>
            <div class="form-row pb-1 pr-2 d-flex align-items-center">

                <div class="form-group col-md-3">
                    <input type="checkbox" #isAdditional  [(ngModel)] = 'addCheckbox' (keydown.enter)="toggleAddi()" (change)="onAddititonalData(isAdditional)"
                        formControlName="selectMultipleCheckbox1" id="selectMultipleCheckbox1">
                    <label class="ml-2 " for="selectMultipleCheckbox1">additional information</label>
                </div>

            </div>
        </div>
        <ng-container formArrayName="transport">
            <ng-container *ngFor="let transportControl of transportArray().controls; let i= index" [formGroupName]="i">
                <div class="table-wrapper">
                    <!-- <div class="form-group" *ngIf="isMultipleTransport && hideDestination">
                        <div class="row">
                            <div class="col-3">
                                <div class="d-flex mb-2">
                                    <label for="transportMode" class="label mr-2 mb-0">transport system {{i +
                                        1}} <span class="text-danger">* </span></label>
                                    <button *ngIf="isMultipleRowTransport >= 1" class="button button-text icon-remove"
                                        style="margin-left: 10px !important;" (click)="deleteTransportSystem(i)"
                                        title="delete selected transport">
                                        <span class="sr-only">delete transport system</span>
                                    </button>
                                </div>
                                <app-select-dropdown [items]="transportSystemModeArray"
                                    [defaultValue]="defaultTransportModalSystem[i]"
                                    formControlName="transportSystemMode" (afterChange)="fetchLocation(i)">
                                </app-select-dropdown>
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="hideDestination">
                        <div *ngIf="isMultipleTransport || i===0">
                            <span class="table-type">origin</span>
                            <table aria-describedby="origin">
                                <tr>
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> description</th>
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> rdi</th>
                                    <!-- <th *ngIf="inCpTab || inCpNomTab"> counter party</th> -->

                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled date
                                    </th>
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]"></span> Arrival Time</th>
                                    <!-- <th *ngIf="inCpTab"><span class="text-danger" *ngIf="isOriginRequired[i]"> </span>
                                        end load date</th> -->
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> location</th>
                                    
                                    <th> tank</th>

                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled
                                        product material code
                                    </th>

                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> scheduled
                                        product
                                    </th>
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> demand product
                                        material code
                                    </th>

                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> demand product
                                    </th>
                                    <th><span class="text-danger" *ngIf="isOriginRequired[i]">* </span> qty(mb)</th>
                                    <th>sap contract</th>
                                    <th>contract line no.</th>
                                    <th *ngIf="isAdditionalData">batch origin location</th>
                                    <th *ngIf="isAdditionalData">valuation type origin location</th>
                                    <th *ngIf="isAdditionalData">batch destination location</th>
                                    <th *ngIf="isAdditionalData">valuation type destination location</th>
                                    <th *ngIf="nominationService.isNominationScreen !== 'cargo'">Confirm</th>
                                </tr>
                                <ng-container formArrayName="origin">
                                    <ng-container *ngFor="let list of origin(i).controls; let j = index"
                                        [formGroupName]="j">
                                        <tr>
                                            <td>
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="originRDIDescArray"
                                                        formControlName="description"
                                                        [defaultValue]="originRDIDescSelected[i][j]"
                                                        (afterChange)="onDescriptionChange(i, j)">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="rdiWidth">
                                                    <app-select-dropdown [items]="originRDIArray" formControlName="rdi"
                                                        [defaultValue]="originRDISelected[i][j]"
                                                        (afterChange)="onRdiChange(i, j)">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>


                                            <!-- <td *ngIf="inCpTab || inCpNomTab"><input type="text"
                                                    class="form-control text-field" formControlName="counterParty"></td> -->

                                            <td><input type="date" class="form-control text-field date-field"
                                                    formControlName="scheduledDate"
                                                    (input)="onOriginScheduledDateInput($event, origin(i).controls, destination(i).controls, i, j)">
                                            </td>
                                            <td>{{scheduledTime[i][j]}}</td>
                                            <!-- <td *ngIf="inCpTab"><input class="form-control text-field date-field"
                                                    formControlName="endLoadDate" type="date"></td> -->

                                            <td>
                                                <span *ngIf="terminalList[i]?.length>0">
                                                    <div class="statwidth"> <app-select-dropdown
                                                            [items]="terminalList[i]" formControlName="location"
                                                            [defaultValue]="locationOriginArray[i][j]"
                                                            (afterChange)="onLocChangeOrigin(i, j)">
                                                        </app-select-dropdown>
                                                    </div>

                                                </span>
                                            </td>

                                            <td>
                                                <div class="statwidth"> <app-select-dropdown [items]="tankOriginList[i][j]" formControlName="tankCode"
                                                        [defaultValue]="defaultTankOrigin[i][j]" [disabled]="!isTankOriginDisabled[i][j]"></app-select-dropdown>
                                                </div>
                                            </td>
                                            <td *ngIf="terminalList[i]?.length>0">
                                                <div class="statwidth">
                                                    <app-select-dropdown
                                                        [items]="scheduledDemandProductListMaterialCode[i][j]"
                                                        formControlName="scheduledProductMaterialCode"
                                                        [defaultValue]="defaultProductMaterialCode[i][j]"
                                                        (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChangeMaterialCode(i, j, 'origin')">
                                                    </app-select-dropdown>
                                                </div>

                                            </td>
                                            <td *ngIf="terminalList[i]?.length>0">
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="scheduledDemandProductList[i][j]"
                                                        formControlName="scheduledProduct"
                                                        [defaultValue]="defaultProduct[i][j]"
                                                        (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChange(i, j)">
                                                    </app-select-dropdown>
                                                </div>

                                            </td>
                                            <td>
                                                <span *ngIf="terminalList[i]?.length > 0">
                                                    <div class="statwidth">
                                                        <app-select-dropdown
                                                            [items]="demandProductListMaterialCode[i][j]"
                                                            [defaultValue]="demandProductOriginMaterialCode[i][j]"
                                                            formControlName="demandProductMaterialCode"
                                                            (afterChange)="onDemandProductChangeMaterialCode(i, j, 'origin')">
                                                        </app-select-dropdown>
                                                    </div>
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="terminalList[i]?.length > 0">
                                                    <div class="statwidth">
                                                        <app-select-dropdown [items]="demandProductList[i][j]"
                                                            [defaultValue]="demandProductOrigin[i][j]"
                                                            formControlName="demandProduct"
                                                            (afterChange)="onDemandProductChange(i, j)">
                                                        </app-select-dropdown>
                                                    </div>
                                                </span>
                                            </td>

                                            <td><input type="number" class="form-control text-field"
                                                    formControlName="scheduledQty"></td>
                                            <td><input type="text" class="form-control text-field"
                                                    formControlName="sapcontract"
                                                    (input)="onContractLineChange($event,j,i,'origin');"></td>
                                            <td>
                                                <div class="statwidth contractLineNo">
                                                    <app-select-dropdown  [action]="action"  [items]="contractLineItemsOrigin[i][j]"
                                                        [defaultValue]="refContractLineItems[i][j]"
                                                        formControlName="contractLineNo"
                                                        (triggerClick)="fetchContractLineItem(i,j,'origin')">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="batchOriginLocation"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="valuationTypeOriginLoc"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="batchDestinationLocation"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="valuationTypeDestinationLoc"></td>
                                            <td *ngIf="nominationService.isNominationScreen !== 'cargo'"><input
                                                    type="checkbox" class="form-control text-field cell-form-checkbox"
                                                    formControlName="confirmationIndicator"></td>
                                            <td>
                                                <div class="custom-dropdown">
                                                    <button class="icon-btn action-icon action-button"></button>
                                                    <div class="dropdown-menu-wrap">
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item action-btn-item"
                                                                (click)="copyOriginRow(i,j)">duplicate row
                                                            </button>
                                                            <button *ngIf="isMultipleRowItemOrigin[i]?.length>1"
                                                                class="dropdown-item action-btn-item text-danger"
                                                                (click)="deleteOriginRow(i,j)">delete
                                                            </button>
                                                            <button
                                                                class="dropdown-item action-btn-item text-act-btn"
                                                                (click)="clearOriginRow(i,j)">clear all fields
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </table>
                            <div class="my-3 d-flex">
                                <button class="button button-text" (keydown.enter) = "addOriginRow(i)" (click)="addOriginRow(i)">
                                    add location
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hideDestination">
                        <div *ngIf="isMultipleTransport || i===0">
                            <span class="table-type">destination</span>
                            <table aria-describedby="destination">
                                <tr>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> description
                                    </th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> rdi</th>
                                    <!-- <th *ngIf="inCpTab || inCpNomTab"> counter party</th> -->



                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled
                                        date</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]"></span> Arrival Time
                                    </th>
                                    <!-- <th *ngIf="inCpTab"><span class="text-danger" *ngIf="isOriginRequired[i]"> </span>
                                        end discharge date</th> -->


                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> location
                                    </th>
                                    <th>tank</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled
                                        product material code</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> scheduled
                                        product</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> demand
                                        product material code</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> demand
                                        product</th>
                                    <th><span class="text-danger" *ngIf="isDestinationRequired[i]">* </span> qty(mb)
                                    </th>
                                    <th>sap contract</th>
                                    <th>contract line no.</th>
                                    <th *ngIf="isAdditionalData">batch origin location</th>
                                    <th *ngIf="isAdditionalData">valuation type origin location</th>
                                    <th *ngIf="isAdditionalData">batch destination location</th>
                                    <th *ngIf="isAdditionalData">valuation type destination location</th>
                                    <th *ngIf="nominationService.isNominationScreen !== 'cargo'">Confirm</th>
                                </tr>
                                <ng-container formArrayName="destination">
                                    <ng-container *ngFor="let list of destination(i).controls; let k = index"
                                        [formGroupName]="k">
                                        <tr>
                                            <td>
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="destinationRDIDescArray"
                                                        formControlName="value"
                                                        [defaultValue]="destinationRDIDescSelected[i][k]"
                                                        (afterChange)="onDestinationDescriptionChange(i, k)">
                                                    </app-select-dropdown>
                                                </div>

                                            </td>
                                            <td>
                                                <div class="rdiWidth">
                                                    <app-select-dropdown [items]="destinationRDIArray"
                                                        formControlName="key"
                                                        [defaultValue]="destinationRDISelected[i][k]"
                                                        (afterChange)="onDestinationRdiChange(i, k)">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <!-- <td *ngIf="inCpTab || inCpNomTab"><input type="text"
                                                    class="form-control text-field" formControlName="counterParty"></td> -->
                                            <td><input class="form-control text-field date-field"
                                                    formControlName="scheduledDate" type="date"
                                                    (input)="onDestinationScheduledDateInput($event, origin(i).controls, destination(i).controls, i, k)">
                                            </td>
                                            <td>{{scheduledTimeDesti[i][k]}}</td>
                                            <!-- <td *ngIf="inCpTab"><input class="form-control text-field date-field"
                                                    formControlName="endLoadDate" type="date"></td> -->
                                            <td>
                                                <span *ngIf="terminalList[i]?.length>0">
                                                    <div class="statwidth">
                                                        <app-select-dropdown [items]="terminalList[i]"
                                                            formControlName="location"
                                                            [defaultValue]="locationDestiArray[i][k]"
                                                            (afterChange)="onLocChangeDest(i, k)">
                                                        </app-select-dropdown>
                                                    </div>

                                                </span>

                                            </td>
                                            <td>
                                                <div class="statwidth"> <app-select-dropdown [items]="tankDestiList[i][k]" formControlName="tankCode"
                                                        [defaultValue]="defaultTankDesti[i][k]" [disabled]="!isTankDestiDisabled[i][k]"></app-select-dropdown>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="statwidth">
                                                    <app-select-dropdown
                                                        [items]="scheduledDemandProductListDescMaterialCode[i][k]"
                                                        formControlName="scheduledProductMaterialCode"
                                                        [defaultValue]="defaultProductdestiMaterialCode[i][k]"
                                                        (afterChange)="onScheduledProductDestiChangeMaterialCode(i, k)">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <td *ngIf="terminalList[i]?.length > 0">
                                                <div class="statwidth">

                                                    <app-select-dropdown [items]="scheduledDemandProductListDesc[i][k]"
                                                        formControlName="scheduledProduct"
                                                        [defaultValue]="defaultProductdesti[i][k]"
                                                        (afterChange)="onScheduledProductDestiChange(i, k)">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <td>
                                                <span *ngIf="demandProductListDesc?.length>0">
                                                    <div class="statwidth">

                                                        <app-select-dropdown
                                                            [items]="demandProductListDescMaterialCode[i][k]"
                                                            [defaultValue]="demandProductDestiMaterialCode[i][k]"
                                                            formControlName="demandProductMaterialCode"
                                                            (afterChange)="onDemandProductDestiChangeMaterialCode(i, k)">
                                                        </app-select-dropdown>
                                                    </div>
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="terminalList[i]?.length > 0 && demandProductListDesc?.length > 0">
                                                    <div class="statwidth">

                                                        <app-select-dropdown [items]="demandProductListDesc[i][k]"
                                                            [defaultValue]="demandProductDesti[i][k]"
                                                            formControlName="demandProduct"
                                                            (afterChange)="onDemandProductDestiChange(i, k)">
                                                        </app-select-dropdown>
                                                    </div>
                                                </span>
                                            </td>
                                            <td><input class="form-control text-field" formControlName="scheduledQty"
                                                     type="number"></td>
                                            <td><input class="form-control text-field" type="text"
                                                    formControlName="sapcontract"
                                                    (input)="onContractLineChange($event,k,i,'destination');"></td>
                                            <td>
                                                <div class="statwidth contractLineNo">
                                                    <app-select-dropdown   [action]="action"  [items]="contractLineItemsDestination[i][k]"
                                                        [defaultValue]="refContractLineItemsDesti[i][k]"
                                                        formControlName="contractLineNo"
                                                        (triggerClick)="fetchContractLineItem(i,k, 'destination')">
                                                    </app-select-dropdown>
                                                </div>
                                            </td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="batchOriginLocation"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="valuationTypeOriginLoc"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="batchDestinationLocation"></td>
                                            <td *ngIf="isAdditionalData"><input type="text"
                                                    class="form-control text-field"
                                                    formControlName="valuationTypeDestinationLoc"></td>
                                            <td *ngIf="nominationService.isNominationScreen !== 'cargo'"><input
                                                    type="checkbox" class="form-control text-field cell-form-checkbox"
                                                    formControlName="confirmationIndicator"></td>
                                            <td>
                                                <div class="custom-dropdown">
                                                    <button class="icon-btn action-icon action-button"></button>
                                                    <div class="dropdown-menu-wrap">
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item action-btn-item"
                                                                (click)="copyDestinationRow(i,k)">duplicate row
                                                            </button>
                                                            <button *ngIf="isMultipleRowItemDestination[i]?.length>1"
                                                                class="dropdown-item action-btn-item text-danger"
                                                                (click)="deletedestinationRow(i,k)">delete
                                                            </button>
                                                            <button
                                                                class="dropdown-item action-btn-item text-act-btn"
                                                                (click)="clearDestinationRow(i,k)">clear all fields
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </table>
                            <div class="my-3">
                                <button class="button button-text"  (keydown.enter) = "addDestinationRow(i)" (click)="addDestinationRow(i)">
                                    add location
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="showMultipleTables && (createPipelineNominationForm.get('transportSystem')?.value === 'ITE'|| createPipelineNominationForm.get('transportSystem')?.value === 'ITW')">
                    <div *ngIf="hideDestination || i===0">
                        <table aria-describedby="ite">
                            <thead>
                                <tr>
                                    <th><span class="text-danger">* </span> description</th>
                                    <th><span class="text-danger">* </span> rdi</th>
                                    <th><span class="text-danger">* </span> scheduled date</th>
                                    <th>Arrival Time</th>
                                    <th><span class="text-danger">* </span> location</th>
                                    <th><span class="text-danger">* </span> scheduled product material code</th>
                                    <th><span class="text-danger">* </span> scheduled product</th>
                                    <th><span class="text-danger">* </span> demand product material code</th>
                                    <th><span class="text-danger">* </span> demand product</th>

                                    <th><span class="text-danger">* </span> qty(mb)</th>
                                    <th>sap contract</th>
                                    <th>contract line no.</th>
                                    <th *ngIf="isAdditionalData">batch origin location</th>
                                    <th *ngIf="isAdditionalData">valuation type origin location</th>
                                    <th *ngIf="isAdditionalData">batch destination location</th>
                                    <th *ngIf="isAdditionalData">valuation type destination location</th>
                                    <th *ngIf="nominationService.isNominationScreen !== 'cargo'">Confirm</th>
                                </tr>
                            </thead>
                            <ng-container formArrayName="ite">
                                <ng-container *ngFor="let list of ite(i).controls; let j = index" [formGroupName]="j">
                                    <tr>
                                        <td>
                                            <div class="statwidth">
                                                <app-select-dropdown [items]="iteRDIDescArray"
                                                    formControlName="descPort"
                                                    [defaultValue]="inTankRDIDescSelected[i][j]"
                                                    (afterChange)="onDescIteTable(i, j)">
                                                </app-select-dropdown>
                                            </div>
                                        </td>


                                        <td>
                                            <div class="rdiWidth">
                                                <app-select-dropdown [items]="iteRDIArray" formControlName="rdiPort"
                                                    [defaultValue]="inTankRDISelected[i][j]"
                                                    (afterChange)="onRdiItetable(i, j)">
                                                </app-select-dropdown>
                                            </div>
                                        </td>
                                        <td><input class="form-control text-field date-field"
                                                formControlName="scheduledDate" type="date"
                                                (input)="onDestinationScheduledDateInput($event, [], ite(i).controls, i, j)">
                                        </td>
                                        <td>{{scheduledTime[i][j]}}</td>
                                        <td>
                                            <span *ngIf="terminalList[i]?.length>0">
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="terminalList[i]"
                                                        formControlName="location"
                                                        [defaultValue]="locationIteArray[i][j]"
                                                        (afterChange)="onLocChangeIte(i, j)">
                                                    </app-select-dropdown>
                                                </div>
                                            </span>

                                        </td>
                                        <td>
                                            <div class="statwidth">
                                                <app-select-dropdown
                                                    [items]="scheduledDemandProductListMaterialCode[i][j]"
                                                    formControlName="scheduledProductMaterialCode"
                                                    [defaultValue]="defaultProductMaterialCode[i][j]"
                                                    (afterChange)="onScheduledProductChangeMaterialCode(i, j, 'ite')">
                                                </app-select-dropdown>
                                            </div>

                                        </td>
                                        <td
                                            *ngIf="terminalList[i]?.length > 0 && scheduledDemandProductList[i]?.length > 0">
                                            <div class="statwidth">
                                                <app-select-dropdown [items]="scheduledDemandProductList[i][j]"
                                                    formControlName="scheduledProduct"
                                                    [defaultValue]="defaultProduct[i][j]"
                                                    (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChange(i, j)">
                                                </app-select-dropdown>
                                            </div>

                                        </td>
                                        <td>
                                            <span *ngIf="demandProductList?.length>0">
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="demandProductListMaterialCode[i][j]"
                                                        [defaultValue]="demandProductIteMaterialCode[i][j]"
                                                        formControlName="demandProductMaterialCode"
                                                        (afterChange)="onDemandProductChangeMaterialCode(i, j, 'ite')">
                                                    </app-select-dropdown>
                                                </div>
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="terminalList[i]?.length > 0 && demandProductList?.length > 0">
                                                <div class="statwidth">
                                                    <app-select-dropdown [items]="demandProductList[i][j]"
                                                        [defaultValue]="demandProductIte[i][j]"
                                                        formControlName="demandProduct"
                                                        (afterChange)="onDemandProductChange(i, j)">
                                                    </app-select-dropdown>
                                                </div>
                                            </span>
                                        </td>
                                        <td><input class="form-control text-field" formControlName="scheduledQty"
                                                type="number"></td>
                                        <td><input type="text" class="form-control text-field"
                                                formControlName="sapcontract"
                                                (input)="onContractLineChange($event,j,i,'ite');"></td>
                                        <td>
                                            <div class="statwidth contractLineNo">
                                                <app-select-dropdown  [action]="action"  [items]=" contractLineItemsITE[i][j]"
                                                    [defaultValue]="refContractLineItemsIte[i][j]"
                                                    formControlName="contractLineNo"
                                                    (triggerClick)="fetchContractLineItem(i,j,'ite')">
                                                </app-select-dropdown>
                                            </div>
                                        </td>

                                        <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                                                formControlName="batchOriginLocation"></td>
                                        <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                                                formControlName="valuationTypeOriginLoc"></td>
                                        <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                                                formControlName="batchDestinationLocation"></td>
                                        <td *ngIf="isAdditionalData"><input type="text" class="form-control text-field"
                                                formControlName="valuationTypeDestinationLoc"></td>
                                        <td *ngIf="nominationService.isNominationScreen !== 'cargo'"><input
                                                type="checkbox" class="form-control text-field cell-form-checkbox"
                                                formControlName="confirmationIndicator"></td>
                                        <td>
                                            <div class="custom-dropdown">
                                                <button class="icon-btn action-icon action-button"></button>
                                                <div class="dropdown-menu-wrap">
                                                    <div class="dropdown-menu">
                                                        <button class="dropdown-item action-btn-item"
                                                            (click)="copyIteRow(i,j)">duplicate row
                                                        </button>
                                                        <button *ngIf="isMultipleRowItemIte[i]?.length>1"
                                                            class="dropdown-item action-btn-item text-danger"
                                                            (click)="deleteIteRow(i,j)">delete
                                                        </button>
                                                        <button
                                                        class="dropdown-item action-btn-item text-act-btn"
                                                        (click)="clearIteRow(i,j)">clear all fields
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </table>
                        <div class="my-3">
                            <button class="button button-text" (click)="addIteRow(i)">
                                add location
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-danger mb-5"
                    *ngIf="errorMessage[i] && isOriginRequired[i] && isDestinationRequired[i]">
                    <span>
                        Origin Total Volume = {{originQtyValue[i]}}; Destination Total Volume = {{destinQtyValue[i]}}
                    </span>
                </div> -->


            </ng-container>
        </ng-container>

        <div class="form-row pb-1 pr-2">
            <div class="form-group col-md-6"
                [ngClass]="['col-md-4']">
                <button class="button" tabindex="0" (keydown.enter)="closePopup(true)" (click)="closePopup(true)">cancel</button>
            </div>
            <!-- <div class="form-group col-md-6" *ngIf="isCargoDesks && inCpTab">
                <button class="button" tabindex="0"  (keydown.enter)="submitForm()"  (click)="submitForm() "
                    [disabled]="!createPipelineNominationForm.valid">{{this.trNominationForPipeline?.editNomination?
                    "update" : "create"}}</button>
            </div> -->
            <div class="form-group col-md-6">
                <button class="button" tabindex="0" (keydown.enter)="submitUpdatePublish()" (click)="submitUpdatePublish()"
                    [disabled]="!createPipelineNominationForm.valid">{{this.trNominationForPipeline?.editNomination?
                    "update + publish" : "create + publish"}}</button>
            </div>
        </div>
    </form>
</div>