import { EthanolTruckService } from './../../../services/ethanol-truck.service';
import { takeUntil, Subject } from 'rxjs';
import { selectEthanolTRFiltersResponse, getUserDetails, selectSwitchView, selectDesk, selectedPipelineCompactView } from './../../store/selector/app.selector';
import { DatePipe } from '@angular/common';
import { NominationService } from './../../../services/nomination.service';
import { ToastService } from './../../../services/toast.service';
import { Router } from '@angular/router';
import { Utilities } from './../../utilities/utilities';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, ICellRendererParams } from 'ag-grid-community';
import { select, Store } from '@ngrx/store';
import { saveEthanolNomFiltersResponse } from '../../store/action/app.action';
import { UDMDesk, deskName } from '../../constants/terminal-replenishment.constant';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { MdmService } from 'src/app/services/mdm.service';
import { trNominationList } from 'src/app/shared/store/selector/app.selector';
import { invokeTrNominationList } from '../../store/action/app.action';
import { concatMap, filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-ethanol-more-menu',
  templateUrl: './ethanol-more-menu.component.html',
  styleUrls: ['./ethanol-more-menu.component.scss']
})
export class EthanolMoreMenuComponent implements ICellRendererAngularComp {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isOpen: any = false;
  public rowIndex: any;
  public value!: any;
  public nominationTab: any;
  public isEditable = false;
  public moremenuIconShow = false;
  public lineItem = false;
  public isDraft = false;
  public rowStatus: any;
  public params: any
  public createdBy = '';
  ethonalSwitch: any;
  getEhtanolFilterResponse: any = [];
  public filterList: any = {}
  terminalOweneshipValue: any;
  userEmail:any;
  userDetails$ = this.store.pipe(select(getUserDetails));
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
  selectSwitchView$ = this.store.pipe(select(selectSwitchView));
  selectedPipelineCompactView$ = this.store.pipe(select(selectedPipelineCompactView))
  trNominationList$ = this.store.pipe(select(trNominationList));
  desk: string = '';
  nominations = [];
  compactView: boolean = false;
  isNomPresent = false;
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName : any = '';
  selectedDeskOption: any;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  nominationItemStatus: any;
  selectedDeskParams: any;
  transLoadSplitConditionCheckUSEC: any;
  transLoadSplitConditionCheckUSWC: any;
  isTransloadColumn: any;
  gridColumnApi2!: ColumnApi;
  constructor(
    private utilities: Utilities,
    private router: Router,
    private toasterService: ToastService,
    private nominationService: NominationService,
    private datePipe: DatePipe,
    private store: Store,
    private ethanolTruckService: EthanolTruckService,
    private myMonitoringService:MyMonitoringService,
    private data: NominationService,
    private mdmService : MdmService
  ) { }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params
    this.gridColumnApi2 = params.columnApi;
    if(params?.colDef?.headerName?.toLowerCase() === 'comments'){
      this.value =params?.value
    }else{
      this.value = this.utilities.formatNumber(params?.value);
    }
    this.rowStatus = params.data[params?.colDef?.field + '_itemStatus']
    this.nominationItemStatus = params.data[params?.colDef?.field + '_nominationItemStatus']
    const tswNomStatus1Or4 = this.nominationItemStatus === '1' || this.nominationItemStatus=== '4';
    this.isTransloadColumn = this.params?.colDef?.field?.toLowerCase().includes('transload');
    this.transLoadSplitConditionCheckUSEC = (params?.colDef?.field?.includes('MOBTRANS_Receipts_rail') && tswNomStatus1Or4) || this.isTransloadColumn; // as mobtrans wil be only for usec not adding additional desk condition
    this.transLoadSplitConditionCheckUSWC = (params?.colDef?.field?.includes('OGDEN_Receipts_rail') && tswNomStatus1Or4) || (params?.colDef?.field?.includes('TOOELE_Receipts_rail') && tswNomStatus1Or4) || this.isTransloadColumn; // as OGDEN, TOOELE wil be only for usec not adding additional desk condition
    this.isDraft = this.rowStatus === 'draft';
    this.nominationTab = this.router.url === '/nominations';
    this.isEditable = params.data[params?.colDef?.field + '_isEditable']
    this.createdBy = params.data[params?.colDef?.field + '_createdBy']
    this.rowIndex = params.node.rowIndex;

    this.selectedPipelineCompactView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.pipelineCompactView) {
            this.compactView = true
          }
          else {
            this.compactView = false
          }
        },
        error: (err: any) => {
        },
        complete: () => { },
      });
    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.getEhtanolFilterResponse = response
        response?.ethanolFilterValues?.forEach((element: any) => {
          this.filterList[element.headerName] = element.list
        });
        let terminalOweneshipList = this.getEhtanolFilterResponse?.ethanolFilterValues?.filter((ele: any) => ele.headerName === 'terminal ownership')[0]
        this.terminalOweneshipValue = terminalOweneshipList?.list.filter((ele: any) => ele.checked)[0]
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }

    })

    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

      this.selectSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.ethonalSwitch = response.switchView
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.SelectedDesk = response.selectedDesk;
          this.selectedDeskOption = response.selectedDesk;
          this.selectedDeskParams = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.SelectedDesk.split('+')[this.SelectedDesk.split('+').length -1];
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      if (this.SelectedDesk) {
        const deskOj = { deskValue: this.SelectedDesk, deskParameter: this.SelectedDeskParam, id: this.SelectedDeskId  };
      } else {
        this.SelectedDesk = "Select one";
      }
      this.moremenuIconShow = this.isMoreMenuVisible(this.params)
  }

  moremwnu() {
    this.isOpen = !this.isOpen;
  }

  isMoreMenuVisible(params: any){
    let isStatusPresent = ['unpublished','published','draft', 'failed'].includes(this.rowStatus?.toLowerCase());
    let currentView = this.ethonalSwitch?.split('_')[0]?.toLowerCase() === 'terminal';
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let currentMOT = this.params.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase()
    let isSalesEdited = this.params.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase() === 'sales';
    let isNomActualColumn = ["nomactual"].includes(params?.colDef?.colId?.toLowerCase()) || isSalesEdited;
    if(currentMOT === 'truck'){
      this.isNomPresent = isStatusPresent && currentView && isNomActualColumn;
      return  isStatusPresent && (new Date(params?.data?.date).getTime() >= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) && currentView && isNomActualColumn
    }else if(this.params?.colDef?.field?.toLowerCase().includes('transload')){
      return this.params?.data[this.params?.colDef?.colId + '_transloadTRData']?.length > 0;
    }else{
      this.isNomPresent = isStatusPresent && currentView;
      return  isStatusPresent && this.nominationItemStatus !== 5 && currentView;
    }
  }

  onCreateDraftNomination() {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

      this.nominationService.isModalPopupOpen$.next(false);
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let isSalesEdited = this.params.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase() === 'sales'
    let currentMOT = this.params.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase()
    // currentMOT = isSalesEdited ? this.params.data[this.params.colDef.field + '_modeOfTransport']?.toLowerCase() : currentMOT?.toLowerCase();
    let motSupplier = this.params.colDef.field.split('_')[IsMultiTerminal ? 4 : 3]
    // let supplier = isSalesEdited ? this.params.data[this.params.colDef.field + '_supplier'] : motSupplier;
    // let supplier = motSupplier;
    let supplierName = this.getDetails('supplier', 'id', 'name', motSupplier);
    let heirchyData = this.params.colDef.field.split('_');
    let valuationType = heirchyData[1].toLowerCase() === 'chevron' ? 'CHEVRONTEX' : heirchyData[1].toUpperCase();

    let MOTAttributes: any;

    switch (currentMOT) {
      case 'truck':
        MOTAttributes = {
          modeOfTransportDesc: "Truck",
          modeOfTransport: "J1",
          transportSystem: "TRUCK-US",
          tdVehNo: "TRUCK-US",
          scheduledUom: "BR",
        }
        break;
      case 'rail':
        MOTAttributes = {
          modeOfTransportDesc: "Rail",
          modeOfTransport: "R_",
          transportSystem: "RAIL-US",
          tdVehNo: "RAIL-US",
          scheduledUom: "BR",
        }
        break;
      case 'barge':
        MOTAttributes = {
          modeOfTransportDesc: "Barge",
          modeOfTransport: "S",
          transportSystem: "CA-N",
          tdVehNo: "CA-N",
          scheduledUom: "BR",
        }
        break;
      case 'pipeline':
        MOTAttributes = {
          modeOfTransportDesc: "Pipeline",
          modeOfTransport: "PL",
          transportSystem: this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : "ITE",
          tdVehNo: this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : null,
          scheduledUom: "BR",
        }
        break;

      default:
        break;
    }


    const desk = `&desk=${(this.selectedDeskOption === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.EthanolUswcVal}`;

    this.getNominationByNominationNumberApiCall(desk, MOTAttributes, IsMultiTerminal, supplierName, motSupplier, isSalesEdited, valuationType);

  }
  getNominationByNominationNumberApiCall(desk: any,MOTAttributes :any, IsMultiTerminal: any,supplierName: any,supplier: any, isSalesEdited:any,valuationType: any){
    let nominationDetails: any;
    let masterDataPayload: any = {};
    this.utilities.getMasterDataForNominationDetailsPayload(masterDataPayload);
    this.nominationService.getNominationByNominationNumberPostCall(this.params.data[this.params.colDef.field + '_id'], desk, masterDataPayload).subscribe((data) => {
      nominationDetails = data.result;
      nominationDetails.forEach((element: any) => {
        element.scheduledDate = this.dateCellRenderer(element.scheduledDate); // convert yyyymmdd to mm/dd/yyyy
        element.scheduledQty = element.scheduledQty / 1000;
      });
      MOTAttributes.transportSystem =  nominationDetails[0]?.transportSystem;
      MOTAttributes.tdVehNo = nominationDetails[0]?.tdVehNo;
      let itemNomtype = this.params.data[this.params.colDef.field + '_nomType'] ? this.params.data[this.params.colDef.field + '_nomType'] : "FOB";
      let updateNominationPayload = this.updatePayload(IsMultiTerminal, supplierName, supplier, itemNomtype, isSalesEdited, valuationType, nominationDetails);
      updateNominationPayload.nominationNo = data.result.length ?  data.result[0].nominationNumber : '';
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'onCreateEthanolNominationFromTR',
        type: '',
        values: { ...updateNominationPayload, ...MOTAttributes }
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    });
  }

  getTswInfo(nominationDetails:any,itemNomtype:any){
    let tswNominationType;
      if(itemNomtype.toLowerCase() === 'supplier direct delivered' || itemNomtype.toLowerCase() === 'sale'){
         tswNominationType = `${nominationDetails[0]?.scheduleType}${nominationDetails[0]?.referenceDocumentIndicator}`;
      }
      else if(itemNomtype.toLowerCase() === 'fob'){
        let oArr = nominationDetails.filter((nom: { scheduleType: string; }) =>{ return  nom.scheduleType === 'O'});
        let dArr =  nominationDetails.filter((nom: { scheduleType: string; }) =>{ return nom.scheduleType === 'D'});
        tswNominationType = `${oArr[0]?.scheduleType}${oArr[0]?.referenceDocumentIndicator}+${dArr[0]?.scheduleType}${dArr[0]?.referenceDocumentIndicator}`;
      }
    return tswNominationType;
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  updatePayload(IsMultiTerminal:any, supplierName:any,supplier:any,itemNomtype:any,isSalesEdited:any,valuationType:any, nominationDetails: any){
   let updateScheduledMaterial = this.params.data[this.params.colDef.field + '_scheduledMaterial'] ? this.params.data[this.params.colDef.field + '_scheduledMaterial'] : "296669990";
   let updateDemandMaterial = this.params.data[this.params.colDef.field + '_demandMaterial'] ? this.params.data[this.params.colDef.field + '_demandMaterial'] : "296669990";
   let tswNominationType = this.getTswInfo(nominationDetails,itemNomtype);
    let carrierDetails = this.params.colDef.field.split('_')[IsMultiTerminal ? 5 : 4];
    nominationDetails.forEach((element: any) => {
      element.scheduledDate = `${new Date(element.scheduledDate).getMonth() + 1}/${new Date(element.scheduledDate).getDate()}/${new Date(element.scheduledDate).getFullYear()}`
    });
    let nomination = {
      screenName: 'ethanoltr',
      id: this.params.data[this.params.colDef.field + '_id'],
      itemId: this.params.data[this.params.colDef.field + '_itemId'],
      tempTRKey: null,
      nominationNo: nominationDetails[0].nominationNumber,
      nominationKey: null,
      product: "ETHANOL",
      scheduledMaterial:  this.params.data[this.params.colDef.field + '_status'].toLowerCase() !== "draft" ? updateScheduledMaterial : undefined,
      demandMaterial:  this.params.data[this.params.colDef.field + '_status'].toLowerCase() !== "draft" ? updateDemandMaterial : undefined,
      scheduledQty: this.params.data[this.params.colDef.field + '_actualNomination'],
      locationId: this.params.colDef.field.split('_')[0].replace("-", " "),
      locationName: this.getDetails('terminal', 'id', 'name', this.params.colDef.field.split('_')[0].replace("-", " ")),
      carrierName: carrierDetails ? this.getDetails('carrier', 'id', 'name', carrierDetails) : nominationDetails[0]?.carrier,
      carrier: carrierDetails ? carrierDetails : this.getDetails('carrier', 'name', 'id', nominationDetails[0]?.carrier),
      supplierName: supplierName ? supplierName : nominationDetails[0]?.supllier,
      supplier: supplier ? supplier : this.getDetails('supllier', 'name', 'id', nominationDetails[0]?.supllier),
      sys_status: this.params.data[this.params.colDef.field + '_status'],
      rowItemStatus: this.params.data[this.params?.colDef?.field + '_itemStatus'],
      scheduledDate: this.datePipe.transform(this.params.data.date, 'MM-dd-yyy')?.split('-').join('/'),
      scenario : itemNomtype?.toLowerCase(),
      createdBy: this.createdBy,
      isSalesNom: isSalesEdited,
      headerToItemNav: nominationDetails,
      trMoreMenu: true,
      isSalesCol: this.params.colDef.field.split('_')[1].toLowerCase() === 'sales',
      tswNominationType:tswNominationType,
      scheduleType: this.params.data[this.params?.colDef?.field + '_scheduleType'],
      referenceDocumentIndicator :this.params.data[this.params?.colDef?.field + '_referenceDocumentIndicator'],
      valuationTypeDestinationLoc : IsMultiTerminal ? valuationType : undefined,
      customerName: nominationDetails[0].customer === 'null' ? null : nominationDetails[0].customer,
      udmTimeFrameOption : nominationDetails[0].udmTimeFrameOption
    };

    return nomination
  }

  seeInNominationPannel() {
    this.nominationService.sidePanel$.next(true);
    this.nominationService.ethanolSidePanelData$.next(this.params)
  }

  getDetails(type: any, dataKey: any, requiredKey: any, val: any) {
    let currentData = this.filterList[type]?.filter((ele: any) => ele[dataKey] === val && ele.name.toLowerCase() !== 'all')
    return currentData?.length > 0 ? currentData[0][requiredKey] : '';
  }

  delete(){
    let action = 'delete';
    this.data.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true);
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
      if(actionConfirmed){
        if(action !== ''){
          this.deleteAfterConfirm();
          this.mdmService.actionConfirm(false);
          this.mdmService.invokeReplenishmentToggleMethod(false);
          action = '';
        }

      }
    });
  }

  deleteAfterConfirm() { // delete from tr screen grid
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    let modeOfTransportDesc = '';

    let payload = [
      {
        id: this.params.data[this.params.colDef.field + '_id'],
        modeOfTransportDesc: modeOfTransportDesc,
        deskFilter: this.selectedDeskParams,
        sys_status: this.params.data[this.params.colDef.field + '_status'],
        headerToItemNav: [{
          itemId: this.params.data[this.params.colDef.field + '_itemId'],
          udmItemStatus: this.params.data[this.params.colDef.field + '_itemStatus'],
          deletionIndicator: "X",
          textLine: `{Author:${this.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        }]
      }]
    this.utilities.getCreateUpdateMasterDataPayload(payload);
    this.ethanolTruckService.saveEthanolNominationData(payload).subscribe({
      next: (response) => {
        this.myMonitoringService.logEvent(`Deleted ethanol nomination`, {category:"Replenishment Planning"})
        this.toasterService.setToastNotification({
          show: true,
          type: 'success',
          msg: "Nomination has been deleted",
        });
      },
      error: (err) => {
        this.toasterService.setToastNotification({ show: true, type: 'error', msg: `Request coudn't get completed, try again in sometime.` });
      },
      complete: () => {
        const selectedTabFunction = {
          tab: this.router.url,
          function: 'deleteEthanolNomination',
          type: '',
        }
        const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
        this.utilities.addUnsubscribableList(modalPopupAction);
      },
    });
  }


  goToNominations() {
    // this set of code is for the transfer of desk
    let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.selectedDeskParams , id: this.SelectedDeskId };
    if(!this.SelectedDesk && !this.SelectedDeskParam){
      deskOj = this.SelectedDeskName;
    }
    localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
    // this set of code is for the transfer of nomination
    const fieldType = this.params?.colDef?.field;
    let fieldDataName =  fieldType + "_id";
    localStorage.setItem('nomId', this.params.data[fieldDataName]);
    //this set of code is for the transfer of filter
    this.nominationService.restrictNomApiGetCall$.next(true);
    localStorage.setItem('gEFRRP',JSON.stringify(this.getEhtanolFilterResponse));
    window.open("/nominations");
    this.nominationService.goToNominationData$.next({isTrue:true,  data:this.params});
  }


  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  editNomination() {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let isSalesEdited = this.params.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase() === 'sales'
    let currentMOT = this.params.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase()
    let motSupplier = this.params.colDef.field.split('_')[IsMultiTerminal ? 4 : 3]
    let supplierName = this.getDetails('supplier', 'id', 'name', motSupplier);
    let heirchyData = this.params.colDef.field.split('_');
    let valuationType = heirchyData[1].toLowerCase() === 'chevron' ? 'CHEVRONTEX' : heirchyData[1].toUpperCase();
    let MOTAttributes: any;

    switch (currentMOT) {
      case 'truck':
        MOTAttributes = {
          modeOfTransportDesc: "Truck",
          modeOfTransport: "J1",
          transportSystem: "TRUCK-US",
          tdVehNo: "TRUCK-US",
          scheduledUom: "BR",
        }
        break;
      case 'rail':
        MOTAttributes = {
          modeOfTransportDesc: "Rail",
          modeOfTransport: "R_",
          transportSystem: "RAIL-US",
          tdVehNo: "RAIL-US",
          scheduledUom: "BR",
        }
        break;
      case 'barge':
        MOTAttributes = {
          modeOfTransportDesc: "Barge",
          modeOfTransport: "S",
          transportSystem: "CA-N",
          tdVehNo: "CA-N",
          scheduledUom: "BR",
        }
        break;
      case 'pipeline':
        MOTAttributes = {
          modeOfTransportDesc: "Pipeline",
          modeOfTransport: "PL",
          transportSystem: this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : "ITE",
          tdVehNo: this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : null,
          scheduledUom: "BR",
        }
        break;

      default:
        break;
    }

    const desk = `&desk=${(this.selectedDeskOption === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.EthanolUswcVal}`;
    let nominationDetails: any;
    this.nominationService.getNominationByNominationNumber(this.params.data[this.params.colDef.field + '_id'], desk ).subscribe((data) => {
      nominationDetails = data.result;
      nominationDetails.forEach((element: any) => {
        element.scheduledDate = this.dateCellRenderer(element.scheduledDate); // convert yyyymmdd to mm/dd/yyyy
        element.scheduledQty = element.scheduledQty / 1000;
      });
      MOTAttributes.transportSystem =  nominationDetails[0]?.transportSystem;
      MOTAttributes.tdVehNo = nominationDetails[0]?.tdVehNo;
      let itemNomtype = this.params.data[this.params.colDef.field + '_nomType'] ? this.params.data[this.params.colDef.field + '_nomType'] : "FOB";
      let updateNominationPayload = this.updatePayload(IsMultiTerminal, supplierName, motSupplier, itemNomtype, isSalesEdited, valuationType, nominationDetails);
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'onCreateEthanolNominationFromTR',
        type: 'editNomination',
        values: { ...updateNominationPayload, ...MOTAttributes }
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);

  });
}

  filterResponse(response: any, filter: any) {
    let data = response.ethanolFilterValues.find(
      (value: any) =>
        value?.headerName?.toLowerCase() === filter
    )?.list;

    data = data?.length ? data.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.id) : [];
    return data;
  }

  filterMOTResponse(response: any, filter: any) {
    let mot: any = [];
    let data = response?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === filter);

    if (data?.length > 0) {
      data[0]?.list?.forEach((dt: any) => {
        if (dt?.checked) {
          if (dt?.name === "tank") {
            mot.push("pipeline");
          } else {
            mot.push(dt?.name);
          }
        }
      })
    } else {
      mot = [];
    }
    return mot;
  }

  transloadSplit() {
    let matchingNominations: any[] = [];
    const seenNominations = new Set<string>();

    // Subscribe to ethanol filter response and process nominations
    this.selectEthanolTRFiltersResponse$.pipe(
      take(1),
      takeUntil(this.destroy$),
      concatMap((response: any) => {

      // Prepare payload for fetching nominations
      const payload = {
        desk: (this.desk.toLowerCase() === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.Ethanol,
        modeOfTransport: this.filterMOTResponse(response, 'mode of transport'),
        fromDate: response.ethanolFromDate,
        toDate: response.ehtanolToDate,
        terminal: this.filterResponse(response, 'terminal'),
        carrier: this.filterResponse(response, 'carrier'),
        supplier: this.filterResponse(response, 'supplier')
      };

      // Dispatch action to get nominations
      this.store.dispatch(invokeTrNominationList({ payload }));

      // Wait for nominations to be available
      return this.trNominationList$.pipe(
        filter((nominations: any[]) => nominations && nominations.length > 0),
        take(1),
        takeUntil(this.destroy$)
      );

      })
    ).subscribe({
      next: (nominations: any) => {
      this.nominations = nominations;
      const rowDate = new Date(this.params.data.date);
      const selectedRowDate = this.datePipe.transform(rowDate, 'yyyyMMdd');

      // Filter nominations matching the selected row date
      this.nominations.forEach((nom: any) => {
        nom.headerToItemNav.forEach((item: any) => {
        if (item.scheduledDate === selectedRowDate && !seenNominations.has(nom.nominationNo)) {
          seenNominations.add(nom.nominationNo);
          matchingNominations.push(nom);
        }
        });
      });

      // Prepare payload for transload split
      const payload = matchingNominations.map((nom: any) => ({
        'id': nom.id,
        "headerToItemNav": nom.headerToItemNav
        .filter((item: any) => item.scheduledDate === selectedRowDate)
        .map((item: any) => ({
          "itemId": item.itemId
        }))
      }));

      // Fetch transload split data and open modal
      if (payload.length > 0) {
        this.nominationService.getTransloadSplitById(payload).subscribe((data) => {
        this.openSplitModal(payload, data);
        });
      } else {
        this.openSplitModal();
      }
      }
    });
  }

  openSplitModal(payload?: any, data?: any, ){

      const selectedTabFunction = {
        tab: this.router.url,
        function: 'ontransloadSplitFromTR',
        type: '',
        values: { params: this.params, transloadSplitData: data?.result, payload: payload }
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
  }

}
