import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { MdmService } from 'src/app/services/mdm.service';
import { NominationService } from 'src/app/services/nomination.service';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { ToastService } from 'src/app/services/toast.service';
import { Terminal, ApprovalStatus, DeskNames, RegionTerminal, NomStatusList, Supplier, McatStatus, Vessel } from 'src/app/shared/constants/cca-dummy.constatnt';
import { Utilities } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'auto-complete',
  encapsulation: ViewEncapsulation.None,
  template: `
    <input
      #input
      id="inputEvent"
      [style.width]="gridWidth + 'px'"
      (ngModelChange)="processDataInput($event)"
      (input)="onFilterTextBoxChanged()"
    />
    <ag-grid-angular
      [style.height]="gridHeight + 'px'"
      [style.width]="gridWidth + 'px'"
      class="ag-theme-alpine ag-theme-nglo"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [rowSelection]="'single'"
      (gridReady)="onGridReady($event)"
      [headerHeight]="headerHeight"
      (rowClicked)="rowClicked($event)"
    >
    </ag-grid-angular>
  `
})
export class AutoCompleteComponent
  implements ICellEditorAngularComp, AfterViewInit {
  // variables for agGrid
  public params: any;
  public gridApi: any;
  public rowData: any;
  public columnDefs!: [{}];
  public rowSelection!: string;
  public columnFilter: any;
  // variables for component
  public returnObject!: boolean;
  public cellValue!: string;
  public filteredRowData: any;
  public inputValue!: string;
  public apiEndpoint!: string;
  public gridHeight: number = 175;
  public gridWidth: number = 180;
  public useApi!: boolean;
  public propertyName!: string;
  public isCanceled: boolean = true;
  public selectedObject: any = {};
  public headerHeight: number = 0;
  private cellFocusIndex!: number;
  public contractNo!: any;
  public materialCode: any[] = [
    {
      name: "ETHANOL-FUEL GRADE W/ RIN",
      value: "296669990",
    },
    {
      name: "ETHANOL-FUEL GRADE",
      value: "296660990",
    }
  ];

  @ViewChild('input', { static: false }) input!: ElementRef;
  showedColumn: any;
  desk: any = localStorage.getItem("desk");

  // will be selected if there is a default value
  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this.inputValue === this.cellValue) {
        this.input.nativeElement.select();
      } else {
        this.input.nativeElement.focus();
      }
    });
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('inputEvent') as HTMLInputElement).value
    );
  }
  constructor(private nominationService: NominationService, private replenishmentPlanningService: ReplenishmentPlanningService, private cpService: CargoPlanningService, private toasterService: ToastService, private mdmService: MdmService, private utilities: Utilities) {
  }

  // ICellEditorAngularComp functions
  agInit(params: any): void {
    if (Object?.keys(params?.rowData) && params?.rowData?.column === 'contractLineItem') {
      this.populateLineItems(params);
    }

    if (Object?.keys(params?.rowData)?.length && params?.rowData?.column === "sapcontract") {
      const scheduleDate = params.data.scheduledDate.replace(/(..).(..).(....)/, "$3$1$2"); // converts mm/dd/yyyy to yyyymmdd
      this.nominationService.getSapContracts(params.data.locationId, [this.materialCode.find(el => el.name === params?.data?.scheduledMaterialName)?.value], scheduleDate).subscribe((response) => {

        let sapContactDetailsFormation: any = {};
        response.result.forEach((element: { contractNumber: string | number; contractLineNumber: any; }) => {
          if (sapContactDetailsFormation[element.contractNumber]) {
            sapContactDetailsFormation[element.contractNumber].push(element.contractLineNumber);
          }
          else {
            sapContactDetailsFormation[element.contractNumber] = [];
            sapContactDetailsFormation[element.contractNumber].push(element.contractLineNumber);
          }
        })
        const contractDetails = Object.entries(sapContactDetailsFormation).map(([contarctNo, contractLine]) => ({ contractNumber: contarctNo, contractLineNumber: contractLine }))
        params.rowData = contractDetails;
        this.detail(params);
      })
    }
    else {
      this.detail(params);
    }

    if(['Panama','ECCA','WCCA'].includes(params.data?.udmItineraryPlanName || params.data?.planName)){
      this.getCargoPlanCCaDropdowns(params)
    }else{
      this.whenVesselOrVehicleName(params);
    }
    
    
   

  }

  getRegionData(params: any) {
    let regionData;
      regionData = this.mdmService.getDeskPortTerminal(this.mdmService?.pavisResponse, params?.data?.udmItineraryPlanName, params?.data?.port, params?.data?.terminal);
    return regionData;
  }

  getCargoPlanCCaDropdowns(params:any){
  if(params?.column?.colId === "ccaStatus"){
    params.rowData = ApprovalStatus.map((data: any) => { return { name: data } });
    this.detail(params);
  }
  if(params?.column?.colId === "nomStatus"){
    params.rowData = NomStatusList.map((data: any) => { return { name: data } });
    this.detail(params);
  }
  if(params?.column?.colId === "udmItineraryPlanName"){
    // let regionData = this.mdmService.getDeskPortTerminal(params?.data?.udmItineraryPlanName, params?.data?.port, params?.data?.terminal);
    let regionData = this.getRegionData(params);
    params.rowData = regionData.deskArray;
    this.detail(params);
  }
  if(params?.column?.colId === "terminal"){
    // let regionData = this.mdmService.getDeskPortTerminal(params?.data?.udmItineraryPlanName, params?.data?.port, params?.data?.terminal);
    let regionData = this.getRegionData(params);
    params.rowData = regionData.terminalArray;
    this.detail(params);
  }
  if(params?.column?.colId === "port"){
    // let regionData = this.mdmService.getDeskPortTerminal(params?.data?.udmItineraryPlanName, params?.data?.port, params?.data?.terminal);
    let regionData = this.getRegionData(params);
    params.rowData = regionData.portArray;
    this.detail(params);
  }
  if(params?.column?.colId === "supplierReceiver"){
    params.rowData = this.utilities.getSupplierList(Supplier)
    this.detail(params);
  }
  if(params?.column?.colId === "mcatStatus"){
    params.rowData = McatStatus.map((data: any) => { return { name: data } });
    this.detail(params);
  }
  if(params?.column?.colId === "vesselName"){
    params.rowData = Vessel.includes(params.value) ? 
      Vessel.map((data: any) => { return { name: data } }) :
      [...Vessel.map((data: any) => { return { name: data } }), {name: params.value}];
    this.detail(params);
  }
  
}

  whenVesselOrVehicleName(params: any){
    if (params?.column?.colId === "vesselName" || params?.column?.colId === "vehicleName") {
        this.mdmService.getVehicles((params?.data?.mot || params?.data?.modeOfTransport)?.toLowerCase() === 'barge' ? 'B' : 'S').subscribe({
        next: (response: any) => {
          if (response?.success && response?.statusCode === 200) {
            params.rowData = response?.value?.value?.map((data: any) => { return { name: data?.vehicleName, id: data?.vehicle } });
            this.cpService?.vesselNameOrVehicleName?.next(params.rowData);
            this.detail(params);
          }
        }
      });
    } else {
      this.whenLocationName(params);
    }
  }

  /**Adding this method for future use in whenLocationName method payload */
  getUpdatedPayloadForPipelineOrCargo(params: any) {
    if (this.nominationService?.isCargoScreen?.value) {
      return {
        desk: `pl${params?.deskParameter}`,
        region: params?.region,
        isCargoType: true,
        includeXLocations: true,
        requestFor: "dropdown",
        modeOfTransport: [params?.data?.modeOfTransportDesc || params?.data?.modeOfTransport || params?.modeOfTransport],
        transportSystem: [params?.data?.transportSystem || params?.transportSystem],
      }
    } else {
      return {
        desk: `pl${params?.deskParameter}`,
        region: params?.region,
        includeXLocations: true,
        isPipelineType: true,
        modeOfTransport: [params?.data?.modeOfTransportDesc || params?.data?.modeOfTransport || params?.modeOfTransport],
        transportSystem: [params?.data?.transportSystem || params?.transportSystem],
      }
    }
  }

  whenLocationName(params: any) {
    if (params?.column?.colId === "locationName" && (params?.desk?.includes("clean products") || params?.desk?.includes("pipeline"))) {
      let response: any= this.utilities.getFilterDataList(true, [params?.data?.transportSystem || params?.transportSystem])
      params.rowData = response?.value?.terminal?.map((data: any) => { return { name: data?.terminalName, id: data?.terminalCode } });
      this.cpService?.locationNameAndId?.next(params?.rowData);
      this.detail(params);
    } else {
      this.whenScheduledMaterialAndDemandMaterial(params);
    }
    this.getContractLineDetails(params)
  }
  getContractLineDetails(params: any) {
    let updateMaterialsCode = params?.data?.demandMaterial !== params?.data?.scheduledMaterial ? [`${params?.data?.scheduledMaterial}`,`${params?.data?.demandMaterial}`] : [`${params?.data?.scheduledMaterial}`];
    let payload: any = {
      desk: `pl${params?.deskParameter}`,
      deskId: params?.selectedDeskId || params?.deskId,
      contractNumber: params?.data?.nominationReferenceDocument,
      location: params?.data?.locationId || null,
      scheduledDate: params?.data?.scheduledDate || null,
      materials : updateMaterialsCode || null
    }
    if (params.data.nominationReferenceDocument && params?.column?.colId === "nominationReferenceDocumentItem" && ((this.desk?.includes("clean products + latam + panama desk") && params?.data?.mot !== 'TankTransfer') || !this.desk?.includes("clean products + latam + panama desk"))) {
      this.replenishmentPlanningService.getContractBalancingSmartFilter(payload).subscribe({
        next: (response: any) => {
          if (response.length > 0) {
            params.rowData = response?.map((data: any) => { return { contractLineNumber: data?.contractLineNumber, contractQuantity: data?.contractQuantity, location: data?.location, modeOfTransportDescription: data?.modeOfTransportDescription, sraDealDetailReferenceNumber: data?.sraDealDetailReferenceNumber, supplier: data?.supplier, contractDateRange: data?.contractDateRange}});
            this.detail(params);
          }
          else {
            this.toasterService.setToastNotification({
              show: true,
              type: 'error',
              msg: `contract line item no. not available`,
            });
          }
        }
      })
    }
  }

  whenScheduledMaterialAndDemandMaterial(params: any) {
    let desk= localStorage.getItem("desk");
    if ((params?.column?.colId === "scheduledMaterialName" || params?.column?.colId === "scheduledMaterial" || params?.column?.colId === "demandMaterialName" || params?.column?.colId === "demandMaterial")  && (desk?.includes("clean products") || desk?.includes("pipeline") || desk?.includes("refinery"))) {
    let response = this.utilities.getFilterDataList(true, [params?.data?.transportSystem], [params?.data?.locationId])
      params.rowData = response?.value?.product?.map((data: any) => { return { name: data?.productName, id: data?.productCode } });
      this.cpService?.scheduledMaterialAndDemandMaterialName?.next(params?.rowData);
      this.detail(params);
    }
  }


  populateLineItems(params: any) {
    const scheduleDate = params.data.scheduledDate.replace(/(..).(..).(....)/, "$3$1$2"); // converts mm/dd/yyyy to yyyymmdd
    this.nominationService.getSapContracts(params.data.locationId, [this.materialCode.find(el => el.name === params?.data?.scheduledMaterialName)?.value], scheduleDate).subscribe((response) => {
      let sapContactDetailsFormation: any = {};
      response.result.forEach((element: { contractNumber: string | number; contractLineNumber: any; }) => {
        if (sapContactDetailsFormation[element.contractNumber]) {
          sapContactDetailsFormation[element.contractNumber].push(element.contractLineNumber);
        }
        else {
          sapContactDetailsFormation[element.contractNumber] = [];
          sapContactDetailsFormation[element.contractNumber].push(element.contractLineNumber);
        }
      })
      const contractDetails = Object.entries(sapContactDetailsFormation).map(([contarctNo, contractLine]) => ({ contractNumber: contarctNo, contractLineNumber: contractLine }));
      const lineNumber = contractDetails.filter(data => data.contractNumber === params.data['nominationReferenceDocument'])
      params.rowData = lineNumber[0].contractLineNumber;
      this.detail(params);
    })
  }
  detail(params: any) {
    this.params = params;
    if (!params?.rowData?.length) {
      this.apiEndpoint = params.apiEndpoint;
      this.useApi = true;
      this.rowData = [{}];
    } else {
      this.rowData = params.rowData;
      let isArray = checkDataType(this.rowData);
      if (isArray) {
        const result = this.rowData.map((item: any) => Object.fromEntries([[params.columnDefs[0].field, item]]));
        this.rowData = result;
      }
    }
    if (params.gridHeight) this.gridHeight = params.gridHeight;
    if (params.gridWidth) {
      this.gridWidth = params.gridWidth;
    }
    else if (params?.column?.colId === "nominationReferenceDocumentItem") {
      this.gridWidth = 650;
    }
    else {
      this.gridWidth = 180;
    }
    params.columnDefs?.forEach((el: any) => {
      if (typeof el.filter === 'undefined') {
        el.filter = 'agTextColumnFilter';
      }
    });
    this.columnDefs = params.columnDefs;
    this.propertyName = params.propertyRendered;
    this.showedColumn = params.showedColumn;
    this.cellValue = params.data[this.propertyName];
    this.returnObject = params.returnObject;

    if (!params.charPress) {
      if (this.cellValue) this.inputValue = this.cellValue;
    } else {
      this.inputValue = params.charPress;
    }

  }

  // Board Event
  @HostListener('keydown', ['$event'])
  onKeydown(event: any) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.params.api.stopEditing();
      return false;
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.rowConfirmed();
      return false;
    }
    if (event.key === 'ArrowUp') {
      this.navigateGrid();
      if (
        this.cellFocusIndex === 0 &&
        this.gridApi.getFocusedCell().rowIndex === 0
      ) {
        this.input.nativeElement.focus();
      } else {
        this.cellFocusIndex = this.gridApi.getFocusedCell().rowIndex;
      }
      return false;
    }
    if (event.key === 'ArrowDown') {
      this.navigateGrid();
      return false;
    }
    return ''
  }


  getValue(): any {
    if (!this.returnObject) return this.selectedObject[this.propertyName];
    return this.selectedObject[this.rowData.columnDefs[0].field];
  }
  isPopup(): boolean {
    return true;
  }
  isCancelAfterEnd(): boolean {
    return this.isCanceled;
  }
  // ag-Grid functions
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.columnFilter = this.gridApi.getFilterInstance(this.propertyName);
  }

  // component functions
  // double clicked value will be selected and combobox will be closed.
  rowClicked(params: any) {
    this.selectedObject = params.data;
    this.isCanceled = false;
    this.params.api.stopEditing();
  }


  processDataInput(event: any) {
    this.updateFilter();
  }

  updateFilter() {
    this.columnFilter.setModel({
      type: 'contains',
      filter: this.inputValue
    });
    this.columnFilter.onFilterChanged();
    if (this.gridApi.getDisplayedRowAtIndex(0)) {
      this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
      this.gridApi.ensureIndexVisible(0, 'top');
    } else {
      this.gridApi.deselectAll();
    }
  }

  // Enter Event Process
  rowConfirmed() {
    if (this.gridApi.getSelectedRows()[0]) {
      this.selectedObject = this.gridApi.getSelectedRows()[0];
      this.isCanceled = false;
    }
    else {
      this.selectedObject = { lineItemList: ' ', sapContractNumber: ' ' }
      this.isCanceled = false;
    }
    this.params.api.stopEditing();
  }

  // Arrow change event
  // The data navigated on it is written to adjust its position on the screen with the arrow keys.
  navigateGrid() {
    if (
      this.gridApi.getFocusedCell() == null ||
      this.gridApi.getDisplayedRowAtIndex(
        this.gridApi.getFocusedCell().rowIndex
      ) == null
    ) {
      // check if no cell has focus, or if focused cell is filtered
      this.gridApi.setFocusedCell(
        this.gridApi.getDisplayedRowAtIndex(0).rowIndex,
        this.showedColumn !== undefined ? this.showedColumn : this.propertyName
      );
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true);
    } else {
      this.gridApi.setFocusedCell(
        this.gridApi.getFocusedCell().rowIndex,
        this.showedColumn !== undefined ? this.showedColumn : this.propertyName
      );
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true);
    }
  }
}

function checkDataType(rowData: any) {
  return (typeof rowData[0] == 'string' || typeof rowData[0] == 'number');
}

