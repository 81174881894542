import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { Router } from '@angular/router';
import { triggerNotifParamSave } from 'src/app/shared/store/action/app.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-save-alerts',
  templateUrl: './save-alerts.component.html',
  styleUrls: ['./save-alerts.component.scss']
})
export class SaveAlertsComponent implements OnInit {

  @Output() closeModalPopup = new EventEmitter();
  changeList: string[] = [];
  choseHref: boolean = false;
  choseDesk: boolean = false;
  constructor(private alertSaveService: AlertSaveGuardService, private router: Router, private store: Store) { }

  ngOnInit(): void {
    this.alertSaveService.listObservable$.subscribe(list => this.changeList = list);
    this.alertSaveService.hrefObservable$.subscribe(show => this.choseHref = show);
  }

  discardChanges(){
    this.alertSaveService.routeAway();
    this.closePopup();
  }

  saveChanges(){

    this.store.dispatch(triggerNotifParamSave({ triggeredNotifParamDataSave: true, isNotifParamSaveEnabled: true }));
    this.alertSaveService.routeAway(); //THIS OCCURS TOO FAST FOR THE STORE TO FINISH
    this.closePopup();
  }

  closePopup(){
    this.alertSaveService.setHrefSource(false);
    this.alertSaveService.setDeskSource(false);
    this.closeModalPopup.emit(false);
  }
}

