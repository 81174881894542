<app-np-header *ngIf="isLoggedIn && deskListResponse.length" (updatedDesk)="getSelectedDesk($event)"
    [deskList]="deskListResponse"></app-np-header>
    
<div id="nav-container" *ngIf="isLoggedIn && homeMenuChange">
    <np-menu class="mainnav" [ngClass]="{'combined-view': isCombinedView}" [location]="NavigationLocation.SiteSpecific" (click)="onClickNavChange($event)"></np-menu>
</div>
<div id="nav-container" *ngIf="isLoggedIn && !homeMenuChange">
    <np-menu class="mainnav" [ngClass]="{'combined-view': isCombinedView}" [location]="NavigationLocation.SiteSpecific" (click)="onClickNavChange($event)"></np-menu>
</div>
<np-notification [show]="toastObj.show" [data]="toastObj" [isContractBalancingDynamicDeals]="path$.value.includes('contracts/dynamicdeals') || path$.value.includes('contracts/dashboard')"></np-notification>
<div class="main-container">
    <div class="sub-header d-flex" *ngIf="!path$.value.includes('/reports/ev-reports')"
    [class.justify-content-start]="path$.value.includes('/contract-balancing') ||path$.value.includes('/dealdetail') " [class.contract-dashboard]="path$.value.includes('/dashboard')">

        <div class="filter-btn-wrap d-flex align-items-center">
            <div class="">
                <div class="">
                    <app-filter-panel [closeFilterPanel]="isFilterPanelClosed"
                        (filterPanelClosed)="getFilterPanelAction($event)" [updatedDesk]="selectedDesk"
                        (deskList)="getDeskList($event)">
                    </app-filter-panel>
                </div>
                <div class="pt-10 view-btns d-flex flex-nowrap ethonal-view--align_items"
                    [class.filter-panel-opened]="!isFilterPanelClosed">
                    <button class="icon-btn" *ngIf="!path$.value.includes('/reports/ev-reports')&& !path$.value.includes('/dashboard') && !path$.value.includes('/dealdetail') && noContractBalancing"
                        (click)="toggleFilterPanel()"
                        [ngClass]="{'active icon-remove': !isFilterPanelClosed, 'filter-icon': isFilterPanelClosed}">
                        <span>filter</span>
                    </button>
                    <button class="icon-btn table-icon" *ngIf="path$.value.includes('/replenishmentPlanning') || path$.value.includes('/marketElasticity')"
                        (click)="toggleView('table')" [ngClass]="{'active': selectedView === 'table'}">
                        <span>table</span>
                    </button>
                    <ng-container
                        *ngIf="(selectedDeskOption === 'ethanol + uswc'|| selectedDeskOption === 'ethanol + usec') && isPivotVisible && path$.value.includes('/replenishmentPlanning')">

                        <div class="udm-header">
                            <div class="udm-header__desk">
                                <custom-dropdown [optionList]="getList(terminalTemp,supplierTemp,carrierTemp)"
                                    (change)="togglebuttonSwitch($event)"></custom-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <!-- <ng-container
                        *ngIf="path$.value.includes('/marketElasticity')">
                        <div class="udm-header me-udm-header">
                            <div class="udm-header__desk">
                                <custom-dropdown [optionList]="getOriginProductList(originProductTemp)"
                                    (change)="togglebuttonSwitch($event)"></custom-dropdown>
                            </div>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="!isCCAPanamaView && isPipelineView && path$.value.includes('/replenishmentPlanning')">

                        <div class="udm-header">
                            <div class="udm-header__desk">
                                <custom-dropdown
                                    [optionList]="getPipelinePivotViews(pipelineProductTemp,pipelineTerminalTemp)"
                                    (change)="togglePipelineButtonSwitch($event)"></custom-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="path$.value.includes('/bcpDashboard')">
                        <div class="udm-header">
                            <div class="udm-header__desk">
                                <custom-dropdown
                                    [optionList]="getBcpPivotViews(bcpProductTemp,bcpTerminalTemp)"
                                    (change)="toggleBcpButtonSwitch($event)"></custom-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <div class="product_highlight-text-color" *ngIf="(selectedDeskOption === 'panama' || isCCAPanamaView) && !path$.value.includes('/contract-balancing') && !path$.value.includes('/reports') 
                                && !path$.value.includes('/admin/parameters') && !path$.value.includes('/settings/alerts') && !path$.value.includes('/bcpDashboard') && !path$.value.includes('/admin/locations') 
                                && !path$.value.includes('/admin/materialMapping') && !path$.value.includes('/admin/desks') && !path$.value.includes('/admin/transport') && !path$.value.includes('/cargoPlanning')"> {{this.getProduct |
                        lowercase}}
                    </div>
                    <ng-container>
                        <div *ngIf="isCCAView && path$.value.includes('/replenishmentPlanning')"class="tr-header-right" >
                            <span class="barrel-text"> data displayed is in barrels</span>
                        </div>
                    </ng-container>
                    <div *ngIf="path$.value.includes('/admin/materialMapping') || path$.value.includes('/admin/transport') || path$.value.includes('/admin/desks')" class="admin-collpase-expand-container">
                        <button class="btn" [class.active]='adminExpandMode$.value'
                            (click)="toggleAdminCollapseAction('expand')">
                            <span>
                                <i class="icon-chevron-up"></i>
                                <i class="icon-chevron-down"></i>
                            </span>
                            <span class="action-text"> expand rows</span>
                        </button>
                        <button class="btn" [class.active]='adminCollapseMode$.value'
                            (click)="toggleAdminCollapseAction('collapse')">
                            <span>
                                <i class="icon-chevron-down"></i>
                                <i class="icon-chevron-up"></i>
                            </span>
                            <span class="action-text">collapse rows</span>
                        </button>

                    </div>
                </div>
            </div>

            <div class="collpase-expand-container"
                *ngIf="((selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec' || selectedDeskOption.includes('pipeline')) && path$.value.includes('/nominations')) || (path$.value.includes('/admin/parameters')) || (path$.value.includes('/settings/alerts'))"
                [class.filter-panel-active]='!isFilterPanelClosed'>
                <button class="btn" [class.active]='expandedMode$.value' (click)="toggleExpandCollpaseAction('expand')">
                    <span>
                        <i class="icon-chevron-up"></i>
                        <i class="icon-chevron-down"></i>
                    </span>

                    <span class="action-text"> expand rows</span>

                </button>
                <button class="btn" [class.active]='collpaseMode$.value'
                    (click)="toggleExpandCollpaseAction('collapse')">
                    <span>
                        <i class="icon-chevron-down"></i>
                        <i class="icon-chevron-up"></i>
                    </span>

                    <span class="action-text">collapse rows</span>
                </button>

                <div style="display: inline-flex;" *ngIf="path$.value.includes('/settings/alerts')">

                    <button class="btn">

                        <span>
                            <mat-slide-toggle class="mat-slide-delete" [checked]="isDeletedRules"
                                (change)="isDeletedRules = !isDeletedRules; getAlertRulesData()"
                                matTooltip="recently deleted alerts will only remain in the system for 90 days"
                                [matTooltipPosition]="'above'"
                                [matTooltipClass]="'alertConfigurationToolTip'"></mat-slide-toggle>

                        </span>
                        <span class="delete-text">deleted rules</span>
                    </button>
                </div>
            </div>
            <div class="collpase-expand-container"
                *ngIf="(((selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec' || selectedDeskOption.includes('clean products'))&& (selectedParams !== 'floridadesk' || selectedParams !== 'pnwdesk')) && path$.value.includes('/nominations'))"
                [class.filter-panel-active]='!isFilterPanelClosed'>
            </div>
            <div class="isCPCargoPlanning-container"
                *ngIf="(selectedParams?.toLowerCase() === 'floridadesk'  || selectedParams?.toLowerCase() === 'pnwdesk' || selectedParams?.toLowerCase() === 'pascagouladesk' || selectedParams?.toLowerCase() === 'richmonddesk' )  && path$.value.includes('/nominations')"
                [class.filter-panel-active]='!isFilterPanelClosed'>
                <button class="btn" [class.active]='cpNominations$.value' (click)="toggleCarogPlanning('cpNomination')">
                    nominations
                </button>
                <button class="btn" [class.active]='cpCargoPlanning$.value'
                    (click)="toggleCarogPlanning('cpCargoPlanning')">
                    cargo planning
                </button>
            </div>
        </div>
        <div *ngIf="path$.value.includes('contracts/dynamicdeals')" class="dynamic-deals-header" >
            <div class="dynamic-deal-count" [class.secondary-filters-opened]="!isFilterPanelClosed"> 
                <ng-container *ngIf="cbDynamicDealsCount$ | async as cbDynamicDeal">
                <span class="dynamic-deals-header__value">{{ cbDynamicDeal.cbDynamicDealsCount }}  deal{{cbDynamicDeal.cbDynamicDealsCount > 1?'s':''}}</span>
                </ng-container>
            </div>
            <div class="dynamic-deals-primary-filters" [class.cb-global-search-bar-opened]="isCBGlobalSearchBarOpened">
                <div class="dynamic-deals-custom-date-range"> 
                    <app-custom-date-range-picker (selectedValues)="getSelectedValues($event)" [changedDeskObj]="checkIfDeskChanged">
                    </app-custom-date-range-picker>
                </div>
                <div class="dynamic-deals-filter-dropdown">
                    <app-filter-dropdown *ngIf="path$.value.includes('contracts/dynamicdeals')" [changedDeskObj]="checkIfDeskChanged"></app-filter-dropdown>
                </div>
             </div>
             <div>
                <app-cb-global-search (isCbGlobalSearchBarOpened)="getIsCBGlobalSearchBarOpened($event)"></app-cb-global-search>
             </div>
        </div>
        <div class="d-flex btn-wrapper sub-header-btns"
            [class.animate-alert-close-icons]="alertsService.sidePanel$.value" *ngIf="!path$.value.includes('contracts/dynamicdeals')">
            
            <div class="state-management-list-container" 
            *ngIf="(path$.value.includes('/marketElasticity') || path$.value.includes('/replenishmentPlanning') || path$.value.includes('/nominations')) && (selectedDeskOption === 'ethanol + uswc'|| selectedDeskOption === 'ethanol + usec' ||
(selectedDeskOption.startsWith('refinery + usec') || (selectedDeskOption.startsWith('clean products'))) && !nominationService.isCargoScreen.value && !selectedDeskOption.includes('+ latam +')) || (path$.value.includes('/bcpDashboard'))"
                #viewsDropdown>
                <div class="dropdown-heading-text">
                    <p> <span class="icon-user"></span> displaying</p>
                </div>
                <div class="st-mgmt-views-dropdown-container dropdown-trigger" [class.active]="showViewsDropdown"
                    (click)="toggleViewDropdown()" [class.active]="showViewsDropdown">
                    <p class="selected-view-item dropdown-trigger">{{selectedCustomView}}
                        <span *ngIf="!showViewsDropdown" class="icon-chevron-down dropdown-trigger"></span> <span
                            *ngIf="showViewsDropdown" class="icon-chevron-up dropdown-trigger"></span>
                    </p>
                    <div class="st-mgmt-views-dropdown-inner-container" *ngIf="showViewsDropdown">

                        <p class="view-list-item" (click)="setSelectedView(view)"
                            *ngFor="let view of viewsList; let i=index;">
                            {{view.name}}</p>
                        <p class="redirect-action-item" (click)="openCustomViewAccordion($event)">create new</p>
                        <p class="redirect-action-item" (click)="openCustomViewAccordion($event)">manage custom
                            views
                        <p class="redirect-action-item" (click)="resetToDefaultView()">default view
                        </p>
                    </div>

                </div>
            </div>
            <div *ngIf="path$.value.includes('/bcpDashboard')" style="float:inherit; margin:10px 50px">
                <div>
                  <label class="switch">
                    <input type="checkbox" id="compactBCP" [checked]="compactViewBCP" (click)="toggleWhiteSpaceActionForBCP($event)">
                    <span class="slider round" [class.data-on-compact]="compactViewBCP"></span>
                  </label>
                  compact view
                </div>
            </div>
            <div>
                <button class="icon-btn export-icon" *ngIf="path$.value.includes('/bcpDashboard')"
                    (click)="downloadBcpReport()">
                    <span>export full report</span>
                </button>
            </div>
            <div class="tr-header-right" *ngIf="path$.value.includes('/admin/parameters')" style="margin-right: 80px">
                <button class="icon-btn save-icon" (click)="saveTankParams()">
                    <span>save</span>
                </button>
            </div>
            <div style="display: inline-flex;" *ngIf="path$.value.includes('/settings/alerts')">
                <div>
                    <button class="icon-btn save-icon" (click)="saveNotifParams()">
                        <span>save</span>
                    </button>
                </div>
                <button id="create_alert_btn" class="create-alert-btn" (click)="toggleCreateAlertModal(); allowUpdate=false">
                    create new alert <img src="../../assets/icons/icon-alert-notification.svg"
                        alt="alert-icon"></button>
                <button id="create_alert_btn" class="create-alert-btn" (click)="toggleUserVacationModal()">
                    vacations<img src="../../assets/icons/icon_vacation_plane.svg" alt="alert-icon">
                </button>
            </div>
            <div class="tr-header-right" [class.sidePanelOpened]=" nominationSidePanelExpanded"
                style="padding-right: 80px" *ngIf="path$.value.includes('/replenishmentPlanning')">
                <div class="custom-dropdown">
                    <button class="icon-btn action-icon dropdown" style="top: 6px; right: 10px;"
                        [ngClass]="{'active': selectedView === 'action'}">
                        <span>actions</span>
                    </button>
                    <div class="dropdown-menu-wrap">
                        <div class="dropdown-menu">
                            <div class="dropdown-item dropdown-label">
                                <label class="switch">
                                    <input type="checkbox" id="compact" [checked]="compactView"
                                        (click)="toggleWhiteSpaceAction($event, selectedDesk)">
                                    <span class="slider round" [class.data-on]="compactView"></span>
                                    compact view
                                </label>
                            </div>
                            <button class="dropdown-item button-text expand-icon"
                                (click)="allGroupActionClick(true)">expand
                                all
                                groups</button>
                            <button class="dropdown-item button-text collapse-icon"
                                (click)="allGroupActionClick(false)">collapse
                                all groups</button>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item button-text constraints-icon"
                            (click)="redirectToInventoryParameters()">set constraints</button>

                        </div>
                    </div>

                </div>
                
                <button class="icon-btn save-icon" (click)="save()"
                    [class.sidePanelOpened]=" nominationSidePanelExpanded" [disabled]="isSaveEnabled || nominationService.isTRSaveDisabled$.value">
                    <span>save</span>
                </button>
                <button class=" nomination-icon icon-btn active m-0" (click)="toggleCreateNominationPanel()">
                    <span>nominations</span>
                </button>
            </div>

            <!-- Market Elasticity -->
            <div class="tr-header-right" [class.sidePanelOpened]=" nominationSidePanelExpanded"
                style="padding-right: 80px" *ngIf="path$.value.includes('/marketElasticity')">
                <div class="custom-dropdown">
                    <button class="icon-btn action-icon dropdown" style="top: 6px; right: 10px;"
                        [ngClass]="{'active': selectedView === 'action'}">
                        <span>actions</span>
                    </button>
                    <div class="dropdown-menu-wrap">
                        <div class="dropdown-menu">
                            <div class="dropdown-item dropdown-label">
                                <label class="switch">
                                    <input type="checkbox" id="compact" [checked]="compactView"
                                        (click)="toggleWhiteSpaceAction($event, selectedDesk)">
                                    <span class="slider round" [class.data-on]="compactView"></span>
                                    compact view
                                </label>
                            </div>
                            <button class="dropdown-item button-text expand-icon"
                                (click)="allGroupActionClick(true)">expand
                                all
                                groups</button>
                            <button class="dropdown-item button-text collapse-icon"
                                (click)="allGroupActionClick(false)">collapse
                                all groups</button>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item button-text constraints-icon"
                            (click)="redirectToInventoryParameters()">set constraints</button>

                        </div>
                    </div>

                </div>
                
                <button class="icon-btn save-icon" (click)="saveEdit()" [disabled]="isSaveDisabledForPSD">
                    <span>save</span>
                </button>
            </div>

            <div *ngIf="path$.value.includes('/nominations') && linkNomData?.length" style="display: block; padding-right: 80px !important;" class="nomination-btn">
                    <button type="button" [disabled]="!nominationService.enableLinkToNomButton$.value.linkNomBotton" (click)="linkToExistingNom()"
                      class="button m-0 mr-3 link-nom">
                      link to selected nominations
                    </button>
            </div>
            <div style="display: inline-flex; padding-right: 80px !important;" class="nomination-btn"
                *ngIf="(path$.value.includes('/nominations') || path$.value.includes('/cargoPlanning')) && !linkNomData?.length">
                <div class="nom-dropdown-container dropdown-trigger" >
                    <p class="selected-view-item dropdown-trigger" (click)="toggleNomCargoDropdown()" >    
                        <span *ngIf="!inCpTab">create new nomination</span>
                         <span *ngIf="inCpTab">create new cargo</span>
                        <span *ngIf="!showNomCargoDropdown" class="icon-chevron-down dropdown-trigger"></span> 
                        <span *ngIf="showNomCargoDropdown" class="icon-chevron-up dropdown-trigger"></span>
                    </p>    
                    <div class="nom-dropdown-inner-container" *ngIf="showNomCargoDropdown">
                            <a class="dropdown-item button-text" *ngIf="!(isCCAPanamaView && !inCpTab)" (click)="openAccordionNomCargo($event)">from
                                scratch</a>  
                            <a class="dropdown-item button-text" *ngIf="isCCAPanamaView && !inCpTab" (click)="openAccordionNomCargo($event)" [class.disabledCreateWithRef]="filterLocationSelected === 'PASCAGOU' || filterLocationSelected === 'RICHMOND'">from
                                scratch - in-tank</a>                    
                            <a class="dropdown-item button-text" *ngIf="isCCAPanamaView && !inCpTab" (click)="openAccordionNomCargo($event)">from
                                scratch - other mots</a>                          
                            <a class="dropdown-item button-text" [class.disabledCreateWithRef]="!nominationService.motCheck$.value && (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec') ||
                                !nominationService.nomSelected$.value && (selectedDeskOption.includes('clean products') || selectedDeskOption.includes('refinery')) "
                                (click)="openAccordionNomCargo($event)">from
                                reference
                                <span 
                                    *ngIf="!nominationService.motCheck$.value  && (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec') || !nominationService.nomSelected$.value && (selectedDeskOption?.includes('clean products'))"
                                    class="tooltiptext">  Please select only one line to create from reference.
                                </span>
                            </a>  
                            <a *ngIf="inCpTab && this.selectedDeskOption?.includes('clean products + latam') " class="dropdown-item button-text" [class.disabledCreateWithRef]="!nominationService.motCheck$.value && (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec') ||
                            !nominationService.nomSelected$.value && selectedDeskOption.includes('clean products')" (click)="openAccordionNomCargo($event)">duplicate
                                <span 
                                    *ngIf="!nominationService.motCheck$.value  && (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec') || !nominationService.nomSelected$.value && (selectedDeskOption?.includes('clean products'))"
                                    class="tooltiptext">  Please select only one line to duplicate.
                                </span>
                            </a>                   
                    </div>            
                </div>
                <div><button (click)="checkEventTypeForDeleteAndPublish('delete')" class="icon-btn delete-icon add-font"
                        [disabled]="!nominationService.enableHeaderButton$.value.deleteButton"></button></div>
                <div *ngIf="(isPipelineView || isRefineryView) && !inCpTab"><button (click)="saveUpdatedNominations()" *ngIf="!searchNomIsOpen"
                    class="icon-btn publish-icon pipeline add-font"
                    [disabled]="!nominationService.enableNominationsSaveButton$.value">
                    <span class="add-font">publish</span>
                </button></div>
                 <div *ngIf="(!isPipelineView && !isRefineryView) || path$.value.includes('/cargoPlanning')"><button (click)="publishOrDeleteData('publish')" *ngIf="!nominationService?.isCargoScreen?.value"
                        class="icon-btn publish-icon add-font"
                        [disabled]="!nominationService.enableHeaderButton$.value.publishButton"></button></div>
                <!--<div><button *ngIf="path$.value.includes('/cargoPlanning') && inCpTab"
                    class="icon-btn push-icon-top-menu add-font"
                    (click)="triggerPushToApplication()">
                </button></div> -->
                <div *ngIf="(!isPipelineView && !isRefineryView) || inCpTab"><button *ngIf="!searchNomIsOpen" (click)="saveUpdatedNominations()" class="icon-btn save-icon add-font"
                        [disabled]="!nominationService.enableNominationsSaveButton$.value">
                        <span style="margin-top: 12px;">save</span>
                    </button></div> 
                <div class="custom-dropdown">
                    <button *ngIf="selectedParams?.toLowerCase() !== 'floridadesk' 
                && selectedParams?.toLowerCase() !== 'pnwdesk'
                && selectedParams?.toLowerCase() !== 'pascagouladesk'
                && selectedParams?.toLowerCase() !== 'richmonddesk'" class="icon-btn action-icon dropdown"
                        [ngClass]="{'active': selectedView === 'action'}">
                        <span>actions</span>
                    </button>   
                    <div class="dropdown-menu-wrap">
                        <div class="dropdown-menu">
                            <button class="dropdown-item button-text expand-icon"
                                (click)="toggleExpandCollpaseAction('expand')">expand all groups
                            </button>
                            <button class="dropdown-item button-text collapse-icon"
                                (click)="toggleExpandCollpaseAction('collapse')">collapse all groups
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!linkNomData?.length">
                <button
                    *ngIf="path$.value.includes('/nominations') && !inCpTab && (selectedDeskOption === 'ethanol + uswc' ||selectedDeskOption === 'ethanol + usec' || selectedDeskOption === PipelineUsecFloridaDesk || selectedDeskOption === PipelineUsecRichmondDesk || selectedDeskOption === PipelineUsecpascagoulaDesk || selectedDeskOption === PipelineUswcPNWDesk || selectedDeskOption === CleanProductsEccaDesk || selectedDeskOption === CleanProductsWccaDesk)"
                    [disabled]="!nominationService.enableHeaderButton$.value.vendorNotificationButton"
                    (click)="triggerVendorPreview()" class="button-text action-btn vendor-notification-button">create
                    vendor
                    notification</button>
            </div>
            <div style="display: inline-flex;"
                *ngIf="path$.value.includes('/admin/materialMapping')">
                <button class="icon-btn save-icon" [disabled]="!isAdminSettingsSaveEnabled" (click)="confirmSaveLocation($event)">
                  <span>save</span>
                </button>
            </div>
            <div style="display: inline-flex;"
              *ngIf="path$.value.includes('/admin/locations')">
              <button class="icon-btn save-icon" [disabled]="!isAdminSettingsSaveEnabled" (click)="confirmSaveLocation($event)">
                  <span>save</span>
              </button>
            </div>
        </div>
        <div class="tr-nominations-panel"
            *ngIf="path$.value.includes('/replenishmentPlanning') && (selectedDeskOption==='panama' || isCCAPanamaView)">
            <div class="create-nomination-wrapper" *ngIf="nominationService.sidePanel$.value">
                <app-create-nominations-side-panel>
                </app-create-nominations-side-panel>
            </div>
        </div>
        <div *ngIf="path$.value.includes('/contract-balancing') &&  (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec' || selectedDeskOption.startsWith('clean'))"
            class="contract-balancing-header" [class.filter-panel-opened]="!isFilterPanelClosed">
            <form class="branded header__form">
                <ng-container *ngIf="cbHeaderCheckboxes.length">
                    <p *ngFor="let value of cbHeaderCheckboxes" class="header-wrapper">
                        <input type="checkbox" name="formcheckbox" id="formcheckbox" [value]="value.name"
                            (change)="filterData($event)" [checked]="value.checked">
                        <label for="'formcheckbox'+ value.name">{{value.name}}</label>
                    </p>
                </ng-container>
            </form>
        </div>
    </div>

    <div class="tr-nominations-panel"
        *ngIf="path$.value.includes('/replenishmentPlanning') && (selectedDeskOption === 'ethanol + uswc' || selectedDeskOption === 'ethanol + usec')">
        <div class="create-nomination-wrapper" *ngIf="nominationService.sidePanel$.value">
            <app-create-nomination-ethanol-side-panel>

            </app-create-nomination-ethanol-side-panel>
        </div>
    </div>
    <div class="tr-nominations-panel" *ngIf="path$.value.includes('/marketElasticity')">
        <div class="create-nomination-wrapper" *ngIf="nominationService.sidePanel$.value">
            <app-create-nomination-elasticity-side-panel>

            </app-create-nomination-elasticity-side-panel>
        </div>
    </div>
    <div class="tr-nominations-panel"
        *ngIf="path$.value.includes('/replenishmentPlanning') && (!isCCAPanamaView && selectedDeskOption.includes('clean products'))">
        <div class="create-nomination-wrapper" *ngIf="nominationService.sidePanel$.value">
            <app-create-nomination-pipeline-side-panel>

                </app-create-nomination-pipeline-side-panel>
            </div>
        </div>
        <div class="sapcontract-warning-modal" *ngIf="showDeleteConfirmation">
            <p class="modal-warning-title">view delete confirmation</p>
            <p class="modal-warning-content">
                Are you sure you want to delete this view?
            </p>
            <div class="button-container d-flex">
                <button class="button confirm-button" (click)="confirmDeleteView('yes')">yes</button>
                <button class="button" (click)="confirmDeleteView('no')">no</button>
            </div>
        </div>
        <np-modal [show]="deleteLocationModal.open" [headerTitle]="'save changes'" [noModalButtons]="true">
          <div class="delete-alerts">
            <div class="label-3">To save the changes made, you need to click "save." If you prefer, cancel the changes  by clicking "cancel."</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div class="form-row pb-1 pr-2">
              <div class="form-group col-md-6 branded">
                <button class="cancel-btn" (click)="confirmSaveView('no')">cancel</button>
            </div>
                <div class="form-group col-md-6 branded">
                    <button class="delete-rule-btn" (click)="confirmSaveView('yes')">save</button>
                </div>
            </div>
          </div>
      </np-modal>

        <div *ngIf="(selectedDeskOption === 'ethanol + uswc'|| selectedDeskOption === 'ethanol + usec' || selectedDeskOption.startsWith('clean products') || selectedDeskOption.startsWith('refinery + usec')) || path$.value.includes('/bcpDashboard')"
            class="tr-nominations-panel">
            <div class="custom-view" *ngIf="nominationService.customViewSidePanel$.value">
                <app-custom-view-side-panel [updatedDesk]="selectedDesk" [deskParam]="selectedParams" [desk]="selectedDeskOption" [selectedCustomView]="selectedCustomView"></app-custom-view-side-panel>
            </div>
        </div>
        <div class="sub-container"
        [class.filter-panel-opened]="!isFilterPanelClosed && !path$.value.includes('/reports/ev-reports')"
        [class.alert-notification-panel-opened]="alertNotificationPanelOpen"
        [class.both-panels-opened]="alertNotificationPanelOpen && !isFilterPanelClosed"
        [class.enable-full-width]="path$.value.includes('contracts/dynamicdeals') && isFilterPanelClosed">
        <router-outlet>
        </router-outlet>
        </div>
</div>

<app-np-loader *ngIf="isLoading"></app-np-loader>

<ng-template #terminalTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>supplier</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_carrier">
            <span>carrier</span>
        </button>
    </div>
</ng-template>
<ng-template #supplierTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>supplier</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
    </div>
</ng-template>
<ng-template #carrierTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_carrier">
            <span>carrier</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
    </div>
</ng-template>
<ng-template #pipelineProductTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>product</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
    </div>
</ng-template>
<ng-template #pipelineTerminalTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>product</span>
        </button>
    </div>
</ng-template>
<ng-template #bcpProductTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>product</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
    </div>
</ng-template>
<ng-template #bcpTerminalTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>terminal</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>product</span>
        </button>
    </div>
</ng-template>
<ng-template #originProductTemp>
    <div>
        <button class="ethonal-view--switch active ethonal-view_supplier">
            <span>origin</span>
        </button>
        <span class="ethonal-view_right-arrow">></span>
        <button class="ethonal-view--switch active ethonal-view_terminal">
            <span>product</span>
        </button>
    </div>
</ng-template>

<div class="create-notifications-wrapper alert-side-panel" [class.animate-alert-close]="alertsService.sidePanel$.value">
    <app-create-alert-notifications-side-panel>
    </app-create-alert-notifications-side-panel>
</div>

<np-modal [show]="dismissAlertModal.open" *ngIf="this.dismissAlertModal.open">
    <app-dismiss-workspace-alert (closeModalPopup)="closeDismissModal()">
    </app-dismiss-workspace-alert>
</np-modal>
<popout-window #popoutWindow id="popoutWindow">
    <app-activity-tracker *ngIf="showAT"></app-activity-tracker>
</popout-window>
<div class="create-notifications-wrapper alert-side-panel" [class.animate-alert-close]="viewInfoPanel">
    <information-panel>
    </information-panel>
</div>