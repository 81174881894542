import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  menuIcon: string;
}

@Component({
  selector: 'app-custom-header',
  template: `
    <div class="custom-header-container">
     <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('asc', $event)"
        [ngClass]="ascSort"
        class="customSortDownLabel"
      >
        <i class="icon-arrow-down"></i>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('desc', $event)"
        [ngClass]="descSort"
        class="customSortUpLabel"
      >
        <i class="icon-arrow-up"></i>
      </div>
      
      <div class="custom-header-label">{{ params.displayName }}</div>
      
       <div
        *ngIf="params.enableMenu"
        #menuButton
        class="custom-header-menu"
        (click)="onMenuClicked()"
      >
      <img src="../assets/icons/menu.svg"
      alt="menu icon" class="menu-icon">
      </div>
      
    </div>
  `,
  styles: [
    `.active {
        color: cornflowerblue;
      }
      .inactive {
        color: grey;
      }
      .custom-header-container {
        display: inline-flex;
      }
      
      .custom-header-label {
        margin: 0 4px;
      }
    `,
  ],
})
export class CustomHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;

  public ascSort = 'inactive';
  public descSort = 'inactive';
  public noSort = 'inactive';

  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    params.column.setMenuVisible(true);

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  onMenuClicked() {
    console.log(this.params);
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }
holdSort:any = null;
  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
if(this.holdSort  === order){
  this.holdSort = null;
  this.params.setSort(null, event.shiftKey);
}else{
  this.holdSort = order;
  this.params.setSort(order, event.shiftKey);
}
    
  }

  refresh(params: IHeaderParams) {
    return false;
  }
}
