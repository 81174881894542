import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { ComponentPortal, DomPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { ActivityTrackerComponent } from '../core/components/activity-tracker/old AT/activity-tracker.component';
import { BehaviorSubject } from 'rxjs';

/*
 * Service used to generate non-modal popup window via new popup browser window
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class PopupWindowService implements OnDestroy {

  popupShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showNomAT$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataToShow$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  togglePopup(setValue: boolean){
    this.popupShow$.next(setValue);
  }

  toggleActivityTrackerNomComponent(setValue: boolean){
    this.showNomAT$.next(setValue);
  }

  setData(dataToSet: any){
    this.dataToShow$.next(dataToSet);
  }


  constructor(private componentFactor: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }

  ngOnDestroy(): void { }
}