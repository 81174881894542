
import { Action, createReducer, on } from "@ngrx/store";
import { applyDDPrimaryFilter, applyDDSecondaryFilter, loadDynamicDealSuccess, resetAllFilters, resetDDSecondaryFilter, selectMaterialTab, toggleDealHeader, updateCustomDateRangePicker } from "../action/cb-dynamic-deals.action";

export interface State {
    cbDynamicDealsResponse: any[];
    filters: { 
        primary : {
             transaction: string[],
              material: { tsw: string[], sra: string[] },
               cycle: string[] 
            },
        secondary: {
             exposure: string[];
              term: string[]; 
              counterParty: string[]; 
              location: string[]
             }
            };
    selectedFilters: {
          primary : {
             transaction: string[],
              material: { tab: string, values: string[] },
               cycle: string[]
             },
          secondary : {
             exposure: string[],
              term: string[],
               counterParty: string[],
                location: string[] 
            }
        };
    filteredData: any[];
    updateCustomDateRangePickerFlag: boolean;
}

export const initialState: State = {
  cbDynamicDealsResponse: [],
    filters: {
        primary: {
            transaction: ['all'],
            material: {
                tsw: ['all'],
                sra: ['all']
            },
            cycle: ['all']
        },
        secondary: {
            exposure: ['all'],
            term: ['all'],
            counterParty: ['all'],
            location: ['all']
        }
    },
    selectedFilters: {
        primary: {
            transaction: ['all'],
            material: {
                tab: "tsw",
                values: ['all']
            },
            cycle: ['all']
        },
        secondary: {
            exposure: ['all'],
            term: ['all'],
            counterParty: ['all'],
            location: ['all']
        }
    },
        filteredData: [],
        updateCustomDateRangePickerFlag: false
    };

  export const months:string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
   export function formatStringToNA(value:any)
  {
      if(!value)
      {
         return 'n/a';
      }
      if(value?.length == 0)
        {
           return 'n/a';
        }
      return value;
  }
  
  export function formatStringToUnAssigned(value:any)
  {
      if(!value)
      {
         return 'unassigned';
      }
      if(value?.length == 0)
        {
           return 'unassigned';
        }
      return value;
  }
  
  function sortfilterValues(data:any)
  {
    return data.sort((a:any,b:any)=>{
      if(a == 'all') return -1;
      if(b == 'all') return 1;
      if(a == 'n/a') return -1;
      if(b == 'n/a') return 1;
      return a.localeCompare(b);
    });
  }
  
  function getStoredSelectedFilters()
  {
    const temp = localStorage.getItem('desk'+getSelectedDeskId()+'selectedFilters');
          const storedFilters = temp ? JSON.parse(temp) : {
              primary: {
                  transaction: ['all'],
                  material: {
                      tab: "tsw",
                      values: ['all']
                  },
                  cycle: ['all']
              },
              secondary: {
                  exposure: ['all'],
                  term: ['all'],
                  counterParty: ['all'],
                  location: ['all']
              }
          };
          return storedFilters;
  }
  
  export function getSelectedDeskId()
  {
    const storedDeskId = localStorage.getItem("deskId");
    const deskId = storedDeskId? parseInt(storedDeskId,10):0;
    return deskId;
  }
  
  export function getSelectedDates()
  {
    const selectedDates = localStorage.getItem(
      `datefilterPayload_Cb_desk${getSelectedDeskId()}`);
    if(selectedDates)
    {
      const parsedDates = JSON.parse(selectedDates);
  
      return {
        startDate: parsedDates.startDate ? parsedDates.startDate : null,
        endDate: parsedDates.endDate ? parsedDates.endDate : null
      }
    }
    // Add current month as default
    const currentMonth = months[new Date().getMonth()];
    
    const currentMonthIndex = months.indexOf(currentMonth);
    
    const firstDate = new Date(new Date().getFullYear(), currentMonthIndex,1);
    const lastDate = new Date(new Date().getFullYear(), currentMonthIndex + 1, 0); // Last day of the last selected month

    return {startDate:firstDate,endDate:lastDate};
  }
  
  export function getPayloadForDynamicDeals()
  {
    const selectedDates = getSelectedDates();
    if(!selectedDates.startDate || !selectedDates.endDate)
    {
      return {deskId:getSelectedDeskId(),filterDealLeg:'true'};
    }
    return {deskId:getSelectedDeskId(),fromDate:selectedDates.startDate,toDate:selectedDates.endDate,filterDealLeg:'true'};
  }

    
function setUpDynamicDealsData(deals:any) {
    let dynamicDeals = deals?.map((dealInfo:any,i:any) => {
             const newdealInfo = {...dealInfo};
            const {counterpartyName,udmMaterialTSW,udmDeliveryWindow,dealRevisionDate,trader,dealTerm,dealNumber,contractNumber} = newdealInfo;

            //adding formatted dealNoContractNo key to each dealInfo obj
             newdealInfo.dealNoContractNo = `${dealNumber} | ${contractNumber===''?'pending':contractNumber}`;
             const detailsList = [{
                label:'counterparty',
                value:counterpartyName??'n/a',
                marginRightValue: "119px"
              },
              {
                label:'material (TSW)',
                value: udmMaterialTSW??'n/a',
                marginRightValue: "167px"
              },
              {
                label:'delivery window',
                value:udmDeliveryWindow??'n/a',
                marginRightValue: "88px"
              },
              {
                label:'date revised',
                value: formatDate(dealRevisionDate),
                marginRightValue: "51px"
              },
              {
                label:'trader',
                value:trader??'n/a',
                marginRightValue: "86px"
              },
              {
                label:'term',
                value:dealTerm??'n/a'
              }];
              newdealInfo['dealDetailList']=  detailsList;
              //For expand-collapse deal leg container
              newdealInfo['isExpanded']=  false;
             if(newdealInfo.dealLegs) {
               newdealInfo.dealLegs =  newdealInfo?.dealLegs?.map((dealLegInfo:any,j:any) =>{
                 const newDealLegInfo = {...dealLegInfo};
                 newDealLegInfo.udmMaterialTSW = formatStringToNA(newDealLegInfo?.udmMaterialTSW)
                 newDealLegInfo.udmMaterialSRA = formatStringToNA(newDealLegInfo?.udmMaterialSRA)
                 newDealLegInfo.pipelineCycle = formatStringToNA(newDealLegInfo?.pipelineCycle)
                 newDealLegInfo.locationAbbreviation = formatStringToNA(newDealLegInfo?.locationAbbreviation)



                 const dealLegAttributes = [
                    {
                      label:'location',
                      value:newDealLegInfo?.location??'n/a',
                      marginRightValue: "145px"
                    },
                    {
                      label:'material (TSW)',
                      value:newDealLegInfo?.udmMaterialTSW??'n/a',
                      marginRightValue: "161px"
                    },
                    {
                      label:'delivery window',
                      value:newDealLegInfo?.udmDeliveryWindow??'n/a',
                      marginRightValue: "88px"
                    },
                    {
                      label:'price curve',
                      value:newDealLegInfo?.curveName??'n/a',
                      marginRightValue: "176px"
                    },
                    {
                      label:'offset',
                      value:newDealLegInfo?.offset??'n/a',
                      marginRightValue: "62px"
                    },
                    {
                      label:'cycle',
                      value:newDealLegInfo?.pipelineCycle??'n/a'
                    }
                  ];
                  newDealLegInfo['dealLegAttributes']=  dealLegAttributes;
                   //For expand-collapse deal leg info
                  newDealLegInfo['expandDealLegInfo']=  {isExpanded: false,index:j};
                 if(newDealLegInfo.nominationCycles)
                 {
                   newDealLegInfo.nominationCycles = newDealLegInfo?.nominationCycles?.map((nominationCycle:any,i:any) => {
                       const newNominationCycle = {...nominationCycle};
                       const fullfilledQuantity = newNominationCycle?.nominations?.reduce((acc:any,curr:any)=>{
                           acc = acc+(+curr.nominatedQuantity);
                           return acc;
                         },0)
                         newNominationCycle["nominationCycleFullfilledQuantity"] = roundToThree(fullfilledQuantity);
                         newNominationCycle["nominationCycleTotalQuantity"] = "null";
                         newNominationCycle.cycle = formatStringToUnAssigned(newNominationCycle.cycle);
                         if(newNominationCycle.nominations) {
                            newNominationCycle.nominations =   newNominationCycle?.nominations?.map((nominationList:any,k:any)=>{
                                const newnominationList = {...nominationList};
                                const nomCycleNomList = [
                                  {
                                    label:'',
                                    value:'',
                                    marginRightValue: "6px",
                                    isActionButton: true
                                  },
                                  {
                                    label:'nom name',
                                    value:newnominationList?.nominationName??'n/a',
                                    marginRightValue: "83px"
                                  },
                                  {
                                    label:'origin',
                                    value:newnominationList?.origin??'n/a',
                                    marginRightValue: "92px"
                                  },
                                  {
                                    label:'qty(mb)',
                                    value:newnominationList?.nominatedQuantity??'n/a',
                                    marginRightValue: "73px"
                                  },
                                  {
                                    label:'batch date',
                                    value: formatDate(newnominationList?.batchDate),
                                    marginRightValue: "49px"
                                  },
                                  {
                                    label:'status',
                                    value:newnominationList?.status??'n/a',
                                    marginRightValue: "92px"
                                  },
                                  {
                                    label:'rdi',
                                    value:newnominationList?.rdi??'n/a',
                                    marginRightValue: "76px"
                                  },
                                  {
                                    label:'chain',
                                    value:newnominationList?.chain??'n/a',
                                  }
                                ];
                                newnominationList['nomCycleNomList']=  nomCycleNomList;
                                return newnominationList;
                              });
                            }
                              // initial space between each nom list in collapsed state
                              const spacing =35;
                              newNominationCycle["top"] =  i * spacing;
                              newNominationCycle["isExpanded"] = false;
                         return newNominationCycle;
                   });
                 }
                   const dealLegFullfilledQuantity = newDealLegInfo?.nominationCycles?.reduce((acc:any,curr:any)=>{
                       acc = acc+(+curr.nominationCycleFullfilledQuantity);
                       return acc;
                     },0);
                     newDealLegInfo.udmMaterialTSW = newDealLegInfo.udmMaterialTSW??'n/a';
                     newDealLegInfo.udmMaterialSRA = newDealLegInfo.udmMaterialSRA??'n/a';
                     newDealLegInfo["dealLegFullfilledQuantity"] = roundToThree(dealLegFullfilledQuantity);
                     return newDealLegInfo;
               });
             }
              const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(dealInfo);
              newdealInfo["udmDealFullfilledQuantity"] = dealFullfilledQuantity;
              newdealInfo["udmDealTotalQuantity"] = dealTotalQuantity;
              return newdealInfo;
           });

    return dynamicDeals;
}




function filterData(data: any[], filters: any) : any[]  {
       
    let filteredData = data;

    if(filters.primary.transaction?.length == 0 || filters.primary.material.values.length == 0 || filters.primary.cycle.length == 0)
    {
      filteredData = []
      return filteredData;
    }
   
       
    if(filters.primary.transaction && filters.primary.transaction.length > 0 && !filters.primary.transaction.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => filters.primary.transaction.includes(dealInfo.udmDealContractType.toLocaleLowerCase()));
    }
    if(filters.secondary.exposure && filters.secondary.exposure.length > 0 && !filters.secondary.exposure.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => {
            const temp =  +dealInfo.udmDealFullfilledQuantity === +dealInfo.udmDealTotalQuantity ? 'balanced':
                              +dealInfo.udmDealFullfilledQuantity > +dealInfo.udmDealTotalQuantity ? 'over': 'under';
            return filters.secondary.exposure.includes(temp);
        });
    }
    if(filters.secondary.term && filters.secondary.term.length > 0 && !filters.secondary.term.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => filters.secondary.term.includes(dealInfo.dealTerm.toLocaleLowerCase()) || dealInfo.dealTerm.toLocaleLowerCase === 'term evergreen');
    }

    if(filters.secondary.counterParty && filters.secondary.counterParty.length > 0 && !filters.secondary.counterParty.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => filters.secondary.counterParty.includes(dealInfo.counterpartyAbbreviation));
    }

    if(filters.primary.material.values && filters.primary.material.values.length > 0 && !filters.primary.material.values.includes('all'))
    {
        if(filters.primary.material.tab === 'tsw')
        {
        filteredData = filteredData.map(dealInfo =>{
            const newDealInfo = {...dealInfo}
            newDealInfo['udmMaterialTSW'] = filters.primary.material.values.length>1?'multiple':filters.primary.material.values.length==1?filters.primary.material.values[0]:newDealInfo['udmMaterialTSW'];
          newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.material.values.includes(dealLeg.udmMaterialTSW))
          return newDealInfo;
        } 
        ).filter(dealInfo => dealInfo.dealLegs?.length > 0);
        
        }
        if(filters.primary.material.tab === 'sra')
        {
            filteredData = filteredData.map(dealInfo =>{
                const newDealInfo = {...dealInfo}
              newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.material.values.includes(dealLeg.udmMaterialSRA))
              return newDealInfo;
            } 
            ).filter(dealInfo => dealInfo.dealLegs?.length > 0);
        }

    }

    if(filters.secondary.location && filters.secondary.location.length > 0 && !filters.secondary.location.includes('all'))
        {
            
            filteredData = filteredData.map(dealInfo =>{
                const newDealInfo = {...dealInfo}
              newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.secondary.location.includes(dealLeg.locationAbbreviation))
              return newDealInfo;
            } 
            ).filter(dealInfo => dealInfo.dealLegs?.length > 0);

        }

    if(filters.primary.cycle && filters.primary.cycle.length > 0 && !filters.primary.cycle.includes('all'))
    {

      filteredData = filteredData.map(dealInfo =>{
        const newDealInfo = {...dealInfo}
      newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.cycle.includes(dealLeg.pipelineCycle))
      return newDealInfo;
    } 
    ).filter(dealInfo => dealInfo.dealLegs?.length > 0);


    }

    // to update deal header quantities
    filteredData = filteredData.map(dealInfo =>{
      const newDealInfo = {...dealInfo};
      const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(newDealInfo);
      newDealInfo["udmDealFullfilledQuantity"] = dealFullfilledQuantity;
      newDealInfo["udmDealTotalQuantity"] = dealTotalQuantity;
      return newDealInfo;
    });

    return filteredData;

}


const cbDynamicDealsReducer = createReducer(
    initialState,
    on(loadDynamicDealSuccess, (state, { cbDynamicDeals }) => {
        const storedFilters = getStoredSelectedFilters();
        const deals = setUpDynamicDealsData(cbDynamicDeals);

        return {
        ...state,
        cbDynamicDealsResponse: deals,
        filters:  {
            primary : 
            {
            transaction: ['all', 'buy', 'sell'],
            material: {
                tsw: sortfilterValues(['all', ...new Set<string>(deals.flatMap((obj:any) => obj.dealLegs).map((l1:any) => l1.udmMaterialTSW || 'n/a'))]),
                sra: sortfilterValues(['all', ...new Set<string>(deals.flatMap((d:any) => d.dealLegs).map((l1:any) => l1.udmMaterialSRA || 'n/a'))])
            },
            cycle: sortfilterValues(['all', ...new Set<string>(deals.flatMap((obj:any) => obj?.dealLegs).map((l1:any) => l1?.pipelineCycle || 'n/a'))])
        },
        secondary: {
            exposure: ['all', 'balanced', 'over', 'under'],
            term: ['all', 'evergreen', 'spot', 'term'],
            counterParty: sortfilterValues(['all', ...new Set<string>(deals.map((l1:any) => l1.counterpartyAbbreviation || 'n/a'))]),
            location: sortfilterValues(['all', ...new Set<string>(deals.flatMap((l1:any) => l1.dealLegs).map((l2:any) => l2.locationAbbreviation || 'n/a'))])
            }
         },
         selectedFilters:storedFilters,
        filteredData: filterData(deals,storedFilters)
     }
    }),
    on(applyDDPrimaryFilter, (state, { filters }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            primary: filters
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters);
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: filteredData
        };
    }),
    on(applyDDSecondaryFilter, (state, { filters }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            secondary: filters
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters);
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: setUpDynamicDealsData(filteredData)
        };
    }),
    on(resetDDSecondaryFilter, (state) => {
        const filters =  {
                exposure: ['all'],
                term: ['all'],
                counterParty: ['all'],
                location: ['all']
        };
        const updatedFilters =  {
           ...state.selectedFilters,
           secondary: filters
    };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters);
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData:filteredData
        };
    }),
    on(resetAllFilters, (state) => {
        const updatedFilters =  {
            primary: {
                transaction: ['all'],
                material: {
                    tab: "tsw",
                    values: ['all']
                },
                cycle: ['all']
            },
            secondary: {
                exposure: ['all'],
                term: ['all'],
                counterParty: ['all'],
                location: ['all']
            }
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters);
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData:filteredData
        };
    }),
    on(selectMaterialTab, (state, { tab }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            primary: {
                ...state.selectedFilters.primary,
                material:{
                    ...state.selectedFilters.primary.material,
                    tab: tab,
                    values: ['all']
                }
            }
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        return {
            ...state,
            selectedFilters: updatedFilters
        };
    }),
    on(toggleDealHeader, (state, { index }) => {
       const deals = state.filteredData.map((item:any,i:any) =>
        i === index ? {...item,isExpanded:!item.isExpanded }: item
       );
        return {
            ...state,
            filteredData: deals
        };
    }),
    on(updateCustomDateRangePicker, (state, { updateCustomDateRangePickerFlag }) => {
       return {
           ...state,
           updateCustomDateRangePickerFlag: updateCustomDateRangePickerFlag
       };
   })

);

function roundToThree(num:any) {
    if(!num)
    {
        return 0;
    }
    return +(Math.round(Number(num + "e+3"))  + "e-3");
  }

  function formatDate(date:any) {
    if(!date)
    {
        return 'n/a';
    }
    const getFullDateInReverse = date?.split("T")[0]?.split("-");
    return `${getFullDateInReverse[1]}/${getFullDateInReverse[2]}/${getFullDateInReverse[0]}`;
  };

  function calculateDealFullFilledAndTotalQty(newdealInfo:any) {
    let dealFullfilledQuantity = newdealInfo?.dealLegs?.reduce((acc:any,curr:any)=>{
      acc = acc+(+curr.dealLegFullfilledQuantity);
      return acc;
    },0);
    // To calculate total quantity for each deal
    let dealTotalQuantity = newdealInfo?.dealLegs?.reduce((acc:any,curr:any)=>{
      acc = acc+(+curr.quantityInMB);
      return acc;
    },0);
    dealFullfilledQuantity = roundToThree(dealFullfilledQuantity);
    dealTotalQuantity = roundToThree(dealTotalQuantity);
    return [dealFullfilledQuantity,dealTotalQuantity];
  }

export function dynamicDealsReducer(state: State | undefined, action: Action) {
    return cbDynamicDealsReducer(state, action);
}
