import { selectTrPipelineApiResponse, trPipelineNominationList, updateNominationData } from './../selector/app.selector';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { PipelineViewService } from 'src/app/services/pipeline-view.service';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { NominationService } from "src/app/services/nomination.service";
import { createNomination, createNominationSuccess, getTrNominationListSuccess, getTRPipelineDataAPISuccess, getTrPipelineNominationListSuccess, getTRTruckDataAPISuccess, invokeGetTRPipelineDataAPI, invokeGetTRTruckDataAPI, invokeTrNominationList, invokeTrPipelineNominationList, updateNomination, updateNominationSuccess } from "../action/app.action";
import { AppState } from "../reducer/app.reducer";
import { createNominationData, trNominationList, trTruckApiResponse } from "../selector/app.selector";

@Injectable()
export class AppEffect {
    constructor(
        private actions$: Actions,
        private nominationService: NominationService,
        private store: Store,
        private appStore: Store<AppState>,
        private ethanolTruckService: EthanolTruckService,
        private pipelineViewService: PipelineViewService
    ) { }

    invokeTRNominationListAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invokeTrNominationList),
            withLatestFrom(this.store.pipe(select(trNominationList))),
            mergeMap((data) => {
                return this.nominationService
                    .getTRNominationsList(data)
                    .pipe(map((data: any) => {
                        return getTrNominationListSuccess({
                            trNominationList: data.result,
                        });
                    }),
                        catchError((error: any) => of(error.result)));
            }),
        ),
    );

    invokeTRPipelineNominationListAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invokeTrPipelineNominationList),
            withLatestFrom(this.store.pipe(select(trPipelineNominationList))),
            mergeMap(([data]) => {
                return this.nominationService
                    .getFilterNominations(data.payload)
                    .pipe(map((data: any) => {
                        return getTrPipelineNominationListSuccess({
                            trPipelineNominationList: data.result,
                        });
                    }),
                        catchError((error: any) => of(error.result)));
            }),
        ),
    );



    createNominationAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createNomination),
            withLatestFrom(this.store.pipe(select(createNominationData))),
            mergeMap(([postData]) => {
                return this.nominationService
                    .createTerminalNomination(postData.payload)
                    .pipe(map((data: any) => {
                        return createNominationSuccess({
                            createdNominationResponse: data,
                        });
                    }),
                        catchError((error: any) => of(error.result)));
            }),
        ),
    );

    invokeGetTRTruckDataAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invokeGetTRTruckDataAPI),
            withLatestFrom(this.store.pipe(select(trTruckApiResponse))),
            mergeMap(([postData]) => {
                return this.ethanolTruckService
                    .getEthanolTruckInformation(postData.payload, postData.isPivotActive)
                    .pipe(map((data: any) => {
                        return getTRTruckDataAPISuccess({
                            
                            trTruckApiResponse: data,
                        });
                    }),
                        catchError((error: any) => of(error.result)));
            }),
        ),
    );
    updatedNominationAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateNomination),
            withLatestFrom(this.store.pipe(select(updateNominationData))),
            mergeMap(([postData]) => {
                return this.nominationService
                    .updateNomination(postData.payload)
                    .pipe(map((data: any) => {
                        return updateNominationSuccess({
                            updatedNominationResponse: data,
                            publishOrDeleteNominationApiResponse: postData.publishOrDeleteNominationApiResponse,
                        });
                    }), catchError((error: any) => of(error.result)));
            }),
        ),
    );
    invokeGetTRPipelineDataAPI$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invokeGetTRPipelineDataAPI),
            withLatestFrom(this.store.pipe(select(selectTrPipelineApiResponse))),
            mergeMap(([postData]) => {
                return this.pipelineViewService
                    .getPipelineViewData(postData.payload, postData.isPivotActive)
                    .pipe(map((data: any) => {
                        return getTRPipelineDataAPISuccess({
                            trPipelineApiResponse: data,
                        });
                    }),
                        catchError((error: any) => {
                            return of(error.result);
                        }));
            }),
        ),
    );
}