<div>
  <div class="form-row pb-1 pr-2">
      <span class="label-3">if</span> &nbsp;
      <span class="label-4">forecasted inventory</span> &nbsp;
      <span class="label-3"> in the</span>
    <div class="form-group col-md-4">
        <select class="form-control" [(ngModel)]="selectedReference" (change)="shareInventoryDetails()">
            <option value="" disabled selected>reference</option>
            <option *ngFor="let reference of referenceDropdown">
                {{reference}}</option>
        </select>
    </div>
  </div>
  <div class="form-row pb-1 pr-2">
    <div class="form-group col-md-4">
      <select class="form-control" [(ngModel)]="selectedNoOfDays" (change)="shareInventoryDetails()">
          <option value="" disabled selected>amount of days</option>
          <option *ngFor="let days of amountOfDaysDropdown">
              {{days}}</option>
      </select>
  </div> <span class="label-3"> days is</span>
  <div class="form-group col-md-4">
      <select class="form-control" [(ngModel)]="selectedRules" (change)="updateParameters()">
          <option value="" disabled selected>rule</option>
          <option *ngFor="let rule of ruleDropdown">
              {{rule}}</option>
      </select>
  </div>
  </div>
  <div class="form-row pb-1 pr-2">
      <div class="form-group col-md-4">
        <select class="form-control" [(ngModel)]="selectedParameters" 
                  [disabled]="this.selectedRules == null || this.selectedRules == ''" (change)="shareInventoryDetails()">
            <option value="" disabled selected>parameter</option>
            <option *ngFor="let parameter of parameterOptions">
              {{parameter}}</option>
        </select>
      </div>
</div>